<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">LISTE DE PRODUITS</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Produits</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content bdy" style="background-color: white;">
    <div class="container-fluid" style="padding: 30px;">


        <button class="btn btn-primary btn-sm" (click)="onAddNewProduct()">Ajouter un produit</button>


        <div class="row mb-3 mt-3">
	    <!--
            <div class="col-2">
                <mat-label>Catégorie de produit</mat-label>

                <mat-select   placeholder="Choisir une categorie de produit" class="  form-control form-control-sm mb-2" [formControl]="productCategoriesListCtrl" multiple required>
                    <mat-select-trigger>
                        {{productCategoriesListCtrl.value?.[0]?.name || ''}}
                        <span *ngIf="(productCategoriesList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(productCategoriesList?.length || 0) - 1}} {{productCategoriesList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let productCategory of productCategoriesList" [value]="productCategory">{{productCategory.name}}</mat-option>
                </mat-select>
            </div>
            <div class="col-2">
                <mat-label>Type de produit</mat-label>
                <mat-select   placeholder="Choisir type de produit" class="  form-control form-control-sm mb-2" [formControl]="productTypesListCtrl" multiple required>
                    <mat-select-trigger>
                        {{productTypesListCtrl.value?.[0]?.name || ''}}
                        <span *ngIf="(productTypesList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(productTypesList?.length || 0) - 1}} {{productTypesList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let productType of productTypesList" [value]="productType">{{productType.name}}</mat-option>
                </mat-select>

            </div>
            <div class="col-2">
                <mat-label>Status</mat-label>

                <mat-select placeholder="Choisir un status " class="  form-control form-control-sm mb-2" [formControl]="statusesListCtrl" multiple required>
                    <mat-select-trigger>
                        {{statusesListCtrl.value?.[0]?.label || ''}}
                        <span *ngIf="(statusesList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(statusesList?.length || 0) - 1}} {{statusesList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
                </mat-select>
            </div>
            <div class="col-2">
            </div>
	    -->
        </div>

        <div class="table-responsive">
	    <div fxLayout fxLayoutAlign="end center">
		<mat-form-field fxFlex="60%">
		    <input matInput type="text" (keyup)="doFilter($event.target.value)"  name="filter" placeholder="Filtrer">
		</mat-form-field>
	    </div>
		    
            <table mat-table [dataSource]="datasource" matSort>

                <ng-container matColumnDef="#">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">#</th>
                    <td style="width: 4%;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.id}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="mainImage">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Image produit</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">
			    <span *ngIf="element && element.mainImage">
				<img cImg height="140" rounded src="{{getUrlOf(element.mainImage)}}" thumbnail  />
				
			    </span>
			</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Nom</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.name}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="sku">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Sku</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.sku}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Prix</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.price | number : '1.2' }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="stock">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Etat des stocks
                    </th>
                    <td mat-cell *matCellDef="let element">

			
			<span *ngIf="!element.quantity || element.quantity == 0 " class="badge badge-pill badge-danger">Rupture de stock</span>
                        <span *ngIf="element.quantity && element.quantity > 0 && element.quantity < 5" class="badge badge-pill badge-primary">Sotck faible</span>

			<span *ngIf="element.quantity && element.quantity >=5 " class="badge badge-pill badge-info">En stock</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="productType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Type de produit</th>
                    <td mat-cell *matCellDef="let element">
			<span class="badge badge-pill badge-info">{{element.productType?.name}}</span>
			<mat-divider></mat-divider>
			<button mat-button  color="primary" *ngIf="element?.productTypes?.length > 3" (click)="more_productTypes = !more_productTypes">{{ more_productTypes ? 'voir moins' : 'voir plus' }}</button> 
                    </td>
                </ng-container>

                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Action</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon matTooltip="consulter" (click)="onConsulterProduct(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="preview"></mat-icon>
                        <mat-icon matTooltip="editer" (click)="onEditProduct(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="edit"></mat-icon>
                        <mat-icon matTooltip="supprimer" (click)="onDelete(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="delete"></mat-icon>
                    </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;" class="example-element-row"></tr>
            </table>
	    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
		<mat-progress-spinner 
		    color="primary" 
		    mode="indeterminate">
		</mat-progress-spinner>
	    </mat-card>
	    
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="pageSize" showFirstLastButtons
                aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>


    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
