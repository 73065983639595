import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import {switchMap, map, combineLatest} from 'rxjs/operators'

import { ClassLevel } from 'src/app/interfaces/class-level';
import { Note } from 'src/app/interfaces/note';

import { ClassLevelService } from 'src/app/services/class-level.service';


import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-niveau-scolaire',
  templateUrl: './new-niveau-scolaire.component.html',
  styleUrls: ['./new-niveau-scolaire.component.scss'], encapsulation: ViewEncapsulation.None
})
export class NewNiveauScolaireComponent  implements OnInit  {
  @Input() TitleVisible: boolean = true;
  @Input() DisplayConfirmationButton: boolean = true;

    ////////////////////////
    // Attributes
    ///////////////////////

    form: FormGroup;
    utilisateurs: any;
    permissions: any;

    ////////////////////////
    // Constructor 
    ///////////////////////
    
  constructor(private router: Router,
	      private _snackBar: MatSnackBar,
	      private classLevelService: ClassLevelService,
      public fb: FormBuilder) { 
 
      }

    ////////////////////////
    // Init
    ///////////////////////
    ngOnInit(): void {
	this.refresh();
	this.initForm();
  }

    ////////////////////////
    // Attributes
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    nom: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    description: new FormControl(null, [Validators.required, Validators.maxLength(150), Validators.minLength(2)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
	
	});

    }

    refresh(): void {

    }

    ////////////////////////
    // Form Actions
    ///////////////////////

    submit() {
    if (!this.form.valid) {
      return;
    }
      const classLevel: ClassLevel = {
          name: this.form.value.nom,
          description:this.form.value.description,
	  //note: this.form.value.note,
	  
      };

	const note : Note = {
	    content : this.form.value.note
	}

	classLevel.note = note;
	// preparing object relations before creating object 
      	this.classLevelService.prepareAddRelations(classLevel).pipe(
	    switchMap(preparedClassLevel => { 
		console.log("entered switch map -- add new classLevel ");
		console.log(preparedClassLevel);
		// add object 
		return this.classLevelService.add(classLevel);}),
	    switchMap(classLevelAddedObservable => {
		let classLevelAdded = classLevelAddedObservable.Data;
		    
		console.log("entered switch map -- handle post relations ");
		console.log(classLevelAdded);
		classLevelAdded.note = note;
		// add relations after object creations
		return this.classLevelService.handlePostAddRelations(classLevelAdded); 
	    })
	).subscribe({
	    next: (res) => { this.checkSubmitSucess(res);},
	    error: (error) => {this.submitError(error);},
	    complete: () => {
		console.info('save profile pre relations complete')

		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();
		    }, 3000);
		
	    }
	    
	    
	});


    }
            ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListNiveauScolairePath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }
}
