import { Component, EventEmitter, Input,  Output, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';

import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { Note } from 'src/app/interfaces/note';
import { Status } from 'src/app/interfaces/status';
import { PaymentType } from 'src/app/interfaces/payment-type';
import { Payment  } from 'src/app/interfaces/payment';
import { Order  } from 'src/app/interfaces/order';

import { PaymentTypeService } from 'src/app/services/payment-type.service';
import { StatusService } from 'src/app/services/status.service';
import { PaymentService } from 'src/app/services/payment.service';
import { OrderService } from 'src/app/services/order.service';


import { Response } from '../../interfaces/response';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

import {ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-editer-methode-payment',
  templateUrl: './editer-methode-payment.component.html',
  styleUrls: ['./editer-methode-payment.component.scss'], encapsulation: ViewEncapsulation.None
})
export class EditerMethodePaymentComponent implements OnInit {
  @Input() TitleVisible: boolean = true;
  @Input() DisplayConfirmationButton: boolean = true;

    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();

    ////////////////////////
    //  Attributes     
    //////////////////////// 

    @Input()     _paymentType: PaymentType;
    @Input('id') _id?: string;
    
    form: FormGroup;


    paymentsListCtrl = new FormControl('');
    paymentsData : Payment[];//string[];
    paymentsList : Payment[];//string[];
    selectedPayments : Payment[];

    
    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatuses : Status[];
    selectedStatus : Status;

    ordersListCtrl = new FormControl('');
    ordersList : Order[];//string[];
    ordersData : Order[];//string[];
    selectedOrders : Order[];
//    selectedOrder : Order;

    ////////////////////////
    // Constructor
    ////////////////////////
  
    constructor(private router: Router,
		private route: ActivatedRoute,
		private paymentTypeService : PaymentTypeService,
		private statusService : StatusService,
		private paymentService : PaymentService,
		private orderService : OrderService,
		
		private _snackBar: MatSnackBar,
		public fb: FormBuilder) { 
 
      }

        ////////////////////////
    // Init
    ////////////////////////  
    ngOnInit(): void {
	this.initCurrentRoute();
	this.refresh();
	this.initForm(); 

    }

    initCurrentRoute():void {
	this.route.params.subscribe(params => {  
	    this._id = params["id"];
	    console.log("current id " + this._id)
	});

    }

    initCurrentObject(): void {
	
      this.paymentTypeService.getById(this._id).subscribe(
	  {
              next: (response: Response<number, PaymentType>) => {
		  console.log("response");
		  console.log(response);
		  this._paymentType = response.Data;
		  this.selectedPayments = this._paymentType?.payments;
		  if (this._paymentType?.status)
		      this.selectedStatus = this._paymentType?.status ;

		  /*
		    if (this._paymentType?.familyType)
		    this.selectedOrder = this._paymentType?.familyType ;
		  */
		  
		  if (this._paymentType?.note) {
		      
		  } else {
		      const note : Note = {  content :  "" };
		      this._paymentType.note = note;
		  }
		  console.log(this._paymentType.status);
		  console.log(this._paymentType);
		  
              },
              error: (e) => {
		  console.log(e);
              },
              complete: () => console.info('loaduser complete')
	  }
      );

    }



    ////////////////////////
    // Datasrouces
    ///////////////////////


    
    refresh(): void {
	this.initCurrentObject();
	
	this.statusService.getAllStatuses().subscribe({
	    next: (response: Response<number, Status[]>) => {
		
		this.statusesData = response.Data;
		this.initStatusesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load statuses complete')
	});

	this.orderService.getAllOrders().subscribe({
	    next: (response: Response<number, Order[]>) => {
		
		this.ordersData = response.Data;
		this.initOrdersSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load orders complete')
	});

	this.paymentService.getAllPayments().subscribe({
            next: (response: Response<number, Payment[]>) => {
		this.paymentsData = response.Data;
		this.initPaymentsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load payments complete')
	});

    }

    ////////////////////////
    // Datasrouces Form
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    name: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    label: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    description: new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(2)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
	});

    }
    
    ////////////////////////
    // Datasources Functions
    ///////////////////////

    

    compareFn(c1,c2): boolean {
	return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }
    
    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns


    initPaymentsSearchDropdown(){
	this.paymentsList = [];
        for ( let i = 0 ; i < this.paymentsData.length; i ++ ) {
            this.paymentsList.push( this.paymentsData[i] );
        }
    }


    initStatusesSearchDropdown(){
	this.statusesList = [];
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
	    {

		if (!this.selectedStatus && this.statusesData[i].label == "Actif")
		{
		    this.selectedStatuses = [];
		    this.selectedStatus = this.statusesData[i];
		    
		    this.selectedStatuses.push(this.statusesData[i]);
		}
		this.statusesList.push( this.statusesData[i] );
	    }
        }

    }

    initOrdersSearchDropdown(){
	this.ordersList = [];
        for ( let i = 0 ; i < this.ordersData.length; i ++ ) {
	    {
		this.ordersList.push( this.ordersData[i] );
	    }
        }

    }

    ////////////////////////
    // On change

    onStatusesChanged($event: any) {
      console.log($event);
    }

    onOrdersChanged($event: any) {
	console.log($event);
    }

    onPaymentsChanged($event: any) {
      console.log($event);
    }


    ////////////////////////
    // On Search
    onKeyPayments(value) { 
            this.paymentsList= []; 
            this.selectPaymentSearch(value);       
    }



    onKeyStatuses(value) { 
            this.statusesList= []; 
            this.selectStatusesSearch(value);       
    }

    onKeyOrders(value) { 
            this.ordersList= []; 
            this.selectOrdersSearch(value);       
    }

    ////////////////////////
    // search elements


    selectPaymentSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.paymentsData.length; i ++ ) {
            let option = this.paymentsData[i];
            if (  option.paymentNumber.toLowerCase().indexOf(filter) >= 0
		|| option.paymentNumber?.toLowerCase().indexOf(filter) >= 0 ) {
                this.paymentsList?.push( option );
            }
        }
    }

    
    selectStatusesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
            let option = this.statusesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.statusesList?.push( option );
            }
        }
    }



    selectOrdersSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.ordersData.length; i ++ ) {
            let option = this.ordersData[i];
            if (    option.orderNumber.toLowerCase().indexOf(filter) >= 0 ) {
                this.ordersList?.push( option );
            }
        }
    }




    ////////////////////////
    ///////////////////////
    
    
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid) {
	    return;
	}

	let paymentType : PaymentType = {
            name: this.form.value.name,
	    description: this.form.value.description,
	};


	const note : Note = {
	    content : this.form.value.note
	}

	if ((this._paymentType.note &&  this._paymentType.note.id ) || this._paymentType.noteId ){
	    note.id =  this._paymentType.note && this._paymentType.note.id ?  this._paymentType.note.id :  this._paymentType.noteId ; 
	}
	
	if (this.selectedStatus){
	    this._paymentType.statusId = this.selectedStatus.id;
	}

	/*if (this.selectedOrders){
	    this._paymentType.familyTypeIdorders = this.selectedOrders;
	}*/


	this._paymentType.note = note;
	this._paymentType.payments = this.selectedPayments;
		
	console.log(this._paymentType);
	console.log("gonna save");
	console.log(this.selectedPayments);
	

	this.paymentTypeService.prepareUpdateRelations(this._paymentType).pipe(
	    switchMap(preparedPaymentType => { 
		console.log("entered switch map -- add new paymentType ");
		console.log(preparedPaymentType);
		return this.paymentTypeService.updateById(this._paymentType.id, this._paymentType);
	    }),
	    switchMap(paymentTypeUpdatedObservable => {
		let paymentTypeUpdated = paymentTypeUpdatedObservable.Data;
		    
		console.log("entered switch map -- handle post relations ");
		console.log(this._paymentType);
		this._paymentType.payments = this.selectedPayments;
		return this.paymentTypeService.handlePostUpdateRelations(this._paymentType); 
	    })
	
						      
	).subscribe({
	    next: (res) => { this.checkSubmitSucess(res);},
	    error: (error) => {this.submitError(error)},
	    complete: () => {
		console.info('save paymentType pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();
		    }, 5000);
		
	    }
	    
	    
	});

    }

    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListMethodePaymentsPath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }
}
