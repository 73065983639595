import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';
import { School } from 'src/app/interfaces/school';
import { SchoolService } from 'src/app/services/school.service';

import { environment } from 'src/environments/environment';
import { Response } from '../../interfaces/response';
import { Router } from '@angular/router';

import { MatSort } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout'; 
import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-list-ecole',
  templateUrl: './list-ecole.component.html',
  styleUrls: ['./list-ecole.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ListEcoleComponent implements OnInit, AfterViewInit {

    //////////////////////
    // Attributes 
    //////////////////////

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
  
    columns: string[] = ['#', 'name', 'email', 'phoneNumber', 'classes', 'address', 'Action' ];
    datasource: MatTableDataSource<School> = new MatTableDataSource<School>();
    pageSize = 10;
    schools: School[];
    
    color="#f8f8fb";
    toppings = new FormControl('');

    isLoading = true;
    
    //////////////////////
    //  Constructor  
    ////////////////////// 

    constructor(private schoolService: SchoolService,
		
    private router: Router,
    public dialog: MatDialog) {

  }

    //////////////////////
    // Init 
    //////////////////////
    
    ngAfterViewInit() {
	this.datasource.paginator = this.paginator;
    }

    ngOnInit(): void {
	this.refresh();   
    }

    //////////////////////
    // Data sources    
    //////////////////////  

    doFilter(value: string) : void  {
	this.datasource.filter = value.trim().toLocaleLowerCase();
    }

    
    refresh() : void {
	this.setLoading(true);
	this.schoolService.getAllSchools().subscribe(
      {
        next: (response: Response<number, School[]>) => {
          this.schools = response.Data;
          this.datasource.data = this.schools;
            this.datasource.paginator = this.paginator;
	    this.setLoading(false);
        },
          error: (e) => {
	      this.setLoading(false);
        },
        complete: () => console.info('loadMenu complete')
      }
    );
    }


    //////////////////////
    // Loading
    //////////////////////

    toggleLoading() : void {
	this.isLoading = !this.isLoading;
    }

    setLoading(loading : boolean) : void {
	this.isLoading = loading;
    }

    //////////////////////
    //////////////////////

    
    //////////////////////
    // Actions
    //////////////////////
    
  onAddNewSchool(): void {
    this.router.navigate([environment.paths.NewEcolePath]);
  }

  onEditSchool(id): void {
      this.router.navigate([environment.paths.EditerEcolePath,id]);
  }

  onConsulterSchool(id): void {
      this.router.navigate([environment.paths.ConsulterEcolePath,id]);
  }

  onDelete(id): void {
    const dialogRef = this.dialog.open(ConfirmerSuppressionDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
	if (result) {
	    this.schoolService.deleteById(id).subscribe(
	      {
		  next: (response: Response<number, School>) => {
		      console.log(response);
		      this.refresh();
		  },
		  error: (e) => {
		      console.log(e);
		  },
		  complete: () => console.info('delete complete')
	      }
	  );

      }
    });
  }
}
