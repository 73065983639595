<!-- <h2 mat-dialog-title><mat-icon>attach_file</mat-icon> File</h2> -->
<mat-dialog-content class="mat-typography">
    <!-- <h3>Send file</h3> -->
    <p>Confirmer la suppression?</p>
  
  
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
  </mat-dialog-actions>