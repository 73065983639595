<div class="container-fluid ps-md-0">
    <div class="row g-0">
      <div class="d-none d-md-flex col-md-8 col-lg-8 logo-image"></div>
      <div class="col-md-4 col-lg-4 bg-form">
        <div class="login  ">
          <div class="container">
            <div class="row">
              <div class="col-md-9 col-lg-8 mx-auto">
                <img src="https://www.office-plus.fr/assets/images/icon/logo.png" width="200" height="200"/>
                <h3 class="login-heading mb-1">Bienvenue!</h3>
                <h6 class="login-subheading mb-4">Connectez-vous pour continuer</h6>
                <form [formGroup]="form" (ngSubmit)="submit()">
  
                    <div class="form-floating mb-3">
                        <mat-form-field appearance="outline" class="example-full-width" >
                            <mat-label>Nom d'utilisateur</mat-label>
                            <input   type="email"  class="form-control" formControlName="email" maxlength="50" matInput placeholder="Ex. pat@example.com" maxlength="50" autocomplete="section-blue username"  required>
                            <mat-error *ngIf="form.controls.email.touched && form.controls.email.invalid">
                              <span *ngIf="form.controls.email.errors.required">Email obligatoire</span>
                              <span *ngIf="form.controls.email.errors.email">Email invalide</span>
                            </mat-error>
                          </mat-form-field>
                    </div>

              
                    <div class="form-floatingmb-3">
                        <mat-form-field class="example-full-width" appearance="outline" >
                            <mat-label>Mot de passe</mat-label>
                            <input  type="password" matInput
				    autocomplete="section-blue current-password"
				    placeholder="Mot de passe" maxlength="20" formControlName="password" required>
                            <mat-error *ngIf="form.controls.password.touched && form.controls.password.invalid">
                              <span *ngIf="form.controls.password.errors.required">Mot de passe obligatoire</span>
                              <span *ngIf="form.controls.password.errors.maxlength">Longueur maximum du mot de passe = 20</span>
                              <span *ngIf="form.controls.password.errors.minlength">Longueur minimum du mot de passe = 4</span>
                            </mat-error>
                          </mat-form-field>
                    </div>
		    <!--
                    <div class="form-check mb-3">
                        <mat-checkbox>
                        </mat-checkbox>
                        <label class="form-check-label" style="color: black;" for="rememberPasswordCheck">
                            Se souvenir de moi
                          </label>
                      </div>
		    -->
                      <div class="d-grid">
                        <button [disabled]="form.invalid"  class="example-full-width mb-2" mat-raised-button color="primary" type="submit" mdbWavesEffect>
                             Log In</button>
			<!--
			<div class="text-center">
                            <a class="small" href="#">Mot de passe oublié?</a>
                        </div>
                        <div class="text-center">
                            <a class="small" href="#">Enregistrez-vous ici</a>
                        </div>
			-->
                      </div>


              

              
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
