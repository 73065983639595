<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">VOIR PERMISSION</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Utilisateurs</a></li>
                    <li class="breadcrumb-item active">Permission</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;" *ngIf="_permission">
    <div class="container-fluid" style="padding: 30px;">

        <div class="d-flex justify-content-end">
            <button class="btn btn-primary btn-sm" (click)="onEditPermission(_permission.id)" >Editer une permission</button>
        </div>

        <label>Voir permission</label>
        <div class="lateral-space">
   
            <div class="row mt-3 w-100 left-background">
                <div class="col-6 left">
                    Nom 
                </div>
                <div class="col-6 right">
                     {{_permission.name}}     
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Label
                </div>
                <div class="col-6 right">
                     {{_permission.label}}  
                </div>
            </div>
	    <div class="row w-100 left-background">
                <div class="col-6 left">
                    Description
                </div>
                <div class="col-6 right">
		    {{_permission.description}}
		</div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    Permissions
                </div>
                <div class="col-6 right">
		    <span *ngFor="let role of _permission?.roles"  class="badge badge-pill badge-primary">{{role.name}}</span>
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Utilisateurs
                </div>
                <div class="col-6 right">
                    <span *ngFor="let user of _permission?.users"  class="badge badge-pill badge-primary">{{user?.username}} - {{user?.profile?.firstname}} {{user?.profile?.lastname}}</span>
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    Module
                </div>
                <div class="col-6 right">
                    {{_permission?.module?.label}} 
                </div>
            </div>
        </div>




        <label class="mt-5">Informations complémentaires</label>
        <div class="lateral-space">
   
            <div class="row mt-3 w-100 left-background">
                <div class="col-6 left">
                    Créé le {{_permission?.objectDetail?.creationDate |  date:'dd/MM/yyyy' }}  
                </div>
                <div class="col-6 right">
                    Modifié le {{_permission?.objectDetail?.creationDate |  date:'dd/MM/yyyy' }} 
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Créé par
                </div>
                <div class="col-6 right">
                    {{_permission?.objectDetail?.creator?.username}}    
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    Modifié par
                </div>
                <div class="col-6 right">
                      {{_permission?.objectDetail?.updater?.username}}
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Status
                </div>
                <div class="col-6 right d-flex justify-content-center">
                     {{_permission?.status?.label}}     
                </div>
            </div>
        </div>
    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
