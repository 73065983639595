<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">LISTE DES LOCALISATIONS</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Localisation</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content bdy" style="background-color: white;">
    <div class="container-fluid" style="padding: 30px;">


        <button class="btn btn-primary btn-sm" (click)="onAddNewLocation()">Ajouter une localisation</button>


        <div class="row mb-3 mt-3">
<!--
            <div class="col-2">
		<mat-label>Commande</mat-label>
                <mat-select   placeholder="Choisir une commande" class="  form-control form-control-sm mb-2" [formControl]="ordersListCtrl" multiple required>
		    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyOrders($event.target.value)">  
                    <mat-select-trigger>
                        {{ordersListCtrl.value?.[0]?.orderNumber || ''}}
                        <span *ngIf="(ordersList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(ordersList?.length || 0) - 1}} {{ordersList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let order of ordersList" [value]="order">{{order.orderNumber}}</mat-option>
                </mat-select>
            </div>
            <div class="col-2">
                <mat-label>Expéditions </mat-label>
                <mat-select   placeholder="Choisir une Expédition" class="  form-control form-control-sm mb-2" [formControl]="shippingsListCtrl" multiple required>
		    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyShippings($event.target.value)">  
                    <mat-select-trigger>
                        {{shippingsListCtrl.value?.[0]?.name || ''}}
                        <span *ngIf="(shippingsList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(shippingsList?.length || 0) - 1}} {{shippingsList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let shipping of shippingsList" [value]="shipping">{{shipping.name}}</mat-option>
                </mat-select>
            </div>
            <div class="col-2">
                <mat-label>Status</mat-label>

                <mat-select placeholder="Choisir un status " class="  form-control form-control-sm mb-2" [formControl]="statusesListCtrl" multiple required>
		    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyStatuses($event.target.value)">  
                    <mat-select-trigger>
                        {{statusesListCtrl.value?.[0]?.label || ''}}
                        <span *ngIf="(statusesList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(statusesList?.length || 0) - 1}} {{statusesList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
                </mat-select>
            </div>
	    -->
        </div>

        <div class="table-responsive">
	    <div fxLayout fxLayoutAlign="end center">
		<mat-form-field fxFlex="60%">
		    <input matInput type="text" (keyup)="doFilter($event.target.value)"  name="filter" placeholder="Filtrer">
		</mat-form-field>
	    </div>
            <table mat-table [dataSource]="datasource" matSort>

                <ng-container matColumnDef="#">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">#</th>
                    <td style="width: 4%;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.id}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Nom</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.name}}</span>
                    </td>
                </ng-container>

		
                <ng-container matColumnDef="address.street">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Adresse</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.address?.street}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="address.zip">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Code Postale</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.address?.zip}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="address.city">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Ville</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.address?.city}}
                    </td>
                </ng-container>

		<ng-container matColumnDef="locationType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Type</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span *ngIf="element.isRelayPoint" class="table-regular">
			    <span class="badge badge-pill badge-info"> Point relais </span>
			</span>
			<span *ngIf="element.isSalePoint" class="table-regular">
			    <span class="badge badge-pill badge-warning"> Point de vente </span>
			</span>
                    </td>
                </ng-container>

		
		<ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Status</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge badge-pill badge-primary"> {{element.status?.label}} </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Action</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon matTooltip="consulter" (click)="onConsulterLocation(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="preview"></mat-icon>
                        <mat-icon matTooltip="editer" (click)="onEditLocation(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="edit"></mat-icon>
                        <mat-icon matTooltip="supprimer" (click)="onDelete(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="delete"></mat-icon>
                    </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;" class="example-element-row"></tr>
            </table>

	    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
		<mat-progress-spinner 
		    color="primary" 
		    mode="indeterminate">
		</mat-progress-spinner>
	    </mat-card>
	    
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="pageSize" showFirstLastButtons
                aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>


    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
