import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';


import { Note } from 'src/app/interfaces/note';
import { Shipping } from 'src/app/interfaces/shipping';
import { ShippingType } from 'src/app/interfaces/shipping-type';
import { Status } from 'src/app/interfaces/status';

import { ShippingService } from 'src/app/services/shipping.service';
import { ShippingTypeService } from 'src/app/services/shipping-type.service';
import { StatusService } from 'src/app/services/status.service';

import { Response } from '../../interfaces/response';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-methode-expedition',
  templateUrl: './new-methode-expedition.component.html',
  styleUrls: ['./new-methode-expedition.component.scss'], encapsulation: ViewEncapsulation.None
})
export class NewMethodeExpeditionComponent implements OnInit {
    @Input() TitleVisible: boolean = true;
    @Input() DisplayConfirmationButton: boolean = true;

    form: FormGroup;

    ////////////////////////
    // Attributes 
    ////////////////////////  

    shippingsListCtrl = new FormControl('');
    shippingsList : Shipping[];//string[];
    shippingsData : Shipping[];//string[];
    selectedShippings : Shipping[];

    ////////////////////////
    // Constructor 
    ////////////////////////
    
    constructor(private router: Router,
		private _snackBar: MatSnackBar,
		public fb: FormBuilder,

		private shippingTypeService: ShippingTypeService,
		private shippingService : ShippingService) { 
 
      }
      

    ////////////////////////
    // Init
    ///////////////////////
    
    ngOnInit(): void {

	
	this.refresh();
	this.initForm();
    }

    ////////////////////////
    // Datasrouces
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    name: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    label: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    description: new FormControl(null, [Validators.required, Validators.maxLength(150), Validators.minLength(2)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
    });
    }

    ////////////////////////
    // Datasrouces
    ///////////////////////

    refresh(): void {
	
	this.shippingService.getAllShippings().subscribe({
            next: (response: Response<number, Shipping[]>) => {
		this.shippingsData = response.Data;
		this.initShippingsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load shippings complete')
	});



    }

    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns
    

    
    initShippingsSearchDropdown(){
	this.shippingsList = [];
        for ( let i = 0 ; i < this.shippingsData.length; i ++ ) {
            this.shippingsList.push( this.shippingsData[i] );
        }
    }

    
    ////////////////////////
    // On Search
    
    onKeyShippings(value) { 
            this.shippingsList= []; 
            this.selectShippingsearch(value);       
    }


    ////////////////////////
    // search elements


    selectShippingsearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.shippingsData.length; i ++ ) {
            let option = this.shippingsData[i];
            if (  option.shippingNumber.toLowerCase().indexOf(filter) >= 0
		|| option.shippingNumber?.toLowerCase().indexOf(filter) >= 0  ) {
                this.shippingsList?.push( option );
            }
        }
    }

    
    ////////////////////////////
    // elements selections
    ///////////////////////////

    OnShippingsChanged($event: any) {
	console.log($event);
    }

    ////////////////////////////
    // External actions
    ///////////////////////////
    
    
    ////////////////////////
    ///////////////////////
    
    
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid) {
	    return;
	}

	
	let shippingType : ShippingType = {
	    name: this.form.value.name,
	    label: this.form.value.label,
	    description: this.form.value.description,
	};


	const note : Note = {
	    content : this.form.value.note
	}



	shippingType.note = note;
	if (this.selectedShippings && this.selectedShippings.length > 0)
	    shippingType.shippings = this.selectedShippings;
	

	console.log(shippingType);
	console.log("gonna save");

	this.shippingTypeService.prepareAddRelations(shippingType).pipe(
	    switchMap(preparedShippingType => { 
		console.log("entered switch map -- add new shippingType ");
		console.log(preparedShippingType);
		return this.shippingTypeService.add(shippingType);}),
	    switchMap(shippingTypeAddedObservable => {
		let shippingTypeAdded = shippingTypeAddedObservable.Data;
		shippingTypeAdded.shippings = this.selectedShippings;
		console.log("entered switch map -- handle post relations ");
		console.log(shippingTypeAdded);
		return this.shippingTypeService.handlePostAddRelations(shippingTypeAdded); 
	    })
	
						      
	).subscribe({
	    next: (res) => {  this.checkSubmitSucess(res);},
	    error: (error) => { this.submitError(error) },
	    complete: () => {
		console.info('save shippings pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();  
		    }, 5000);
		
	    }
	    
	    
	});

    }

    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListMethodeExpeditionPath]);
	
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }
}
