import { AfterViewInit, Component, EventEmitter, OnInit,  Output, Input,
	 ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';

import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import {ActivatedRoute} from '@angular/router';   

// models / services
import { Model } from 'src/app/interfaces/model';  

import { ProductService } from 'src/app/services/product.service';
import { Product } from 'src/app/interfaces/product';
import { Response } from '../../interfaces/response';

//
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';

import { MatSort } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';

import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';


@Component({
  selector: 'app-consulter-product',
  templateUrl: './consulter-product.component.html',
  styleUrls: ['./consulter-product.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ConsulterProductComponent implements OnInit  {
  @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
  
    
    ////////////////////////
    // Attributes
    ////////////////////////
    @Input()     _product: Product;
    @Input('id') _id?: string;

/*    slides =  ['https://m.media-amazon.com/images/W/IMAGERENDERING_521856-T2/images/I/51aQn7tPhnL._AC_UL1500_.jpg',
    'https://m.media-amazon.com/images/W/IMAGERENDERING_521856-T2/images/I/61SgZDS6+RL._AC_UL1500_.jpg',
  'https://www.pngmart.com/files/22/Naruto-Uzumaki-PNG-Isolated-HD.png'];
*/
    slides = [];

    ////////////////////////
    // PRODUCTS ATTRIBUTES 
    ////////////////////////
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    
    //
    // Datatable
    //
    columnsSelection: string[] = ['#', 'companyGroup.label', 'companyGroup.name', 'companyGroup.description',  'companyGroup.companies', 'price', 'Action' ];

    //datasource: MatTableDataSource<Model> = new MatTableDataSource<Model>();
    datasourceSelection: MatTableDataSource<Model> = new MatTableDataSource<Model>();
    pageSize = 20;

    // selectedProduct Converted 
    // selectedGroup Converted 
    selectedCompanyGroupDetails : any[] = [];
    companyGroupDetails : any[] = [];
    
    
    isLoading = false;
    
    ////////////////////////
    // Constructor
    ////////////////////////
    constructor(private router: Router,
		private route: ActivatedRoute,
		private productService: ProductService) {
    }


    ////////////////////////
    // Init
    ////////////////////////

    ngAfterViewInit() {
	this.datasourceSelection.paginator = this.paginator;
	this.datasourceSelection.sort = this.sort;
	this.datasourceSelection.filterPredicate = this.tableFilter(); 

    }

    
    ngOnInit(): void {
	this.initCurrentRoute();
	this.refresh();
    }
    
    initCurrentRoute():void {
	this.route.params.subscribe(params => {
            this._id = params["id"];
	    console.log("current id " + this._id)
        });


    }

    
    initCurrentObject(): void {
	this.productService.getById(this._id).subscribe(
	    {
		next: (response: Response<number, Product>) => {
		    console.log(response);
		    this._product = response.Data;






		    this.initSlides();
		    //this.datasource.data = this.product;
		    //this.datasource.paginator = this.paginator;

		    console.log(this._product);
		    this.initDataTables();
	      // this.refresh();
		},
		error: (e) => {
		    console.log(e);
		},
		complete: () => console.info('loaduser complete')
	    });
    }



    initSlides() : void{
	this.slides = [];

	this.slides = this._product.images?.map(img => this.getUrlOf(img));
	if (this._product.mainImage && this._product.images?.length > 0 ){
	    this.slides = [this.getUrlOf(this._product.mainImage)].concat(this.slides);
	} else if (this._product.mainImage) {
	    this.slides = [(this.getUrlOf(this._product.mainImage))];

	}
	console.log("slides");
	console.log(this.slides);
    }

        ////////////////////////
    // Datatable
    ///////////////////////

    
    initDatasources() : void {
	
	this.datasourceSelection.data = this.companyGroupDetails;
	this.datasourceSelection.paginator = this.paginator;
	this.datasourceSelection.sort = this.sort;
	this.datasourceSelection.filterPredicate = this.tableFilter(); 
	
    }

    initDataTables(): void {
	this.companyGroupDetails = this._product.companyGroupItemTypes?.map(product => this.fromCompanyGroupDataToCompanyGroupDetail(product));
	this.initDatasources(); 
    }

    
    fromCompanyGroupDataToCompanyGroupDetail(companyGroupData) {
	let companyGroupDetail = {
	    checked : false,
	    companyGroupId : companyGroupData?.id,
	    companyGroup: companyGroupData.companyGroup, 
	    price : companyGroupData?.price,
	}
	//console.log(companyGroupDetail);
	return companyGroupDetail;
	
    }

    
    ////////////////////////
    // Filter Datatable
    ///////////////////////

    doFilter(value: string) : void  {
	this.datasourceSelection.filter = value.trim().toLocaleLowerCase();
    }

    
    tableFilter(): (data: any, filter: string) => boolean {
	let columnsNames = this.columnsSelection;

	let filterFunction = function(data, filter): boolean{
	    

	    var datas = columnsNames.map(x => {
		// split string succh as "customer.name"
		var objectData = x.includes(".") ? x.split(".") : [x];
		var currentObject = data;

		// concat array to create object like data["customer"]["name"]
		objectData.forEach(key => {
		    currentObject = currentObject[key];
		})
		// get val and check if its related to filter
		let val = currentObject ?? data[x];
		if (val && val.toString().toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1){		    
		    return  data;
		}
		return null;
		
	    }).filter(x => x); // map and filter to eliminate null data in array

	    
	    return datas?.length > 0 ? true : false; 
	}
	return filterFunction;
     }

    
    //////////////////////
    // TOOLS
    //////////////////////

    getUrlOf(element) : string {
	var path =  element.path ? element.path.replace(/^public?\//i, "") : null;
	return element && element.path ? `${environment.domains.api.url}${path}` : "";
    }

    ////////////////////////
    // Datasources
    ////////////////////////
    
    refresh() : void {
	this.initCurrentObject();	

    }


    //////////////////////
    // Loading
    //////////////////////

    toggleLoading() : void {
	this.isLoading = !this.isLoading;
    }

    setLoading(loading : boolean) : void {
	this.isLoading = loading;
    }

    //////////////////////
    //////////////////////


    ////////////////////////
    // Links / URL
    ///////////////////////


    openInNewTab(namedRoute) {
	let newRelativeUrl = this.router.createUrlTree([namedRoute]);
	console.log(newRelativeUrl);
	let baseUrl = window.location.href.replace(this.router.url, '');

	window.open(baseUrl + newRelativeUrl, '_blank');
    }
    
    onConsulterCompanyGroup(id): void {
	this.openInNewTab(`${environment.paths.ConsulterCompanyGroupPath}/${id}`);
    }

    ////////////////////////
    // Actions
    ////////////////////////

    
  onEditProduct(id): void {
      this.router.navigate([environment.paths.EditerProductPath, id]);
  }

}
