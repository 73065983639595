import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';

export const stringInjector = new InjectionToken<any>('stringInjector');

@Injectable({
  providedIn: 'root'
})
export class HttpRequestOptionsService {
    headers?: HttpHeaders;
    filters?: any[];
    // only for count or delete
    whereClauses?: any[];
    relations?: any[];
    relationsToOmit?: any[];
    
    observe: string = "response";
    bodyParams?: any;
    //
    body?: any;

    //	    'Content-Type':   _headers && _headers.has('Content-Type') ? _headers.get('Content-Type') : 'application/json',    
    // constructor(@Inject(stringInjector) private contentType?:any, @Inject(stringInjector) private accept?:any) {
    //	    'Accept' : accept? accept : "",
    
    constructor( @Inject(stringInjector) private _headers?:any) {
	//const token = localStorage.getItem('token'); // get token from local storage
	const token2 =  JSON.parse(localStorage.getItem('token')); 
	console.log(token2);

	this.headers =   _headers ? _headers: new HttpHeaders({
	    
	    'Content-Type':  'application/json',
	    'Authorization': token2 ? `Bearer ${token2}` : 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFkbWluQGFwaS1vZnAuZnIiLCJpYXQiOjE2ODUzMzE1NDN9.ZSs9Sz1oagYVxu1mUdh-gO1rfgp8zntUV9RTryohWWM' ,
	    
	    //'Access-Control-Allow-Origin': '*'
	});
	this.filters = [];
	this.relations = [];
	
    }

    public static FromArray(arrayOptions : any) : HttpRequestOptionsService {
	const options = new HttpRequestOptionsService();

	options.headers =  arrayOptions.headers ? arrayOptions.headers : options.headers ;
	// filter value getted 
	options.filters =  arrayOptions.filters ? arrayOptions.filters : options.filters ;
	// only for count or delete  
	options.whereClauses =  arrayOptions.whereClauses ? arrayOptions.whereClauses : options.whereClauses ;
	options.relations =  arrayOptions.relations ? arrayOptions.relations : options.relations ;
	options.relationsToOmit =  arrayOptions.relationsToOmit ? arrayOptions.relationsToOmit : options.relationsToOmit ;

	//console.log("init options");
	//console.log(options);
	return options;
    }


    public static toArray(url: string, options: HttpRequestOptionsService)  : any[]{
	var hasFilters, hasParams, hasWhereClauses:boolean;
	var params: [] = [];

	//	console.log("toArray");
	params["url"] = "";
	params["headers"] = options?.headers;
	params["observe"] = options?.observe;

	// Check if has where clause 
	if (Array.isArray(options?.whereClauses) && options?.whereClauses?.length){
	    params["where"]= options?.whereClauses?.length > 1 ?
		`"where={"and":[${HttpRequestOptionsService.list_params(options.whereClauses)}]}` :
		`where=${HttpRequestOptionsService.list_params(options.whereClauses)}`;
	    hasWhereClauses = true;
	    console.log("whereClauses " + options.whereClauses);
	}


	
	if (Array.isArray(options?.filters) && options?.filters?.length){
	    params["url"]= options?.filters?.length > 1 ?
		`"where":{"and":[${HttpRequestOptionsService.list_params(options.filters)}]}` :
		`"where":${HttpRequestOptionsService.list_params(options.filters)}`;
	    hasFilters = true;
	    hasParams = true;
	    console.log("filters " + options.filters);
	}

	if (Array.isArray(options?.relations) && options?.relations?.length){
	    params["url"] += hasFilters ? "," : ""; 
	    params["url"] += `"include":[${HttpRequestOptionsService.list_params(options.relations)}]`;
	    console.log("relations: " + HttpRequestOptionsService.list_params(options?.relations));
	    hasParams = true;
	}

	// choose between filter , where or base url 
	params["url"] = hasParams == true ?  `${url}?filter={${params["url"]}}` :
	    hasWhereClauses == true ? `${url}?${params["where"]}` :
	    url;

	

	params["urlOptions"] = {};
	if (options?.observe)
	    params["urlOptions"].observe = options?.observe;
	if (options?.observe)
	    params["urlOptions"].headers = options?.headers;
	if (options?.bodyParams)
	    params["urlOptions"].body = options?.bodyParams;

	if (options?.body)
	    params["body"] = options?.body;

	if (Array.isArray(options?.relationsToOmit) && options?.relationsToOmit?.length  )
	{
	    //console.log("to Omit");
	    params["relationsToOmit"] = options?.relationsToOmit;
	    //console.log(params["relationsToOmit"]);
	    
	    params = HttpRequestOptionsService.omitProperties(params);

	    
	    
	}

	console.log(params["urlOptions"]);
	return params;

    }

    private static omitProperties(params) : any {
	console.log("omitProperties");
	
	if (!params["relationsToOmit"] || !(params["body"]))
	    return params;

	var bodyArr = params["body"];

	//console.log("Before omit");
	//console.log(bodyArr);
	
	if ( Array.isArray(bodyArr)) {
	    const arrayBody = bodyArr?.map((r: any): any => {
		var tmpBody = r;

		params["relationsToOmit"].forEach((relationToOmit: any) =>{
		    delete tmpBody[relationToOmit];
		});
		return tmpBody;
	    });

	    const bodyArray = arrayBody && arrayBody.length > 0 ? arrayBody : bodyArr;
	    params["body"] = bodyArray;
	} else {
	    var tmpBody = bodyArr; 
	    params["relationsToOmit"].forEach((relationToOmit: any) =>{
		delete tmpBody[relationToOmit];
	    });

	    params["body"] = tmpBody; 
	    //return tmpBody;
	    
	}
	//console.log("After omit");
	//console.log(params);
	return params;
    }
	   


    private static params(data) : string {
	return Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
    }
    
    private static list_params(data) : string {
	return Object.keys(data).map(key => `${JSON.stringify(data[key])}`).join(',');
    }
    

    private static list_params_encoded(data) : string {
	return Object.keys(data).map(key => `{${key}=${encodeURIComponent(data[key])}}`).join('&');
    }

}
