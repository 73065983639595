import { Country } from '@angular-material-extensions/select-country';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AddPermissionDialogComponent } from 'src/app/Dialog/add-permission-dialog/add-permission-dialog.component';
import { AddRoleDialogComponent } from 'src/app/Dialog/add-role-dialog/add-role-dialog.component';

import { Address } from 'src/app/interfaces/address';
import { ProfilDto } from 'src/app/interfaces/profil-dto';

import { UserService } from 'src/app/services/user.service';
import { ProfileService } from 'src/app/services/profile.service';
import { RolesService } from 'src/app/services/roles.service';
import { PermissionService } from 'src/app/services/permission.service';

import { Role } from 'src/app/interfaces/role';
import { Permission } from 'src/app/interfaces/permission';
import { Profil } from 'src/app/interfaces/profil';
import { UserModel } from 'src/app/interfaces/user';
import { Response } from '../../interfaces/response';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-new-user-account',
  templateUrl: './new-user-account.component.html',
  styleUrls: ['./new-user-account.component.scss'], encapsulation: ViewEncapsulation.None
})
export class NewUserAccountComponent implements OnInit {

    ////////////////////////////
    // Attributes
    ///////////////////////////
  form: FormGroup;
  role: any;
  permission: any;
  selectedCountry: Country = undefined;
    toppings = new FormControl('');
    toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

    roles = new FormControl('');
    rolesList : Role[];//string[];
    selectedRoles : Role[];
    rolesData: Role[];
    
    permissions = new FormControl('');
    permissionsList : Permission[];//string[];
    selectedPermissions : Permission[];
    permissionsData: Permission[];
    
    ////////////////////////////
    // Constructor
    ///////////////////////////
    
    constructor(
	private roleService: RolesService,
	private userService: UserService,
	private permissionService: PermissionService,
	private profileService : ProfileService,
	private router: Router,
	private _snackBar: MatSnackBar,
	public dialog: MatDialog,
	public fb: FormBuilder) { 
 
      }


    ////////////////////////////
    // Init
    ///////////////////////////
  ngOnInit(): void {

      this.refresh();
      this.initForm();
  }



    ////////////////////////////
    // Init Forms
    ///////////////////////////
    initForm() : void {
      this.form = this.fb.group({
      nom: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
      prenom: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
      phone: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$"), Validators.minLength(10)]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
	  passwordcheck: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
	  //street: new FormControl(null, [Validators.required, Validators.maxLength(5), Validators.pattern("^[0-9]*$"), Validators.minLength(1)]),
	  street: new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(2)]),
      city: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
      postal: new FormControl(null, [Validators.required, Validators.maxLength(5),  Validators.pattern("^[0-9]*$"), Validators.minLength(5)])
    });
	
    }

    ////////////////////////////
    // Datasources
    ///////////////////////////

    refresh(): void{

	this.roleService.getAllRoles().subscribe({
            next: (response: Response<number, Role[]>) => {
		this.rolesData = response.Data;
		this.initRolesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load roles complete')
	});

	this.permissionService.getAllPermissions().subscribe({
	    next: (response: Response<number, Permission[]>) => {
		this.permissionsData = response.Data;
		this.initPermissionsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load permissions complete')
	});
    }

    compare(o1: any, o2: any): boolean {
	// if possible compare by object's name, and not by reference.
	return o1 && o2 ? o1.id === o2.id : o2 === o2;
    }
    
    
    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns
    
    initPermissionsSearchDropdown(){
	this.permissionsList = [];
        for ( let i = 0 ; i < this.permissionsData.length; i ++ ) {
            this.permissionsList.push( this.permissionsData[i] );
        }
    }

    initRolesSearchDropdown(){
	this.rolesList = [];
        for ( let i = 0 ; i < this.rolesData.length; i ++ ) {
            this.rolesList.push( this.rolesData[i] );
        }
    }

    ////////////////////////
    // On Search

    onKeyRoles(value) { 
            this.rolesList= []; 
            this.selectRoleSearch(value);       
    }

    onKeyPermissions(value) { 
            this.permissionsList= []; 
            this.selectPermissionsSearch(value);       
    }

    ////////////////////////
    // search elements

    selectPermissionsSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.permissionsData.length; i ++ ) {
            let option = this.permissionsData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.permissionsList?.push( option );
            }
        }
    }

    selectRoleSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.rolesData.length; i ++ ) {
            let option = this.rolesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >= 0
		|| option.name?.toLowerCase().indexOf(filter) >= 0) {
                this.rolesList?.push( option );
            }
        }
    }


    ////////////////////////
    ///////////////////////

    ////////////////////////////
    // elements selections
    ///////////////////////////
    
  onCountrySelected($event: Country) {
    this.selectedCountry = $event;
  }

  onRolesChanged($event: any) {
      console.log($event);
  }

  onPermissionsChanged($event: any) {
      console.log($event);
  }
    
    ////////////////////////////
    // External actions
    ///////////////////////////
    
  addNewRole(): void {
    const dialogRef = this.dialog.open(AddRoleDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {

      }
    });
  }

  addNewPermission(): void {
    const dialogRef = this.dialog.open(AddPermissionDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {

      }
    });
  }

    ////////////////////////////
    // Submit actions
    ///////////////////////////

  submit() {
    if (!this.form.valid) {
      return;
    }
    if (this.form.value.password !== this.form.value.passwordcheck) {
      this._snackBar.open('Les 2 mots de passe ne correspondent pas.', '', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    } /*else if (this.role === undefined || this.permission === undefined) {
      this._snackBar.open('Veuillez choisir un role et une permission', '', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    } */else if (this.selectedCountry === undefined) {
      this._snackBar.open('Veuillez choisir un pays', '', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    } else {

	//
	// Creating objects from forms 
	//
	
      let profil: Profil = {
        firstname: this.form.value.nom,
        lastname: this.form.value.prenom,
        email: this.form.value.email,
        phoneNumber: this.form.value.phone
      };
      const address: Address = {
	  country:  this.selectedCountry.name,
          street: this.form.value.street?.toString(),
        city: this.form.value.city,
          zip: this.form.value.postal?.toString(),
//	  zipCode: this.form.value.postal?.toString(),
          additionalDetail: ''
	  
      };
	
	const profilDto: ProfilDto = {
            Profil: profil,
            Address: address
	};
	
	let user: UserModel = {
	    username : this.form.value.email,
	    password :this.form.value.password 
	};


	console.log("roles");
	console.log(this.selectedRoles);

	user.roles = this.selectedRoles;
	user.permissions = this.selectedPermissions;
	profil.address = address;
	profil.user = user;
	
	console.log("Mon profil");
	console.log(profil);
	console.log("Monuser");
	console.log(profil.user);

	this.profileService.prepareAddRelations(profil).pipe(
	    switchMap(preparedProfileObservable => {
		let profilePrepared = preparedProfileObservable.Data;
		console.log("Prepared profile");
		console.log(profilePrepared);
		return this.profileService.add(profilePrepared); 
	    })
	).subscribe({
	    next: (res) => { this.checkSubmitSucess(res) },
	    error: (error) => {this.submitError(error)},
	    complete: () => {
		console.info('save profile pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();
		    }, 5000);
		
	    }
	    
	    
	});

    }
  }
    
    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
      this.router.navigate([environment.paths.ListAccountPath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }

}

	//this.profileService.prepareRelations(profil).subscribe(v => console.log(v));
    	
	/*this.userService.add(user).pipe(
	    switchMap(usrResp => {
		console.log(usrResp);
		var usr = usrResp.Data;
		
		profil.userId = usr.id;
		console.log(profil);
		return this.userService.withProfile(profil);
	    })
	).subscribe(result3 => {
	    console.log(result3);
	    // allready combined

	});*/
/*	).subscribe({
	    next: (response: Response<number, Profil>) => {
		console.log(response)
            },error: (e) => { }, complete: () => console.info('save user complete')
	});*/

/*    handleRelations(): Observable<Rep<number, Any>> {
	return forkJoin
	(
            
            
	)
    }
  */  



	/*
	this.profileService.prepareRelations(profil).subscribe({
	    next: (res) => { console.log(res); },
	    error: (error) => {console.log(error)},
	    complete: () => console.info('save profile pre relations complete') 
	    
	});*/

	//this.profileService.makeBeCalls1(profil).subscribe(v => console.log(v));
