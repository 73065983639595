import { AfterViewInit, Component, EventEmitter, OnInit,  Output, Input,
	 ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

// models   
import { Model } from 'src/app/interfaces/model';  
import { Note } from 'src/app/interfaces/note';
import { Company } from 'src/app/interfaces/company';
import { CompanyGroup } from 'src/app/interfaces/company-group';
import { Product } from 'src/app/interfaces/product';
import { Status } from 'src/app/interfaces/status';

// services 
import { CompanyService } from 'src/app/services/company.service';
import { CompanyGroupService } from 'src/app/services/company-group.service';
import { ProductService } from 'src/app/services/product.service';
import { StatusService } from 'src/app/services/status.service';

//
import { Response } from '../../interfaces/response';

//
import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

//
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';

import { MatSort } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';

import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';


@Component({
  selector: 'app-new-company-group',
  templateUrl: './new-company-group.component.html',
  styleUrls: ['./new-company-group.component.scss'], encapsulation: ViewEncapsulation.None
})
export class NewCompanyGroupComponent implements OnInit {
    @Input() TitleVisible: boolean = true;
    @Input() DisplayConfirmationButton: boolean = true;

    form: FormGroup;

    ////////////////////////
    // Attributes 
    ////////////////////////  

    companiesListCtrl = new FormControl('');
    companiesList : Company[];//string[];
    companiesData : Company[];//string[];
    selectedCompanies : Company[];


    ////////////////////////
    // PRODUCTS ATTRIBUTES 
    ////////////////////////
    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();   
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    
    //
    // Datatable
    //
    //columns: string[] = ['#', 'Price' ];
    columnsSelection: string[] = ['#', 'image', 'product.name', 'product.description', 'product.price', 'price', 'Action' ];

    //datasource: MatTableDataSource<Model> = new MatTableDataSource<Model>();
    datasourceSelection: MatTableDataSource<Model> = new MatTableDataSource<Model>();

    pageSize = 20;
    
    //
    // Data Sources
    //
    productsListCtrl = new FormControl('');
    productsList : Product[];//string[];
    productsData : Product[];//string[];
    selectedProducts : Product[];

    // selectedProduct Converted 
    selectedProductDetails : any[] = [];
    productDetails : any[] = [];
    
    
    isLoading = true;
    
    ////////////////////////
    // Constructor 
    ////////////////////////
    
    constructor(private router: Router,
		private _snackBar: MatSnackBar,
		public fb: FormBuilder,

		private productService: ProductService,
		private companyGroupService: CompanyGroupService,
		private companyService : CompanyService) { 
 
      }
      

    ////////////////////////
    // Init
    ///////////////////////
    
    ngOnInit(): void {

	this.initDataTables();
	this.refresh();
	this.initForm();


    }

    ngAfterViewInit() {
	this.datasourceSelection.paginator = this.paginator;
	this.datasourceSelection.sort = this.sort;
	this.datasourceSelection.filterPredicate = this.tableFilter(); 

    }

    getUrlOf(element) : string {
	var path =  element.path ? element.path.replace(/^public?\//i, "") : null;
	return element && element.path ? `${environment.domains.api.url}${path}` : "";
    }



    
    ////////////////////////
    // Form
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    name: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    label: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    description: new FormControl(null, [Validators.required, Validators.maxLength(150), Validators.minLength(2)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
    });
    }


    ////////////////////////
    // Datatable
    ///////////////////////

    
    initDatasources() : void {
	
	this.datasourceSelection.data = this.productDetails;
	this.datasourceSelection.paginator = this.paginator;
	this.datasourceSelection.sort = this.sort;
	this.datasourceSelection.filterPredicate = this.tableFilter(); 
	
    }

    initDataTables(): void {
	this.setLoading(true);  
	this.productService.getAll().subscribe({
            next: (response: Response<number, Product[]>) => {
		this.productsData = response.Data;
		this.productDetails = this.productsData.map(product => this.fromProductDataToProductDetail(product));
		
		this.initDatasources(); 
		this.initProductsSearchDropdown();
		this.setLoading(false);
            },error: (e) => { this.setLoading(false); }, complete: () => console.info('load roles complete')
	});


    }

    getCheckedItems() {
	this.selectedProductDetails = this.datasourceSelection.data
	    .filter(i => (i as any).checked == true)
	    .map(product => this.fromProductDetailToProductData(product));
    }

    fromProductDetailToProductData(productDetail) {
	let productData = {
	    productId : productDetail.productId,
	    price : productDetail.price
	    //quantity: productDetail.quantity
	};
	return productData;
    }
    
    fromProductDataToProductDetail(product) {
	let productDetail = {
	    checked : false,
	    productId : product.id,
	    product: product, 
	    price : product.price,
	    quantity: 0,
	    img : product.mainImage? this.getUrlOf(product.mainImage) : ""
	}
	//console.log(productDetail);
	return productDetail;
	
    }
    
    ////////////////////////
    // Filter Datatable
    ///////////////////////

    doFilter(value: string) : void  {
	this.datasourceSelection.filter = value.trim().toLocaleLowerCase();
    }

    
    tableFilter(): (data: any, filter: string) => boolean {
	let columnsNames = this.columnsSelection;

	let filterFunction = function(data, filter): boolean{
	    

	    var datas = columnsNames.map(x => {
		// split string succh as "customer.name"
		var objectData = x.includes(".") ? x.split(".") : [x];
		var currentObject = data;

		// concat array to create object like data["customer"]["name"]
		objectData.forEach(key => {
		    currentObject = currentObject[key];
		})
		// get val and check if its related to filter
		let val = currentObject ?? data[x];
		if (val && val.toString().toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1){		    
		    return  data;
		}
		return null;
		
	    }).filter(x => x); // map and filter to eliminate null data in array

	    
	    return datas?.length > 0 ? true : false; 
	}
	return filterFunction;
     }


    ////////////////////////
    // Datasrouces
    ///////////////////////

    refresh(): void {
	
	this.companyService.getAllCompanies().subscribe({
            next: (response: Response<number, Company[]>) => {
		this.companiesData = response.Data;
		this.initCompaniesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load companies complete')
	});



    }

    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns
    

    
    initCompaniesSearchDropdown(){
	this.companiesList = [];
        for ( let i = 0 ; i < this.companiesData.length; i ++ ) {
            this.companiesList.push( this.companiesData[i] );
        }
    }


    initProductsSearchDropdown(){
	this.productsList = [];
        for ( let i = 0 ; i < this.productsData.length; i ++ ) {
            this.productsList.push( this.productsData[i] );
        }
    }

    
    ////////////////////////
    // On Search


    
    onKeyCompanies(value) { 
        this.companiesList= []; 
        this.selectCompaniesSearch(value);       
    }


    onKeyProducts(value) { 
        this.productsList= []; 
        this.selectProductSearch(value);       
    }


    ////////////////////////
    // search elements


    selectCompaniesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.companiesData.length; i ++ ) {
            let option = this.companiesData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0
		|| option.name?.toLowerCase().indexOf(filter) >= 0  ) {
                this.companiesList?.push( option );
            }
        }

    }


    selectProductSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.productsData.length; i ++ ) {
            let option = this.productsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0
		|| option.name?.toLowerCase().indexOf(filter) >= 0  ) {
                this.productsList?.push( option );
            }
        }
    }

    
    ////////////////////////////
    // elements selections
    ///////////////////////////

    
    OnCompaniesChanged($event: any) {
	console.log($event);
    }

    onProductsChanged($event: any) {
      console.log($event);
    }

    
    ////////////////////////////
    // External actions
    ///////////////////////////
    
    
    ////////////////////////
    ///////////////////////


    ////////////////////////
    ///////////////////////
    
    //////////////////////
    // Loading
    //////////////////////

    toggleLoading() : void {
	this.isLoading = !this.isLoading;
    }

    setLoading(loading : boolean) : void {
	this.isLoading = loading;
    }

    //////////////////////
    //////////////////////

    
    ////////////////////////
    // Links / URL
    ///////////////////////


    openInNewTab(namedRoute) {
	let newRelativeUrl = this.router.createUrlTree([namedRoute]);
	console.log(newRelativeUrl);
	let baseUrl = window.location.href.replace(this.router.url, '');

	window.open(baseUrl + newRelativeUrl, '_blank');
    }
    
    onConsulterProduct(id): void {
	this.openInNewTab(`${environment.paths.ConsulterProductPath}/${id}`);
    }

    
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid) {
	    return;
	}

	console.log("CompanyGroup");
	// get items checked 
	this.getCheckedItems();
	
	let companyGroup : CompanyGroup = {
	    name: this.form.value.name,
	    label: this.form.value.label,
	    description: this.form.value.description,
	};


	const note : Note = {
	    content : this.form.value.note
	}



	companyGroup.note = note;
	if (this.selectedCompanies && this.selectedCompanies.length > 0)
	    companyGroup.companies = this.selectedCompanies;

	if (this.selectedProductDetails && this.selectedProductDetails.length > 0)
	    companyGroup.companyGroupItemTypes = this.selectedProductDetails;
	

	console.log(companyGroup);
	console.log("gonna save");

	this.companyGroupService.prepareAddRelations(companyGroup).pipe(
	    switchMap(preparedCompanyGroup => { 
		console.log("entered switch map -- add new companyGroup ");
		console.log(preparedCompanyGroup);
		return this.companyGroupService.add(companyGroup);}),
	    switchMap(companyGroupAddedObservable => {
		let companyGroupAdded = companyGroupAddedObservable.Data;
		companyGroupAdded.companyGroupItemTypes = this.selectedProductDetails;
		companyGroupAdded.companies = this.selectedCompanies;
		console.log("entered switch map -- handle post relations ");
		console.log(companyGroupAdded);
		return this.companyGroupService.handlePostAddRelations(companyGroupAdded); 
	    })
	
						      
	).subscribe({
	    next: (res) => {  this.checkSubmitSucess(res);},
	    error: (error) => { this.submitError(error) },
	    complete: () => {
		console.info('save companies pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();  
		    }, 5000);
		
	    }
	    
	    
	});

    }

    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListCompanyGroupsPath]);
	
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }
}
