import { Component, EventEmitter, Input,  Output, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';

import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { Note } from 'src/app/interfaces/note';
import { Status } from 'src/app/interfaces/status';
import { ShippingType } from 'src/app/interfaces/shipping-type';
import { Shipping  } from 'src/app/interfaces/shipping';

import { ShippingTypeService } from 'src/app/services/shipping-type.service';
import { StatusService } from 'src/app/services/status.service';
import { ShippingService } from 'src/app/services/shipping.service';


import { Response } from '../../interfaces/response';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-editer-methode-expedition',
  templateUrl: './editer-methode-expedition.component.html',
  styleUrls: ['./editer-methode-expedition.component.scss'], encapsulation: ViewEncapsulation.None
})
export class EditerMethodeExpeditionComponent implements OnInit {
  @Input() TitleVisible: boolean = true;
  @Input() DisplayConfirmationButton: boolean = true;

    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();

    ////////////////////////
    //  Attributes     
    //////////////////////// 

    @Input()     _shippingType: ShippingType;
    @Input('id') _id?: string;
    
    form: FormGroup;


    shippingsListCtrl = new FormControl('');
    shippingsData : Shipping[];//string[];
    shippingsList : Shipping[];//string[];
    selectedShippings : Shipping[];

    
    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatuses : Status[];
    selectedStatus : Status;


    ////////////////////////
    // Constructor
    ////////////////////////
  
    constructor(private router: Router,
		private route: ActivatedRoute,
		private shippingTypeService : ShippingTypeService,
		private statusService : StatusService,
		private shippingService : ShippingService,
		
		private _snackBar: MatSnackBar,
		public fb: FormBuilder) { 
 
      }

        ////////////////////////
    // Init
    ////////////////////////  
    ngOnInit(): void {
	this.initCurrentRoute();
	this.refresh();
	this.initForm(); 

    }

    initCurrentRoute():void {
	this.route.params.subscribe(params => {  
	    this._id = params["id"];
	    console.log("current id " + this._id)
	});

    }

    initCurrentObject(): void {
	
      this.shippingTypeService.getById(this._id).subscribe(
	  {
              next: (response: Response<number, ShippingType>) => {
		  console.log("response");
		  console.log(response);
		  this._shippingType = response.Data;
		  this.selectedShippings = this._shippingType?.shippings;
		  if (this._shippingType?.status)
		      this.selectedStatus = this._shippingType?.status ;
		  if (this._shippingType?.note) {
		      
		  } else {
		      const note : Note = {  content :  "" };
		      this._shippingType.note = note;
		  }
		  console.log(this._shippingType.status);
		  console.log(this._shippingType);
		  
              },
              error: (e) => {
		  console.log(e);
              },
              complete: () => console.info('loaduser complete')
	  }
      );

    }



    ////////////////////////
    // Datasrouces
    ///////////////////////


    
    refresh(): void {
	this.initCurrentObject();
	
	this.statusService.getAllStatuses().subscribe({
	    next: (response: Response<number, Status[]>) => {
		
		this.statusesData = response.Data;
		this.initStatusesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load statuses complete')
	});


	this.shippingService.getAllShippings().subscribe({
            next: (response: Response<number, Shipping[]>) => {
		this.shippingsData = response.Data;
		this.initShippingsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load shippings complete')
	});

    }

    ////////////////////////
    // Datasrouces Form
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    name: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    label: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    description: new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(2)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
	});

    }
    
    ////////////////////////
    // Datasources Functions
    ///////////////////////

    

    compareFn(c1,c2): boolean {
	return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }
    
    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns


    initShippingsSearchDropdown(){
	this.shippingsList = [];
        for ( let i = 0 ; i < this.shippingsData.length; i ++ ) {
            this.shippingsList.push( this.shippingsData[i] );
        }
    }


    initStatusesSearchDropdown(){
	this.statusesList = [];
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
	    {

		if (!this.selectedStatus && this.statusesData[i].label == "Actif")
		{
		    this.selectedStatuses = [];
		    this.selectedStatus = this.statusesData[i];
		    
		    this.selectedStatuses.push(this.statusesData[i]);
		}
		this.statusesList.push( this.statusesData[i] );
	    }
        }

    }


    ////////////////////////
    // On change

    onStatusesChanged($event: any) {
      console.log($event);
    }

    onShippingsChanged($event: any) {
      console.log($event);
    }


    ////////////////////////
    // On Search
    onKeyShippings(value) { 
            this.shippingsList= []; 
            this.selectShippingSearch(value);       
    }



    onKeyStatuses(value) { 
            this.statusesList= []; 
            this.selectStatusesSearch(value);       
    }


    ////////////////////////
    // search elements


    selectShippingSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.shippingsData.length; i ++ ) {
            let option = this.shippingsData[i];
            if (  option.shippingNumber.toLowerCase().indexOf(filter) >= 0
		|| option.shippingNumber?.toLowerCase().indexOf(filter) >= 0 ) {
                this.shippingsList?.push( option );
            }
        }
    }

    
    selectStatusesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
            let option = this.statusesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.statusesList?.push( option );
            }
        }
    }





    ////////////////////////
    ///////////////////////
    
    
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid) {
	    return;
	}

	let shippingType : ShippingType = {
            name: this.form.value.name,
	    description: this.form.value.description,
	};


	const note : Note = {
	    content : this.form.value.note
	}

	if ((this._shippingType.note &&  this._shippingType.note.id ) || this._shippingType.noteId ){
	    note.id =  this._shippingType.note && this._shippingType.note.id ?  this._shippingType.note.id :  this._shippingType.noteId ; 
	}
	
	if (this.selectedStatus){
	    this._shippingType.statusId = this.selectedStatus.id;
	}


	this._shippingType.note = note;
	this._shippingType.shippings = this.selectedShippings;
		
	console.log(this._shippingType);
	console.log("gonna save");
	console.log(this.selectedShippings);
	

	this.shippingTypeService.prepareUpdateRelations(this._shippingType).pipe(
	    switchMap(preparedShippingType => { 
		console.log("entered switch map -- add new shippingType ");
		console.log(preparedShippingType);
		return this.shippingTypeService.updateById(this._shippingType.id, this._shippingType);
	    }),
	    switchMap(shippingTypeUpdatedObservable => {
		let shippingTypeUpdated = shippingTypeUpdatedObservable.Data;
		    
		console.log("entered switch map -- handle post relations ");
		console.log(this._shippingType);
		this._shippingType.shippings = this.selectedShippings;
		return this.shippingTypeService.handlePostUpdateRelations(this._shippingType); 
	    })
	
						      
	).subscribe({
	    next: (res) => { this.checkSubmitSucess(res);},
	    error: (error) => {this.submitError(error)},
	    complete: () => {
		console.info('save shippingType pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();
		    }, 5000);
		
	    }
	    
	    
	});

    }

    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListMethodeExpeditionPath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }
}
