<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">LISTE DES CLASSES</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Établissement</a></li>
                    <li class="breadcrumb-item active">Liste</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content bdy" style="background-color: white;">
    <div class="container-fluid" style="padding: 30px;">


        <button class="btn btn-primary btn-sm" (click)="onAddNewClass()">Nouvelle classe</button>


        <div class="row mb-3 mt-3">
	    <!--
            <div class="col-2">
                <mat-label>Niveau scolaire</mat-label>

                <mat-select   placeholder="Choisir niveau scolaire" class="  form-control form-control-sm mb-2" [formControl]="classLevelsListCtrl" multiple required>
                    <mat-select-trigger>
                        {{classLevelsListCtrl.value?.[0]?.name || ''}}
                        <span *ngIf="(classLevelsList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(classLevelsList?.length || 0) - 1}} {{classLevelsList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let classLevel of classLevelsList" [value]="classLevel">{{classLevel.name}}</mat-option>
                </mat-select>
            </div>
            <div class="col-2">
                <mat-label>Écoles</mat-label>

                <mat-select   placeholder="Choisir une ecole" class="  form-control form-control-sm mb-2" [formControl]="schoolsListCtrl" multiple required>
                    <mat-select-trigger>
                        {{schoolsListCtrl.value?.[0]?.name || ''}}
                        <span *ngIf="(schoolsList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(schoolsList?.length || 0) - 1}} {{schoolsList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let school of schoolsList" [value]="school">{{school.name}}</mat-option>
                </mat-select>
		
            </div>
            <div class="col-2">
                <mat-label>Status</mat-label>

                <mat-select placeholder="Choisir un status " class="  form-control form-control-sm mb-2" [formControl]="statusesListCtrl" multiple required>
                    <mat-select-trigger>
                        {{statusesListCtrl.value?.[0]?.label || ''}}
                        <span *ngIf="(statusesList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(statusesList?.length || 0) - 1}} {{statusesList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
                </mat-select>
            </div>
	    -->
        </div>

        <div class="table-responsive">
	    
            <table mat-table [dataSource]="datasource" matSort>

                <ng-container matColumnDef="#">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">#</th>
                    <td style="width: 4%;" mat-cell *matCellDef="let element">
                        <span class="table-regular">#{{element.id}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="school.name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">École</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.school?.name}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="classLevel.name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Niveau scolaire</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.classLevel?.name}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="note">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Note</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.note?.content}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="schoolList">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Liste scolaire</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span *ngIf="element.schoolList"><a href="#">voir le pdf</a> </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.background-color]="color"> Status</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge badge-pill badge-primary">{{element.status?.label}}</span>
                    </td>
                </ng-container>


                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Action</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon matTooltip="consulter" (click)="onConsulterClass(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="preview"></mat-icon>
                        <mat-icon matTooltip="editer" (click)="onEditClass(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="edit"></mat-icon>
                        <mat-icon matTooltip="supprimer" (click)="onDelete(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="delete"></mat-icon>
                    </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;" class="example-element-row"></tr>
            </table>

	    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
		<mat-progress-spinner 
		    color="primary" 
		    mode="indeterminate">
		</mat-progress-spinner>
	    </mat-card>
		    
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="pageSize" showFirstLastButtons
                aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>


    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
