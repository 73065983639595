import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import {ActivatedRoute} from '@angular/router';   

import { ShippingService } from 'src/app/services/shipping.service';
import { Shipping } from 'src/app/interfaces/shipping';
import { Response } from '../../interfaces/response';

@Component({
  selector: 'app-consulter-expedition',
  templateUrl: './consulter-expedition.component.html',
  styleUrls: ['./consulter-expedition.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ConsulterExpeditionComponent implements OnInit  {
  @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
    ////////////////////////
    // Attributes
    ////////////////////////
    @Input()     _shipping: Shipping;
    @Input('id') _id?: string;

    ////////////////////////
    // Constructor
    ////////////////////////
    constructor(private router: Router,
		private route: ActivatedRoute,
		private shippingService: ShippingService) {
    }


    ////////////////////////
    // Init
    ////////////////////////

    ngOnInit(): void {
	this.initCurrentRoute();
	this.refresh();
    }
    
    initCurrentRoute():void {
	this.route.params.subscribe(params => {
            this._id = params["id"];
	    console.log("current id " + this._id)
        });


    }

    
    initCurrentObject(): void {
	this.shippingService.getById(this._id).subscribe(
	    {
		next: (response: Response<number, Shipping>) => {
		    console.log(response);
		    this._shipping = response.Data;
	      
	      //this.datasource.data = this.shipping;
              //this.datasource.paginator = this.paginator;

		    console.log(this._shipping);

	      // this.refresh();
		},
		error: (e) => {
		    console.log(e);
		},
		complete: () => console.info('loaduser complete')
	    });
    }


    

    ////////////////////////
    // Datasources
    ////////////////////////
    
    refresh() : void {
	this.initCurrentObject();	

    }
    
    ////////////////////////
    // Actions
    ////////////////////////
    
    onEditShipping(id): void {

	this.router.navigate([environment.paths.EditerExpeditionPath,id]);
  }

}
