import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


// models
import { ClassGrade } from '../interfaces/class-grade';
import { ClassLevel } from '../interfaces/class-level';
import { Note } from '../interfaces/note';

// services
import {NoteService} from '../services/note.service'

// rxjs
import { of, from,  map, Observable, exhaustMap, EMPTY, empty ,identity, forkJoin} from 'rxjs';
import { mergeMap, mergeAll, toArray, switchMap} from 'rxjs/operators';

// response   
import { Response as Rep } from '../interfaces/response';

import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"


@Injectable({
  providedIn: 'root'
})
export class ClassGradeService extends BaseCrudModel<ClassGrade>{

    constructor(protected override httpService: HttpService,
	        private noteService : NoteService) {
	super(httpService, "class-grades");

	var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "objectDetail"},
		{"relation" : "note"},
		{"relation" : "classLevel"},
		{"relation" : "school", "scope" : { "include" : [{"relation": "address" }]}}
		//, "scope": {"include" : [{"relation" : "classLevel"}]}}
	    ],
	});

	var addOptions =  RequestOptions.FromArray({
	    relationsToOmit : ['note', 'status', 'classLevel', 'school']
	});

	this.methods.listing.urlOptions = options;
	this.methods.view.urlOptions = options;
	this.methods.add.urlOptions = addOptions;
	this.methods.update.urlOptions = addOptions;
	
    }

    getAllClassGrades(): Observable<Rep<number, ClassGrade[]>> {
	const options = new RequestOptions();

	return this.getAll()
    }

        ////////////////////////////////
    // PREPARE || HANDLE Relations
    ////////////////////////////////
    
    override prepareRelations(fromClassGrade): Observable<Rep<number, ClassGrade>> { //Observable<Profile> {
	// values to save
	const classGrade : ClassGrade = fromClassGrade;


	// Obs Functions
	const failObj : Rep<number, Note> = {IsSuccess:  0, Data:  null, Status : 0};

	const a$ = of();

	const note$ : Observable<Rep<number, Note>> = classGrade.note ? 
	    ( classGrade.note.id ? this.noteService.updateById(fromClassGrade.note.id, fromClassGrade.note) : this.noteService.add(fromClassGrade.note) )
	    :  this.httpService.createObservableResponse<Note>(failObj);

	
	return note$
	    .pipe(
		map(note => 
		    {
			console.log("created note");
			console.log(note);
			console.log("before setting Note");
			console.log(classGrade);
			(classGrade.noteId = note && note.IsSuccess ?
			    (note.Data && note.Data.id ? note.Data.id : classGrade.noteId) : classGrade.noteId );
			
			return   this.httpService.createResponseFromObj<ClassGrade>(classGrade)
		    }),
		map(rel =>
		    {
			console.log("final result");
			console.log(rel);
			// return profile
			return    this.httpService.createResponseFromObj<ClassGrade>(classGrade) ;
		    }),
		
	    );
    }
}
