import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

// models   
import { ClassLevel } from '../interfaces/class-level';
import { Note } from '../interfaces/note';

// services
import {UserService} from '../services/user.service'
import {NoteService} from '../services/note.service'

// rxjs
import { of, from,  map, Observable, exhaustMap, EMPTY, empty ,identity, forkJoin} from 'rxjs';
import { mergeMap, mergeAll, toArray, switchMap} from 'rxjs/operators';

// response   
import { Response as Rep } from '../interfaces/response';

import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"


@Injectable({
  providedIn: 'root'
})
export class ClassLevelService extends BaseCrudModel<ClassLevel>{

 
    constructor(protected override httpService: HttpService,
		private noteService : NoteService) {
	super(httpService, "class-levels");


	var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "objectDetail"},
		{"relation" : "note"},
	    ],
	});

	var addOptions =  RequestOptions.FromArray({
	    relationsToOmit : ['note', 'status']
	});

	this.methods.listing.urlOptions = options;
	this.methods.view.urlOptions = options;
	this.methods.add.urlOptions = addOptions;
	this.methods.update.urlOptions = addOptions;
    }

    getAllClassLevels(): Observable<Rep<number, ClassLevel[]>> {
	const options = new RequestOptions();

	return this.getAll()
    }


    ////////////////////////////////
    // PREPARE || HANDLE Relations
    ////////////////////////////////
    
    override prepareRelations(fromClassLevel): Observable<Rep<number, ClassLevel>> { //Observable<Profile> {
	// values to save
	const classLevel : ClassLevel = fromClassLevel;


	// Obs Functions
	const failObj : Rep<number, Note> = {IsSuccess:  0, Data:  null, Status : 0};

	const a$ = of();

	const note$ : Observable<Rep<number, Note>> = classLevel.note ? 
	    ( classLevel.note.id ? this.noteService.updateById(fromClassLevel.note.id, fromClassLevel.note) : this.noteService.add(fromClassLevel.note) )
	    :  this.httpService.createObservableResponse<Note>(failObj);

	
	return note$
	    .pipe(
		map(note => 
		    {
			console.log("created note");
			console.log(note);
			console.log("before setting Note");
			console.log(classLevel);
			(classLevel.noteId = note && note.IsSuccess ?
			    (note.Data && note.Data.id ? note.Data.id : classLevel.noteId) : classLevel.noteId );
			
			return   this.httpService.createResponseFromObj<ClassLevel>(classLevel)
		    }),
		map(rel =>
		    {
			console.log("final result");
			console.log(rel);
			// return profile
			return    this.httpService.createResponseFromObj<ClassLevel>(classLevel) ;
		    }),
		
	    );
    }


}
