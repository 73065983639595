import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';        

// models
import { CompanyGroup, CompanyGroupProduct } from '../interfaces/company-group';
import { Company } from '../interfaces/company';
import { Note } from '../interfaces/note';

// services
import {NoteService} from '../services/note.service'

// rxjs
import { of, from,  map, Observable, exhaustMap, EMPTY, empty ,identity, forkJoin} from 'rxjs';
import { mergeMap, mergeAll, toArray, switchMap} from 'rxjs/operators';

// response   
import { Response as Rep } from '../interfaces/response';

import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"

@Injectable({
  providedIn: 'root'
})
export class CompanyGroupService extends BaseCrudModel<CompanyGroup>{

    postCompanies = {url: `${environment.domains.api.url}/companies`, urlOptions: this.options};
    deleteCompanies = {url: `${environment.domains.api.url}/companies`, urlOptions: this.options};

    postProducts = {url: `${environment.domains.api.url}/company-group-products/all`, urlOptions: this.options};
    deleteProducts = {url: `${environment.domains.api.url}/company-group-products`, urlOptions: this.options};

    constructor(protected override httpService: HttpService,
		private noteService : NoteService) {
	super(httpService, "company-groups");

	var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "objectDetail"},
		{"relation" : "note"},
		{"relation" : "companies"},
		{"relation" : "products"},
		{"relation" : "companyGroupItemTypes", "scope" : {
		    "include" : [{"relation": "product", "scope" : { "include" : [{"relation": "mainImage"}]} }]}},
	    ],
	});

	var addOptions =  RequestOptions.FromArray({
	    relationsToOmit : ['note', 'status', 'companies', 'productDetails', 'companyGroupItemTypes']
	});

	this.methods.listing.urlOptions = options;
	this.methods.view.urlOptions = options;
	this.methods.add.urlOptions = addOptions;
	this.methods.update.urlOptions = addOptions;
	
    }

    getAllCompanyGroups(): Observable<Rep<number, CompanyGroup[]>> {
	const options = new RequestOptions();

	return this.getAll()
    }

        ////////////////////////////////
    // PREPARE || HANDLE Relations
    ////////////////////////////////
    
    override prepareRelations(fromCompanyGroup): Observable<Rep<number, CompanyGroup>> { //Observable<Profile> {
	// values to save
	const companyGroup : CompanyGroup = fromCompanyGroup;


	// Obs Functions
	const failObj : Rep<number, Note> = {IsSuccess:  0, Data:  null, Status : 0};

	const a$ = of();

	const note$ : Observable<Rep<number, Note>> = companyGroup.note ? 
	    ( companyGroup.note.id ? this.noteService.updateById(fromCompanyGroup.note.id, fromCompanyGroup.note) : this.noteService.add(fromCompanyGroup.note) )
	    :  this.httpService.createObservableResponse<Note>(failObj);

	
	return note$
	    .pipe(
		map(note => 
		    {
			console.log("created note");
			console.log(note);
			console.log("before setting Note");
			console.log(companyGroup);
			(companyGroup.noteId = note && note.IsSuccess ?
			    (note.Data && note.Data.id ? note.Data.id : companyGroup.noteId) : companyGroup.noteId );
			
			return   this.httpService.createResponseFromObj<CompanyGroup>(companyGroup)
		    }),
		map(rel =>
		    {
			console.log("final result");
			console.log(rel);
			// return profile
			return    this.httpService.createResponseFromObj<CompanyGroup>(companyGroup) ;
		    }),
		
	    );
    }

    //////////////////////////
    // HANDLE || PREPARE
    //////////////////////////

    override handleRemoveRelations(companyGroup): Observable<any> {
	console.log("removePostRelations for companyGroup : \n {0}".format(JSON.stringify(companyGroup)));

	return forkJoin
	(
	    // Remove elements if empty
	    companyGroup && companyGroup.id && (companyGroup.companies && companyGroup.companies?.length >= 0) ? this.removeCompanies(companyGroup.id) :
		this.httpService.createObservableResponse<Company[]>({IsSuccess:  0, Data:  undefined, Status : 0}),

	    // Remove elements if empty
	    companyGroup && companyGroup.id && (companyGroup.companyGroupItemTypes && companyGroup.companyGroupItemTypes?.length >= 0) ? this.removeProducts(companyGroup.id) :
		this.httpService.createObservableResponse<CompanyGroupProduct[]>({IsSuccess:  0, Data:  undefined, Status : 0})

	)
    }
    
    override handlePostRelations(companyGroup): Observable<any> {
	console.log(companyGroup);
	console.log("handlePostRelations");
	    return forkJoin
	(
	    
	    // add new elements 
	    companyGroup && companyGroup.id && companyGroup.companies && companyGroup.companies?.length > 0 ? this.addCompanies(companyGroup.id, companyGroup.companies) :
		this.httpService.createObservableResponse<Company[]>({IsSuccess:  0, Data:  undefined, Status : 0}),

	    // add new elements 
	    companyGroup && companyGroup.id && companyGroup.companyGroupItemTypes && companyGroup.companyGroupItemTypes?.length > 0 ? this.addProducts(companyGroup.id, companyGroup.companyGroupItemTypes) :
		this.httpService.createObservableResponse<Company[]>({IsSuccess:  0, Data:  undefined, Status : 0})
	)
    }

    
    //////////////////////////
    // Add Other objects
    //////////////////////////

    public removeCompanies(companyGroupId):  Observable<Rep<number, any>> {
	console.log("remove company for companyGroupId:{companyGroupId}".formatUnicorn({companyGroupId : companyGroupId}));
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		//{"companyGroupId" : companyGroupId},
		{"companyGroupId" : {"regexp" : "/^{companyGroupId}$/".formatUnicorn({companyGroupId: companyGroupId})}},
	    ],
	});
	options.body = {"companyGroupId" : ""}
	return this.httpService.makePatchRequest<any>( `${this.deleteCompanies.url}`, options);
//	return  this.httpService.createObservableResponse<CompanyCompanyGroup[]>({IsSuccess:  0, Data:  undefined, Status : 0});
    }


    //////////////////////////
    // Add Other objects
    //////////////////////////

    public addCompanies(companyGroupId, companies):  Observable<Rep<number, any>> {
	
 	
	//const arrayCompanies = companies?.map((r: any): Company => ({companyGroupId : companyGroupId, companyId: r.id}));

	const arrayCompanies = companies?.map((r: Company): any => (r.id));

	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		{"id" : {"inq" : arrayCompanies}},
	    ],
	});
	console.log("Array companies");
	console.log(arrayCompanies);
	options.body =  {"companyGroupId" : companyGroupId} ;//arrayCompanies;	

	return arrayCompanies && arrayCompanies.length > 0 ?
	    this.httpService.makePatchRequest<Company[]>( `${this.postCompanies.url}`.format(), options) :
	    this.httpService.createObservableResponse<Company[]>({IsSuccess:  0, Data:  undefined, Status : 0});
	//Observable.empty<number, any>() ;
    }


    public removeProducts(companyGroupId):  Observable<Rep<number, any>> {
	console.log("remove product for companyGroupId:{companyGroupId}".formatUnicorn({companyGroupId : companyGroupId}));
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		//{"companyGroupId" : companyGroupId},
		{"companyGroupId" : {"regexp" : "/^{companyGroupId}$/".formatUnicorn({companyGroupId: companyGroupId})}},
	    ],
	});
	//options.body = {"companyGroupId" : ""}
	options.bodyParams = {}
	
	//return this.httpService.makePatchRequest<any>( `${this.deleteProducts.url}`, options);
	return this.httpService.makeDeleteRequest<any>( `${this.deleteProducts.url}`, options);
//	return  this.httpService.createObservableResponse<ProductCompanyGroup[]>({IsSuccess:  0, Data:  undefined, Status : 0});
    }


    //////////////////////////
    // Add Other objects
    //////////////////////////

    public addProducts(companyGroupId, products):  Observable<Rep<number, any>> {
	
 	
	const arrayProducts = products?.map((r: any): CompanyGroupProduct => ({companyGroupId : companyGroupId, ...r}));
	//const arrayProducts = products?.map((r: Product): any => (r.id));

	const options =  new RequestOptions(); 
	/*	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		{"id" : {"inq" : arrayProducts}},
	    ],
	});*/
	console.log("Array products");
	console.log(arrayProducts);
	options.body =  arrayProducts; //{"companyGroupId" : companyGroupId} ;//arrayProducts;	

	return arrayProducts && arrayProducts.length > 0 ?
	    //	    this.httpService.makePatchRequest<CompanyGroupProduct[]>( `${this.postProducts.url}`.format(), options) :
	    this.httpService.makePostRequest<CompanyGroupProduct[]>( `${this.postProducts.url}`.format(), options) :
	    this.httpService.createObservableResponse<CompanyGroupProduct[]>({IsSuccess:  0, Data:  undefined, Status : 0});
	//Observable.empty<number, any>() ;
    }

}
