<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">VOIR SOCIETE</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Client</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;"  *ngIf="_company">
    <div class="container-fluid" style="padding: 30px;">

        <div class="d-flex justify-content-end">
            <button class="btn btn-primary btn-sm" (click)="onEditCompany(_company.id)">Éditer une société</button>
        </div>

        <label>Voir point de vente</label>
        <div class="lateral-space">
   
            <div class="row mt-3 w-100 left-background">
                <div class="col-6 left">
                    Nom
                </div>
                <div class="col-6 right">
                    {{_company?.name}}
                </div>
            </div>
	    <div class="row  w-100">
                <div class="col-6 left">
                    Numero de telephone
                </div>
                <div class="col-6 right">
                    {{_company?.phoneNumber}}
                </div>
	    </div>
	    <div class="row mt-3 w-100 left-background">
                <div class="col-6 left">
                    Email
                </div>
                <div class="col-6 right">
                    {{_company?.email}}
                </div>
            </div>
	    <div class="row mt-3 w-100 ">
                <div class="col-6 left">
                    Groupe
                </div>
                <div class="col-6 right">
                    <span *ngIf="_company && _company?.companyGroup">
			<span class="badge badge-pill badge-info"> {{_company.companyGroup?.label}} </span>
		    </span>
                </div>
            </div>
	    <div class="row mt-3 w-100 left-background">
                <div class="col-6 left">
                    Clients 
                </div>
                <div class="col-6 right">
		    <span *ngIf="_company.customers && _company.customers?.length > 0">
			    <div class="col-6 right"  *ngIf="3 as maxNbCustomers">
				<a  [routerLink]="" (click)="show_customers = !show_customers" >Voir la liste de clients</a>

				<span *ngIf="show_customers;">
				    <span *ngFor="let customer of _company?.customers;nb_customers as index;let i = index;let c = count">
					
					<span *ngIf="i < maxNbCustomers || more_customers;  "  class="badge badge-pill badge-info"> {{customer?.profile?.firstname}}</span>
					<button mat-button  color="primary" *ngIf="i  + 1== c && c > maxNbCustomers" (click)="more_customers = !more_customers">{{ more_customers ? 'voir moins' : 'voir plus' }}</button> 
				    </span>
				</span>
			    </div>
			</span>
                </div>
            </div>
        </div>







        <div class="row mt-5">
            <div class="col-md-6 col-sm-12">
                <label>Adresse </label>
                <div class="lateral-space">
           
                    <div class="row mt-3 w-100 left-background">
                        <div class="col-6 left">
                            Rue
                        </div>
                        <div class="col-6 right">
                            {{_company?.companyAddress?.street}}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Ville
                        </div>
                        <div class="col-6 right">
			    {{_company?.companyAddress?.city}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Code postal
                        </div>
                        <div class="col-6 right">
			    {{_company?.companyAddress?.zip}}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Pays
                        </div>
                        <div class="col-6 right">
                            {{_company?.companyAddress?.country}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Details additionnels
                        </div>
                        <div class="col-6 right">
 			    {{_company?.companyAddress?.additionalDetail}}
			    
                        </div>
                    </div>
                </div>
            </div>
	    


            <div class="col-md-6 col-sm-12">
                <label>Adresse de facturation</label>
                <div class="lateral-space">
           
                    <div class="row mt-3 w-100 left-background">
                        <div class="col-6 left">
                            Rue
                        </div>
                        <div class="col-6 right">
                            {{_company?.billingAddress?.street}}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Ville
                        </div>
                        <div class="col-6 right">
			    {{_company?.billingAddress?.city}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Code postal
                        </div>
                        <div class="col-6 right">
			    {{_company?.billingAddress?.zip}}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Pays
                        </div>
                        <div class="col-6 right">
                            {{_company?.billingAddress?.country}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Details additionnels
                        </div>
                        <div class="col-6 right">
 			    {{_company?.billingAddress?.additionalDetail}}
			    
                        </div>
                    </div>
                </div>
            </div>

	</div>
	<div class="row mt-5">
	    <div class="col-md-12 col-sm-12">
                <label>Informations complémentaires</label>
                <div class="lateral-space">
           
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Créé le {{_company?.objectDetail?.creationDate |  date:'dd/MM/yyyy' }}  
                        </div>
                        <div class="col-6 right">
                            Modifié le  {{_company?.objectDetail?.modificationDate |  date:'dd/MM/yyyy' }} 
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Créé par
                        </div>
                        <div class="col-6 right">
                             {{_company?.objectDetail?.creator?.username}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Modifié par
                        </div>
                        <div class="col-6 right">
                            {{_company?.objectDetail?.updater?.username}} 
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Status
                        </div>
                        <div class="col-6 right d-flex justify-content-center">
                            <span class="badge badge-pill badge-primary">{{_company?.status?.label}}  </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
