<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">NOUVELLE EXPÉDITION</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Expédition</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">



        <h3 style="color: black; font-size:medium">Ajouter un nouveau profil</h3>
        <form class="example-form" [formGroup]="form" (ngSubmit)="submit()">


            <div class="row mt-3">
                <!--<div class="col-4">
                     <mat-label>Numéro</mat-label>
                     <input type="text" matInput formControlName="nom" class="form-control form-control-sm" required>
                     </div>-->
		
                <div class="col-4">
                    <mat-label>Commande</mat-label>
                    <div class="input-group">
			<mat-select placeholder="Choisir un statut" class="  form-control form-control-sm mb-2"   required
				    [(ngModel)]="selectedOrder" [formControl]="ordersListCtrl"  [compareWith]="compare" (selectionChange)="onOrdersChanged($event)"  >
			    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyOrders($event.target.value)">  
			    <mat-select-trigger>
				{{selectedOrder?.orderNumber  || ''}}
				<span *ngIf="(selectedOrders?.length || 0) > 1" class="example-additional-selection">
				    (+{{(selectedOrders?.length || 0) - 1}} {{selectedOrders?.length === 2 ? 'autre' :
				    'autres'}})
				</span>
			    </mat-select-trigger>
			    <mat-option *ngFor="let order of ordersList" [value]="order">{{order.orderNumber}}</mat-option>
			</mat-select>
                    </div>
                </div>
                <div class="col-4">
                    <mat-label>Gestionnaire</mat-label>
                    <div class="input-group  mb-2">
			<mat-select placeholder="Choisir les utilisateurs pour cette permission" class="  form-control form-control-sm mb-2" [formControl]="profilesListCtrl" multiple required [(ngModel)]="selectedProfiles">
			    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyProfiles($event.target.value)"> 
			    <mat-select-trigger>
				{{profilesListCtrl.value?.[0]?.firstname  || ''}} -  {{profilesListCtrl.value?.[0]?.firstname  || ''}} {{profilesList?.[0]?.lastname  || ''}} 
				<span *ngIf="(profilesList?.length || 0) > 1" class="example-additional-selection">
				    (+{{(selectedProfiles?.length || 0) - 1}} {{selectedProfiles?.length === 2 ? 'autre' :
				    'autres'}})
				</span>
			    </mat-select-trigger>
			    <mat-option *ngFor="let profile of profilesList" [value]="profile">{{profile.user?.username}} - {{profile?.fistname }} {{profile?.lastname}}</mat-option>
			</mat-select>
			<div class="input-group-append">
			    <button type="button" class="btn btn-primary  form-control-sm">
				<!--<mat-icon>add</mat-icon>-->
			    </button>
			</div>
		    </div>

                </div>
            </div>

            <div class="row mt-3">
                <div class="col-4">
                    <mat-label>Méthode d'expédition</mat-label>
                    <div class="input-group">
                    	<mat-select placeholder="Choisir une methode d'expédition" class="  form-control form-control-sm mb-2"   required
				    [(ngModel)]="selectedShippingType" [formControl]="shippingTypesListCtrl"  [compareWith]="compare" (selectionChange)="onShippingTypesChanged($event)"  >
			    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyShippingTypes($event.target.value)">  
			    <mat-select-trigger>
				{{selectedShippingType?.label  || ''}}
				<span *ngIf="(selectedShippingTypes?.length || 0) > 1" class="example-additional-selection">
				    (+{{(selectedShippingTypes?.length || 0) - 1}} {{selectedShippingTypes?.length === 2 ? 'autre' :
				    'autres'}})
				</span>
			    </mat-select-trigger>
			    <mat-option *ngFor="let shippingType of shippingTypesList" [value]="shippingType">{{shippingType.label}}</mat-option>
			</mat-select>
			<div class="input-group-append">
			</div>
                    </div>
		</div>
		
                <div class="col-4">
		    <mat-label>Point relais</mat-label>
		    <div class="input-group">
                    	<mat-select placeholder="Choisir un point de retrait" class="  form-control form-control-sm mb-2"   required
				    [(ngModel)]="selectedLocation" [formControl]="locationsListCtrl"  [compareWith]="compare" (selectionChange)="onLocationsChanged($event)"  >
			    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyLocations($event.target.value)">  
			    <mat-select-trigger>
				{{selectedLocation?.name  || ''}}
				<span *ngIf="(selectedLocations?.length || 0) > 1" class="example-additional-selection">
				    (+{{(selectedLocations?.length || 0) - 1}} {{selectedLocations?.length === 2 ? 'autre' :
				    'autres'}})
				</span>
			    </mat-select-trigger>
			    <mat-option *ngFor="let location of locationsList" [value]="location">{{location.name}}</mat-option>
			</mat-select>
		    </div>
                </div>
                <div class="col-4">
		    <mat-label>Status d"expédition</mat-label>
		    <div class="input-group">
			<mat-select placeholder="Choisir un statut" class="  form-control form-control-sm mb-2"   required
				    [(ngModel)]="selectedStatus" [formControl]="statusesListCtrl"  [compareWith]="compare" (selectionChange)="onStatusesChanged($event)"  >
			    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyStatuses($event.target.value)">  
			    <mat-select-trigger>
				{{selectedStatus?.label  || ''}}
				<span *ngIf="(selectedStatuses?.length || 0) > 1" class="example-additional-selection">
				    (+{{(selectedStatuses?.length || 0) - 1}} {{selectedStatuses?.length === 2 ? 'autre' :
				    'autres'}})
				</span>
			    </mat-select-trigger>
			    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
			</mat-select>
		    </div>
                </div>
	    </div>


	    <div class="row mt-3">
                <div class="col-4">
		    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Date d'expédition</mat-label>
                        <input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker
						>
		    </mat-form-field>
                </div>

                <div class="col-4">
		    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Date de livraison</mat-label>
                        <input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="pickerLivraison">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="pickerLivraison"></mat-datepicker-toggle>
                        <mat-datepicker #pickerLivraison></mat-datepicker>
		    </mat-form-field>
                </div>

                <div class="col-4">
		    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Note</mat-label>
                        <textarea style="height: 100px;" matInput placeholder="Note" required></textarea>
			<app-show-errors [ctrl]="form.get('note')"></app-show-errors>     
		    </mat-form-field>
                </div>
	    </div>



	    <mat-label>Information sur la livraison</mat-label>

	    <div class="row">
                <div class="col-3">
		    <mat-label>Numéro et rue</mat-label>
		    <input matInput #numRue  type="text" placeholder="Ex. 21" formControlName="street"
			   class="form-control form-control-sm" required>
		    <app-show-errors [ctrl]="form.get('street')"></app-show-errors>

                </div>
                <div class="col-3">
		    <mat-label>Ville</mat-label>
		    <input matInput formControlName="city" type="text" class="form-control form-control-sm" required>
		    <app-show-errors [ctrl]="form.get('city')"></app-show-errors>

                </div>
                <div class="col-3">
		    <mat-label>Code Postale</mat-label>
		    <input matInput #postalCode maxlength="5" type="number" placeholder="Ex. 94105"
                           formControlName="zip" class="form-control form-control-sm" required>
		    <mat-hint align="end" style="color: black;">{{postalCode.value.length}} / 5</mat-hint>
		    <app-show-errors [ctrl]="form.get('zip')"></app-show-errors>
                </div>
                <div class="col-3">
		    <mat-select-country appearance="legacy" label="Pays" (onCountrySelected)="onCountrySelected($event)"
					required>
		    </mat-select-country>
                </div>
	    </div>


	    <div class="row">
                <!--<div class="col-3">
		     <mat-label>Livreur</mat-label>
		     <div class="input-group">
                     <mat-select placeholder="Choisir..." class="  form-control form-control-sm"
                     [formControl]="toppings" multiple required>
                     <mat-select-trigger>
                     {{toppings.value?.[0] || ''}}
                     <span *ngIf="(toppings.value?.length || 0) > 1" class="example-additional-selection">
                     (+{{(toppings.value?.length || 0) - 1}} {{toppings.value?.length === 2 ? 'other' :
                     'others'}})
                     </span>
                     </mat-select-trigger>
                     <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                     </mat-select>
		     </div>
		     </div>-->
                <!--<div class="col-3">
		     <mat-label>Numéro de livraison</mat-label>
		     <input matInput #postalCode maxlength="5" type="number" placeholder="Ex. 94105"
                     formControlName="postal" class="form-control form-control-sm" required>
		     </div> -->
                <div class="col-3">
		    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Date de livraison previsionnel</mat-label>
                        <input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="pickerLivraisonPre">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="pickerLivraisonPre"></mat-datepicker-toggle>
                        <mat-datepicker #pickerLivraisonPre></mat-datepicker>
		    </mat-form-field>
                </div>
                <div class="col-3">
		    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Livre le</mat-label>
                        <input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="pickerLivreLe">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="pickerLivreLe"></mat-datepicker-toggle>
                        <mat-datepicker #pickerLivreLe></mat-datepicker>
		    </mat-form-field>
                </div>
	    </div>

	    <div class="example-full-width d-flex justify-content-start">
                <button class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>
	    </div>

        </form>
	

    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
