<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">NOUVELLE MARQUE</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Produits</a></li>
                    <li class="breadcrumb-item active">Marque</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">



        <h3 style="color: black; font-size:medium">Nouvelle marque</h3>
        <h6 style="color: black; font-size: small">Ajouter une marque</h6>
        <form class="example-form" [formGroup]="form" (ngSubmit)="submit()">


            <div class="row">
                <div class="col-6">
                    <mat-label>Nom</mat-label>
                    <input type="text" matInput formControlName="nom" class="form-control form-control-sm mb-2"
                           required>
		    <app-show-errors [ctrl]="form.get('nom')"></app-show-errors> 
                </div>
                <div class="col-6">
                    <mat-label>Description</mat-label>
                    <mat-form-field appearance="outline" class="example-full-width mb-1">

                        <textarea style="height: 100px;" matInput formControlName="description"  placeholder="Description" required></textarea>
			<app-show-errors [ctrl]="form.get('description')"></app-show-errors> 
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <mat-form-field appearance="outline" class="example-full-width mb-2">
                        <mat-label>Note</mat-label>
                        <textarea style="height: 100px;" matInput placeholder="Note" formControlName="note"  required></textarea>
			 <app-show-errors [ctrl]="form.get('note')"></app-show-errors> 
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <mat-label>Produits</mat-label>
                    <div class="input-group">
			<mat-select placeholder="Choisir les produits pour cette marque" class="  form-control form-control-sm mb-2" [formControl]="productsListCtrl" multiple required [(ngModel)]="selectedProducts">
			    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyProducts($event.target.value)"> 
			    <mat-select-trigger>
                        {{productsListCtrl.value?.[0]?.name  || ''}} 
				<span *ngIf="(productsList?.length || 0) > 1" class="example-additional-selection">
				    (+{{(selectedProducts?.length || 0) - 1}} {{selectedProducts?.length === 2 ? 'autre' :
				    'autres'}})
				</span>
			    </mat-select-trigger>
			    <mat-option *ngFor="let product of productsList" [value]="product">{{product.name}} </mat-option>
			</mat-select>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-primary  form-control-sm">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="example-full-width d-flex justify-content-center" *ngIf="DisplayConfirmationButton">
                <button class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>
                <!-- <button mat-raised-button cdkFocusInitial>Annuler</button> -->
            </div>



        </form>




    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
