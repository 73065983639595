import { Country } from '@angular-material-extensions/select-country';
import { Component, OnInit, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { AddPermissionDialogComponent } from 'src/app/Dialog/add-permission-dialog/add-permission-dialog.component';
import { AddRoleDialogComponent } from 'src/app/Dialog/add-role-dialog/add-role-dialog.component';
import { Address } from 'src/app/interfaces/address';


import { UserService } from 'src/app/services/user.service';
import { ProfileService } from 'src/app/services/profile.service';
import { RolesService } from 'src/app/services/roles.service';
import { PermissionService } from 'src/app/services/permission.service';
import { StatusService } from 'src/app/services/status.service';

import { Role } from 'src/app/interfaces/role';
import { Permission } from 'src/app/interfaces/permission';
import { Status } from 'src/app/interfaces/status';

import { Profil } from 'src/app/interfaces/profil';
import { ProfilDto } from 'src/app/interfaces/profil-dto';

import { UserModel } from 'src/app/interfaces/user';
import { Response } from '../../interfaces/response';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-editer-utilisateur',
  templateUrl: './editer-utilisateur.component.html',
  styleUrls: ['./editer-utilisateur.component.scss'], encapsulation: ViewEncapsulation.None
})
export class EditerUtilisateurComponent implements OnInit {

    ////////////////////////
    // Attributes
    ///////////////////////
    
    @Input()     _profile: Profil;
    @Input('id') _id?: string;

    
    form: FormGroup;
    role: any;
    permission: any;
    selectedCountry: Country = undefined;
    toppings = new FormControl('');
    
    toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

    

    roles = new FormControl('');
    rolesList : Role[];//string[];
    rolesData : Role[];
    selectedRoles : Role[];

    permissions = new FormControl('');
    permissionsList : Permission[];//string[];
    permissionsData : Permission[];      
    selectedPermissions : Permission[];

    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatuses : Status[];
    selectedStatus : Status;
    
    ////////////////////////
    // Constructor
    ///////////////////////

    
  
    constructor(private router: Router,
		private route: ActivatedRoute,
		private roleService: RolesService,
		private userService: UserService,
		private permissionService: PermissionService,
		private profileService : ProfileService,
		private statusService : StatusService,
		
      private _snackBar: MatSnackBar,
      public dialog: MatDialog,
      public fb: FormBuilder) { 

      
      }

    ////////////////////////
    // init
    ///////////////////////

    ngOnInit(): void {
	
	this.initCurrentRoute();   
	this.refresh();
	this.initForm();
	
	
    }

    initCurrentRoute():void {
	this.route.params.subscribe(params => {
	    this._id = params["id"];
	    console.log("current id " + this._id)
	});
    }

    
    initCurrentObject():void {
	this.profileService.getById(this._id).subscribe(
	  {
              next: (response: Response<number, Profil>) => {
		  console.log(response);
		  this._profile = response.Data;
		  this.selectedRoles = this._profile?.user?.roles;
		  this.selectedPermissions = this._profile.user?.permissions;
		  if (this._profile?.status)
		      this.selectedStatus = this._profile?.status ;
		  this.selectedCountry.name = this._profile?.address?.country;
		  console.log(this._profile);
		  
              },
              error: (e) => {
		  console.log(e);
              },
              complete: () => console.info('loaduser complete')
	  }
      );

    }
    

    
    ////////////////////////
    // Datasources 
    //////////////////////// 

    
    refresh():void {
	
	this.initCurrentObject();

	this.roleService.getAllRoles().subscribe({
            next: (response: Response<number, Role[]>) => {
		this.rolesData = response.Data;
		this.initRolesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load roles complete')
	});

	this.permissionService.getAllPermissions().subscribe({
	    next: (response: Response<number, Permission[]>) => {
		this.permissionsData = response.Data;
		this.initPermissionsSearchDropdown();  
	    },error: (e) => { }, complete: () => console.info('load permissions complete')
	});

	this.statusService.getAllStatuses().subscribe({
	    next: (response: Response<number, Status[]>) => {
		
		this.statusesData = response.Data;
		this.initStatusesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load statuses complete')
	});
	

    }

    ////////////////////////
    // Data source FORM
    //////////////////////// 

    initForm() : void { 
        this.form = this.fb.group({
	    nom: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    prenom: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    phone: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$"), Validators.minLength(10)]),
	    email: new FormControl(null, [Validators.required, Validators.email]),
	    password: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
	    passwordcheck: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
	    //street: new FormControl(null, [Validators.required, Validators.maxLength(5), Validators.pattern("^[0-9]*$"), Validators.minLength(1)]),
	    street: new FormControl(null, [Validators.required, Validators.maxLength(60), Validators.minLength(2)]),
	    city: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    postal: new FormControl(null, [Validators.required, Validators.maxLength(5),  Validators.pattern("^[0-9]*$"), Validators.minLength(5)]),
	    country: new FormControl(''),
	});




    }




    ////////////////////////
    // Datasources functions
    ////////////////////////
    
    compare(o1: any, o2: any): boolean {
	// if possible compare by object's name, and not by reference.
	return o1 && o2 ? o1.name === o2.name : o2 === o2;
    }
    
    
    onCountrySelected($event: Country) {
	this.selectedCountry = $event;
	console.log($event);
    }


        ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns
    
    initPermissionsSearchDropdown(){
	this.permissionsList = [];
        for ( let i = 0 ; i < this.permissionsData.length; i ++ ) {
            this.permissionsList.push( this.permissionsData[i] );
        }
    }

    initRolesSearchDropdown(){
	this.rolesList = [];
        for ( let i = 0 ; i < this.rolesData.length; i ++ ) {
            this.rolesList.push( this.rolesData[i] );
        }
    }

    initStatusesSearchDropdown(){
	this.statusesList = [];
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
	    {

		if (!this.selectedStatus && this.statusesData[i].label == "Actif")
		{
		    this.selectedStatuses = [];
		    this.selectedStatus = this.statusesData[i];
		    
		    this.selectedStatuses.push(this.statusesData[i]);
		    //this.onStatusesChanged(this.selectedStatus);
		    //console.log("selectedd status");
		    //console.log(this.selectedStatus);
		}
		this.statusesList.push( this.statusesData[i] );
	    }
        }

    }
    
    ////////////////////////
    // On change

    onStatusesChanged($event: any) {
      console.log($event);
    }
    
    onPermissionsChanged($event: any) {
      console.log($event);
    }

    onRolesChanged($event: any) {
      console.log($event);
    }

    ////////////////////////
    // On Search


    onKeyRoles(value) { 
            this.rolesList= []; 
            this.selectRoleSearch(value);       
    }

    onKeyPermissions(value) { 
            this.permissionsList= []; 
            this.selectPermissionsSearch(value);       
    }

    onKeyStatuses(value) { 
            this.statusesList= []; 
            this.selectStatusesSearch(value);       
    }

    ////////////////////////
    // search elements

    selectPermissionsSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.permissionsData.length; i ++ ) {
            let option = this.permissionsData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.permissionsList?.push( option );
            }
        }
    }


    selectStatusesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
            let option = this.statusesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.statusesList?.push( option );
            }
        }
    }


    selectRoleSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.rolesData.length; i ++ ) {
            let option = this.rolesData[i];
            if (  option.name?.toLowerCase().indexOf(filter) >= 0
		|| option.label?.toLowerCase().indexOf(filter) >= 0 ) {
                this.rolesList?.push( option );
            }
        }
    }


    ////////////////////////
    ///////////////////////
    
    

    ////////////////////////
    // Actions External 
    ////////////////////////
    
  addNewRole(): void {
   // event.stopPropagation();
    const dialogRef = this.dialog.open(AddRoleDialogComponent, {
      disableClose: true,
      width: '800px',
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {

      }
    });
  }

  addNewPermission(): void {
 //   event.stopPropagation();
    const dialogRef = this.dialog.open(AddPermissionDialogComponent, {
      disableClose: true,
      width: '800px',
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {

      }
    });
  }

    ////////////////////////
    // Actions
    ///////////////////////

    
    // Actions Submit  
    ////////////////////////

    submit() {
    if (!this.form.valid) {
      return;
    }
    if (this.form.value.password !== this.form.value.passwordcheck) {
      this._snackBar.open('Les 2 mots de passe ne correspondent pas.', '', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    /*} else if (this.role === undefined || this.permission === undefined) {
      this._snackBar.open('Veuillez choisir un role et une permission', '', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });*/
    } else if (this.selectedCountry === undefined) {
      this._snackBar.open('Veuillez choisir un pays', '', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    } else {
      const profil: Profil = {
     //   id:'',
        firstname: this.form.value.nom,
        lastname: this.form.value.prenom,
        email: this.form.value.email,
          phoneNumber: this.form.value.phone,
	  userId: this._profile.userId,
	  addressId: this._profile.addressId
      };
	
      const address: Address = {
       // id: '',
        country:  this.selectedCountry.name,
          street: this.form.value.street.toString(),
          city: this.form.value.city,
          zip: this.form.value.postal.toString(),
        additionalDetail: ''
      };
      const profilDto: ProfilDto = {
        Profil: profil,
        Address: address
      };

	const user: UserModel = {
	    username : this.form.value.email, //username,
	    password :this.form.value.password 
	};


	if (this._profile.user?.id)
	    user.id = this._profile.user.id.toString();
	else if (this._profile.userId)
	    user.id = this._profile.userId;
	if (this._profile.address?.id)
	    address.id = this._profile.address.id.toString();
	else if (this._profile.addressId)
	    address.id = this._profile.addressId;

	if (this.selectedStatus)
	    this._profile.statusId = this.selectedStatus.id;
	
	user.permissions = this.selectedPermissions;
	user.roles = this.selectedRoles;
	this._profile.address = address;
	this._profile.user = user;
	
	//this.profileService.prepareRelations(profil).subscribe(v => console.log(v));

	
	this.profileService.prepareUpdateRelations(this._profile).pipe(
	    switchMap(preparedProfileObservable => { 
		const profilePrepared = preparedProfileObservable.Data;
		console.log(profilePrepared);
		return this.profileService.updateById(this._id, profilePrepared); 
	    })
	).subscribe({
	    next: (res) => { this.checkSubmitSucess(res); },
	    error: (error) => { this.submitError(error)},
	    complete: () => {
		console.info('save profile pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();
		    }, 5000);
		
	    }
	    
	    
	});


    }
  }

        ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
      this.router.navigate([environment.paths.ListAccountPath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
  }
    
}
