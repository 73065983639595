import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, from,  map,  concatMap, switchMap, Observable, exhaustMap, EMPTY, empty ,identity, forkJoin} from 'rxjs';  

import { environment } from 'src/environments/environment';

// Services 
import {NoteService} from '../services/note.service'
import {FileService} from '../services/file.service'

// models   
import { School } from '../interfaces/school';
import { Address as Address } from '../interfaces/address';
import { ClassLevel } from '../interfaces/class-level';
import { ClassGrade } from '../interfaces/class-grade';

import { Product } from '../interfaces/product';

import { Note } from '../interfaces/note';
import { File } from '../interfaces/file';
import { User } from '../interfaces/user';
import { Profil as Profile } from '../interfaces/profil';

import { Order } from '../interfaces/order';

// response 
import { Response as Rep } from '../interfaces/response';

import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"

@Injectable({
  providedIn: 'root'
})
export class OrderService extends BaseCrudModel<Order>{
    postProducts = {url: `${environment.domains.api.url}/order-details/all`, urlOptions: this.options};
    deleteProducts = {url: `${environment.domains.api.url}/order-details`, urlOptions: this.options};
    //postProducts = {url: `${environment.domains.api.url}/item-types`, urlOptions: this.options};
    //deleteProducts = {url: `${environment.domains.api.url}/item-types`, urlOptions: this.options};       
    
    constructor(protected override httpService: HttpService,
		private fileService : FileService,
		private noteService : NoteService) {
	super(httpService, "orders");

	var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "objectDetail"},
		{"relation" : "note"},
		{"relation" : "customer", "scope" : {"include" : [
		    {"relation" : "profile", "scope" : {"include" : [{"relation" : "user"}]}},
		    {"relation" : "company"}]}
		},
		{"relation" : "paymentMean"},
		{"relation" : "shipping" ,  "scope" :
		 {"include" : [
		     {"relation" : "status" } , {"relation" : "shippingStatus" } ,
		     {"relation" : "delivery", "scope" : {"include" : [{"relation" : "address"}]}},
		     {"relation" : "relayPoint" , "scope" : {"include" : [{"relation" : "address"}]}}
		 ]}},
		{"relation" : "paymentStatus"},
		{"relation" : "payment"},
		{"relation" : "orderDetails",
		 "scope": {"include" : [
		     {"relation" : "product", "scope" : {"include" : [{"relation" : "mainImage"}]}},
		     {"relation" : "location"}
		 ]}},
		{"relation" : "manager"}

	    ],
	});

	var addOptions =  RequestOptions.FromArray({
	    relationsToOmit : ['note',  'paymentMean', 'paymentStatus',
			       'status', 'payment', 'order-details',
			       'manager']
	});

	this.methods.listing.urlOptions = options;
	this.methods.view.urlOptions = options;
	this.methods.add.urlOptions = addOptions;
	this.methods.update.urlOptions = addOptions;
    }

    getAllOrders(): Observable<Rep<number, Order[]>> {
	const options = new RequestOptions();

	return this.getAll()
//	return this.httpService.makeGetRequest<Order[]>( `${environment.domains.profil.GetAllOrders}`, options);
    }
}
