import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import {ActivatedRoute} from '@angular/router';   

import { BrandService } from 'src/app/services/brand.service';
import { Brand } from 'src/app/interfaces/brand';
import { Response } from '../../interfaces/response';



@Component({
  selector: 'app-consulter-brand',
  templateUrl: './consulter-brand.component.html',
  styleUrls: ['./consulter-brand.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ConsulterBrandComponent  implements OnInit  {
  @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();

    ////////////////////////
    // Attributes
    ////////////////////////
    @Input()     _brand: Brand;
    @Input('id') _id?: string;

    ////////////////////////
    // Constructor
    ////////////////////////
    constructor(private router: Router,
		private route: ActivatedRoute,
		private brandService: BrandService) {
    }


    ////////////////////////
    // Init
    ////////////////////////

    ngOnInit(): void {
	this.initCurrentRoute();
	this.refresh();
    }
    
    initCurrentRoute():void {
	this.route.params.subscribe(params => {
            this._id = params["id"];
	    console.log("current id " + this._id)
        });


    }

    
    initCurrentObject(): void {
	this.brandService.getById(this._id).subscribe(
	    {
		next: (response: Response<number, Brand>) => {
		    console.log(response);
		    this._brand = response.Data;
	      
	      //this.datasource.data = this.brand;
              //this.datasource.paginator = this.paginator;

		    console.log(this._brand);

	      // this.refresh();
		},
		error: (e) => {
		    console.log(e);
		},
		complete: () => console.info('loaduser complete')
	    });
    }


    

    ////////////////////////
    // Datasources
    ////////////////////////
    
    refresh() : void {
	this.initCurrentObject();	

    }
    
    ////////////////////////
    // Actions
    ////////////////////////
    onEditBrand(id): void {
      this.router.navigate([environment.paths.EditerBrandPath, id]);
    }
}
