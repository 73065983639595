<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">EDITER UTILISATEUR</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Contacts</a></li>
                    <li class="breadcrumb-item active">User List</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">

        <mat-label>Editer un profil</mat-label>
        <form class="example-form mt-2" [formGroup]="form" (ngSubmit)="submit()" *ngIf="_profile">


            <div class="row mb-3">

                <div class="col">
                    <mat-label>Nom</mat-label>
                    <input class="form-control form-control-sm" matInput type="text" formControlName="nom" [(ngModel)]="_profile.firstname" required>
		    <app-show-errors [ctrl]="form.get('nom')"></app-show-errors>
                </div>
                <div class="col">
                    <mat-label>Prénom</mat-label>
                    <input formControlName="prenom" [(ngModel)]="_profile.lastname" class="form-control form-control-sm" matInput type="text" required>
		    <app-show-errors [ctrl]="form.get('prenom')"></app-show-errors>
                </div>
                <div class="col">
                    <mat-label>Téléphone</mat-label>
                    <input matInput formControlName="phone" [(ngModel)]="_profile.phoneNumber" class="form-control form-control-sm" type="text" required>
		    <app-show-errors [ctrl]="form.get('phone')"></app-show-errors>
                </div>
                <div class="col">
                    <mat-label>Email</mat-label>
                    <input type="email" [(ngModel)]="_profile.email" matInput formControlName="email" class="form-control form-control-sm" required>
		    <app-show-errors [ctrl]="form.get('email')"></app-show-errors>
                </div>
            </div>



            <div class="row mb-3">
                <div class="col-4">
                    <mat-label>Mot de passe </mat-label>
                    <input type="password"
			   [ngModel]="_profile?.user?.password"
			   (ngModelChange)="_profile.user.password = $event"
			   matInput formControlName="password" class="form-control form-control-sm"
                        required>
                </div>
                <div class="col-4">
                    <mat-label>Vérification du mot de passe</mat-label>
                    <input type="passwordCheck"
			   
			   (ngModelChange)="_profile.user.password = $event"
			   matInput formControlName="passwordcheck" class="form-control form-control-sm"
                        required>
                </div>
            </div>


            <div class="row">

                <div class="col-3">
                    <mat-label>Rue</mat-label>
                    <input matInput #numRue maxlength="60" type="text" placeholder="Ex. 21" formControlName="street"
			   [ngModel]="_profile && _profile?.address && _profile?.address?.street"
			   (ngModelChange)="_profile &&  _profile.address && _profile.address.street = $event"
                        class="form-control form-control-sm" required>
                    <!--<mat-hint align="end" style="color: black;">{{numRue.value.length}} / 5</mat-hint>-->
		    <app-show-errors [ctrl]="form.get('street')"></app-show-errors>
                </div>
                <div class="col-2">
                    <mat-label>Ville</mat-label>
                    <input matInput
			   [ngModel]="_profile && _profile?.address && _profile?.address?.city"
			   (ngModelChange)="_profile &&  _profile.address && _profile.address.city = $event"
			   formControlName="city" type="text" class="form-control form-control-sm" required>
		    <app-show-errors [ctrl]="form.get('city')"></app-show-errors>
                </div>
                <div class="col-2">
                    <mat-label>Code Postale</mat-label>
                    <input matInput #postalCode maxlength="5" type="number" placeholder="Ex. 94105"
			   [ngModel]="_profile && _profile?.address &&_profile?.address?.zip"
			   (ngModelChange)="_profile &&  _profile.address && _profile.address.zip = $event"
			   formControlName="postal" class="form-control form-control-sm" required>
                    <mat-hint align="end" style="color: black;">{{postalCode.value.length}} / 5</mat-hint>
		    <app-show-errors [ctrl]="form.get('zip')"></app-show-errors>
                </div>
                <div class="col-2">
                    <mat-select-country name="country" formControlName="country" appearance="outline"
					[ngModel]="_profile && _profile?.address && _profile?.address?.country"
					(ngModelChange)="_profile && _profile?.address && _profile.address.country = $event"
					label="Country" 
					(onCountrySelected)="onCountrySelected($event)" required>
                    </mat-select-country>
                </div>
            </div>



            <div class="row no-gutters mt-3">
                <div class="col-6 ">
                        <mat-label>Role</mat-label>
                        <div class="input-group">
                            <mat-select (selectionChange)="onRolesChanged($event)"
					class="  form-control form-control-sm mb-2" [formControl]="roles" 
					[(ngModel)]="selectedRoles"
					[compareWith]="compare"
					multiple required>
				<input class="mat-select-input-search" placeholder ="recherche " (keyup)="onKeyRoles($event.target.value)"> 
                                <mat-select-trigger>
                                    {{roles.value?.[0]?.name || ''}}
                                    <span *ngIf="(roles.value?.length || 0) > 1" class="example-additional-selection">
                                        (+{{(roles.value?.length || 0) - 1}} {{roles.value?.length === 2 ? 'other' :
                                        'others'}})
                                    </span>
                                </mat-select-trigger>
                                <mat-option *ngFor="let currentRole of rolesList" [value]="currentRole">{{currentRole.name}}</mat-option>
                            </mat-select>
                            <div class="input-group-append">
                              <button type="button"class="btn btn-primary  form-control-sm" (click)="addNewRole()">
                                <mat-icon>add</mat-icon>
                              </button>
                            </div>
                          </div>
                </div>
            </div>


            <div class="row no-gutters">
                <div class="col-6 ">
                    <mat-label>Permissions</mat-label>
                    <div class="input-group">
                        <mat-select (selectionChange)="onPermissionsChanged($event)"
				    class="  form-control form-control-sm mb-2"
				    [formControl]="permissions"
				    [(ngModel)]="selectedPermissions"
				    [compareWith]="compare"  
				    multiple required>
			    <input class="mat-select-input-search" placeholder ="recherche " (keyup)="onKeyPermissions($event.target.value)"> 
                            <mat-select-trigger>
                                {{permissions.value?.[0]?.label || ''}}
                                <span *ngIf="(permissions.value?.length || 0) > 1" class="example-additional-selection">
                                    (+{{(permissions.value?.length || 0) - 1}} {{permissions.value?.length === 2 ? 'other' :
                                    'others'}})
                                </span>
                            </mat-select-trigger>
                            <mat-option *ngFor="let permission of permissionsList" [value]="permission">{{permission.label}}</mat-option>
                        </mat-select>
                        <div class="input-group-append">
                          <button type="button"class="btn btn-primary  form-control-sm" (click)="addNewPermission()">
                            <mat-icon>add</mat-icon>
                          </button>
                        </div>
                      </div>
                </div>

            </div>

	    <div class="row no-gutters">
		<div class="col-6 ">   
	            <mat-label>Status</mat-label>
		    <div class="input-group">
			<mat-select placeholder="Choisir un statut pour ce profil" class="  form-control form-control-sm mb-2"   required
			    [(ngModel)]="selectedStatus"   [formControl]="statusesListCtrl"  [compareWith]="compare" (selectionChange)="OnStatusesChanged($event)"  >
			<input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyStatuses($event.target.value)">  
                      <mat-select-trigger>
                        {{statusesListCtrl.value?.label  || ''}}
                        <span *ngIf="(selectedStatuses?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedStatuses?.length || 0) - 1}} {{selectedStatuses?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
		    </mat-select>

		    </div>
		</div>
	    </div>
	    

            <div class="mt-3">
                <label class="form-check-label" style="color: black; font-weight: 300;" for="rememberPasswordCheck">
                    Compte est crée pour un client ?
                </label>
                <mat-checkbox>
                </mat-checkbox>
            </div>

            <button [disabled]="form.invalid" class="mt-3 btn btn-primary" type="submit">Sauvegarder</button>

        </form>




    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
