import { Component, EventEmitter, Input,  Output, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';

import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { Note } from 'src/app/interfaces/note';
import { Status } from 'src/app/interfaces/status';
import { ProductType } from 'src/app/interfaces/product-type';
import { Product  } from 'src/app/interfaces/product';
import { ProductCategory  } from 'src/app/interfaces/product-category';

import { ProductTypeService } from 'src/app/services/product-type.service';
import { StatusService } from 'src/app/services/status.service';
import { ProductService } from 'src/app/services/product.service';
import { ProductCategoryService } from 'src/app/services/product-category.service';


import { Response } from '../../interfaces/response';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-editer-product-type',
  templateUrl: './editer-product-type.component.html',
  styleUrls: ['./editer-product-type.component.scss'], encapsulation: ViewEncapsulation.None
})
export class EditerProductTypeComponent  implements OnInit  {
  @Input() TitleVisible: boolean = true;
  @Input() DisplayConfirmationButton: boolean = true;

    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();

    ////////////////////////
    //  Attributes     
    //////////////////////// 

    @Input()     _productType: ProductType;
    @Input('id') _id?: string;
    
    form: FormGroup;


    productsListCtrl = new FormControl('');
    productsData : Product[];//string[];
    productsList : Product[];//string[];
    selectedProducts : Product[];

    
    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatuses : Status[];
    selectedStatus : Status;

    productCategoriesListCtrl = new FormControl('');
    productCategoriesList : ProductCategory[];//string[];
    productCategoriesData : ProductCategory[];//string[];
    selectedProductCategories : ProductCategory[];
    selectedProductCategory : ProductCategory;

    ////////////////////////
    // Constructor
    ////////////////////////
  
    constructor(private router: Router,
		private route: ActivatedRoute,
		private productTypeService : ProductTypeService,
		private statusService : StatusService,
		private productService : ProductService,
		private productCategoryService : ProductCategoryService,
		
		private _snackBar: MatSnackBar,
		public fb: FormBuilder) { 
 
      }

        ////////////////////////
    // Init
    ////////////////////////  
    ngOnInit(): void {
	this.initCurrentRoute();
	this.refresh();
	this.initForm(); 

    }

    initCurrentRoute():void {
	this.route.params.subscribe(params => {  
	    this._id = params["id"];
	    console.log("current id " + this._id)
	});

    }

    initCurrentObject(): void {
	
      this.productTypeService.getById(this._id).subscribe(
	  {
              next: (response: Response<number, ProductType>) => {
		  console.log("response");
		  console.log(response);
		  this._productType = response.Data;
		  this.selectedProducts = this._productType?.products;
		  if (this._productType?.status)
		      this.selectedStatus = this._productType?.status ;
		  if (this._productType?.familyType)
		      this.selectedProductCategory = this._productType?.familyType ;
		  if (this._productType?.note) {
		      
		  } else {
		      const note : Note = {  content :  "" };
		      this._productType.note = note;
		  }
		  console.log(this._productType.status);
		  console.log(this._productType);
		  
              },
              error: (e) => {
		  console.log(e);
              },
              complete: () => console.info('loaduser complete')
	  }
      );

    }



    ////////////////////////
    // Datasrouces
    ///////////////////////


    
    refresh(): void {
	this.initCurrentObject();
	
	this.statusService.getAllStatuses().subscribe({
	    next: (response: Response<number, Status[]>) => {
		
		this.statusesData = response.Data;
		this.initStatusesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load statuses complete')
	});

	this.productCategoryService.getAllProductCategories().subscribe({
	    next: (response: Response<number, ProductCategory[]>) => {
		
		this.productCategoriesData = response.Data;
		this.initProductCategoriesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load productCategories complete')
	});

	this.productService.getAllProducts().subscribe({
            next: (response: Response<number, Product[]>) => {
		this.productsData = response.Data;
		this.initProductsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load products complete')
	});

    }

    ////////////////////////
    // Datasrouces Form
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    name: new FormControl(null, [Validators.required, Validators.maxLength(60), Validators.minLength(2)]),
	    description: new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(2)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
	});

    }
    
    ////////////////////////
    // Datasources Functions
    ///////////////////////

    

    compareFn(c1,c2): boolean {
	return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }
    
    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns


    initProductsSearchDropdown(){
	this.productsList = [];
        for ( let i = 0 ; i < this.productsData.length; i ++ ) {
            this.productsList.push( this.productsData[i] );
        }
    }


    initStatusesSearchDropdown(){
	this.statusesList = [];
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
	    {

		if (!this.selectedStatus && this.statusesData[i].label == "Actif")
		{
		    this.selectedStatuses = [];
		    this.selectedStatus = this.statusesData[i];
		    
		    this.selectedStatuses.push(this.statusesData[i]);
		}
		this.statusesList.push( this.statusesData[i] );
	    }
        }

    }

    initProductCategoriesSearchDropdown(){
	this.productCategoriesList = [];
        for ( let i = 0 ; i < this.productCategoriesData.length; i ++ ) {
	    {
		this.productCategoriesList.push( this.productCategoriesData[i] );
	    }
        }

    }

    ////////////////////////
    // On change

    onStatusesChanged($event: any) {
      console.log($event);
    }

    onProductCategoriesChanged($event: any) {
	console.log($event);
    }

    onProductsChanged($event: any) {
      console.log($event);
    }


    ////////////////////////
    // On Search
    onKeyProducts(value) { 
            this.productsList= []; 
            this.selectProductSearch(value);       
    }



    onKeyStatuses(value) { 
            this.statusesList= []; 
            this.selectStatusesSearch(value);       
    }

    onKeyProductCategories(value) { 
            this.productCategoriesList= []; 
            this.selectProductCategoriesSearch(value);       
    }

    ////////////////////////
    // search elements


    selectProductSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.productsData.length; i ++ ) {
            let option = this.productsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0
		|| option.name?.toLowerCase().indexOf(filter) >= 0 ) {
                this.productsList?.push( option );
            }
        }
    }

    
    selectStatusesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
            let option = this.statusesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.statusesList?.push( option );
            }
        }
    }



    selectProductCategoriesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.productCategoriesData.length; i ++ ) {
            let option = this.productCategoriesData[i];
            if (    option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.productCategoriesList?.push( option );
            }
        }
    }




    ////////////////////////
    ///////////////////////
    
    
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid) {
	    return;
	}

	let productType : ProductType = {
            name: this.form.value.name,
	    description: this.form.value.description,
	};


	const note : Note = {
	    content : this.form.value.note
	}

	if ((this._productType.note &&  this._productType.note.id ) || this._productType.noteId ){
	    note.id =  this._productType.note && this._productType.note.id ?  this._productType.note.id :  this._productType.noteId ; 
	}
	
	if (this.selectedStatus){
	    this._productType.statusId = this.selectedStatus.id;
	}

	if (this.selectedProductCategory){
	    this._productType.familyTypeId = this.selectedProductCategory.id;
	}


	this._productType.note = note;
	this._productType.products = this.selectedProducts;
		
	console.log(this._productType);
	console.log("gonna save");
	console.log(this.selectedProducts);
	

	this.productTypeService.prepareUpdateRelations(this._productType).pipe(
	    switchMap(preparedProductType => { 
		console.log("entered switch map -- add new productType ");
		console.log(preparedProductType);
		return this.productTypeService.updateById(this._productType.id, this._productType);
	    }),
	    switchMap(productTypeUpdatedObservable => {
		let productTypeUpdated = productTypeUpdatedObservable.Data;
		    
		console.log("entered switch map -- handle post relations ");
		console.log(this._productType);
		this._productType.products = this.selectedProducts;
		return this.productTypeService.handlePostUpdateRelations(this._productType); 
	    })
	
						      
	).subscribe({
	    next: (res) => { this.checkSubmitSucess(res);},
	    error: (error) => {this.submitError(error)},
	    complete: () => {
		console.info('save productType pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();
		    }, 5000);
		
	    }
	    
	    
	});

    }

    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListProductTypePath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }
}
