import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';


import { Note } from 'src/app/interfaces/note';
import { Payment } from 'src/app/interfaces/payment';
import { PaymentType } from 'src/app/interfaces/payment-type';
import { Status } from 'src/app/interfaces/status';

import { PaymentService } from 'src/app/services/payment.service';
import { PaymentTypeService } from 'src/app/services/payment-type.service';
import { StatusService } from 'src/app/services/status.service';

import { Response } from '../../interfaces/response';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-new-methode-payment',
  templateUrl: './new-methode-payment.component.html',
  styleUrls: ['./new-methode-payment.component.scss'], encapsulation: ViewEncapsulation.None
})
export class NewMethodePaymentComponent implements OnInit {
    @Input() TitleVisible: boolean = true;
    @Input() DisplayConfirmationButton: boolean = true;

    form: FormGroup;

    ////////////////////////
    // Attributes 
    ////////////////////////  

    paymentsListCtrl = new FormControl('');
    paymentsList : Payment[];//string[];
    paymentsData : Payment[];//string[];
    selectedPayments : Payment[];

    ////////////////////////
    // Constructor 
    ////////////////////////
    
    constructor(private router: Router,
		private _snackBar: MatSnackBar,
		public fb: FormBuilder,

		private paymentTypeService: PaymentTypeService,
		private paymentService : PaymentService) { 
 
      }
      

    ////////////////////////
    // Init
    ///////////////////////
    
    ngOnInit(): void {

	
	this.refresh();
	this.initForm();
    }

    ////////////////////////
    // Datasrouces
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    name: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    label: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    description: new FormControl(null, [Validators.required, Validators.maxLength(150), Validators.minLength(2)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
    });
    }

    ////////////////////////
    // Datasrouces
    ///////////////////////

    refresh(): void {
	
	this.paymentService.getAllPayments().subscribe({
            next: (response: Response<number, Payment[]>) => {
		this.paymentsData = response.Data;
		this.initPaymentsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load payments complete')
	});



    }

    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns
    

    
    initPaymentsSearchDropdown(){
	this.paymentsList = [];
        for ( let i = 0 ; i < this.paymentsData.length; i ++ ) {
            this.paymentsList.push( this.paymentsData[i] );
        }
    }

    
    ////////////////////////
    // On Search
    
    onKeyPayments(value) { 
            this.paymentsList= []; 
            this.selectPaymentsearch(value);       
    }


    ////////////////////////
    // search elements


    selectPaymentsearch(value:string){
        let filter = value.toLowerCase();
/*        for ( let i = 0 ; i < this.paymentsData.length; i ++ ) {
            let option = this.paymentsData[i];
            if (  option.paymentNumber.toLowerCase().indexOf(filter) >= 0
		|| option.paymentNumber?.toLowerCase().indexOf(filter) >= 0  ) {
                this.paymentsList?.push( option );
            }
        }
*/
    }

    
    ////////////////////////////
    // elements selections
    ///////////////////////////

    OnPaymentsChanged($event: any) {
	console.log($event);
    }

    ////////////////////////////
    // External actions
    ///////////////////////////
    
    
    ////////////////////////
    ///////////////////////
    
    
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid) {
	    return;
	}

	
	let paymentType : PaymentType = {
	    name: this.form.value.name,
	    label: this.form.value.label,
	    description: this.form.value.description,
	};


	const note : Note = {
	    content : this.form.value.note
	}



	paymentType.note = note;
	if (this.selectedPayments && this.selectedPayments.length > 0)
	    paymentType.payments = this.selectedPayments;
	

	console.log(paymentType);
	console.log("gonna save");

	this.paymentTypeService.prepareAddRelations(paymentType).pipe(
	    switchMap(preparedPaymentType => { 
		console.log("entered switch map -- add new paymentType ");
		console.log(preparedPaymentType);
		return this.paymentTypeService.add(paymentType);}),
	    switchMap(paymentTypeAddedObservable => {
		let paymentTypeAdded = paymentTypeAddedObservable.Data;
		paymentTypeAdded.payments = this.selectedPayments;
		console.log("entered switch map -- handle post relations ");
		console.log(paymentTypeAdded);
		return this.paymentTypeService.handlePostAddRelations(paymentTypeAdded); 
	    })
	
						      
	).subscribe({
	    next: (res) => {  this.checkSubmitSucess(res);},
	    error: (error) => { this.submitError(error) },
	    complete: () => {
		console.info('save payments pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();  
		    }, 5000);
		
	    }
	    
	    
	});

    }

    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListMethodePaymentsPath]);
	
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }
}
