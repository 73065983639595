// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

var url = "https://api-ofp.innogen.fr/";


export const environment = {
  production: false,
    domains: {
	api: {url : `${url}`},
    menu: {
      GetMenu: 'http://localhost:4200/api/Menu/GetMenu',
      GetMenuHeader: 'http://localhost:4200/api/Menu/GetMenuHeaders'
    },
	defaultValues: {
	    defaultStatus: '5d38f405732eab7c0aa3033a',
    },
    profil: {
//	GetAllProfil:'http://localhost:4200/api/Profil/GetAllProfil'
	
	GetAllRoles:`${url}/roles`,
	GetAllPermissions:`${url}/permissions`,
	GetAllModules:`${url}/modules`,
	
	GetAllProfil:`${url}/profiles`,
	GetAllUsers:`${url}/users`,
	GetAllCustomers:`${url}/customers`,



	GetAllStatuses:`${url}/statuses`,
	GetAllBrands:`${url}/brands`,
	GetAllVouchers:`${url}/vouchers`,  
    }
  },
  paths: {
    ListAccountPath: 'list/accounts',
    ListRolePath: 'list/roles',
    ListPermissionPath: 'list/permissions',
    ListNiveauScolairePath: 'list/niveaux-scolaires',
    ListEcolePath: 'list/ecoles',
    ListProductPath: 'list/products',
    ListClassPath: 'list/classes',
    ListListScolairePath: 'list/listes-scolaires',
    ListOrderPath: 'list/orders',
    ListCategoryPath: 'list/categories',
    ListBrandPath: 'list/brands',
    ListProductTypePath: 'list/products-types',
      ListCustomerPath: 'list/customers',
      ListCompaniesPath: 'list/companies',
      ListCompanyGroupsPath: 'list/groups',
    ListExpeditionPath: 'list/expeditions',
    ListDeliveryPath: 'list/deliveries',
    ListMethodeExpeditionPath: 'list/methode-expedition',
    ListMethodePaymentsPath: 'list/methode-payments',
    ListSalesPointsPath: 'list/sales-points',
    ListLocalisationPath: 'list/localisations',
    ListRelayPointsPath: 'list/relay-points',
    ListDocumentsPath: 'list/documents',

    NewUserAccountPath: 'add/account',
    NewRolePath: 'add/role',
    NewPermissionPath: 'add/permission',
    NewEcolePath: 'add/ecole',
    NewNiveauScolairePath: 'add/niveau-scolaire',
    NewProductPath: 'add/product',
    NewClassPath: 'add/classe',
    NewListScolairePath: 'add/liste-scolaire',
    NewCategoryPath: 'add/category',
    NewBrandPath: 'add/brand',
      NewProductTypePath: 'add/product-type',
      NewCompanyGroupPath: 'add/group',
      NewCustomerPath: 'add/customer',
      NewCompanyPath: 'add/company',
    NewOrderPath: 'add/order',
    NewExpeditionPath: 'add/expedition',
    NewDeliveryPath: 'add/delivery',
    NewMethodeExpeditionPath: 'add/methode-expedition',
    NewMethodePaymentsPath: 'add/methode-payment',
    NewSalesPointPath: 'add/sales-point',
    NewLocalisationPath: 'add/localisation',
    NewRelayPointPath: 'add/relay-point',
    NewDocumentPath: 'add/document',

    ConsulterPermissionPath: 'details/permission',
    ConsulterNiveauScolairePath: 'details/niveau-scolaire',
    ConsulterEcolePath: 'details/ecole',
    ConsulterProfilPath: 'details/account',
    ConsulterRolePath: 'details/role',
    ConsulterProductPath: 'details/product',
    ConsulterClassePath: 'details/classe',
    ConsulterListeScolairePath: 'details/liste-scolaire',
    ConsulterCategoryPath: 'details/category',
    ConsulterBrandPath: 'details/brand',
    ConsulterProductTypePath: 'details/product-type',
      ConsulterCompanyGroupPath: 'details/group',
      ConsulterCustomerPath: 'details/customer',
      ConsulterCompanyPath: 'details/company',

      ConsulterOrderPath: 'details/order',
    ConsulterExpeditionPath: 'details/expedition',
    ConsulterDeliveryPath: 'details/delivery',
    ConsulterMethodeExpeditionPath: 'details/methode-expedition',
    ConsulterMethodePaymentsPath: 'details/methode-payment',
    ConsulterSalesPointPath: 'details/sales-point',
    ConsulterLocalisationPath: 'details/localisation',
    ConsulterRelayPointPath: 'details/relay-point',
    ConsulterDocumentPath: 'details/document',

    EditerUtilisateurPath: 'edit/account',
    EditerEcolePath: 'edit/ecole',
    EditerPermissionPath: 'edit/permission',
    EditerRolePath: 'edit/role',
    EditerNiveauScolairePath: 'edit/niveau-scolaire',
    EditerProductPath: 'edit/product',
    EditerClassePath: 'edit/classe',
    EditerListeScolairePath: 'edit/liste-scolaire',
    EditerCategoryPath: 'edit/category',
    EditerBrandPath: 'edit/brand',
      EditerProductTypePath: 'edit/product-type',
      EditerCompanyGroupPath: 'edit/group',
      EditerCompanyPath: 'edit/company',
    EditerCustomerPath: 'edit/customer',
    EditerOrderPath: 'edit/order',
    EditerExpeditionPath: 'edit/expedition',
    EditerDeliveryPath: 'edit/delivery',
    EditerMethodeExpeditionPath: 'edit/methode-expedition',
    EditerMethodePaymentsPath: 'edit/methode-payment',
    EditerSalesPointPath: 'edit/sales-point',
    EditerLocalisationPath: 'edit/localisation',
    EditerRelayPointPath: 'edit/relay-point',
    EditerDocumentPath: 'edit/document'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
