import { Component, EventEmitter, Input, Output, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { UserService } from 'src/app/services/user.service';
import { ProfileService } from 'src/app/services/profile.service';
import { RolesService } from 'src/app/services/roles.service';
import { StatusService } from 'src/app/services/status.service';
import { PermissionService } from 'src/app/services/permission.service';
import { ModuleService } from 'src/app/services/module.service';

import { Role } from 'src/app/interfaces/role';
import { Permission } from 'src/app/interfaces/permission';
import { Profil } from 'src/app/interfaces/profil';
import { UserModel } from 'src/app/interfaces/user';
import { Status } from 'src/app/interfaces/status';
import { Module } from 'src/app/interfaces/module';

import { Response } from '../../interfaces/response';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-editer-permission',
  templateUrl: './editer-permission.component.html',
  styleUrls: ['./editer-permission.component.scss'], encapsulation: ViewEncapsulation.None
})
export class EditerPermissionComponent implements OnInit  {
  @Input() TitleVisible: boolean = true;
  @Input() DisplayConfirmationButton: boolean = true;


    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
    ////////////////////////
    // Attributes
    ///////////////////////

    @Input()     _permission: Permission;
    @Input('id') _id?: string;
    
  form: FormGroup;
  utilisateurs: any;
  roles: any;

  toppings = new FormControl('');

  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

    usersListCtrl = new FormControl('');
    usersData : UserModel[];//string[];
    usersList : UserModel[];//string[];
    selectedUsers : UserModel[];

    rolesListCtrl = new FormControl('');
    rolesList : Role[];//string[];
    rolesData : Role[];//string[];
    selectedRoles : Role[];

    modulesListCtrl = new FormControl('');
    modulesList : Module[];//string[];
    modulesData : Module[];//string[];
    selectedModule : Module;

    
    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatuses : Status[];
    selectedStatus : Status;


    ////////////////////////
    // Constructor
    ///////////////////////
    
    constructor(private router: Router,
		private route: ActivatedRoute,
		private permissionService: PermissionService,
		private userService: UserService,
		private roleService: RolesService,
		private profileService : ProfileService,
		private statusService : StatusService,
		private moduleService: ModuleService,
		
		private changeDetectorRefs: ChangeDetectorRef, 

      private _snackBar: MatSnackBar,
      public fb: FormBuilder) { 
 
      }

    ////////////////////////
    // Init
    ///////////////////////
    
    ngOnInit(): void {

	this.initCurrentRoute();

	this.refresh();
	this.initForm();
	
	
    }

    initCurrentRoute():void {
	this.route.params.subscribe(params => {
	    this._id = params["id"];
	    console.log("current id " + this._id)
	});

    }

    initCurrentObject(): void {
      this.permissionService.getById(this._id).subscribe(
	  {
              next: (response: Response<number, Permission>) => {
		  console.log("response");
		  console.log(response);
		  this._permission = response.Data;
		  this.selectedRoles = this._permission?.roles;
		  this.selectedUsers = this._permission?.users;
		  if (this._permission?.status)
		      this.selectedStatus = this._permission?.status ;
		  if (this._permission?.module)
		      this.selectedModule= this._permission?.module ;
		  console.log(this._permission.status);
		  console.log(this._permission);
		  console.log(this.selectedUsers);
		  
              },
              error: (e) => {
		  console.log(e);
              },
              complete: () => console.info('loaduser complete')
	  }
      );

    }



    ////////////////////////
    // Datasrouces
    ///////////////////////


    
    refresh(): void {
	this.initCurrentObject();
	
	this.userService.getAllUsers().subscribe({
            next: (response: Response<number, UserModel[]>) => {
		this.usersData = response.Data;
		this.initUsersSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load users complete')
	});

	this.moduleService.getAllModules().subscribe({
	    next: (response: Response<number, Module[]>) => {
		this.modulesData = response.Data;
		this.initModulesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load modules complete')
	});

	this.roleService.getAllRoles().subscribe({
	    next: (response: Response<number, Role[]>) => {
		this.rolesData = response.Data;
		this.initRolesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load roles complete')
	});

	
	this.statusService.getAllStatuses().subscribe({
	    next: (response: Response<number, Status[]>) => {
		
		this.statusesData = response.Data;
		this.initStatusesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load statuses complete')
	});


    }

    ////////////////////////
    // Datasrouces Form
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    name: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    label: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),

	    description: new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(2)]),
	});

    }
    
    ////////////////////////
    // Datasources Functions
    ///////////////////////

    

    compareFn(c1,c2): boolean {
	return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }
    
    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns
    
    initModulesSearchDropdown(){
	this.modulesList = [];
        for ( let i = 0 ; i < this.modulesData.length; i ++ ) {
            this.modulesList.push( this.modulesData[i] );
        }
    }

    initRolesSearchDropdown(){
	this.rolesList = [];
        for ( let i = 0 ; i < this.rolesData.length; i ++ ) {
            this.rolesList.push( this.rolesData[i] );
        }
    }

    initUsersSearchDropdown(){
	this.usersList = [];
        for ( let i = 0 ; i < this.usersData.length; i ++ ) {
            this.usersList.push( this.usersData[i] );
        }
    }

    initStatusesSearchDropdown(){
	this.statusesList = [];
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
	    {

		if (!this.selectedStatus && this.statusesData[i].label == "Actif")
		{
		    this.selectedStatuses = [];
		    this.selectedStatus = this.statusesData[i];
		    
		    this.selectedStatuses.push(this.statusesData[i]);
		    //this.onStatusesChanged(this.selectedStatus);
		    //console.log("selectedd status");
		    //console.log(this.selectedStatus);
		}
		this.statusesList.push( this.statusesData[i] );
	    }
        }

    }
    
    ////////////////////////
    // On change

    onStatusesChanged($event: any) {
      console.log($event);
    }
    
    onModulesChanged($event: any) {
      console.log($event);
    }

    onRolesChanged($event: any) {
      console.log($event);
    }

    onUsersChanged($event: any) {
      console.log($event);
    }

    ////////////////////////
    // On Search


    onKeyUsers(value) { 
            this.usersList= []; 
            this.selectUserSearch(value);       
    }

    onKeyModules(value) { 
            this.modulesList= []; 
            this.selectModulesSearch(value);       
    }

    onKeyRoles(value) { 
            this.rolesList= []; 
            this.selectRolesSearch(value);       
    }

    onKeyStatuses(value) { 
            this.statusesList= []; 
            this.selectStatusesSearch(value);       
    }

    ////////////////////////
    // search elements

    selectModulesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.modulesData.length; i ++ ) {
            let option = this.modulesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.modulesList?.push( option );
            }
        }
    }

    selectRolesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.rolesData.length; i ++ ) {
            let option = this.rolesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.rolesList?.push( option );
            }
        }
    }


    selectStatusesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
            let option = this.statusesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.statusesList?.push( option );
            }
        }
    }


    selectUserSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.usersData.length; i ++ ) {
            let option = this.usersData[i];
            if (  option.username.toLowerCase().indexOf(filter) >= 0
		|| option.profile?.firstname?.toLowerCase().indexOf(filter) >= 0 
		|| option.profile?.lastname?.toLowerCase().indexOf(filter) >= 0  ) {
                this.usersList?.push( option );
            }
        }
    }


    ////////////////////////
    ///////////////////////
    
    
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid) {
	    return;
	}
/*	let permission : Permission = {
            name: this.form.value.name,
            label: this.form.value.label,
            description: this.form.value.description,
//        usersphoneNumber: this.form.value.users
	};
*/
	if (this.selectedStatus){
	    this._permission.statusId = this.selectedStatus.id;
	}

	if (this.selectedModule){
	    this._permission.moduleId = this.selectedModule.id;
	}
	
	this._permission.users = this.selectedUsers;
	this._permission.roles = this.selectedRoles;
	console.log(this._permission);
	console.log(this.selectedUsers);
	console.log(this.selectedRoles);
	console.log("gonna save");

	this.permissionService.prepareUpdateRelations(this._permission).pipe(
	    switchMap(preparedPermission => { 
		console.log("entered switch map -- add new permission ");
		console.log(preparedPermission);
		return this.permissionService.updateById(this._permission.id, this._permission);
	    }),
	    switchMap(permissionUpdatedObservable => {
		let permissionUpdated = permissionUpdatedObservable.Data;
		    
		console.log("entered switch map -- handle post relations ");
		console.log(this._permission);
		this._permission.users = this.selectedUsers;
		this._permission.roles = this.selectedRoles;
		return this.permissionService.handlePostUpdateRelations(this._permission); 
	    })
	
						      
	).subscribe({
	    next: (res) => { this.checkSubmitSucess(res);  },
	    error: (error) => {this.submitError(error); },
	    complete: () => {
		console.info('save permission pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			 this.submitSuccess(); 
		    }, 5000);
		
	    }
	    
	    
	});

    }

        ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListPermissionPath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }
}
