import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'], encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {

  constructor(private appComponent: AppComponent) {
  }

  ngOnInit(): void {

  }

}
