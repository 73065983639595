import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';
import { Profil } from 'src/app/interfaces/profil';
import { User } from 'src/app/interfaces/user';
import { ClassGrade } from 'src/app/interfaces/class-grade';
import { ClassLevel } from 'src/app/interfaces/class-level';
import { School } from 'src/app/interfaces/school';
import { Status } from 'src/app/interfaces/status';

import { SchoolService } from 'src/app/services/school.service';
import { ClassLevelService } from 'src/app/services/class-level.service';
import { ClassGradeService } from 'src/app/services/class-grade.service';
import { UserService } from 'src/app/services/user.service';
import { StatusService } from 'src/app/services/status.service';

import { environment } from 'src/environments/environment';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Response } from '../../interfaces/response';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';

import { MatSort } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout'; 
import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-list-class',
  templateUrl: './list-class.component.html',
  styleUrls: ['./list-class.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ListClassComponent  implements OnInit, AfterViewInit {
    
    //////////////////////
    // Attributes 
    //////////////////////

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    
    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
  
    columns: string[] = ['#', 'school.name', 'classLevel.name', 'note', 'schoolList', 'status', 'Action' ];
    datasource: MatTableDataSource<User> = new MatTableDataSource<User>();
    pageSize = 10;
    color="#f8f8fb";
    
    
    classGrades : ClassGrade[]
  
    images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);
    
    schoolsListCtrl = new FormControl('');
    schoolsData : School[];//string[];
    schoolsList : School[];//string[];
    selectedSchools : School[];

    classLevelsListCtrl = new FormControl('');
    classLevelsList : ClassGrade[];//string[];
    classLevelsData : ClassGrade[];//string[];
    selectedClassLeveles : ClassGrade[];
    
    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatus : Status;

    isLoading = true; 

    
    ////////////////////////
    // Constructor
    //////////////////////// 
    constructor(private classGradeService: ClassGradeService,
		private schoolService : SchoolService,
		private statusService : StatusService,
		private classLevelService: ClassLevelService,

		private router: Router,
		public dialog: MatDialog) {

  }

    //////////////////////
    // Init
    //////////////////////

    
  ngAfterViewInit() {
    this.datasource.paginator = this.paginator;
  }

  ngOnInit(): void {
      this.refresh();
  }


    //////////////////////
    // Data sources
    //////////////////////

    doFilter(value: string) : void  {
	this.datasource.filter = value.trim().toLocaleLowerCase();
    }

    
    refresh(): void {
	this.setLoading(true);
	this.classGradeService.getAllClassGrades().subscribe(
	    {
		next: (response: Response<number, ClassGrade[]>) => {
		    console.log(response);
		    this.classGrades = response.Data;
		    this.datasource.data = this.classGrades;
		    this.datasource.paginator = this.paginator;
		    this.setLoading(false);
		    console.log(this.classGrades);

		},
		error: (e) => {
		    this.setLoading(false);
		},
		complete: () => console.info('loadMenu complete')
	    }
	);
	this.schoolService.getAll().subscribe({
	    next: (response: Response<number, School[]>) => {
		this.schoolsList = response.Data;
            },error: (e) => { }, complete: () => console.info('load schools complete')
	});
	this.statusService.getAll().subscribe({
	    next: (response: Response<number, Status[]>) => {
		this.statusesList = response.Data;
            },error: (e) => { }, complete: () => console.info('load status complete')
	});
	this.classLevelService.getAll().subscribe({
	    next: (response: Response<number, ClassLevel[]>) => {
		this.classLevelsList = response.Data;
            },error: (e) => { }, complete: () => console.info('load status complete')
	});
    }

    //////////////////////
    // Loading
    /////////////////////

    toggleLoading() : void {
	this.isLoading = !this.isLoading;
    }

    setLoading(loading : boolean) : void {
	this.isLoading = loading;
    }

    //////////////////////
    //////////////////////

    

    //////////////////////
    // Actions
    //////////////////////


    
  onAddNewClass(): void {
    this.router.navigate([environment.paths.NewClassPath]);
  }

  onEditClass(id): void {
      this.router.navigate([environment.paths.EditerClassePath,id]);
  }

  onConsulterClass(id): void {
      this.router.navigate([environment.paths.ConsulterClassePath,id]);
  }


  onDelete(id): void {
    const dialogRef = this.dialog.open(ConfirmerSuppressionDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
	  console.log(id);

	  this.classGradeService.deleteById(id).subscribe(
	      {
		  next: (response: Response<number, ClassGrade>) => {
		      console.log(response);
		      this.refresh();
		  },
		  error: (e) => {
		      console.log(e);
		  },
		  complete: () => console.info('delete complete')
	      }
	  );

      }
    });
  }

}
