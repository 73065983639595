<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">LISTE DES LIVRAISONS</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Livraison</a></li>
                    <li class="breadcrumb-item active">Livraison</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content bdy" style="background-color: white;">
    <div class="container-fluid" style="padding: 30px;">


        <!--<button class="btn btn-primary btn-sm" (click)="onAddNewDelivery()">Ajouter une livraison</button>-->


        <div class="row mb-3 mt-3">


        </div>

        <div class="table-responsive">

	    <div fxLayout fxLayoutAlign="end center">
		<mat-form-field fxFlex="40%">
		    <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filtrer">
		</mat-form-field>
	    </div>

	    
            <table mat-table [dataSource]="datasource" matSort>

                <ng-container matColumnDef="#">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">
                        <mat-checkbox></mat-checkbox>
                    </th>
                    <td style="width: 4%;" mat-cell *matCellDef="let element">
                        <!--<mat-checkbox></mat-checkbox>-->
			 {{element.id}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="NumExp">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Numero de livraison</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">#LIV_{{element.deliveryNumber}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="NomClient">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Nom client</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.shipping?.order?.customer?.profile?.lastname}} {{element.shipping?.order?.customer?.profile?.firstname}} </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Adresse">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Adresse</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element?.address?.street}} {{element?.address?.zip}},{{element?.address?.city}} {{element?.address?.country}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="DateLivraison">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Date de livraison</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.deliveryDate |  date:'dd/MM/yyyy'}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Etat">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">État
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge badge-pill badge-primary">{{element.status?.label}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="NumCommand">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Numéro de commande</th>
                    <td mat-cell *matCellDef="let element">
                        <span>#CMD_{{element.shipping?.order?.orderNumber}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Action</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon matTooltip="consulter" (click)="onConsulterDelivery(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="preview"></mat-icon>
                        <!--<mat-icon matTooltip="editer" (click)="onEditDelivery()" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="edit"></mat-icon>-->
                        <mat-icon matTooltip="supprimer" (click)="onDelete(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="delete"></mat-icon>
                    </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;" class="example-element-row"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="pageSize" showFirstLastButtons
                aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>


    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
