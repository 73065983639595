import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';        

// models
import { CompanyGroup } from '../interfaces/company-group';
import { Company } from '../interfaces/company';
import { Note } from '../interfaces/note';
import { Address } from '../interfaces/address';

// services
import {NoteService} from '../services/note.service'
import {AddressService} from '../services/address.service'

// rxjs
import { of, from,  map, Observable, exhaustMap, EMPTY, empty ,identity, forkJoin} from 'rxjs';
import { mergeMap, mergeAll, toArray, switchMap} from 'rxjs/operators';

// response   
import { Response as Rep } from '../interfaces/response';

import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"


@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseCrudModel<Company>{
    postCompanyGroups = {url: `${environment.domains.api.url}/company-groups`, urlOptions: this.options};
    deleteCompanyGroups = {url: `${environment.domains.api.url}/company-groups`, urlOptions: this.options};
    
    constructor(protected override httpService: HttpService,
		private noteService : NoteService,
	       private addressService : AddressService) {
	super(httpService, "companies");

	var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "objectDetail"},
		{"relation" : "note"},
		{"relation" : "companyGroup"},
		//{"relation" : "customers", "scope" : {"include" : [{"relation" : "profile"}]}},
		{"relation" : "billingAddress"},
		{"relation" : "companyAddress"},
	    ],
	});

	var addOptions =  RequestOptions.FromArray({
	    relationsToOmit : ['note', 'status', 'companyGroups', 'billingAddress', 'companyAddress']
	});

	this.methods.listing.urlOptions = options;
	this.methods.view.urlOptions = options;
	this.methods.add.urlOptions = addOptions;
	this.methods.update.urlOptions = addOptions;
	
    }

    getAllCompanies(): Observable<Rep<number, Company[]>> {
	const options = new RequestOptions();

	return this.getAll()
    }

    ////////////////////////////////
    // PREPARE || HANDLE Relations
    ////////////////////////////////
    
    override prepareRelations(fromCompany): Observable<Rep<number, Company>> { //Observable<Profile> {
	// values to save
	const company : Company = fromCompany;


	// Obs Functions
	const failObj : Rep<number, Note> = {IsSuccess:  0, Data:  null, Status : 0};
	const failAddr : Rep<number, Address> = {IsSuccess:  0, Data:  null, Status : 0};

	const a$ = of();

	const add$ : Observable<Rep<number, Address>> = company.companyAddress ? 
	    ( company.companyAddress.id ? this.addressService.updateById(fromCompany.companyAddress.id, fromCompany.companyAddress) : this.addressService.add(fromCompany.companyAddress) )
	    :  this.httpService.createObservableResponse<Address>(failAddr);

	const billingAdd$ : Observable<Rep<number, Address>> = company.billingAddress ? 
	    ( company.billingAddress.id ? this.addressService.updateById(fromCompany.billingAddress.id, fromCompany.billingAddress) : this.addressService.add(fromCompany.billingAddress) )
	    :  this.httpService.createObservableResponse<Address>(failAddr);


	const note$ : Observable<Rep<number, Note>> = company.note ? 
	    ( company.note.id ? this.noteService.updateById(fromCompany.note.id, fromCompany.note) : this.noteService.add(fromCompany.note) )
	    :  this.httpService.createObservableResponse<Note>(failObj);

	
	return add$
	    .pipe(
		exhaustMap(add => 
		    {
			//(company.addressId = add && add.IsSuccess && add.Data ? add.Data.id : null );
			console.log("before setting CompanyAddress");
			console.log(company);
			(company.companyAddressId = add && add.IsSuccess ?
			    (add.Data && add.Data.id ? add.Data.id : company.companyAddressId) : company.companyAddressId );
			
			return   billingAdd$;
		    }),
		exhaustMap(add => 
		    {
			//(company.addressId = add && add.IsSuccess && add.Data ? add.Data.id : null );
			console.log("before setting BillingAddress");
			console.log(company);
			(company.billingAddressId = add && add.IsSuccess ?
			    (add.Data && add.Data.id ? add.Data.id : company.billingAddressId) : company.billingAddressId );
			
			return   note$
		    }),
		exhaustMap(note => 
		    {
			console.log("created note");
			console.log(note);
			console.log("before setting Note");
			console.log(company);
			(company.noteId = note && note.IsSuccess ?
			    (note.Data && note.Data.id ? note.Data.id : company.noteId) : company.noteId );

			return this.httpService.createObservableResponse<Note>({IsSuccess : 1, Data:note.Data, Status:0});
			
			//return   this.httpService.createResponseFromObj<Company>(company)
		    }),
		map(rel =>
		    {
			console.log("final result");
			console.log(rel);
			// return profile
			return    this.httpService.createResponseFromObj<Company>(company) ;
		    }),
		
	    );
    }

    //////////////////////////
    // HANDLE || PREPARE
    //////////////////////////
/*
    override handleRemoveRelations(company): Observable<any> {
	console.log("removePostRelations for company : \n {0}".format(JSON.stringify(company)));

	return forkJoin
	(
	    // Remove elements if empty
	    company && company.id && (company.companyGroups && company.companyGroups?.length >= 0) ? this.removeCompanyGroups(company.id) :
		this.httpService.createObservableResponse<Company[]>({IsSuccess:  0, Data:  undefined, Status : 0})

	)
    }
    
    override handlePostRelations(company): Observable<any> {
	console.log(company);
	console.log("handlePostRelations");
	    return forkJoin
	(
	    
	    // add new elements 
	    company && company.id && company.companyGroups && company.companyGroups?.length > 0 ? this.addCompanyGroups(company.id, company.companyGroups) :
		this.httpService.createObservableResponse<Company[]>({IsSuccess:  0, Data:  undefined, Status : 0})
	)
    }
*/
    
    //////////////////////////
    // Add Other objects
    //////////////////////////

    public removeCompanyGroups(companyId):  Observable<Rep<number, any>> {
	console.log("remove companyGroup for companyId:{companyId}".formatUnicorn({companyId : companyId}));
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		//{"companyId" : companyId},
		{"companyId" : {"regexp" : "/^{companyId}$/".formatUnicorn({companyId: companyId})}},
	    ],
	});
	options.body = {"companyId" : ""}
	return this.httpService.makePatchRequest<any>( `${this.deleteCompanyGroups.url}`, options);
//	return  this.httpService.createObservableResponse<CompanyCompanyGroup[]>({IsSuccess:  0, Data:  undefined, Status : 0});
    }


    //////////////////////////
    // Add Other objects
    //////////////////////////

    public addCompanyGroups(companyId, companyGroups):  Observable<Rep<number, any>> {
	
 	
	//const arrayCompanyGroups = companyGroups?.map((r: any): Company => ({companyId : companyId, companyGroupId: r.id}));

	const arrayCompanyGroups = companyGroups?.map((r: Company): any => (r.id));

	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		{"id" : {"inq" : arrayCompanyGroups}},
	    ],
	});
	console.log("Array companyGroups");
	console.log(arrayCompanyGroups);
	options.body =  {"companyId" : companyId} ;//arrayCompanyGroups;	

	return arrayCompanyGroups && arrayCompanyGroups.length > 0 ?
	    this.httpService.makePatchRequest<Company[]>( `${this.postCompanyGroups.url}`.format(), options) :
	    this.httpService.createObservableResponse<Company[]>({IsSuccess:  0, Data:  undefined, Status : 0});
	//Observable.empty<number, any>() ;
    }
}


