import { Country } from '@angular-material-extensions/select-country';
import { Component, EventEmitter, Input,  Output, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core'; 
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { SchoolService } from 'src/app/services/school.service';
import { ClassGradeService } from 'src/app/services/class-grade.service';
import { ClassLevelService } from 'src/app/services/class-level.service';
import { StatusService } from 'src/app/services/status.service';

import { Status } from 'src/app/interfaces/status';
import { School } from 'src/app/interfaces/school';
import { Note } from 'src/app/interfaces/note';
import { ClassLevel } from 'src/app/interfaces/class-level';
import { ClassGrade } from 'src/app/interfaces/class-grade';


import { Response } from '../../interfaces/response';
import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-editer-classe',
  templateUrl: './editer-classe.component.html',
  styleUrls: ['./editer-classe.component.scss'], encapsulation: ViewEncapsulation.None
})
export class EditerClasseComponent implements OnInit  {

    @Input() TitleVisible: boolean = true;
    @Input() DisplayConfirmationButton: boolean = true;

    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter(); 
    ////////////////////////
    //  Attributes 
    ////////////////////////

    @Input()     _classGrade: ClassGrade;
    @Input('id') _id?: string; 
    
    form: FormGroup;
    utilisateurs: any;
    permissions: any;

    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatuses : Status[];
    selectedStatus : Status;

    
    schoolsListCtrl = new FormControl('');
    schoolsData : School[];//string[];
    schoolsList : School[];//string[];
    selectedSchool : School;
    selectedSchools : School[];

    classLevelsListCtrl = new FormControl('');
    classLevelsList : ClassLevel[];//string[];
    classLevelsData : ClassLevel[];//string[];
    selectedClassLevel : ClassLevel;
    selectedClassLevels : ClassLevel[];
    
    ////////////////////////
    // Constructor
    //////////////////////// 

    constructor(private router: Router,
		private route: ActivatedRoute,
		private statusService : StatusService,
		private schoolService: SchoolService,
		private classLevelService: ClassLevelService,
		private classGradeService: ClassGradeService,

		private changeDetectorRefs: ChangeDetectorRef,   
		private _snackBar: MatSnackBar,
		public fb: FormBuilder) { 
	
    }

    ////////////////////////
    // Init
    ////////////////////////  
    ngOnInit(): void {
	this.initCurrentRoute();
	this.refresh();
	this.initForm(); 

    }

    initCurrentRoute():void {
	this.route.params.subscribe(params => {  
	    this._id = params["id"];
	    console.log("current id " + this._id)
	});

    }

    initCurrentObject(): void {
      this.classGradeService.getById(this._id).subscribe(
	  {
              next: (response: Response<number, ClassGrade>) => {
		  console.log("response");
		  console.log(response);
		  this._classGrade = response.Data;
		  if (this._classGrade?.status)
		      this.selectedStatus = this._classGrade?.status ;
		  if (this._classGrade?.school)
		      this.selectedSchool = this._classGrade?.school ;
		  if (this._classGrade?.classLevel)
		      this.selectedClassLevel = this._classGrade?.classLevel ;
		  if (this._classGrade?.note) {
		      
		  } else {
		      const note : Note = {  content :  "" };
		      this._classGrade.note = note;
		  }
		  console.log(this._classGrade.status);
		  console.log(this._classGrade);
		  
              },
              error: (e) => {
		  console.log(e);
              },
              complete: () => console.info('loaduser complete')
	  }
      );

    }



    ////////////////////////
    // Datasrouces
    ///////////////////////


    
    refresh(): void {
	this.initCurrentObject();
	
	this.statusService.getAllStatuses().subscribe({
	    next: (response: Response<number, Status[]>) => {
		
		this.statusesData = response.Data;
		this.initStatusesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load statuses complete')
	});

	this.schoolService.getAllSchools().subscribe({
            next: (response: Response<number, School[]>) => {
		this.schoolsData = response.Data;
		this.initSchoolsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load schools complete')
	});

	this.classLevelService.getAllClassLevels().subscribe({
	    next: (response: Response<number, ClassLevel[]>) => {
		this.classLevelsData = response.Data;
		this.initClassLevelsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load classLevels complete')
	});

    }

    ////////////////////////
    // Datasrouces Form
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    //name: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    //description: new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(2)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
	});

    }
    
    ////////////////////////
    // Datasources Functions
    ///////////////////////

    

    compareFn(c1,c2): boolean {
	return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }
    
    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns
    

    initStatusesSearchDropdown(){
	this.statusesList = [];
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
	    {

		if (!this.selectedStatus && this.statusesData[i].label == "Actif")
		{
		    this.selectedStatuses = [];
		    this.selectedStatus = this.statusesData[i];
		    
		    this.selectedStatuses.push(this.statusesData[i]);
		}
		this.statusesList.push( this.statusesData[i] );
	    }
        }

    }

        initClassLevelsSearchDropdown(){
	this.classLevelsList = [];
        for ( let i = 0 ; i < this.classLevelsData.length; i ++ ) {
            this.classLevelsList.push( this.classLevelsData[i] );
        }
    }


    
    initSchoolsSearchDropdown(){
	this.schoolsList = [];
        for ( let i = 0 ; i < this.schoolsData.length; i ++ ) {
            this.schoolsList.push( this.schoolsData[i] );
        }
    }

    
    
    ////////////////////////
    // On change

    onStatusesChanged($event: any) {
      console.log($event);
    }
    
    OnClassLevelsChanged($event: any) {
      console.log($event);
    }

    OnSchoolsChanged($event: any) {
	console.log($event);
    }
    
    ////////////////////////
    // On Search

    onKeySchools(value) { 
            this.schoolsList= []; 
            this.selectSchoolSearch(value);       
    }

    onKeyClassLevels(value) { 
            this.classLevelsList= []; 
            this.selectClassLevelsSearch(value);       
    }

    onKeyStatuses(value) { 
            this.statusesList= []; 
            this.selectStatusesSearch(value);       
    }

    ////////////////////////
    // search elements

    selectStatusesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
            let option = this.statusesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.statusesList?.push( option );
            }
        }
    }


    selectClassLevelsSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.classLevelsData.length; i ++ ) {
            let option = this.classLevelsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.classLevelsList?.push( option );
            }
        }
    }

    selectSchoolSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.schoolsData.length; i ++ ) {
            let option = this.schoolsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0
		|| option.name?.toLowerCase().indexOf(filter) >= 0  ) {
                this.schoolsList?.push( option );
            }
        }
    }


    ////////////////////////
    ///////////////////////
    
    
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid) {
	    return;
	}

	const note : Note = {
	    content : this.form.value.note
	}

	if ((this._classGrade.note  && this._classGrade.note.id ) || this._classGrade.noteId){
	    note.id =  this._classGrade.note && this._classGrade.note.id ?  this._classGrade.note.id :  this._classGrade.noteId ; 
	}

	if (this.selectedClassLevel){
	    this._classGrade.classLevelId = this.selectedClassLevel.id;
	}

	if (this.selectedSchool){
	    this._classGrade.schoolId = this.selectedSchool.id;
	}
	
	if (this.selectedStatus){
	    this._classGrade.statusId = this.selectedStatus.id;
	}


	console.log(this._classGrade);
	console.log("gonna save");

	this.classGradeService.prepareUpdateRelations(this._classGrade).pipe(
	    switchMap(preparedClassGrade => { 
		console.log("entered switch map -- add new classGrade ");
		console.log(preparedClassGrade);
		return this.classGradeService.updateById(this._classGrade.id, this._classGrade);
	    }),
	    switchMap(classGradeUpdatedObservable => {
		let classGradeUpdated = classGradeUpdatedObservable.Data;
		    
		console.log("entered switch map -- handle post relations ");
		console.log(this._classGrade);
		return this.classGradeService.handlePostUpdateRelations(this._classGrade); 
	    })
	
						      
	).subscribe({
	    next: (res) => { this.checkSubmitSucess(res);},
	    error: (error) => {this.submitError(error)},
	    complete: () => {
		console.info('save classGrade pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();
		    }, 5000);
		
	    }
	    
	    
	});

    }

    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListClassPath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }



}
/*  @Input() TitleVisible: boolean = true;
  @Input() DisplayConfirmationButton: boolean = true;

  form: FormGroup;
  utilisateurs: any;
  permissions: any;
  selectedCountry: Country = undefined;
  
  toppings = new FormControl('');

  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

  constructor(private router: Router,
      private _snackBar: MatSnackBar,
      public fb: FormBuilder) { 
 
      }
      
  ngOnInit(): void {
    this.form = this.fb.group({
      nom: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
      prenom: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
      phone: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$"), Validators.minLength(10)]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
      passwordcheck: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
      street: new FormControl(null, [Validators.required, Validators.maxLength(5), Validators.pattern("^[0-9]*$"), Validators.minLength(1)]),
      city: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
      postal: new FormControl(null, [Validators.required, Validators.maxLength(5),  Validators.pattern("^[0-9]*$"), Validators.minLength(5)])
    });
  }

  onCountrySelected($event: Country) {
    this.selectedCountry = $event;
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
    }
    
} */
