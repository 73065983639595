import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';        

// models
import { Profil as Profile } from '../interfaces/profil';
import { Company } from '../interfaces/company';
import { Customer } from '../interfaces/customer';
import { Note } from '../interfaces/note';
import { Address } from '../interfaces/address';

// services
import {NoteService} from '../services/note.service'
import {CompanyService} from '../services/company.service'
import {AddressService} from '../services/address.service'
import {ProfileService} from '../services/profile.service'

// rxjs
import { of, from,  map, Observable, exhaustMap, EMPTY, empty ,identity, forkJoin} from 'rxjs';
import { mergeMap, mergeAll, toArray, switchMap} from 'rxjs/operators';

// response   
import { Response as Rep } from '../interfaces/response';

import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"


@Injectable({
  providedIn: 'root'
})
export class CustomerService extends BaseCrudModel<Customer>{

    postCompanies = {url: `${environment.domains.api.url}/company-groups`, urlOptions: this.options};
    deleteCompanies = {url: `${environment.domains.api.url}/company-groups`, urlOptions: this.options};
    
    constructor(protected override httpService: HttpService,
		private noteService : NoteService,
		private profileService : ProfileService,
		private companyService : CompanyService,
		private addressService : AddressService) {
	super(httpService, "customers");

		var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "objectDetail"},// "scope" : { "include" : [{"relation" : "creator"}, {"relation" : "updater"}] }},
		{"relation" : "note"},
		{"relation" : "orders"},
		{"relation" : "profile",
		 "scope" : {
		     "include" : [{"relation" : "user"}]
		 }
		},
		{"relation" : "company" ,
		 "scope" : {
		     "include" : [{"relation" : "companyGroup"}]
		 }
		},
		{"relation" : "billingAddress"},
		{"relation" : "shippingAddress"},
	    ],
	});

	var addOptions =  RequestOptions.FromArray({
	    relationsToOmit : ['note', 'status',
			       'billingAddress', 'shippingAddress',
			       'profile', 'company']
	});

	this.methods.listing.urlOptions = options;
	this.methods.view.urlOptions = options;
	this.methods.add.urlOptions = addOptions;
	this.methods.update.urlOptions = addOptions;
    }

    getAllCustomers(): Observable<Rep<number, Customer[]>> {
	const options = new RequestOptions();

	return this.getAll()
//	return this.httpService.makeGetRequest<Customer[]>( `${environment.domains.profil.GetAllCustomers}`, options);
    }


    ////////////////////////////////
    // PREPARE || HANDLE Relations
    ////////////////////////////////
    
    override prepareRelations(fromCustomer): Observable<Rep<number, Customer>> { //Observable<Profile> {
	// values to save
	const customer : Customer = fromCustomer;


	// Obs Functions
	const failObj : Rep<number, Note> = {IsSuccess:  0, Data:  null, Status : 0};
	const failProfile : Rep<number, Profile> = {IsSuccess:  0, Data:  null, Status : 0};
	const failAddr : Rep<number, Address> = {IsSuccess:  0, Data:  null, Status : 0};

	const a$ = of();

	const add$ : Observable<Rep<number, Address>> = customer.shippingAddress ? 
	    ( customer.shippingAddress.id ? this.addressService.updateById(fromCustomer.shippingAddress.id, fromCustomer.shippingAddress) : this.addressService.add(fromCustomer.shippingAddress) )
	    :  this.httpService.createObservableResponse<Address>(failAddr);

	const billingAdd$ : Observable<Rep<number, Address>> = customer.billingAddress ? 
	    ( customer.billingAddress.id ? this.addressService.updateById(fromCustomer.billingAddress.id, fromCustomer.billingAddress) : this.addressService.add(fromCustomer.billingAddress) )
	    :  this.httpService.createObservableResponse<Address>(failAddr);


	const note$ : Observable<Rep<number, Note>> = customer.note ? 
	    ( customer.note.id ? this.noteService.updateById(fromCustomer.note.id, fromCustomer.note) : this.noteService.add(fromCustomer.note) )
	    :  this.httpService.createObservableResponse<Note>(failObj);

	const prepareProfile$ : Observable<Rep<number, Profile>> = customer.profile ? 
	    ( customer.profile.id ? this.profileService.prepareUpdateRelations(fromCustomer.profile) : this.profileService.prepareAddRelations(fromCustomer.profile) )
	    :  this.httpService.createObservableResponse<Profile>(failProfile);


	
	return add$
	    .pipe(
		exhaustMap(add => 
		    {
			//(customer.addressId = add && add.IsSuccess && add.Data ? add.Data.id : null );
			console.log("before setting CustomerAddress");
			console.log(customer);
			(customer.shippingAddressId = add && add.IsSuccess ?
			    (add.Data && add.Data.id ? add.Data.id : customer.shippingAddressId) : customer.shippingAddressId );
			
			return   billingAdd$;
		    }),
		exhaustMap(add => 
		    {
			//(customer.addressId = add && add.IsSuccess && add.Data ? add.Data.id : null );
			console.log("before setting BillingAddress");
			console.log(customer);
			(customer.billingAddressId = add && add.IsSuccess ?
			    (add.Data && add.Data.id ? add.Data.id : customer.billingAddressId) : customer.billingAddressId );
			
			return   note$
		    }),
		exhaustMap(note => 
		    {
			console.log("created note");
			console.log(note);
			console.log("before setting Note");
			console.log(customer);
			(customer.noteId = note && note.IsSuccess ?
			    (note.Data && note.Data.id ? note.Data.id : customer.noteId) : customer.noteId );

			
			//return this.httpService.createObservableResponse<Note>({IsSuccess : 1, Data:note.Data, Status:0});
			//return   this.httpService.createResponseFromObj<Customer>(customer);

			return prepareProfile$;
		    }),
		exhaustMap(preparedProfileObservable => 
		    {
			let preparedProfile = preparedProfileObservable.Data;
			console.log("created profile Prepared");
			console.log(preparedProfile);
			console.log("before setting Prepared profile ");


			const setProfile$ : Observable<Rep<number, Profile>> = preparedProfile ? 
			    ( customer.profile.id ?
				this.profileService.updateById(fromCustomer.profile.id, preparedProfile)
				: this.profileService.add(preparedProfile) )
			    :  this.httpService.createObservableResponse<Profile>(failProfile);

			return setProfile$;
		    }),
		exhaustMap(profile => 
		    {
			console.log("created profile");
			console.log(profile);
			console.log("before setting Profile");
			console.log(customer);

			(customer.profileId = profile && profile.IsSuccess ?
			    (profile.Data && profile.Data.id ? profile.Data.id : customer.profileId) : customer.profileId );

			return this.httpService.createObservableResponse<Profile>({IsSuccess : 1, Data:profile.Data, Status:0});
		    }),
		
		map(rel =>
		    {
			console.log("final result");
			console.log(rel);
			// return profile
			return    this.httpService.createResponseFromObj<Customer>(customer) ;
		    }),
		
	    );
    }

    //////////////////////////
    // HANDLE || PREPARE
    //////////////////////////
/*
    override handleRemoveRelations(customer): Observable<any> {
	console.log("removePostRelations for customer : \n {0}".format(JSON.stringify(customer)));

	return forkJoin
	(
	    // Remove elements if empty
	    customer && customer.id && (customer.companies && customer.companies?.length >= 0) ? this.removeCompanies(customer.id) :
		this.httpService.createObservableResponse<Customer[]>({IsSuccess:  0, Data:  undefined, Status : 0})

	)
    }
    
    override handlePostRelations(customer): Observable<any> {
	console.log(customer);
	console.log("handlePostRelations");
	    return forkJoin
	(
	    
	    // add new elements 
	    customer && customer.id && customer.companies && customer.companies?.length > 0 ? this.addCompanies(customer.id, customer.companies) :
		this.httpService.createObservableResponse<Customer[]>({IsSuccess:  0, Data:  undefined, Status : 0})
	)
    }
*/
    
    //////////////////////////
    // Add Other objects
    //////////////////////////

    public removeCompanies(customerId):  Observable<Rep<number, any>> {
	console.log("remove company for customerId:{customerId}".formatUnicorn({customerId : customerId}));
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		//{"customerId" : customerId},
		{"customerId" : {"regexp" : "/^{customerId}$/".formatUnicorn({customerId: customerId})}},
	    ],
	});
	options.body = {"customerId" : ""}
	return this.httpService.makePatchRequest<any>( `${this.deleteCompanies.url}`, options);
//	return  this.httpService.createObservableResponse<CustomerCompany[]>({IsSuccess:  0, Data:  undefined, Status : 0});
    }


    //////////////////////////
    // Add Other objects
    //////////////////////////

    public addCompanies(customerId, companies):  Observable<Rep<number, any>> {
	
 	
	//const arrayCompanies = companies?.map((r: any): Customer => ({customerId : customerId, companyId: r.id}));

	const arrayCompanies = companies?.map((r: Customer): any => (r.id));

	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		{"id" : {"inq" : arrayCompanies}},
	    ],
	});
	console.log("Array companies");
	console.log(arrayCompanies);
	options.body =  {"customerId" : customerId} ;//arrayCompanies;	

	return arrayCompanies && arrayCompanies.length > 0 ?
	    this.httpService.makePatchRequest<Customer[]>( `${this.postCompanies.url}`.format(), options) :
	    this.httpService.createObservableResponse<Customer[]>({IsSuccess:  0, Data:  undefined, Status : 0});
	//Observable.empty<number, any>() ;
    }

}
