<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">EDITER PERMISSION</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Utilisateurs</a></li>
                    <li class="breadcrumb-item active">Permissions</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">



        <h3 style="color: black; font-size:medium">Permission</h3>
        <h6 style="color: black; font-size: small">Editer une permission</h6>
        <form class="example-form" [formGroup]="form" (ngSubmit)="submit()"  *ngIf="_permission">


            <mat-label>Nom</mat-label>
            <input type="text" matInput  formControlName="name"  [(ngModel)]="_permission.name" class="form-control form-control-sm mb-2" required>

            <mat-label>Label</mat-label>
            <input type="text" matInput formControlName="label" [(ngModel)]="_permission.label" class="form-control form-control-sm mb-2" required>


            <mat-label>Roles</mat-label>
            <div class="input-group">
                <mat-select placeholder="Choisir les roles pour cette permission"  class="  form-control form-control-sm mb-2" [formControl]="rolesListCtrl" multiple required
			    [(ngModel)]="selectedRoles"  [compareWith]="compareFn" (selectionChange)="OnRolesChanged($event)" >
			<input class="mat-select-input-search" placeholder ="recherche " (keyup)="onKeyRoles($event.target.value)"> 
                    <mat-select-trigger>
                        {{rolesListCtrl.value?.[0]?.label || ''}}
                        <span *ngIf="(rolesList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedRoles?.length || 0) - 1}} {{selectedRoles?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let role of rolesList" [value]="role">{{role.label}}</mat-option>
		    </mat-select>
            </div>


            <mat-label>Utilisateurs</mat-label>
            <div class="input-group">
                <mat-select placeholder="Choisir les utilisateurs pour cette permission" class="  form-control form-control-sm mb-2" [formControl]="usersListCtrl" multiple required
			    [(ngModel)]="selectedUsers"  [compareWith]="compareFn" (selectionChange)="OnUsersChanged($event)" >
			<input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyUsers($event.target.value)"> 
                      <mat-select-trigger>
                        {{usersListCtrl.value?.[0]?.username  || ''}} -  {{usersListCtrl.value?.[0]?.profile?.firstname  || ''}} {{usersList?.[0]?.profile?.lastname  || ''}} 
                        <span *ngIf="(usersList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedUsers?.length || 0) - 1}} {{selectedUsers?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let user of usersList" [value]="user">{{user.username}} - {{user.profile?.fistname }} {{user.profile?.lastname}}</mat-option>
		</mat-select>

            </div>


            <mat-label>Modules</mat-label>
            <div class="input-group">
                <mat-select placeholder="Choisir un modules pour cette permission"  class="  form-control form-control-sm mb-2"
			    [formControl]="modulesListCtrl"  required [(ngModel)]="selectedModule"
			    [compareWith]="compareFn" (selectionChange)="OnModulesChanged($event)">
			<input class="mat-select-input-search" placeholder ="recherche " (keyup)="onKeyModules($event.target.value)"> 
                    <mat-select-trigger>
                        {{selectedModule?.label || ''}}
                        <span *ngIf="(modulesList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedModules?.length || 0) - 1}} {{selectedModules?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let module of modulesList" [value]="module">{{module.label}}</mat-option>                    </mat-select>
            </div>

	    
            <mat-form-field appearance="outline" class="example-full-width mb-2">
                <mat-label>Description</mat-label>
                <textarea  formControlName="description" style="height: 100px;" matInput placeholder="Description" required
		 [(ngModel)]="_permission.description" placeholder="Ex. Cette permission a pour but de definir le personnel ayant..."></textarea>
            </mat-form-field>

	                <mat-label>Status</mat-label>
            <div class="input-group">
                <mat-select placeholder="Choisir un statut pour ce role" class="  form-control form-control-sm mb-2"   required
			    [(ngModel)]="selectedStatus"
			    [formControl]="statusesListCtrl"  [compareWith]="compareFn" (selectionChange)="OnStatusesChanged($event)"  >
			<input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyStatuses($event.target.value)">  
                      <mat-select-trigger>
                        {{statusesListCtrl.value?.label  || ''}}
                        <span *ngIf="(selectedStatuses?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedStatuses?.length || 0) - 1}} {{selectedStatuses?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
		    </mat-select>

            </div>
	    
            <div class="example-full-width d-flex justify-content-center" *ngIf="DisplayConfirmationButton">
                <button class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>
                <!-- <button mat-raised-button cdkFocusInitial>Annuler</button> -->
            </div>



        </form>




    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
