<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">ÉDITER UN DOCUMENT</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Documents</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">



        <h3 style="color: black; font-size:medium">Information sur le document</h3>
        <h6 style="color: black; font-size: small">Remplissez les informations suivantes</h6>
        <form class="example-form" [formGroup]="form" (ngSubmit)="submit()">


            <div class="row mt-3">
                <div class="col-6">
                    <mat-label>Destinaire</mat-label>
                    <input type="text" matInput formControlName="nom" class="form-control form-control-sm" required>
                </div>
                <div class="col-6">
                    <mat-label>Type de document</mat-label>
                    <div class="input-group">
                        <mat-select placeholder="Choisir..." class="  form-control form-control-sm"
                            [formControl]="toppings"  required>
                            <mat-select-trigger>
                                {{toppings.value?.[0] || ''}}
                                <span *ngIf="(toppings.value?.length || 0) > 1" class="example-additional-selection">
                                    (+{{(toppings.value?.length || 0) - 1}} {{toppings.value?.length === 2 ? 'other' :
                                    'others'}})
                                </span>
                            </mat-select-trigger>
                            <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                        </mat-select>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-primary  form-control-sm">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-6">
                    <mat-label>Générer par</mat-label>
                    <div class="input-group">
                        <mat-select placeholder="Choisir..." class="  form-control form-control-sm"
                            [formControl]="toppings"  required>
                            <mat-select-trigger>
                                {{toppings.value?.[0] || ''}}
                                <span *ngIf="(toppings.value?.length || 0) > 1" class="example-additional-selection">
                                    (+{{(toppings.value?.length || 0) - 1}} {{toppings.value?.length === 2 ? 'other' :
                                    'others'}})
                                </span>
                            </mat-select-trigger>
                            <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                        </mat-select>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-primary  form-control-sm">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row mt-3">
                <div class="col-6">
                    <div class="row">
                        <div class="col-12">
                            <mat-label>Status</mat-label>
                            <div class="input-group">
                                <mat-select placeholder="Choisir un statut" class="  form-control form-control-sm mb-2" [formControl]="toppings"  required>
                                    <mat-select-trigger>
                                        {{toppings.value?.[0] || ''}}
                                        <span *ngIf="(toppings.value?.length || 0) > 1" class="example-additional-selection">
                                            (+{{(toppings.value?.length || 0) - 1}} {{toppings.value?.length === 2 ? 'other' :
                                            'others'}})
                                        </span>
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                                </mat-select>
                            </div>
                        </div>

     
                    </div>
                   
                </div>
                <div class="col-6">
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Contenu HTML</mat-label>
                        <textarea style="height: 100px;" matInput placeholder="Contenu HTML" required></textarea>
                    </mat-form-field>
                </div>
            </div>


            <div class="row mt-3">
                <div class="col-6">
                    <mat-label>Fichier</mat-label>
                    <app-file-input-select></app-file-input-select>
                </div>

                <div class="col-6">
                    <mat-label>Signature</mat-label>
                    <app-file-input-select></app-file-input-select>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-6">
                    <mat-form-field appearance="outline" class="example-full-width mb-2">
                        <mat-label>Note</mat-label>
                        <textarea style="height: 100px;" matInput placeholder="Note" required></textarea>
                    </mat-form-field>
                </div>
            </div>


            <div class="example-full-width d-flex justify-content-start">
                <button class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>
            </div>
            
        </form>




    </div><!--/. container-fluid -->
</section>
<!-- /.content -->