import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import { Router } from '@angular/router';


import { UserService } from 'src/app/services/user.service';
import { ProfileService } from 'src/app/services/profile.service';
import { RolesService } from 'src/app/services/roles.service';
import { PermissionService } from 'src/app/services/permission.service';

import { Role } from 'src/app/interfaces/role';
import { Permission } from 'src/app/interfaces/permission';
import { Profil } from 'src/app/interfaces/profil';
import { UserModel } from 'src/app/interfaces/user';
import { Response } from '../../interfaces/response';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-new-role',
  templateUrl: './new-role.component.html',
  styleUrls: ['./new-role.component.scss'], encapsulation: ViewEncapsulation.None
})
export class NewRoleComponent implements OnInit {

  @Input() TitleVisible: boolean = true;
  @Input() DisplayConfirmationButton: boolean = true;

    form: FormGroup;

    
    ////////////////////////
    // Attributes
    ///////////////////////

    
    utilisateurs: any;
    permissions: any;

    toppings = new FormControl('');

    toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
    
    usersListCtrl = new FormControl('');
    usersData : UserModel[];//string[];
    usersList : UserModel[];//string[];
    selectedUsers : UserModel[];

    permissionsListCtrl = new FormControl('');
    permissionsList : Permission[];//string[];
    permissionsData : Permission[];//string[];
    selectedPermissions : Permission[];
    
    
    ////////////////////////
    // Constructor
    ///////////////////////

    
    constructor(private router: Router,
		private roleService: RolesService,
		private userService: UserService,
		private permissionService: PermissionService,
		private profileService : ProfileService,
		
		private _snackBar: MatSnackBar,
		public fb: FormBuilder) { 
 
      }
      
    ngOnInit(): void {

	this.refresh();
	this.initForm();
    }
    
    ////////////////////////
    // Datasrouces
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    name: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    label: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),

	    description: new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(2)]),
	});

    }

    ////////////////////////
    // Datasrouces
    ///////////////////////

    refresh(): void {
	
	this.userService.getAllUsers().subscribe({
            next: (response: Response<number, UserModel[]>) => {
		this.usersData = response.Data;
		this.initUsersSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load users complete')
	});

	this.permissionService.getAllPermissions().subscribe({
	    next: (response: Response<number, Permission[]>) => {
		this.permissionsData = response.Data;
		this.initPermissionsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load permissions complete')
	});

    }

    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns
    
    initPermissionsSearchDropdown(){
	this.permissionsList = [];
        for ( let i = 0 ; i < this.permissionsData.length; i ++ ) {
            this.permissionsList.push( this.permissionsData[i] );
        }
    }

    initUsersSearchDropdown(){
	this.usersList = [];
        for ( let i = 0 ; i < this.usersData.length; i ++ ) {
            this.usersList.push( this.usersData[i] );
        }
    }

    ////////////////////////
    // On Search

    onKeyUsers(value) { 
            this.usersList= []; 
            this.selectUserSearch(value);       
    }

    onKeyPermissions(value) { 
            this.permissionsList= []; 
            this.selectPermissionsSearch(value);       
    }

    ////////////////////////
    // search elements

    selectPermissionsSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.permissionsData.length; i ++ ) {
            let option = this.permissionsData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.permissionsList?.push( option );
            }
        }
    }

    selectUserSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.usersData.length; i ++ ) {
            let option = this.usersData[i];
            if (  option.username.toLowerCase().indexOf(filter) >= 0
		|| option.profile?.firstname?.toLowerCase().indexOf(filter) >= 0 
		|| option.profile?.lastname?.toLowerCase().indexOf(filter) >= 0  ) {
                this.usersList?.push( option );
            }
        }
    }


    ////////////////////////
    ///////////////////////
    
    
    ////////////////////////
    // Actions
    ///////////////////////

    
    submit() {

	if (!this.form.valid) {
	    return;
	}

	let role : Role = {
            name: this.form.value.name,
            label: this.form.value.label,
            description: this.form.value.description,
//        usersphoneNumber: this.form.value.users
	};
	role.users = this.selectedUsers;
	role.permissions = this.selectedPermissions;
	console.log(role);
	console.log(this.selectedUsers);
	console.log(this.selectedPermissions);
	console.log("gonna save");

	this.roleService.prepareAddRelations(role).pipe(
	    switchMap(preparedRole => { 
		console.log("entered switch map -- add new role ");
		console.log(preparedRole);
		return this.roleService.add(role);}),
	    switchMap(roleAddedObservable => {
		let roleAdded = roleAddedObservable.Data;
		    
		console.log("entered switch map -- handle post relations ");
		console.log(roleAdded);
		roleAdded.users = this.selectedUsers;
		roleAdded.permissions = this.selectedPermissions;
		return this.roleService.handlePostAddRelations(roleAdded); 
	    })
	
						      
	).subscribe({
	    next: (res) => { console.log(res); },
	    error: (error) => {console.log(error)},
	    complete: () => {
		console.info('save role pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.router.navigate([environment.paths.ListRolePath]);
		    }, 5000);
		
	    }
	    
	    
	});

	


  }

}

/*
	this.form = this.fb.group({
	    nom: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    prenom: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    phone: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$"), Validators.minLength(10)]),
	    email: new FormControl(null, [Validators.required, Validators.email]),
	    password: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
	    passwordcheck: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
	    street: new FormControl(null, [Validators.required, Validators.maxLength(5), Validators.pattern("^[0-9]*$"), Validators.minLength(1)]),
	    city: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    postal: new FormControl(null, [Validators.required, Validators.maxLength(5),  Validators.pattern("^[0-9]*$"), Validators.minLength(5)])
	});*/
