import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';
import { Brand } from 'src/app/interfaces/brand';
import { BrandService } from 'src/app/services/brand.service';
import { environment } from 'src/environments/environment';
import { Response } from '../../interfaces/response';
import { Router } from '@angular/router';


@Component({
  selector: 'app-list-brand',
  templateUrl: './list-brand.component.html',
  styleUrls: ['./list-brand.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ListBrandComponent implements OnInit, AfterViewInit {
    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
    @ViewChild(MatPaginator) paginator: MatPaginator;

    //////////////////////
    // Attributes
    //////////////////////
    
  columns: string[] = ['#', 'name', 'description', 'note', 'status', 'Action' ];
  datasource: MatTableDataSource<Brand> = new MatTableDataSource<Brand>();
  pageSize = 10;
  brands: Brand[];

  color="#f8f8fb";
  toppings = new FormControl('');
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  
  images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);

    isLoading = true;
    
    //////////////////////
    // Constructor
    //////////////////////
    
    constructor(private brandService: BrandService,
		private router: Router,
		public dialog: MatDialog) {

  }

      
    //////////////////////
    // Init
    //////////////////////

    ngAfterViewInit() {
	this.datasource.paginator = this.paginator;
    }

    
    ngOnInit(): void {
      this.refresh();
    }

    //////////////////////
    // Data sources
    //////////////////////
    
    doFilter(value: string) : void  {
	this.datasource.filter = value.trim().toLocaleLowerCase();
	}
    
    refresh() :void{
	this.setLoading(true);
	this.brandService.getAllBrands().subscribe(
	    {
		next: (response: Response<number, Brand[]>) => {
		    this.brands = response.Data;
		    this.datasource.data = this.brands;
		    this.datasource.paginator = this.paginator;
		    this.setLoading(false);
		},
		error: (e) => {
		    this.setLoading(false);
		},
		complete: () => console.info('loadMenu complete')
	    }
	);
    }

    //////////////////////
    // Loading
    //////////////////////

    toggleLoading() : void {
	this.isLoading = !this.isLoading;
    }

    setLoading(loading : boolean) : void {
	this.isLoading = loading;
    }

    //////////////////////
    //////////////////////


    //////////////////////
    // Actions
    ////////////////////// 
    


  onAddNewBrand(): void {
    this.router.navigate([environment.paths.NewBrandPath]);
  }

  onEditBrand(id): void {
      this.router.navigate([environment.paths.EditerBrandPath, id]);
  }

  onConsulterBrand(id): void {
      this.router.navigate([environment.paths.ConsulterBrandPath, id]);
  }

  onDelete(id): void {
    const dialogRef = this.dialog.open(ConfirmerSuppressionDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
	  console.log(id);;


	  this.brandService.deleteById(id).subscribe(
	      {
		  next: (response: Response<number, Brand>) => {
		      console.log(response);
		      this.refresh();
		  },
		  error: (e) => {
		      console.log(e);
		  },
		  complete: () => console.info('delete complete')
	      }
	  );
      }
    });
  }
}
