import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree,  Router  } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

      constructor(private authService: AuthService, private router: Router) {}
    /*canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return true;
      }*/

    canActivate(      
      next: ActivatedRouteSnapshot,
	state: RouterStateSnapshot):  Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // :Observable | Promise | boolean {
	if (!this.authService.isLoggedIn()) {
	    console.log("Not logged in");
            this.router.navigate(['/login']); // go to login if not authenticated
            return false;
      }
    return true;
  }
  
}
