import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { data } from 'jquery';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MenuDto } from '../interfaces/menu-dto';
import { MenuHeader } from '../interfaces/menu-header';
import { MenuJson } from '../interfaces/menu-json';
import { Response as Rep } from '../interfaces/response';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private httpClient: HttpClient) { }

    getMenu(menuHeaderId: number): Observable<Rep<number, MenuJson>> {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });

       return new Observable<Rep<number, MenuJson>>(observer => {
        this.getJSON(menuHeaderId).subscribe((data) => {
          observer.next({
            IsSuccess: 0,
            Data: 
              {
                Id: 1,
                Json: data,
                MenuHeaderId: menuHeaderId
              }
          });
          observer.complete();
        });
      }); 

//      return this.httpClient.get<Rep<number, MenuJson>>( `${environment.domains.menu.GetMenu}?menuHeaderId=${menuHeaderId}`, { headers: headers });
    }

    public getJSON(id: number): Observable<string> {
      return  this.httpClient.get("assets/menu/menu" + id + ".json", { responseType: 'text' });
    }

    getMenuHeaders(): Observable<Rep<number, MenuHeader[]>> {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });

       return new Observable<Rep<number, MenuHeader[]>>(observer => {
        observer.next({
          IsSuccess: 0,
          Data: [
            {
              Id: 1,
              Name: 'OFFICE PLUS'
            },
            {
              Id: 2,
              Name: 'ECOMMERCE'
            }
          ]
        });
        observer.complete();
      }); 
//      return this.httpClient.get<Rep<number, MenuHeader[]>>( `${environment.domains.menu.GetMenuHeader}`, { headers: headers });
    }

}
