import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

// models
import { CompanyGroup, CompanyGroupProduct } from '../interfaces/company-group';
import { ProductCategory } from '../interfaces/product-category';
import { ProductType } from '../interfaces/product-type';
import { Product } from '../interfaces/product';
import { Note } from '../interfaces/note';
import { File } from '../interfaces/file';

// services
import {NoteService} from '../services/note.service'
import {FileService} from '../services/file.service'

// rxjs
import { of, from, concatMap,  map, Observable, exhaustMap, EMPTY, empty ,identity, forkJoin} from 'rxjs';
import { mergeMap, mergeAll, toArray, switchMap} from 'rxjs/operators';



import { Response as Rep } from '../interfaces/response';

import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"

@Injectable({
  providedIn: 'root'
})
export class ProductService extends BaseCrudModel<Product>{
    postFiles = {url: `${environment.domains.api.url}/files`, urlOptions: this.options};
    deleteFiles = {url: `${environment.domains.api.url}/files`, urlOptions: this.options};

    postCompanyGroupProducts = {url: `${environment.domains.api.url}/company-group-products/all`, urlOptions: this.options};
    deleteCompanyGroupProducts = {url: `${environment.domains.api.url}/company-group-products`, urlOptions: this.options};

    constructor(protected override httpService: HttpService,
		private fileService : FileService,
		private noteService : NoteService) {
	super(httpService, "item-types");

	var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "objectDetail"},
		{"relation" : "note"},
		{"relation" : "brand"},
		{"relation" : "mainImage"},
		{"relation" : "images"},
		{"relation" : "companyGroupItemTypes" ,"scope" :
		 { "include" : [
		     {"relation" : "companyGroup" , "scope" : {"include" : [  {"relation" : "companies" } ]} }
		 ]}
		},
		{"relation" : "productType" ,
		 "scope" : {"include" : [  {"relation" : "familyType"} ]}},
	    ],
	});

	var addOptions =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "images"},
		{"relation" : "mainImage"},
		{"relation" : "brand"},
		 {"relation" : "productType"}
	     ],
	    relationsToOmit : [
		'note', 'status',
		'productType', 'brand',
		'dataToUpload', 'imagesDataToUpload', 'mainImage', 'images',
		'companyGroupItemTypes'
	    ]
	});

	this.methods.listing.urlOptions = options;
	this.methods.view.urlOptions = options;
	this.methods.add.urlOptions = addOptions;
	this.methods.update.urlOptions = addOptions;
	
    }

    getAllProducts(): Observable<Rep<number, Product[]>> {
	const options = new RequestOptions();

	return this.getAll()
    }


    getAllProductsTable(): Observable<Rep<number, Product[]>> {
	var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
//		{"relation" : "objectDetail"},
//		{"relation" : "note"},
//		{"relation" : "brand"},
		{"relation" : "mainImage"},
//		{"relation" : "images"},
		{"relation" : "companyGroupItemTypes" ,"scope" :
		 { "include" : [
		     {"relation" : "companyGroup" /*, "scope" : {"include" : [  {"relation" : "companies" } ]}*/ }
		 ]}
		},
		{"relation" : "productType" ,
		 "scope" : {"include" : [  {"relation" : "familyType"} ]}},
	    ],
	});


	return this.getAll(options)
    }

    ////////////////////////////////
    // PREPARE || HANDLE Relations
    ////////////////////////////////
    
    override prepareRelations(fromProduct): Observable<Rep<number, Product>> { //Observable<Profile> {
	// values to save
	const product : Product = fromProduct;


	// Obs Functions
	const failObj : Rep<number, Note> = {IsSuccess:  0, Data:  null, Status : 0};
	const failFile : Rep<number, File[]> = {IsSuccess:  0, Data:  null, Status : 0};
	

	const a$ = of();

	const note$ : Observable<Rep<number, Note>> = product.note ? 
	    ( product.note.id ? this.noteService.updateById(fromProduct.note.id, fromProduct.note) : this.noteService.add(fromProduct.note) )
	    :  this.httpService.createObservableResponse<Note>(failObj);


	const uploadMainImage$ : Observable<Rep<number, File[]>> = (product.mainImage && product.mainImage.dataToUpload ) || product.dataToUpload ?
	    ( product.mainImage && product.mainImage.dataToUpload ?
		this.fileService.uploadFiles(fromProduct.mainImage.dataToUpload) : 
		this.fileService.uploadFiles(fromProduct.dataToUpload)) :
	    this.httpService.createObservableResponse<File[]>(failFile);

 


	return note$
	    .pipe(
		 exhaustMap(note => 
		     {
			 console.log("Created note: \n {0} \n before Setting note , product was : {1}\n".format(JSON.stringify(note), JSON.stringify(product)));
			(product.noteId = note && note.IsSuccess ?
			    (note.Data && note.Data.id ? note.Data.id : product.noteId) : product.noteId );
			
			return  uploadMainImage$;
		    }),
		exhaustMap(fileUploaded => 
		    {

			console.log("created fileUploaded");
			console.log(fileUploaded);
			console.log("before setting FileUploaded");
			console.log(product);
			return fileUploaded && fileUploaded.Data ? this.fileService.addMany(fileUploaded.Data) :
			    this.httpService.createObservableResponse<File[]>(failFile) ;
		    }),
		exhaustMap(files => 
		    {
			var mainImageId = product.id && product.mainImage && product.dataToUpload ? product.mainImage.id : null;
			
			console.log("created fileUploaded");
			console.log(files);

			var  file : File = files && files.Data && files.Data.length > 0 ? files.Data[0] : null; 
			(product.mainImageId = file && files.IsSuccess ?
			    (file && file.id ? file.id : product.mainImageId) : product.mainImageId );

			return mainImageId ?  this.removeMainImage( mainImageId)
			    : this.httpService.createObservableResponse<File[]>({IsSuccess:  1, Data:  files.Data, Status : 0});

		    }),
		map(rel =>
		    {
			console.log("final result");
			console.log(rel);
			// return profile
			return    this.httpService.createResponseFromObj<Product>(product) ;
		    }),
		
	    );
    }


    //////////////////////////
    // HANDLE || PREPARE
    //////////////////////////

    override handleRemoveRelations(product): Observable<any> {
	console.log(product);
	console.log("removePostRelations");

	
	return forkJoin
	(
	    // Remove elements if empty
	    product && product.id && (product.images && product.images?.length > 0 && product.imagesDataToUpload ) ?
		this.removeImages(product.id) :
		this.httpService.createObservableResponse<File[]>({IsSuccess:  0, Data:  undefined, Status : 0}),

	    // add new elements 
	    product && product.id && product.companyGroupItemTypes && product.companyGroupItemTypes?.length >= 0 ? this.removeCompanyGroupProducts(product.id) :
		this.httpService.createObservableResponse<CompanyGroupProduct[]>({IsSuccess:  0, Data:  undefined, Status : 0})

	)

    }

    override handlePostRelations(product): Observable<any> {
	console.log(product);
	console.log("handlePostRelations");

	return forkJoin
	(
	    
	    // add new elements 
	    product && product.id && product.imagesDataToUpload   ? this.addImages(product) :
		this.httpService.createObservableResponse<File[]>({IsSuccess:  0, Data:  undefined, Status : 0}),

	    
	    // add new elements 
	    product && product.id && product.companyGroupItemTypes && product.companyGroupItemTypes?.length > 0 ? this.addCompanyGroupProducts(product.id, product.companyGroupItemTypes) :
		this.httpService.createObservableResponse<CompanyGroupProduct[]>({IsSuccess:  0, Data:  undefined, Status : 0})

	)
    }

    //////////////////////////
    
    //////////////////////////
    // Remove/Detach objects
    //////////////////////////

    public detachMainImage(product):  Observable<Rep<number, any>> {
	product.mainImageId = "";
	return this.updateById(product.id, product);
    }

    
    public removeMainImage(mainImageId):  Observable<Rep<number, any>> {	
	return this.fileService.deleteById(mainImageId);
    }

    public dettachAndRemoveMainImage(product, mainImageId){
	return this.detachMainImage(product).pipe(concatMap(res=> {
	    return this.removeMainImage(mainImageId);
	}));
    }

    

    public removeImages(productId):  Observable<Rep<number, any>> {
	console.log("remove images for productId:{productId}".formatUnicorn({productId : productId}));
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		//{"productId" : productId},
		{"productId" : {"regexp" : "/^{productId}$/".formatUnicorn({productId: productId})}},
	    ],
	});
	//options.body = {"productId" : ""}
	options.bodyParams = {}
	
	//return this.httpService.makePatchRequest<any>( `${this.deleteFiles.url}`, options);
	return this.httpService.makeDeleteRequest<any>( `${this.deleteFiles.url}`, options);
//	return  this.httpService.createObservableResponse<Imageproduct[]>({IsSuccess:  0, Data:  undefined, Status : 0});
    }


    public detachImages(productId):  Observable<Rep<number, any>> {
	console.log("remove images for productId:{productId}".formatUnicorn({productId : productId}));
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		//{"productId" : productId},
		{"productId" : {"regexp" : "/^{productId}$/".formatUnicorn({productId: productId})}},
	    ],
	});
	options.body = {"productId" : ""}
	//options.bodyParams = {}
	
	return this.httpService.makePatchRequest<any>( `${this.deleteFiles.url}`, options);
	//return this.httpService.makeDeleteRequest<any>( `${this.deleteFiles.url}`, options);
	//	return  this.httpService.createObservableResponse<Imageproduct[]>({IsSuccess:  0, Data:  undefined, Status : 0});
    }


    //////////////////////////
    // Add Other objects
    //////////////////////////



    public addImages(fromProduct):  Observable<Rep<number, any>> {

	// values to save
	const product : Product = fromProduct;


	// Obs Functions
	const failFile : Rep<number, File[]> = {IsSuccess:  0, Data:  null, Status : 0};
	
	let imagesDataToUpload = product.images && product.images.length && !product.imagesDataToUpload ?
	    null : null; //product.images.map(img=> img.dataToUpload)


	console.log(imagesDataToUpload);
	const uploadImages$ : Observable<Rep<number, File[]>> =
	    // got images data to upload OR product got images data to upload 
	    (imagesDataToUpload && imagesDataToUpload.length > 0 ) || product.imagesDataToUpload ?
	    // then if product has images AND got  data to upload 
	    ( product.images && imagesDataToUpload && imagesDataToUpload.length > 0
		? this.fileService.uploadFiles(imagesDataToUpload)
		: this.fileService.uploadFiles(product.imagesDataToUpload)) :

	    this.httpService.createObservableResponse<File[]>(failFile);

	return uploadImages$
	    .pipe(
		// upload imagess 
		exhaustMap(imageUploaded => 
		    {

			console.log("created imageUploaded");
			console.log(imageUploaded);
			console.log("before setting ImageUploaded");
			console.log(product);

			return imageUploaded && imageUploaded.Data ? this.fileService.addMany(imageUploaded.Data) :
			    this.httpService.createObservableResponse<File[]>(failFile) ;
			//return    this.httpService.createResponseFromObj<File[]>(fileUploaded) ;
			//			return  uploadFile$;
		    }),
		exhaustMap(imagesAdded => 
		    {

			console.log("created imageUploaded");
			console.log(imagesAdded);
			var  images : File[] = imagesAdded && imagesAdded.Data && imagesAdded.Data.length > 0 ? imagesAdded.Data : null; 
			
			return images && images.length > 0 ?
			    this.assignProductImages(product.id, images) :
			    this.httpService.createObservableResponse<File[]>({IsSuccess:  1, Data:  images, Status : 0});
			//return this.httpService.createObservableResponse<File[]>({IsSuccess:  1, Data:  images.Data, Status : 0});
			
		    }),
		map(rel => {
		    console.log("final result");
		    console.log(rel);
		    return    this.httpService.createResponseFromObj<Product>(product) ;
		})
	    );
		
	

    }

    
    
    //////////////////////////
    // Assign objects
    //////////////////////////
    public assignMainImage(product, mainImage):  Observable<Rep<number, any>> {

	product.mainImageId = mainImage.id;
	return this.updateById(product.id, product);
    }
    
    public assignProductImages(productId, files):  Observable<Rep<number, any>> {
	const arrayFiles = files?.map((r: File): any => (r.id));

	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		{"id" : {"inq" : arrayFiles}},
	    ],
	});

	console.log("Array files");
	console.log(arrayFiles);
	options.body =  {"productId" : productId} ;

	return arrayFiles && arrayFiles.length > 0 ?
	    this.httpService.makePatchRequest<File[]>( `${this.postFiles.url}`.format(), options) :
	    //this.httpService.makePostRequest<SchoolListFile[]>( `${this.postFiles.url}`.format(), options) :
	    this.httpService.createObservableResponse<File[]>({IsSuccess:  0, Data:  undefined, Status : 0});
    }    

    //////////////////////////
    // Remove objects
    //////////////////////////


    
    public removeCompanyGroupProducts(productId):  Observable<Rep<number, any>> {
	console.log("remove companyGroup for productId:{productId}".formatUnicorn({productId : productId}));
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		//{"productId" : productId},
		{"productId" : {"regexp" : "/^{productId}$/".formatUnicorn({productId: productId})}},
	    ],
	});
	//options.body = {"productId" : ""}
	options.bodyParams = {}
	
	return this.httpService.makeDeleteRequest<any>( `${this.deleteCompanyGroupProducts.url}`, options);

    }

    //////////////////////////
    // Add Other objects
    //////////////////////////

    
    public addCompanyGroupProducts(productId, companyGroupProducts):  Observable<Rep<number, any>> {
	
 	
	const arrayCompanyGroupProducts = companyGroupProducts?.map((r: any): CompanyGroupProduct => ({productId : productId, ...r}));

	const options =  new RequestOptions(); 
	console.log("Array products");
	console.log(arrayCompanyGroupProducts);
	options.body =  arrayCompanyGroupProducts; 

	return arrayCompanyGroupProducts && arrayCompanyGroupProducts.length > 0 ?
	    this.httpService.makePostRequest<CompanyGroupProduct[]>( `${this.postCompanyGroupProducts.url}`.format(), options) :
	    this.httpService.createObservableResponse<CompanyGroupProduct[]>({IsSuccess:  0, Data:  undefined, Status : 0});

    }

}
