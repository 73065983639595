<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">AJOUTER UNE ÉCOLE</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Établissements</a></li>
                    <li class="breadcrumb-item active">Écoles</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">



        <h3 style="color: black; font-size:medium">Écoles</h3>
        <h6 style="color: black; font-size: small">Remplir les informations ci-dessous</h6>
        <form class="example-form" [formGroup]="form" (ngSubmit)="submit()">


            <mat-label>Nom</mat-label>
            <input type="text" matInput formControlName="name" class="form-control form-control-sm mb-2" required>
	    <app-show-errors [ctrl]="form.get('name')"></app-show-errors>
	    
            <mat-label>Téléphone</mat-label>
            <input matInput formControlName="phone" class="form-control form-control-sm mb-2" type="text" required>
	    <app-show-errors [ctrl]="form.get('phone')"></app-show-errors>
	    
            <mat-label>Email</mat-label>
            <input type="email" matInput formControlName="email" class="form-control form-control-sm mb-2" required>
	    <app-show-errors [ctrl]="form.get('email')"></app-show-errors>     
	    
            <div class="row mb-2">

                <div class="col-6">
                    <mat-label>Numéro de rue</mat-label>
                    <input class="form-control form-control-sm" matInput type="text" formControlName="street" required>
		    <app-show-errors [ctrl]="form.get('street')"></app-show-errors>     
                </div>
                <div class="col-4">
                    <mat-label>Ville</mat-label>
                    <input formControlName="city" class="form-control form-control-sm" matInput type="text" required>
		    <app-show-errors [ctrl]="form.get('city')"></app-show-errors>     
                </div>
            </div>

            <div class="row mb-2">

                <div class="col-6">
                    <mat-label>Code postal</mat-label>
                    <input class="form-control form-control-sm" matInput type="number" formControlName="zip" required>
		    <app-show-errors [ctrl]="form.get('zip')"></app-show-errors>     
                </div>
                <div class="col-4">
                    <mat-select-country appearance="" label="Pays" (onCountrySelected)="onCountrySelected($event)"
                        required>
                    </mat-select-country>
                </div>
            </div>



            <div class="input-group mb-2">
                <mat-label>Classes</mat-label>&nbsp;
		<span style="display: inline-block;
			     color: red;
			     font-size: xx-small;">
		  *
		</span><span style="display: block;
			     color: black;
			     font-size: x-small;">
		    Attribuer une classe existante
		</span>
                <div class="input-group">
                    <mat-select (selectionChange)="onClassGradesChanged($event)"
						   class="  form-control form-control-sm mb-2" [formControl]="classGradesListCtrl" 
						   [(ngModel)]="selectedClassGrades"
				multiple required>
			<input class="mat-select-input-search" placeholder ="recherche " (keyup)="onKeyClassGrades($event.target.value)"> 
                        <mat-select-trigger>
                            {{classGradesListCtrl.value?.[0]?.name || ''}}
                            <span *ngIf="(classGradesListCtrl.value?.length || 0) > 1" class="example-additional-selection">
                                (+{{(classGradesListCtrl.value?.length || 0) - 1}} {{classGradesListCtrl.value?.length === 2 ? 'autre' :
                                'autres'}})
                            </span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let currentClassGrade of classGradesList" [value]="currentClassGrade">{{currentClassGrade?.classLevel?.name}} - {{currentClassGrade.school?.name}} </mat-option>
                    </mat-select>
                    <div class="input-group-append">
                        <button type="button"class="btn btn-primary  form-control-sm" (click)="addNewClassGrade()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <mat-label>Utilisateurs</mat-label>
            <div class="input-group  mb-2">
                <mat-select placeholder="Choisir les utilisateurs pour cette permission" class="  form-control form-control-sm mb-2" [formControl]="profilesListCtrl" multiple required [(ngModel)]="selectedProfiles">
			<input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyProfiles($event.target.value)"> 
                      <mat-select-trigger>
                        {{profilesListCtrl.value?.[0]?.firstname  || ''}} -  {{profilesListCtrl.value?.[0]?.firstname  || ''}} {{profilesList?.[0]?.lastname  || ''}} 
                        <span *ngIf="(profilesList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedProfiles?.length || 0) - 1}} {{selectedProfiles?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let profile of profilesList" [value]="profile">{{profile.user?.username}} - {{profile?.fistname }} {{profile?.lastname}}</mat-option>
		    </mat-select>
                <div class="input-group-append">
                    <button type="button" class="btn btn-primary  form-control-sm">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>


            <mat-form-field appearance="outline" class="example-full-width mb-1">
                <mat-label>Description</mat-label>
                <textarea style="height: 100px;" matInput formControlName="description" placeholder="Description" required></textarea>
		<app-show-errors [ctrl]="form.get('description')"></app-show-errors>     
            </mat-form-field>

            <mat-form-field appearance="outline" class="example-full-width mb-2">
                <mat-label>Note</mat-label>
                <textarea style="height: 100px;" matInput placeholder="Note" formControlName="note" required></textarea>
		<app-show-errors [ctrl]="form.get('note')"></app-show-errors>     
            </mat-form-field>

            <div class="example-full-width d-flex justify-content-center" *ngIf="DisplayConfirmationButton">
                <button class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>
                <!-- <button mat-raised-button cdkFocusInitial>Annuler</button> -->
            </div>



        </form>




    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
