import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'app-invoice-viewer',
  templateUrl: './invoice-viewer.component.html',
  styleUrls: ['./invoice-viewer.component.scss'], encapsulation: ViewEncapsulation.None
})
export class InvoiceViewerComponent implements OnInit  {
  @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
  
  url = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  public src: Blob;
  constructor(private router: Router, private http: HttpClient) {
    pdfDefaultOptions.assetsFolder = 'bleeding-edge';
  }

  ngOnInit(): void {

    this.http
      .get(
        this.url,
        { responseType: 'blob' }
      )
      .subscribe((res) =>{
        this.src = res;
        console.log("done");
      });

  }

  onDownloadDocument(): void {

  }

}
