import { HttpClient, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import {HttpRequestOptionsService as RequestOptions} from "./http-request-options.service"

import { Response as Rep } from '../../interfaces/response';
@Injectable({
  providedIn: 'root'
})

export class CustomHttpRequestService {
  constructor(private httpClient: HttpClient) {}

    public generateFullParameters<T>(url: string, options: RequestOptions ): any[] {
	return RequestOptions.toArray(url, options);
    }
    
    public makeGetRequest<T>(url: string, options?: RequestOptions ): Observable<Rep<number, T>> {
	console.log(`base url : ${url} `);
	console.log(options);
	// get full url
	var params = this.generateFullParameters(url, options);
	console.log(`url generated  :   ${params["url"]}  ` );
	
	// convert json object to wrapped json object
	// {x:..., y:... }
	// converted to {is_success:true,Data:{x:..., y:... }, status: "ok"}
	// according to status (html status)
	return this.httpClient.get<Rep<number, T>>(params["url"], params["urlOptions"]).pipe(
	    map((res: HttpResponse<Rep<number, T>>) => {
		var success =  res.status == 200;
		console.log(res);
		return  {
		    IsSuccess: res.status != 200 ? 0 : 1,
		    Data: success ? res.body as T : undefined,
		    Status : res.status,
		    //	    Status : res.status,
		    //Msg : success ? res.statusText : "" //res.erro,
		}
	    }),
	    catchError(this.errorHandler),
	    
	);
  }


    public makePostRequest<T>(url: string, options?: RequestOptions): Observable<Rep<number, T>> {

	var params = this.generateFullParameters(url, options);  

	console.log("gonna post :");
	console.log(params["body"]);
	return this.httpClient
	    .post<Rep<number, T>>(params["url"], params["body"], params["urlOptions"])
	    .pipe(

		map((res: HttpResponse<Rep<number,T>>) => {
		    return this.extractResponse<T>(res, 200);
		}),
		catchError(this.errorHandler),
	    );
    }

    public makePutRequest<T>(url: string, options?: RequestOptions): Observable<Rep<number, T>> {

	var params = this.generateFullParameters(url, options);  

	return this.httpClient
	    .put<Rep<number, T>>(params["url"], params["body"], params["urlOptions"])
	    .pipe(

		map((res: HttpResponse<Rep<number,T>>) => {
		    return this.extractResponse<T>(res, 204);
		}),
		catchError(this.errorHandler),
	    );
    }

    public makePatchRequest<T>(url: string,options?: RequestOptions): Observable<Rep<number, T>>  {
	var params = this.generateFullParameters(url, options);
	console.log("Patch {0}".format(JSON.stringify(params["body"])) );

	return this.httpClient
	    .patch<Rep<number, T>>(params["url"], params["body"], params["urlOptions"])
	    .pipe(
		map((res: HttpResponse<Rep<number, T>>) => {
		    return this.extractResponse<T>(res, 200);  
		}),
		catchError(this.errorHandler),
	    );
    }
  
/*  public makePostRequest<T>(url: string, body?: any): Observable<T> {
    return this.httpClient.post<T>(url, body, { observe: "response" }).pipe(
      map((res: HttpResponse<T>) => {
        return res.body;
      })
    );
  }

    
  public makePutRequest<T>(url: string, body?: any): Observable<T> {
    return this.httpClient.put<T>(url, body, { observe: "response" }).pipe(
      map((res: HttpResponse<T>) => {
        return res.body;
      })
    );
  }
*/
    /*
  public makePatchRequest<T>(url: string, body?: any): Observable<T> {
    return this.httpClient.patch<T>(url, body, { observe: "response" }).pipe(
      map((res: HttpResponse<T>) => {
        return res.body;
      })
    );
  }
    */

/*  public makeDeleteRequest<T>(url: string, body?: any): Observable<T> {
    return this.httpClient
      .request<T>("delete", url, {
        body,
        observe: "response"
      })
      .pipe(
        map((res: HttpResponse<T>) => {
          return res.body;
        })
      );
      }*/

    public makeDeleteRequest<T>(url: string, options?: RequestOptions): Observable<Rep<number, T>> {

	var params = this.generateFullParameters(url, options);  

	console.log("Delete");
	console.log(params);
	return this.httpClient
	    .request<Rep<number, T>>("delete", params["url"],  params["urlOptions"])
	    .pipe(
		map((res: HttpResponse<Rep<number,T>>) => this.extractResponse<T>(res, 204) )
	    );
    }

    
	
    public extractResponse<T>(res,successCode) : Rep<number,T> {
	var success =  res.status == successCode;
	console.log(res)
	return  {
	    IsSuccess: success ? 1 : 0,
	    Data: success ? res.body as T : undefined,
	    Status : res.status,
	    //	    Status : res.status,
	    //Msg : success ? res.statusText : "",//: res.body?.error?.message,
	}
    }


    private errorHandler(error: HttpErrorResponse){
	if (error.error instanceof ErrorEvent) {
	    // A client-side or network error occurred. Handle it accordingly.
	    console.error('An error occurred:', error.error.message);
	} else {
	    console.error(error);
	    // The backend returned an unsuccessful response code.
	    // The response body may contain clues as to what went wrong,
	    console.error(
		`Backend returned code ${error.status}, ` +
		    `body was: ${JSON.stringify(error.error)}`);
	}
	// return an observable with a user-facing error message
	//return throwError(
	//  'Something bad happened; please try again later.');
	const errorResult =   new Observable<Rep<number,any>>(x => x.next({
	    IsSuccess:  0,
	    Data: error?.error?.message ? error?.error?.message : undefined,
	    Status : error.status,
	    //	    Status : res.status,
	    //Msg : success ? res.statusText : "",//: res.body?.error?.message,
	}));
	console.log(errorResult);
	return errorResult;
    }
    

    
    public createObservableResponse<T>(obj: Rep<number, T>) : Observable<Rep<number, T>> {
	console.log(obj);

	return new Observable(
	    observer => {
		try {
		    observer.next(obj);
		} catch(err) {
		    observer.error(err);
		} finally {
		    observer.complete();
		}
	    });
    }

    public createObservable(obj: any) : Observable<any> {
	console.log(obj);

	return new Observable(
	    observer => {
		try {
		    observer.next(obj);
		} catch(err) {
		    observer.error(err);
		} finally {
		    observer.complete();
		}
	    });
    }

    public createResponseFromObj<T>(obj:  T) : Rep<number, T> {
	const obs : Rep<number, T> = {IsSuccess:  1, Data:  obj, Status : 0};
	return obs;
    }
    
    public createObservableResponseFromObj<T>(obj:  T) : Observable<Rep<number, T>> {
	const obs : Rep<number, T> = {IsSuccess:  1, Data:  obj, Status : 0}; 
	console.log(obj);

	return new Observable(
	    observer => {
		try {
		    observer.next(obs);
		} catch(err) {
		    observer.error(err);
		} finally {
		    observer.complete();
		}
	    });
    }
    
/*
    private attachSource<T>(source: number, target: () => Observable<T>): Observable<[number, Rep<number,T>]> {
	return target().pipe(map((result) => [source, result]));
    }

    function series<T>(failed: (arg: T) => boolean, ...factories: (() => Observable<Rep<number,T>>)[]): Observable<[number, Rep<number,T>]> {
      return factories.reduce((agr, cur, index) => {
        if (index === 0) {
          return attachSource(index+1, cur);
        } else {
          return agr.pipe(switchMap((prevResult) => failed(prevResult[1]) ? of(prevResult) : attachSource(index+1, cur)));
        }
      }, empty<[number, Rep<number,T>]>());
    }
*/
}
