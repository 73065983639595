<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">NOUVEAU NIVEAU SCOLAIRE</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Établissements</a></li>
                    <li class="breadcrumb-item active">Niveau scolaire</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">



        <h3 style="color: black; font-size:medium">Niveau scolaire</h3>
        <h6 style="color: black; font-size: small">Ajouter un niveau scolaire</h6>
        <form class="example-form" [formGroup]="form" (ngSubmit)="submit()">


            <div class="row">
                <div class="col">
                    <mat-label>Nom</mat-label>
                    <input type="text" matInput formControlName="nom" class="form-control form-control-sm mb-2"
                           required>
		    <app-show-errors [ctrl]="form.get('nom')"></app-show-errors>
                </div>
	    </div>
	     <div class="row">  
                <div class="col">
                    <mat-label>Description</mat-label>
                    <mat-form-field appearance="outline" class="example-full-width mb-1">
           
                        <textarea style="height: 100px;" formControlName="description" matInput placeholder="Description" required></textarea>
			<app-show-errors [ctrl]="form.get('description')"></app-show-errors>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline" class="example-full-width mb-2">
                        <mat-label>Note</mat-label>
                        <textarea style="height: 100px;" matInput formControlName="note" placeholder="Note" required></textarea>
			<app-show-errors [ctrl]="form.get('note')"></app-show-errors>
                    </mat-form-field>
                </div>
            </div>


            <div class="example-full-width d-flex justify-content-center" *ngIf="DisplayConfirmationButton">
                <button class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>
                <!-- <button mat-raised-button cdkFocusInitial>Annuler</button> -->
            </div>



        </form>




    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
