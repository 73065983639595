import { AfterViewInit, Component, EventEmitter, OnInit,  Output, Input,
	 ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Country, MatSelectCountryComponent } from '@angular-material-extensions/select-country';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

// models
import { Model } from 'src/app/interfaces/model';  
import { Order } from 'src/app/interfaces/order';
import { Shipping } from 'src/app/interfaces/shipping';
import { Profil as Profile } from 'src/app/interfaces/profil';   
import { OrderDetail } from 'src/app/interfaces/order-detail';
import { School } from 'src/app/interfaces/school';
import { ClassLevel } from 'src/app/interfaces/class-level';
import { Product } from 'src/app/interfaces/product';
import { Note } from 'src/app/interfaces/note';
import { Status } from 'src/app/interfaces/status';
import { ClassGrade } from 'src/app/interfaces/class-grade';
import { File } from 'src/app/interfaces/file';
import { PaymentType } from 'src/app/interfaces/payment-type';
import { ShippingType } from 'src/app/interfaces/shipping-type';
import { Location } from 'src/app/interfaces/location';  


// services
import { ProfileService } from 'src/app/services/profile.service';
import { OrderService } from 'src/app/services/order.service';
import { ShippingService } from 'src/app/services/shipping.service';
import { SchoolService } from 'src/app/services/school.service';
import { StatusService } from 'src/app/services/status.service';
import { FileService } from 'src/app/services/file.service';
import { ProductService } from 'src/app/services/product.service';
import { ClassLevelService } from 'src/app/services/class-level.service';
import { ClassGradeService } from 'src/app/services/class-grade.service';
import { PaymentTypeService } from 'src/app/services/payment-type.service';
import { ShippingTypeService } from 'src/app/services/shipping-type.service';
import { LocationService } from 'src/app/services/location.service';

//  
import { Router } from '@angular/router';

import { Response } from 'src/app/interfaces/response';

import { switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

//
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';

import { MatSort } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';

import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';


@Component({
  selector: 'app-new-expedition',
  templateUrl: './new-expedition.component.html',
  styleUrls: ['./new-expedition.component.scss'], encapsulation: ViewEncapsulation.None
})
export class NewExpeditionComponent  implements OnInit  {


    ////////////////////////
    // DATATABLE ATTRIBUTES 
    ////////////////////////

    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();   
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    ////////////////////////
    // VIEW ATTRIBUTES 
    ////////////////////////

    
    @ViewChild("fileUpload", {static: false}) fileUpload: ElementRef;

    
    @Input() TitleVisible: boolean = true;
    @Input() DisplayConfirmationButton: boolean = true;
    
    form: FormGroup;
    selectedCountry: Country = undefined;

    
    ////////////////////////
    // Attributes
    ////////////////////////
    
    columns: string[] = ['#', 'Price' ];
    datasource: MatTableDataSource<Model> = new MatTableDataSource<Model>();

    

    files  = [];
    

    ordersListCtrl = new FormControl('');
    ordersList : Order[];//string[];
    ordersData : Order[];//string[];
    selectedOrders : Order[];
    selectedOrder : Order;

    profilesListCtrl = new FormControl('');
    profilesData : Profile[];//string[];
    profilesList : Profile[];//string[];
    selectedProfiles : Profile[];
    selectedProfile : Profile;

    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatus : Status;
    selectedStatuses : Status[];



    locationsListCtrl = new FormControl('');
    locationsList : Location[];//string[];
    locationsData : Location[];//string[];
    selectedLocations : Location[];
    selectedLocation: Location;

    shippingTypesListCtrl = new FormControl('');
    shippingTypesList : ShippingType[];//string[];
    shippingTypesData : ShippingType[];//string[];
    selectedShippingTypes : ShippingType[];
    selectedShippingType : ShippingType;

    paymentTypesListCtrl = new FormControl('');
    paymentTypesList : PaymentType[];//string[];
    paymentTypesData : PaymentType[];//string[];
    selectedPaymentTypes : PaymentType[];

    

    ////////////////////////
    // PRODUCTS ATTRIBUTES 
    ////////////////////////
   
    //
    // Datatable
    //
    columnsSelection: string[] = ['#', 'image', 'product.name', 'product.description', 'price', 'quantity', 'Total', 'Action' ];

    datasourceSelection: MatTableDataSource<Model> = new MatTableDataSource<Model>();

    pageSize = 20;
    
    //
    // Data Sources
    //
    productsListCtrl = new FormControl('');
    productsList : Product[];//string[];
    productsData : Product[];//string[];
    selectedProducts : Product[];

    // selectedProduct Converted 
    selectedProductDetails : any[] = [];
    productDetails : any[] = [];
    
    
    isLoading = true;
    
    ////////////////////////
    // Constructor
    ////////////////////////  
    
    constructor(private router: Router,
		private _snackBar: MatSnackBar,
		public fb: FormBuilder,

		private fileService: FileService,
		private locationService: LocationService,
		private paymentTypeService: PaymentTypeService,
		private shippingTypeService: ShippingTypeService,

		private profileService : ProfileService,
		private statusService : StatusService,
		
		private orderService: OrderService,
		private shippingService: ShippingService,
		private productService: ProductService,
	     ) { 
      
    }

    ///////////////////////
    // Init
    ///////////////////////
    
    ngOnInit(): void {

	this.refresh();
	this.initForm();
	this.initDataTables();
    }

    ngAfterViewInit() {


    }

    getUrlOf(element) : string {
	var path =  element.path ? element.path.replace(/^public?\//i, "") : null;
	return element && element.path ? `${environment.domains.api.url}${path}` : "";
    }

    
    ////////////////////////
    // FORM
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    /*firstname: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    lastname: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    phone: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$"), Validators.minLength(10)]),
	    email: new FormControl(null, [Validators.required, Validators.email]),
	    password: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
	    passwordcheck: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
	    */
	    street: new FormControl(null, [Validators.required, Validators.maxLength(5), Validators.pattern("^[0-9]*$"), Validators.minLength(1)]),
	    city: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    zip: new FormControl(null, [Validators.required, Validators.maxLength(5),  Validators.pattern("^[0-9]*$"), Validators.minLength(5)])
    });
/*    this.datasourceSelection.data = [
      {
        img: "https://img.ltwebstatic.com/images3_pi/2021/08/23/1629685272269c15203b2014d6427c7d6ccf1140f0_thumbnail_900x.webp",
        descr: "Sweet Shirt",
        price: 1233,
        qty: 1
      },
      {
        img: "https://png.pngitem.com/pimgs/s/212-2128969_nike-free-sneakers-shoe-footwear-cartoon-nike-shoes.png",
        descr: "Nike air",
        price: 2033,
        qty: 1
      }
    ];*/
    this.datasource.data = [
      {
        desc: "Total",
        value: 0
      },
      {
        desc: "Promotions",
        value: 0
      },
      {
        desc: "Frais d expedition",
        value: 0
      },
      {
        desc: "Taxes",
        value: 0
      },
      {
        desc: "Total",
        value: 0
      }
    ];
	
    }


        ////////////////////////
    // Datatable
    ///////////////////////

    
    initDatasources() : void {
	
	this.datasourceSelection.data = this.productDetails;
	this.datasourceSelection.paginator = this.paginator;
	this.datasourceSelection.sort = this.sort;
	this.datasourceSelection.filterPredicate = this.tableFilter(); 
	
    }

    initDataTables(): void {
	this.setLoading(true);  
	this.productService.getAll().subscribe({
            next: (response: Response<number, Product[]>) => {
		this.productsData = response.Data;
		this.productDetails = this.productsData.map(product => this.fromProductDataToProductDetail(product));
		
		this.initDatasources(); 
		this.initProductsSearchDropdown();
		this.setLoading(false);
            },error: (e) => { this.setLoading(false); }, complete: () => console.info('load roles complete')
	});


    }

    getCheckedItems() {
	this.selectedProductDetails = this.datasourceSelection.data
	    .filter(i => (i as any).checked == true)
	    .map(product => this.fromProductDetailToProductData(product));
    }

    fromProductDetailToProductData(productDetail) {
	let productData = {
	    productId : productDetail.productId,
	    price : productDetail.price
	    //quantity: productDetail.quantity
	};
	return productData;
    }
    
    fromProductDataToProductDetail(product) {
	let productDetail = {
	    checked : false,
	    productId : product.id,
	    product: product, 
	    price : product.price,
	    quantity: 0,
	    img : product.mainImage? this.getUrlOf(product.mainImage) : ""
	}
	//console.log(productDetail);
	return productDetail;
	
    }
    
    ////////////////////////
    // Filter Datatable
    ///////////////////////

    doFilter(value: string) : void  {
	this.datasourceSelection.filter = value.trim().toLocaleLowerCase();
    }

    
    tableFilter(): (data: any, filter: string) => boolean {
	let columnsNames = this.columnsSelection;

	let filterFunction = function(data, filter): boolean{
	    

	    var datas = columnsNames.map(x => {
		// split string succh as "customer.name"
		var objectData = x.includes(".") ? x.split(".") : [x];
		var currentObject = data;

		// concat array to create object like data["customer"]["name"]
		objectData.forEach(key => {
		    currentObject = currentObject[key];
		})
		// get val and check if its related to filter
		let val = currentObject ?? data[x];
		if (val && val.toString().toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1){		    
		    return  data;
		}
		return null;
		
	    }).filter(x => x); // map and filter to eliminate null data in array

	    
	    return datas?.length > 0 ? true : false; 
	}
	return filterFunction;
     }


    ////////////////////////
    // Datasrouces Form
    ///////////////////////

    refresh() : void {

	this.statusService.getAllStatuses().subscribe({
	    next: (response: Response<number, Status[]>) => {
		
		this.statusesData = response.Data;
		this.initStatusesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load statuses complete')
	});

	this.profileService.getAllProfiles().subscribe({
            next: (response: Response<number, Profile[]>) => {
		this.profilesData = response.Data;
		this.initProfilesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load profiles complete')
	});

	this.orderService.getAll().subscribe({
            next: (response: Response<number, Order[]>) => {
		this.ordersData = response.Data;
		this.initOrdersSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load roles complete')
	});
	
	this.paymentTypeService.getAll().subscribe({
            next: (response: Response<number, PaymentType[]>) => {
		this.paymentTypesData = response.Data;
		this.initPaymentTypesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load paymentType complete')
	});

	this.shippingTypeService.getAll().subscribe({
            next: (response: Response<number, ShippingType[]>) => {
		this.shippingTypesData = response.Data;
		this.initShippingTypesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load roles complete')
	});

	this.locationService.getAllSalePoints().subscribe({
            next: (response: Response<number, Location[]>) => {
		this.locationsData = response.Data;
		this.initLocationsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load roles complete')
	});


	
	// this.productService.getAll().subscribe({
        //     next: (response: Response<number, Product[]>) => {
	// 	this.productsData = response.Data;
	// 	this.datasourceSelection.data = this.productsData.map(product => {
	// 	    let orderDetail = {
	// 		productId : product.id,
	// 		product: product, 
	// 		price : product.price,
	// 		quantity: 0,
	// 		//description : product.description, 
	// 		img : product.mainImage? this.getUrlOf(product.mainImage) : ""
	// 		//img :  "https://img.ltwebstatic.com/images3_pi/2021/08/23/1629685272269c15203b2014d6427c7d6ccf1140f0_thumbnail_900x.webp",
	// 	    }
		    
	// 	    //orderDetail.img= "https://img.ltwebstatic.com/images3_pi/2021/08/23/1629685272269c15203b2014d6427c7d6ccf1140f0_thumbnail_900x.webp";
	// 	    return orderDetail;
	// 	});
	// 	this.initProductsSearchDropdown();
	// 	this.setLoading(false);
        //     },error: (e) => { this.setLoading(false); }, complete: () => console.info('load roles complete')
	// });
    }

    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns

        initStatusesSearchDropdown(){
	this.statusesList = [];
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
	    {

		if (!this.selectedStatus && this.statusesData[i].label == "Actif")
		{
		    this.selectedStatuses = [];
		    this.selectedStatus = this.statusesData[i];
		    
		    this.selectedStatuses.push(this.statusesData[i]);
		}
		this.statusesList.push( this.statusesData[i] );
	    }
        }
    }
    
    initOrdersSearchDropdown(){
	this.ordersList = [];
        for ( let i = 0 ; i < this.ordersData.length; i ++ ) {
            this.ordersList.push( this.ordersData[i] );
        }
    }

    
    initProductsSearchDropdown(){
	this.productsList = [];
        for ( let i = 0 ; i < this.productsData.length; i ++ ) {
            this.productsList.push( this.productsData[i] );
        }
    }


    initLocationsSearchDropdown(){
	this.locationsList = [];
        for ( let i = 0 ; i < this.locationsData.length; i ++ ) {
            this.locationsList.push( this.locationsData[i] );
        }
    }

    initPaymentTypesSearchDropdown(){
	this.paymentTypesList = [];
        for ( let i = 0 ; i < this.paymentTypesData.length; i ++ ) {
            this.paymentTypesList.push( this.paymentTypesData[i] );
        }
    }

    initShippingTypesSearchDropdown(){
	this.shippingTypesList = [];
        for ( let i = 0 ; i < this.shippingTypesData.length; i ++ ) {
            this.shippingTypesList.push( this.shippingTypesData[i] );
        }
    }

        initProfilesSearchDropdown(){
	this.profilesList = [];
        for ( let i = 0 ; i < this.profilesData.length; i ++ ) {
            this.profilesList.push( this.profilesData[i] );
        }
    }

    ////////////////////////
    // On Search

    onKeyStatuses(value) { 
            this.statusesList= []; 
            this.selectStatusesSearch(value);       
    }

    onKeyProfiles(value) { 
            this.profilesList= []; 
            this.selectProfileSearch(value);       
    }

    onKeyProducts(value) { 
            this.productsList= []; 
            this.selectProductSearch(value);       
    }


    onKeyLocations(value) { 
            this.locationsList= []; 
            this.selectLocationSearch(value);       
    }

    onKeyPaymentTypes(value) { 
            this.paymentTypesList= []; 
            this.selectPaymentTypeSearch(value);       
    }


    onKeyShippingTypes(value) { 
            this.shippingTypesList= []; 
            this.selectShippingTypeSearch(value);       
    }

    
    onKeyOrders(value) { 
            this.ordersList= []; 
            this.selectOrdersSearch(value);       
    }

    ////////////////////////
    // search elements

    selectProfileSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.profilesData.length; i ++ ) {
            let option = this.profilesData[i];
            if (  option.user?.username.toLowerCase().indexOf(filter) >= 0
		|| option.firstname?.toLowerCase().indexOf(filter) >= 0 
		|| option.lastname?.toLowerCase().indexOf(filter) >= 0  ) {
                this.profilesList?.push( option );
            }
        }
    }

    selectStatusesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
            let option = this.statusesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.statusesList?.push( option );
            }
        }
    }


    
    selectOrdersSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.ordersData.length; i ++ ) {
            let option = this.ordersData[i];
            if (  option.orderNumber.toLowerCase().indexOf(filter) >=0
		||  option.id.toLowerCase().indexOf(filter) >= 0 ) {
                this.ordersList?.push( option );
            }
        }
    }

    selectProductSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.productsData.length; i ++ ) {
            let option = this.productsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0
		|| option.name?.toLowerCase().indexOf(filter) >= 0  ) {
                this.productsList?.push( option );
            }
        }
    }


    selectLocationSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.locationsData.length; i ++ ) {
            let option = this.locationsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0
		|| option.name?.toLowerCase().indexOf(filter) >= 0  ) {
                this.locationsList?.push( option );
            }
        }
    }


        selectPaymentTypeSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.paymentTypesData.length; i ++ ) {
            let option = this.paymentTypesData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0
		|| option.name?.toLowerCase().indexOf(filter) >= 0  ) {
                this.paymentTypesList?.push( option );
            }
        }
    }

        selectShippingTypeSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.shippingTypesData.length; i ++ ) {
            let option = this.shippingTypesData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0
		|| option.name?.toLowerCase().indexOf(filter) >= 0  ) {
                this.shippingTypesList?.push( option );
            }
        }
    }


    
    ////////////////////////////
    // elements selections
    ///////////////////////////
    onCountrySelected($event: Country) {
	this.selectedCountry = $event;
    }

        onStatusesChanged($event: any) {
      console.log($event);
    }

    
    onOrdersChanged($event: any) {
      console.log($event);
    }

    onProductsChanged($event: any) {
      console.log($event);
    }

    onLocationsChanged($event: any) {
      console.log($event);
    }


    onPaymentTypesChanged($event: any) {
	console.log($event);
    }

    onShippingTypesChanged($event: any) {
	console.log($event);
    }

    
    ////////////////////////////
    // External actions
    ///////////////////////////
    
    addNewOrder(): void {
/*    const dialogRef = this.dialog.open(AddRoleDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {

      }
    });*/
  }
    
    ////////////////////////
    ///////////////////////
    
    //////////////////////
    // Loading
    //////////////////////

    toggleLoading() : void {
	this.isLoading = !this.isLoading;
    }

    setLoading(loading : boolean) : void {
	this.isLoading = loading;
    }

    //////////////////////
    //////////////////////

    ////////////////////////
    // Links / URL
    ///////////////////////

    openInNewTab(namedRoute) {
	let newRelativeUrl = this.router.createUrlTree([namedRoute]);
	console.log(newRelativeUrl);
	let baseUrl = window.location.href.replace(this.router.url, '');

	window.open(baseUrl + newRelativeUrl, '_blank');
    }
    
    onConsulterProduct(id): void {
	this.openInNewTab(`${environment.paths.ConsulterProductPath}/${id}`);
	//this.router.navigate([environment.paths.ConsulterProductPath,id]);
    }
    
    ////////////////////////
    // Actions
    ///////////////////////

    
    
  submit() {
	if (!this.form.valid) {
	    return;
	}

/*	let order : Order = {
            name: this.form.value.name,

	};

	const note : Note = {
	    content : this.form.value.note
	}



	
	order.note = note;
 
	order.products = this.selectedProducts;
	order.order = this.selectedOrder;

	if (this.selectedOrder && this.selectedOrder.id){
	    order.orderId = this.selectedOrder.id;
	}
	
	console.log("Gonna save school list : \n {0}".formatUnicorn(JSON.stringify(order)));

	const fileUpload = this.fileUpload.nativeElement;
	this.files = fileUpload.files;
	const formData = new FormData();

	for (const file of this.files) {
	    console.log("Files : \n {file}".formatUnicorn({file: JSON.stringify(file)}));
	    console.log(file);
	    formData.append('files', file, file.name);
	}
	//formData.append('files', this.form.value.files);
	console.log(formData);
//	console.log("Boundary : {0} ".format(formData.getBoundary()));
	//order.dataToUpload  = this.files[0]; // working for files/image/upload
//	order.dataToUpload  = this.fileService.getBase64(this.files[0]); 
//	order.dataToUpload  = this.files;
	order.dataToUpload  = formData; // working for /files/upload/images
//	order.dataToUpload  = {"files" : this.files};//


	
	this.orderService.prepareAddRelations(order).pipe(
	    switchMap(preparedOrder => { 
		console.log("entered switch map -- add new order ");
		console.log(preparedOrder);
		return this.orderService.add(order);}),
	    switchMap(orderAddedObservable => {
		let orderAdded = orderAddedObservable.Data;
		    
		console.log("entered switch map -- handle post relations ");
		console.log(orderAdded);
		orderAdded.products = this.selectedProducts;
		orderAdded.order = this.selectedOrder;
		return this.orderService.handlePostAddRelations(orderAdded); 
	    })
	
						      
	).subscribe({
	    next: (res) => { this.checkSubmitSucess(res); },
	    error: (error) => {this.submitError(error);},
	    complete: () => {
		console.info('save order pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();
		    }, 5000);
		
	    }
	    
	    
	});
*/
  }

    compare(o1: any, o2: any): boolean {
	// if possible compare by object's name, and not by reference.
	return o1 && o2 ? o1.name === o2.name : o2 === o2;
    }


        ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListListScolairePath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }
    
    myFilter = (d: Date | null): boolean => {
	const day = (d || new Date()).getDay();
	// Prevent Saturday and Sunday from being selected.
	return day !== 0 && day !== 6;
  };

/*

    @Input() TitleVisible: boolean = true;
  @Input() DisplayConfirmationButton: boolean = true;

  form: FormGroup;
  utilisateurs: any;
  permissions: any;

  
  toppings = new FormControl('');

  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

  constructor(private router: Router,
      private _snackBar: MatSnackBar,
      public fb: FormBuilder) { 
 
      }
      
  ngOnInit(): void {
    this.form = this.fb.group({
      nom: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
      prenom: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
      phone: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$"), Validators.minLength(10)]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
      passwordcheck: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
      street: new FormControl(null, [Validators.required, Validators.maxLength(5), Validators.pattern("^[0-9]*$"), Validators.minLength(1)]),
      city: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
      postal: new FormControl(null, [Validators.required, Validators.maxLength(5),  Validators.pattern("^[0-9]*$"), Validators.minLength(5)])
    });
  }

  onCountrySelected($event: Country) {
    this.selectedCountry = $event;
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
  }
*/
}
