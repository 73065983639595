<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">VOIR DOCUMENT</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Documents</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 30px;">

        <div class="d-flex justify-content-end">
            <button class="btn btn-primary btn-sm mr-3"(click)="onShowDocument()" >Voir le PDF</button>
            <button class="btn btn-primary btn-sm"(click)="onEditDocument()" >Editer le document</button>
        </div>

        <label>Spécifications</label>
        <div class="lateral-space">
   
            <div class="row mt-3 w-100 left-background">
                <div class="col-6 left">
                    Type de document
                </div>
                <div class="col-6 right">
                    Papier
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Destination
                </div>
                <div class="col-6 right">
                   Paul, Robert
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    Commandes
                </div>
                <div class="col-6 right">
                    <a href="#">Voir la commande</a>
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Produits
                </div>
                <div class="col-6 right">
                    <a href="#">Voir le produit</a>
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    PDF
                </div>
                <div class="col-6 right">
                    <a href="#">Voir le pdf</a>
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Note
                </div>
                <div class="col-6 right">
                    .......
                </div>
            </div>
        </div>




        <label class="mt-5">Informations complémentaires</label>
        <div class="lateral-space">
   
            <div class="row mt-3 w-100 left-background">
                <div class="col-6 left">
                    Créé le 12/02/2023
                </div>
                <div class="col-6 right">
                    Modifié le 12/02/2023
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Créé par
                </div>
                <div class="col-6 right">
                    admin@gmail.com
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    Modifié par
                </div>
                <div class="col-6 right">
                    resposabel@test.com
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Status
                </div>
                <div class="col-6 right d-flex justify-content-center">
                    Actif
                </div>
            </div>
        </div>
    </div><!--/. container-fluid -->
</section>
<!-- /.content -->