<div class="row">

    <div class="col-4 px-2">
        <!-- <img class="previewImage mb-2" *ngFor="let img of Images" [src]="img"/> -->
        <mat-accordion  *ngIf="Images"  >
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false"
				 *ngFor="let item of [1]; first as isFirst" [expanded]="isFirst">
                                 <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span class="material-icons">
                                            search
                                        </span>
					<span *ngIf="Images && Images.length > 1"> Images </span>
					<span *ngIf="Images && Images.length == 1"> Image </span>
                                    </mat-panel-title>
                                    <mat-panel-description>
                                   
                                    </mat-panel-description>
                                  </mat-expansion-panel-header>
                                 <img class="previewImage mb-2" style="cursor: pointer;" *ngFor="let img of Images" [src]="img" (click)="onClick(img)"/>
            </mat-expansion-panel>
          </mat-accordion>
    </div>

    <div class="col-8">
        <img [src]="currentImage" class="selectedImg"/>
    </div>
</div>
