import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

// models
import { ProductCategory } from '../interfaces/product-category';
import { ProductType } from '../interfaces/product-type';
import { Product } from '../interfaces/product';
import { Note } from '../interfaces/note';

// services
import {NoteService} from '../services/note.service'

// rxjs
import { of, from,  map, Observable, exhaustMap, EMPTY, empty ,identity, forkJoin} from 'rxjs';
import { mergeMap, mergeAll, toArray, switchMap} from 'rxjs/operators';



import { Response as Rep } from '../interfaces/response';

import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"


@Injectable({
  providedIn: 'root'
})
export class ProductCategoryService extends BaseCrudModel<ProductCategory>{

    postProductTypes = {url: `${environment.domains.api.url}/product-types`, urlOptions: this.options};
    deleteProductTypes = {url: `${environment.domains.api.url}/product-types`, urlOptions: this.options};
    
    constructor(protected override httpService: HttpService,
	       private noteService : NoteService) {
	super(httpService, "family-types");

	    	var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "objectDetail"},
		{"relation" : "note"},
		{"relation" : "productTypes" ,
		 "scope" : {"include" : [  {"relation" : "products"} ]}},
	    ],
	});

	var addOptions =  RequestOptions.FromArray({
	    relationsToOmit : ['note', 'status', 'productTypes']
	});

	this.methods.listing.urlOptions = options;
	this.methods.view.urlOptions = options;
	this.methods.add.urlOptions = addOptions;
	this.methods.update.urlOptions = addOptions;

    }

    getAllProductCategories(): Observable<Rep<number, ProductCategory[]>> {
	const options = new RequestOptions();

	return this.getAll()
    }




    ////////////////////////////////
    // PREPARE || HANDLE Relations
    ////////////////////////////////
    
    override prepareRelations(fromProductType): Observable<Rep<number, ProductType>> { //Observable<Profile> {
	// values to save
	const productCategory : ProductType = fromProductType;


	// Obs Functions
	const failObj : Rep<number, Note> = {IsSuccess:  0, Data:  null, Status : 0};

	const a$ = of();

	const note$ : Observable<Rep<number, Note>> = productCategory.note ? 
	    ( productCategory.note.id ? this.noteService.updateById(fromProductType.note.id, fromProductType.note) : this.noteService.add(fromProductType.note) )
	    :  this.httpService.createObservableResponse<Note>(failObj);

	
	return note$
	    .pipe(
		map(note => 
		    {
			console.log("created note");
			console.log(note);
			console.log("before setting Note");
			console.log(productCategory);
			(productCategory.noteId = note && note.IsSuccess ?
			    (note.Data && note.Data.id ? note.Data.id : productCategory.noteId) : productCategory.noteId );
			
			return   this.httpService.createResponseFromObj<ProductType>(productCategory)
		    }),
		map(rel =>
		    {
			console.log("final result");
			console.log(rel);
			// return profile
			return    this.httpService.createResponseFromObj<ProductType>(productCategory) ;
		    }),
		
	    );
    }

    //////////////////////////
    // HANDLE || PREPARE
    //////////////////////////

    override handleRemoveRelations(productCategory): Observable<any> {
	console.log("removePostRelations for productCategory : \n {0}".format(JSON.stringify(productCategory)));

	return forkJoin
	(
	    // Remove elements if empty
	    productCategory && productCategory.id && (productCategory.productTypes && productCategory.productTypes?.length >= 0) ? this.removeProductTypes(productCategory.id) :
		this.httpService.createObservableResponse<ProductType[]>({IsSuccess:  0, Data:  undefined, Status : 0})

	)
    }
    
    override handlePostRelations(productCategory): Observable<any> {
	console.log(productCategory);
	console.log("handlePostRelations");
	    return forkJoin
	(
	    
	    // add new elements 
	    productCategory && productCategory.id && productCategory.productTypes && productCategory.productTypes?.length > 0 ? this.addProductTypes(productCategory.id, productCategory.productTypes) :
		this.httpService.createObservableResponse<ProductType[]>({IsSuccess:  0, Data:  undefined, Status : 0})
	)
    }

    //////////////////////////
    // Add Other objects
    //////////////////////////

    public removeProductTypes(productCategoryId):  Observable<Rep<number, any>> {
	console.log("remove productCategory for productCategoryId:{productCategoryId}".formatUnicorn({familyTypeId : productCategoryId}));
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		//{"productCategoryId" : productCategoryId},
		{"familyTypeId" : {"regexp" : "/^{familyTYpeId}$/".formatUnicorn({familyTypeId: productCategoryId})}},
	    ],
	});
	options.body = {"familyTypeId" : ""}
	return this.httpService.makePatchRequest<any>( `${this.deleteProductTypes.url}`, options);
//	return  this.httpService.createObservableResponse<ProductTypeProductType[]>({IsSuccess:  0, Data:  undefined, Status : 0});
    }


    //////////////////////////
    // Add Other objects
    //////////////////////////

    public addProductTypes(productCategoryId, productTypes):  Observable<Rep<number, any>> {
	
 	
	//const arrayProductTypes = productTypes?.map((r: any): ProductType => ({productCategoryId : productCategoryId, productCategoryId: r.id}));

	const arrayProductTypes = productTypes?.map((r: ProductType): any => (r.id));

	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		{"id" : {"inq" : arrayProductTypes}},
	    ],
	});
	console.log("Array productTypes");
	console.log(arrayProductTypes);
	options.body =  {"familyTypeId" : productCategoryId} ;//arrayProductTypes;	

	return arrayProductTypes && arrayProductTypes.length > 0 ?
	    this.httpService.makePatchRequest<ProductType[]>( `${this.postProductTypes.url}`.format(), options) :
	    this.httpService.createObservableResponse<ProductType[]>({IsSuccess:  0, Data:  undefined, Status : 0});
	//Observable.empty<number, any>() ;
    }
}
