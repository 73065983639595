<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">LISTE DES COMMANDES</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Commande</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content bdy" style="background-color: white;">
    <div class="container-fluid" style="padding: 30px;">


        <!--<button class="btn btn-primary btn-sm" (click)="onAddNewOrder()">Ajouter une commande</button>-->


        <div class="row mb-3 mt-3">
<!--
            <div class="col-2">
                <mat-label>Client</mat-label>

                <mat-select   placeholder="Choisir un client" class="  form-control form-control-sm mb-2" [formControl]="customersListCtrl" multiple required>
                    <mat-select-trigger>
                        {{customersListCtrl.value?.[0]?.name || ''}}
                        <span *ngIf="(customersList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(customersList?.length || 0) - 1}} {{customersList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let customer of customersList" [value]="customer">{{customer.profile?.firstname}} {{customer.profile?.lasstname}}</mat-option>
                </mat-select>
            </div>
            <div class="col-2">
                <mat-label>Type de paiement</mat-label>

                <mat-select   placeholder="Choisir une methode de paiement" class="  form-control form-control-sm mb-2" [formControl]="paymentTypesListCtrl" multiple required>
                    <mat-select-trigger>
                        {{paymentTypesListCtrl.value?.[0]?.name || ''}}
                        <span *ngIf="(paymentTypesList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(paymentTypesList?.length || 0) - 1}} {{paymentTypesList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let paymentType of paymentTypesList" [value]="paymentType">{{paymentType.name}}</mat-option>
                </mat-select>
            </div>
            <div class="col-4">
                <mat-label>Status de la commande</mat-label>
		<mat-select placeholder="Choisir un status " class="  form-control form-control-sm mb-2" [formControl]="statusesListCtrl" multiple required>
                    <mat-select-trigger>
                        {{statusesListCtrl.value?.[0]?.label || ''}}
                        <span *ngIf="(statusesList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(statusesList?.length || 0) - 1}} {{statusesList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
		</mat-select>
				
            </div>
            <div class="col-4">
                <mat-label>Status du paiement</mat-label>
		<mat-select placeholder="Choisir un paymentStatus " class="  form-control form-control-sm mb-2" [formControl]="paymentStatusesListCtrl" multiple required>
                    <mat-select-trigger>
                        {{paymentStatusesListCtrl.value?.[0]?.label || ''}}
                        <span *ngIf="(paymentStatusesList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(paymentStatusesList?.length || 0) - 1}} {{paymentStatusesList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let paymentStatus of paymentStatusesList" [value]="paymentStatus">{{paymentStatus.label}}</mat-option>
		</mat-select>
				
            </div>
-->
        </div>

        <div class="table-responsive">
	    <div fxLayout fxLayoutAlign="end center">
		<mat-form-field fxFlex="60%">
		    <input matInput type="text" (keyup)="doFilter($event.target.value)"  name="filter" placeholder="Filtrer">
		</mat-form-field>
	    </div>
		    
            <table mat-table [dataSource]="datasource" matSort>

                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">
                        <mat-checkbox></mat-checkbox>
                    </th>
                    <td style="width: 4%;" mat-cell *matCellDef="let element">
                        <!--<mat-checkbox></mat-checkbox> --> {{element.id}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="orderNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Numero de commande</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.orderNumber}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="customer.profile.lastname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Nom du client</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.customer?.profile?.firstname}} {{element.customer?.profile?.lastname}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Date d'achat</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element?.objectDetail?.creationDate |  date:'dd/MM/yyyy' }}   </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Prix total</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.total | number : '1.2-2' }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="paymentStatus.label">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Etat paiement
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge badge-pill badge-info">{{element.paymentStatus?.label}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="paymentMean.label">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Methode de paiement</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge badge-pill badge-primary">{{element.paymentMean?.label}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Actions</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon matTooltip="consulter" (click)="onConsulterOrder(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="preview"></mat-icon>
                        <!--<mat-icon matTooltip="editer" (click)="onEditOrder(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="edit"></mat-icon>-->
                        <mat-icon matTooltip="supprimer" (click)="onDelete(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="delete"></mat-icon>
                    </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;" class="example-element-row"></tr>
            </table>

	    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
		<mat-progress-spinner 
		    color="primary" 
		    mode="indeterminate">
		</mat-progress-spinner>
	    </mat-card>
		    
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="pageSize" showFirstLastButtons
                aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>


    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
