import { AfterViewInit, Component, EventEmitter,
	 OnInit, ChangeDetectorRef,
	 Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';
import { Profil } from 'src/app/interfaces/profil';
import { User } from 'src/app/interfaces/user';
import { UserService } from 'src/app/services/user.service';
import { ProfileService } from 'src/app/services/profile.service';
import { environment } from 'src/environments/environment';
import { Response } from '../../interfaces/response';
import { Router } from '@angular/router';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';
import { FlexLayoutModule } from '@angular/flex-layout';
import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@Component({
  selector: 'app-list-account',
  templateUrl: './list-account.component.html',
  styleUrls: ['./list-account.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ListAccountComponent  implements OnInit, AfterViewInit {


    isLoading = true;
    
    columns: string[] = ['#', 'firstname', 'email', 'role.name', 'permission.name',/* 'profileType',*/ 'phoneNumber', 'Action' ];
    datasource: MatTableDataSource<Profil> = new MatTableDataSource<Profil>();
    pageSize = 10;
    profiles: Profil[];
    color="#f8f8fb";


    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    
    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();    

    constructor(private userService: UserService,
		private profileService: ProfileService,
		private router: Router,
		public dialog: MatDialog,
	        private changeDetectorRefs: ChangeDetectorRef) {

  }

    //////////////////////
    // Init
    //////////////////////
    
    ngAfterViewInit() {
	this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort;
    }
    
    ngOnInit(): void {
	//      this.userService.getAllProfil().subscribe(
	this.refresh();
    }

    //////////////////////
    // Data sources
    //////////////////////
    
    doFilter(value: string) : void  {
	this.datasource.filter = value.trim().toLocaleLowerCase();
    }
    
    
    refresh(): void {
	this.setLoading(true);

	this.profileService.getAllProfiles().subscribe(
	  {
              next: (response: Response<number, Profil[]>) => {
		  console.log(response);
		  this.profiles = response.Data;
		  
		  this.datasource.data = this.profiles;
		  this.datasource.paginator = this.paginator;

		  console.log(this.profiles);
		  this.setLoading(false);
		  //this.datasource.connect().next(this.profiles);
              },
              error: (e) => {
		  console.log(e);
		  this.setLoading(false);
              },
              complete: () => console.info('loaduser complete')
	  }
      );
      
  }
    
    //////////////////////
    // Loading
    //////////////////////

    toggleLoading() : void {
	this.isLoading = !this.isLoading;
    }

    setLoading(loading : boolean) : void {
	this.isLoading = loading;
    }

    //////////////////////
    //////////////////////

    

    //////////////////////
    // Actions
    //////////////////////
    
    onAddNewUser(): void {
	this.router.navigate([environment.paths.NewUserAccountPath]);
    }
    
    onEditProfil(id): void {
	this.router.navigate([environment.paths.EditerUtilisateurPath, id]);
    }
    
    onConsulterProfil(id): void {
	this.router.navigate([environment.paths.ConsulterProfilPath, id]);
    }
    
    onDelete(id): void {
	const dialogRef = this.dialog.open(ConfirmerSuppressionDialogComponent, {
	    disableClose: true,
	    data: { TitleVisible: false, DisplayConfirmationButton: false },
	});

	dialogRef.afterClosed().subscribe((result: boolean) => {
	    if (result) {
		console.log(id);;


	  this.profileService.deleteById(id).subscribe(
	      {
		  next: (response: Response<number, Profil>) => {
		      console.log(response);
		      this.refresh();
		  },
		  error: (e) => {
		      console.log(e);
		  },
		  complete: () => console.info('delete complete')
	      }
	  );


	  
	    }
	});
    }

    //////////////////////
    

}
