import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';
import { ClassLevel } from 'src/app/interfaces/class-level';
import { ClassLevelService } from 'src/app/services/class-level.service';
import { environment } from 'src/environments/environment';
import { Response } from '../../interfaces/response';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-niveau-scolaire',
  templateUrl: './list-niveau-scolaire.component.html',
  styleUrls: ['./list-niveau-scolaire.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ListNiveauScolaireComponent  implements OnInit, AfterViewInit {
    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
    @ViewChild(MatPaginator) paginator: MatPaginator;

    //////////////////////
    // Attributes 
    //////////////////////

    columns: string[] = ['#', 'name', 'description', 'note', 'status',  'Action' ];
    datasource: MatTableDataSource<ClassLevel> = new MatTableDataSource<ClassLevel>();
    pageSize = 10;
    classLeveles: ClassLevel[];
  
    color="#f8f8fb";
    toppings = new FormControl('');

    toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

    isLoading = true;

    //////////////////////
    // Constructor 
    //////////////////////
    
  constructor(private classLevelService: ClassLevelService,
    private router: Router,
    public dialog: MatDialog) {

  }
    //////////////////////
    // Init
    //////////////////////

    ngAfterViewInit() {
	this.datasource.paginator = this.paginator;
    }

    
    ngOnInit(): void {
      this.refresh();
    }

    //////////////////////
    // Data sources
    //////////////////////
    
    doFilter(value: string) : void  {
	this.datasource.filter = value.trim().toLocaleLowerCase();
	}
    
    refresh() :void{
	this.setLoading(true);
	this.classLevelService.getAllClassLevels().subscribe(
	    {
		next: (response: Response<number, ClassLevel[]>) => {
		    this.classLeveles = response.Data;
		    this.datasource.data = this.classLeveles;
		    this.datasource.paginator = this.paginator;
		    this.setLoading(false);
		},
		error: (e) => {
		    this.setLoading(false);
        },
        complete: () => console.info('loadMenu complete')
      }
    );
  }
    //////////////////////
    // Loading
    //////////////////////

    toggleLoading() : void {
	this.isLoading = !this.isLoading;
    }

    setLoading(loading : boolean) : void {
	this.isLoading = loading;
    }

    //////////////////////
    //////////////////////

    
    //////////////////////
    // Actions
    //////////////////////
    
  onAddNewNiveau(): void {
    this.router.navigate([environment.paths.NewNiveauScolairePath]);
  }

  onEditNiveau(id): void {
      this.router.navigate([environment.paths.EditerNiveauScolairePath,id]);
  }

  onConsulterNiveau(id): void {
      this.router.navigate([environment.paths.ConsulterNiveauScolairePath,id]);
  }

  onDelete(id): void {
    const dialogRef = this.dialog.open(ConfirmerSuppressionDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
	  console.log(id);;


	  this.classLevelService.deleteById(id).subscribe(
	      {
		  next: (response: Response<number, ClassLevel>) => {
		      console.log(response);
		      this.refresh();
		  },
		  error: (e) => {
		      console.log(e);
		  },
		  complete: () => console.info('delete complete')
	      }
	  );
      }
    });
  }
}
