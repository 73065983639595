<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">VOIR CLIENTS</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Clients</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;"  *ngIf="_customer">
    <div class="container-fluid" style="padding: 30px;">

        <div class="d-flex justify-content-end">
            <button class="btn btn-primary btn-sm" (click)="onEditCustomer(_customer.id)">Éditer un client</button>
        </div>

        <label>Information personnelle</label>
        <div class="lateral-space">


            <div class="row mt-3 w-100 left-background">
                <div class="col-6 left">
                    Nom d'utilisateur
                </div>
                <div class="col-6 right">
                    {{_customer?.profile?.user?.username}}
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Nom
                </div>
                <div class="col-6 right">
                    <span  *ngIf="_customer?.profile"
			   (click)="goTo('profiles', _customer?.profile?.id )">
			{{_customer?.profile?.lastname}}
		    </span>
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    Prénom
                </div>
                <div class="col-6 right">
                    {{_customer?.profile?.firstname}}
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Email
                </div>
                <div class="col-6 right">
                    {{_customer?.profile?.email}}
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    Téléphone
                </div>
                <div class="col-6 right">
                    {{_customer?.profile?.phoneNumber}}
                </div>
            </div>
	</div>

	<div class="row mt-5" >
	    <div class="col-md-12 col-sm-12">

		<label>Voir client</label>
		<div class="lateral-space">
		    <div class="row mt-3 w-100 left-background ">
			<div class="col-6 left">
			    Société
			</div>
			<div class="col-6 right">
			    <span *ngIf="_customer && _customer?.company">
				<span  *ngIf="_customer?.company"
				       (click)="goTo('companies', _customer?.company?.id )">
				    <span class="badge badge-pill badge-info"> {{_customer.company?.name}} </span>
				</span>


			    </span>
			</div>
		    </div>
		    <div class="row mt-3 w-100 left-background ">
			<div class="col-6 left">
			    SIRET
			</div>
			<div class="col-6 right">
			    <span *ngIf="_customer && _customer?.company">
				<span class="badge badge-pill badge-info"> {{_customer.company?.siret}} </span>
			    </span>
			</div>
		    </div>
		    <div class="row mt-3 w-100 ">
			<div class="col-6 left">
			    Groupe
			</div>
			<div class="col-6 right">
			    <span *ngIf="_customer && _customer?.company && _customer?.company?.companyGroup">
				<span class="badge badge-pill badge-warning"> {{_customer.company?.companyGroup?.label}} </span>
			    </span>
			</div>
		    </div>
		    <div *ngIf="_customer.orders" class="row mt-3 w-100 left-background">
			<div class="col-6 left">
			    Commandes
			</div>
			<div class="col-6 right">
			    <span *ngIf="_customer.orders && _customer.orders?.length > 0">
				<div class="col-6 right"  *ngIf="3 as maxNbOrders">
				    <a  [routerLink]="" (click)="show_orders = !show_orders" >Voir la liste des commandes</a>

				    <span *ngIf="show_orders;">
					<span *ngFor="let order of _customer?.orders;nb_orders as index;let i = index;let c = count">

					    <span *ngIf="i < maxNbOrders || more_orders;  "  class="badge badge-pill badge-info"> #CMD_{{order?.orderNumber}}</span>
					    <button mat-button  color="primary" *ngIf="i  + 1== c && c > maxNbOrders" (click)="more_orders = !more_orders">{{ more_orders ? 'voir moins' : 'voir plus' }}</button>
					</span>
				    </span>
				</div>
			    </span>
			</div>
		    </div>


		</div>
	    </div>
	</div>







        <div class="row mt-5">
            <div class="col-md-6 col-sm-12">
                <label>Adresse </label>
                <div class="lateral-space">

                    <div class="row mt-3 w-100 left-background">
                        <div class="col-6 left">
                            Rue
                        </div>
                        <div class="col-6 right">
                            {{_customer?.shippingAddress?.street}}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Ville
                        </div>
                        <div class="col-6 right">
			    {{_customer?.shippingAddress?.city}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Code postal
                        </div>
                        <div class="col-6 right">
			    {{_customer?.shippingAddress?.zip}}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Pays
                        </div>
                        <div class="col-6 right">
                            {{_customer?.shippingAddress?.country}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Details additionnels
                        </div>
                        <div class="col-6 right">
 			    {{_customer?.shippingAddress?.additionalDetail}}

                        </div>
                    </div>
                </div>
            </div>



            <div class="col-md-6 col-sm-12">
                <label>Adresse de facturation</label>
                <div class="lateral-space">

                    <div class="row mt-3 w-100 left-background">
                        <div class="col-6 left">
                            Rue
                        </div>
                        <div class="col-6 right">
                            {{_customer?.billingAddress?.street}}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Ville
                        </div>
                        <div class="col-6 right">
			    {{_customer?.billingAddress?.city}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Code postal
                        </div>
                        <div class="col-6 right">
			    {{_customer?.billingAddress?.zip}}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Pays
                        </div>
                        <div class="col-6 right">
                            {{_customer?.billingAddress?.country}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Details additionnels
                        </div>
                        <div class="col-6 right">
 			    {{_customer?.billingAddress?.additionalDetail}}

                        </div>
                    </div>
                </div>
            </div>

	</div>

	<div class="row mt-5">
	    <div class="col-md-12 col-sm-12">
		<div class="lateral-space">

                    <div class="row w-100 left-background">
			<div class="col-6 left">
                            Créé le {{_customer?.objectDetail?.creationDate |  date:'dd/MM/yyyy' }}
			</div>
			<div class="col-6 right">
                            Modifié le  {{_customer?.objectDetail?.modificationDate |  date:'dd/MM/yyyy' }}
			</div>
                    </div>
                    <div class="row w-100">
			<div class="col-6 left">
                            Créé par
			</div>
			<div class="col-6 right">
                            {{_customer?.objectDetail?.creator?.username}}
			</div>
                    </div>
                    <div class="row w-100 left-background">
			<div class="col-6 left">
                            Modifié par
			</div>
			<div class="col-6 right">
                            {{_customer?.objectDetail?.updater?.username}}
			</div>
                    </div>
                    <div class="row w-100">
			<div class="col-6 left">
                            Status
			</div>
			<div class="col-6 right d-flex justify-content-center">
                            <span class="badge badge-pill badge-primary">{{_customer?.status?.label}}  </span>
			</div>
                    </div>
		</div>
            </div>
	</div>
    </div>

    <!--/. container-fluid -->
</section>
<!-- /.content -->
