import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import { RolesService } from 'src/app/services/roles.service';
import { Role } from 'src/app/interfaces/role';

import { Response } from '../../interfaces/response';
import {ActivatedRoute} from '@angular/router';      

@Component({
  selector: 'app-consulter-role',
  templateUrl: './consulter-role.component.html',
  styleUrls: ['./consulter-role.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ConsulterRoleComponent  implements OnInit  {
  @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
    
    ////////////////////////
    // Attributes
    ///////////////////////

    @Input()     _role: Role;
    @Input('id') _id?: string;

    ////////////////////////
    // Constructor
    ///////////////////////
    
    constructor(private router: Router,
	        private route: ActivatedRoute,
	       private roleService: RolesService) {
  }

    ////////////////////////
    // Init
    ///////////////////////
    
    ngOnInit(): void {

	this.initCurrentRoute();

	this.refresh();
	
	
    }

    initCurrentRoute():void {
	this.route.params.subscribe(params => {
	    this._id = params["id"];
	    console.log("current id " + this._id)
	});

    }

    initCurrentObject(): void {
      this.roleService.getById(this._id).subscribe(
	  {
              next: (response: Response<number, Role>) => {
		  console.log("response");
		  console.log(response);
		  this._role = response.Data;
		  
		  console.log(this._role);
		  
              },
              error: (e) => {
		  console.log(e);
              },
              complete: () => console.info('loaduser complete')
	  }
      );

    }

    ////////////////////////
    // Datasrouces
    ///////////////////////

    
    refresh(): void {
	this.initCurrentObject();
    }

    
    ////////////////////////
    // Actions
    ///////////////////////
    
    onEditRole(id): void {
	this.router.navigate([environment.paths.EditerRolePath, id]);
    }

}
