<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">VOIR LA LISTE SCOLAIRE</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Établissements</a></li>
                    <li class="breadcrumb-item active">Liste scolaire</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;" *ngIf="_schoolList">
    <div class="container-fluid" style="padding: 30px;">

        <div class="d-flex justify-content-end">
            <button class="btn btn-primary btn-sm" (click)="onEditListeScolaire(_schoolList.id)" >Editer la liste</button>
        </div>

        <label>Voir Liste scolaire</label>

	<mat-stepper orientation="horizontal">

            <mat-step>
		
		
		<ng-template matStepLabel><mat-icon>local_shipping</mat-icon> Information du groupe </ng-template>
		<ng-template matStepContent>
		    
		    <h6 style="color: black; font-size: small">Voir les informations de la liste  ci-dessous</h6>

		    <div class="lateral-space">
			
			<div class="row mt-3 w-100 left-background">
			    <div class="col-6 left">
				Nom
			    </div>
			    <div class="col-6 right normal">
				{{_schoolList.name}} - {{_schoolList.classGrade?.classLevel?.name}} - {{_schoolList.classGrade?.school?.name}}
			    </div>
			</div>
			<div class="row w-100">
			    <div class="col-6 left">
				Classe
			    </div>
			    <div class="col-6 right link">
				{{_schoolList.classGrade?.classLevel?.name}} - {{_schoolList.classGrade?.school?.name}} 
			    </div>
			</div>
			<div class="row w-100 left-background">
			    <div class="col-6 left">
				Note
			    </div>
			    <div class="col-6 right normal">
				{{_schoolList.note?.content}}  
			    </div>
			</div>
			<div class="row  w-100">
			    <div class="col-6 left">
				Fichier PDF
			    </div>
			    <div class="col-6 right">
				<a  href="{{getUrlOf(_schoolList.file)}}"  *ngIf="(_schoolList?.file && _schoolList.file?.path)" >Voir le pdf</a>
			    </div>
			</div>
			<div class="row w-100 left-background">
			    <div class="col-6 left">
				Produits
			    </div>
			    <div class="col-6 right"  *ngIf="3 as maxNbProducts">
				<a  [routerLink]="" (click)="show_products = !show_products" >Voir la liste de produits</a>

				<span *ngIf="show_products;">
				    <span *ngFor="let product of _schoolList?.products;nb_products as index;let i = index;let c = count">

					<span *ngIf="i < maxNbProducts || more_products;  "  class="badge badge-pill badge-info"> {{product?.name}}</span>
					<button mat-button  color="primary" *ngIf="i  + 1== c && c > maxNbProducts" (click)="more_products = !more_products">{{ more_products ? 'voir moins' : 'voir plus' }}</button> 
				    </span>
				</span>

			    </div>
			</div>
		    </div>




		    <label class="mt-5">Informations complémentaires</label>
		    <div class="lateral-space">
			
			<div class="row mt-3 w-100 left-background">
			    <div class="col-6 left">
				Créé le {{_schoolList?.objectDetail?.creationDate |  date:'dd/MM/yyyy' }} 
			    </div>
			    <div class="col-6 right normal">
				Modifié le {{_schoolList?.objectDetail?.creationDate |  date:'dd/MM/yyyy' }}   
			    </div>
			</div>
			<div class="row w-100">
			    <div class="col-6 left">
				Créé par
			    </div>
			    <div class="col-6 right link">
				{{_schoolList?.objectDetail?.creator?.username}} 
			    </div>
			</div>
			<div class="row w-100 left-background">
			    <div class="col-6 left">
				Modifié par
			    </div>
			    <div class="col-6 right link">
				{{_schoolList?.objectDetail?.updater?.username}}   
			    </div>
			</div>
			<div class="row w-100">
			    <div class="col-6 left">
				Status
			    </div>
			    <div class="col-6 right link d-flex justify-content-center">
				{{_schoolList?.status?.label}}  
			    </div>
			</div>
		    </div>

		</ng-template>
	    </mat-step>

	    <mat-step >
                <ng-template matStepLabel><mat-icon>fact_check</mat-icon> Produits de la liste</ng-template>
		
		<!--
		     <div   class="row mt-5 top-right" layout="row" layout-align=" end">
		     <div  layout="column" layout-align="center end" >
		     <div><button mat-raised-button matStepperPrevious color="primary" class="mr-1" type="button">Précédent</button></div>
		     <div><button mat-raised-button matStepperNext color="primary" type="button">Suivant</button></div>
		     </div>
		     </div>
		-->

		<h6 style="color: black; font-size: small">Voir les produits de la liste ci-dessous</h6>
		
                <div class="table-responsive">
		    <div fxLayout="row" fxLayoutAlign="end center">
			<mat-form-field fxFlex="60%">
			    <input matInput type="text" (keyup)="doFilter($event.target.value)"  name="filter" placeholder="Filtrer">
			</mat-form-field>
		    </div>

                    <table mat-table [dataSource]="datasourceSelection" matSort>
			
                        <ng-container matColumnDef="#">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">
                                
                            </th>
                            <td style="width: 4%;" mat-cell *matCellDef="let element">
                                
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="image">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Image</th>
                            <td style="width: 15%;" mat-cell *matCellDef="let element">
                                <img class="bg-image" src="{{element?.img}}" *ngIf="element && element.img" />
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="product.name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Nom</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span>{{element?.product?.name}}</span>
                            </td>
                        </ng-container>

			<ng-container matColumnDef="product.description">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Description</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span>{{element?.product?.description}}</span>
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="product.price">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Prix</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span class="table-regular">{{element?.product?.price  | number : '1.2-2' }} </span>
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="quantity">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Quantité</th>
                            <td style="width: 120px;" mat-cell *matCellDef="let element">
                                <!-- <input matInput maxlength="5" type="number" placeholder="0" [(ngModel)]="element.qty"  class="form-control form-control-sm" required> -->
                                <mat-form-field appearance="outline" floatLabel="always">
                                    <input matInput  disabled type="number" class="example-right-align" [(ngModel)]="element.quantity"  placeholder="0">
                                </mat-form-field>
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="Total">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Total</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span class="table-regular">{{(element.product?.price * element.quantity)  | number : '1.2-2' }} €</span>
                            </td>
                        </ng-container>
			
			
			
			
                        <ng-container matColumnDef="Action">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-icon matTooltip="consulter" (click)="onConsulterProduct(element.productId)" class="icon" aria-hidden="false"
					  aria-label="Example home icon" fontIcon="preview"></mat-icon>
                            </td>
                        </ng-container>
			
			
			
                        <tr mat-header-row *matHeaderRowDef="columnsSelection"></tr>
                        <tr mat-row *matRowDef="let row; columns: columnsSelection;" class="example-element-row"></tr>
                    </table>
		    
		    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
			<mat-progress-spinner 
			    color="primary" 
			    mode="indeterminate">
			</mat-progress-spinner>
		    </mat-card>
		    
                    <mat-paginator [pageSizeOptions]="[20, 50, 100, 500]" [pageSize]="pageSize" showFirstLastButtons
				   aria-label="Select page of periodic elements">
                    </mat-paginator>
                </div>


		<button mat-raised-button matStepperPrevious color="primary" class="mr-1" type="button">Précédent</button>

            </mat-step>



	</mat-stepper>

    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
