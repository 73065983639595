<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">LISTE DE DOCUMENTS</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Documents</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content bdy" style="background-color: white;">
    <div class="container-fluid" style="padding: 30px;">


        <button class="btn btn-primary btn-sm" (click)="onAddNewDocument()">Ajouter un document</button>


        <div class="row mb-3 mt-3">

            <div class="col-2">
                <mat-label>Num du document</mat-label>

                <mat-select placeholder="Saisir num" class="  form-control form-control-sm mb-2" [formControl]="toppings"  required>
                    <mat-select-trigger>
                        {{toppings.value?.[0] || ''}}
                        <span *ngIf="(toppings.value?.length || 0) > 1" class="example-additional-selection">
                            (+{{(toppings.value?.length || 0) - 1}} {{toppings.value?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                </mat-select>

            </div>
            <div class="col-2">
                <mat-label>Type de document</mat-label>

                <mat-select  placeholder="Saisir type" class="form-control form-control-sm mb-2" [formControl]="toppings" multiple required>
                    <mat-select-trigger>
                        {{toppings.value?.[0] || ''}}
                        <span *ngIf="(toppings.value?.length || 0 ) > 1" class="example-additional-selection">
                            (+{{(toppings.value?.length || 0) - 1}} {{toppings.value?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                </mat-select>

            </div>
            <div class="col-2">
                <mat-label>Profil</mat-label>

                <mat-select placeholder="Saisir profil" class="form-control form-control-sm mb-2" [formControl]="toppings"  required>
                    <mat-select-trigger>
                        {{toppings.value?.[0] || ''}}
                        <span *ngIf="(toppings.value?.length || 0) > 1" class="example-additional-selection">
                            (+{{(toppings.value?.length || 0) - 1}} {{toppings.value?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                </mat-select>
            </div>
            <div class="col-2">
                <mat-label>Status</mat-label>

                <mat-select placeholder="Saisir status" class="  form-control form-control-sm mb-2" [formControl]="toppings"  required>
                    <mat-select-trigger>
                        {{toppings.value?.[0] || ''}}
                        <span *ngIf="(toppings.value?.length || 0) > 1" class="example-additional-selection">
                            (+{{(toppings.value?.length || 0) - 1}} {{toppings.value?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                </mat-select>
            </div>

        </div>

        <div class="table-responsive">
            <table mat-table [dataSource]="datasource" matSort>

                <ng-container matColumnDef="Selection">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">
                        <mat-checkbox></mat-checkbox>
                    </th>
                    <td style="width: 4%;" mat-cell *matCellDef="let element">
                        <mat-checkbox></mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="#">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Id</th>
                    <td style="width: 4%;" mat-cell *matCellDef="let element">
                        <span class="table-regular">2345</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="img">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"></th>
                    <td style="width: 4%;" mat-cell *matCellDef="let element">
                        <span class="material-icons">picture_as_pdf</span>
                    </td>
                </ng-container>



                <ng-container matColumnDef="Num">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Num. de document</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">#23892</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Dest">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Destinataire</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">John Doe</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="File">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Fichiers</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <a href="#">Voir fichier</a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Signature">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Signature</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge badge-pill badge-primary">Oui</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Type de document</th>
                    <td mat-cell *matCellDef="let element">
                        <a href="#">Facture</a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Action</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon matTooltip="consulter" (click)="onConsulterDocument()" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="preview"></mat-icon>
                        <mat-icon matTooltip="editer" (click)="onEditDocument()" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="edit"></mat-icon>
                        <mat-icon matTooltip="supprimer" (click)="onDelete()" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="delete"></mat-icon>
                    </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;" class="example-element-row"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="pageSize" showFirstLastButtons
                aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>


    </div><!--/. container-fluid -->
</section>
<!-- /.content -->