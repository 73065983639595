import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
// models
import { Role } from '../interfaces/role';
import { User } from '../interfaces/user';
import { Permission } from '../interfaces/permission';
// relations models
import { UserRole } from '../interfaces/user';
import { RolePermission } from '../interfaces/role';
// services
import {UserService} from '../services/user.service'

// rxjs
import { of, from,  map, Observable, exhaustMap, EMPTY, empty ,identity, forkJoin} from 'rxjs';
import { mergeMap, mergeAll, toArray, switchMap} from 'rxjs/operators';
// response
import { Response as Rep } from '../interfaces/response';

// Base Crud services 
import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"

@Injectable({
  providedIn: 'root'
})

export class RolesService extends BaseCrudModel<Role>{

    postUsers = {url: `${environment.domains.api.url}/user-roles/all`, urlOptions: this.options};
    deleteUsers = {url: `${environment.domains.api.url}/user-roles/`, urlOptions: this.options};
    postPermissions = {url: `${environment.domains.api.url}/role-permissions/all`, urlOptions: this.options};
    deletePermissions = {url: `${environment.domains.api.url}/role-permissions`, urlOptions: this.options};
    
    constructor(protected override httpService: HttpService,
	        private userService: UserService) {

	super(httpService, "roles");

	this.methods.listing.urlOptions =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
	    ],
	});

	this.methods.view.urlOptions =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "objectDetail"},
		{"relation" : "permissions"},
		{"relation" : "users", "scope" : {"include" : [{"relation" : "profile"}]}},
	    ],
	});

	var addOptions =  RequestOptions.FromArray({
	    relationsToOmit : ['users', 'permissions']
	});
	

	this.methods.add.urlOptions = addOptions;
	this.methods.update.urlOptions = addOptions;

    }

    getAllRoles(): Observable<Rep<number, Role[]>> {
	const options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "permissions"},
		{"relation" : "users" , "scope" : {"include" : [{"relation" : "profile"}]}},
		{"relation" : "UserRoles"},
		
	    ],
	});



	return this.getAll(options);

    }

    //////////////////////////
    // HANDLE || PREPARE
    //////////////////////////
    
    override handlePostRelations(role): Observable<any> {
	console.log(role);
	console.log("handlePostRelations");
	    return forkJoin
	(
	    // Remove elements if empty
	    role && role.id && (role.users && role.users?.length == 0) ? this.removeUsers(role.id) :
		this.httpService.createObservableResponse<UserRole[]>({IsSuccess:  0, Data:  undefined, Status : 0}),
	    role && role.id && (role.permissions && role.permissions?.length == 0) ? this.removePermissions(role.id) :
		this.httpService.createObservableResponse<UserRole[]>({IsSuccess:  0, Data:  undefined, Status : 0}),

	    // add new elements 
	    role && role.id && role.users && role.users?.length > 0 ? this.addUsers(role.id, role.users) :
		this.httpService.createObservableResponse<UserRole[]>({IsSuccess:  0, Data:  undefined, Status : 0}),
	    role && role.id  && role.permissions && role.permissions?.length > 0 ? this.addPermissions(role.id, role.permissions) :
		this.httpService.createObservableResponse<RolePermission[]>({IsSuccess:  0, Data:  undefined, Status : 0})
            //this.copyAttachments(),
            //this.uploadCustomerData(),
            //this.uploadAttachments()
	)
    }

    //////////////////////////
    // Add Other objects
    //////////////////////////

    public removeUsers(roleId):  Observable<Rep<number, any>> {
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		{"roleId" : roleId},
	    ],
	});
	options.bodyParams = {}
	return this.httpService.makeDeleteRequest<any>( `${this.deleteUsers.url}`, options);
//	return  this.httpService.createObservableResponse<UserRole[]>({IsSuccess:  0, Data:  undefined, Status : 0});
    }

    public removePermissions(roleId):  Observable<Rep<number, any>> {
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		{"roleId" : roleId},
	    ],
	});
	options.bodyParams = {}
	return this.httpService.makeDeleteRequest<any>( `${this.deletePermissions.url}`, options);

    }

    //////////////////////////
    // Add Other objects
    //////////////////////////

    public addUsers(roleId, users):  Observable<Rep<number, any>> {
	const options =  new RequestOptions();
 	
	const arrayUsers = users?.map((r: any): UserRole => ({roleId : roleId, userId: r.id}));

	console.log(arrayUsers);
	options.body = arrayUsers;	

	return arrayUsers && arrayUsers.length > 0 ?
	    this.httpService.makePostRequest<UserRole[]>( `${this.postUsers.url}`, options) :
	    this.httpService.createObservableResponse<UserRole[]>({IsSuccess:  0, Data:  undefined, Status : 0});
	//Observable.empty<number, any>() ;
    }

    public addPermissions(roleId, permissions):  Observable<Rep<number, any>> {
	const options =  new RequestOptions();
	
	const arrayPermissions = permissions?.map((r: any): RolePermission => ({roleId : roleId, permissionId: r.id}));

	console.log(arrayPermissions);
	options.body = arrayPermissions;	

	return arrayPermissions && arrayPermissions.length > 0 ?
	    this.httpService.makePostRequest<RolePermission[]>( `${this.postPermissions.url}`, options) :
	    this.httpService.createObservableResponse<RolePermission[]>({IsSuccess:  0, Data:  undefined, Status : 0});
    }
}

	/*
	var userOptions =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "roles"},
		{"relation" : "permissions"},
		{"relation" : "status"},
		{"relation" : "objectDetail"},
	    ],
	});

	return this.getAll(options).pipe(
	    mergeMap((result: any) =>
		    // `from` emits each role from result separately
		    from(result.Data).pipe(
			// load each roles
			mergeMap(
			    (role : any ) => {
				const userRoles = role.UserRoles;
				const userRolesArray = userRoles.map(u => u.userId);  
				userOptions.filters = [
				    {"id" :{"inq": userRolesArray}},
				];
				return this.userService.getAll(userOptions).pipe(
				    map(userResp => ({ ...role, users: userResp.Data })),
				);
			    }),
			    // collect all contacts into an array
			    toArray(),
			    // add the newly fetched data to original result
			    map(role => ({ ...result.Data, role })),
			),
		    ),
	);

	*/


	    /*	    exhaustMap(r => 
		{
		    console.log(role);
			//(profile.addressId = add && add.IsSuccess && add.Data ? add.Data.id : null );
		    return   r;
		}),
	    exhaustMap(role => 
		{
		    console.log(role);
			//(profile.addressId = add && add.IsSuccess && add.Data ? add.Data.id : null );
			return   this.userService.getAll(options);
		}),
*/
//	    mergeAll(), 
/*
	    mergeMap(rolesObs =>
		{
		    let roles  = rolesObs.Data;
		    roles.map(role => {
			const userRoles = role.UserRoles;
			const userRolesArray = userRoles.map(u => u.userId);  
			userOptions.filters = [
			    {"id" :{"inq": userRolesArray}}
			]
			
			this.userService.getAll(userOptions).pipe(map(users => {

			    role.users = users
			    return users;
			}));			
			
		    });
		    console.log("final result");
		    console.log(rel);
		    
		    return  roles ;
		}),
*/




/*	return this.getAll(options).pipe(
	     // map our observable of a post to a new observable of user
	    switchMap(roleObs => {
		let roles  = roleObs.Data;
		roles.map(role => {
		    const userRoles = role.UserRoles;
		    const userRolesArray = userRoles.map(u => u.userId);  
		    userOptions.filters = [
		    {"id" :{"inq": userRolesArray}}
		]
		
		    return this.userService.getAll(userOptions);
		});
		
		
	    }),

	    // map our previous observable of a user to a new observable of all the users posts
	    //switchMap(user => this.userService.loadPosts(user.id)),
	    map(roles => {
		return roles;
	    })
*/
/*	    mergeAll(),
	    mergeMap((r) => {
		const roles = r.Data;
		roles.map(roles => {
		const userRoles = roles.UserRoles;
		const userRolesArray = userRoles.map(u => u.userId);  
		return this.userService.getAll(userOptions).pipe(map((user) => ({ ...r.Data, ...user.Data })))
		});
	    }),
	    toArray()
*/

//	);
