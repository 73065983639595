<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">ÉDITER LOCALISATION</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Localisation</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">


        <h3 style="color: black; font-size:medium">Localisation</h3>
        <h6 style="color: black; font-size: small">Éditer la localisation</h6>
        <form class="example-form" [formGroup]="form" (ngSubmit)="submit()"  *ngIf="_location">


            <mat-label>Nom</mat-label>
            <input type="text" matInput formControlName="name"  [(ngModel)]="_location.name"  class="form-control form-control-sm mb-2" required>
	    <app-show-errors [ctrl]="form.get('name')"></app-show-errors>

            <div class="row">
                <div class="col-6">
                    <mat-label>Numéro de rue</mat-label>
                    <input matInput #numRue type="text" placeholder="Ex. 21" formControlName="street"
			   [ngModel]="_location && _location?.address && _location?.address?.street"
			   (ngModelChange)="_location &&  _location.address && _location.address.street = $event"
                        class="form-control form-control-sm" required>
		    <app-show-errors [ctrl]="form.get('street')"></app-show-errors>
                </div>
                <div class="col-6">
                    <mat-label>Ville</mat-label>
                    <input matInput
			   [ngModel]="_location && _location?.address && _location?.address?.city"
			   (ngModelChange)="_location &&  _location.address && _location.address.city = $event"
			   formControlName="city" type="text" class="form-control form-control-sm" required>
		    <app-show-errors [ctrl]="form.get('city')"></app-show-errors>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <mat-label>Code Postale</mat-label>
                    <input matInput #postalCode maxlength="5" type="number" placeholder="Ex. 94105"
			   [ngModel]="_location && _location?.address &&_location?.address?.zip"
			   (ngModelChange)="_location &&  _location.address && _location.address.zip = $event"
			   formControlName="zip" class="form-control form-control-sm" required>
                    <mat-hint align="end" style="color: black;">{{postalCode.value.length}} / 5</mat-hint>
		    <app-show-errors [ctrl]="form.get('zip')"></app-show-errors>
                </div>
                <div class="col-6">
                    <mat-select-country name="country" formControlName="country" appearance="outline"
					[ngModel]="_location && _location?.address && _location?.address?.country"
					(ngModelChange)="_location && _location?.address && _location.address.country = $event"
					label="Country" 
					(onCountrySelected)="onCountrySelected($event)" required>
                    </mat-select-country>
                </div>
            </div>

	    <div class="row">
		<div class="col-6">
			<mat-checkbox color="warn" formControlName="isRelayPoint"  [(ngModel)]="_location.isRelayPoint"  class=" mb-2 " color="warn"> 
			    Point relais
			</mat-checkbox>
		</div>
		<div class="col-6">
		     <mat-checkbox  formControlName="isSalePoint"  [(ngModel)]="_location.isSalePoint"  class=" mb-2" color="accent" > 
			 Point de vente
		     </mat-checkbox>
		 </div>
	    </div>

	    
	    <mat-label>Expéditions</mat-label>
            <div class="input-group">
		<mat-select placeholder="Choisir les Expéditions pour  cette localisation"
			    class="  form-control form-control-sm mb-2"
			    [formControl]="shippingsListCtrl" 
			    [(ngModel)]="selectedShippings"
			    [compareWith]="compareFn"
			    (selectionChange)="onShippingsChanged($event)"
			    multiple required >
		    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyShippings($event.target.value)"> 
		    <mat-select-trigger>
			{{shippingsListCtrl.value?.[0]?.name  || ''}} 
                        <span *ngIf="(shippingsList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedShippings?.length || 0) - 1}} {{selectedShippings?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let shipping of shippingsList" [value]="shipping">{{shipping.name}}</mat-option>
		</mat-select>
                <div class="input-group-append">
                    <button type="button"class="btn btn-primary  form-control-sm">
                      <mat-icon>add</mat-icon>
                    </button>
                  </div>
            </div>

	    <mat-label>Commandes</mat-label>
            <div class="input-group">
		<mat-select placeholder="Choisir les commandes pour  cette localisation"
			    class="  form-control form-control-sm mb-2"
			    [formControl]="ordersListCtrl" 
			    [(ngModel)]="selectedOrders"
			    [compareWith]="compareFn"
			    (selectionChange)="onOrdersChanged($event)"
			    multiple required >
		    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyOrders($event.target.value)"> 
		    <mat-select-trigger>
			{{ordersListCtrl.value?.[0]?.name  || ''}} 
                        <span *ngIf="(ordersList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedOrders?.length || 0) - 1}} {{selectedOrders?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let order of ordersList" [value]="order">{{order.name}}</mat-option>
		</mat-select>
                <div class="input-group-append">
                    <button type="button"class="btn btn-primary  form-control-sm">
                      <mat-icon>add</mat-icon>
                    </button>
                  </div>
            </div>
	    
	    <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Note</mat-label>
		<textarea style="height: 50px;" matInput placeholder="note" formControlName="note"
			  [ngModel]="_location && _location.note && _location.note.content"
			  (ngModelChange)="_location && _location.note && _location.note.content  = $event"
			  required></textarea>
		<app-show-errors [ctrl]="form.get('note')"></app-show-errors>
            </mat-form-field>

	    
            <div class="row">
                <div class="col-6">
                    <mat-label>Status</mat-label>
                    <div class="input-group">
			<mat-select placeholder="Choisir un statut pour ce type de produit" class="  form-control form-control-sm mb-2"   required
			    [(ngModel)]="selectedStatus" [formControl]="statusesListCtrl"  [compareWith]="compareFn" (selectionChange)="onStatusesChanged($event)"  >
		    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyStatuses($event.target.value)">  
                    <mat-select-trigger>
                        {{selectedStatus?.label  || ''}}
                        <span *ngIf="(selectedStatuses?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedStatuses?.length || 0) - 1}} {{selectedStatuses?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
		</mat-select>
                    </div>
                </div>
            </div>

            <div class="example-full-width d-flex justify-content-center" *ngIf="DisplayConfirmationButton">
                <button class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>

                <!-- <button mat-raised-button cdkFocusInitial>Annuler</button> -->
            </div>



        </form>




    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
