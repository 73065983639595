import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';


import { Order } from 'src/app/interfaces/order';
import { PaymentType } from 'src/app/interfaces/payment-type';
import { Customer } from 'src/app/interfaces/customer';
import { Status } from 'src/app/interfaces/status';

import { OrderService } from 'src/app/services/order.service';
import { PaymentTypeService } from 'src/app/services/payment-type.service';
import { CustomerService } from 'src/app/services/customer.service';
import { StatusService } from 'src/app/services/status.service';

import { environment } from 'src/environments/environment';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Response } from '../../interfaces/response';
import { Router } from '@angular/router';

import { Observable, forkJoin } from 'rxjs';

import { MatSort } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout'; 
import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@Component({
  selector: 'app-list-order',
  templateUrl: './list-order.component.html',
  styleUrls: ['./list-order.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ListOrderComponent implements OnInit, AfterViewInit {
    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
    @ViewChild(MatPaginator) paginator: MatPaginator;

    
    //////////////////////
    // Attributes   
    ////////////////////// 

    columns: string[] = ['id', 'orderNumber', 'customer.profile.lastname', 'date', 'total', 'paymentStatus.label', 'paymentMean.label', 'Action' ];
//    columns: string[] = ['#', 'mainImage', 'name', 'sku', 'price', 'stock', 'paymentType', 'Action' ];
    datasource: MatTableDataSource<Order> = new MatTableDataSource<Order>();
    pageSize = 10;

    //images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);
    images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);
    
    orders: Order[];

    customersListCtrl = new FormControl('');
    customersData : Customer[];//string[];
    customersList : Customer[];//string[];
    selectedCustomers : Customer[];

    paymentTypesListCtrl = new FormControl('');
    paymentTypesList : PaymentType[];//string[];
    paymentTypesData : PaymentType[];//string[];
    selectedPaymentTypes : PaymentType[];
    
    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatus : Status;

    paymentStatusesListCtrl = new FormControl('');
    paymentStatusesList : Status[];//string[];
    paymentStatusesData : Status[];//string[];
    selectedPaymentStatus : Status;
    
    isLoading = true;

    //////////////////////
    // Constructor       
     //////////////////////   

    constructor(private orderService: OrderService,
		private customerService: CustomerService,
		
		private paymentTypeService : PaymentTypeService,
		private statusService : StatusService,
		private router: Router,
		public dialog: MatDialog) {
      
  }

    
    //////////////////////
    // INIT
    //////////////////////
    
    ngAfterViewInit() {
	this.datasource.paginator = this.paginator;
	
	this.datasource.filterPredicate = this.tableFilter(); 

    }

    ngOnInit(): void {
	this.refresh();
    }


    //////////////////////
    // TOOLS
    //////////////////////

    getUrlOf(element) : string {
	var path =  element.path ? element.path.replace(/^public?\//i, "") : null;
	return element && element.path ? `${environment.domains.api.url}${path}` : "";
    }
    
    //////////////////////
    // Data sources
    //////////////////////

    tableFilter(): (data: any, filter: string) => boolean {
	let columnsNames = this.columns;

	let filterFunction = function(data, filter): boolean{
	    

	    var datas = columnsNames.map(x => {
		// split string succh as "customer.name"
		var objectData = x.includes(".") ? x.split(".") : [x];
		var currentObject = data;

		// concat array to create object like data["customer"]["name"]
		objectData.forEach(key => {
		    if (!currentObject[key])
			return;
		    currentObject = currentObject[key];
		})
		// get val and check if its related to filter
		let val = currentObject ?? data[x];
		if (val && val.toString().toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1){		    
		    return  data;
		}
		return null;
		
	    }).filter(x => x); // map and filter to eliminate null data in array

	    
	    return datas?.length > 0 ? true : false; 
	}
	return filterFunction;
     }

    doFilter(value: string) : void  {
	this.datasource.filter = value.trim().toLocaleLowerCase();
    }



    refresh(): void {
	this.setLoading(true);
	this.orderService.getAllOrders().subscribe(
	    {
		next: (response: Response<number, Order[]>) => {
		    this.orders = response.Data;
		    this.datasource.data = this.orders;
		    this.datasource.paginator = this.paginator;

		    this.datasource.paginator = this.paginator;
		    this.datasource.filterPredicate = this.tableFilter(); 

		    this.setLoading(false);
		    console.log(this.orders);
		},
		error: (e) => {
		},
		complete: () => console.info('loadMenu complete')
	    }
	);
	this.paymentTypeService.getAll().subscribe({
	    next: (response: Response<number, PaymentType[]>) => {
		this.paymentTypesList = response.Data;
            },error: (e) => { }, complete: () => console.info('load paymentTypes complete')
	});
	this.customerService.getAllCustomers().subscribe({
	    next: (response: Response<number, Customer[]>) => {
		console.log(response);
		this.customersList = response.Data;
	    },error: (e) => { },complete: () => console.info('loadMenu complete')
	});

	this.statusService.getAll().subscribe({
	    next: (response: Response<number, Status[]>) => {
		this.statusesList = response.Data;
		this.paymentStatusesList = response.Data;
            },error: (e) => { }, complete: () => console.info('load status complete')
	});
    }

    //////////////////////
    // Loading
    /////////////////////

    toggleLoading() : void {
	this.isLoading = !this.isLoading;
    }

    setLoading(loading : boolean) : void {
	this.isLoading = loading;
    }

    //////////////////////
    //////////////////////

    

    //////////////////////
    // Actions
    //////////////////////

  onAddNewOrder(): void {
    this.router.navigate([environment.paths.NewOrderPath]);
  }

  onEditOrder(id): void {
      this.router.navigate([environment.paths.EditerOrderPath, id]);
  }

  onConsulterOrder(id): void {
      this.router.navigate([environment.paths.ConsulterOrderPath,id]);
  }

  onDelete(id): void {
    const dialogRef = this.dialog.open(ConfirmerSuppressionDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
      	  this.orderService.deleteById(id).subscribe(
	      {
		  next: (response: Response<number, Order>) => {
		      console.log(response);
		      this.refresh();
		  },
		  error: (e) => {
		      console.log(e);
		  },
		  complete: () => console.info('delete complete')
	      }
	  );
      }
    });
  }
}
