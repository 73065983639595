import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';


import { Note } from 'src/app/interfaces/note';
import { Address } from 'src/app/interfaces/address';
import { CompanyGroup } from 'src/app/interfaces/company-group';
import { Company } from 'src/app/interfaces/company';
import { Customer } from 'src/app/interfaces/customer';
import { Status } from 'src/app/interfaces/status';

import { CompanyGroupService } from 'src/app/services/company-group.service';
import { CompanyService } from 'src/app/services/company.service';
import { CustomerService } from 'src/app/services/customer.service';
import { StatusService } from 'src/app/services/status.service';

import { Country } from '@angular-material-extensions/select-country';

import { Response } from '../../interfaces/response';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-company',
  templateUrl: './new-company.component.html',
  styleUrls: ['./new-company.component.scss'], encapsulation: ViewEncapsulation.None
})
export class NewCompanyComponent implements OnInit {
  selectedCountry: Country = undefined;

    @Input() TitleVisible: boolean = true;
    @Input() DisplayConfirmationButton: boolean = true;

    form: FormGroup;

    ////////////////////////
    // Attributes 
    ////////////////////////  

    companyGroupsListCtrl = new FormControl('');
    companyGroupsList : CompanyGroup[];//string[];
    companyGroupsData : CompanyGroup[];//string[];
    selectedCompanyGroups : CompanyGroup[];
    selectedCompanyGroup : CompanyGroup;

    customersListCtrl = new FormControl('');
    customersData : Customer[];//string[];
    customersList : Customer[];//string[];
    selectedCustomers : Customer[];
    
    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatus : Status;
    selectedStatuses : Status[];
    
    ////////////////////////
    // Constructor 
    ////////////////////////
    
    constructor(private router: Router,
		private _snackBar: MatSnackBar,
		public fb: FormBuilder,
		
		private statusService : StatusService,
		private companyService: CompanyService,
		private customerService: CustomerService,
		private companyGroupService : CompanyGroupService) { 
 
      }
      

    ////////////////////////
    // Init
    ///////////////////////
    
    ngOnInit(): void {

	
	this.refresh();
	this.initForm();
    }

    ////////////////////////
    // Datasrouces
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    name: new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(2)]),
	    siret: new FormControl(null, [Validators.maxLength(50), Validators.minLength(2)]),
	    phone: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$"), Validators.minLength(10)]),
	    email: new FormControl(null, [Validators.required, Validators.email]),

	    street: new FormControl(null, [Validators.required, Validators.maxLength(30), Validators.minLength(2)]),
	    city: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    zip: new FormControl(null, [Validators.required, Validators.maxLength(5),  Validators.pattern("^[0-9]*$"), Validators.minLength(5)]),

	    billingStreet: new FormControl(null, [Validators.required, Validators.maxLength(30), Validators.minLength(2)]),
	    billingCity: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    billingZip: new FormControl(null, [Validators.required, Validators.maxLength(5),  Validators.pattern("^[0-9]*$"), Validators.minLength(5)]),

	    
	    //	    label: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    //	    description: new FormControl(null, [Validators.required, Validators.maxLength(150), Validators.minLength(2)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
    });
    }

    ////////////////////////
    // Datasrouces
    ///////////////////////

    refresh(): void {
	this.statusService.getAllStatuses().subscribe({
	    next: (response: Response<number, Status[]>) => {
		
		this.statusesData = response.Data;
		this.initStatusesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load statuses complete')
	});
	
	this.companyGroupService.getAllCompanyGroups().subscribe({
            next: (response: Response<number, CompanyGroup[]>) => {
		this.companyGroupsData = response.Data;
		this.initCompanyGroupsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load companyGroups complete')
	});

	this.customerService.getAllCustomers().subscribe({
            next: (response: Response<number, Customer[]>) => {
		this.customersData = response.Data;
		this.initCustomersSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load customers complete')
	});



    }

    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns

        initStatusesSearchDropdown(){
	this.statusesList = [];
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
	    {

		if (!this.selectedStatus && this.statusesData[i].label == "Actif")
		{
		    this.selectedStatuses = [];
		    this.selectedStatus = this.statusesData[i];
		    
		    this.selectedStatuses.push(this.statusesData[i]);
		}
		this.statusesList.push( this.statusesData[i] );
	    }
        }
    }

    
    initCompanyGroupsSearchDropdown(){
	this.companyGroupsList = [];
        for ( let i = 0 ; i < this.companyGroupsData.length; i ++ ) {
            this.companyGroupsList.push( this.companyGroupsData[i] );
        }
    }

    initCustomersSearchDropdown(){
	this.customersList = [];
        for ( let i = 0 ; i < this.customersData.length; i ++ ) {
            this.customersList.push( this.customersData[i] );
        }
    }

    
    ////////////////////////
    // On Search
    
    onKeyCompanyGroups(value) { 
            this.companyGroupsList= []; 
            this.selectCompanyGroupsSearch(value);       
    }

        onKeyCustomers(value) { 
            this.customersList= []; 
            this.selectCustomersSearch(value);       
    }

    onKeyStatuses(value) { 
            this.statusesList= []; 
            this.selectStatusesSearch(value);       
    }

    ////////////////////////
    // search elements


    selectCompanyGroupsSearch(value:string){
        let filter = value.toLowerCase();
       for ( let i = 0 ; i < this.companyGroupsData.length; i ++ ) {
            let option = this.companyGroupsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0
		|| option.name?.toLowerCase().indexOf(filter) >= 0  ) {
                this.companyGroupsList?.push( option );
            }
        }
	
    }

    selectCustomersSearch(value:string){
        let filter = value.toLowerCase();
       for ( let i = 0 ; i < this.customersData.length; i ++ ) {
            let option = this.customersData[i];
            if (  option.profile?.firstname.toLowerCase().indexOf(filter) >= 0
		|| option.profile?.lastname?.toLowerCase().indexOf(filter) >= 0  ) {
                this.customersList?.push( option );
            }
        }
	
    }
    

    selectStatusesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
            let option = this.statusesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.statusesList?.push( option );
            }
        }
    }


    onCountrySelected($event: Country) {
	this.selectedCountry = $event;
    }
    
    ////////////////////////////
    // elements selections
    ///////////////////////////

    onStatusesChanged($event: any) {
      console.log($event);
    }
    
    OnCompanyGroupsChanged($event: any) {
	console.log($event);
    }


    OnCustomersChanged($event: any) {
	console.log($event);
    }

    ////////////////////////////
    // External actions
    ///////////////////////////
    
    
    ////////////////////////
    ///////////////////////
    
    ////////////////////////
    ///////////////////////
    
    compare(o1: any, o2: any): boolean {
	// if possible compare by object's name, and not by reference.
	return o1 && o2 ? o1.name === o2.name : o2 === o2;
    }
    
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid) {
	    return;
	}

	
	let company : Company = {
	    name: this.form.value.name,
	    siret: this.form.value.siret,
	    email: this.form.value.email,
            phoneNumber: this.form.value.phone
	    //label: this.form.value.label,
	    //description: this.form.value.description,
	};


	const note : Note = {
	    content : this.form.value.note
	}


	const address: Address = {
	  country:  this.selectedCountry.name,
          street: this.form.value.street?.toString(),
          city: this.form.value.city,
          zip: this.form.value.zip?.toString(),
//	  zipCode: this.form.value.postal?.toString(),
          additionalDetail: ''	  
      };


	const billingAddress: Address = {
	    country:  this.selectedCountry.name,
            street: this.form.value.billingStreet?.toString(),
            city: this.form.value.billingCity,
            zip: this.form.value.billingZip?.toString(),
//	  zipCode: this.form.value.postal?.toString(),
          additionalDetail: ''	  
      };

	

	company.note = note;
	company.billingAddress = billingAddress;
	company.companyAddress = address;

/*	if (this.selectedCompanyGroups && this.selectedCompanyGroups.length > 0)
	    company.companyGroups = this.selectedCompanyGroups;
*/
	if (this.selectedCompanyGroup)
	    company.companyGroupId = this.selectedCompanyGroup.id;
		    
	if (this.selectedStatus){
	    company.statusId = this.selectedStatus.id;
	}
	
	console.log(company);
	console.log("gonna save");

	this.companyService.prepareAddRelations(company).pipe(
	    switchMap(preparedCompany => { 
		console.log("entered switch map -- add new company ");
		console.log(preparedCompany);
		return this.companyService.add(company);}),
	    switchMap(companyAddedObservable => {
		let companyAdded = companyAddedObservable.Data;
		//companyAdded.companyGroups = this.selectedCompanyGroups;
		console.log("entered switch map -- handle post relations ");
		console.log(companyAdded);
		return this.companyService.handlePostAddRelations(companyAdded); 
	    })
	
						      
	).subscribe({
	    next: (res) => {  this.checkSubmitSucess(res);},
	    error: (error) => { this.submitError(error) },
	    complete: () => {
		console.info('save companyGroups pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();  
		    }, 5000);
		
	    }
	    
	    
	});

    }

    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListCompaniesPath]);
	
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }
    



}
