import { AfterViewInit, Component, EventEmitter, OnInit,  Output, Input,
	 ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';

import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

// services
import { BrandService } from 'src/app/services/brand.service';
import { ProductService } from 'src/app/services/product.service';
import { ProductTypeService } from 'src/app/services/product-type.service';
import { StatusService } from 'src/app/services/status.service';
import { CompanyGroupService } from 'src/app/services/company-group.service';

// models
import { Model } from 'src/app/interfaces/model';  
import { Brand } from 'src/app/interfaces/brand';
import { Product } from 'src/app/interfaces/product';
import { Note } from 'src/app/interfaces/note';
import { ProductType } from 'src/app/interfaces/product-type';
import { Status } from 'src/app/interfaces/status';   
import { CompanyGroup } from 'src/app/interfaces/company-group';

//
import { Response } from '../../interfaces/response';

//
import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

//
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';

import { MatSort } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';

import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.scss'], encapsulation: ViewEncapsulation.None
})
export class NewProductComponent  implements OnInit  {
  @Input() TitleVisible: boolean = true;
  @Input() DisplayConfirmationButton: boolean = true;

  form: FormGroup;

    ////////////////////////
    // Attributes
    ////////////////////////
    
    @ViewChild("mainImageUpload", {static: false}) mainImageUpload: ElementRef;
    mainImages = [];

    @ViewChild("imagesUpload", {static: false}) imagesUpload: ElementRef;
    images  = [];
    
    brandsListCtrl = new FormControl('');
    brandsData : Brand[];//string[];
    brandsList : Brand[];//string[];
    selectedBrand : Brand;

    
    productTypesListCtrl = new FormControl('');
    productTypesList : ProductType[];//string[];
    productTypesData : ProductType[];//string[];
    selectedProductType : ProductType;

    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatuses : Status[];
    selectedStatus : Status;


    ////////////////////////
    // GROUPS ATTRIBUTES 
    ////////////////////////
    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();   
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    
    //
    // Datatable
    //
    //columns: string[] = ['#', 'Price' ];
    columnsSelection: string[] = ['#', 'companyGroup.label', 'companyGroup.name', 'companyGroup.description',  'companyGroup.companies', 'companyGroup.products', 'price', 'Action' ];

    //datasource: MatTableDataSource<Model> = new MatTableDataSource<Model>();
    datasourceSelection: MatTableDataSource<Model> = new MatTableDataSource<Model>();

    pageSize = 20;
    
    //
    // Data Sources
    //
    companyGroupsListCtrl = new FormControl('');
    companyGroupsList : CompanyGroup[];//string[];
    companyGroupsData : CompanyGroup[];//string[];
    selectedCompanyGroups : CompanyGroup[];


    // selectedGroup Converted 
    selectedCompanyGroupDetails : any[] = [];
    companyGroupDetails : any[] = [];
    
    
    isLoading = true;

    ////////////////////////
    // Constructor
    ////////////////////////  

    constructor(private router: Router,
		private _snackBar: MatSnackBar,
		public fb: FormBuilder,

		private companyGroupService: CompanyGroupService,
		private productService: ProductService,
		private productTypeService: ProductTypeService,
		private statusService : StatusService,
		private brandService : BrandService,     
	       ) { 
 
      }
      


    ////////////////////////
    // Init
    ///////////////////////

    ngAfterViewInit() {
    }

    ngOnInit(): void {

	
	this.refresh();
	this.initForm();
	this.initDataTables();
    }

    ////////////////////////
    // Datatable
    ///////////////////////

    
    initDatasources() : void {
	
	this.datasourceSelection.data = this.companyGroupDetails;
	this.datasourceSelection.paginator = this.paginator;
	this.datasourceSelection.sort = this.sort;
	this.datasourceSelection.filterPredicate = this.tableFilter(); 
	
    }

    initDataTables(): void {
	this.setLoading(true);  
	this.companyGroupService.getAll().subscribe({
            next: (response: Response<number, CompanyGroup[]>) => {
		this.companyGroupsData = response.Data;
		this.companyGroupDetails = this.companyGroupsData.map(companyGroup => this.fromCompanyGroupDataToCompanyGroupDetail(companyGroup));
		
		this.initDatasources(); 
		this.initCompanyGroupsSearchDropdown();
		this.setLoading(false);
            },error: (e) => { this.setLoading(false); }, complete: () => console.info('load roles complete')
	});


    }

    getCheckedItems() {
	this.selectedCompanyGroupDetails = this.datasourceSelection.data
	    .filter(i => (i as any).checked == true)
	    .map(companyGroup => this.fromCompanyGroupDetailToCompanyGroupData(companyGroup));
    }

    fromCompanyGroupDetailToCompanyGroupData(companyGroupDetail) {
	let companyGroupData = {
	    companyGroupId : companyGroupDetail.companyGroupId,
	    price : companyGroupDetail.price

	};
	return companyGroupData;
    }
    
    fromCompanyGroupDataToCompanyGroupDetail(companyGroup) {
	let companyGroupDetail = {
	    checked : false,
	    companyGroupId : companyGroup.id,
	    companyGroup: companyGroup, 
	    price : 0,
	}
	//console.log(companyGroupDetail);
	return companyGroupDetail;
	
    }
    
    ////////////////////////
    // Filter Datatable
    ///////////////////////

    doFilter(value: string) : void  {
	this.datasourceSelection.filter = value.trim().toLocaleLowerCase();
    }

    
    tableFilter(): (data: any, filter: string) => boolean {
	let columnsNames = this.columnsSelection;

	let filterFunction = function(data, filter): boolean{
	    

	    var datas = columnsNames.map(x => {
		// split string succh as "customer.name"
		var objectData = x.includes(".") ? x.split(".") : [x];
		var currentObject = data;

		// concat array to create object like data["customer"]["name"]
		objectData.forEach(key => {
		    currentObject = currentObject[key];
		})
		// get val and check if its related to filter
		let val = currentObject ?? data[x];
		if (val && val.toString().toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1){		    
		    return  data;
		}
		return null;
		
	    }).filter(x => x); // map and filter to eliminate null data in array

	    
	    return datas?.length > 0 ? true : false; 
	}
	return filterFunction;
     }


    ////////////////////////
    // Datasrouces
    ///////////////////////

    initForm() : void {
	const  numRegex = /^-?\d*[.,]?\d{0,2}$/;
	this.form = this.fb.group({
	    name: new FormControl(null, [Validators.required, Validators.maxLength(60), Validators.minLength(2)]),
	    price: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.pattern(numRegex), Validators.minLength(1)]),
	    weight: new FormControl(null, [ Validators.maxLength(10), Validators.pattern("^[0-9]*$"), Validators.minLength(1)]),
	    sku: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    quantity: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(1)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
	    description: new FormControl(null, [Validators.required, Validators.maxLength(150), Validators.minLength(2)]),
	    images : new FormControl(null, []),
	    mainImages : new FormControl(null, [])
    });
    }

    ////////////////////////
    // Datasrouces
    ///////////////////////

    refresh(): void {
	this.statusService.getAllStatuses().subscribe({
	    next: (response: Response<number, Status[]>) => {
		
		this.statusesData = response.Data;
		this.initStatusesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load statuses complete')
	});
	
	this.brandService.getAllBrands().subscribe({
            next: (response: Response<number, Brand[]>) => {
		this.brandsData = response.Data;
		this.initBrandsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load brands complete')
	});

	this.productTypeService.getAllProductTypes().subscribe({
	    next: (response: Response<number, ProductType[]>) => {
		this.productTypesData = response.Data;
		this.initProductTypesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load productTypes complete')
	});


    }

    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns

    initStatusesSearchDropdown(){
	this.statusesList = [];
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
	    {

		if (!this.selectedStatus && this.statusesData[i].label == "Actif")
		{
		    this.selectedStatuses = [];
		    this.selectedStatus = this.statusesData[i];
		    
		    this.selectedStatuses.push(this.statusesData[i]);
		}
		this.statusesList.push( this.statusesData[i] );
	    }
        }
    }
	
    initProductTypesSearchDropdown(){
	this.productTypesList = [];
        for ( let i = 0 ; i < this.productTypesData.length; i ++ ) {
            this.productTypesList.push( this.productTypesData[i] );
        }
    }


    
    initBrandsSearchDropdown(){
	this.brandsList = [];
        for ( let i = 0 ; i < this.brandsData.length; i ++ ) {
            this.brandsList.push( this.brandsData[i] );
        }
    }

    initCompanyGroupsSearchDropdown(){
	this.companyGroupsList = [];
        for ( let i = 0 ; i < this.companyGroupsData.length; i ++ ) {
            this.companyGroupsList.push( this.companyGroupsData[i] );
        }
    }


    ////////////////////////
    // On Search
    
    onKeyBrands(value) { 
            this.brandsList= []; 
            this.selectBrandSearch(value);       
    }

    onKeyProductTypes(value) { 
            this.productTypesList= []; 
            this.selectProductTypesSearch(value);       
    }

    onKeyStatuses(value) { 
            this.statusesList= []; 
            this.selectStatusesSearch(value);       
    }
    

    onKeyCompanyGroups(value) { 
            this.companyGroupsList= []; 
            this.selectCompanyGroupsSearch(value);       
    }

    ////////////////////////
    // search elements

    selectProductTypesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.productTypesData.length; i ++ ) {
            let option = this.productTypesData[i];
            if (  option.name.toLowerCase().indexOf(filter) >=0 ) {
                this.productTypesList?.push( option );
            }
        }
    }

    selectBrandSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.brandsData.length; i ++ ) {
            let option = this.brandsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0  ) {
                this.brandsList?.push( option );
            }
        }
    }

	selectStatusesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
            let option = this.statusesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.statusesList?.push( option );
            }
        }
    }


    selectCompanyGroupsSearch(value:string){
        let filter = value.toLowerCase();
       for ( let i = 0 ; i < this.companyGroupsData.length; i ++ ) {
            let option = this.companyGroupsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0
		|| option.name?.toLowerCase().indexOf(filter) >= 0  ) {
                this.companyGroupsList?.push( option );
            }
        }
	
    }

    
    ////////////////////////////
    // elements selections
    ///////////////////////////

    onStatusesChanged($event: any) {
      console.log($event);
    }
    
    onProductTypesChanged($event: any) {
      console.log($event);
    }

    onBrandChanged($event: any) {
      console.log($event);
    }

    OnCompanyGroupsChanged($event: any) {
	console.log($event);
    }


    ////////////////////////////
    // External actions
    ///////////////////////////
    
    addNewProductType(): void {
/*    const dialogRef = this.dialog.open(AddRoleDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {

      }
    });*/
    }

        
    //////////////////////
    // Loading
    //////////////////////

    toggleLoading() : void {
	this.isLoading = !this.isLoading;
    }

    setLoading(loading : boolean) : void {
	this.isLoading = loading;
    }

    //////////////////////
    //////////////////////

    
    ////////////////////////
    // Links / URL
    ///////////////////////


    openInNewTab(namedRoute) {
	let newRelativeUrl = this.router.createUrlTree([namedRoute]);
	console.log(newRelativeUrl);
	let baseUrl = window.location.href.replace(this.router.url, '');

	window.open(baseUrl + newRelativeUrl, '_blank');
    }
    
    onConsulterCompanyGroup(id): void {
	this.openInNewTab(`${environment.paths.ConsulterCompanyGroupPath}/${id}`);
    }


    
    ////////////////////////
    ///////////////////////
    
    compare(o1: any, o2: any): boolean {
	// if possible compare by object's name, and not by reference.
	return o1 && o2 ? o1.name === o2.name : o2 === o2;
    }
	
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid) {
	    return;
	}

	this.getCheckedItems();
	//
	// Main Image
	const mainImageUpload = this.mainImageUpload.nativeElement;
	this.mainImages = mainImageUpload.files;
	const formDataMainImage = new FormData();
	//	if ( this.mainImage) {
	for (const mainImage of this.mainImages) {
	    console.log("MainImages : \n {mainImage}".formatUnicorn({mainImage: JSON.stringify(mainImage)}));
	    console.log(mainImage);
	    formDataMainImage.append('files', mainImage, mainImage.name);
	}
	

	//
	// Images
	const imagesUpload = this.imagesUpload.nativeElement;
	this.images = imagesUpload.files;
	const formDataImages = new FormData();

	for (const image of this.images) {
	    console.log("Images : \n {image}".formatUnicorn({image: JSON.stringify(image)}));
	    console.log(image);
	    formDataImages.append('files', image, image.name);
	}

	//
	// Product
	let product : Product = {
            name: this.form.value.name,
            price: this.form.value.price,
            weight: this.form.value.weight,
	    sku: this.form.value.sku,
	    quantity: this.form.value.quantity,
	    description: this.form.value.description,
	};


	const note : Note = {
	    content : this.form.value.note
	}

	product.note = note;

	if (this.selectedProductType && this.selectedProductType.id){
	    product.productTypeId = this.selectedProductType.id;
	    product.productType = this.selectedProductType;
	}


	if (this.selectedBrand && this.selectedBrand.id){
	    product.brandId = this.selectedBrand.id;
	    product.brand = this.selectedBrand;
	}

	if (this.selectedCompanyGroupDetails)
	    product.companyGroupItemTypes = this.selectedCompanyGroupDetails;
	
	if (this.selectedStatus){
	    product.statusId = this.selectedStatus.id;
	}

	if (formDataMainImage.has('files')){
	    product.dataToUpload = formDataMainImage;
	}


	console.log(product);
	console.log("gonna save");

	this.productService.prepareAddRelations(product).pipe(
	    switchMap(preparedProduct => { 
		console.log("entered switch map -- add new product ");
		console.log(preparedProduct);
		return this.productService.add(product);}),
	    switchMap(productAddedObservable => {
		let productAdded = productAddedObservable.Data;
		if (productAddedObservable.IsSuccess == 0)
		    this.submitFaillure();
		
		console.log("entered switch map -- handle post relations ");
		console.log(productAdded);
		productAdded.brand = this.selectedBrand;
		productAdded.productType = this.selectedProductType;
		if (formDataImages.has('files')){
		    productAdded.imagesDataToUpload = formDataImages;
		    productAdded.companyGroupItemTypes = this.selectedCompanyGroupDetails;   
		}
		return this.productService.handlePostAddRelations(productAdded); 
	    })
	
						      
	).subscribe({
	    next: (res) => { this.checkSubmitSucess(res); },
	    error: (error) => {this.submitError(error);},
	    complete: () => {
		console.info('save product pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();  
		    }, 5000);
		
	    }
	    
	    
	});

    }



    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////
    
    submitSuccess(){
	this.router.navigate([environment.paths.ListProductPath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }
}
