<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">LISTE DES EXPÉDITIONS</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Expédition</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content bdy" style="background-color: white;">
    <div class="container-fluid" style="padding: 30px;">


        <!--<button class="btn btn-primary btn-sm" (click)="onAddNewShipping()">Ajouter une expédition</button>-->


        <div class="row mb-3 mt-3">

        </div>

        <div class="table-responsive">

	    <div fxLayout fxLayoutAlign="end center">
		<mat-form-field fxFlex="40%">
		    <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filtrer">
		</mat-form-field>
	    </div>

            <table mat-table [dataSource]="datasource" matSort>

                <ng-container matColumnDef="#">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">
                        <mat-checkbox></mat-checkbox>
                    </th>
                    <td style="width: 4%;" mat-cell *matCellDef="let element">
                        {{element.id}} <!-- <mat-checkbox></mat-checkbox>-->
                    </td>
                </ng-container>

                <ng-container matColumnDef="NumExp">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Numero d'expédition</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">#EXP_{{element.shippingNumber}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="NomClient">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Nom client</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">
			    <span
				*ngIf="element.order?.customer?.profile"
				(click)="goTo('profiles', element.order?.customer?.profile?.id )">
				{{element.order?.customer?.profile?.lastname}} {{element.order?.customer?.profile?.firstname}}
			    </span>
			</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Adresse">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Adresse</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">
			    <span *ngIf="element.delivery">{{element.delivery?.address?.street}} {{element.delivery?.address?.zip}}, {{element.delivery?.address?.city}} {{element.delivery?.address?.country}}</span>
			    <span *ngIf="element.relayPoint">{{element.relayPoint?.address?.street}} {{element.relayPoint?.address?.zip}}, {{element.relayPoint?.address?.city}} {{element.relayPoint?.address?.country}}</span>
			</span>
                    </td>
                </ng-container>



                <ng-container matColumnDef="Etat">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">État
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span class="table-regular">
			    <span *ngIf="element.delivery"> <span class="badge badge-pill badge-primary"> Livraison </span> </span>
			    <span *ngIf="element.relayPoint">
				<span *ngIf="element.relayPoint.isRelayPoint" class="table-regular">
				    <span class="badge badge-pill badge-info"> Point relais </span>
				</span>
				<span *ngIf="element.relayPoint.isSalePoint" class="table-regular">
				    <span class="badge badge-pill badge-warning"> Point de vente </span>
				</span>

			    </span>
			</span>

                    </td>
                </ng-container>

				
                <ng-container matColumnDef="DateRetrait">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Date de retrait</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.shippingDate |  date:'dd/MM/yyyy'}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="NumCommand">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Numéro de commande</th>
                    <td mat-cell *matCellDef="let element">
                        <span>
			    <span
				*ngIf="element.order"
				(click)="goTo('orders', element.order?.id )">
				#CMD_{{element.order?.orderNumber}}
				
			    </span>


			</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Action</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon matTooltip="consulter" (click)="onConsulterShipping(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="preview"></mat-icon>
                        <!--<mat-icon matTooltip="editer" (click)="onEditShipping(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="edit"></mat-icon>-->
                        <mat-icon matTooltip="supprimer" (click)="onDelete(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="delete"></mat-icon>
                    </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;" class="example-element-row"></tr>
            </table>
	    
	    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
		<mat-progress-spinner 
		    color="primary" 
		    mode="indeterminate">
		</mat-progress-spinner>
	    </mat-card>

            <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="pageSize" showFirstLastButtons
                aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>


    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
