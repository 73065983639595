import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


// models
import { File } from '../interfaces/file';
import { Product } from '../interfaces/product';
import { Note } from '../interfaces/note';

// services
import {UserService} from '../services/user.service'
import {NoteService} from '../services/note.service'

// rxjs
import { of, from,  map, Observable, exhaustMap, EMPTY, empty ,identity, forkJoin} from 'rxjs';
import { mergeMap, mergeAll, toArray, switchMap} from 'rxjs/operators';

// response   
import { Response as Rep } from '../interfaces/response';

import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"


@Injectable({
  providedIn: 'root'
})
export class FileService extends BaseCrudModel<File>{

    //ex : schoolList.dataToUpload  = this.files[0]; // working for files/image/upload
    postUploadSingleImage = {url: `${environment.domains.api.url}/files/image/upload`, urlOptions: this.options};

    //ex : schoolList.dataToUpload  = formData; // working for /files/upload/images
    postUploadManyImages = {url: `${environment.domains.api.url}/files/upload/images`, urlOptions: this.options};

    // Upload many FILES 
    postUploadFiles = {url: `${environment.domains.api.url}/files/upload/many`, urlOptions: this.options};
    
    postAddManyFiles = {url: `${environment.domains.api.url}/files/all`, urlOptions: this.options};
    deleteProducts = {url: `${environment.domains.api.url}/item-types`, urlOptions: this.options};
    
    constructor(protected override httpService: HttpService,
	          private noteService : NoteService) {
	super(httpService, "files");

	var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "objectDetail"},
		{"relation" : "note"},
		//{"relation" : "products"},
	    ],
	});

	var addOptions =  RequestOptions.FromArray({
	    relationsToOmit : ['note', 'status']
	});

	this.methods.listing.urlOptions = options;
	this.methods.view.urlOptions = options;
	this.methods.add.urlOptions = addOptions;
	this.methods.update.urlOptions = addOptions;
    }

    ////////////////////////////////
    // Gett || ADD
    ////////////////////////////////

    getAllFiles(): Observable<Rep<number, File[]>> {
	const options = new RequestOptions();

	return this.getAll()
    }

    addMany(files : File[]) : Observable<Rep<number, File[]>> {
	const options = this.methods.add.urlOptions;

	console.log("Add");
	options.body = files;
	console.log(options);

	return this.httpService.makePostRequest<File[]>( `${this.postAddManyFiles.url}`, options);
    }

    ////////////////////////////////
    // TOOLS || UTILS
    ////////////////////////////////

    getBase64(file) : any {
	return new Promise((resolve, reject) => {
	    const reader = new FileReader();
	    reader.readAsDataURL(file);
	    reader.onload = () => resolve(reader.result);
	    reader.onerror = error => reject(error);
	});
    }

    ////////////////////////////////
    // UPLOAD 
    ////////////////////////////////
    
    uploadFiles(files) : Observable<Rep<number, File[]>> {
	const options = new RequestOptions(new HttpHeaders(
	    {
		'Authorization' : this.options.headers.get('Authorization'),
		'Access-Control-Allow-Origin': '*'
	    }));
	if(! Array.isArray(files) ){
	    //files = {"files" : files };
	}
	options.body = files;
	console.log("body for uploadedfiles");
	console.log(options);
	let uploadFiles$ =
	    this.httpService.makePostRequest<any>( `${this.postUploadFiles.url}`.format(), options).pipe(
		map(filesUploaded =>
		    {
			var newFiles : File[] = filesUploaded.Data.files?.map(fileUp => {

			    const newFile : File = {
				name : fileUp.filename,
				path : fileUp.path
			    }
			    console.log("Original File uploaded : \n {fileUploaded} \n File type to create : \n {fileType}".formatUnicorn({fileUploaded: JSON.stringify(fileUp), fileType : JSON.stringify(newFile)}));
			    return newFile;
			});
			return this.httpService.createResponseFromObj<File[]>(newFiles);
		    })
	    );
	
	return  files   ? ( Array.isArray(files) && files.length > 0 ?
	    uploadFiles$ : uploadFiles$ 
			  ) : this.httpService.createObservableResponse<File[]>({IsSuccess:  0, Data:  undefined, Status : 0})
	    ;

    }
}

/*
    uploadFiles(files) : Observable<Rep<number, File[]>> {
	//options["headers"]["Content-Type"] = "multipart/form-data; boundary=----WebKitFormBoundaryUucA6DiAhQeYNgIm";
	//options["headers"]["Accept"] = "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*\/\*;q=0.8" ;

	const options = new RequestOptions(new HttpHeaders(
	    {
		//'Content-Type': "multipart/form-data; boundary=----WebKitFormBoundaryUucA6DiAhQeYNgIm",
		//'Accept' : "*\/*",
		//'Content-type' : 'application/x-www-form-urlencoded',
		//'Content-type' : 'image/png',
		//'Content-Transfer-Encoding' : 'binary',
		'Authorization' : this.options.headers.get('Authorization'),
		'Access-Control-Allow-Origin': '*'
	    }));
//	const options = new RequestOptions("multipart/form-data; boundary=----WebKitFormBoundaryUucA6DiAhQeYNgIm", "*\/\*");
//	const options = new RequestOptions("multipart/form-data;boundary=MyBoundary");
//	const options = new RequestOptions("application/x-www-form-urlencoded");
//	const options = new RequestOptions("application/octet-stream");
	//application/x-www-form-urlencoded

	//options.headers.set("Content-Type", "multipart/form-data");
	if(! Array.isArray(files) ){
	    //files = {"files" : files };
	}
	options.body = files;
	console.log("body for uploadedfiles");
	console.log(options);
	let uploadFiles$ =
	    this.httpService.makePostRequest<any>( `${this.postUploadFiles.url}`.format(), options).pipe(
		map(filesUploaded =>
		    {
			var newFiles : File[] = filesUploaded.Data.files?.map(fileUp => {

			    const newFile : File = {
				name : fileUp.filename,
				path : fileUp.path
			    }
			    console.log("Original File uploaded : \n {fileUploaded} \n File type to create : \n {fileType}".formatUnicorn({fileUploaded: JSON.stringify(fileUp), fileType : JSON.stringify(newFile)}));
			    return newFile;
			});
			return this.httpService.createResponseFromObj<File[]>(newFiles);
		    })
	    );
	
	return  files   ? ( Array.isArray(files) && files.length > 0 ?
	    uploadFiles$ : uploadFiles$ 
			  ) : this.httpService.createObservableResponse<File[]>({IsSuccess:  0, Data:  undefined, Status : 0})
	    ;

    }*/


	/*return  files && files.length > 0 ?
	    this.httpService.makePostRequest<File[]>( `${this.postUploadFiles.url}`.format(), options) :
	    this.httpService.createObservableResponse<File[]>({IsSuccess:  0, Data:  undefined, Status : 0});
	*/


/*    generateBoundary() : string{
	alphaNumericEncodingMap = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789AB'; //yes I know, "AB" appears twice
	
	var boundary = "----WebKitFormBoundary";
	for (var i = 0; i < 4; ++i) {
        var randomness = crypto.randomBytes(4);
        boundary += alphaNumericEncodingMap[randomness[3] & 0x3F]; //wasting bits makes me sad... but whatever
        boundary += alphaNumericEncodingMap[randomness[2] & 0x3F];
        boundary += alphaNumericEncodingMap[randomness[1] & 0x3F];
        boundary += alphaNumericEncodingMap[randomness[0] & 0x3F];
    }
    return boundary;
}*/

    
