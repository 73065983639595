import { Component, EventEmitter, Input,  Output, OnInit, ViewEncapsulation, ViewChild,
       	 SimpleChanges,  ChangeDetectionStrategy, ChangeDetectorRef, OnChanges} from '@angular/core';

import { Country, MatSelectCountryComponent } from '@angular-material-extensions/select-country';
import {timeout, takeWhile} from 'rxjs/operators';

//import { Component, EventEmitter, Input,  Output, OnInit, ViewEncapsulation, ViewChild, ChangeDetectorRef } from '@angular/core'; 
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { UserService } from 'src/app/services/user.service';
import { ProfileService } from 'src/app/services/profile.service';
import { SchoolService } from 'src/app/services/school.service';
//import { ClassGradeService } from 'src/app/services/roles.service';

import { RolesService } from 'src/app/services/roles.service';
import { StatusService } from 'src/app/services/status.service';
import { PermissionService } from 'src/app/services/permission.service';
import { ModuleService } from 'src/app/services/module.service';
import { ClassGradeService } from 'src/app/services/class-grade.service';

import { Role } from 'src/app/interfaces/role';
import { Status } from 'src/app/interfaces/status';
import { Permission } from 'src/app/interfaces/permission';
import { Profil as Profile } from 'src/app/interfaces/profil';
import { UserModel } from 'src/app/interfaces/user';
import { Module } from 'src/app/interfaces/module';
import { Address } from 'src/app/interfaces/address';
import { School } from 'src/app/interfaces/school';
import { Note } from 'src/app/interfaces/note';
import { ClassGrade } from 'src/app/interfaces/class-grade';

import { Response } from '../../interfaces/response';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-editer-ecole',
  templateUrl: './editer-ecole.component.html',
  styleUrls: ['./editer-ecole.component.scss'], encapsulation: ViewEncapsulation.None
})
export class EditerEcoleComponent  implements OnInit,  OnChanges  {

    @Input() TitleVisible: boolean = true;
    @Input() DisplayConfirmationButton: boolean = true;

    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter(); 

    ////////////////////////
    //  Attributes 
    ////////////////////////

    @Input()     _school: School;
    @Input('id') _id?: string; 

    form: FormGroup;
    
    profilesListCtrl = new FormControl('');
    profilesData : Profile[];//string[];
    profilesList : Profile[];//string[];
    selectedProfiles : Profile[];

    classGradesListCtrl = new FormControl('');
    classGradesList : ClassGrade[];//string[];
    classGradesData : ClassGrade[];//string[];
    selectedClassGrades : ClassGrade[];

    
    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatuses : Status[];
    selectedStatus : Status;


    //////////////////////// 
    // Country
    //////////////////////// 
    
    @ViewChild('countryChild') selectCountryComponent: MatSelectCountryComponent;

    countriesChildComponentsLoaded = false;

    selectedCountry: Country = undefined;
    
    _defaultCountry: Country ;
    get defaultCountry() : Country {
	return this._defaultCountry;
    }
    set defaultCountry(country: Country) {
	this._defaultCountry = country;
	this.selectedCountry  = this._defaultCountry;
    } 

    initCountriesDatabaseValuesChangesCheck(){
	this.form.valueChanges.pipe(
	    //timeout(12000),
	    takeWhile(value => !this.countriesChildComponentsLoaded)
	).subscribe( {
	    next: (formValue) => {
	    
		if (this.selectCountryComponent?.countries?.length > 0) {
		    
		    this.countriesChildComponentsLoaded = true

		    var countryName = this._school?.address?.country
			? this._school?.address?.country : "Guyane";
		    console.log(countryName)
		    // Change country here
		    this.initCountriesSearchDropdown(countryName);

		    //console.log(JSON.stringify(this.selectCountryComponent?.countries)) 
		}
	    },
	    error: (e) => console.log(`There is an Error ${e}`)
	});
    }
    
    initCountriesSearchDropdown(defaultCountryName){
	setTimeout(_=> {
	    console.log("enter init country");
	    let defaultValue = this.selectCountryComponent?.countries?.find(country => country.name === defaultCountryName);

	    this.defaultCountry = defaultValue
	    
	    console.log(this.defaultCountry);
	    if (!this.defaultCountry) {
		
		//MatSelectCountryComponent
		this.defaultCountry = this.defaultCountry ? this.defaultCountry :  
		    {
			name: 'Guyane',
			alpha2Code: 'GY',
			alpha3Code: 'GUY',
			numericCode: '328',
			callingCode: '+592'
		    };
	    }
	});
    }

    
    //////////////////////// 
    // END Country
    //////////////////////// 

    ////////////////////////
    // Constructor
    //////////////////////// 

    constructor(private router: Router,
		private route: ActivatedRoute,
		private schoolService : SchoolService,
		private statusService : StatusService,
		private classGradeService: ClassGradeService,
		private profileService : ProfileService,
		
		private changeDetectorRefs: ChangeDetectorRef,   
		private _snackBar: MatSnackBar,
		public fb: FormBuilder) { 
	
    }

    ////////////////////////
    // Init
    ////////////////////////  
    ngOnInit(): void {
	this.initCurrentRoute();
	this.refresh();
	this.initForm(); 

	 this.initCountriesDatabaseValuesChangesCheck();    
    }

    ngOnChanges(changes: SimpleChanges): void {
	console.log("Changes")
	console.log(changes);
    }    

    
    initCurrentRoute():void {
	this.route.params.subscribe(params => {  
	    this._id = params["id"];
	    console.log("current id " + this._id)
	});

    }

    initCurrentObject(): void {
	
      this.schoolService.getById(this._id).subscribe(
	  {
              next: (response: Response<number, School>) => {
		  console.log("response");
		  console.log(response);
		  this._school = response.Data;
		  this.selectedProfiles = this._school?.profiles;
		  this.selectedClassGrades = this._school?.classes;
		  if (this._school?.status)
		      this.selectedStatus = this._school?.status ;
		  if (this._school?.note) {
		      
		  } else {
		      const note : Note = {  content :  "" };
		      this._school.note = note;
		  }
		  console.log(this._school.status);
		  console.log(this._school);

		  setTimeout(_=> {
		      console.log("Enter check");
		      var countryName = this._school?.address?.country
			  ? this._school?.address?.country : "Guyane";
		      console.log(countryName);
		      this.initCountriesSearchDropdown(countryName);
		  });

              },
              error: (e) => {
		  console.log(e);
              },
              complete: () => console.info('loaduser complete')
	  }
      );

    }



    ////////////////////////
    // Datasrouces
    ///////////////////////


    
    refresh(): void {
	this.initCurrentObject();
	
	this.statusService.getAllStatuses().subscribe({
	    next: (response: Response<number, Status[]>) => {
		
		this.statusesData = response.Data;
		this.initStatusesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load statuses complete')
	});

	this.profileService.getAllProfiles().subscribe({
            next: (response: Response<number, Profile[]>) => {
		this.profilesData = response.Data;
		this.initProfilesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load profiles complete')
	});

	this.classGradeService.getAllClassGrades().subscribe({
	    next: (response: Response<number, ClassGrade[]>) => {
		this.classGradesData = response.Data;
		this.initClassGradesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load classGrades complete')
	});
    }

    ////////////////////////
    // Datasrouces Form
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    name: new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(2)]),
	    //prenom: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    phone: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$"), Validators.minLength(10)]),
	    email: new FormControl(null, [Validators.required, Validators.email]),
	    //password: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
	    //passwordcheck: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
	    //street: new FormControl(null, [Validators.required, Validators.maxLength(5), Validators.pattern("^[0-9]*$"), Validators.minLength(1)]),
	    street: new FormControl(null, [Validators.required, Validators.maxLength(60), Validators.minLength(2)]),
	    city: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    zip: new FormControl(null, [Validators.required, Validators.maxLength(5),  Validators.pattern("^[0-9]*$"), Validators.minLength(5)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
	    description: new FormControl(null, [Validators.required, Validators.maxLength(150), Validators.minLength(2)]),
	});

    }
    
    ////////////////////////
    // Datasources Functions
    ///////////////////////

    

    compareFn(c1,c2): boolean {
	return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }
    
    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns

    initClassGradesSearchDropdown(){
	this.classGradesList = [];
        for ( let i = 0 ; i < this.classGradesData.length; i ++ ) {
            this.classGradesList.push( this.classGradesData[i] );
        }
    }

    initProfilesSearchDropdown(){
	this.profilesList = [];
        for ( let i = 0 ; i < this.profilesData.length; i ++ ) {
            this.profilesList.push( this.profilesData[i] );
        }
    }


    initStatusesSearchDropdown(){
	this.statusesList = [];
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
	    {

		if (!this.selectedStatus && this.statusesData[i].label == "Actif")
		{
		    this.selectedStatuses = [];
		    this.selectedStatus = this.statusesData[i];
		    
		    this.selectedStatuses.push(this.statusesData[i]);
		}
		this.statusesList.push( this.statusesData[i] );
	    }
        }

    }
    
    ////////////////////////
    // On change

    onStatusesChanged($event: any) {
      console.log($event);
    }

    onProfilesChanged($event: any) {
      console.log($event);
    }

    onCountrySelected($event: Country) {
	this.selectedCountry = $event;
    }
    
    onClassGradesChanged($event: any) {
      console.log($event);
    }

    ////////////////////////
    // On Search
    onKeyProfiles(value) { 
            this.profilesList= []; 
            this.selectProfileSearch(value);       
    }

    onKeyClassGrades(value) { 
            this.classGradesList= []; 
            this.selectClassGradesSearch(value);       
    }


    onKeyStatuses(value) { 
            this.statusesList= []; 
            this.selectStatusesSearch(value);       
    }

    ////////////////////////
    // search elements

        selectClassGradesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.classGradesData.length; i ++ ) {
            let option = this.classGradesData[i];
            if (  option.classLevel?.name.toLowerCase().indexOf(filter) >=0
		||  option.school?.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.classGradesList?.push( option );
            }
        }
    }

    selectProfileSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.profilesData.length; i ++ ) {
            let option = this.profilesData[i];
            if (  option.user?.username.toLowerCase().indexOf(filter) >= 0
		|| option.firstname?.toLowerCase().indexOf(filter) >= 0 
		|| option.lastname?.toLowerCase().indexOf(filter) >= 0  ) {
                this.profilesList?.push( option );
            }
        }
    }

    
    selectStatusesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
            let option = this.statusesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.statusesList?.push( option );
            }
        }
    }




    ////////////////////////
    ///////////////////////
    
    
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid) {
	    return;
	}

	let school : School = {
            name: this.form.value.name,
            phoneNumber: this.form.value.phone,
            email: this.form.value.email,
	    description: this.form.value.description,
	};

	const address: Address = {
	    country:  this.selectedCountry.name,
            street: this.form.value.street?.toString(),
            city: this.form.value.city,
            zip: this.form.value.zip?.toString(),
	    //	  zipCode: this.form.value.postal?.toString(),
            additionalDetail: ''
	  
	};

	const note : Note = {
	    content : this.form.value.note
	}

	if ((this._school.note &&  this._school.note.id ) || this._school.noteId ){
	    note.id =  this._school.note && this._school.note.id ?  this._school.note.id :  this._school.noteId ; 
	}
	
	if (this.selectedStatus){
	    this._school.statusId = this.selectedStatus.id;
	}


	this._school.note = note;
	this._school.address = address;
	this._school.profiles = this.selectedProfiles;
	this._school.classes = this.selectedClassGrades;
		
	console.log(this._school);
	console.log("gonna save");
	console.log(this.selectedProfiles);
	

	this.schoolService.prepareUpdateRelations(this._school).pipe(
	    switchMap(preparedSchool => { 
		console.log("entered switch map -- add new school ");
		console.log(preparedSchool);
		return this.schoolService.updateById(this._school.id, this._school);
	    }),
	    switchMap(schoolUpdatedObservable => {
		let schoolUpdated = schoolUpdatedObservable.Data;
		    
		console.log("entered switch map -- handle post relations ");
		console.log(this._school);
		this._school.profiles = this.selectedProfiles;
		this._school.classes = this.selectedClassGrades;
		return this.schoolService.handlePostUpdateRelations(this._school); 
	    })
	
						      
	).subscribe({
	    next: (res) => { this.checkSubmitSucess(res);},
	    error: (error) => {this.submitError(error)},
	    complete: () => {
		console.info('save school pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();
		    }, 5000);
		
	    }
	    
	    
	});

    }

    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListEcolePath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }




}


    /*  @Input() TitleVisible: boolean = true;
  @Input() DisplayConfirmationButton: boolean = true;

  form: FormGroup;
  utilisateurs: any;
  permissions: any;
  selectedCountry: Country = undefined;
  
  toppings = new FormControl('');

  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

  constructor(private router: Router,
      private _snackBar: MatSnackBar,
      public fb: FormBuilder) { 
 
      }
      
  ngOnInit(): void {
    this.form = this.fb.group({
      nom: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
      prenom: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
      phone: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$"), Validators.minLength(10)]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
      passwordcheck: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
      street: new FormControl(null, [Validators.required, Validators.maxLength(5), Validators.pattern("^[0-9]*$"), Validators.minLength(1)]),
      city: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
      postal: new FormControl(null, [Validators.required, Validators.maxLength(5),  Validators.pattern("^[0-9]*$"), Validators.minLength(5)])
    });
  }

  onCountrySelected($event: Country) {
    this.selectedCountry = $event;
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
  }
*/
