import { Component, EventEmitter, Input,  Output, OnInit, ViewEncapsulation, ViewChild,
       	 SimpleChanges,  ChangeDetectionStrategy, ChangeDetectorRef, OnChanges} from '@angular/core';

import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { Country, MatSelectCountryComponent } from '@angular-material-extensions/select-country';

import { Note } from 'src/app/interfaces/note';
import { Address } from 'src/app/interfaces/address';
//import { CompanyType } from 'src/app/interfaces/company-type';
import { Customer } from 'src/app/interfaces/customer';
import { Status } from 'src/app/interfaces/status';
import { Company  } from 'src/app/interfaces/company';

//import { CompanyTypeService } from 'src/app/services/company-type.service';
import { CustomerService } from 'src/app/services/customer.service';
import { StatusService } from 'src/app/services/status.service';
import { CompanyService } from 'src/app/services/company.service';

import { Response } from '../../interfaces/response';

import {timeout, takeWhile} from 'rxjs/operators';
import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-editer-customer',
  templateUrl: './editer-customer.component.html',
    styleUrls: ['./editer-customer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    //changeDetection: ChangeDetectionStrategy.OnPush 
})
export class EditerCustomerComponent implements OnInit, OnChanges {

    @Input() TitleVisible: boolean = true;
    @Input() DisplayConfirmationButton: boolean = true;

    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();

    
    ////////////////////////
    //  Attributes     
    //////////////////////// 

    @Input()     _customer: Customer;
    @Input('id') _id?: string;
    
    form: FormGroup;


/* 
    companyTypesListCtrl = new FormControl('');
    companyTypesData : CompanyType[];//string[];
    companyTypesList : CompanyType[];//string[];
    selectedCompanyTypes : CompanyType[]; 
*/

    
    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatuses : Status[];
    selectedStatus : Status;

    companiesListCtrl = new FormControl('');
    companiesList : Company[];//string[];
    companiesData : Company[];//string[];
    selectedCompanies : Company[];
    selectedCompany : Company;


    //////////////////////// 
    // Country
    //////////////////////// 
    
    @ViewChild('countryChild') selectCountryComponent: MatSelectCountryComponent;

    countriesChildComponentsLoaded = false;

    selectedCountry: Country = undefined;
    
    _defaultCountry: Country ;
    get defaultCountry() : Country {
	return this._defaultCountry;
    }
    set defaultCountry(country: Country) {
	this._defaultCountry = country;
	this.selectedCountry  = this._defaultCountry;
    } 


    
    ////////////////////////
    // Constructor
    ////////////////////////
  
    constructor(private router: Router,
		private route: ActivatedRoute,
		private customerService : CustomerService,
		private statusService : StatusService,
		//private companyTypeService : CompanyTypeService,
		private companyService : CompanyService,

		private changeDetectorRef: ChangeDetectorRef,
		private _snackBar: MatSnackBar,
		public fb: FormBuilder) { 
 
      }

    ////////////////////////
    // Init
    ////////////////////////  

    ngOnInit(): void {
	this.initCurrentRoute();
	this.refresh();
	this.initForm();

	this.initCountriesDatabaseValuesChangesCheck();	

    }

    ngAfterViewInit(){
	console.log("**after view init");

	

    }

    ngOnChanges(changes: SimpleChanges): void {
	console.log("Changes")
	console.log(changes);
    }    

    initCountriesDatabaseValuesChangesCheck(){
	this.form.valueChanges.pipe(
	    //timeout(12000),
	    takeWhile(value => !this.countriesChildComponentsLoaded)
	).subscribe( {
	    next: (formValue) => {
	    
		if (this.selectCountryComponent?.countries?.length > 0) {
		    
		    this.countriesChildComponentsLoaded = true

		    var countryName = this._customer?.shippingAddress?.country
			? this._customer?.shippingAddress?.country : "Guyane";
		    console.log(countryName)
		    // Change country here
		    this.initCountriesSearchDropdown(countryName);

		    //console.log(JSON.stringify(this.selectCountryComponent?.countries)) 
		}
	    },
	    error: (e) => console.log(`There is an Error ${e}`)
	});
    }


    initCurrentRoute():void {
	this.route.params.subscribe(params => {  
	    this._id = params["id"];
	    console.log("current id " + this._id)
	});

    }

    initCurrentObject(): void {
	
      this.customerService.getById(this._id).subscribe(
	  {
              next: (response: Response<number, Customer>) => {
		  console.log("response");
		  console.log(response);
		  this._customer = response.Data;
		  //this.selectedCompanyTypes = this._customer?.companyTypes;

		  if (this._customer?.status)
		      this.selectedStatus = this._customer?.status ;
		  
		  if (this._customer?.company)
		      this.selectedCompany = this._customer?.company ;

		  if (!this._customer?.shippingAddress)
		      this._customer.shippingAddress = { country : "Guyane", street: "", city : "", zip : "" };
		  if (!this._customer?.billingAddress)
		      this._customer.billingAddress = { country : "Guyane", street: "", city : "", zip : "" };
		  if (!this._customer?.profile)
		      this._customer.profile = { firstname : "", lastname: "", email : "", phoneNumber : "" };
		  if (!this._customer?.profile?.address)
		      this._customer.profile.address = this._customer?.shippingAddress;
		  if (this._customer?.note) {
		      
		  } else {
		      const note : Note = {  content :  "" };
		      this._customer.note = note;
		  }
		  console.log(this._customer.status);
		  console.log(this._customer);

		  setTimeout(_=> {
		      console.log("Enter check");
		      var countryName = this._customer?.shippingAddress?.country
			  ? this._customer?.shippingAddress?.country : "Guyane";
		      console.log(countryName);
		      this.initCountriesSearchDropdown(countryName);
		  });
              },
              error: (e) => {
		  console.log(e);
              },
              complete: () => console.info('loaduser complete')
	  }
      );

    }



    ////////////////////////
    // Datasrouces
    ///////////////////////


    
    refresh(): void {
	this.initCurrentObject();
	
	this.statusService.getAllStatuses().subscribe({
	    next: (response: Response<number, Status[]>) => {
		
		this.statusesData = response.Data;
		this.initStatusesSearchDropdown();
		this.changeDetectorRef.markForCheck();
            },error: (e) => { }, complete: () => console.info('load statuses complete')
	});

	this.companyService.getAllCompanies().subscribe({
	    next: (response: Response<number, Company[]>) => {
		
		this.companiesData = response.Data;
		this.initCompaniesSearchDropdown();
		 this.changeDetectorRef.markForCheck();  
            },error: (e) => { }, complete: () => console.info('load companies complete')
	});

	/*
	this.companyTypeService.getAllCompanyTypes().subscribe({
            next: (response: Response<number, CompanyType[]>) => {
		this.companyTypesData = response.Data;
		this.initCompanyTypesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load companyTypes complete')
	});
	*/

    }

    ////////////////////////
    // Datasrouces Form
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    firstname: new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(2)]),
	    lastname:  new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(2)]),
	    phone: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$"), Validators.minLength(10)]),
	    email: new FormControl(null, [Validators.required, Validators.email]),
//	    password: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
//	    passwordcheck: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),

	    street: new FormControl(null, [Validators.required, Validators.maxLength(30), Validators.minLength(2)]),
	    //	    street: new FormControl(null, [Validators.required, Validators.maxLength(35), Validators.pattern("^[0-9]*$"), Validators.minLength(1)]),
	    city: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    zip: new FormControl(null, [Validators.required, Validators.maxLength(5),  Validators.pattern("^[0-9]*$"), Validators.minLength(5)]),
	    

	    billingStreet: new FormControl(null, [Validators.required, Validators.maxLength(30), Validators.minLength(2)]),
	    billingCity: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    billingZip: new FormControl(null, [Validators.required, Validators.maxLength(5),  Validators.pattern("^[0-9]*$"), Validators.minLength(5)]),

	    //	    label: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    //	    description: new FormControl(null, [Validators.required, Validators.maxLength(150), Validators.minLength(2)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
	    country : new FormControl(null, [Validators.required])
	});

    }
    
    ////////////////////////
    // Datasources Functions
    ///////////////////////

    

    compareFn(c1,c2): boolean {
	return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }
    
    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns

    /*
    initCompanyTypesSearchDropdown(){
	this.companyTypesList = [];
        for ( let i = 0 ; i < this.companyTypesData.length; i ++ ) {
            this.companyTypesList.push( this.companyTypesData[i] );
        }
    }
    */

    initStatusesSearchDropdown(){
	this.statusesList = [];
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
	    {

		if (!this.selectedStatus && this.statusesData[i].label == "Actif")
		{
		    this.selectedStatuses = [];
		    this.selectedStatus = this.statusesData[i];
		    
		    this.selectedStatuses.push(this.statusesData[i]);
		}
		this.statusesList.push( this.statusesData[i] );
	    }
        }

    }

    initCompaniesSearchDropdown(){
	this.companiesList = [];
        for ( let i = 0 ; i < this.companiesData.length; i ++ ) {
	    {
		this.companiesList.push( this.companiesData[i] );
	    }
        }

    }


    initCountriesSearchDropdown(defaultCountryName){
	setTimeout(_=> {
	    console.log("enter init country");
	    let defaultValue = this.selectCountryComponent?.countries?.find(country => country.name === defaultCountryName);

	    this.defaultCountry = defaultValue
	    
	    console.log(this.defaultCountry);
	    if (!this.defaultCountry) {
		
		//MatSelectCountryComponent
		this.defaultCountry = this.defaultCountry ? this.defaultCountry :  
		    {
			name: 'Guyane',
			alpha2Code: 'GY',
			alpha3Code: 'GUY',
			numericCode: '328',
			callingCode: '+592'
		    };
	    }
	});
    }


    ////////////////////////
    // On change

    onStatusesChanged($event: any) {
      console.log($event);
    }

    onCompaniesChanged($event: any) {
	console.log($event);
    }
    
    /*
      onCompanyTypesChanged($event: any) {
      console.log($event);
      }
    */

    ////////////////////////
    // On Search

    onCountrySelected($event: Country) {
    this.selectedCountry = $event;
  }

/*    
    onKeyCompanyTypes(value) { 
            this.companyTypesList= []; 
            this.selectCompanyTypeSearch(value);       
    }
*/


    onKeyStatuses(value) { 
            this.statusesList= []; 
            this.selectStatusesSearch(value);       
    }

    onKeyCompanies(value) { 
            this.companiesList= []; 
            this.selectCompaniesSearch(value);       
    }

    ////////////////////////
    // search elements

/*
    selectCompanyTypeSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.companyTypesData.length; i ++ ) {
            let option = this.companyTypesData[i];
            if (  option.companyTypeNumber.toLowerCase().indexOf(filter) >= 0
		|| option.companyTypeNumber?.toLowerCase().indexOf(filter) >= 0 ) {
                this.companyTypesList?.push( option );
            }
        }
    }
*/
    
    selectStatusesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
            let option = this.statusesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.statusesList?.push( option );
            }
        }
    }



    selectCompaniesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.companiesData.length; i ++ ) {
            let option = this.companiesData[i];
            if (    option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.companiesList?.push( option );
            }
        }
    }




    ////////////////////////
    ///////////////////////
    
    
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid) {
	    return;
	}


	const note : Note = {
	    content : this.form.value.note
	}

	if ((this._customer.note &&  this._customer.note.id ) || this._customer.noteId ){
	    note.id =  this._customer.note && this._customer.note.id ?  this._customer.note.id :  this._customer.noteId ; 
	}
	
	if (this.selectedStatus){
	    this._customer.statusId = this.selectedStatus.id;
	}

	/*if (this.selectedCompanies){
	    this._customer.familyTypeIdcompanies = this.selectedCompanies;
	}*/

	const address: Address = {
	  country:  this.selectedCountry.name,
          street: this.form.value.street?.toString(),
          city: this.form.value.city,
          zip: this.form.value.zip?.toString(),
//	  zipCode: this.form.value.postal?.toString(),
          additionalDetail: ''	  
      };

	const billingAddress: Address = {
	    country:  this.selectedCountry.name,
	    street: this.form.value.billingStreet?.toString(),
	    city: this.form.value.billingCity,
	    zip: this.form.value.billingZip?.toString(),
	    //	  zipCode: this.form.value.postal?.toString(),
	    additionalDetail: ''	  
	};
	
	// Shipping Address
	if (this._customer.shippingAddress?.id)
	    address.id = this._customer.shippingAddress.id.toString();
	else if (this._customer.shippingAddressId)
	    address.id = this._customer.shippingAddressId;


	// Billing Address
	if (this._customer.billingAddress?.id)
	    billingAddress.id = this._customer.billingAddress.id.toString();
	else if (this._customer.billingAddressId)
	    billingAddress.id = this._customer.billingAddressId;


	
	this._customer.note = note;
	this._customer.shippingAddress = address;
	this._customer.billingAddress = billingAddress;
	this._customer.profile.address = address;


	if (this.selectedCompany)
	    this._customer.companyId = this.selectedCompany.id;

	/*
	if (this.selectedCompanyTypes && this.selectedCompanyTypes.length > 0)
	    this._customer.companyTypes = this.selectedCompanyTypes;

	if (this.selectedCompanies && this.selectedCompanies.length > 0)
	    this._customer.companies = this.selectedCompanies;
*/	
	this._customer.note = note;

		
	console.log(this._customer);
	console.log("gonna save");
//	console.log(this.selectedCompanyTypes);
	

	this.customerService.prepareUpdateRelations(this._customer).pipe(
	    switchMap(preparedCustomer => { 
		console.log("entered switch map -- add new customer ");
		console.log(preparedCustomer);
		return this.customerService.updateById(this._customer.id, this._customer);
	    }),
	    switchMap(customerUpdatedObservable => {
		let customerUpdated = customerUpdatedObservable.Data;
		    
		console.log("entered switch map -- handle post relations ");
		console.log(this._customer);
		//this._customer.companyTypes = this.selectedCompanyTypes;
		//this._customer.companies = this.selectedCompanies;
		return this.customerService.handlePostUpdateRelations(this._customer); 
	    })
	
						      
	).subscribe({
	    next: (res) => { this.checkSubmitSucess(res);},
	    error: (error) => {this.submitError(error)},
	    complete: () => {
		console.info('save customer pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();
		    }, 5000);
		
	    }
	    
	    
	});

    }

    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListCustomerPath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }




}
