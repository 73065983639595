import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-show-errors',
  templateUrl: './show-errors.component.html',
  styleUrls: ['./show-errors.component.scss']
})

export class ShowErrorsComponent implements OnInit{

  @Input() ctrl: FormControl;

  ERROR_MESSAGE = {
      required: () => `Ce champ est obligatoire`,
      //pattern : (par) => `Mauvais format ${JSON.stringify(par.requiredPattern)}`,
      email: () => `Saisir une adresse email ( ex:  test@domaine.fr)`,
      minlength: (par) => ` ${par.requiredLength} lettres/nombres minimum requis.`,
      minLength: (par) => ` ${par.requiredLength} lettres/nombres minimum requis.`,
      maxlength: (par) => ` ${par.requiredLength} lettres/nombres maximum requis.`,
      maxLength: (par) => ` ${par.requiredLength} lettres/nombres maximum requis.`,
  };

  constructor() { }

  ngOnInit() { }

  shouldShowErrors(): boolean {
    return this.ctrl && this.ctrl.errors && this.ctrl.touched;
  }

  listOfErrors(): string[] {
    return Object.keys(this.ctrl.errors).map(
	err => this.ERROR_MESSAGE[err] ?
	    this.ERROR_MESSAGE[err](this.ctrl.getError(err) ? this.ctrl.getError(err) : "Erreur sur le champ du formulaire :" /*+err*/):
	    "Erreur sur le champ du formulaire :" //+ err
    );
  }

}
