import { AfterViewInit, Component, EventEmitter, OnInit,  Output, Input,
	 ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';

import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

// models
import { Model } from 'src/app/interfaces/model';  
import { Note } from 'src/app/interfaces/note';
import { Status } from 'src/app/interfaces/status';
import { CompanyGroup } from 'src/app/interfaces/company-group';
import { Company  } from 'src/app/interfaces/company';
import { Product  } from 'src/app/interfaces/product';

// services
import { CompanyGroupService } from 'src/app/services/company-group.service';
import { StatusService } from 'src/app/services/status.service';
import { CompanyService } from 'src/app/services/company.service';
import { ProductService } from 'src/app/services/product.service';

//
import { Response } from '../../interfaces/response';

//
import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

import {ActivatedRoute} from '@angular/router';

//
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';

import { MatSort } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';

import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';


@Component({
  selector: 'app-editer-company-group',
  templateUrl: './editer-company-group.component.html',
  styleUrls: ['./editer-company-group.component.scss'], encapsulation: ViewEncapsulation.None
})
export class EditerCompanyGroupComponent implements OnInit {
  @Input() TitleVisible: boolean = true;
  @Input() DisplayConfirmationButton: boolean = true;

    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();

    ////////////////////////
    //  Attributes     
    //////////////////////// 

    @Input()     _companyGroup: CompanyGroup;
    @Input('id') _id?: string;
    
    form: FormGroup;


    companiesListCtrl = new FormControl('');
    companiesData : Company[];//string[];
    companiesList : Company[];//string[];
    selectedCompanies : Company[];

    
    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatuses : Status[];
    selectedStatus : Status;

    ////////////////////////
    // PRODUCTS ATTRIBUTES 
    ////////////////////////
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    
    //
    // Datatable
    //
    //columns: string[] = ['#', 'Price' ];
    columnsSelection: string[] = ['#', 'image', 'product.name', 'product.description', 'product.price', 'price', 'Action' ];

    //datasource: MatTableDataSource<Model> = new MatTableDataSource<Model>();
    datasourceSelection: MatTableDataSource<Model> = new MatTableDataSource<Model>();

    pageSize = 20;
    
    //
    // Data Sources
    //
    productsListCtrl = new FormControl('');
    productsList : Product[];//string[];
    productsData : Product[];//string[];
    selectedProducts : Product[];


    // selectedProduct Converted 
    selectedProductDetails : any[] = [];
    selectedProductDetailsMap : Map<any, any> = new Map<any, any>();
    productDetails : any[] = [];
    
    
    isLoading = true;
    

    ////////////////////////
    // Constructor
    ////////////////////////
  
    constructor(private router: Router,
		private route: ActivatedRoute,
		private companyGroupService : CompanyGroupService,
		private statusService : StatusService,
		private companyService : CompanyService,
		private productService : ProductService,
		
		private _snackBar: MatSnackBar,
		public fb: FormBuilder) { 
 
      }

    ////////////////////////
    // Init
    ////////////////////////  
    ngOnInit(): void {
	this.initCurrentRoute();
	this.refresh();
	this.initForm(); 

    }

    ngAfterViewInit() {
	this.datasourceSelection.paginator = this.paginator;
	this.datasourceSelection.sort = this.sort;
	this.datasourceSelection.filterPredicate = this.tableFilter(); 

    }


    initCurrentRoute():void {
	this.route.params.subscribe(params => {  
	    this._id = params["id"];
	    console.log("current id " + this._id)
	});

    }

    initCurrentObject(): void {
	
      this.companyGroupService.getById(this._id).subscribe(
	  {
              next: (response: Response<number, CompanyGroup>) => {
		  console.log("response");
		  console.log(response);

		  this._companyGroup = response.Data;
		  
		  this.selectedCompanies = this._companyGroup?.companies;
		  this.selectedProductDetails = this._companyGroup?.companyGroupItemTypes;
		  this.selectedProductDetailsMap = this.toMap(this.selectedProductDetails, 'productId');

		  if (this._companyGroup?.status)
		      this.selectedStatus = this._companyGroup?.status ;

		  /*
		    if (this._companyGroup?.familyType)
		    this.selectedProduct = this._companyGroup?.familyType ;
		  */
		  
		  if (this._companyGroup?.note) {
		      
		  } else {
		      const note : Note = {  content :  "" };
		      this._companyGroup.note = note;
		  }
		  console.log(this._companyGroup.status);
		  console.log(this._companyGroup);

		  this.initDataTables();
              },
              error: (e) => {
		  console.log(e);
              },
              complete: () => console.info('loaduser complete')
	  }
      );

    }

    getUrlOf(element) : string {
	var path =  element.path ? element.path.replace(/^public?\//i, "") : null;
	return element && element.path ? `${environment.domains.api.url}${path}` : "";
    }


    ////////////////////////
    // Datatable
    ///////////////////////

    
    initDatasources() : void {
	
	this.datasourceSelection.data = this.productDetails;
	this.datasourceSelection.paginator = this.paginator;
	this.datasourceSelection.sort = this.sort;
	this.datasourceSelection.filterPredicate = this.tableFilter(); 
	
    }

    initDataTables(): void {
	this.setLoading(true);  
	this.productService.getAllProducts().subscribe({
            next: (response: Response<number, Product[]>) => {
		this.productsData = response.Data;
		this.productDetails = this.productsData.map(product => this.fromProductDataToProductDetail(product));

		console.log(this.selectedProductDetails);
		this.initDatasources(); 
		this.initProductsSearchDropdown();
		this.setLoading(false);
            },error: (e) => { this.setLoading(false); }, complete: () => console.info('load roles complete')
	});


    }

    getCheckedItems() {
	this.selectedProductDetails = this.datasourceSelection.data
	    .filter(i => (i as any).checked == true)
	    .map(product => this.fromProductDetailToProductData(product));
    }

    fromProductDetailToProductData(productDetail) {
	let productData = {
	    productId : productDetail.productId,
	    price : productDetail.price
	    //quantity: productDetail.quantity
	};
	return productData;
    }

    toMap(list, keyProp) : any{
	const keyValuePairs = list?.map(item => [item[keyProp], item]);
	return new Map(keyValuePairs);
    }

    fromProductDataToProductDetail(product) {
	// check if product is checked
	//let found = this.selectedProductDetails?.find(x=> x.productId == product.id);
	let found = this.selectedProductDetailsMap.get(product.id);
	if (found)
	{
	    //console.log(found);
	    //console.log(this.selectedProductDetails);
	}
	let productDetail = {
	    checked : found ? true :  false,
	    productId : product.id,
	    product: product, 
	    price : found ? found.price : product.price,
	    quantity: 0,
	    img : product.mainImage? this.getUrlOf(product.mainImage) : ""
	}
	//console.log(productDetail);
	return productDetail;
	
    }
    
    ////////////////////////
    // Filter Datatable
    ///////////////////////

    doFilter(value: string) : void  {
	this.datasourceSelection.filter = value.trim().toLocaleLowerCase();
    }

    
    tableFilter(): (data: any, filter: string) => boolean {
	let columnsNames = this.columnsSelection;

	let filterFunction = function(data, filter): boolean{
	    

	    var datas = columnsNames.map(x => {
		// split string succh as "customer.name"
		var objectData = x.includes(".") ? x.split(".") : [x];
		var currentObject = data;

		// concat array to create object like data["customer"]["name"]
		objectData.forEach(key => {
		    currentObject = currentObject[key];
		})
		// get val and check if its related to filter
		let val = currentObject ?? data[x];
		if (val && val.toString().toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1){		    
		    return  data;
		}
		return null;
		
	    }).filter(x => x); // map and filter to eliminate null data in array

	    
	    return datas?.length > 0 ? true : false; 
	}
	return filterFunction;
     }



    ////////////////////////
    // Datasrouces
    ///////////////////////


    
    refresh(): void {
	this.initCurrentObject();
	
	this.statusService.getAllStatuses().subscribe({
	    next: (response: Response<number, Status[]>) => {
		
		this.statusesData = response.Data;
		this.initStatusesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load statuses complete')
	});


	this.companyService.getAllCompanies().subscribe({
            next: (response: Response<number, Company[]>) => {
		this.companiesData = response.Data;
		this.initCompaniesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load companies complete')
	});


    }

    ////////////////////////
    // Datasrouces Form
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    name: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    label: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    description: new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(2)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
	});

    }
    
    ////////////////////////
    // Datasources Functions
    ///////////////////////

    

    compareFn(c1,c2): boolean {
	return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }
    
    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns


    initCompaniesSearchDropdown(){
	this.companiesList = [];
        for ( let i = 0 ; i < this.companiesData.length; i ++ ) {
            this.companiesList.push( this.companiesData[i] );
        }
    }


    initStatusesSearchDropdown(){
	this.statusesList = [];
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
	    {

		if (!this.selectedStatus && this.statusesData[i].label == "Actif")
		{
		    this.selectedStatuses = [];
		    this.selectedStatus = this.statusesData[i];
		    
		    this.selectedStatuses.push(this.statusesData[i]);
		}
		this.statusesList.push( this.statusesData[i] );
	    }
        }

    }

    initProductsSearchDropdown(){
	this.productsList = [];
        for ( let i = 0 ; i < this.productsData.length; i ++ ) {
	    {
		this.productsList.push( this.productsData[i] );
	    }
        }

    }

    ////////////////////////
    // On change

    onStatusesChanged($event: any) {
      console.log($event);
    }

    onProductsChanged($event: any) {
	console.log($event);
    }

    onCompaniesChanged($event: any) {
      console.log($event);
    }


    ////////////////////////
    // On Search
    onKeyCompanies(value) { 
            this.companiesList= []; 
            this.selectCompanySearch(value);       
    }



    onKeyStatuses(value) { 
            this.statusesList= []; 
            this.selectStatusesSearch(value);       
    }

    onKeyProducts(value) { 
            this.productsList= []; 
            this.selectProductsSearch(value);       
    }

    ////////////////////////
    // search elements


    selectCompanySearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.companiesData.length; i ++ ) {
            let option = this.companiesData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0
		|| option.name?.toLowerCase().indexOf(filter) >= 0 ) {
                this.companiesList?.push( option );
            }
        }
    }

    
    selectStatusesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
            let option = this.statusesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.statusesList?.push( option );
            }
        }
    }



    selectProductsSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.productsData.length; i ++ ) {
            let option = this.productsData[i];
            if (option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.productsList?.push( option );
            }
        }
    }



    //////////////////////
    // Loading
    //////////////////////

    toggleLoading() : void {
	this.isLoading = !this.isLoading;
    }

    setLoading(loading : boolean) : void {
	this.isLoading = loading;
    }

    //////////////////////
    //////////////////////

    
    ////////////////////////
    // Links / URL
    ///////////////////////


    openInNewTab(namedRoute) {
	let newRelativeUrl = this.router.createUrlTree([namedRoute]);
	console.log(newRelativeUrl);
	let baseUrl = window.location.href.replace(this.router.url, '');

	window.open(baseUrl + newRelativeUrl, '_blank');
    }
    
    onConsulterProduct(id): void {
	this.openInNewTab(`${environment.paths.ConsulterProductPath}/${id}`);
    }


    ////////////////////////
    ///////////////////////
    
    
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid) {
	    return;
	}

	this.getCheckedItems();
	let companyGroup : CompanyGroup = {
            name: this.form.value.name,
	    description: this.form.value.description,
	};


	const note : Note = {
	    content : this.form.value.note
	}

	if ((this._companyGroup.note &&  this._companyGroup.note.id ) || this._companyGroup.noteId ){
	    note.id =  this._companyGroup.note && this._companyGroup.note.id ?  this._companyGroup.note.id :  this._companyGroup.noteId ; 
	}
	
	if (this.selectedStatus){
	    this._companyGroup.statusId = this.selectedStatus.id;
	}

	/*if (this.selectedProducts){
	    this._companyGroup.familyTypeIdproducts = this.selectedProducts;
	}*/


	this._companyGroup.note = note;
	this._companyGroup.companies = this.selectedCompanies;
	if (this.selectedProductDetails && this.selectedProductDetails.length > 0)
	    this._companyGroup.companyGroupItemTypes = this.selectedProductDetails;
	
	console.log(this._companyGroup);
	console.log("gonna save");
	console.log(this.selectedCompanies);
	

	this.companyGroupService.prepareUpdateRelations(this._companyGroup).pipe(
	    switchMap(preparedCompanyGroup => { 
		console.log("entered switch map -- add new companyGroup ");
		console.log(preparedCompanyGroup);
		return this.companyGroupService.updateById(this._companyGroup.id, this._companyGroup);
	    }),
	    switchMap(companyGroupUpdatedObservable => {
		let companyGroupUpdated = companyGroupUpdatedObservable.Data;
		    
		console.log("entered switch map -- handle post relations ");
		console.log(this._companyGroup);
		this._companyGroup.companyGroupItemTypes = this.selectedProductDetails;
		this._companyGroup.companies = this.selectedCompanies;
		return this.companyGroupService.handlePostUpdateRelations(this._companyGroup); 
	    })
	
						      
	).subscribe({
	    next: (res) => { this.checkSubmitSucess(res);},
	    error: (error) => {this.submitError(error)},
	    complete: () => {
		console.info('save companyGroup pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();
		    }, 5000);
		
	    }
	    
	    
	});

    }

    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListCompanyGroupsPath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }
}
