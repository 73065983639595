<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">ÉDITER LA CATÉGORIE DE PRODUIT</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Produits</a></li>
                    <li class="breadcrumb-item active">Catégorie</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;"  *ngIf="_productCategory" >



        <h3 style="color: black; font-size:medium">Catégorie de produits</h3>
        <h6 style="color: black; font-size: small">Éditer la catégorie de produit</h6>
        <form class="example-form" [formGroup]="form" (ngSubmit)="submit()">


  
            <mat-label>Nom</mat-label>
            <input type="text" matInput formControlName="name"  [(ngModel)]="_productCategory.name"  class="form-control form-control-sm mb-2" required>
	    <app-show-errors [ctrl]="form.get('name')"></app-show-errors>
	    
            <mat-form-field appearance="outline" class="example-full-width mb-1 mt-3">
                <mat-label>Description</mat-label>
            	<textarea style="height: 50px;" matInput placeholder="Description" formControlName="description"
			  [(ngModel)]="_productCategory.description" 
			  required></textarea>
		<app-show-errors [ctrl]="form.get('description')"></app-show-errors>  
            </mat-form-field>

            <mat-label>Type de produits</mat-label>
            <div class="input-group  mb-2">
		<mat-select placeholder="Choisir les produits pour cette marque"
			    class="  form-control form-control-sm mb-2"
			    [formControl]="productTypesListCtrl" 
			    [(ngModel)]="selectedProductTypes"
			    [compareWith]="compareFn"
			    (selectionChange)="onProductTypesChanged($event)"
			    multiple required >
		    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyProductTypes($event.target.value)"> 
		    <mat-select-trigger>
			{{productTypesListCtrl.value?.[0]?.name  || ''}} 
                        <span *ngIf="(productTypesList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedProductTypes?.length || 0) - 1}} {{selectedProductTypes?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
		    </mat-select-trigger>
			    <mat-option *ngFor="let productType of productTypesList" [value]="productType">{{productType.name}}</mat-option>
		</mat-select>
		<div class="input-group-append">
                    <button type="button" class="btn btn-primary  form-control-sm">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>

            <mat-form-field appearance="outline" class="example-full-width mt-3">
                <mat-label>Note</mat-label>
		<textarea style="height: 50px;" matInput placeholder="note" formControlName="note"
			  [ngModel]="_productCategory && _productCategory.note && _productCategory.note.content"
			  (ngModelChange)="_productCategory && _productCategory.note && _productCategory.note.content  = $event"
			  required></textarea>
		<app-show-errors [ctrl]="form.get('note')"></app-show-errors> 
            </mat-form-field>

            <mat-label>Status</mat-label>
            <div class="input-group">
		<mat-select placeholder="Choisir un statut pour cette marque" class="  form-control form-control-sm mb-2"   required
			    [(ngModel)]="selectedStatus" [formControl]="statusesListCtrl"  [compareWith]="compareFn" (selectionChange)="onStatusesChanged($event)"  >
		    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyStatuses($event.target.value)">  
                    <mat-select-trigger>
                        {{selectedStatus?.label  || ''}}
                        <span *ngIf="(selectedStatuses?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedStatuses?.length || 0) - 1}} {{selectedStatuses?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
		</mat-select>
            </div>
            
            <div class="example-full-width d-flex justify-content-center" *ngIf="DisplayConfirmationButton">
                <button class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>
                <!-- <button mat-raised-button cdkFocusInitial>Annuler</button> -->
            </div>



        </form>




    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
