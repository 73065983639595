import { of, map, concatMap, switchMap, Observable, exhaustMap, EMPTY, empty ,identity} from 'rxjs';   
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { Response as Rep } from '../../interfaces/response';

import {CustomHttpRequestService as HttpService} from "../http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../http/http-request-options.service"

export class BaseCrudModelService<T> {

    baseName: string;
    baseUrl : string;
    listingUrl : string;
    viewUrl: string;
    updateUrl : string;
    deleteUrl: string;

    options :  RequestOptions = new RequestOptions()
    methods = {
	listing: {url: "", urlOptions: this.options},
	view : { url: "" , urlOptions : this.options},
	update : { url: "" , urlOptions : this.options},
	delete : { url: "" , urlOptions : this.options},
	add : { url: "" , urlOptions : this.options},
    }
    constructor(protected httpService: HttpService, name : string = "") {
	var url = `${environment.domains.api.url}`;

	this.baseName = name;
	this.baseUrl = `${url}/${this.baseName}`;
	this.methods.listing.url = `${this.baseUrl}`;
	this.methods.view.url = `${this.baseUrl}`;
	this.methods.update.url = `${this.baseUrl}`;
	this.methods.delete.url = `${this.baseUrl}`;
	this.methods.add.url = `${this.baseUrl}`;
    }

    public getAll(requestOptions?: RequestOptions) : Observable<Rep<number, T[]>> {
	const options = requestOptions ? requestOptions : this.methods.listing.urlOptions;

	return this.httpService.makeGetRequest<T[]>( `${this.methods.listing.url}`, options);
    }


    public getById(id, requestOptions?: RequestOptions) : Observable<Rep<number, T>> {
	const options = requestOptions ? requestOptions : this.methods.view.urlOptions;
	
	return this.httpService.makeGetRequest<T>( `${this.methods.view.url}/${id}`, options);
    }

    public deleteById(id, requestOptions?: RequestOptions) : Observable<Rep<number, T>> {
	const options = requestOptions ? requestOptions : new RequestOptions();

	options.bodyParams = {}
	return this.httpService.makeDeleteRequest<T>( `${this.methods.delete.url}/${id}`, options);
    }

    public add(body: T, requestOptions?: RequestOptions) : Observable<Rep<number, T>> {
	const options = requestOptions ? requestOptions : this.methods.add.urlOptions;

	console.log("Add");
	options.body = body;
	console.log(options);

	return this.httpService.makePostRequest<T>( `${this.methods.add.url}`, options);
    }
    
    public updateById(id, body: T, requestOptions?: RequestOptions) : Observable<Rep<number, T>> {
	const options = requestOptions ? requestOptions : this.methods.update.urlOptions;

	console.log("Update");
	options.body = body;
	console.log(options);

	return this.httpService.makePutRequest<T>( `${this.methods.update.url}/${id}`, options);
    }



    public prepareRelations(obj): Observable<Rep< number, T>> {
	const  obs : Rep<number, T> = {IsSuccess:  1, Data:  undefined, Status : 0}; 
	return this.httpService.createObservableResponse<T>(obs);
    }

    public  handlePostRelations<T>(obj): Observable<Rep<number, T>> {
	const  obs : Rep<number, T> = {IsSuccess:  1, Data:  undefined, Status : 0}; 
	return this.httpService.createObservableResponse<T>(obs);
//	return  EMPTY;
    }

    public  handleRemoveRelations<T>(obj): Observable<Rep<number, T>> {
	const  obs : Rep<number, T> = {IsSuccess:  1, Data:  undefined, Status : 0}; 
	return this.httpService.createObservableResponse<T>(obs);
//	return  EMPTY;
    }

    
    //////////////////////////////////
    // Prepare Relations 
    //////////////////////////////////

    public prepareAddRelations(obj): Observable<Rep< number, T>> {

	return this.prepareRelations(obj);
	//return Observable.empty<T>();
    }

    public  handlePostAddRelations<T>(obj): Observable<Rep<number, T>> {
	return this.handlePostRelations(obj);
	//	return Observable.empty<T>();

	//return  EMPTY;
    }


    public prepareUpdateRelations(obj): Observable<Rep< number, T>> {
	return this.prepareRelations(obj);
    }

    public  handlePostUpdateRelations<T>(obj, removeRelations = true): Observable<Rep<number, T>> {
	if (removeRelations == true){
	    return  this.handleRemoveRelations<T>(obj).pipe(concatMap( res => {
		return this.handlePostRelations<T>(obj);
	    }));
	}
							
	return this.handlePostRelations(obj);
    }
}
