import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';        

// models
import { ProductType } from '../interfaces/product-type';
import { Product } from '../interfaces/product';
import { Note } from '../interfaces/note';

// services
import {NoteService} from '../services/note.service'

// rxjs
import { of, from,  map, Observable, exhaustMap, EMPTY, empty ,identity, forkJoin} from 'rxjs';
import { mergeMap, mergeAll, toArray, switchMap} from 'rxjs/operators';

// response   
import { Response as Rep } from '../interfaces/response';

import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"


@Injectable({
  providedIn: 'root'
})
export class ProductTypeService extends BaseCrudModel<ProductType>{

    postProducts = {url: `${environment.domains.api.url}/item-types`, urlOptions: this.options};
    deleteProducts = {url: `${environment.domains.api.url}/item-types`, urlOptions: this.options};
    
    constructor(protected override httpService: HttpService,
	       private noteService : NoteService) {
	super(httpService, "product-types");

	var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "objectDetail"},
		{"relation" : "note"},
		{"relation" : "products"},
		{"relation" : "familyType"},
	    ],
	});

	var addOptions =  RequestOptions.FromArray({
	    relationsToOmit : ['note', 'status', 'products', 'familyType']
	});

	this.methods.listing.urlOptions = options;
	this.methods.view.urlOptions = options;
	this.methods.add.urlOptions = addOptions;
	this.methods.update.urlOptions = addOptions;
    }

    getAllProductTypes(): Observable<Rep<number, ProductType[]>> {
	const options = new RequestOptions();

	return this.getAll()
    }

    ////////////////////////////////
    // PREPARE || HANDLE Relations
    ////////////////////////////////
    
    override prepareRelations(fromProductType): Observable<Rep<number, ProductType>> { //Observable<Profile> {
	// values to save
	const productType : ProductType = fromProductType;


	// Obs Functions
	const failObj : Rep<number, Note> = {IsSuccess:  0, Data:  null, Status : 0};

	const a$ = of();

	const note$ : Observable<Rep<number, Note>> = productType.note ? 
	    ( productType.note.id ? this.noteService.updateById(fromProductType.note.id, fromProductType.note) : this.noteService.add(fromProductType.note) )
	    :  this.httpService.createObservableResponse<Note>(failObj);

	
	return note$
	    .pipe(
		map(note => 
		    {
			console.log("created note");
			console.log(note);
			console.log("before setting Note");
			console.log(productType);
			(productType.noteId = note && note.IsSuccess ?
			    (note.Data && note.Data.id ? note.Data.id : productType.noteId) : productType.noteId );
			
			return   this.httpService.createResponseFromObj<ProductType>(productType)
		    }),
		map(rel =>
		    {
			console.log("final result");
			console.log(rel);
			// return profile
			return    this.httpService.createResponseFromObj<ProductType>(productType) ;
		    }),
		
	    );
    }

    //////////////////////////
    // HANDLE || PREPARE
    //////////////////////////

    override handleRemoveRelations(productType): Observable<any> {
	console.log("removePostRelations for productType : \n {0}".format(JSON.stringify(productType)));

	return forkJoin
	(
	    // Remove elements if empty
	    productType && productType.id && (productType.products && productType.products?.length >= 0) ? this.removeProducts(productType.id) :
		this.httpService.createObservableResponse<Product[]>({IsSuccess:  0, Data:  undefined, Status : 0})

	)
    }
    
    override handlePostRelations(productType): Observable<any> {
	console.log(productType);
	console.log("handlePostRelations");
	    return forkJoin
	(
	    
	    // add new elements 
	    productType && productType.id && productType.products && productType.products?.length > 0 ? this.addProducts(productType.id, productType.products) :
		this.httpService.createObservableResponse<Product[]>({IsSuccess:  0, Data:  undefined, Status : 0})
	)
    }

    //////////////////////////
    // Add Other objects
    //////////////////////////

    public removeProducts(productTypeId):  Observable<Rep<number, any>> {
	console.log("remove product for productTypeId:{productTypeId}".formatUnicorn({productTypeId : productTypeId}));
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		//{"productTypeId" : productTypeId},
		{"productTypeId" : {"regexp" : "/^{productTypeId}$/".formatUnicorn({productTypeId: productTypeId})}},
	    ],
	});
	options.body = {"productTypeId" : ""}
	return this.httpService.makePatchRequest<any>( `${this.deleteProducts.url}`, options);
//	return  this.httpService.createObservableResponse<ProductProductType[]>({IsSuccess:  0, Data:  undefined, Status : 0});
    }


    //////////////////////////
    // Add Other objects
    //////////////////////////

    public addProducts(productTypeId, products):  Observable<Rep<number, any>> {
	
 	
	//const arrayProducts = products?.map((r: any): Product => ({productTypeId : productTypeId, productId: r.id}));

	const arrayProducts = products?.map((r: Product): any => (r.id));

	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		{"id" : {"inq" : arrayProducts}},
	    ],
	});
	console.log("Array products");
	console.log(arrayProducts);
	options.body =  {"productTypeId" : productTypeId} ;//arrayProducts;	

	return arrayProducts && arrayProducts.length > 0 ?
	    this.httpService.makePatchRequest<Product[]>( `${this.postProducts.url}`.format(), options) :
	    this.httpService.createObservableResponse<Product[]>({IsSuccess:  0, Data:  undefined, Status : 0});
	//Observable.empty<number, any>() ;
    }
}
