<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">VOIR LIVRAISON</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Livraison</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 30px;">

        <div class="d-flex justify-content-end">
            <!--<button class="btn btn-primary btn-sm" (click)="onEditDelivery()">Éditer une livraison</button>-->
        </div>

        <label>Voir un profil</label>
        <div class="lateral-space">
   
            <div class="row mt-3 w-100 left-background">
                <div class="col-6 left">
                    Numéro de la livraison
                </div>
                <div class="col-6 right">
                    #LIV_{{_delivery.deliveryNumber}} 
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Date previsionnel
                </div>
                <div class="col-6 right">
                    {{ _delivery.previsionalDate |  date:'dd/MM/yyyy' }}  
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    Date de livraison
                </div>
                <div class="col-6 right">
                    {{ _delivery.deliveryDate |  date:'dd/MM/yyyy' }}  
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Status de la livraison
                </div>
                <div class="col-6 right">
                     {{_delivery.status?.label}} 
                </div>
            </div>
            <div  *ngIf="_delivery?.deliveryMan" class="row w-100 left-background">
                <div class="col-6 left">
                    Livreur
                </div>
                <div class="col-6 right">
                    {{_delivery?.deliveryMan?.firstname}} {{_delivery?.deliveryMan?.lastname}}  
                </div>
            </div>
            <div  *ngIf="_delivery?.shipping" class="row w-100">
                <div class="col-6 left">
                    Expédition
                </div>
                <div class="col-6 right">
		    {{_delivery?.shipping?.shippingNumber}}
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    Note
                </div>
                <div class="col-6 right">
		    {{_delivery?.note?.content}}
                </div>
            </div>
        </div>







        <div class="row mt-5">
            <div class="col-md-6 col-sm-12">
                <label>Adresse de livraison</label>
                <div class="lateral-space">
           
                    <div class="row mt-3 w-100 left-background">
                        <div class="col-6 left">
                            Rue
                        </div>
                        <div class="col-6 right">
			    {{_delivery?.address?.street}}  
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Ville
                        </div>
                        <div class="col-6 right">
			    {{_delivery?.address?.city}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Code postal
                        </div>
                        <div class="col-6 right">
                            {{_delivery?.address?.zip}}     
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Pays
                        </div>
                        <div class="col-6 right">
                            {{_delivery?.address?.country}}
                        </div>
                    </div>
                   <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Details additionnels
                        </div>
                        <div class="col-6 right">
                            {{_delivery?.address?.additionalDetail}}  
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <label>Informations complémentaires</label>
                <div class="lateral-space">
		    
                    <!--<div class="row mt-3 w-100 left-background">
                        <div class="col-6 left">
                            Produit
                        </div>
                        <div class="col-6 right">
                            <a href="#">Voir produit</a>
                        </div>
                    </div>-->
                    <!--<div class="row w-100">
                        <div class="col-6 left">
                            Commande
                        </div>
                        <div class="col-6 right">
                            <a href="#">Voir commande</a>
                        </div>
                    </div>-->
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Créé le {{_delivery?.objectDetail?.creationDate |  date:'dd/MM/yyyy' }}      
                        </div>
                        <div class="col-6 right">
                            Modifié {{_delivery?.objectDetail?.creationDate |  date:'dd/MM/yyyy' }}   
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Créé par
                        </div>
                        <div class="col-6 right">
                             {{_delivery?.objectDetail?.creator?.username}}   
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Modifié par
                        </div>
                        <div class="col-6 right">
                             {{_delivery?.objectDetail?.updater?.username}}   
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Status
                        </div>
                        <div class="col-6 right d-flex justify-content-center">
                            <span class="badge badge-pill badge-primary">{{_delivery?.status?.label}}  </span>  
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
