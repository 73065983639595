import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';        

// models
import { PaymentType } from '../interfaces/payment-type';
import { Payment } from '../interfaces/payment';
import { Note } from '../interfaces/note';

// services
import {NoteService} from '../services/note.service'

// rxjs
import { of, from,  map, Observable, exhaustMap, EMPTY, empty ,identity, forkJoin} from 'rxjs';
import { mergeMap, mergeAll, toArray, switchMap} from 'rxjs/operators';

// response   
import { Response as Rep } from '../interfaces/response';

import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends BaseCrudModel<Payment> {

    constructor(protected override httpService: HttpService) {
	super(httpService, "payments");
    }

    getAllPayments(): Observable<Rep<number, Payment[]>> {
	const options = new RequestOptions();

	return this.getAll()
    }
}
