import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { of, map, concatMap, switchMap, Observable, exhaustMap, EMPTY, empty ,identity} from 'rxjs';
import { delay, take } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { Profil as Profile } from '../interfaces/profil';
import { Address as Address } from '../interfaces/address';
import { UserModel } from '../interfaces/user';
import { Role } from '../interfaces/role';

import { Response as Rep } from '../interfaces/response';

import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"

import {UserService} from '../services/user.service'
import {AddressService} from '../services/address.service'

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseCrudModel<Profile>{

    private userService: UserService;

    constructor(protected override httpService: HttpService,
		private injector: Injector,
//		private userService: UserService,
		private addressService: AddressService
	       ) {
	super(httpService, "profiles");
	
	var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation": "user", "scope" : {"include" : [{"relation" : "roles"}, {"relation" : "permissions"}]}},
		{"relation" : "status"},
		//{"relation" : "customer"},
//		{"relation" : "school"},
		{"relation" : "address"},
		{"relation" : "objectDetail"},
	    ],
	});

	var addOptions =  RequestOptions.FromArray({
	    relationsToOmit : ['address', 'user'],
	});
	
	this.methods.listing.urlOptions = options
	this.methods.view.urlOptions = options;
	this.methods.add.urlOptions = addOptions;

	
    }

    getAllProfiles(): Observable<Rep<number, Profile[]>> {
	const options =  RequestOptions.FromArray({
	    relations :  [
		{"relation": "user"},
		//{"relation" : "status"}
	    ],
	});

	
	return this.getAll();
//	return this.httpService.makeGetRequest<Profile[]>( `${environment.domains.profil.GetAllProfiles}`, options);
    }


    ////////////////////////////////
    // PREPARE || HANDLE Relations
    ////////////////////////////////
    
    override prepareRelations(fromProfile): Observable<Rep<number, Profile>> { //Observable<Profile> {
	// services
	const userService = this.injector.get(UserService);
	// values to save
	const userPermissions = fromProfile?.user?.permissions;
	const userRoles = fromProfile?.user?.roles;

	const profile : Profile = fromProfile;

	console.log(userPermissions);
	console.log(userRoles);


	// Obs Functions
	const failObj : Rep<number, Address> = {IsSuccess:  0, Data:  null, Status : 0};
	const failUsr : Rep<number, UserModel> = {IsSuccess:  0, Data:  undefined, Status : 0};

	const a$ = of();

	const add$ : Observable<Rep<number, Address>> = profile.address ? 
	    ( profile.address.id ? this.addressService.updateById(fromProfile.address.id, fromProfile.address) : this.addressService.add(fromProfile.address) )
	    :  this.httpService.createObservableResponse<Address>(failObj);

	const usr$ : Observable<Rep<number, UserModel>> = profile.user ?
	    ( profile.user.id ?  userService.updateById(fromProfile.user.id, fromProfile.user) : userService.add(fromProfile.user)) :
	    this.httpService.createObservableResponse<UserModel>(failUsr);


	
	return add$
	    .pipe(
		exhaustMap(add => 
		    {
			//(profile.addressId = add && add.IsSuccess && add.Data ? add.Data.id : null );
			console.log("before setting Address");
			console.log(profile);
			(profile.addressId = add && add.IsSuccess ?
			    (add.Data && add.Data.id ? add.Data.id : profile.addressId) : profile.addressId );
			
			return   usr$
		    }),
		
		exhaustMap(usr =>
		    {
			console.log("before setting User");
			
			//(profile.userId = usr && usr.IsSuccess && usr.Data ? usr.Data.id : null);
			(profile.userId = usr && usr.IsSuccess ?
			    ( usr.Data && usr.Data.id  ? usr.Data.id : profile.userId ) : profile.userId);
			if (profile.user) 
			    (profile.user.id = usr && usr.IsSuccess && usr.Data && profile.user ? usr.Data.id : profile.userId);

			if (userPermissions && userPermissions.length > 0) 
			    (profile.user.permissions = usr && usr.IsSuccess &&  userPermissions ? userPermissions : null);

			if (userRoles && userRoles.length > 0) 
			    (profile.user.roles = usr && usr.IsSuccess && userRoles ? userRoles :  null);

			console.log("before handle user relations");
			console.log(profile.user);
			//return concatMap([userService.handleRemoveRelations(profile.user),
			//		  userService.handlePostRelations(profile.user)]);

			return  userService.handleRemoveRelations(profile.user).pipe(concatMap( res => {
			    return userService.handlePostRelations(profile.user).pipe(delay(50));
			}))
			//			return userService.handleRemoveRelations(profile.user),
			//return userService.handlePostRelations(profile.user);
		    
		    }),

		map(rel =>
		    {
			console.log("final result");
			console.log(rel);
			// return profile
			return    this.httpService.createResponseFromObj<Profile>(profile) ;
		    }),
		
	    );
    }




    

}



/*
    makeBeCalls1(fromProfile): Observable<Profile> {
	//	const events: Result = { a: null, b: null, c: null, d: null };
	const profile :Profile = fromProfile ;
	
	const a$ = of('a');
	const b$ = of('b');
	const c$ = of('c');
	const d$ = of('d');

	
	return a$
	    .pipe(
		exhaustMap(a => (profile.id = a) && b$),
		exhaustMap(b => (profile.userId = b) && c$),
		exhaustMap(c => (profile.firstname = c) && d$),
		map(d => (profile.addressId = d) && profile) 
	    );
    }

*/



/*	return Observable.create(observer => {
            observer.next(obj);
            observer.complete();
	    });*/



/*


    prepareRelations(fromProfile): Observable<Profile> {
	//	const events: Result = { a: null, b: null, c: null, d: null };
	const profile : Profile = fromProfile;

	
	const userService = this.injector.get(UserService);


	const failObj : Rep<number, Address> = {IsSuccess:  0, Data:  null, Status : 0};
	const failUsr : Rep<number, UserModel> = {IsSuccess:  0, Data:  undefined, Status : 0};

	var address : Address ;
	const a$ = of();
//	const add$  : Observable<any>  = new Observable<any>(x => x.next(null));
	const usr$  : Observable<any>  = new Observable<any>(x => x.next(null));

	const add$ : Observable<Rep<number, Address>> = profile.address ? this.addressService.add(fromProfile.address) :  this.createObservable<Address>(failObj);
	    //new Observable<Rep<number,Address>>(x => x.next(null));
	
	//profile.address ? this.addressService.add(fromProfile.address) : new Observable<Rep<number,Address>>(x => x.next(null));
	//this.createObservable<Address>({IsSuccess:  0, Data:  address, Status : 0});
/*	    profile.address ?
	    this.addressService.add(fromProfile.address) :
	    //of<Rep<number, Address>>(failObj);
	    this.createObservable<Address>(failObj);
*/	
	    //of<Rep<number, Address>>(failObj); //({IsSuccess:  0, Data:  undefined, Status : 0});

//	console.log("gonna set user service");
	
//	const usr$ : Observable<Rep<number, UserModel>> = userService.add(fromProfile.user);
	    
/*	    profile.user ? userService.add(fromProfile.user):
	    //EMPTY;
	    this.createObservable<UserModel>(failUsr);
//	    of<Rep<number, UserModel>>(failObj);//({IsSuccess:  0, Data:  undefined, Status : 0});
*/
	
/*	return add$
	    .pipe(
		//		exhaustMap(a => 
		//		    {(console.log(a)); return  add$ }),
		exhaustMap(add => 
		    { (profile.addressId = add && add.IsSuccess && add.Data ? add.Data.id : null ); console.log("step1");return   usr$ ;}),
		//exhaustMap(usr => (profile.userId = usr) && c$),
		//exhaustMap(c => (events.c = c) && d$),
		map(usr =>
		    { (profile.userId = usr && usr.IsSuccess ? usr.Data.id : null); console.log("step2"); return  profile ;})
	    );
    }


*/



/*

getDeviceItemData() {
    this.devices$ = this.device_ids.map((id) =>
      this.getDeviceId(id).pipe(
        switchMap((device) => {
          return forkJoin([
            this.getPlaceById(device['place_id']),
            this.getTestStandardById(device['test_standard_id'])
          ]).pipe(map((y) => ({ device, place: y[0], test_standard: y[1] })));
        })
      )
    );
  }

*/



/*    withRelation(profile) :Observable<any>  {
	var results = [];
	
	if (profile.address)
	    results.push()
	const combinedResult$ = combineLatest([result1$, result2$]).pipe(
	    map(([result1Data, result2Data]) => { 

		// combine result1Data and result2Data here

		return combinedResult
	    })

    }
*/
