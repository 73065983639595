import { Component, Input, OnInit, ViewEncapsulation, ViewChild,
	 SimpleChanges,  ChangeDetectionStrategy, ChangeDetectorRef, OnChanges } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';


import { Note } from 'src/app/interfaces/note';
import { Address } from 'src/app/interfaces/address';
import { Company } from 'src/app/interfaces/company';
import { Customer } from 'src/app/interfaces/customer';
import { Status } from 'src/app/interfaces/status';
import {Profil as Profile} from 'src/app/interfaces/profil';
import { UserModel } from 'src/app/interfaces/user';


import { CompanyService } from 'src/app/services/company.service';
import { CustomerService } from 'src/app/services/customer.service';
import { StatusService } from 'src/app/services/status.service';

import { Country, MatSelectCountryComponent } from '@angular-material-extensions/select-country';

import { Response } from '../../interfaces/response';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import {timeout, takeWhile} from 'rxjs/operators';

import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-new-customer',
    templateUrl: './new-customer.component.html',
    styleUrls: ['./new-customer.component.scss'],
    encapsulation: ViewEncapsulation.None,
//    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewCustomerComponent implements OnInit, OnChanges {
    selectedCountry: Country = undefined;

    @Input() TitleVisible: boolean = true;
    @Input() DisplayConfirmationButton: boolean = true;


    
    form: FormGroup;

    ////////////////////////
    // Attributes 
    ////////////////////////  

    companiesListCtrl = new FormControl('');
    companiesList : Company[];//string[];
    companiesData : Company[];//string[];
    selectedCompanies : Company[];
    selectedCompany : Company;

    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatus : Status;
    selectedStatuses : Status[];

    //
    // Country
    //
    
    @ViewChild('countryChild') selectCountryComponent: MatSelectCountryComponent;
    
    _defaultCountry: Country ;
    get defaultCountry() : Country {
	return this._defaultCountry;
    }
    set defaultCountry(country: Country) {
	this._defaultCountry = country;
	this.selectedCountry  = this._defaultCountry;
    } 
    countriesChildComponentsLoaded = false;
    
    ////////////////////////
    // Constructor 
    ////////////////////////
    
    constructor(private router: Router,
		private _snackBar: MatSnackBar,
		public fb: FormBuilder,
		private changeDetectorRef: ChangeDetectorRef,
		
		private statusService : StatusService,
		private customerService: CustomerService,
		private companyService : CompanyService) { 
	
    }
    

    ////////////////////////
    // Init
    ///////////////////////


    ngOnInit(): void {


	this.refresh();
	this.initForm();
	this.initCountriesDatabaseValuesChangesCheck();	


    }

    ngAfterViewInit(){
	console.log("**after view init");

	setTimeout(_=> {
	    console.log("Enter check");
	    this.initCountriesSearchDropdown("Guyane");
	});
    }

    ngOnChanges(changes: SimpleChanges): void {
	console.log("Changes")
	console.log(changes);
    }
    

    initCountriesDatabaseValuesChangesCheck(){
	this.form.valueChanges.pipe(
	    timeout(12000),
	    takeWhile(value => !this.countriesChildComponentsLoaded)
	).subscribe( {
	    next: (formValue) => {
	    
		if (this.selectCountryComponent?.countries?.length > 0) {
		    this.countriesChildComponentsLoaded = true
		    //console.log(JSON.stringify(this.selectCountryComponent?.countries))
		    // Change country here
		    //this.initCountriesSearchDropdown("Guyane");

		}
	    },
	    error: (e) => console.log(`There is an Error ${e}`)
	});
    }

    

    ////////////////////////
    // Datasrouces
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    firstname: new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(2)]),
	    lastname:  new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(2)]),
	    phone: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$"), Validators.minLength(10)]),
	    email: new FormControl(null, [Validators.required, Validators.email]),
	    password: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
	    passwordcheck: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),

	    street: new FormControl(null, [Validators.required, Validators.maxLength(30), Validators.minLength(2)]),
	    //	    street: new FormControl(null, [Validators.required, Validators.maxLength(35), Validators.pattern("^[0-9]*$"), Validators.minLength(1)]),
	    city: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    zip: new FormControl(null, [Validators.required, Validators.maxLength(5),  Validators.pattern("^[0-9]*$"), Validators.minLength(5)]),
	    

	    billingStreet: new FormControl(null, [Validators.required, Validators.maxLength(30), Validators.minLength(2)]),
	    billingCity: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    billingZip: new FormControl(null, [Validators.required, Validators.maxLength(5),  Validators.pattern("^[0-9]*$"), Validators.minLength(5)]),

	    //	    label: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    //	    description: new FormControl(null, [Validators.required, Validators.maxLength(150), Validators.minLength(2)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
	    country : new FormControl(null, [Validators.required])
	});


    }

    ////////////////////////
    // Datasrouces
    ///////////////////////

    refresh(): void {

	this.statusService.getAllStatuses().subscribe({
	    next: (response: Response<number, Status[]>) => {
		
		this.statusesData = response.Data;
		this.initStatusesSearchDropdown();
		this.changeDetectorRef.markForCheck();
	    },error: (e) => { }, complete: () => console.info('load statuses complete')
	});
	
	this.companyService.getAllCompanies().subscribe({
	    next: (response: Response<number, Company[]>) => {
		this.companiesData = response.Data;
		this.initCompaniesSearchDropdown();
		this.changeDetectorRef.markForCheck();
	    },error: (e) => { }, complete: () => console.info('load companies complete')
	});



    }

    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns

    initStatusesSearchDropdown(){
	this.statusesList = [];
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
	    {

		if (!this.selectedStatus && this.statusesData[i].label == "Actif")
		{
		    this.selectedStatuses = [];
		    this.selectedStatus = this.statusesData[i];
		    
		    this.selectedStatuses.push(this.statusesData[i]);
		}
		this.statusesList.push( this.statusesData[i] );
	    }
        }
    }

    
    initCompaniesSearchDropdown(){
	this.companiesList = [];
        for ( let i = 0 ; i < this.companiesData.length; i ++ ) {
	    this.companiesList.push( this.companiesData[i] );
        }
    }

    
    initCountriesSearchDropdown(defaultCountryName){
	setTimeout(_=> {  
	    let defaultValue = this.selectCountryComponent?.countries?.find(country => country.name === defaultCountryName);

	    this.defaultCountry = defaultValue
	    
	    console.log(this.defaultCountry);
	    if (!this.defaultCountry) {
		//MatSelectCountryComponent
		this.defaultCountry = this.defaultCountry ? this.defaultCountry :  
		    {
			name: 'Guyane',
			alpha2Code: 'GY',
			alpha3Code: 'GUY',
			numericCode: '328',
			callingCode: '+592'
		    };
	    }
	});
    }

    
    ////////////////////////
    // On Search
    
    onKeyCompanies(value) { 
        this.companiesList= []; 
        this.selectCompaniesSearch(value);       
    }

    onKeyStatuses(value) { 
        this.statusesList= []; 
        this.selectStatusesSearch(value);       
    }

    ////////////////////////
    // search elements


    selectCompaniesSearch(value:string){
        let filter = value.toLowerCase();
	for ( let i = 0 ; i < this.companiesData.length; i ++ ) {
	    let option = this.companiesData[i];
	    if (  option.name.toLowerCase().indexOf(filter) >= 0
		|| option.name?.toLowerCase().indexOf(filter) >= 0  ) {
                this.companiesList?.push( option );
	    }
        }
	
    }

    selectStatusesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
	    let option = this.statusesData[i];
	    if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.statusesList?.push( option );
	    }
        }
    }


    onCountrySelected($event: Country) {
	this.selectedCountry = $event;
    }
    
    ////////////////////////////
    // elements selections
    ///////////////////////////

    onStatusesChanged($event: any) {
	console.log($event);
    }
    
    OnCompaniesChanged($event: any) {
	console.log($event);
    }

    ////////////////////////////
    // External actions
    ///////////////////////////
    
    
    ////////////////////////
    ///////////////////////
    
    ////////////////////////
    ///////////////////////
    
    compare(o1: any, o2: any): boolean {
	// if possible compare by object's name, and not by reference.
	return o1 && o2 ? o1.name === o2.name : o2 === o2;
    }
    
    ////////////////////////
    // Actions
    ///////////////////////


    submit() {
	if (!this.form.valid) {
	    return;
	}
	if (this.form.value.password !== this.form.value.passwordcheck) {
	    this._snackBar.open('Les 2 mots de passe ne correspondent pas.', '', {
		duration: 3000,
		horizontalPosition: 'center',
		verticalPosition: 'bottom',
	    });
	} /*else if (this.role === undefined || this.permission === undefined) {
	    this._snackBar.open('Veuillez choisir un role et une permission', '', {
	    duration: 3000,
	    horizontalPosition: 'center',
	    verticalPosition: 'bottom',
	    });
	    } */else if (this.selectedCountry === undefined) {
		this._snackBar.open('Veuillez choisir un pays', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		});
	    } else {

		
		let customer : Customer = {
		};
		
		let profile: Profile = {
		    firstname: this.form.value.firstname,
		    lastname: this.form.value.lastname,
		    email: this.form.value.email,
		    phoneNumber: this.form.value.phone
		};

		let user: UserModel = {
		    username : this.form.value.email, //username,
		    password :this.form.value.password 
		};

		const note : Note = {
		    content : this.form.value.note
		}


		const address: Address = {
		    country:  this.selectedCountry.name,
		    street: this.form.value.street?.toString(),
		    city: this.form.value.city,
		    zip: this.form.value.zip?.toString(),
		    //	  zipCode: this.form.value.postal?.toString(),
		    additionalDetail: ''	  
		};

		const billingAddress: Address = {
		    country:  this.selectedCountry.name,
		    street: this.form.value.billingStreet?.toString(),
		    city: this.form.value.billingCity,
		    zip: this.form.value.billingZip?.toString(),
		    //	  zipCode: this.form.value.postal?.toString(),
		    additionalDetail: ''	  
		};

		profile.address = address;
		profile.user = user;

		customer.shippingAddress = address;
		customer.billingAddress = billingAddress;
		customer.profile = profile;
		customer.note = note;

		if (this.selectedCompany)
		    customer.companyId = this.selectedCompany.id;

		
		if (this.selectedStatus){
		    customer.statusId = this.selectedStatus.id;
		    profile.statusId = this.selectedStatus.id;
		}
		
		console.log(customer);
		console.log("gonna save");

		this.customerService.prepareAddRelations(customer).pipe(
		    switchMap(preparedCustomer => { 
			console.log("entered switch map -- add new customer ");
			console.log(preparedCustomer);
			return this.customerService.add(customer);}),
		    switchMap(customerAddedObservable => {
			let customerAdded = customerAddedObservable.Data;
	 		//customerAdded.companies = this.selectedCompanies;
			console.log("entered switch map -- handle post relations ");
			console.log(customerAdded);
			return this.customerService.handlePostAddRelations(customerAdded); 
		    })
		    
		    
		).subscribe({
		    next: (res) => {  this.checkSubmitSucess(res);},
		    error: (error) => { this.submitError(error) },
		    complete: () => {
			console.info('save companies pre relations complete')
			this._snackBar.open('Sauvegarde effectuee', '', {
			    duration: 3000,
			    horizontalPosition: 'center',
			    verticalPosition: 'bottom',
			    panelClass: ['mat-toolbar', 'mat-primary']}
					   );
			setTimeout(() => 
			    {
				this.submitSuccess();  
			    }, 5000);
			
		    }
		    
		    
		});
	    }
    }

    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListCustomerPath]);
	
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
	    duration: 3000,
	    horizontalPosition: 'center',
	    verticalPosition: 'bottom',
	});
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }
    



}
