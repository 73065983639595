import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';



// models
import { ShippingType } from '../interfaces/shipping-type';
import { Shipping } from '../interfaces/shipping';
import { Note } from '../interfaces/note';

// services
import {NoteService} from '../services/note.service'

// rxjs
import { of, from,  map, Observable, exhaustMap, EMPTY, empty ,identity, forkJoin} from 'rxjs';
import { mergeMap, mergeAll, toArray, switchMap} from 'rxjs/operators';

// response   
import { Response as Rep } from '../interfaces/response';

import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"

@Injectable({
  providedIn: 'root'
})
export class ShippingService extends BaseCrudModel<Shipping>{

    getShippingWithOrders = {url: `${environment.domains.api.url}/shippings/orders`, urlOptions: this.options};

    constructor(protected override httpService: HttpService,
	       	private noteService : NoteService) {
	super(httpService, "shippings");

	var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "shippingStatus"},
		{"relation" : "objectDetail"},
		{"relation" : "note"},
		{"relation" : "delivery", "scope" : {"include" : [{"relation" : "address"}]}},
		{"relation" : "relayPoint", "scope" : {"include" : [{"relation" : "address"}]}}
	    ],
	});

	var addOptions =  RequestOptions.FromArray({
	    relationsToOmit : ['note', 'status']
	});

	this.methods.listing.urlOptions = options;
	this.methods.view.urlOptions = options;
	this.methods.add.urlOptions = addOptions;
	this.methods.update.urlOptions = addOptions;

    }

    getAllShippings(): Observable<Rep<number, Shipping[]>> {
	const options = new RequestOptions();

	return this.getAll()
    }

    getAllShippingsWithOrders(): Observable<Rep<number, Shipping[]>> {

	var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "objectDetail"},
		{"relation" : "note"},
		{"relation" : "delivery", "scope" : {"include" : [{"relation" : "address"}]}},
		{"relation" : "relayPoint", "scope" : {"include" : [{"relation" : "address"}]}}
	    ],
	});

	return this.httpService.makeGetRequest<Shipping[]>( `${this.getShippingWithOrders.url}`, options);

    }


    ////////////////////////////////
    // PREPARE || HANDLE Relations
    ////////////////////////////////
    
    override prepareRelations(fromShipping): Observable<Rep<number, Shipping>> { //Observable<Profile> {
	// values to save
	const shipping : Shipping = fromShipping;


	// Obs Functions
	const failObj : Rep<number, Note> = {IsSuccess:  0, Data:  null, Status : 0};

	const a$ = of();

	const note$ : Observable<Rep<number, Note>> = shipping.note ? 
	    ( shipping.note.id ? this.noteService.updateById(fromShipping.note.id, fromShipping.note) : this.noteService.add(fromShipping.note) )
	    :  this.httpService.createObservableResponse<Note>(failObj);

	
	return note$
	    .pipe(
		map(note => 
		    {
			console.log("created note");
			console.log(note);
			console.log("before setting Note");
			console.log(shipping);
			(shipping.noteId = note && note.IsSuccess ?
			    (note.Data && note.Data.id ? note.Data.id : shipping.noteId) : shipping.noteId );
			
			return   this.httpService.createResponseFromObj<Shipping>(shipping)
		    }),
		map(rel =>
		    {
			console.log("final result");
			console.log(rel);
			// return profile
			return    this.httpService.createResponseFromObj<Shipping>(shipping) ;
		    }),
		
	    );
    }
    
}
