<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">ÉDITER GROUPE DE SOCIÉTÉS</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Clients</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">


        <h3 style="color: black; font-size:medium">Groupe de sociétés</h3>
        <h6 style="color: black; font-size: small">Éditer un roupe de sociétés</h6>

	<mat-stepper orientation="horizontal">

            <mat-step>
		

		<ng-template matStepLabel><mat-icon>local_shipping</mat-icon> Information du groupe </ng-template>
		<ng-template matStepContent>
		    
		    <h6 style="color: black; font-size: small">Remplir les informations ci-dessous</h6>

		    <form id="companyGroupForm" class="example-form" [formGroup]="form" (ngSubmit)="submit()" *ngIf="_companyGroup"> 
			<mat-label>Nom</mat-label>
			<input type="text" matInput formControlName="name"  [(ngModel)]="_companyGroup.name"  class="form-control form-control-sm mb-2" required>
			<app-show-errors [ctrl]="form.get('name')"></app-show-errors>

			<mat-label>Label</mat-label>
			<input type="text" matInput formControlName="label"  [(ngModel)]="_companyGroup.label"  class="form-control form-control-sm mb-2" required>
			<app-show-errors [ctrl]="form.get('label')"></app-show-errors>

			<mat-form-field appearance="outline" class="example-full-width">
			    <mat-label>Description</mat-label>
            		    <textarea style="height: 50px;" matInput placeholder="Description" formControlName="description"
				      [(ngModel)]="_companyGroup.description" 
				      required></textarea>
			    <app-show-errors [ctrl]="form.get('description')"></app-show-errors>
			</mat-form-field>

			<mat-label>Produits</mat-label>
			<div class="input-group">
			    
			    <mat-select placeholder="Choisir les produits"
					class="form-control form-control-sm mb-2"  multiple required
					[(ngModel)]="selectedProducts"
					[formControl]="productsListCtrl"
					[compareWith]="compareFn"
					(selectionChange)="onProductsChanged($event)"  >
				<input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyProducts($event.target.value)">  
				<mat-select-trigger>
				    {{productsListCtrl?.value?.[0]?.name  || ''}}
				    <span *ngIf="(selectedProducts?.length || 0) > 1" class="example-additional-selection">
					(+{{(selectedProducts?.length || 0) - 1}} {{selectedProducts?.length === 2 ? 'autre' :
					'autres'}})
				    </span>
				</mat-select-trigger>
				<mat-option *ngFor="let product of productsList" [value]="product">{{product.name}}</mat-option>
			    </mat-select>
			    <div class="input-group-append">
				<button type="button"class="btn btn-primary  form-control-sm" >
				    <mat-icon>add</mat-icon>
				</button>
			    </div>
			</div>

			<mat-label>Sociétés</mat-label>
			<div class="input-group">
			    <mat-select placeholder="Choisir les sociétés"
					class="  form-control form-control-sm mb-2"
					[formControl]="companiesListCtrl" 
					[(ngModel)]="selectedCompanies"
					[compareWith]="compareFn"
					(selectionChange)="onCompaniesChanged($event)"
					multiple required >
				<input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyCompanies($event.target.value)"> 
				<mat-select-trigger>
				    {{companiesListCtrl.value?.[0]?.name  || ''}} 
				    <span *ngIf="(companiesList?.length || 0) > 1" class="example-additional-selection">
					(+{{(selectedCompanies?.length || 0) - 1}} {{selectedCompanies?.length === 2 ? 'autre' :
					'autres'}})
				    </span>
				</mat-select-trigger>
				<mat-option *ngFor="let company of companiesList" [value]="company">{{company.name}}</mat-option>
			    </mat-select>
			    <div class="input-group-append">
				<button type="button"class="btn btn-primary  form-control-sm">
				    <mat-icon>add</mat-icon>
				</button>
			    </div>
			</div>

			<mat-form-field appearance="outline" class="example-full-width">
			    <mat-label>Note</mat-label>
			    <textarea style="height: 50px;" matInput placeholder="note" formControlName="note"
				      [ngModel]="_companyGroup && _companyGroup.note && _companyGroup.note.content"
				      (ngModelChange)="_companyGroup && _companyGroup.note && _companyGroup.note.content  = $event"
				      required></textarea>
			    <app-show-errors [ctrl]="form.get('note')"></app-show-errors>
			</mat-form-field>

			<mat-label>Status</mat-label>
			<div class="input-group">
			    <mat-select placeholder="Choisir un statut pour ce type de produit" class="  form-control form-control-sm mb-2"   required
					[(ngModel)]="selectedStatus" [formControl]="statusesListCtrl"  [compareWith]="compareFn" (selectionChange)="onStatusesChanged($event)"  >
				<input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyStatuses($event.target.value)">  
				<mat-select-trigger>
				    {{selectedStatus?.label  || ''}}
				    <span *ngIf="(selectedStatuses?.length || 0) > 1" class="example-additional-selection">
					(+{{(selectedStatuses?.length || 0) - 1}} {{selectedStatuses?.length === 2 ? 'autre' :
					'autres'}})
				    </span>
				</mat-select-trigger>
				<mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
			    </mat-select>
			</div>




		    </form>

		</ng-template>
	    </mat-step>

	    <mat-step >
                <ng-template matStepLabel><mat-icon>fact_check</mat-icon> Produits du groupe</ng-template>
		
		<!--
		     <div   class="row mt-5 top-right" layout="row" layout-align=" end">
		     <div  layout="column" layout-align="center end" >
		     <div><button mat-raised-button matStepperPrevious color="primary" class="mr-1" type="button">Précédent</button></div>
		     <div><button mat-raised-button matStepperNext color="primary" type="button">Suivant</button></div>
		     </div>
		     </div>
		-->

		<h6 style="color: black; font-size: small">Remplir les informations ci-dessous</h6>
		
                <div class="table-responsive">
		    <div fxLayout="row" fxLayoutAlign="end center">
			<mat-form-field fxFlex="60%">
			    <input matInput type="text" (keyup)="doFilter($event.target.value)"  name="filter" placeholder="Filtrer">
			</mat-form-field>
		    </div>

                    <table mat-table [dataSource]="datasourceSelection" matSort>
			
                        <ng-container matColumnDef="#">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">
                                <mat-checkbox></mat-checkbox>
                            </th>
                            <td style="width: 4%;" mat-cell *matCellDef="let element">
                                <mat-checkbox [(ngModel)]="element.checked"></mat-checkbox>
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="image">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Image</th>
                            <td style="width: 15%;" mat-cell *matCellDef="let element">
                                <img class="bg-image" src="{{element?.img}}" *ngIf="element && element.img" />
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="product.name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Nom</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span>{{element?.product?.name}}</span>
                            </td>
                        </ng-container>

			<ng-container matColumnDef="product.description">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Description</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span>{{element?.product?.description}}</span>
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="product.price">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Prix original</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span class="table-regular">{{element?.product?.price}} </span>
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="price">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Prix</th>
                            <td style="width: 120px;" mat-cell *matCellDef="let element">
                                <!-- <input matInput maxlength="5" type="number" placeholder="0" [(ngModel)]="element.qty"  class="form-control form-control-sm" required> -->
                                <mat-form-field appearance="outline" floatLabel="always">
                                    <input matInput type="number" class="example-right-align" [(ngModel)]="element.price"  placeholder="0">
                                </mat-form-field>
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="Total">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Total</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span class="table-regular">{{element.price * element.quantity}} $</span>
                            </td>
                        </ng-container>
			
			
			
                        <ng-container matColumnDef="Action">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-icon matTooltip="consulter" (click)="onConsulterProduct(element.productId)" class="icon" aria-hidden="false"
					  aria-label="Example home icon" fontIcon="preview"></mat-icon>
                            </td>
                        </ng-container>
			
			
			
                        <tr mat-header-row *matHeaderRowDef="columnsSelection"></tr>
                        <tr mat-row *matRowDef="let row; columns: columnsSelection;" class="example-element-row"></tr>
                    </table>
		    
		    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
			<mat-progress-spinner 
			    color="primary" 
			    mode="indeterminate">
			</mat-progress-spinner>
		    </mat-card>
		    
                    <mat-paginator [pageSizeOptions]="[20, 50, 100, 500]" [pageSize]="pageSize" showFirstLastButtons
				   aria-label="Select page of periodic elements">
                    </mat-paginator>
                </div>


		<button mat-raised-button matStepperPrevious color="primary" class="mr-1" type="button">Précédent</button>
                <button mat-raised-button matStepperNext color="primary" type="button">Suivant</button>

            </mat-step>

	    <mat-step>
		<ng-template matStepLabel><mat-icon>done</mat-icon> Confirmation</ng-template>


		<ng-template matStepContent>
		    <div class="example-full-width d-flex justify-content-center" *ngIf="DisplayConfirmationButton">
			<button type="submit" form="companyGroupForm" class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>
			
			<!-- <button mat-raised-button cdkFocusInitial>Annuler</button> -->
		    </div>

		</ng-template>
		
		
		<button mat-raised-button color="primary" matStepperPrevious>Précédent</button>
            </mat-step>


	</mat-stepper>



    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
