import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-consulter-document',
  templateUrl: './consulter-document.component.html',
  styleUrls: ['./consulter-document.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ConsulterDocumentComponent implements OnInit  {
  @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
  
  constructor(private router: Router) {
  }

  ngOnInit(): void {

  }

  onEditDocument(): void {
    this.router.navigate([environment.paths.EditerDocumentPath]);
  }

  onShowDocument(): void {
    this.router.navigate(['details/invoice']);
  }

}
