<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">LISTE DES CATÉGORIES</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Produits</a></li>
                    <li class="breadcrumb-item active">Catégories</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content bdy" style="background-color: white;">
    <div class="container-fluid" style="padding: 30px;">


        <button class="btn btn-primary btn-sm" (click)="onAddNewCategory()">Nouvelle catégorie</button>


        <div class="row mb-3 mt-3">
	    <!--
            <div class="col-2">
                <mat-label>Categorie de produits</mat-label>

                <mat-select   placeholder="Choisir une categorie de produit" class="  form-control form-control-sm mb-2" [formControl]="productTypesListCtrl" multiple required>
                    <mat-select-trigger>
                        {{productTypesListCtrl.value?.[0]?.name || ''}}
                        <span *ngIf="(productTypesList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(productTypesList?.length || 0) - 1}} {{productTypesList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let productType of productTypesList" [value]="productType">{{productType.name}}</mat-option>
                </mat-select>

            </div>
            <div class="col-2">
                <mat-label>Produits</mat-label>

                <mat-select   placeholder="Choisir un produit" class="  form-control form-control-sm mb-2" [formControl]="productsListCtrl" multiple required>
                    <mat-select-trigger>
                        {{productsListCtrl.value?.[0]?.name || ''}}
                        <span *ngIf="(productsList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(productsList?.length || 0) - 1}} {{productsList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let product of productsList" [value]="product">{{product.name}}</mat-option>
                </mat-select>
            </div>
            <div class="col-2">
                <mat-label>Status</mat-label>

                <mat-select placeholder="Choisir un status " class="  form-control form-control-sm mb-2" [formControl]="statusesListCtrl" multiple required>
                    <mat-select-trigger>
                        {{statusesListCtrl.value?.[0]?.label || ''}}
                        <span *ngIf="(statusesList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(statusesList?.length || 0) - 1}} {{statusesList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
                </mat-select>
            </div>
	    -->
        </div>

        <div class="table-responsive">
	    <div fxLayout fxLayoutAlign="end center">
		<mat-form-field fxFlex="60%">
		    <input matInput type="text" (keyup)="doFilter($event.target.value)"  name="filter" placeholder="Filtrer">
		</mat-form-field>
		</div>

		
            <table mat-table [dataSource]="datasource" matSort>


                <ng-container matColumnDef="#">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">#</th>
                    <td style="width: 4%;" mat-cell *matCellDef="let element">
                        {{ element.id }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Nom</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.name}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Description</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.description}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="productTypes">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Types de produits</th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngFor="let productType of element.productTypes ; nb_productTypes as index;let i = index"   class="badge  badge-info">
			    <span *ngIf="i < 3 || more_productTypes">{{productType?.name}}</span>
			</span>
			<mat-divider></mat-divider>
			<button mat-button  color="primary" *ngIf="element?.productTypes?.length > 3" (click)="more_productTypes = !more_productTypes">{{ more_productTypes ? 'voir moins' : 'voir plus' }}</button> 
                    </td>
                </ng-container>


                <ng-container matColumnDef="note">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Note</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.note?.content}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.background-color]="color"> Status</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge badge-pill badge-primary">{{element.status?.label}}</span>
                    </td>
                </ng-container>


                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Actions</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon matTooltip="consulter" (click)="onConsulterCategory(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="preview"></mat-icon>
                        <mat-icon matTooltip="editer" (click)="onEditCategory(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="edit"></mat-icon>
                        <mat-icon matTooltip="supprimer" (click)="onDelete(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="delete"></mat-icon>
                    </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;" class="example-element-row"></tr>
            </table>

	    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
		<mat-progress-spinner 
		    color="primary" 
		    mode="indeterminate">
		</mat-progress-spinner>
	    </mat-card>
		    
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="pageSize" showFirstLastButtons
                aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>


    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
