import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-history-button',
  templateUrl: './history-button.component.html',
  styleUrls: ['./history-button.component.scss'], encapsulation: ViewEncapsulation.None
})
export class HistoryButtonComponent implements OnInit {

  private sub: Subscription;
  init: boolean = false;
  history: NavigationEnd[] = [];

  constructor(private router: Router) {
    this.sub = router.events.subscribe((val) => {
        // see also 
       // console.log(val instanceof NavigationEnd) 
        if (val instanceof NavigationEnd) {
          const nav: NavigationEnd = val as NavigationEnd;

          this.history.push(nav);
        }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
  
  }

  onHistoryBtnClicked() {
    this.history.pop();
    const nav = this.history.pop();

    if (nav === undefined) {
      return;
    }
    console.log(nav.url);
    this.router.navigate([nav.url]);
  }

}
