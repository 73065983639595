<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">DOCUMENTS - PDF</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Documents</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 30px;">

        <div class="d-flex justify-content-end">
            <button class="btn btn-primary btn-sm"(click)="onDownloadDocument()" >Télécharger les documents</button>
        </div>


        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
            <mat-tab label="Voir le bon de livraison">
<!-- 
                <div class="d-flex justify-content-end">
                    <button class="btn btn-primary btn-sm"(click)="onDownloadDocument()" >Télécharger le document</button>
                </div> -->


                <div class="pdf-container">
                    <pdf-viewer
                      [src]="url"
                      [rotation]="0"
                      [original-size]="true"
                      [show-all]="true"
                      [fit-to-page]="false"
                      [zoom]="1"
                      [zoom-scale]="'page-width'"
                      [stick-to-page]="true"
                      [render-text]="true"
                      [external-link-target]="'blank'"
                      [autoresize]="true"
                      [show-borders]="true"
                    ></pdf-viewer>
<!--                     <ngx-extended-pdf-viewer
  [src]="src"

  [useBrowserLocale]="true"
> 
</ngx-extended-pdf-viewer>-->
                  </div>

            </mat-tab>
            <mat-tab label="Voir la facture">

                
                <div class="pdf-container">
                    <pdf-viewer
                      [src]="url"
                      [rotation]="0"
                      [original-size]="true"
                      [show-all]="true"
                      [fit-to-page]="false"
                      [zoom]="1"
                      [zoom-scale]="'page-width'"
                      [stick-to-page]="true"
                      [render-text]="true"
                      [external-link-target]="'blank'"
                      [autoresize]="true"
                      [show-borders]="true"
                    ></pdf-viewer>
                  </div>

            </mat-tab>
          </mat-tab-group>


    </div><!--/. container-fluid -->
</section>
<!-- /.content -->