import { AfterViewInit, Component, EventEmitter, OnInit,  Output, Input,
	 ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';

import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import {ActivatedRoute} from '@angular/router';   

// Models/Services
import { Model } from 'src/app/interfaces/model';   
import { SchoolListService } from 'src/app/services/school-list.service';
import { SchoolList } from 'src/app/interfaces/school-list';
import { Response } from '../../interfaces/response';

//
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';

import { MatSort } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';

import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';

@Component({
  selector: 'app-consulter-liste-scolaire',
  templateUrl: './consulter-liste-scolaire.component.html',
  styleUrls: ['./consulter-liste-scolaire.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ConsulterListeScolaireComponent  implements OnInit  {
    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
    ////////////////////////
    // Attributes
    ////////////////////////
    @Input()     _schoolList: SchoolList;
    @Input('id') _id?: string;

    ////////////////////////
    // PRODUCTS ATTRIBUTES 
    ////////////////////////
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    
    //
    // Datatable
    //
    columnsSelection: string[] = ['#', 'image', 'product.name', 'product.description', 'product.price', 'quantity', 'Total', 'Action' ];


    //datasource: MatTableDataSource<Model> = new MatTableDataSource<Model>();
    datasourceSelection: MatTableDataSource<Model> = new MatTableDataSource<Model>();
    pageSize = 20;

    // selectedProduct Converted 
    selectedProductDetails : any[] = [];
    productDetails : any[] = [];
    
    
    isLoading = false;


    ////////////////////////
    // Constructor
    ////////////////////////
    constructor(private router: Router,
		private route: ActivatedRoute,
		private schoolListService: SchoolListService) {
    }


    ////////////////////////
    // Init
    ////////////////////////


    ngOnInit(): void {
	this.initCurrentRoute();
	this.refresh();
    }
    
    initCurrentRoute():void {
	this.route.params.subscribe(params => {
            this._id = params["id"];
	    console.log("current id " + this._id)
        });


    }


    ngAfterViewInit() {
    }


    
    initCurrentObject(): void {
	this.schoolListService.getById(this._id).subscribe(
	    {
		next: (response: Response<number, SchoolList>) => {
		    console.log(response);
		    this._schoolList = response.Data;
	      
	      //this.datasource.data = this.schoolList;
              //this.datasource.paginator = this.paginator;

		    console.log(this._schoolList);
		    this.initDataTables();
	      // this.refresh();
		},
		error: (e) => {
		    console.log(e);
		},
		complete: () => console.info('loaduser complete')
	    });
    }

    ////////////////////////
    // Datatable
    ///////////////////////

    
    initDatasources() : void {
	
	this.datasourceSelection.data = this.productDetails;
	this.datasourceSelection.paginator = this.paginator;
	this.datasourceSelection.sort = this.sort;
	this.datasourceSelection.filterPredicate = this.tableFilter(); 
	
    }

    initDataTables(): void {
	this.productDetails = this._schoolList.schoolListProducts.map(product => this.fromProductDataToProductDetail(product));
	this.initDatasources(); 
    }

    
    fromProductDataToProductDetail(productDetail) {
	let productGroup = {
	    checked : true,
	    productId : productDetail?.productId,
	    product: productDetail?.product, 
	    price :  productDetail?.price,
	    quantity: productDetail?.quantity ?? 1,
	    img : productDetail.product?.mainImage? this.getUrlOf(productDetail.product.mainImage) : ""
	}
	return productGroup;
	
    }
    
    ////////////////////////
    // Filter Datatable
    ///////////////////////

    doFilter(value: string) : void  {
	this.datasourceSelection.filter = value.trim().toLocaleLowerCase();
    }

    
    tableFilter(): (data: any, filter: string) => boolean {
	let columnsNames = this.columnsSelection;

	let filterFunction = function(data, filter): boolean{
	    

	    var datas = columnsNames.map(x => {
		// split string succh as "customer.name"
		var objectData = x.includes(".") ? x.split(".") : [x];
		var currentObject = data;

		// concat array to create object like data["customer"]["name"]
		objectData.forEach(key => {
		    currentObject = currentObject[key];
		})
		// get val and check if its related to filter
		let val = currentObject ?? data[x];
		if (val && val.toString().toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1){		    
		    return  data;
		}
		return null;
		
	    }).filter(x => x); // map and filter to eliminate null data in array

	    
	    return datas?.length > 0 ? true : false; 
	}
	return filterFunction;
     }

    ////////////////////////
    // Links / URL
    ///////////////////////


    openInNewTab(namedRoute) {
	let newRelativeUrl = this.router.createUrlTree([namedRoute]);
	console.log(newRelativeUrl);
	let baseUrl = window.location.href.replace(this.router.url, '');

	window.open(baseUrl + newRelativeUrl, '_blank');
    }
    
    onConsulterProduct(id): void {
	this.openInNewTab(`${environment.paths.ConsulterProductPath}/${id}`);
    }

    

    ////////////////////////
    // Datasources
    ////////////////////////

    getUrlOf(element) : string {
	var path =  element.path ? element.path.replace(/^public?\//i, "") : null;
	return element && element.path ? `${environment.domains.api.url}/${path}` : "";
    }

    refresh() : void {
	this.initCurrentObject();	

    }

    ////////////////////////
    // Actions 
    //////////////////////// 
  onEditListeScolaire(id): void {
      this.router.navigate([environment.paths.EditerListeScolairePath, id]);
  }

}
