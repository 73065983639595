<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">ÉDITER MÉTHODE D'EXPÉDITION</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Livraisons</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">


        <h3 style="color: black; font-size:medium">Méthode d'expédition</h3>
        <h6 style="color: black; font-size: small">Éditer une méthode d'expédition</h6>
        <form class="example-form" [formGroup]="form" (ngSubmit)="submit()"  *ngIf="_shippingType">


            <mat-label>Nom</mat-label>
	    <input type="text" matInput formControlName="name"  [(ngModel)]="_shippingType.name"  class="form-control form-control-sm mb-2" required>
	    <app-show-errors [ctrl]="form.get('name')"></app-show-errors>

	    <mat-label>Label</mat-label>
	    <input type="text" matInput formControlName="label"  [(ngModel)]="_shippingType.label"  class="form-control form-control-sm mb-2" required>
	    <app-show-errors [ctrl]="form.get('label')"></app-show-errors>

            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Description</mat-label>
            		<textarea style="height: 50px;" matInput placeholder="Description" formControlName="description"
			   [(ngModel)]="_shippingType.description" 
				  required></textarea>
			<app-show-errors [ctrl]="form.get('description')"></app-show-errors>
            </mat-form-field>


            <mat-label>Expeditions</mat-label>
            <div class="input-group">
		<mat-select placeholder="Choisir les produits pour ce type de produits"
			    class="  form-control form-control-sm mb-2"
			    [formControl]="shippingsListCtrl" 
			    [(ngModel)]="selectedShippings"
			    [compareWith]="compareFn"
			    (selectionChange)="onShippingsChanged($event)"
			    multiple required >
		    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyShippings($event.target.value)"> 
		    <mat-select-trigger>
			{{shippingsListCtrl.value?.[0]?.name  || ''}} 
                        <span *ngIf="(shippingsList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedShippings?.length || 0) - 1}} {{selectedShippings?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let shipping of shippingsList" [value]="shipping">{{shipping.name}}</mat-option>
		</mat-select>
                <div class="input-group-append">
                    <button type="button"class="btn btn-primary  form-control-sm">
                      <mat-icon>add</mat-icon>
                    </button>
                  </div>
            </div>

            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Note</mat-label>
		<textarea style="height: 50px;" matInput placeholder="note" formControlName="note"
			  [ngModel]="_shippingType && _shippingType.note && _shippingType.note.content"
			  (ngModelChange)="_shippingType && _shippingType.note && _shippingType.note.content  = $event"
			  required></textarea>
		<app-show-errors [ctrl]="form.get('note')"></app-show-errors>
            </mat-form-field>

            <mat-label>Status</mat-label>
            <div class="input-group">
		<mat-select placeholder="Choisir un statut pour ce type de produit" class="  form-control form-control-sm mb-2"   required
			    [(ngModel)]="selectedStatus" [formControl]="statusesListCtrl"  [compareWith]="compareFn" (selectionChange)="onStatusesChanged($event)"  >
		    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyStatuses($event.target.value)">  
                    <mat-select-trigger>
                        {{selectedStatus?.label  || ''}}
                        <span *ngIf="(selectedStatuses?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedStatuses?.length || 0) - 1}} {{selectedStatuses?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
			</mat-select>
            </div>


            <div class="example-full-width d-flex justify-content-center" *ngIf="DisplayConfirmationButton">
                <button class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>
                
                <!-- <button mat-raised-button cdkFocusInitial>Annuler</button> -->
            </div>



        </form>




    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
