import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';
import { SchoolList } from 'src/app/interfaces/school-list';
import { School } from 'src/app/interfaces/school';
import { ClassLevel } from 'src/app/interfaces/class-level';
import { Product } from 'src/app/interfaces/product';
import { Status } from 'src/app/interfaces/status';


import { SchoolListService } from 'src/app/services/school-list.service';
import { SchoolService } from 'src/app/services/school.service';
import { StatusService } from 'src/app/services/status.service';
import { ProductService } from 'src/app/services/product.service';
import { ClassLevelService } from 'src/app/services/class-level.service';

import { Response } from '../../interfaces/response';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


import { MatSort } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout'; 
import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@Component({
  selector: 'app-list-list-scolaire',
  templateUrl: './list-list-scolaire.component.html',
  styleUrls: ['./list-list-scolaire.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ListListScolaireComponent   implements OnInit, AfterViewInit {

    //////////////////////
    // Attributes 
    //////////////////////

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;    

    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
    
    columns: string[] = ['#', 'classGrade.school.name', 'PDF', 'note',  'products', 'status',  'Action' ];
    datasource: MatTableDataSource<SchoolList> = new MatTableDataSource<SchoolList>();
    pageSize = 10;
    schoolListsList: SchoolList[];
    
    color="#f8f8fb";
    
    //toppings = new FormControl('');
    //toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

    schoolsListCtrl = new FormControl('');
    schoolsData : School[];//string[];
    schoolsList : School[];//string[];
    selectedSchool : School;
    selectedSchools : School[];

    productsListCtrl = new FormControl('');
    productsData : Product[];//string[];
    productsList : Product[];//string[];
    selectedProduct : Product;
    selectedProducts : Product[];

    classLevelsListCtrl = new FormControl('');
    classLevelsList : ClassLevel[];//string[];
    classLevelsData : ClassLevel[];//string[];
    selectedClassLevel : ClassLevel;
    selectedClassLevels : ClassLevel[];

    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatus : Status;
    
    isLoading = true;
    

    //////////////////////
    // Constructor 
    ////////////////////// 

    constructor(private schoolListService: SchoolListService,
		private productService : ProductService,
		private schoolService : SchoolService,
		private statusService : StatusService,
		private classLevelService: ClassLevelService,

		private router: Router,
		public dialog: MatDialog) {

    }

    //////////////////////
    // Init
    ////////////////////// 

    ngAfterViewInit() {
	this.datasource.paginator = this.paginator;
    }
    
    ngOnInit(): void {
	this.refresh();
    }

    getUrlOf(element) : string {
	var path =  element.path ? element.path.replace(/^public?\//i, "") : null;
	return element && element.path ? `${environment.domains.api.url}${path}` : "";
    }

    //////////////////////
    // Data Sources 
    ////////////////////// 
    
    doFilter(value: string) : void  {
	this.datasource.filter = value.trim().toLocaleLowerCase();
    }
    
    refresh() : void {
	this.schoolListService.getAllSchoolLists().subscribe(
      {
          next: (response: Response<number, SchoolList[]>) => {
              this.schoolListsList = response.Data;
              this.datasource.data = this.schoolListsList;
              this.datasource.paginator = this.paginator;
	      this.setLoading(false);
	      console.log(this.schoolListsList);
          },
          error: (e) => {
	      this.setLoading(false);
          },
          complete: () => console.info('loadMenu complete')
      });

	this.productService.getAll().subscribe({
	    next: (response: Response<number, Product[]>) => {
		this.productsList = response.Data;
            },error: (e) => { }, complete: () => console.info('load products complete')
	});
	this.schoolService.getAll().subscribe({
	    next: (response: Response<number, School[]>) => {
		this.schoolsList = response.Data;
            },error: (e) => { }, complete: () => console.info('load schools complete')
	});

	this.statusService.getAll().subscribe({
	    next: (response: Response<number, Status[]>) => {
		this.statusesList = response.Data;
            },error: (e) => { }, complete: () => console.info('load status complete')
	});
	this.classLevelService.getAll().subscribe({
	    next: (response: Response<number, ClassLevel[]>) => {
		this.classLevelsList = response.Data;
            },error: (e) => { }, complete: () => console.info('load status complete')
	});
    }
    
    //////////////////////
    // Loading
    //////////////////////

    toggleLoading() : void {
	this.isLoading = !this.isLoading;
    }

    setLoading(loading : boolean) : void {
	this.isLoading = loading;
    }

    //////////////////////
    //////////////////////

    
    //////////////////////
    // Actions
    //////////////////////
    
    
    onAddNewListeScolaire(): void {
	this.router.navigate([environment.paths.NewListScolairePath]);
    }

    onEditListeScolaire(id): void {
	this.router.navigate([environment.paths.EditerListeScolairePath, id]);
    }

    onConsulterListeScolaire(id): void {
      this.router.navigate([environment.paths.ConsulterListeScolairePath, id]);
    }

    onDelete(id): void {
	const dialogRef = this.dialog.open(ConfirmerSuppressionDialogComponent, {
	    disableClose: true,
	    data: { TitleVisible: false, DisplayConfirmationButton: false },
	});

	dialogRef.afterClosed().subscribe((result: boolean) => {
	if (result) {
	  console.log(id);;


	  this.schoolListService.deleteById(id).subscribe(
	      {
		  next: (response: Response<number, SchoolList>) => {
		      console.log(response);
		      this.refresh();
		  },
		  error: (e) => {
		      console.log(e);
		  },
		  complete: () => console.info('delete complete')
	      }
	  );

      }
    });
  }

/*    onDuplicate(id): void {
	const dialogRef = this.dialog.open(ConfirmerSuppressionDialogComponent, {
	    disableClose: true,
	    data: { TitleVisible: false, DisplayConfirmationButton: false },
	});

	dialogRef.afterClosed().subscribe((result: boolean) => {
	if (result) {
	  console.log(id);;


	  this.schoolListService.deleteById(id).subscribe(
	      {
		  next: (response: Response<number, SchoolList>) => {
		      console.log(response);
		      this.refresh();
		  },
		  error: (e) => {
		      console.log(e);
		  },
		  complete: () => console.info('delete complete')
	      }
	  );

      }
    });
  }
*/
}
