<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">LISTE DES CLIENTS</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Clients</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content bdy" style="background-color: white;">
    <div class="container-fluid" style="padding: 30px;">


        <button class="btn btn-primary btn-sm" (click)="onAddNewCustomer()">Ajouter un client</button>


        <div class="row mb-3 mt-3">
	    <!--
            <div class="col-2">
		<mat-label>Socétés</mat-label>
                <mat-select   placeholder="Choisir une socété" class="  form-control form-control-sm mb-2" [formControl]="companiesListCtrl" multiple required>
		    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyCompanies($event.target.value)">  
                    <mat-select-trigger>
                        {{companiesListCtrl.value?.[0]?.name || ''}}
                        <span *ngIf="(companiesList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(companiesList?.length || 0) - 1}} {{companiesList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let company of companiesList" [value]="company">{{company.name}}</mat-option>
                </mat-select>
            </div>
            <div class="col-2">
                <mat-label>Groupes </mat-label>
                <mat-select   placeholder="Choisir un groupe" class="  form-control form-control-sm mb-2" [formControl]="companyGroupsListCtrl" multiple required>
		    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyCompanyGroups($event.target.value)">  
                    <mat-select-trigger>
                        {{companyGroupsListCtrl.value?.[0]?.name || ''}}
                        <span *ngIf="(companyGroupsList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(companyGroupsList?.length || 0) - 1}} {{companyGroupsList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let companyGroup of companyGroupsList" [value]="companyGroup">{{companyGroup.name}}</mat-option>
                </mat-select>
            </div>
            <div class="col-2">
                <mat-label>Status</mat-label>

                <mat-select placeholder="Choisir un status " class="  form-control form-control-sm mb-2" [formControl]="statusesListCtrl" multiple required>
		    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyStatuses($event.target.value)">  
                    <mat-select-trigger>
                        {{statusesListCtrl.value?.[0]?.label || ''}}
                        <span *ngIf="(statusesList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(statusesList?.length || 0) - 1}} {{statusesList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
                </mat-select>
            </div>
	    -->
        </div>

        <div class="table-responsive">
	    <div fxLayout fxLayoutAlign="end center">
		<mat-form-field fxFlex="60%">
		    <input matInput type="text" (keyup)="doFilter($event.target.value)"  name="filter" placeholder="Filtrer">
		</mat-form-field>
	    </div>
            <table mat-table [dataSource]="datasource" matSort>

                <ng-container matColumnDef="#">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">#</th>
                    <td style="width: 4%;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.id}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Nom</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.profile?.firstname}} {{element.profile?.lastname}}</span>
                    </td>
                </ng-container>

		
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Email</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.profile?.email}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="phoneNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Telephone</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.profile?.phoneNumber}}</span>
                    </td>
                </ng-container>

	
                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Addresse</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.shippingAddress?.street}} , {{element.shippingAddress?.city}} {{element.shippingAddress?.zip}} {{element.shippingAddress?.country}}
                    </td>
                </ng-container>

		<ng-container matColumnDef="company">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Société</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span *ngIf="element.company" class="table-regular">
			    <span class="badge badge-pill badge-info"> {{element.company?.name}} </span>
			</span>
                    </td>
                </ng-container>

		<ng-container matColumnDef="companyGroup">
		    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Groupe</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span *ngIf="element.company" class="table-regular">
			    <span class="badge badge-pill badge-info"> {{element.company?.companyGroup?.name}} </span>
			</span>
                    </td>
                </ng-container>

		
		<ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Status</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge badge-pill badge-primary"> {{element.status?.label}} </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Action</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon matTooltip="consulter" (click)="onConsulterCustomer(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="preview"></mat-icon>
                        <mat-icon matTooltip="editer" (click)="onEditCustomer(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="edit"></mat-icon>
                        <mat-icon matTooltip="supprimer" (click)="onDelete(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="delete"></mat-icon>
                    </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;" class="example-element-row"></tr>
            </table>

	    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
		<mat-progress-spinner 
		    color="primary" 
		    mode="indeterminate">
		</mat-progress-spinner>
	    </mat-card>
	    
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="pageSize" showFirstLastButtons
                aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>


    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
