<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">NOUVELLE MÉTHODE D'EXPÉDITION</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Livraisons</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">


        <h3 style="color: black; font-size:medium">Méthode d'expédition</h3>
        <h6 style="color: black; font-size: small">Ajouter une nouvelle méthode d'expédition</h6>
        <form class="example-form" [formGroup]="form" (ngSubmit)="submit()">

	    <mat-label>Nom</mat-label>
            <input type="text" matInput formControlName="name" class="form-control form-control-sm mb-2" required>
	    <app-show-errors [ctrl]="form.get('name')"></app-show-errors>

	    <mat-label>Label</mat-label>
	    <input type="text" matInput formControlName="label" class="form-control form-control-sm mb-2" required>
	    <app-show-errors [ctrl]="form.get('label')"></app-show-errors>
	    
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Description</mat-label>
                <textarea style="height: 100px;" matInput placeholder="Description" formControlName="description"  required></textarea>
		<app-show-errors [ctrl]="form.get('description')"></app-show-errors>
            </mat-form-field>

	    <div >
            <mat-label>Commandes</mat-label>
            <div class="input-group">
                <mat-select placeholder="Choisir des commandes" class="  form-control form-control-sm mb-2" [formControl]="shippingsListCtrl" multiple required [(ngModel)]="selectedShippings">
		    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyShippings($event.target.value)"> 
		    <mat-select-trigger>
                        {{shippingsListCtrl.value?.[0]?.name  || ''}} 
			<span *ngIf="(shippingsList?.length || 0) > 1" class="example-additional-selection">
				    (+{{(selectedShippings?.length || 0) - 1}} {{selectedShippings?.length === 2 ? 'autre' :
				    'autres'}})
			</span>
		    </mat-select-trigger>
		    <mat-option *ngFor="let shipping of shippingsList" [value]="shipping">{{shipping.shippingNumber}} </mat-option>
		</mat-select>
            </div>
	    </div>
            <div class="row mt-3">
                <div class="col-6">
                    <mat-form-field appearance="outline" class="example-full-width mb-2">
                        <mat-label>Note</mat-label>
                        <textarea style="height: 100px;" formControlName="note" matInput placeholder="Note" required></textarea>
			<app-show-errors [ctrl]="form.get('note')"></app-show-errors>
                    </mat-form-field>
                </div>
	    </div>                

            <div class="example-full-width d-flex justify-content-center" *ngIf="DisplayConfirmationButton">
                <button class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>
                
                <!-- <button mat-raised-button cdkFocusInitial>Annuler</button> -->
            </div>



        </form>




    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
