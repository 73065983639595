import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


// models
import { Brand } from '../interfaces/brand';
import { Product } from '../interfaces/product';
import { Note } from '../interfaces/note';

// services
import {UserService} from '../services/user.service'
import {NoteService} from '../services/note.service'

// rxjs
import { of, from,  map, Observable, exhaustMap, EMPTY, empty ,identity, forkJoin} from 'rxjs';
import { mergeMap, mergeAll, toArray, switchMap} from 'rxjs/operators';

// response   
import { Response as Rep } from '../interfaces/response';

import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"



@Injectable({
  providedIn: 'root'
})
export class BrandService extends BaseCrudModel<Brand>{

    postProducts = {url: `${environment.domains.api.url}/item-types`, urlOptions: this.options};
    deleteProducts = {url: `${environment.domains.api.url}/item-types`, urlOptions: this.options};
    
    constructor(protected override httpService: HttpService,
	          private noteService : NoteService) {
	super(httpService, "brands");

	var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "objectDetail"},
		{"relation" : "note"},
		{"relation" : "products"},
	    ],
	});

	var addOptions =  RequestOptions.FromArray({
	    relationsToOmit : ['note', 'status', 'products']
	});

	this.methods.listing.urlOptions = options;
	this.methods.view.urlOptions = options;
	this.methods.add.urlOptions = addOptions;
	this.methods.update.urlOptions = addOptions;
    }

    getAllBrands(): Observable<Rep<number, Brand[]>> {
	const options = new RequestOptions();

	return this.getAll()
    }

    ////////////////////////////////
    // PREPARE || HANDLE Relations
    ////////////////////////////////
    
    override prepareRelations(fromBrand): Observable<Rep<number, Brand>> { //Observable<Profile> {
	// values to save
	const brand : Brand = fromBrand;


	// Obs Functions
	const failObj : Rep<number, Note> = {IsSuccess:  0, Data:  null, Status : 0};

	const a$ = of();

	const note$ : Observable<Rep<number, Note>> = brand.note ? 
	    ( brand.note.id ? this.noteService.updateById(fromBrand.note.id, fromBrand.note) : this.noteService.add(fromBrand.note) )
	    :  this.httpService.createObservableResponse<Note>(failObj);

	
	return note$
	    .pipe(
		map(note => 
		    {
			console.log("created note");
			console.log(note);
			console.log("before setting Note");
			console.log(brand);
			(brand.noteId = note && note.IsSuccess ?
			    (note.Data && note.Data.id ? note.Data.id : brand.noteId) : brand.noteId );
			
			return   this.httpService.createResponseFromObj<Brand>(brand)
		    }),
		map(rel =>
		    {
			console.log("final result");
			console.log(rel);
			// return profile
			return    this.httpService.createResponseFromObj<Brand>(brand) ;
		    }),
		
	    );
    }

    //////////////////////////
    // HANDLE || PREPARE
    //////////////////////////

    override handleRemoveRelations(brand): Observable<any> {
	console.log("removePostRelations for brand : \n {0}".format(JSON.stringify(brand)));

	return forkJoin
	(
	    // Remove elements if empty
	    brand && brand.id && (brand.products && brand.products?.length >= 0) ? this.removeProducts(brand.id) :
		this.httpService.createObservableResponse<Product[]>({IsSuccess:  0, Data:  undefined, Status : 0})

	)
    }
    
    override handlePostRelations(brand): Observable<any> {
	console.log(brand);
	console.log("handlePostRelations");
	    return forkJoin
	(
	    
	    // add new elements 
	    brand && brand.id && brand.products && brand.products?.length > 0 ? this.addProducts(brand.id, brand.products) :
		this.httpService.createObservableResponse<Product[]>({IsSuccess:  0, Data:  undefined, Status : 0})
	)
    }

    //////////////////////////
    // Add Other objects
    //////////////////////////

    public removeProducts(brandId):  Observable<Rep<number, any>> {
	console.log("remove product for brandId:{brandId}".formatUnicorn({brandId : brandId}));
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		//{"brandId" : brandId},
		{"brandId" : {"regexp" : "/^{brandId}$/".formatUnicorn({brandId: brandId})}},
	    ],
	});
	options.body = {"brandId" : ""}
	return this.httpService.makePatchRequest<any>( `${this.deleteProducts.url}`, options);
//	return  this.httpService.createObservableResponse<ProductBrand[]>({IsSuccess:  0, Data:  undefined, Status : 0});
    }


    //////////////////////////
    // Add Other objects
    //////////////////////////

    public addProducts(brandId, products):  Observable<Rep<number, any>> {
	
 	
	//const arrayProducts = products?.map((r: any): Product => ({brandId : brandId, productId: r.id}));

	const arrayProducts = products?.map((r: Product): any => (r.id));

	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		{"id" : {"inq" : arrayProducts}},
	    ],
	});
	console.log("Array products");
	console.log(arrayProducts);
	options.body =  {"brandId" : brandId} ;//arrayProducts;	

	return arrayProducts && arrayProducts.length > 0 ?
	    this.httpService.makePatchRequest<Product[]>( `${this.postProducts.url}`.format(), options) :
	    this.httpService.createObservableResponse<Product[]>({IsSuccess:  0, Data:  undefined, Status : 0});
	//Observable.empty<number, any>() ;
    }


}
