<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">VOIR UTILISATEUR</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Contacts</a></li>
                    <li class="breadcrumb-item active">User List</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 30px;">

        <div class="d-flex justify-content-end">
            <button class="btn btn-primary btn-sm" (click)="onEditProfil(_profile.id)">Éditer un profil</button>
        </div>

        <label>Information personnelle</label>
        <div class="lateral-space">
   
            <div class="row mt-3 w-100 left-background">
                <div class="col-6 left">
                    Nom d'utilisateur
                </div>
                <div class="col-6 right">
                    {{_profile?.user?.username}}
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Nom
                </div>
                <div class="col-6 right">
                    {{_profile?.lastname}}
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    Prénom
                </div>
                <div class="col-6 right">
                    {{_profile?.firstname}}
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Email
                </div>
                <div class="col-6 right">
                    {{_profile?.email}}
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    Téléphone
                </div>
                <div class="col-6 right">
                    {{_profile?.phoneNumber}}
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-6 col-sm-12">
                <label>Adresse</label>
                <div class="lateral-space">
           
                    <div class="row mt-3 w-100 left-background">
                        <div class="col-6 left">
                            Rue
                        </div>
                        <div class="col-6 right">
                            {{_profile?.address?.street}}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Ville
                        </div>
                        <div class="col-6 right">
                            {{_profile?.address?.city}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Code postal
                        </div>
                        <div class="col-6 right">
                              {{_profile?.address?.zip}} 
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Pays
                        </div>
                        <div class="col-6 right">
                              {{_profile?.address?.country}} 
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Details additionnels
                        </div>
                        <div class="col-6 right">
                              {{_profile?.address?.additionnalDetail}} 
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <label>Informations complémentaires</label>
                <div class="lateral-space">
           
                    <div class="row mt-3 w-100 left-background">
                        <div class="col-6 left">
                            Role
                        </div>
                        <div class="col-6 right">
                            <span *ngFor="let role of _profile?.user?.roles"  class="badge badge-pill badge-primary">{{role.name}}</span>
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Permissions
                        </div>
                        <div class="col-6 right">
                            <span *ngFor="let permission of _profile?.user?.permissions"  class="badge badge-pill badge-primary">{{permission.name}}</span>
			</div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Créé le : {{_profile?.objectDetail?.creationDate |  date:'dd/MM/yyyy' }}
                        </div>
                        <div class="col-6 right">
                            Modifié le : {{_profile?.objectDetail?.modificationDate  |  date:'dd/MM/yyyy' }}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Créé par
                        </div>
                        <div class="col-6 right">
                            {{_profile?.objectDetail?.creator?.username}}  
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Modifié par
                        </div>
                        <div class="col-6 right">
                            {{_profile?.objectDetail?.updater?.username}}  
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Status
                        </div>
                        <div class="col-6 right d-flex justify-content-center">
                            {{_profile?.status?.label}}  
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
