<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">LISTES SCOLAIRES</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Établissements</a></li>
                    <li class="breadcrumb-item active">Liste scolaire</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content bdy" style="background-color: white;">
    <div class="container-fluid" style="padding: 30px;">


        <button class="btn btn-primary btn-sm" (click)="onAddNewListeScolaire()">Nouvelle liste scolaire</button>


        <div class="row mb-3 mt-3">
	    <!--
            <div class="col-2">
                <mat-label>Niveau scolaire</mat-label>

                <mat-select   placeholder="Choisir niveau scolaire" class="  form-control form-control-sm mb-2" [formControl]="classLevelsListCtrl" multiple required>
                    <mat-select-trigger>
                        {{classLevelsListCtrl.value?.[0]?.name || ''}}
                        <span *ngIf="(classLevelsList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(classLevelsList?.length || 0) - 1}} {{classLevelsList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let classLevel of classLevelsList" [value]="classLevel">{{classLevel.name}}</mat-option>
                </mat-select>
            </div>
            <div class="col-2">
                <mat-label>École</mat-label>

               <mat-select placeholder="Choisir une ecole" class="  form-control form-control-sm mb-2" [formControl]="schoolsListCtrl" multiple required>
                    <mat-select-trigger>
                        {{schoolsListCtrl.value?.[0]?.name || ''}}
                        <span *ngIf="(schoolsList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(schoolsList?.length || 0) - 1}} {{schoolsList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let school of schoolsList" [value]="school">{{school.name}}</mat-option>
                </mat-select>
            </div>
            <div class="col-2">
                <mat-label>Produits</mat-label>

               <mat-select   placeholder="Choisir un produit" class="  form-control form-control-sm mb-2" [formControl]="productsListCtrl" multiple required>
                    <mat-select-trigger>
                        {{productsListCtrl.value?.[0]?.name || ''}}
                        <span *ngIf="(productsList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(productsList?.length || 0) - 1}} {{productsList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let product of productsList" [value]="product">{{product.name}}</mat-option>
                </mat-select>
            </div>

	    
            <div class="col-2">
                <mat-label>Status</mat-label>
		<mat-select placeholder="Choisir un status " class="  form-control form-control-sm mb-2" [formControl]="statusesListCtrl" multiple required>
                    <mat-select-trigger>
                        {{statusesListCtrl.value?.[0]?.label || ''}}
                        <span *ngIf="(statusesList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(statusesList?.length || 0) - 1}} {{statusesList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
		</mat-select>
            </div>
	    -->
        </div>

        <div class="table-responsive">
	    <div fxLayout fxLayoutAlign="end center">
		<mat-form-field fxFlex="60%">
		    <input matInput type="text" (keyup)="doFilter($event.target.value)"  name="filter" placeholder="Filtrer">
		</mat-form-field>
	    </div>
		    
            <table mat-table [dataSource]="datasource" matSort>

                <ng-container matColumnDef="#">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">#</th>
                    <td style="width: 4%;" mat-cell *matCellDef="let element">
                        <span class="table-regular">#{{element.id}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="classGrade.school.name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Nom</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <h5 class="table-username">{{element.name}}</h5>
                        <h6 class="table-subusername">{{element.classGrade?.classLevel?.name}} - {{element.classGrade?.school?.name}}</h6>
			<h6 class="table-subusername">{{element.classGrade?.school?.address?.street}},  {{element.classGrade?.school?.address?.zip}}, {{element.classGrade?.school?.address?.city}}, {{element.classGrade?.school?.address?.country}}</h6>
                    </td>
                </ng-container>

                <ng-container matColumnDef="PDF">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> PDF</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <a   *ngIf="(element?.file && element.file?.path)" href="{{getUrlOf(element.file)}}">Voir le pdf</a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="note">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Note
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.note?.content}}</span>
                    </td>
                </ng-container>

		<ng-container matColumnDef="products">
		    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Produits</th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngFor="let product of element.products ; nb_products as index;let i = index"   class="badge  badge-info">
			    <span *ngIf="i < 3 || more_products">{{product?.name}}</span>
			</span>
			<mat-divider></mat-divider>
			<button mat-button  color="primary" *ngIf="element?.products?.length > 3" (click)="more_products = !more_products">{{ more_products ? 'voir moins' : 'voir plus' }}</button> 
                    </td>
		</ng-container>
				


                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.background-color]="color"> Status</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge badge-pill badge-primary">{{element.status?.label}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Action</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon matTooltip="consulter" (click)="onConsulterListeScolaire(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="preview"></mat-icon>
                        <mat-icon matTooltip="editer" (click)="onEditListeScolaire(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="edit"></mat-icon>
                        <mat-icon matTooltip="supprimer" (click)="onDelete(element.id)" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="delete"></mat-icon>
                    </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;" class="example-element-row"></tr>
            </table>

	    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
		<mat-progress-spinner 
		    color="primary" 
		    mode="indeterminate">
		</mat-progress-spinner>
	    </mat-card>
		    
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="pageSize" showFirstLastButtons
                aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>


    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
