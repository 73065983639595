<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">VOIR POINT RELAIS</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Points relais</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;" *ngIf="_location">
    <div class="container-fluid" style="padding: 30px;">

        <div class="d-flex justify-content-end">
            <button class="btn btn-primary btn-sm" (click)="onEditLocation(_location.id)">Éditer un point relais</button>
        </div>

        <label>Voir point de vente</label>
        <div class="lateral-space">
   
            <div class="row mt-3 w-100 left-background">
                <div class="col-6 left">
                    Nom
                </div>
                <div class="col-6 right">
                    {{_location?.name}}
                </div>
            </div>
        </div>







        <div class="row mt-5">
            <div class="col-md-6 col-sm-12">
                <label>Adresse </label>
                <div class="lateral-space">
           
                    <div class="row mt-3 w-100 left-background">
                        <div class="col-6 left">
                            Rue
                        </div>
                        <div class="col-6 right">
                            {{_location?.address?.street}}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Ville
                        </div>
                        <div class="col-6 right">
			    {{_location?.address?.city}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Code postal
                        </div>
                        <div class="col-6 right">
			    {{_location?.address?.zip}}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Pays
                        </div>
                        <div class="col-6 right">
                            {{_location?.address?.country}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Details additionnels
                        </div>
                        <div class="col-6 right">
 			    {{_location?.address?. additionalDetail}}
			    
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <label>Informations complémentaires</label>
                <div class="lateral-space">
           
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Créé le {{_location?.objectDetail?.creationDate |  date:'dd/MM/yyyy' }}  
                        </div>
                        <div class="col-6 right">
                            Modifié le  {{_location?.objectDetail?.modificationDate |  date:'dd/MM/yyyy' }} 
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Créé par
                        </div>
                        <div class="col-6 right">
                             {{_location?.objectDetail?.creator?.username}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Modifié par
                        </div>
                        <div class="col-6 right">
                            {{_location?.objectDetail?.updater?.username}} 
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Status
                        </div>
                        <div class="col-6 right d-flex justify-content-center">
                            <span class="badge badge-pill badge-primary">{{_location?.status?.label}}  </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
