import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';


import { ShippingType } from 'src/app/interfaces/shipping-type';
import { Product } from 'src/app/interfaces/product';
import { Order } from 'src/app/interfaces/order';
import { Status } from 'src/app/interfaces/status';     


import { ShippingTypeService } from 'src/app/services/shipping-type.service';
import { ProductService } from 'src/app/services/product.service';  
import { StatusService } from 'src/app/services/status.service';
import { OrderService } from 'src/app/services/order.service';  

import { environment } from 'src/environments/environment';

import { Response } from '../../interfaces/response';
import { Router } from '@angular/router';

import { MatSort } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout'; 
import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@Component({
  selector: 'app-list-method-expedition',
  templateUrl: './list-method-expedition.component.html',
  styleUrls: ['./list-method-expedition.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ListMethodExpeditionComponent implements OnInit, AfterViewInit {
    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
    @ViewChild(MatPaginator) paginator: MatPaginator;

    //////////////////////
    // Attributes
    //////////////////////
    
    columns: string[] = ['#', 'name', 'label', 'description', 'note', 'status', 'Action' ];
  datasource: MatTableDataSource<ShippingType> = new MatTableDataSource<ShippingType>();
  pageSize = 10;

    shippingTypes: ShippingType[];

    color="#f8f8fb";
    
    ordersListCtrl = new FormControl('');
    ordersData : Order[];//string[];
    ordersList : Order[];//string[];
    selectedOrders : Order[];

    productsListCtrl = new FormControl('');
    productsList : Product[];//string[];
    productsData : Product[];//string[];
    selectedProductes : Product[];
    
    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatus : Status;
    
    isLoading = true;
    
    //////////////////////
    // Constructor
    //////////////////////
    
    constructor(private shippingTypeService: ShippingTypeService,
		private productService : ProductService,
		private orderService : OrderService,
		private statusService : StatusService,
		
		private router: Router,
		public dialog: MatDialog) {

  }

      
    //////////////////////
    // Init
    //////////////////////

    ngAfterViewInit() {
	this.datasource.paginator = this.paginator;
    }

    
    ngOnInit(): void {
      this.refresh();
    }

    //////////////////////
    // Data sources
    //////////////////////
    
    doFilter(value: string) : void  {
	this.datasource.filter = value.trim().toLocaleLowerCase();
	}
    
    refresh() :void{
	this.setLoading(true);
	this.shippingTypeService.getAllShippingTypes().subscribe(
	    {
		next: (response: Response<number, ShippingType[]>) => {
		    this.shippingTypes = response.Data;
		    this.datasource.data = this.shippingTypes;
		    this.datasource.paginator = this.paginator;
		    this.setLoading(false);
		},
		error: (e) => {
		    this.setLoading(false);
		},
		complete: () => console.info('loadMenu complete')
	    }
	);

	this.orderService.getAll().subscribe({
	    next: (response: Response<number, Order[]>) => {
		this.ordersData = response.Data ? response.Data : [];
		this.initOrdersSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load orders complete')
	});
	this.statusService.getAll().subscribe({
	    next: (response: Response<number, Status[]>) => {
		this.statusesData = response.Data;
		this.initStatusesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load status complete')
	});
	this.productService.getAll().subscribe({
	    next: (response: Response<number, Product[]>) => {
		this.productsData = response.Data;
		this.initProductsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load status complete')
	});
	
    }




    ////////////////////////
    // Datasources Functions
    ///////////////////////

    

    compareFn(c1, c2): boolean {
	return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }
    
    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns

    initProductsSearchDropdown(){
	this.productsList = [];
        for ( let i = 0 ; i < this.productsData.length; i ++ ) {
            this.productsList.push( this.productsData[i] );
        }
    }

    initOrdersSearchDropdown(){
	this.ordersList = [];
        for ( let i = 0 ; i < this.ordersData.length; i ++ ) {
            this.ordersList.push( this.ordersData[i] );
        }
    }


    initStatusesSearchDropdown(){
	this.statusesList = [];
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
	    this.statusesList.push( this.statusesData[i] );	    
        }

    }
    
    ////////////////////////
    // On change

    onStatusesChanged($event: any) {
      console.log($event);
    }

    onOrdersChanged($event: any) {
      console.log($event);
    }

    
    onProductsChanged($event: any) {
      console.log($event);
    }

    ////////////////////////
    // On Search
    onKeyOrders(value) { 
            this.ordersList= []; 
            this.selectOrderSearch(value);       
    }

    onKeyProducts(value) { 
            this.productsList= []; 
            this.selectProductsSearch(value);       
    }


    onKeyStatuses(value) { 
            this.statusesList= []; 
            this.selectStatusesSearch(value);       
    }

    ////////////////////////
    // search elements

    selectProductsSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.productsData.length; i ++ ) {
            let option = this.productsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >=0 ) {
                this.productsList?.push( option );
            }
        }
    }

    selectOrderSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.ordersData.length; i ++ ) {
            let option = this.ordersData[i];
            if (  option.orderNumber.toLowerCase().indexOf(filter) >= 0  ) {
                this.ordersList?.push( option );
            }
        }
    }

    
    selectStatusesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
            let option = this.statusesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.statusesList?.push( option );
            }
        }
    }




    ////////////////////////
    ///////////////////////
    
    

    //////////////////////
    // Loading
    //////////////////////

    toggleLoading() : void {
	this.isLoading = !this.isLoading;
    }

    setLoading(loading : boolean) : void {
	this.isLoading = loading;
    }

    //////////////////////
    //////////////////////
    
    //////////////////////
    // Actions
    ////////////////////// 
    


  onAddNewShippingType(): void {
    this.router.navigate([environment.paths.NewMethodeExpeditionPath]);
  }

    onEditShippingType(id): void {
	console.log("shipping");
      this.router.navigate([environment.paths.EditerMethodeExpeditionPath, id]);
  }

  onConsulterShippingType(id): void {
      this.router.navigate([environment.paths.ConsulterMethodeExpeditionPath, id]);
  }

  onDelete(id): void {
    const dialogRef = this.dialog.open(ConfirmerSuppressionDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
	  console.log(id);;


	  this.shippingTypeService.deleteById(id).subscribe(
	      {
		  next: (response: Response<number, ShippingType>) => {
		      console.log(response);
		      this.refresh();
		  },
		  error: (e) => {
		      console.log(e);
		  },
		  complete: () => console.info('delete complete')
	      }
	  );
      }
    });
  }
}
