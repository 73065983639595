<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">

           <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">LISTE DES UTILISATEURS</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Contacts</a></li>
                    <li class="breadcrumb-item active">Liste utilisateurs</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 30px;">


        <button  class="btn btn-primary btn-sm" (click)="onAddNewUser()">Ajouter un utilisateur</button>
        <div class="table-responsive mt-2">

	    <div fxLayout fxLayoutAlign="end center">
		<mat-form-field fxFlex="40%">
		    <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filtrer">
		</mat-form-field>
	    </div>

            <table mat-table [dataSource]="datasource" matSort matSortStart="desc">
		   
                <ng-container matColumnDef="#">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.background-color]="color">#</th>
                    <td style="width: 4%;" mat-cell *matCellDef="let element">
                        <img class="bg-image"
                            src="https://office-plus.fr/wp-content/uploads/2020/05/Logo-Office-plus.png" />
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="firstname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.background-color]="color"> Nom</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <h5 class="table-username">{{element.lastname}} {{element.firstname}}</h5>
                        <h6 class="table-subusername">{{element.email}}</h6>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.background-color]="color"> Email</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.email}}</span>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="role.name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.background-color]="color"> Roles</th>
                    <td mat-cell *matCellDef="let element">
			<span *ngFor="let role of element.user?.roles"  class="badge badge-pill badge-primary">{{role.name}}</span>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="permission.name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.background-color]="color"> Permissions</th>
                    <td mat-cell *matCellDef="let element">
			<span *ngFor="let permission of element.user?.permissions"  class="badge badge-pill badge-info">{{permission.name}}</span>
                        
                    </td>
                </ng-container>

		<!--<ng-container matColumnDef="profileType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Type</th>
                    <td style="width: auto;" mat-cell *matCellDef="let element">
                        <span *ngIf="element.school" class="table-regular">
			    <span class="badge badge-pill badge-success"> Charge d\'ecole </span>
			</span>
			<span *ngIf="element.customer" class="table-regular">
			    <span class="badge badge-pill badge-warning">Client</span>
			</span>
			<span *ngIf="!element.school && !element.customer" class="table-regular">
			    <span class="badge badge-pill badge-warning">Employ�</span>
			</span>

                    </td>
                </ng-container>
		-->
                <ng-container matColumnDef="phoneNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.background-color]="color"> Numero</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.phoneNumber}}</span>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.background-color]="color"> Action</th>
                    <td mat-cell *matCellDef="let element">
                        <!--<mat-icon matTooltip="voir les messages" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="comment"></mat-icon>
                        <mat-icon matTooltip="consulter le profil"  (click)="onConsulterProfil()" class="icon" aria-hidden="false"
                            aria-label="Example home icon" fontIcon="account_circle"></mat-icon>
			-->
			<mat-icon matTooltip="consulter"  (click)="onConsulterProfil(element.id)" class="icon" aria-hidden="false" aria-label="Example home icon"
                            fontIcon="preview"></mat-icon>
                        <mat-icon matTooltip="editer" (click)="onEditProfil(element.id)" class="icon" aria-hidden="false" aria-label="Example home icon"
                            fontIcon="edit"></mat-icon>
                        <mat-icon matTooltip="supprimer" (click)="onDelete(element.id)" class="icon" aria-hidden="false" aria-label="Example home icon"
                            fontIcon="delete"></mat-icon>
                    </td>
                </ng-container>
    
    
    
                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;" class="example-element-row"></tr>
            </table>
    	    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
		<mat-progress-spinner 
		    color="primary" 
		    mode="indeterminate">
		</mat-progress-spinner>
	    </mat-card>
	    
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="pageSize" showFirstLastButtons
                aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>


    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
