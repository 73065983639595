import { Component, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { MatSnackBar } from '@angular/material/snack-bar';

import { UserService } from 'src/app/services/user.service';
import { UserModel } from 'src/app/interfaces/user';

import { Response } from '../interfaces/response';
import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'], encapsulation: ViewEncapsulation.None
})
export class LoginComponent {

  form: FormGroup;

    constructor(private router: Router, private appComponent: AppComponent,
		private _snackBar: MatSnackBar,
		private userService: UserService,
		public fb: FormBuilder) { 
        appComponent.hideMainMenu(true);
      }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)])
    });
  }

    //////////////////////   
    //    Login Data                            
    /////////////////////
    loginDataSave(data){
//      const user = data.user
        // token   
      const token = data.token;
        // user and profile                                             
        const username = data.username;   
        const profile = data.profile; 
        // relations
        
        const customer = data.customer;                                 
        
        const company = data.customer?.company;                         
        
        const companyGroup = data.customer?.company?.companyGroup;                          
        // set data
        if (token)   localStorage.setItem('token', JSON.stringify(token));
        if (username)    localStorage.setItem('user', JSON.stringify({username:username}));
        if (profile) localStorage.setItem('profile', JSON.stringify(profile));
        if (company) localStorage.setItem('company', JSON.stringify(company));
        if (companyGroup) localStorage.setItem('companyGroup', JSON.stringify(companyGroup));
        
  }
    
    //////////////////////   
    //    Submit data                            
    /////////////////////
    
  submit() {
    if (!this.form.valid) {
      return;
    }

      const user: UserModel = {
	    username : this.form.value.email,
	    password :this.form.value.password 
	};

      this.userService.authenticate(user).subscribe({
	  next: (res) => {
	      console.log(res);
	      if (res.IsSuccess == 0){
		  this.loginFaillure();
	      } else {
		  console.log(res);
		  this.loginDataSave(res.Data);
		  const profile = JSON.parse(localStorage['profile']);
		  console.log(profile);
		  const token = JSON.parse(localStorage['token']);
		  
		  console.log(token);
		  
		  // token = res.Data.token ;
		  //save token here
		  this.loginSuccess();
	      }
	  },
	  error: (error) => {
	      console.log(error);
	      console.log("error ins ervice");
	      this.loginFaillure();
	  },
	  complete: () => {
		console.info('Connexion success')
		this._snackBar.open('Connexion reussie', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			
			// save token here 
			this.loginSuccess();
		    }, 5000);
		
	    }
	    
	    
	});

  }

    ///////////////////////////
    // Success / Faillure
    ////////////////////////////

    loginSuccess(){
      this.appComponent.hideMainMenu(false);
      this.appComponent.loadMenuHeader();
      this.router.navigate(['/list/orders']).then(() => { window.location.reload();}); 
//      this.router.navigate(['dashboard']);
  }
    
    loginFaillure(){
      this._snackBar.open('l\'Authentification a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
  }
}
