<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">ÉDITER MÉTHODE DE PAIEMENT</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Livraisons</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">


        <h3 style="color: black; font-size:medium">Méthode de paiement</h3>
        <h6 style="color: black; font-size: small">Éditer une méthode de paiement</h6>
        <form class="example-form" [formGroup]="form" (ngSubmit)="submit()" *ngIf="_paymentType"> 
            <mat-label>Nom</mat-label>
            <input type="text" matInput formControlName="name"  [(ngModel)]="_paymentType.name"  class="form-control form-control-sm mb-2" required>
	    <app-show-errors [ctrl]="form.get('name')"></app-show-errors>

	    <mat-label>Label</mat-label>
            <input type="text" matInput formControlName="label"  [(ngModel)]="_paymentType.label"  class="form-control form-control-sm mb-2" required>
	    <app-show-errors [ctrl]="form.get('label')"></app-show-errors>

            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Description</mat-label>
            		<textarea style="height: 50px;" matInput placeholder="Description" formControlName="description"
			   [(ngModel)]="_paymentType.description" 
				  required></textarea>
			<app-show-errors [ctrl]="form.get('description')"></app-show-errors>
            </mat-form-field>

            <mat-label>Commandes</mat-label>
            <div class="input-group">
		
		<mat-select placeholder="Choisir les categories" class="  form-control form-control-sm mb-2"   required
			    [(ngModel)]="selectedOrders" [formControl]="ordersListCtrl"  [compareWith]="compareFn" (selectionChange)="onOrdersChanged($event)"  >
			<input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyOrders($event.target.value)">  
                      <mat-select-trigger>
                        {{selectedOrder?.name  || ''}}
                        <span *ngIf="(selectedOrders?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedOrders?.length || 0) - 1}} {{selectedOrders?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let order of ordersList" [value]="order">{{order.name}}</mat-option>
		    </mat-select>
                <div class="input-group-append">
                    <button type="button"class="btn btn-primary  form-control-sm" >
                      <mat-icon>add</mat-icon>
                    </button>
                  </div>
            </div>

            <mat-label>Paiements</mat-label>
            <div class="input-group">
		<mat-select placeholder="Choisir les paiements pour  ce type de paiements"
			    class="  form-control form-control-sm mb-2"
			    [formControl]="paymentsListCtrl" 
			    [(ngModel)]="selectedPayments"
			    [compareWith]="compareFn"
			    (selectionChange)="onPaymentsChanged($event)"
			    multiple required >
		    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyPayments($event.target.value)"> 
		    <mat-select-trigger>
			{{paymentsListCtrl.value?.[0]?.name  || ''}} 
                        <span *ngIf="(paymentsList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedPayments?.length || 0) - 1}} {{selectedPayments?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let payment of paymentsList" [value]="payment">{{payment.name}}</mat-option>
		</mat-select>
                <div class="input-group-append">
                    <button type="button"class="btn btn-primary  form-control-sm">
                      <mat-icon>add</mat-icon>
                    </button>
                  </div>
            </div>

            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Note</mat-label>
		<textarea style="height: 50px;" matInput placeholder="note" formControlName="note"
			  [ngModel]="_paymentType && _paymentType.note && _paymentType.note.content"
			  (ngModelChange)="_paymentType && _paymentType.note && _paymentType.note.content  = $event"
			  required></textarea>
		<app-show-errors [ctrl]="form.get('note')"></app-show-errors>
            </mat-form-field>

            <mat-label>Status</mat-label>
            <div class="input-group">
		<mat-select placeholder="Choisir un statut pour ce type de produit" class="  form-control form-control-sm mb-2"   required
			    [(ngModel)]="selectedStatus" [formControl]="statusesListCtrl"  [compareWith]="compareFn" (selectionChange)="onStatusesChanged($event)"  >
		    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyStatuses($event.target.value)">  
                    <mat-select-trigger>
                        {{selectedStatus?.label  || ''}}
                        <span *ngIf="(selectedStatuses?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedStatuses?.length || 0) - 1}} {{selectedStatuses?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
			</mat-select>
            </div>

            <div class="example-full-width d-flex justify-content-center" *ngIf="DisplayConfirmationButton">
                <button class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>
                
                <!-- <button mat-raised-button cdkFocusInitial>Annuler</button> -->
            </div>



        </form>




    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
