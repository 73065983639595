<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">CONSULTER GROUPE DE SOCIÉTÉS</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Clients</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;"  *ngIf="_companyGroup">
    <div class="container-fluid" style="padding: 30px;">

        <div class="d-flex justify-content-end">
            <button class="btn btn-primary btn-sm" (click)="onEditCompanyGroup(_companyGroup.id)" >Editer le groupe de sociétés </button>
        </div>

        <label>Voir groupe de sociétés</label>

	<mat-stepper orientation="horizontal">

            <mat-step>
		
		
		<ng-template matStepLabel><mat-icon>local_shipping</mat-icon> Information du groupe </ng-template>
		<ng-template matStepContent>
		    
		    <h6 style="color: black; font-size: small">Voir les informations du groupe ci-dessous</h6>

		    <div class="lateral-space">
			
			<div class="row mt-3 w-100 left-background">
			    <div class="col-6 left">
				Nom du groupe
			    </div>
			    <div class="col-6 right normal">
				{{_companyGroup?.name}}     
			    </div>
			</div>
			<div class="row w-100">
			    <div class="col-6 left">
				Label
			    </div>
			    <div class="col-6 right normal">
				{{_companyGroup?.label}}     
			    </div>
			</div>
			<div class="row w-100 left-background">
			    <div class="col-6 left">
				Description
			    </div>
			    <div class="col-6 right normal">
				{{_companyGroup?.description}}     
			    </div>
			</div>
			<div class="row w-100">
			    <div class="col-6 left">
				Sociétés
			    </div>
			    <div class="col-6 right normal">
				<span *ngFor="let company of _companyGroup.companies ; nb_companies as index;let i = index"   class="badge  badge-info">
				    <span *ngIf="i < 3 || more_companies">{{company?.name}}</span>
				</span>
				<mat-divider></mat-divider>
				<button mat-button  color="primary" *ngIf="_companyGroup?.companies?.length > 3" (click)="more_companies = !more_companies">{{ more_companies ? 'voir moins' : 'voir plus' }}</button>
				
				<!--		    <span *ngIf="_companyGroup.companies && _companyGroup.companies?.length > 0">
				     <div class="col-6 right"  *ngIf="3 as maxNbCompanies">
				     <a  [routerLink]="" (click)="show_companies = !show_companies" >Voir la liste de sociétés</a>

				     <span *ngIf="show_companies;">
				     <span *ngFor="let company of _companyGroup?.companies;nb_companies as index;let i = index;let c = count">

				     <span *ngIf="i < maxNbCompanies || more_companies;  "  class="badge badge-pill badge-info"> {{company?.name}}</span>
				     <button mat-button  color="primary" *ngIf="i  + 1== c && c > maxNbCompanies" (click)="more_companies = !more_companies">{{ more_companies ? 'voir moins' : 'voir plus' }}</button> 
				     </span>
				     </span>
				     </div>
				     </span>
				-->
			    </div>
			</div>
		    </div>




		    <label class="mt-5">Informations complémentaires</label>
		    <div class="lateral-space">
			
			<div class="row mt-3 w-100 left-background">
			    <div class="col-6 left">
				Créé le {{_companyGroup?.objectDetail?.creationDate |  date:'dd/MM/yyyy' }}  
			    </div>
			    <div class="col-6 right normal">
				Modifié le {{_companyGroup?.objectDetail?.modificationDate |  date:'dd/MM/yyyy' }}  
			    </div>
			</div>
			<div class="row w-100">
			    <div class="col-6 left">
				Créé par
			    </div>
			    <div class="col-6 right link">
				{{_companyGroup?.objectDetail?.creator?.username}}   
			    </div>
			</div>
			<div class="row w-100 left-background">
			    <div class="col-6 left">
				Modifié par
			    </div>
			    <div class="col-6 right link">
				{{_companyGroup?.objectDetail?.updater?.username}}   
			    </div>
			</div>
			<div class="row w-100">
			    <div class="col-6 left">
				Status
			    </div>
			    <div class="col-6 right link d-flex justify-content-center">
				<span class="badge badge-pill badge-primary">{{_companyGroup?.status?.label}}  </span>
			    </div>
			</div>
		    </div>

		</ng-template>
	    </mat-step>

	    <mat-step >
                <ng-template matStepLabel><mat-icon>fact_check</mat-icon> Produits du groupe</ng-template>
		
		<!--
		     <div   class="row mt-5 top-right" layout="row" layout-align=" end">
		     <div  layout="column" layout-align="center end" >
		     <div><button mat-raised-button matStepperPrevious color="primary" class="mr-1" type="button">Précédent</button></div>
		     <div><button mat-raised-button matStepperNext color="primary" type="button">Suivant</button></div>
		     </div>
		     </div>
		-->

		<h6 style="color: black; font-size: small">Voir les produits du groupe ci-dessous</h6>
		
                <div class="table-responsive">
		    <div fxLayout="row" fxLayoutAlign="end center">
			<mat-form-field fxFlex="60%">
			    <input matInput type="text" (keyup)="doFilter($event.target.value)"  name="filter" placeholder="Filtrer">
			</mat-form-field>
		    </div>

                    <table mat-table [dataSource]="datasourceSelection" matSort>
			
                        <ng-container matColumnDef="#">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">
                                
                            </th>
                            <td style="width: 4%;" mat-cell *matCellDef="let element">
                                
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="image">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Image</th>
                            <td style="width: 15%;" mat-cell *matCellDef="let element">
                                <img class="bg-image" src="{{element?.img}}" *ngIf="element && element.img" />
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="product.name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Nom</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span>{{element?.product?.name}}</span>
                            </td>
                        </ng-container>

			<ng-container matColumnDef="product.description">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Description</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span>{{element?.product?.description}}</span>
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="product.price">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Prix original</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span class="table-regular">{{element?.product?.price}} </span>
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="price">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Prix</th>
                            <td style="width: 120px;" mat-cell *matCellDef="let element">
                                <!-- <input matInput maxlength="5" type="number" placeholder="0" [(ngModel)]="element.qty"  class="form-control form-control-sm" required> -->
                                <mat-form-field appearance="outline" floatLabel="always">
                                    <input matInput disabled [style.color]="black" type="number" class="example-right-align" [(ngModel)]="element.price"  placeholder="0">
                                </mat-form-field>
                            </td>
                        </ng-container>
						
			
			
                        <ng-container matColumnDef="Action">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-icon matTooltip="consulter" (click)="onConsulterProduct(element.productId)" class="icon" aria-hidden="false"
					  aria-label="Example home icon" fontIcon="preview"></mat-icon>
                            </td>
                        </ng-container>
			
			
			
                        <tr mat-header-row *matHeaderRowDef="columnsSelection"></tr>
                        <tr mat-row *matRowDef="let row; columns: columnsSelection;" class="example-element-row"></tr>
                    </table>
		    
		    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
			<mat-progress-spinner 
			    color="primary" 
			    mode="indeterminate">
			</mat-progress-spinner>
		    </mat-card>
		    
                    <mat-paginator [pageSizeOptions]="[20, 50, 100, 500]" [pageSize]="pageSize" showFirstLastButtons
				   aria-label="Select page of periodic elements">
                    </mat-paginator>
                </div>


		<button mat-raised-button matStepperPrevious color="primary" class="mr-1" type="button">Précédent</button>

            </mat-step>



	</mat-stepper>

    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
