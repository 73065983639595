import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


import { Role } from '../interfaces/role';
import { User } from '../interfaces/user';
import { Permission } from '../interfaces/permission';

import {UserService} from '../services/user.service'

import { UserPermission } from '../interfaces/user';
import { RolePermission } from '../interfaces/role';

import { Response as Rep } from '../interfaces/response';

import { of, from,  map, Observable, exhaustMap, EMPTY, empty ,identity, forkJoin} from 'rxjs';
import { mergeMap, mergeAll, toArray, switchMap} from 'rxjs/operators';

import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"

@Injectable({
  providedIn: 'root'
})
export class PermissionService extends BaseCrudModel<Permission>{

    postUsers = {url: `${environment.domains.api.url}/user-permissions/all`, urlOptions: this.options};
    deleteUsers = {url: `${environment.domains.api.url}/user-permissions`, urlOptions: this.options};
    postRoles = {url: `${environment.domains.api.url}/role-permissions/all`, urlOptions: this.options};
    deleteRoles = {url: `${environment.domains.api.url}/role-permissions`, urlOptions: this.options};
    
    constructor(protected override httpService: HttpService) {
	super(httpService, "permissions");

	this.methods.listing.urlOptions =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "roles"},
		{"relation" : "users" , "scope" : {"include" : [{"relation" : "profile"}]}},
	    ],
	});

	this.methods.view.urlOptions =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "module"},
		{"relation" : "objectDetail"},
		{"relation" : "roles"},
		{"relation" : "users", "scope" : {"include" : [{"relation" : "profile"}]}},
	    ],
	});

	var addOptions =  RequestOptions.FromArray({
	    relationsToOmit : ['users', 'roles']
	});
	

	this.methods.add.urlOptions = addOptions;
	this.methods.update.urlOptions = addOptions;
    }

    getAllPermissions(): Observable<Rep<number, Permission[]>> {
	const options = new RequestOptions();

	return this.getAll()
    }

    //////////////////////////
    // HANDLE || PREPARE
    //////////////////////////
    
    override handlePostRelations(permission): Observable<any> {
	console.log(permission);
	console.log("handlePostRelations - Permissions");
	    return forkJoin
	(
	    // Remove elements if empty
	    permission && permission.id && (permission.users && permission.users?.length == 0) ? this.removeUsers(permission.id) :
		this.httpService.createObservableResponse<UserPermission[]>({IsSuccess:  0, Data:  undefined, Status : 0}),
	    permission && permission.id && (permission.roles && permission.roles?.length == 0) ? this.removeRoles(permission.id) :
		this.httpService.createObservableResponse<RolePermission[]>({IsSuccess:  0, Data:  undefined, Status : 0}),

	    // add new elements 
	    permission && permission.id && permission.users && permission.users?.length > 0 ? this.addUsers(permission.id, permission.users) :
		this.httpService.createObservableResponse<UserPermission[]>({IsSuccess:  0, Data:  undefined, Status : 0}),
	    permission && permission.id  && permission.roles && permission.roles?.length > 0 ? this.addRoles(permission.id, permission.roles) :
		this.httpService.createObservableResponse<RolePermission[]>({IsSuccess:  0, Data:  undefined, Status : 0})
	)
    }

    //////////////////////////
    // Add Other objects
    //////////////////////////

    public removeUsers(permissionId):  Observable<Rep<number, any>> {
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		{"permissionId" : permissionId},
	    ],
	});
	options.bodyParams = {}
	return this.httpService.makeDeleteRequest<any>( `${this.deleteUsers.url}`, options);
//	return  this.httpService.createObservableResponse<UserPermission[]>({IsSuccess:  0, Data:  undefined, Status : 0});
    }

    public removeRoles(permissionId):  Observable<Rep<number, any>> {
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		{"permissionId" : permissionId},
	    ],
	});
	options.bodyParams = {}
	return this.httpService.makeDeleteRequest<any>( `${this.deleteRoles.url}`, options);

    }

    //////////////////////////
    // Add Other objects
    //////////////////////////

    public addUsers(permissionId, users):  Observable<Rep<number, any>> {
	const options =  new RequestOptions();
 	
	const arrayUsers = users?.map((r: any): UserPermission => ({permissionId : permissionId, userId: r.id}));

	console.log(arrayUsers);
	options.body = arrayUsers;	

	return arrayUsers && arrayUsers.length > 0 ?
	    this.httpService.makePostRequest<UserPermission[]>( `${this.postUsers.url}`, options) :
	    this.httpService.createObservableResponse<UserPermission[]>({IsSuccess:  0, Data:  undefined, Status : 0});
	//Observable.empty<number, any>() ;
    }

    public addRoles(permissionId, roles):  Observable<Rep<number, any>> {
	const options =  new RequestOptions();
	
	const arrayRoles = roles?.map((r: any): RolePermission => ({permissionId : permissionId, roleId: r.id}));

	console.log(arrayRoles);
	options.body = arrayRoles;	

	return arrayRoles && arrayRoles.length > 0 ?
	    this.httpService.makePostRequest<RolePermission[]>( `${this.postRoles.url}`, options) :
	    this.httpService.createObservableResponse<RolePermission[]>({IsSuccess:  0, Data:  undefined, Status : 0});
    }
}
