import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';        

// models
import { ShippingType } from '../interfaces/shipping-type';
import { Shipping } from '../interfaces/shipping';
import { Note } from '../interfaces/note';

// services
import {NoteService} from '../services/note.service'

// rxjs
import { of, from,  map, Observable, exhaustMap, EMPTY, empty ,identity, forkJoin} from 'rxjs';
import { mergeMap, mergeAll, toArray, switchMap} from 'rxjs/operators';

// response   
import { Response as Rep } from '../interfaces/response';

import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"


@Injectable({
  providedIn: 'root'
})
export class ShippingTypeService extends BaseCrudModel<ShippingType>{

    postShippings = {url: `${environment.domains.api.url}/item-types`, urlOptions: this.options};
    deleteShippings = {url: `${environment.domains.api.url}/item-types`, urlOptions: this.options};
    
    constructor(protected override httpService: HttpService,
	       private noteService : NoteService) {
	super(httpService, "shipping-types");

	var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "objectDetail"},
		{"relation" : "note"},
		//{"relation" : "shippings"},
		//{"relation" : "familyType"},
	    ],
	});

	var addOptions =  RequestOptions.FromArray({
	    relationsToOmit : ['note', 'status', 'shippings', 'familyType']
	});

	this.methods.listing.urlOptions = options;
	this.methods.view.urlOptions = options;
	this.methods.add.urlOptions = addOptions;
	this.methods.update.urlOptions = addOptions;
    }

    getAllShippingTypes(): Observable<Rep<number, ShippingType[]>> {
	const options = new RequestOptions();

	return this.getAll()
    }

    ////////////////////////////////
    // PREPARE || HANDLE Relations
    ////////////////////////////////
    
    override prepareRelations(fromShippingType): Observable<Rep<number, ShippingType>> { //Observable<Profile> {
	// values to save
	const shippingType : ShippingType = fromShippingType;


	// Obs Functions
	const failObj : Rep<number, Note> = {IsSuccess:  0, Data:  null, Status : 0};

	const a$ = of();

	const note$ : Observable<Rep<number, Note>> = shippingType.note ? 
	    ( shippingType.note.id ? this.noteService.updateById(fromShippingType.note.id, fromShippingType.note) : this.noteService.add(fromShippingType.note) )
	    :  this.httpService.createObservableResponse<Note>(failObj);

	
	return note$
	    .pipe(
		map(note => 
		    {
			console.log("created note");
			console.log(note);
			console.log("before setting Note");
			console.log(shippingType);
			(shippingType.noteId = note && note.IsSuccess ?
			    (note.Data && note.Data.id ? note.Data.id : shippingType.noteId) : shippingType.noteId );
			
			return   this.httpService.createResponseFromObj<ShippingType>(shippingType)
		    }),
		map(rel =>
		    {
			console.log("final result");
			console.log(rel);
			// return profile
			return    this.httpService.createResponseFromObj<ShippingType>(shippingType) ;
		    }),
		
	    );
    }

    //////////////////////////
    // HANDLE || PREPARE
    //////////////////////////
/*
    override handleRemoveRelations(shippingType): Observable<any> {
	console.log("removePostRelations for shippingType : \n {0}".format(JSON.stringify(shippingType)));

	return forkJoin
	(
	    // Remove elements if empty
	    shippingType && shippingType.id && (shippingType.shippings && shippingType.shippings?.length >= 0) ? this.removeShippings(shippingType.id) :
		this.httpService.createObservableResponse<Shipping[]>({IsSuccess:  0, Data:  undefined, Status : 0})

	)
    }
    
    override handlePostRelations(shippingType): Observable<any> {
	console.log(shippingType);
	console.log("handlePostRelations");
	    return forkJoin
	(
	    
	    // add new elements 
	    shippingType && shippingType.id && shippingType.shippings && shippingType.shippings?.length > 0 ? this.addShippings(shippingType.id, shippingType.shippings) :
		this.httpService.createObservableResponse<Shipping[]>({IsSuccess:  0, Data:  undefined, Status : 0})
	)
    }
*/
    
    //////////////////////////
    // Add Other objects
    //////////////////////////

    public removeShippings(shippingTypeId):  Observable<Rep<number, any>> {
	console.log("remove shipping for shippingTypeId:{shippingTypeId}".formatUnicorn({shippingTypeId : shippingTypeId}));
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		//{"shippingTypeId" : shippingTypeId},
		{"shippingTypeId" : {"regexp" : "/^{shippingTypeId}$/".formatUnicorn({shippingTypeId: shippingTypeId})}},
	    ],
	});
	options.body = {"shippingTypeId" : ""}
	return this.httpService.makePatchRequest<any>( `${this.deleteShippings.url}`, options);
//	return  this.httpService.createObservableResponse<ShippingShippingType[]>({IsSuccess:  0, Data:  undefined, Status : 0});
    }


    //////////////////////////
    // Add Other objects
    //////////////////////////

    public addShippings(shippingTypeId, shippings):  Observable<Rep<number, any>> {
	
 	
	//const arrayShippings = shippings?.map((r: any): Shipping => ({shippingTypeId : shippingTypeId, shippingId: r.id}));

	const arrayShippings = shippings?.map((r: Shipping): any => (r.id));

	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		{"id" : {"inq" : arrayShippings}},
	    ],
	});
	console.log("Array shippings");
	console.log(arrayShippings);
	options.body =  {"shippingTypeId" : shippingTypeId} ;//arrayShippings;	

	return arrayShippings && arrayShippings.length > 0 ?
	    this.httpService.makePatchRequest<Shipping[]>( `${this.postShippings.url}`.format(), options) :
	    this.httpService.createObservableResponse<Shipping[]>({IsSuccess:  0, Data:  undefined, Status : 0});
	//Observable.empty<number, any>() ;
    }
}
