import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { UserService } from 'src/app/services/user.service';
import { ProfileService } from 'src/app/services/profile.service';
import { RolesService } from 'src/app/services/roles.service';
import { PermissionService } from 'src/app/services/permission.service';
import { ModuleService } from 'src/app/services/module.service';

import { Role } from 'src/app/interfaces/role';
import { Permission } from 'src/app/interfaces/permission';
import { Profil } from 'src/app/interfaces/profil';
import { UserModel } from 'src/app/interfaces/user';
import { Module } from 'src/app/interfaces/module';

import { Response } from '../../interfaces/response';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-new-permission',
  templateUrl: './new-permission.component.html',
  styleUrls: ['./new-permission.component.scss'], encapsulation: ViewEncapsulation.None
})
export class NewPermissionComponent  implements OnInit  {
    
    @Input() TitleVisible: boolean = true;
    @Input() DisplayConfirmationButton: boolean = true;

    form: FormGroup;
    
    ////////////////////////
    // Attributes
    ///////////////////////

    
    utilisateurs: any;
    roles: any;

    usersListCtrl = new FormControl('');
    usersData : UserModel[];//string[];
    usersList : UserModel[];//string[];
    selectedUsers : UserModel[];

    rolesListCtrl = new FormControl('');
    rolesList : Role[];//string[];
    rolesData : Role[];//string[];
    selectedRoles : Role[];


    modulesListCtrl = new FormControl('');
    modulesList : Module[];//string[];
    modulesData : Module[];//string[];
    selectedModule : Module;


        
    ////////////////////////
    // Constructor
    ///////////////////////

    
    constructor(private router: Router,
		private roleService: RolesService,
		private userService: UserService,
		private moduleService: ModuleService,
		private permissionService: PermissionService,
		private profileService : ProfileService,

      private _snackBar: MatSnackBar,
      public fb: FormBuilder) { 
 
      }

            
    ////////////////////////
    // Init
    ///////////////////////
    
    ngOnInit(): void {

	
	this.refresh();
	this.initForm();
    }

    ////////////////////////
    // Datasrouces
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    name: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    label: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),

	    description: new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(2)]),
	});

    }

    ////////////////////////
    // Datasrouces
    ///////////////////////

    refresh(): void {
	
	this.userService.getAllUsers().subscribe({
            next: (response: Response<number, UserModel[]>) => {
		this.usersData = response.Data;
		this.initUsersSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load users complete')
	});

	this.roleService.getAllRoles().subscribe({
	    next: (response: Response<number, Role[]>) => {
		this.rolesData = response.Data;
		this.initRolesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load roles complete')
	});

	this.moduleService.getAllModules().subscribe({
	    next: (response: Response<number, Module[]>) => {
		this.modulesData = response.Data;
		this.initModulesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load modules complete')
	});

    }

    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns
    
    initRolesSearchDropdown(){
	this.rolesList = [];
        for ( let i = 0 ; i < this.rolesData.length; i ++ ) {
            this.rolesList.push( this.rolesData[i] );
        }
    }

    initModulesSearchDropdown(){
	this.modulesList = [];
        for ( let i = 0 ; i < this.modulesData.length; i ++ ) {
            this.modulesList.push( this.modulesData[i] );
        }
    }

    
    initUsersSearchDropdown(){
	this.usersList = [];
        for ( let i = 0 ; i < this.usersData.length; i ++ ) {
            this.usersList.push( this.usersData[i] );
        }
    }

    ////////////////////////
    // On Search

    onKeyUsers(value) { 
            this.usersList= []; 
            this.selectUserSearch(value);       
    }

    onKeyRoles(value) { 
            this.rolesList= []; 
            this.selectRolesSearch(value);       
    }

    onKeyModules(value) { 
            this.modulesList= []; 
            this.selectModulesSearch(value);       
    }

    ////////////////////////
    // search elements

    selectModulesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.modulesData.length; i ++ ) {
            let option = this.modulesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.modulesList?.push( option );
            }
        }
    }

    selectRolesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.rolesData.length; i ++ ) {
            let option = this.rolesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.rolesList?.push( option );
            }
        }
    }

    selectUserSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.usersData.length; i ++ ) {
            let option = this.usersData[i];
            if (  option.username.toLowerCase().indexOf(filter) >= 0
		|| option.profile?.firstname?.toLowerCase().indexOf(filter) >= 0 
		|| option.profile?.lastname?.toLowerCase().indexOf(filter) >= 0  ) {
                this.usersList?.push( option );
            }
        }
    }


    ////////////////////////
    ///////////////////////
    
    
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid) {
	    return;
	}
	let permission : Permission = {
            name: this.form.value.name,
            label: this.form.value.label,
            description: this.form.value.description,
//        usersphoneNumber: this.form.value.users
	};
	permission.users = this.selectedUsers;
	permission.roles = this.selectedRoles;
	if (this.selectedModule)
	    permission.moduleId = this.selectedModule.id;
	console.log(permission);
	console.log(this.selectedUsers);
	console.log(this.selectedRoles);
	console.log(this.selectedModule);
	console.log("gonna save");

	this.permissionService.prepareAddRelations(permission).pipe(
	    switchMap(preparedPermission => { 
		console.log("entered switch map -- add new permission ");
		console.log(preparedPermission);
		return this.permissionService.add(permission);}),
	    switchMap(permissionAddedObservable => {
		let permissionAdded = permissionAddedObservable.Data;
		    
		console.log("entered switch map -- handle post relations ");
		console.log(permissionAdded);
		permissionAdded.users = this.selectedUsers;
		permissionAdded.roles = this.selectedRoles;
		return this.permissionService.handlePostAddRelations(permissionAdded); 
	    })
	
						      
	).subscribe({
	    next: (res) => { console.log(res); },
	    error: (error) => {console.log(error)},
	    complete: () => {
		console.info('save permission pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.router.navigate([environment.paths.ListPermissionPath]);
		    }, 5000);
		
	    }
	    
	    
	});

	


    }

    
    /*
    this.form = this.fb.group({
      nom: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
      prenom: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
      phone: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$"), Validators.minLength(10)]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
      passwordcheck: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
      street: new FormControl(null, [Validators.required, Validators.maxLength(5), Validators.pattern("^[0-9]*$"), Validators.minLength(1)]),
      city: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
      postal: new FormControl(null, [Validators.required, Validators.maxLength(5),  Validators.pattern("^[0-9]*$"), Validators.minLength(5)])
    });
  }

    
  submit() {
    if (!this.form.valid) {
      return;
    }
    }*/
    
}
