import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Note } from '../interfaces/note';
import { Response as Rep } from '../interfaces/response';

import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"

@Injectable({
  providedIn: 'root'
})
export class NoteService extends BaseCrudModel<Note>{

    constructor(protected override httpService: HttpService) {
	super(httpService, "notes");
    }

    getAllNotes(): Observable<Rep<number, Note[]>> {
	const options = new RequestOptions();

	return this.getAll()
    }
}
