

import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';


import { Delivery } from 'src/app/interfaces/delivery';
import { PaymentType } from 'src/app/interfaces/payment-type';
import { Customer } from 'src/app/interfaces/customer';
import { Status } from 'src/app/interfaces/status';

import { DeliveryService } from 'src/app/services/delivery.service';
import { PaymentTypeService } from 'src/app/services/payment-type.service';
import { CustomerService } from 'src/app/services/customer.service';
import { StatusService } from 'src/app/services/status.service';

import { environment } from 'src/environments/environment';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Response } from '../../interfaces/response';
import { Router } from '@angular/router';

import { Observable, forkJoin } from 'rxjs';

import { MatSort } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout'; 
import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@Component({
  selector: 'app-list-delivery',
  templateUrl: './list-delivery.component.html',
  styleUrls: ['./list-delivery.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ListDeliveryComponent implements OnInit, AfterViewInit {

    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
    @ViewChild(MatPaginator) paginator: MatPaginator;

    
    //////////////////////
    // Attributes   
    ////////////////////// 
    columns: string[] = ['#', 'NumExp', 'NomClient', 'Adresse', 'DateLivraison', 'Etat', 'NumCommand', 'Action' ];
    // columns: string[] = ['#', 'NumExp', 'NomClient', 'Adresse', 'DateRetrait', 'Etat', 'NumCommand', 'Action' ];   
    //    columns: string[] = ['#', 'deliveryNumber', 'customer.name', 'date', 'total', 'paymentStatus', 'paymentMean', 'Action' ];
    //    columns: string[] = ['#', 'mainImage', 'name', 'sku', 'price', 'stock', 'paymentType', 'Action' ];
    datasource: MatTableDataSource<Delivery> = new MatTableDataSource<Delivery>();
    pageSize = 10;

    //images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);
    images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);
    
    deliverys: Delivery[];

    customersListCtrl = new FormControl('');
    customersData : Customer[];//string[];
    customersList : Customer[];//string[];
    selectedCustomers : Customer[];

    paymentTypesListCtrl = new FormControl('');
    paymentTypesList : PaymentType[];//string[];
    paymentTypesData : PaymentType[];//string[];
    selectedPaymentTypes : PaymentType[];
    
    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatus : Status;

    paymentStatusesListCtrl = new FormControl('');
    paymentStatusesList : Status[];//string[];
    paymentStatusesData : Status[];//string[];
    selectedPaymentStatus : Status;
    
    isLoading = true;

    //////////////////////
    // Constructor       
     //////////////////////   

    constructor(private deliveryService: DeliveryService,
		private customerService: CustomerService,
		
		private paymentTypeService : PaymentTypeService,
		private statusService : StatusService,
		private router: Router,
		public dialog: MatDialog) {
      
  }

    
    //////////////////////
    // INIT
    //////////////////////
    
    ngAfterViewInit() {
	this.datasource.paginator = this.paginator;
    }

    ngOnInit(): void {
	this.refresh();
    }


    //////////////////////
    // TOOLS
    //////////////////////

    getUrlOf(element) : string {
	var path =  element.path ? element.path.replace(/^public?\//i, "") : null;
	return element && element.path ? `${environment.domains.api.url}${path}` : "";
    }
    
    //////////////////////
    // Data sources
    //////////////////////

    doFilter(value: string) : void  {
	this.datasource.filter = value.trim().toLocaleLowerCase();
    }

    
    refresh(): void {
	this.setLoading(true);
	this.deliveryService.getAllDeliveriesWithOrders().subscribe(
	    {
		next: (response: Response<number, Delivery[]>) => {
		    this.deliverys = response.Data;
		    this.datasource.data = this.deliverys;
		    this.datasource.paginator = this.paginator;
		    this.setLoading(false);
		    console.log(this.deliverys);
		},
		error: (e) => {
		},
		complete: () => console.info('loadMenu complete')
	    }
	);
	this.paymentTypeService.getAll().subscribe({
	    next: (response: Response<number, PaymentType[]>) => {
		this.paymentTypesList = response.Data;
            },error: (e) => { }, complete: () => console.info('load paymentTypes complete')
	});
	this.customerService.getAllCustomers().subscribe({
	    next: (response: Response<number, Customer[]>) => {
		console.log(response);
		this.customersList = response.Data;
	    },error: (e) => { },complete: () => console.info('loadMenu complete')
	});

	this.statusService.getAll().subscribe({
	    next: (response: Response<number, Status[]>) => {
		this.statusesList = response.Data;
		this.paymentStatusesList = response.Data;
            },error: (e) => { }, complete: () => console.info('load status complete')
	});
    }

    //////////////////////
    // Loading
    /////////////////////

    toggleLoading() : void {
	this.isLoading = !this.isLoading;
    }

    setLoading(loading : boolean) : void {
	this.isLoading = loading;
    }

    //////////////////////
    //////////////////////

    

    //////////////////////
    // Actions
    //////////////////////

  onAddNewDelivery(): void {
    this.router.navigate([environment.paths.NewExpeditionPath]);
  }

  onEditDelivery(id): void {
      this.router.navigate([environment.paths.EditerExpeditionPath, id]);
  }

  onConsulterDelivery(id): void {
      this.router.navigate([environment.paths.ConsulterDeliveryPath,id]);
  }

  onDelete(id): void {
    const dialogRef = this.dialog.open(ConfirmerSuppressionDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
      	  this.deliveryService.deleteById(id).subscribe(
	      {
		  next: (response: Response<number, Delivery>) => {
		      console.log(response);
		      this.refresh();
		  },
		  error: (e) => {
		      console.log(e);
		  },
		  complete: () => console.info('delete complete')
	      }
	  );
      }
    });
  }






/*

    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
  
  columns: string[] = ['#', 'NumExp', 'NomClient', 'Adresse', 'DateLivraison', 'Etat', 'NumCommand', 'Action' ];
  datasource: MatTableDataSource<User> = new MatTableDataSource<User>();
  pageSize = 10;
  profiles: Profil[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  color="#f8f8fb";
  toppings = new FormControl('');

  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  
  constructor(private userService: UserService,
    private router: Router,
    public dialog: MatDialog) {

  }

  ngAfterViewInit() {
    this.datasource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.userService.getAllProfil().subscribe(
      {
        next: (response: Response<number, Profil[]>) => {
          this.profiles = response.Data;
          this.datasource.data = this.profiles;
          this.datasource.paginator = this.paginator;
        },
        error: (e) => {
        },
        complete: () => console.info('loadMenu complete')
      }
    );
  }

  onAddNewDelivery(): void {
    this.router.navigate([environment.paths.NewDeliveryPath]);
  }

  onEditDelivery(): void {
    this.router.navigate([environment.paths.EditerDeliveryPath]);
  }

  onConsulterDelivery(): void {
    this.router.navigate([environment.paths.ConsulterDeliveryPath]);
  }

  onDelete(): void {
    const dialogRef = this.dialog.open(ConfirmerSuppressionDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {

      }
    });
  }*/
}
