import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';
import { Profil } from 'src/app/interfaces/profil';
import { User } from 'src/app/interfaces/user';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { Response } from '../../interfaces/response';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-documents',
  templateUrl: './list-documents.component.html',
  styleUrls: ['./list-documents.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ListDocumentsComponent implements OnInit, AfterViewInit {
  @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
  
  columns: string[] = ['Selection', '#', 'img', 'Num', 'Dest', 'File', 'Signature', 'Type', 'Action' ];
  datasource: MatTableDataSource<User> = new MatTableDataSource<User>();
  pageSize = 10;
  profiles: Profil[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  color="#f8f8fb";
  toppings = new FormControl('');

  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  
  constructor(private userService: UserService,
    private router: Router,
    public dialog: MatDialog) {

  }

  ngAfterViewInit() {
    this.datasource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.userService.getAllProfil().subscribe(
      {
        next: (response: Response<number, Profil[]>) => {
          this.profiles = response.Data;
          this.datasource.data = this.profiles;
          this.datasource.paginator = this.paginator;
        },
        error: (e) => {
        },
        complete: () => console.info('loadMenu complete')
      }
    );
  }

  onAddNewDocument(): void {
    this.router.navigate([environment.paths.NewDocumentPath]);
  }

  onEditDocument(): void {
    this.router.navigate([environment.paths.EditerDocumentPath]);
  }

  onConsulterDocument(): void {
    this.router.navigate([environment.paths.ConsulterDocumentPath]);
  }

  onDelete(): void {
    const dialogRef = this.dialog.open(ConfirmerSuppressionDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {

      }
    });
  }
}