<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">VOIR CATÉGORIE DE PRODUIT</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Produits</a></li>
                    <li class="breadcrumb-item active">Catégorie</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;" *ngIf="_productCategory">
    <div class="container-fluid" style="padding: 30px;">

        <div class="d-flex justify-content-end">
            <button class="btn btn-primary btn-sm" (click)="onEditCategory(_productCategory.id)" >Editer la catégorie</button>
        </div>

        <label>Voir la catégorie</label>
        <div class="lateral-space">
   
            <div class="row mt-3 w-100 left-background">
                <div class="col-6 left">
                    Nom
                </div>
                <div class="col-6 right normal">
                     {{_productCategory?.name}} 
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Description
                </div>
                <div class="col-6 right normal">
                    {{_productCategory?.description}} 
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    Note
                </div>
                <div class="col-6 right normal">
                     {{_productCategory?.note?.content}}  
                </div>
            </div>
	    <div class="row w-100 left">
                <div class="col-6 left">
                    Produits
                </div>
                <div class="col-6 right normal" *ngIf="3 as maxNbProductTypes">
                    <span *ngFor="let productType of _productCategory?.productTypes  ; nb_productTypes as index;let i = index;"	  class="badge badge-pill badge-primary">
			 <span *ngIf="i < maxNbProductTypes || more_productTypes"> {{productType?.name}} </span> 
		    </span>
		    <button mat-button  color="primary" *ngIf="_productCategory?.productTypes?.length > maxNbProductTypes" (click)="more_productTypes = !more_productTypes">{{ more_productTypes ? 'voir moins' : 'voir plus' }}</button> 
                </div>
            </div>

	    <div class="row w-100 left-background">
                <div class="col-6 left">
                    Produits
                </div>
                <div class="col-6 right normal"  *ngIf="3 as maxNbProducts">
		    <span *ngFor="let productType of _productCategory?.productTypes;">
			<span *ngFor="let product of productType?.products;nb_products as index;let i = index;let c = count">

			    <span *ngIf="i < maxNbProducts || more_products;  "  class="badge badge-pill badge-info"> {{product?.name}}</span>
			    <button mat-button  color="primary" *ngIf="i  + 1== c && c > maxNbProducts" (click)="more_products = !more_products">{{ more_products ? 'voir moins' : 'voir plus' }}</button> 
			</span>
		    </span>


                </div>
            </div>

        </div>




        <label class="mt-5">Informations complémentaires</label>
        <div class="lateral-space">
   
            <div class="row mt-3 w-100 left-background">
                <div class="col-6 left">
                    Créé le {{_productCategory?.objectDetail?.creationDate |  date:'dd/MM/yyyy' }}
                </div>
                <div class="col-6 right normal">
                    Modifié le {{_productCategory?.objectDetail?.modificationDate |  date:'dd/MM/yyyy' }}
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Créé par
                </div>
                <div class="col-6 right link">
                    {{_productCategory?.objectDetail?.creator?.username}}
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    Modifié par
                </div>
                <div class="col-6 right link">
                    {{_productCategory?.objectDetail?.updater?.username}}  
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Status
                </div>
                <div class="col-6 right link d-flex justify-content-center">
                    {{_productCategory?.status?.label}}   
                </div>
            </div>
        </div>
    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
