import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, from,  map,  concatMap, switchMap, Observable, exhaustMap, EMPTY, empty ,identity, forkJoin} from 'rxjs';  

import { environment } from 'src/environments/environment';

// Services
import {ClassGradeService} from '../services/class-grade.service'
import {NoteService} from '../services/note.service'
import {FileService} from '../services/file.service'

// models   
import { School } from '../interfaces/school';
import { Address as Address } from '../interfaces/address';
import { ClassLevel } from '../interfaces/class-level';
import { ClassGrade } from '../interfaces/class-grade';

import { Product } from '../interfaces/product';

import { Note } from '../interfaces/note';
import { File } from '../interfaces/file';
import { User } from '../interfaces/user';
import { Profil as Profile } from '../interfaces/profil';

import { SchoolList, SchoolListProduct } from '../interfaces/school-list';

// response 
import { Response as Rep } from '../interfaces/response';

import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"


@Injectable({
  providedIn: 'root'
})
export class SchoolListService extends BaseCrudModel<SchoolList>{
    postProducts = {url: `${environment.domains.api.url}/school-list-products/all`, urlOptions: this.options};
    deleteProducts = {url: `${environment.domains.api.url}/school-list-products`, urlOptions: this.options};
    //postProducts = {url: `${environment.domains.api.url}/item-types`, urlOptions: this.options};
    //deleteProducts = {url: `${environment.domains.api.url}/item-types`, urlOptions: this.options};       
    
    constructor(protected override httpService: HttpService,
		private fileService : FileService,
		private classGradeService : ClassGradeService,
		private noteService : NoteService) {
	super(httpService, "school-lists");

		var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "objectDetail"},
		{"relation" : "note"},
		{"relation" : "file"},
		{"relation" : "products"},
		{"relation" : "schoolListProducts", "scope" : {
		    "include" : [{"relation": "product", "scope" : { "include" : [{"relation": "mainImage"}]} }]}},
		{"relation" : "classGrade",
		 "scope": {"include" : [
		     {"relation" : "classLevel"},
		     {"relation" : "school", "scope" : { "include" : [{"relation": "address" }]}},
		 ]}},
	    ],
	});

	var addOptions =  RequestOptions.FromArray({
	    relationsToOmit : ['note',  'dataToUpload','status', 'classGrade', 'products', 'schoolListProducts']
	});

	this.methods.listing.urlOptions = options;
	this.methods.view.urlOptions = options;
	this.methods.add.urlOptions = addOptions;
	this.methods.update.urlOptions = addOptions;
    }

    getAllSchoolLists(): Observable<Rep<number, SchoolList[]>> {
	const options = new RequestOptions();

	return this.getAll()
    }

    getAllSchoolListsWithoutProducts(): Observable<Rep<number, SchoolList[]>> {
	var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "objectDetail"},
		{"relation" : "note"},
		//{"relation" : "file"},
		//{"relation" : "products"},
		{"relation" : "schoolListProducts", "scope" : {
		    "include" : [/*{"relation": "product", "scope" : { "include" : [{"relation": "mainImage"}]} }*/]}},
		{"relation" : "classGrade",
		 "scope": {"include" : [
		     {"relation" : "classLevel"},
		     {"relation" : "school", "scope" : { "include" : [{"relation": "address" }]}}
		 ]}},
	    ],
	});

	return this.getAll(options)
    }

    getByIdWithoutProducts(id): Observable<Rep<number, SchoolList>> {
	var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "objectDetail"},
		{"relation" : "note"},
		//{"relation" : "file"},
		//{"relation" : "products"},
		{"relation" : "schoolListProducts", "scope" : {
		    "include" : [/*{"relation": "product", "scope" : { "include" : [{"relation": "mainImage"}]} }*/]}},
		{"relation" : "classGrade",
		 "scope": {"include" : [
		     {"relation" : "classLevel"},
		     {"relation" : "school", "scope" : { "include" : [{"relation": "address" }]}}
		 ]}},
	    ],
	});

	return this.getById(id, options);
    }

    ////////////////////////////////
    // PREPARE || HANDLE Relations
    ////////////////////////////////
    
    override prepareRelations(fromSchoolList): Observable<Rep<number, SchoolList>> { //Observable<Profile> {
	// values to save
	const schoolList : SchoolList = fromSchoolList;


	// Obs Functions
	const failClassGrade : Rep<number, ClassGrade> = {IsSuccess:  0, Data:  null, Status : 0};
	const failObj : Rep<number, Note> = {IsSuccess:  0, Data:  null, Status : 0};
	const failFile : Rep<number, File[]> = {IsSuccess:  0, Data:  null, Status : 0};

	

	const a$ = of();

	const classGrade$ : Observable<Rep<number, ClassGrade>> = schoolList.classGrade && schoolList.classGrade.id ?
	    this.httpService.createObservableResponseFromObj<ClassGrade>(schoolList.classGrade) :
	    ( schoolList.classGrade ?  this.classGradeService.add(fromSchoolList.classGrade) :
		this.httpService.createObservableResponse<ClassGrade>(failClassGrade));


	
	const note$ : Observable<Rep<number, Note>> = schoolList.note ? 
	    ( schoolList.note.id ? this.noteService.updateById(fromSchoolList.note.id, fromSchoolList.note) : this.noteService.add(fromSchoolList.note) )
	    :  this.httpService.createObservableResponse<Note>(failObj);


	const uploadFile$ : Observable<Rep<number, File[]>> = (schoolList.file && schoolList.file.dataToUpload ) || schoolList.dataToUpload ?
	    ( schoolList.file && schoolList.file.dataToUpload ?  this.fileService.uploadFiles(fromSchoolList.file.dataToUpload) : 
		this.fileService.uploadFiles(fromSchoolList.dataToUpload)) :
	    this.httpService.createObservableResponse<File[]>(failFile);
/*
	const file$ : Observable<Rep<number, File>> = schoolList.file ?
	    ( schoolList.file.id ?  this.fileService.updateById(fromSchoolList.file.id, fromSchoolList.file) :
		this.fileService.add(fromSchoolList.file)) :
	    this.httpService.createObservableResponse<File>(failFile);
*/
	return note$
	    .pipe(
		 exhaustMap(note => 
		     {
			 console.log("Created note: \n {0} \n before Setting note , schoolList was : {1}\n".format(JSON.stringify(note), JSON.stringify(schoolList)));
			(schoolList.noteId = note && note.IsSuccess ?
			    (note.Data && note.Data.id ? note.Data.id : schoolList.noteId) : schoolList.noteId );
			
			 //return  uploadFile$;
			 return  classGrade$;
		    }),
		 exhaustMap(classGrade => 
		     {
			 console.log("Created classGrade: \n {0} \n before Setting note , schoolList was : {1}\n".format(JSON.stringify(classGrade), JSON.stringify(schoolList)));
			(schoolList.classGradeId = classGrade && classGrade.IsSuccess ?
			    (classGrade.Data && classGrade.Data.id ? classGrade.Data.id : schoolList.classGradeId) : schoolList.classGradeId );
			
			return  uploadFile$;
		    }),
		exhaustMap(fileUploaded => 
		    {

			console.log("created fileUploaded");
			console.log(fileUploaded);
			console.log("before setting FileUploaded");
			console.log(schoolList);
			return fileUploaded && fileUploaded.Data ? this.fileService.addMany(fileUploaded.Data) :
			    this.httpService.createObservableResponse<File[]>(failFile) ;
			//return    this.httpService.createResponseFromObj<File[]>(fileUploaded) ;
			//			return  uploadFile$;
		    }),
		exhaustMap(files => 
		    {

			console.log("created fileUploaded");
			console.log(files);
			var  file : File = files && files.Data && files.Data.length > 0 ? files.Data[0] : null; 
			(schoolList.fileId = file && files.IsSuccess ?
			    (file && file.id ? file.id : schoolList.fileId) : schoolList.fileId );
			//return    this.httpService.createResponseFromObj<File[]>(fileUploaded) ;
			//			return  uploadFile$;
			return this.httpService.createObservableResponse<File[]>({IsSuccess:  1, Data:  files.Data, Status : 0});
			
		    }),
		map(rel =>
		    {
			console.log("final result");
			console.log(rel);
			// return profile
			return    this.httpService.createResponseFromObj<SchoolList>(schoolList) ;
		    }),
		
	    );
    }
			/*(schoolList.fileUploadedId = fileUploaded && fileUploaded.IsSuccess ?
			  (fileUploaded.Data && fileUploaded.Data.id ? fileUploaded.Data.id : schoolList.fileUploadedId) : schoolList.fileUploadedId );*/

    
    //////////////////////////
    // HANDLE || PREPARE
    //////////////////////////

    override handleRemoveRelations(schoolList): Observable<any> {
	console.log("removePostRelations for schoolList : \n {0}".format(JSON.stringify(schoolList)));

	return forkJoin
	(
	    // Remove elements if empty
	    // schoolList && schoolList.id && (schoolList.products && schoolList.products?.length >= 0) ? this.removeProducts(schoolList.id) :
	    // 	this.httpService.createObservableResponse<Product[]>({IsSuccess:  0, Data:  undefined, Status : 0})

	    schoolList && schoolList.id && (schoolList.schoolListProducts && schoolList.schoolListProducts?.length >= 0) ? this.removeSchoolListProducts(schoolList.id) :
		this.httpService.createObservableResponse<SchoolListProduct[]>({IsSuccess:  0, Data:  undefined, Status : 0})


	)
    }
    
    override handlePostRelations(schoolList): Observable<any> {
	console.log(schoolList);
	console.log("handlePostRelations");
	    return forkJoin
	(
	    
	    // add new elements 
	    // schoolList && schoolList.id && schoolList.products && schoolList.products?.length > 0 ? this.addProducts(schoolList.id, schoolList.products) :
	    // 	this.httpService.createObservableResponse<Product[]>({IsSuccess:  0, Data:  undefined, Status : 0})

	    schoolList && schoolList.id && schoolList.schoolListProducts && schoolList.schoolListProducts?.length > 0 ? this.addSchoolListProducts(schoolList.id, schoolList.schoolListProducts) :
		this.httpService.createObservableResponse<SchoolListProduct[]>({IsSuccess:  0, Data:  undefined, Status : 0})

	)
    }

    //////////////////////////
    // Add Other objects
    //////////////////////////

    public removeProducts(schoolListId):  Observable<Rep<number, any>> {
	console.log("remove product for schoolListId:{schoolListId}".formatUnicorn({schoolListId : schoolListId}));
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		//{"schoolListId" : schoolListId},
		{"schoolListId" : {"regexp" : "/^{schoolListId}$/".formatUnicorn({schoolListId: schoolListId})}},
	    ],
	});
	//options.body = {"schoolListId" : ""}
	options.bodyParams = {}
	
	//return this.httpService.makePatchRequest<any>( `${this.deleteProducts.url}`, options);
	return this.httpService.makeDeleteRequest<any>( `${this.deleteProducts.url}`, options);
//	return  this.httpService.createObservableResponse<ProductSchoolList[]>({IsSuccess:  0, Data:  undefined, Status : 0});
    }

    public removeSchoolListProducts(schoolListId):  Observable<Rep<number, any>> {
	return this.removeProducts(schoolListId);
    }


    //////////////////////////
    // Add Other objects
    //////////////////////////

    public addProducts(schoolListId, products):  Observable<Rep<number, any>> {
	
 	
	const arrayProducts = products?.map((r: any): SchoolListProduct => ({schoolListId : schoolListId, productId: r.id}));
	//const arrayProducts = products?.map((r: Product): any => (r.id));

	const options =  new RequestOptions(); 
	/*	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		{"id" : {"inq" : arrayProducts}},
	    ],
	});*/
	console.log("Array products");
	console.log(arrayProducts);
	options.body =  arrayProducts; //{"schoolListId" : schoolListId} ;//arrayProducts;	

	return arrayProducts && arrayProducts.length > 0 ?
	    //	    this.httpService.makePatchRequest<SchoolListProduct[]>( `${this.postProducts.url}`.format(), options) :
	    this.httpService.makePostRequest<SchoolListProduct[]>( `${this.postProducts.url}`.format(), options) :
	    this.httpService.createObservableResponse<SchoolListProduct[]>({IsSuccess:  0, Data:  undefined, Status : 0});
	//Observable.empty<number, any>() ;
    }


    public addSchoolListProducts(schoolListId, schoolListProducts):  Observable<Rep<number, any>> {
	
 	
	const arraySchoolListProducts = schoolListProducts?.map((r: any): SchoolListProduct => ({schoolListId : schoolListId, ...r}));

	const options =  new RequestOptions(); 
	console.log("Array schoolListproducts");
	console.log(arraySchoolListProducts);
	options.body =  arraySchoolListProducts; 

	return arraySchoolListProducts && arraySchoolListProducts.length > 0 ?
	    this.httpService.makePostRequest<SchoolListProduct[]>( `${this.postProducts.url}`.format(), options) :
	    this.httpService.createObservableResponse<SchoolListProduct[]>({IsSuccess:  0, Data:  undefined, Status : 0});
    }
}
