import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Response } from '../../interfaces/response';

import { Role } from 'src/app/interfaces/role';
import { Permission } from 'src/app/interfaces/permission';
import { UserModel } from 'src/app/interfaces/user'; 
import { User } from 'src/app/interfaces/user';
import { Profil } from 'src/app/interfaces/profil';
import { Status } from 'src/app/interfaces/status';


import { RolesService } from 'src/app/services/roles.service'
import { StatusService } from 'src/app/services/status.service';
import { PermissionService } from 'src/app/services/permission.service';
import { UserService } from 'src/app/services/user.service';
import { ProfileService } from 'src/app/services/profile.service';



import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { MatSort } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout'; 
import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-list-role',
  templateUrl: './list-role.component.html',
  styleUrls: ['./list-role.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ListRoleComponent   implements OnInit, AfterViewInit {
    
    ////////////////////////////////
    // Attributes
    ////////////////////////////////

    isLoading = true;
    
    columns: string[] = ['#', 'name', 'label', 'description', 'permissions', 'users', 'Action' ];
    datasource: MatTableDataSource<User> = new MatTableDataSource<User>();

    pageSize = 10;
    color="#f8f8fb";

    toppings = new FormControl('');
    toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

    roles: Role[];

    rolesCtrl = new FormControl('');
    permissionsListCtrl = new FormControl('');
    profilesListCtrl = new FormControl('');
    statusesListCtrl = new FormControl('');
   
    permissionsList : Permission[] = [];
    profilesList : Profil[] = [];
    statusesList : Status[] = [];
    
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    
    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();


    
    constructor(private roleService: RolesService,
		private userService: UserService,
		private profileService: ProfileService,
		private permissionService: PermissionService,
		private statusService: StatusService,
//		private changeDetectorRefs: ChangeDetectorRef,
		private router: Router,
		public dialog: MatDialog) {

  }

    //////////////////////
    // Init
    //////////////////////
    
  ngAfterViewInit() {
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      
  }

  ngOnInit(): void {
      this.refresh();
  }

    //////////////////////
    // Data sources
    //////////////////////

    doFilter(value: string) : void  {
	this.datasource.filter = value.trim().toLocaleLowerCase();
    }

    
    refresh(): void {
	this.setLoading(true);
	this.roleService.getAllRoles().subscribe(
	    {
		next: (response: Response<number, Role[]>) => {
		    console.log(response);
		    this.roles = response.Data;
		    this.datasource.data = this.roles;
		    this.datasource.paginator = this.paginator;
		    this.setLoading(false);
		    console.log(this.roles);


	

		    
		},
		error: (e) => {
		    this.setLoading(false);
		},
		complete: () => console.info('loadMenu complete')
	    }
	);
	this.permissionService.getAll().subscribe({
	    next: (response: Response<number, Permission[]>) => {
		this.permissionsList = response.Data;
            },error: (e) => { }, complete: () => console.info('load permissions complete')
	});
	this.statusService.getAll().subscribe({
	    next: (response: Response<number, Status[]>) => {
		this.statusesList = response.Data;
            },error: (e) => { }, complete: () => console.info('load status complete')
	});
	this.profileService.getAll().subscribe({
	    next: (response: Response<number, Profil[]>) => {
		this.profilesList = response.Data;
            },error: (e) => { }, complete: () => console.info('load status complete')
	});
    }

    //////////////////////
    // Loading
    /////////////////////

        toggleLoading() : void {
	this.isLoading = !this.isLoading;
    }

    setLoading(loading : boolean) : void {
	this.isLoading = loading;
    }

    //////////////////////
    //////////////////////

    

    //////////////////////
    // Actions
    //////////////////////

  onAddNewRole(): void {
    this.router.navigate([environment.paths.NewRolePath]);
  }

  onConsulterRole(id): void {
      this.router.navigate([environment.paths.ConsulterRolePath, id]);
  }

  onEditRole(id): void {
      this.router.navigate([environment.paths.EditerRolePath, id]);
  }

  onDelete(id): void {
    const dialogRef = this.dialog.open(ConfirmerSuppressionDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
	  console.log(id);

	  this.roleService.deleteById(id).subscribe(
	      {
		  next: (response: Response<number, Role>) => {
		      console.log(response);
		      this.refresh();
		  },
		  error: (e) => {
		      console.log(e);
		  },
		  complete: () => console.info('delete complete')
	      }
	  );

      }
    });
  }

}

