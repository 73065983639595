<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">VOIR COMMANDE</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Commande</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 30px;">

        <div class="d-flex justify-content-end">
            <!--<button class="btn btn-primary btn-sm" (click)="onEditShipping(_order.id)">Éditer une expédition</button>-->
        </div>

        <label>Voir Commande</label>

	<mat-stepper orientation="horizontal">

            <mat-step>
		
		
		<ng-template matStepLabel><mat-icon>local_shipping</mat-icon> Information du groupe </ng-template>
		<ng-template matStepContent>

	<div class="lateral-space">
	    <h6 style="color: black; font-size: small">Voir les informations de la commande  ci-dessous</h6>  	    

            <div class="row mt-3 w-100 left-background">
                <div class="col-6 left">
                    Numéro d'expédition retrait
                </div>
                <div class="col-6 right">
                    {{_order?.orderNumber}}
                </div>
            </div>
            <div *ngIf="_order?.shipping?.shippingType" class="row w-100">
                <div class="col-6 left">
                    Méthode d'expédition retrait
                </div>
                <div class="col-6 right">
                    {{_order?.shpping?.shippingType?.name}}
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    Date d'expédition
                </div>
                <div class="col-6 right">
                    {{ _order?.shipping?.shippingDate |  date:'dd/MM/yyyy' }}
                </div>
            </div>
	    
            <div *ngIf="_order?.shipping?.shippingStatus" class="row w-100">
                <div class="col-6 left">
                    Status de l'expédition
                </div>
                <div class="col-6 right">
                    {{_order?.shipping?.shippingStatus?.label}}
                </div>
            </div>
            <div *ngIf="_order?.paymentStatus" class="row w-100">
                <div class="col-6 left">
                    Status de l'expédition
                </div>
                <div class="col-6 right">
                    {{_order?.paymentStatus?.label}}
                </div>
            </div>
            <div *ngIf="_order?.shipping?.manager" class="row w-100 left-background">
                <div class="col-6 left">
                    Gestionnaire
                </div>
                <div class="col-6 right">
                    {{_order?.shipping?.manager?.firstname}} {{_order?.shipping?.manager?.lastname}}
                </div>
            </div>
            <div *ngIf="_order?.shipping?.relayPoint" class="row w-100">
                <div class="col-6 left">
                    Point de relais
                </div>
                <div class="col-6 right">
		    {{_order?.shipping?.relayPoint?.name}}
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    Type Expedition / Livraison
                </div>
                <div class="col-6 right">
		    <span *ngIf="_order?.shipping?.delivery"> <span class="badge badge-pill badge-primary"> Livraison </span> </span>
		    <span *ngIf="_order?.shipping?.relayPoint">
			<span *ngIf="_order?.shipping?.relayPoint.isRelayPoint" class="table-regular">
			    <span class="badge badge-pill badge-info"> Point relais </span>
			</span>
			<span *ngIf="_order?.shipping.relayPoint.isSalePoint" class="table-regular">
			    <span class="badge badge-pill badge-warning"> Point de vente </span>
			</span>
			
		    </span>

                </div>
            </div>
	    <div *ngIf="_order?.shipping" class="row w-100  left-background ">
                <div class="col-6 left">
                    Numero Expedition 
                </div>
                <div class="col-6 right">
		    <span class="badge badge-pill badge-info"> #EXP_{{_order?.shipping?.shippingNumber}} </span>

                </div>
            </div>
	    <div *ngIf="_order?.shipping?.delivery" class="row w-100 " >
                <div class="col-6 left">
                    Numero  Livraison
                </div>
                <div class="col-6 right">
		    <span *ngIf="_order?.shipping?.delivery"> <br/><span class="badge badge-pill badge-primary"> #LIV_{{_order?.shipping?.delivery?.deliveryNumber}} </span> </span>

                </div>
            </div>
            <div *ngIf="_order?.shipping?.deliveryMan"  class="row w-100">
                <div class="col-6 left">
                    Livreur
                </div>
                <div class="col-6 right">
                    {{_order?.shipping?.deliveryMan?.firstname}} {{_order?.shipping?.deliveryMan?.lastname}}
                </div>
            </div>
        </div>







        <div  class="row mt-5">
            <div class="col-md-6 col-sm-12 mt-5" *ngIf="_order?.shipping?.delivery">
                <label>Adresse de livraison</label>
                <div class="lateral-space">
		    
                    <div class="row mt-3 w-100 left-background">
                        <div class="col-6 left">
                            Rue
                        </div>
                        <div class="col-6 right">
                            {{_order?.shipping?.delivery?.address?.street}}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Ville
                        </div>
                        <div class="col-6 right">
                            {{_order?.shipping?.delivery?.address?.city}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Code postal
                        </div>
                        <div class="col-6 right">
                            {{_order?.shipping?.delivery?.address?.zip}}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Pays
                        </div>
                        <div class="col-6 right">
			    {{_order?.shipping?.delivery?.address?.country}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Details additionnels
                        </div>
                        <div class="col-6 right">
                            {{_order?.shipping?.delivery?.additionalDetail}}
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="_order?.shipping?.relayPoint" class="col-md-6 col-sm-12 mt-5">
                <label>Adresse du point de retrait </label>
                <div class="lateral-space">
		    
                    <div class="row mt-3 w-100 left-background">
                        <div class="col-6 left">
                            Rue
                        </div>
                        <div class="col-6 right">
                            {{_order?.shipping?.relayPoint?.address?.street}}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Ville
                        </div>
                        <div class="col-6 right">
                            {{_order?.shipping?.relayPoint?.address?.city}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Code postal
                        </div>
                        <div class="col-6 right">
                            {{_order?.shipping?.relayPoint?.address?.zip}}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Pays
                        </div>
                        <div class="col-6 right">
			    {{_order?.shipping?.relayPoint?.address?.country}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Details additionnels
                        </div>
                        <div class="col-6 right">
                            {{_order?.shipping?.relayPoint?.additionalDetail}}
                        </div>
                    </div>
                </div>
            </div>

	    <div *ngIf="_order?.customer?.profile" class="col-md-6 col-sm-12 mt-5">
		<label>Information personnelles</label>
		<div class="lateral-space">


		    <div class="row mt-3 w-100 left-background">
			<div class="col-6 left">
			    Nom d'utilisateur
			</div>
			<div class="col-6 right">
			    {{_order?.customer?.profile?.user?.username}}
			</div>
		    </div>
		    <div class="row w-100">
			<div class="col-6 left">
			    Nom
			</div>
			<div class="col-6 right">
			    {{_order?.customer?.profile?.lastname}}
			</div>
		    </div>
		    <div class="row w-100 left-background">
			<div class="col-6 left">
			    Prénom
			</div>
			<div class="col-6 right">
			    {{_order?.customer?.profile?.firstname}}
			</div>
		    </div>
		    <div class="row w-100">
			<div class="col-6 left">
			    Email
			</div>
			<div class="col-6 right">
			    {{_order?.customer?.profile?.email}}
			</div>
		    </div>
		    <div class="row w-100 left-background">
			<div class="col-6 left">
			    Téléphone
			</div>
			<div class="col-6 right">
			    {{_order?.customer?.profile?.phoneNumber}}
			</div>
		    </div>
		</div>
	    </div>
	    
	    

	    <div class="col-md-6 col-sm-12 mt-5">
		<label>Informations complémentaires</label>
		<div class="lateral-space">
		    
                    <div class="row w-100 left-background">
			<div class="col-6 left">
                            Créé le {{_order?.objectDetail?.creationDate |  date:'dd/MM/yyyy' }}  
			</div>
			<div class="col-6 right">
                            Modifié le {{_order?.objectDetail?.creationDate |  date:'dd/MM/yyyy' }}  
			</div>
                    </div>
                    <div class="row w-100">
			<div class="col-6 left">
                            Créé par
			</div>
			<div class="col-6 right">
                            {{_order?.objectDetail?.creator?.username}}   
			</div>
                    </div>
                    <div class="row w-100 left-background">
			<div class="col-6 left">
                            Modifié par
			</div>
			<div class="col-6 right">
                            {{_order?.objectDetail?.updater?.username}}
			</div>
                    </div>
                    <div class="row w-100">
			<div class="col-6 left">
                            Status
			</div>
			<div class="col-6 right d-flex justify-content-center">
                            <span class="badge badge-pill badge-primary">{{_order?.status?.label}}  </span>  
			</div>
                    </div>
		</div>
            </div>
	</div>




		</ng-template>
	    </mat-step>

	    <mat-step >
                <ng-template matStepLabel><mat-icon>fact_check</mat-icon> Produits du groupe</ng-template>
		
		<!--
		     <div   class="row mt-5 top-right" layout="row" layout-align=" end">
		     <div  layout="column" layout-align="center end" >
		     <div><button mat-raised-button matStepperPrevious color="primary" class="mr-1" type="button">Précédent</button></div>
		     <div><button mat-raised-button matStepperNext color="primary" type="button">Suivant</button></div>
		     </div>
		     </div>
		-->

		<h6 style="color: black; font-size: small">Voir les produits du groupe ci-dessous</h6>
		
                <div class="table-responsive">
		    <div fxLayout="row" fxLayoutAlign="end center">
			<mat-form-field fxFlex="60%">
			    <input matInput type="text" (keyup)="doFilter($event.target.value)"  name="filter" placeholder="Filtrer">
			</mat-form-field>
		    </div>

                    <table mat-table [dataSource]="datasourceSelection" matSort>
			
                        <ng-container matColumnDef="#">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">
                                
                            </th>
                            <td style="width: 4%;" mat-cell *matCellDef="let element">
                                
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="image">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Image</th>
                            <td style="width: 15%;" mat-cell *matCellDef="let element">
                                <img class="bg-image" src="{{element?.img}}" *ngIf="element && element.img" />
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="product.name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Nom</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span>{{element?.product?.name}}</span>
                            </td>
                        </ng-container>

			<ng-container matColumnDef="product.sku">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Reference</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span>{{element?.product?.sku}}</span>
                            </td>
                        </ng-container>
			


                        <ng-container matColumnDef="price">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Prix</th>
                            <td style="width: 120px;" mat-cell *matCellDef="let element">
                                <!-- <input matInput maxlength="5" type="number" placeholder="0" [(ngModel)]="element.qty"  class="form-control form-control-sm" required> -->
                                <mat-form-field appearance="outline" floatLabel="always">
                                    <input matInput disabled [style.color]="black" type="number" class="example-right-align" [(ngModel)]="element.price"  placeholder="0">
                                </mat-form-field>
                            </td>
                        </ng-container>
						
			<ng-container matColumnDef="quantity">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Quantite</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span class="table-regular">{{element?.quantity}} </span>
                            </td>
                        </ng-container>
			

			<ng-container matColumnDef="total">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Total </th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span class="table-regular">{{element?.total | number : '1.2-2'}} </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Action">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-icon matTooltip="consulter" (click)="onConsulterProduct(element.productId)" class="icon" aria-hidden="false"
					  aria-label="Example home icon" fontIcon="preview"></mat-icon>
                            </td>
                        </ng-container>
			
			
			
                        <tr mat-header-row *matHeaderRowDef="columnsSelection"></tr>
                        <tr mat-row *matRowDef="let row; columns: columnsSelection;" class="example-element-row"></tr>
                    </table>
		    
		    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
			<mat-progress-spinner 
			    color="primary" 
			    mode="indeterminate">
			</mat-progress-spinner>
		    </mat-card>
		    
                    <mat-paginator [pageSizeOptions]="[20, 50, 100, 500]" [pageSize]="pageSize" showFirstLastButtons
				   aria-label="Select page of periodic elements">
                    </mat-paginator>
                </div>


		<button mat-raised-button matStepperPrevious color="primary" class="mr-1" type="button">Précédent</button>

            </mat-step>



	</mat-stepper>




	
	</div><!--/. container-fluid -->
</section>
<!-- /.content -->
