import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import {ActivatedRoute} from '@angular/router';   

import { ProductCategoryService } from 'src/app/services/product-category.service';
import { ProductCategory } from 'src/app/interfaces/product-category';
import { Response } from '../../interfaces/response';

@Component({
  selector: 'app-consulter-category',
  templateUrl: './consulter-category.component.html',
  styleUrls: ['./consulter-category.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ConsulterCategoryComponent  implements OnInit  {
  @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
  
    ////////////////////////
    // Attributes
    ////////////////////////
    @Input()     _productCategory: ProductCategory;
    @Input('id') _id?: string;

    ////////////////////////
    // Constructor
    ////////////////////////
    constructor(private router: Router,
		private route: ActivatedRoute,
		private productCategoryService: ProductCategoryService) {
    }


    ////////////////////////
    // Init
    ////////////////////////

    ngOnInit(): void {
	this.initCurrentRoute();
	this.refresh();
    }
    
    initCurrentRoute():void {
	this.route.params.subscribe(params => {
            this._id = params["id"];
	    console.log("current id " + this._id)
        });


    }

    
    initCurrentObject(): void {
	this.productCategoryService.getById(this._id).subscribe(
	    {
		next: (response: Response<number, ProductCategory>) => {
		    console.log(response);
		    this._productCategory = response.Data;
	      
	      //this.datasource.data = this.productCategory;
              //this.datasource.paginator = this.paginator;

		    console.log(this._productCategory);

	      // this.refresh();
		},
		error: (e) => {
		    console.log(e);
		},
		complete: () => console.info('loaduser complete')
	    });
    }


    

    ////////////////////////
    // Datasources
    ////////////////////////
    
    refresh() : void {
	this.initCurrentObject();	

    }
    
    ////////////////////////
    // Actions
    ////////////////////////
  onEditCategory(id): void {
      this.router.navigate([environment.paths.EditerCategoryPath, id]);
  }

}
