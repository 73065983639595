import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FlexLayoutModule } from '@angular/flex-layout'; 

import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner'; 
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import {MatTreeModule} from '@angular/material/tree';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatChipsModule} from '@angular/material/chips';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSelectModule} from '@angular/material/select';
import { MatSelectCountryModule } from "@angular-material-extensions/select-country";
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';

import { ConsulterPermissionComponent } from './Consulter/consulter-permission/consulter-permission.component';
import { ConsulterProfilComponent } from './Consulter/consulter-profil/consulter-profil.component';
import { ConsulterRoleComponent } from './Consulter/consulter-role/consulter-role.component';
import { AddPermissionDialogComponent } from './Dialog/add-permission-dialog/add-permission-dialog.component';
import { AddRoleDialogComponent } from './Dialog/add-role-dialog/add-role-dialog.component';
import { ConfirmerSuppressionDialogComponent } from './Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';
import { EditerPermissionComponent } from './Editer/editer-permission/editer-permission.component';
import { EditerRoleComponent } from './Editer/editer-role/editer-role.component';
import { EditerUtilisateurComponent } from './Editer/editer-utilisateur/editer-utilisateur.component';
import { ListAccountComponent } from './Listing/list-account/list-account.component';
import { ListPermissionComponent } from './Listing/list-permission/list-permission.component';
import { ListRoleComponent } from './Listing/list-role/list-role.component';
import { NewUserAccountComponent } from './Creation/new-user-account/new-user-account.component';
import { NewRoleComponent } from './Creation/new-role/new-role.component';
import { NewPermissionComponent } from './Creation/new-permission/new-permission.component';
import { ListEcoleComponent } from './Listing/list-ecole/list-ecole.component';
import { NewEcoleComponent } from './Creation/new-ecole/new-ecole.component';
import { ConsulterEcoleComponent } from './Consulter/consulter-ecole/consulter-ecole.component';
import { EditerEcoleComponent } from './Editer/editer-ecole/editer-ecole.component';
import { ListNiveauScolaireComponent } from './Listing/list-niveau-scolaire/list-niveau-scolaire.component';
import { NewNiveauScolaireComponent } from './Creation/new-niveau-scolaire/new-niveau-scolaire.component';
import { EditerNiveauScolaireComponent } from './Editer/editer-niveau-scolaire/editer-niveau-scolaire.component';
import { ConsulterNiveauScolaireComponent } from './Consulter/consulter-niveau-scolaire/consulter-niveau-scolaire.component';
import { ListProductComponent } from './Listing/list-product/list-product.component';
import { NewProductComponent } from './Creation/new-product/new-product.component';
import { FileInputSelectComponent } from './file-input-select/file-input-select.component';
import { EditerProductComponent } from './Editer/editer-product/editer-product.component';
import { ConsulterProductComponent } from './Consulter/consulter-product/consulter-product.component';
import { MatCarouselModule } from 'ng-mat-carousel';
import { ProductCarousselComponent } from './product-caroussel/product-caroussel.component';
import { ListClassComponent } from './Listing/list-class/list-class.component';
import { NewClassComponent } from './Creation/new-class/new-class.component';
import { EditerClasseComponent } from './Editer/editer-classe/editer-classe.component';
import { ConsulterClasseComponent } from './Consulter/consulter-classe/consulter-classe.component';
import { ListListScolaireComponent } from './Listing/list-list-scolaire/list-list-scolaire.component';
import { NewListScolaireComponent } from './Creation/new-list-scolaire/new-list-scolaire.component';
import { EditerListeScolaireComponent } from './Editer/editer-liste-scolaire/editer-liste-scolaire.component';
import { ConsulterListeScolaireComponent } from './Consulter/consulter-liste-scolaire/consulter-liste-scolaire.component';
import { ListOrderComponent } from './Listing/list-order/list-order.component';
import { ConsulterOrderComponent } from './Consulter/consulter-order/consulter-order.component';
import { CheckoutComponent } from './checkout/checkout.component';
import {MatStepperModule} from '@angular/material/stepper';
import { ListCategoryComponent } from './Listing/list-category/list-category.component';
import { NewCategoryComponent } from './Creation/new-category/new-category.component';
import { EditerCategoryComponent } from './Editer/editer-category/editer-category.component';
import { ConsulterCategoryComponent } from './Consulter/consulter-category/consulter-category.component';
import { ListBrandComponent } from './Listing/list-brand/list-brand.component';
import { NewBrandComponent } from './Creation/new-brand/new-brand.component';
import { EditerBrandComponent } from './Editer/editer-brand/editer-brand.component';
import { ConsulterBrandComponent } from './Consulter/consulter-brand/consulter-brand.component';
import { ListProductTypeComponent } from './Listing/list-product-type/list-product-type.component';
import { NewProductTypeComponent } from './Creation/new-product-type/new-product-type.component';
import { EditerProductTypeComponent } from './Editer/editer-product-type/editer-product-type.component';
import { ConsulterProductTypeComponent } from './Consulter/consulter-product-type/consulter-product-type.component';

import { ListCompaniesComponent } from './Listing/list-companies/list-companies.component';
import { NewCompanyComponent } from './Creation/new-company/new-company.component';
import { ConsulterCompanyComponent } from './Consulter/consulter-company/consulter-company.component';
import { EditerCompanyComponent } from './Editer/editer-company/editer-company.component';

import { ListCompanyGroupsComponent } from './Listing/list-company-group/list-company-groups.component';
import { NewCompanyGroupComponent } from './Creation/new-company-group/new-company-group.component';
import { ConsulterCompanyGroupComponent } from './Consulter/consulter-company-group/consulter-company-group.component';
import { EditerCompanyGroupComponent } from './Editer/editer-company-group/editer-company-group.component';

import { ListCustomerComponent } from './Listing/list-customer/list-customer.component';
import { NewCustomerComponent } from './Creation/new-customer/new-customer.component';
import { EditerCustomerComponent } from './Editer/editer-customer/editer-customer.component';
import { ConsulterCustomerComponent } from './Consulter/consulter-customer/consulter-customer.component';
import { ListExpeditionComponent } from './Listing/list-expedition/list-expedition.component';
import { NewExpeditionComponent } from './Creation/new-expedition/new-expedition.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { EditerExpeditionComponent } from './Editer/editer-expedition/editer-expedition.component';
import { ConsulterExpeditionComponent } from './Consulter/consulter-expedition/consulter-expedition.component';
import { ListDeliveryComponent } from './Listing/list-delivery/list-delivery.component';
import { NewDeliveryComponent } from './Creation/new-delivery/new-delivery.component';
import { EditerDeliveryComponent } from './Editer/editer-delivery/editer-delivery.component';
import { ConsulterDeliveryComponent } from './Consulter/consulter-delivery/consulter-delivery.component';
import { ListMethodExpeditionComponent } from './Listing/list-method-expedition/list-method-expedition.component';
import { EditerMethodeExpeditionComponent } from './Editer/editer-methode-expedition/editer-methode-expedition.component';
import { NewMethodeExpeditionComponent } from './Creation/new-methode-expedition/new-methode-expedition.component';
import { ConsulterMethodeExpeditionComponent } from './Consulter/consulter-methode-expedition/consulter-methode-expedition.component';
import { ListMethodePaymentsComponent } from './Listing/list-methode-payments/list-methode-payments.component';
import { EditerMethodePaymentComponent } from './Editer/editer-methode-payment/editer-methode-payment.component';
import { NewMethodePaymentComponent } from './Creation/new-methode-payment/new-methode-payment.component';
import { ConsulterMethodePaymentComponent } from './Consulter/consulter-methode-payment/consulter-methode-payment.component';
import { ListSalesPointComponent } from './Listing/list-sales-point/list-sales-point.component';
import { EditerSalesPointComponent } from './Editer/editer-sales-point/editer-sales-point.component';
import { NewSalesPointComponent } from './Creation/new-sales-point/new-sales-point.component';
import { ConsulterSalesPointComponent } from './Consulter/consulter-sales-point/consulter-sales-point.component';
import { ListLocalisationComponent } from './Listing/list-localisation/list-localisation.component';
import { EditerLocalisationComponent } from './Editer/editer-localisation/editer-localisation.component';
import { NewLocalisationComponent } from './Creation/new-localisation/new-localisation.component';
import { ConsulterLocalisationComponent } from './Consulter/consulter-localisation/consulter-localisation.component';
import { ListRelayPointComponent } from './Listing/list-relay-point/list-relay-point.component';
import { EditerRelayPointComponent } from './Editer/editer-relay-point/editer-relay-point.component';
import { NewRelayPointComponent } from './Creation/new-relay-point/new-relay-point.component';
import { ConsulterRelayPointComponent } from './Consulter/consulter-relay-point/consulter-relay-point.component';
import { HistoryButtonComponent } from './history-button/history-button.component';
import { ListDocumentsComponent } from './Listing/list-documents/list-documents.component';
import { ConsulterDocumentComponent } from './Consulter/consulter-document/consulter-document.component';
import { NewDocumentComponent } from './Creation/new-document/new-document.component';
import { EditerDocumentComponent } from './Editer/editer-document/editer-document.component';
import { InvoiceViewerComponent } from './invoice-viewer/invoice-viewer.component';
import {MatTabsModule} from '@angular/material/tabs';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ShowErrorsComponent } from './ShowErrors/show-errors/show-errors.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    NewUserAccountComponent,
    NewRoleComponent,
    NewPermissionComponent,
    AddRoleDialogComponent,
    AddPermissionDialogComponent,
    ListAccountComponent,
    ListRoleComponent,
    ListPermissionComponent,
    ConsulterProfilComponent,
    EditerUtilisateurComponent,
    ConsulterPermissionComponent,
    EditerPermissionComponent,
    ConsulterRoleComponent,
    EditerRoleComponent,
    ConfirmerSuppressionDialogComponent,
    ListEcoleComponent,
    NewEcoleComponent,
    ConsulterEcoleComponent,
    EditerEcoleComponent,
    ListNiveauScolaireComponent,
    NewNiveauScolaireComponent,
    EditerNiveauScolaireComponent,
    ConsulterNiveauScolaireComponent,
    ListProductComponent,
    NewProductComponent,
    FileInputSelectComponent,
    EditerProductComponent,
    ConsulterProductComponent,
    ProductCarousselComponent,
    ListClassComponent,
    NewClassComponent,
    EditerClasseComponent,
    ConsulterClasseComponent,
    ListListScolaireComponent,
    NewListScolaireComponent,
    EditerListeScolaireComponent,
    ConsulterListeScolaireComponent,
      ListOrderComponent,
      ConsulterOrderComponent,
    CheckoutComponent,
    ListCategoryComponent,
    NewCategoryComponent,
    EditerCategoryComponent,
    ConsulterCategoryComponent,
    ListBrandComponent,
    NewBrandComponent,
    EditerBrandComponent,
    ConsulterBrandComponent,
    ListProductTypeComponent,
    NewProductTypeComponent,
    EditerProductTypeComponent,
      ConsulterProductTypeComponent,
      ListCompanyGroupsComponent,
      NewCompanyGroupComponent,
      ConsulterCompanyGroupComponent,
      EditerCompanyGroupComponent,

      ListCompaniesComponent,
      NewCompanyComponent,
      ConsulterCompanyComponent,
      EditerCompanyComponent,
      
    ListCustomerComponent,
    NewCustomerComponent,
    EditerCustomerComponent,
    ConsulterCustomerComponent,
    ListExpeditionComponent,
    NewExpeditionComponent,
    EditerExpeditionComponent,
    ConsulterExpeditionComponent,
    ListDeliveryComponent,
    NewDeliveryComponent,
    EditerDeliveryComponent,
    ConsulterDeliveryComponent,
    ListMethodExpeditionComponent,
    EditerMethodeExpeditionComponent,
    NewMethodeExpeditionComponent,
    ConsulterMethodeExpeditionComponent,
    ListMethodePaymentsComponent,
    EditerMethodePaymentComponent,
    NewMethodePaymentComponent,
    ConsulterMethodePaymentComponent,
    ListSalesPointComponent,
    EditerSalesPointComponent,
    NewSalesPointComponent,
    ConsulterSalesPointComponent,
    ListLocalisationComponent,
    EditerLocalisationComponent,
    NewLocalisationComponent,
    ConsulterLocalisationComponent,
    ListRelayPointComponent,
    EditerRelayPointComponent,
    NewRelayPointComponent,
    ConsulterRelayPointComponent,
    HistoryButtonComponent,
    ListDocumentsComponent,
    ConsulterDocumentComponent,
    NewDocumentComponent,
    EditerDocumentComponent,
    InvoiceViewerComponent,
    ShowErrorsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatTableModule,
    HttpClientModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
      MatFormFieldModule,
      MatProgressSpinnerModule,
      MatSortModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatIconModule,
    MatTreeModule,
    MatChipsModule,
    MatTooltipModule,
      MatDialogModule,
      MatDividerModule,
    MatExpansionModule,
    MatStepperModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatTabsModule,
    NgxExtendedPdfViewerModule,
    PdfViewerModule,
    MatCarouselModule.forRoot(),
    MatSelectCountryModule.forRoot('fr'),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
