import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import {ActivatedRoute} from '@angular/router';   

import { PaymentTypeService } from 'src/app/services/payment-type.service';
import { PaymentType } from 'src/app/interfaces/payment-type';
import { Response } from '../../interfaces/response';

@Component({
  selector: 'app-consulter-methode-payment',
  templateUrl: './consulter-methode-payment.component.html',
  styleUrls: ['./consulter-methode-payment.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ConsulterMethodePaymentComponent  implements OnInit  {
  @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
    ////////////////////////
    // Attributes
    ////////////////////////
    @Input()     _paymentType: PaymentType;
    @Input('id') _id?: string;

    ////////////////////////
    // Constructor
    ////////////////////////
    constructor(private router: Router,
		private route: ActivatedRoute,
		private paymentTypeService: PaymentTypeService) {
    }


    ////////////////////////
    // Init
    ////////////////////////

    ngOnInit(): void {
	this.initCurrentRoute();
	this.refresh();
    }
    
    initCurrentRoute():void {
	this.route.params.subscribe(params => {
            this._id = params["id"];
	    console.log("current id " + this._id)
        });


    }

    
    initCurrentObject(): void {
	this.paymentTypeService.getById(this._id).subscribe(
	    {
		next: (response: Response<number, PaymentType>) => {
		    console.log(response);
		    this._paymentType = response.Data;
	      
	      //this.datasource.data = this.paymentType;
              //this.datasource.paginator = this.paginator;

		    console.log(this._paymentType);

	      // this.refresh();
		},
		error: (e) => {
		    console.log(e);
		},
		complete: () => console.info('loaduser complete')
	    });
    }


    

    ////////////////////////
    // Datasources
    ////////////////////////
    
    refresh() : void {
	this.initCurrentObject();	

    }
    
    ////////////////////////
    // Actions
    ////////////////////////
    
    onEditPaymentType(id): void {
	this.router.navigate([environment.paths.EditerMethodePaymentsPath, id]);
  }

}
