import { Component, EventEmitter, Input,  Output, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';

import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { Note } from 'src/app/interfaces/note';
import { Address } from 'src/app/interfaces/address';
import { CompanyGroup } from 'src/app/interfaces/company-group';
import { Company } from 'src/app/interfaces/company';
import { Status } from 'src/app/interfaces/status';
import { Customer  } from 'src/app/interfaces/customer';

import { CompanyGroupService } from 'src/app/services/company-group.service';
import { CompanyService } from 'src/app/services/company.service';
import { StatusService } from 'src/app/services/status.service';
import { Country } from '@angular-material-extensions/select-country';
import { CustomerService } from 'src/app/services/customer.service';

import { Response } from '../../interfaces/response';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-editer-company',
  templateUrl: './editer-company.component.html',
  styleUrls: ['./editer-company.component.scss'], encapsulation: ViewEncapsulation.None
})
export class EditerCompanyComponent implements OnInit {
    selectedCountry: Country = undefined;

    @Input() TitleVisible: boolean = true;
    @Input() DisplayConfirmationButton: boolean = true;

    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();

    ////////////////////////
    //  Attributes     
    //////////////////////// 

    @Input()     _company: Company;
    @Input('id') _id?: string;
    
    form: FormGroup;


    companyGroupsListCtrl = new FormControl('');
    companyGroupsData : CompanyGroup[];//string[];
    companyGroupsList : CompanyGroup[];//string[];
    selectedCompanyGroups : CompanyGroup[];
    selectedCompanyGroup : CompanyGroup;

    
    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatuses : Status[];
    selectedStatus : Status;

    customersListCtrl = new FormControl('');
    customersList : Customer[];//string[];
    customersData : Customer[];//string[];
    selectedCustomers : Customer[];
//    selectedCustomer : Customer;

    ////////////////////////
    // Constructor
    ////////////////////////
  
    constructor(private router: Router,
		private route: ActivatedRoute,
		private companyService : CompanyService,
		private statusService : StatusService,
		private companyGroupService : CompanyGroupService,
		private customerService : CustomerService,
		
		private _snackBar: MatSnackBar,
		public fb: FormBuilder) { 
 
      }

        ////////////////////////
    // Init
    ////////////////////////  
    ngOnInit(): void {
	this.initCurrentRoute();
	this.refresh();
	this.initForm(); 

    }

    initCurrentRoute():void {
	this.route.params.subscribe(params => {  
	    this._id = params["id"];
	    console.log("current id " + this._id)
	});

    }

    initCurrentObject(): void {
	
      this.companyService.getById(this._id).subscribe(
	  {
              next: (response: Response<number, Company>) => {
		  console.log("response");
		  console.log(response);
		  this._company = response.Data;
		  
		  if (this._company?.status)
		      this.selectedStatus = this._company?.status ;
		  if (this._company?.companyGroup)
		      this.selectedCompanyGroup = this._company?.companyGroup ;

		  /*
		    if (this._company?.familyType)
		    this.selectedCustomer = this._company?.familyType ;
		  */
		  
		  if (this._company?.note) {
		      
		  } else {
		      const note : Note = {  content :  "" };
		      this._company.note = note;
		  }
		  console.log(this._company.status);
		  console.log(this._company);
		  
              },
              error: (e) => {
		  console.log(e);
              },
              complete: () => console.info('loaduser complete')
	  }
      );

    }



    ////////////////////////
    // Datasrouces
    ///////////////////////


    
    refresh(): void {
	this.initCurrentObject();
	
	this.statusService.getAllStatuses().subscribe({
	    next: (response: Response<number, Status[]>) => {
		
		this.statusesData = response.Data;
		this.initStatusesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load statuses complete')
	});

	this.customerService.getAllCustomers().subscribe({
	    next: (response: Response<number, Customer[]>) => {
		
		this.customersData = response.Data;
		this.initCustomersSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load customers complete')
	});

	this.companyGroupService.getAllCompanyGroups().subscribe({
            next: (response: Response<number, CompanyGroup[]>) => {
		this.companyGroupsData = response.Data;
		this.initCompanyGroupsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load companyGroups complete')
	});

    }

    ////////////////////////
    // Datasrouces Form
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    name: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    siret: new FormControl(null, [Validators.maxLength(20), Validators.minLength(2)]),
	    phone: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$"), Validators.minLength(10)]),
	    email: new FormControl(null, [Validators.required, Validators.email]),

	    street: new FormControl(null, [Validators.required, Validators.maxLength(70), Validators.minLength(2)]),
	    city: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    zip: new FormControl(null, [Validators.required, Validators.maxLength(5),  Validators.pattern("^[0-9]*$"), Validators.minLength(5)]),
	    
	    billingStreet: new FormControl(null, [Validators.required, Validators.maxLength(70), Validators.minLength(2)]),
	    billingCity: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    billingZip: new FormControl(null, [Validators.required, Validators.maxLength(5),  Validators.pattern("^[0-9]*$"), Validators.minLength(5)]),
	    
//	    label: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
//	    description: new FormControl(null, [Validators.required, Validators.maxLength(150), Validators.minLength(2)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
	    country: new FormControl(''),
	});

    }
    
    ////////////////////////
    // Datasources Functions
    ///////////////////////

    

    compareFn(c1,c2): boolean {
	return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }
    
    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns


    initCompanyGroupsSearchDropdown(){
	this.companyGroupsList = [];
        for ( let i = 0 ; i < this.companyGroupsData.length; i ++ ) {
            this.companyGroupsList.push( this.companyGroupsData[i] );
        }
    }


    initStatusesSearchDropdown(){
	this.statusesList = [];
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
	    {

		if (!this.selectedStatus && this.statusesData[i].label == "Actif")
		{
		    this.selectedStatuses = [];
		    this.selectedStatus = this.statusesData[i];
		    
		    this.selectedStatuses.push(this.statusesData[i]);
		}
		this.statusesList.push( this.statusesData[i] );
	    }
        }

    }

    initCustomersSearchDropdown(){
	this.customersList = [];
        for ( let i = 0 ; i < this.customersData.length; i ++ ) {
	    {
		this.customersList.push( this.customersData[i] );
	    }
        }

    }

    ////////////////////////
    // On change

    onStatusesChanged($event: any) {
      console.log($event);
    }

    onCustomersChanged($event: any) {
	console.log($event);
    }

    onCompanyGroupsChanged($event: any) {
      console.log($event);
    }


    ////////////////////////
    // On Search

    onCountrySelected($event: Country) {
    this.selectedCountry = $event;
  }

    onKeyCompanyGroups(value) { 
            this.companyGroupsList= []; 
            this.selectCompanyGroupSearch(value);       
    }



    onKeyStatuses(value) { 
            this.statusesList= []; 
            this.selectStatusesSearch(value);       
    }

    onKeyCustomers(value) { 
            this.customersList= []; 
            this.selectCustomersSearch(value);       
    }

    ////////////////////////
    // search elements


    selectCompanyGroupSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.companyGroupsData.length; i ++ ) {
            let option = this.companyGroupsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0
		|| option.label?.toLowerCase().indexOf(filter) >= 0 ) {
                this.companyGroupsList?.push( option );
            }
        }
    }

    
    selectStatusesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
            let option = this.statusesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.statusesList?.push( option );
            }
        }
    }



    selectCustomersSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.customersData.length; i ++ ) {
            let option = this.customersData[i];
            if ( option.profile?.firstname.toLowerCase().indexOf(filter) >= 0||
		 option.profile?.lastname.toLowerCase().indexOf(filter) >= 0) {
                this.customersList?.push( option );
            }
        }
    }




    ////////////////////////
    ///////////////////////
    
    
    ////////////////////////
    // Actions
    ///////////////////////


    submit() {
	if (this.selectedCountry === undefined) {
	    this._snackBar.open('Veuillez choisir un pays', '', {
		duration: 3000,
		horizontalPosition: 'center',
		verticalPosition: 'bottom',
	    });
	    return;
	}
	if (!this.form.valid) {
	    return;
	}


	const note : Note = {
	    content : this.form.value.note
	}

	if ((this._company.note &&  this._company.note.id ) || this._company.noteId ){
	    note.id =  this._company.note && this._company.note.id ?  this._company.note.id :  this._company.noteId ; 
	}
	
	if (this.selectedStatus){
	    this._company.statusId = this.selectedStatus.id;
	}

	if (this.selectedCompanyGroup){
	    this._company.companyGroupId = this.selectedCompanyGroup.id;
	}


	const companyAddress: Address = {
	  country:  this.selectedCountry.name,
          street: this.form.value.street?.toString(),
          city: this.form.value.city,
          zip: this.form.value.zip?.toString(),
//	  zipCode: this.form.value.postal?.toString(),
          additionalDetail: ''	  
      };
	
	const billingAddress: Address = {
	    country:  this.selectedCountry.name,
            street: this.form.value.billingStreet?.toString(),
            city: this.form.value.billingCity,
            zip: this.form.value.billingZip?.toString(),
//	  zipCode: this.form.value.postal?.toString(),
          additionalDetail: ''	  
      };

	if (this._company.companyAddress?.id)
	    companyAddress.id = this._company.companyAddress.id.toString();
	else if (this._company.companyAddressId)
	    companyAddress.id = this._company.companyAddressId;

	if (this._company.billingAddress?.id)
	    billingAddress.id = this._company.billingAddress.id.toString();
	else if (this._company.billingAddressId)
	    billingAddress.id = this._company.billingAddressId;

	
	this._company.note = note;
	this._company.companyAddress = companyAddress;
	this._company.billingAddress = billingAddress;

	if (this.selectedCustomers && this.selectedCustomers.length > 0)
	    this._company.customers = this.selectedCustomers;
	
	this._company.note = note;

		
	console.log(this._company);
	console.log("gonna save");
	console.log(this.selectedCompanyGroups);
	

	this.companyService.prepareUpdateRelations(this._company).pipe(
	    switchMap(preparedCompany => { 
		console.log("entered switch map -- add new company ");
		console.log(preparedCompany);
		return this.companyService.updateById(this._company.id, this._company);
	    }),
	    switchMap(companyUpdatedObservable => {
		let companyUpdated = companyUpdatedObservable.Data;
		    
		console.log("entered switch map -- handle post relations ");
		console.log(this._company);
		//this._company.companyGroups = this.selectedCompanyGroups;
		this._company.customers = this.selectedCustomers;
		return this.companyService.handlePostUpdateRelations(this._company); 
	    })
	
						      
	).subscribe({
	    next: (res) => { this.checkSubmitSucess(res);},
	    error: (error) => {this.submitError(error)},
	    complete: () => {
		console.info('save company pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();
		    }, 5000);
		
	    }
	    
	    
	});

    }

    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListCompaniesPath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }




}
