<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">NOUVELLE LISTE SCOLAIRE</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Établissements</a></li>
                    <li class="breadcrumb-item active">Liste scolaire</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">


        <h3 style="color: black; font-size:medium">Liste scolaire</h3>
        <h6 style="color: black; font-size: small">Ajouter une liste</h6>

	<mat-stepper orientation="horizontal">

            <mat-step>
		

		<ng-template matStepLabel><mat-icon>local_shipping</mat-icon> Information de la liste  </ng-template>
		<ng-template matStepContent>
		    
		    <h6 style="color: black; font-size: small">Remplir les informations ci-dessous</h6>

		    <form class="example-form" id="schoolListForm" [formGroup]="form" (ngSubmit)="submit()">


			<mat-label>Nom</mat-label>
			<input type="text" matInput formControlName="name" class="form-control form-control-sm mb-2" required>
			<app-show-errors [ctrl]="form.get('name')"></app-show-errors>  
			
			<mat-label>Classe</mat-label>
			<span *ngIf="shouldAddClass 
				     && selectedSchool && selectedSchool.id && selectedClassLevel && selectedClassLevel.id">
			    <span style="display: block;">
				<span style="display: inline-block;
					     color: red;
					     font-size: xx-small;">
				    *
				</span>
				<span style="display: inline-block;
					     color: black;
					     font-size: x-small;">
				    {{selectedClassGrade && selectedClassGrade.id ? "Classe existante" : "Nouvelle classe"}}
				</span>
			    </span>
			</span>

			<div class="input-group">
			    <mat-select class="  form-control form-control-sm mb-2" [formControl]="classGradesListCtrl"
					       [(ngModel)]="selectedClassGrade"
					[compareWith]="compare"
					(selectionChange)="onClassGradesChanged($event)"
					required  >
				<input class="mat-select-input-search" placeholder ="recherche " (keyup)="onKeyClassGrades($event.target.value)"  > 
				<mat-select-trigger>
				    {{selectedClassGrade?.school?.name || ''}} {{selectedClassGrade?.school?.name ? '-' : ''}}  {{selectedClassGrade?.classLevel?.name || ''}}
				    <span *ngIf="(classGradesListCtrl.value?.length || 0) > 1" class="example-additional-selection">
					(+{{(classGradesListCtrl.value?.length || 0) - 1}} {{classGradesListCtrl.value?.length === 2 ? 'other' :
					'others'}})
				    </span>
				</mat-select-trigger>
				<mat-option *ngFor="let classGrade of classGradesList" [value]="classGrade">{{classGrade?.school?.name}} ({{classGrade?.school?.address?.city}}) -- {{classGrade?.classLevel?.name}}</mat-option>
			    </mat-select>
			    <div class="input-group-append">
				<button type="button" (click)="addNewClassGrade()" class="btn btn-primary  form-control-sm">
				    <mat-icon  *ngIf="!shouldAddClass">add</mat-icon>
				    <mat-icon  *ngIf="shouldAddClass">keyboard_control_key</mat-icon>
				</button>
			    </div>
			</div>


			<div  *ngIf="shouldAddClass" style="background:sand;">
			    <mat-label>Ecole</mat-label>
			    <div class="input-group mb-2">
				<mat-select placeholder="Choisir un une ecole pour cette classe" class="  form-control form-control-sm mb-2"   required
					    [(ngModel)]="selectedSchool"   [formControl]="schoolsListCtrl"   (selectionChange)="OnSchoolsChanged($event)"  >
				    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeySchools($event.target.value)">  
				    <mat-select-trigger>
					{{selectedSchool?.name  || ''}}
					<span *ngIf="(selectedSchools?.length || 0) > 1" class="example-additional-selection">
					    (+{{(selectedSchools?.length || 0) - 1}} {{selectedSchools?.length === 2 ? 'autre' :
					    'autres'}})
					</span>
				    </mat-select-trigger>
				    <mat-option *ngFor="let school of schoolsList" [value]="school">{{school.name}}</mat-option>
				</mat-select>
				<!--<div class="input-group-append">
				    <button type="button" class="btn btn-primary  form-control-sm">
					<mat-icon>add</mat-icon>
				    </button>
				</div> -->
			    </div>

			    <mat-label>Niveau scolaire</mat-label>
			    <div class="input-group  mb-2">
				<mat-select placeholder="Choisir un un niveau scolaire pour cette classe" class="  form-control form-control-sm mb-2"   required
					    [(ngModel)]="selectedClassLevel"   [formControl]="classLevelsListCtrl"   (selectionChange)="OnClassLevelsChanged($event)"  >
				    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyClassLevels($event.target.value)">  
				    <mat-select-trigger>
					{{selectedClassLevel?.name  || ''}}
					<span *ngIf="(selectedClassLevels?.length || 0) > 1" class="example-additional-selection">
					    (+{{(selectedClassLevels?.length || 0) - 1}} {{selectedClassLevels?.length === 2 ? 'autre' :
					    'autres'}})
					</span>
				    </mat-select-trigger>
				    <mat-option *ngFor="let classLevel of classLevelsList" [value]="classLevel">{{classLevel.name}}</mat-option>
				</mat-select>
				<!--<div class="input-group-append">
				    <button type="button" class="btn btn-primary  form-control-sm">
					<mat-icon>add</mat-icon>
				    </button>
				</div>-->
			    </div>
			</div>

			
			<mat-form-field appearance="outline" class="example-full-width">
			    <mat-label>Note</mat-label>
			    <textarea style="height: 100px;" matInput placeholder="Note" formControlName="note"  required></textarea>
			    <app-show-errors [ctrl]="form.get('note')"></app-show-errors>  
			</mat-form-field>
			

			<mat-label>Télécharger le pdf (la liste scolaire)</mat-label>
			<div class="input-group example-full-width mb-3">
			    
			    <input type="file"  #fileUpload id="fileUpload" name="fileUpload"  formControlName="files" class="form-control" id="inputGroupFile02">
			</div>

			<!--<mat-label>Produits</mat-label>
			<div class="input-group">
			    <mat-select class="  form-control form-control-sm mb-2" [formControl]="productsListCtrl"
					       [(ngModel)]="selectedProducts"
					multiple required>
				<input class="mat-select-input-search" placeholder ="recherche " (keyup)="onKeyProducts($event.target.value)"> 
				<mat-select-trigger>
				    {{productsListCtrl?.value?.[0]?.name || ''}}
				    <span *ngIf="(productsList?.length || 0) > 1" class="example-additional-selection">
					(+{{(selectedProducts?.length || 0) - 1}} {{selectedProducts?.length === 2 ? 'other' :
					'others'}})
				    </span>
				</mat-select-trigger>
				<mat-option *ngFor="let product of productsList" [value]="product">{{product.name}}</mat-option>
			    </mat-select>
			    <div class="input-group-append">
				<button type="button"class="btn btn-primary  form-control-sm" >
				    <mat-icon>add</mat-icon>
				</button>
			    </div>
			</div>
			-->

			<div class="row" *ngIf="selectedProducts && selectedProducts.length > 0">
			    <div class="col-12 left row">
				Produits
			    </div>
			    <div class="col-12 row "  *ngIf="3 as maxNbProducts">

				<span *ngFor="let product of selectedProducts;nb_products as index;let i = index;let c = count">

				    <span *ngIf="i < maxNbProducts || more_products;  "  class="badge badge-pill badge-info"> {{product?.name}}</span>
				    <button mat-button  color="primary" *ngIf="i  + 1== c && c > maxNbProducts" (click)="more_products = !more_products">{{ more_products ? 'voir moins' : 'voir plus' }}</button> 
				</span>


			    </div>
			</div>




		    </form>

		</ng-template>
	    </mat-step>

	    <mat-step >
                <ng-template matStepLabel><mat-icon>fact_check</mat-icon> Produits de la liste</ng-template>
		
		<!--
		     <div   class="row mt-5 top-right" layout="row" layout-align=" end">
		     <div  layout="column" layout-align="center end" >
		     <div><button mat-raised-button matStepperPrevious color="primary" class="mr-1" type="button">Précédent</button></div>
		     <div><button mat-raised-button matStepperNext color="primary" type="button">Suivant</button></div>
		     </div>
		     </div>
		-->

		<h6 style="color: black; font-size: small">Sélectionnez les produits et saisissez la quantité ci-dessous</h6>
		
                <div class="table-responsive">
		    <div fxLayout="row" fxLayoutAlign="end center">
			<mat-form-field fxFlex="60%">
			    <input matInput type="text" (keyup)="doFilter($event.target.value)"  name="filter" placeholder="Filtrer">
			</mat-form-field>
		    </div>

                    <table mat-table [dataSource]="datasourceSelection" matSort>
			
                        <ng-container matColumnDef="#">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">
                                <mat-checkbox></mat-checkbox>
                            </th>
                            <td style="width: 4%;" mat-cell *matCellDef="let element">
                                <mat-checkbox [(ngModel)]="element.checked"></mat-checkbox>  
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="image">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Image</th>
                            <td style="width: 15%;" mat-cell *matCellDef="let element">
                                <img class="bg-image" src="{{element?.img}}" *ngIf="element && element.img" />
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="product.name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Nom</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span>{{element?.product?.name}}</span>
				<span style="display: block;">
				    <span style="display: inline-block;
						 color: red;
						 font-size: xx-small;">
					*
				    </span>
				    <span style="display: inline-block;
						 color: black;
						 font-size: x-small;">
					{{element?.product?.status?.name == "disable" ? "Non publié" : "Publié"}}
				    </span>
				</span>

                            </td>
                        </ng-container>

			<ng-container matColumnDef="product.description">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Description</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span>{{element?.product?.description}}</span>
                            </td>
                        </ng-container>

			<ng-container matColumnDef="product.sku">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Reference</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span>{{element?.product?.sku}}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="price">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Prix</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span class="table-regular">{{element?.price  | number : '1.2-2' }} </span>
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="quantity">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Quantité</th>
                            <td style="width: 120px;" mat-cell *matCellDef="let element">
                                <!-- <input matInput maxlength="5" type="number" placeholder="0" [(ngModel)]="element.qty"  class="form-control form-control-sm" required> -->
                                <mat-form-field appearance="outline" floatLabel="always">
                                    <input matInput type="number" class="example-right-align" [(ngModel)]="element.quantity"  placeholder="0">
                                </mat-form-field>
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="Total">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Total</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span class="table-regular">{{(element.price * element.quantity)  | number : '1.2-2' }} €</span>
                            </td>
                        </ng-container>
			
			
                        <ng-container matColumnDef="Action">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-icon matTooltip="consulter" (click)="onConsulterProduct(element.productId)" class="icon" aria-hidden="false"
					  aria-label="Example home icon" fontIcon="preview"></mat-icon>
                            </td>
                        </ng-container>
			
			
			
                        <tr mat-header-row *matHeaderRowDef="columnsSelection"></tr>
                        <tr mat-row *matRowDef="let row; columns: columnsSelection;" class="example-element-row"></tr>
                    </table>
		    
		    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
			<mat-progress-spinner 
			    color="primary" 
			    mode="indeterminate">
			</mat-progress-spinner>
		    </mat-card>
		    
                    <mat-paginator [pageSizeOptions]="[20, 50, 100, 500]" [pageSize]="pageSize" showFirstLastButtons
				   aria-label="Select page of periodic elements">
                    </mat-paginator>
                </div>


		<button mat-raised-button matStepperPrevious color="primary" class="mr-1" type="button">Précédent</button>
                <button mat-raised-button matStepperNext color="primary" type="button">Suivant</button>

            </mat-step>

	    <mat-step>
		<ng-template matStepLabel><mat-icon>done</mat-icon> Confirmation</ng-template>


		<ng-template matStepContent>   
		    <div class="example-full-width d-flex justify-content-center" *ngIf="DisplayConfirmationButton">
			<button class="btn btn-primary" form="schoolListForm" [disabled]="form.invalid">Sauvegarder</button>
			
			<!-- <button mat-raised-button cdkFocusInitial>Annuler</button> -->
		    </div>

		</ng-template>
		
		
		<button mat-raised-button color="primary" matStepperPrevious>Précédent</button>
            </mat-step>


	</mat-stepper>



    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
