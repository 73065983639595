import { AfterViewInit, Component, EventEmitter, OnInit,  Output, Input,
	 ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

// models
import { Model } from 'src/app/interfaces/model';   
import { SchoolList } from 'src/app/interfaces/school-list';
import { School } from 'src/app/interfaces/school';
import { ClassLevel } from 'src/app/interfaces/class-level';
import { Product } from 'src/app/interfaces/product';
import { Note } from 'src/app/interfaces/note';
import { Status } from 'src/app/interfaces/status';
import { ClassGrade } from 'src/app/interfaces/class-grade';
import { File } from 'src/app/interfaces/file';

// services
import { SchoolListService } from 'src/app/services/school-list.service';
import { SchoolService } from 'src/app/services/school.service';
import { StatusService } from 'src/app/services/status.service';
import { FileService } from 'src/app/services/file.service';
import { ProductService } from 'src/app/services/product.service';
import { ClassLevelService } from 'src/app/services/class-level.service';
import { ClassGradeService } from 'src/app/services/class-grade.service';

//  
import { Router } from '@angular/router';

import { Response } from '../../interfaces/response';

import { switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

//
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';

import { MatSort } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';

import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';


@Component({
  selector: 'app-new-list-scolaire',
  templateUrl: './new-list-scolaire.component.html',
  styleUrls: ['./new-list-scolaire.component.scss'], encapsulation: ViewEncapsulation.None
})
export class NewListScolaireComponent implements OnInit {

    @ViewChild("fileUpload", {static: false}) fileUpload: ElementRef;

    
    @Input() TitleVisible: boolean = true;
    @Input() DisplayConfirmationButton: boolean = true;
    
    form: FormGroup;
    
    ////////////////////////
    // Attributes
    ////////////////////////

    
/*
  utilisateurs: any;
  permissions: any;

  toppings = new FormControl('');
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
*/
    files  = [];


    ////////////////////////
    // Classes
    ////////////////////////

    schoolsListCtrl = new FormControl('');
    schoolsData : School[];//string[];
    schoolsList : School[];//string[];
    selectedSchool : School;
    selectedSchools : School[];

    classLevelsListCtrl = new FormControl('');
    classLevelsList : ClassLevel[];//string[];
    classLevelsData : ClassLevel[];//string[];
    selectedClassLevel : ClassLevel;
    selectedClassLevels : ClassLevel[];


    classGradesListCtrl = new FormControl('');
    classGradesList : ClassGrade[];//string[];
    classGradesData : ClassGrade[];//string[];
    selectedClassGrades : ClassGrade[];
    selectedClassGrade : ClassGrade;

    
    // productsListCtrl = new FormControl('');
    // productsList : Product[];//string[];
    // productsData : Product[];//string[];
    // selectedProducts : Product[];

        ////////////////////////
    // PRODUCTS ATTRIBUTES 
    ////////////////////////
    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();   
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    
    //
    // Datatable
    //
    columnsSelection: string[] = ['#', 'image', 'product.name', 'product.description','product.sku', 'price', 'quantity', 'Total', 'Action' ];
    
    datasourceSelection: MatTableDataSource<Model> = new MatTableDataSource<Model>();

    pageSize = 150;
    
    //
    // Data Sources
    //
    productsListCtrl = new FormControl('');
    productsList : Product[];//string[];
    productsData : Product[];//string[];
    selectedProducts : Product[];

    // selectedProduct Converted 
    selectedProductDetails : any[] = [];
    productDetails : any[] = [];
    
    
    isLoading = true;
    shouldAddClass = false;

    
    ////////////////////////
    // Constructor
    ////////////////////////  
    
    constructor(private router: Router,
		private _snackBar: MatSnackBar,
		public fb: FormBuilder,

		private fileService: FileService,

		private schoolService: SchoolService,
		private classLevelService: ClassLevelService,
	
		private classGradeService: ClassGradeService,
		private schoolListService: SchoolListService,
		private productService: ProductService,
	     ) { 
      
    }

    ///////////////////////
    // Init
    ///////////////////////
    
    ngOnInit(): void {
	this.initDataTables();
	this.refresh();
	this.initForm();

    }

    ngAfterViewInit() {
	this.datasourceSelection.paginator = this.paginator;
	this.datasourceSelection.sort = this.sort;
	this.datasourceSelection.filterPredicate = this.tableFilter(); 

    }

    getUrlOf(element) : string {
	var path =  element.path ? element.path.replace(/^public?\//i, "") : null;
	return element && element.path ? `${environment.domains.api.url}${path}` : "";
    }


    ////////////////////////
    // Datatable
    ///////////////////////

    
    initDatasources() : void {
	
	this.datasourceSelection.data = this.productDetails;
	this.datasourceSelection.paginator = this.paginator;
	this.datasourceSelection.sort = this.sort;
	this.datasourceSelection.filterPredicate = this.tableFilter(); 
	
    }

    initDataTables(): void {
	this.setLoading(true);  
	this.productService.getAll().subscribe({
            next: (response: Response<number, Product[]>) => {
		this.productsData = response.Data;
		//this.productsData = this.productsData.filter(x => x?.status?.name != "disable");
		this.productDetails = this.productsData.map(product => this.fromProductDataToProductDetail(product));
		
		this.initDatasources(); 
		this.initProductsSearchDropdown();
		this.setLoading(false);
            },error: (e) => { this.setLoading(false); }, complete: () => console.info('load roles complete')
	});


    }

    getCheckedItems() {
	this.selectedProductDetails = this.datasourceSelection.data
	    .filter(i => (i as any).checked == true)
	    .map(product => this.fromProductDetailToProductData(product));

	console.log(this.selectedProductDetails);
    }

    fromProductDetailToProductData(productDetail) {
	let productData = {
	    productId : productDetail.productId,
//	    price : productDetail.price,
	    quantity: productDetail.quantity
	};
	return productData;
    }
    
    fromProductDataToProductDetail(product) {
	let productDetail = {
	    checked : false,
	    productId : product.id,
	    product: product, 
	    price : product.price,
	    quantity: 0,
	    img : product.mainImage? this.getUrlOf(product.mainImage) : ""
	}
	//console.log(productDetail);
	return productDetail;
	
    }
    
    ////////////////////////
    // Filter Datatable
    ///////////////////////

    doFilter(value: string) : void  {
	this.datasourceSelection.filter = value.trim().toLocaleLowerCase();
    }

    
    tableFilter(): (data: any, filter: string) => boolean {
	let columnsNames = this.columnsSelection;

	let filterFunction = function(data, filter): boolean{
	    

	    var datas = columnsNames.map(x => {
		// split string succh as "customer.name"
		var objectData = x.includes(".") ? x.split(".") : [x];
		var currentObject = data;

		// concat array to create object like data["customer"]["name"]
		objectData.forEach(key => {
		    currentObject = currentObject[key];
		})
		// get val and check if its related to filter
		let val = currentObject ?? data[x];
		if (val && val.toString().toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1){		    
		    return  data;
		}
		return null;
		
	    }).filter(x => x); // map and filter to eliminate null data in array

	    
	    return datas?.length > 0 ? true : false; 
	}
	return filterFunction;
     }


    ////////////////////////
    // Datasrouces
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    name: new FormControl(null, [Validators.required, Validators.maxLength(60), Validators.minLength(2)]),
	    note: new FormControl(null, [Validators.maxLength(200), Validators.minLength(0)]),
	    files : new FormControl(null, [])
	});

	
    }

    
    ////////////////////////
    // Datasrouces Form
    ///////////////////////

    refresh() : void {
	
	this.classGradeService.getAll().subscribe({
            next: (response: Response<number, ClassGrade[]>) => {
		this.classGradesData = response.Data.filter(x => x && x.classLevel && x.school);
		this.initClassGradesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load roles complete')
	});

	this.schoolService.getAllSchools().subscribe({
            next: (response: Response<number, School[]>) => {
		this.schoolsData = response.Data;
		this.initSchoolsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load schools complete')
	});

	this.classLevelService.getAllClassLevels().subscribe({
	    next: (response: Response<number, ClassLevel[]>) => {
		this.classLevelsData = response.Data;
		this.initClassLevelsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load classLevels complete')
	});

	/* this.productService.getAll().subscribe({
            next: (response: Response<number, Product[]>) => {
		this.productsData = response.Data;
		this.initProductsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load roles complete')
	}); */
    }

    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns

    initClassLevelsSearchDropdown(){
	this.classLevelsList = [];
        for ( let i = 0 ; i < this.classLevelsData.length; i ++ ) {
            this.classLevelsList.push( this.classLevelsData[i] );
        }
    }


    
    initSchoolsSearchDropdown(){
	this.schoolsList = [];
        for ( let i = 0 ; i < this.schoolsData.length; i ++ ) {
            this.schoolsList.push( this.schoolsData[i] );
        }
    }

    initClassGradesSearchDropdown(){
	this.classGradesList = [];
        for ( let i = 0 ; i < this.classGradesData.length; i ++ ) {
            this.classGradesList.push( this.classGradesData[i] );
        }
    }

    
    initProductsSearchDropdown(){
	this.productsList = [];
        for ( let i = 0 ; i < this.productsData.length; i ++ ) {
            this.productsList.push( this.productsData[i] );
        }
    }

    ////////////////////////
    // On Search
    
    onKeyProducts(value) { 
            this.productsList= []; 
            this.selectProductSearch(value);       
    }

    onKeyClassGrades(value) { 
            this.classGradesList= []; 
            this.selectClassGradesSearch(value);       
    }


    onKeySchools(value) { 
            this.schoolsList= []; 
            this.selectSchoolSearch(value);       
    }

    onKeyClassLevels(value) { 
            this.classLevelsList= []; 
            this.selectClassLevelsSearch(value);       
    }


    ////////////////////////
    // search elements


    selectClassLevelsSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.classLevelsData.length; i ++ ) {
            let option = this.classLevelsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.classLevelsList?.push( option );
            }
        }
    }

    selectSchoolSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.schoolsData.length; i ++ ) {
            let option = this.schoolsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0
		|| option.name?.toLowerCase().indexOf(filter) >= 0  ) {
                this.schoolsList?.push( option );
            }
        }
    }

    

    selectClassGradesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.classGradesData.length; i ++ ) {
            let option = this.classGradesData[i];
            if (  option.classLevel?.name.toLowerCase().indexOf(filter) >=0
		||  option.school?.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.classGradesList?.push( option );
            }
        }
    }

    selectProductSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.productsData.length; i ++ ) {
            let option = this.productsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0
		|| option.name?.toLowerCase().indexOf(filter) >= 0  ) {
                this.productsList?.push( option );
            }
        }
    }

    
    ////////////////////////////
    // elements selections
    ///////////////////////////
    
    
    OnClassLevelsChanged($event: any) {
	console.log($event);

	let school : any = this.selectedSchool;
	let classLevel = $event.value;

	if (school && school.id && classLevel && classLevel.id ) {

	    this.selectedClassGrade =  this.classGradesList.length > 0 ?
		this.classGradesList.find(x => x && x.schoolId == school.id  && x.classLevelId == classLevel.id)
		: null;
	}


    }

    OnSchoolsChanged($event: any) {
	console.log($event);

	let school : any = $event.value;
	let classLevel = this.selectedClassLevel;

	if (school && school.id && classLevel && classLevel.id ) {

	    this.selectedClassGrade =  this.classGradesList.length > 0 ?
		this.classGradesList.find(x => x && x.schoolId == school.id  && x.classLevelId == classLevel.id)
		: null;
	}

    }
    
    onClassGradesChanged($event: any) {
	console.log($event);

	let classGrade : any = $event.value;
	if (classGrade && classGrade.id) {
	    let classLevel = classGrade.classLevel;
	    let school = classGrade.school;

	    this.selectedClassLevel = classLevel && classLevel.id && this.classLevelsList.length > 0 ?
		this.classLevelsList.find(x => x && x.id == classLevel.id)
		: null;
	    this.selectedSchool = school && school.id && this.schoolsList.length > 0 ?
		this.schoolsList.find( x => x && x.id == school.id) : null ;
	}
    }

    onProductsChanged($event: any) {
      console.log($event);
    }

    
    ////////////////////////////
    // External actions
    ///////////////////////////
    
    addNewClassGrade(): void {
	this.shouldAddClass = !this.shouldAddClass;
/*    const dialogRef = this.dialog.open(AddRoleDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {

      }
    });*/
  }
    
    ////////////////////////
    ///////////////////////
    
    //////////////////////
    // Loading
    //////////////////////

    toggleLoading() : void {
	this.isLoading = !this.isLoading;
    }

    setLoading(loading : boolean) : void {
	this.isLoading = loading;
    }

    //////////////////////
    //////////////////////

    
    ////////////////////////
    // Links / URL
    ///////////////////////


    openInNewTab(namedRoute) {
	let newRelativeUrl = this.router.createUrlTree([namedRoute]);
	console.log(newRelativeUrl);
	let baseUrl = window.location.href.replace(this.router.url, '');

	window.open(baseUrl + newRelativeUrl, '_blank');
    }
    
    onConsulterProduct(id): void {
	this.openInNewTab(`${environment.paths.ConsulterProductPath}/${id}`);
    }

    
    ////////////////////////
    // Actions
    ///////////////////////


    
  submit() {
	if (!this.form.valid) {
	    return;
	}

	this.getCheckedItems();
	
	let schoolList : SchoolList = {
            name: this.form.value.name,

	};

	const note : Note = {
	    content : this.form.value.note
	}



	
	schoolList.note = note;

	// if product hasn't quantity
	//schoolList.products = this.selectedProducts;
	schoolList.classGrade = this.selectedClassGrade;

	if (this.selectedClassGrade && this.selectedClassGrade.id){
	    schoolList.classGradeId = this.selectedClassGrade.id;
	}
	else if (this.selectedSchool && this.selectedSchool.id
	    && this.selectedClassLevel && this.selectedClassLevel.id) {
	    let classGrade : ClassGrade = {
		schoolId : this.selectedSchool.id,
		classLevelId : this.selectedClassLevel.id,
	    };

	    schoolList.classGrade = classGrade;  
	}

	if (this.selectedProductDetails && this.selectedProductDetails.length > 0)
	    schoolList.schoolListProducts = this.selectedProductDetails;

	
	console.log("Gonna save school list : \n {0}".formatUnicorn(JSON.stringify(schoolList)));

	const fileUpload = this.fileUpload.nativeElement;
	this.files = fileUpload.files;
	const formData = new FormData();

	for (const file of this.files) {
	    console.log("Files : \n {file}".formatUnicorn({file: JSON.stringify(file)}));
	    console.log(file);
	    formData.append('files', file, file.name);
	}
	//formData.append('files', this.form.value.files);
	console.log(formData);
//	console.log("Boundary : {0} ".format(formData.getBoundary()));
	//schoolList.dataToUpload  = this.files[0]; // working for files/image/upload
//	schoolList.dataToUpload  = this.fileService.getBase64(this.files[0]); 
//	schoolList.dataToUpload  = this.files;
	schoolList.dataToUpload  = formData; // working for /files/upload/images
//	schoolList.dataToUpload  = {"files" : this.files};//


	
	this.schoolListService.prepareAddRelations(schoolList).pipe(
	    switchMap(preparedSchoolList => { 
		console.log("entered switch map -- add new schoolList ");
		console.log(preparedSchoolList);
		return this.schoolListService.add(schoolList);}),
	    switchMap(schoolListAddedObservable => {
		let schoolListAdded = schoolListAddedObservable.Data;
		    
		console.log("entered switch map -- handle post relations ");
		console.log(schoolListAdded);
		schoolListAdded.schoolListProducts = this.selectedProductDetails;
		//schoolListAdded.products = this.selectedProducts;
		schoolListAdded.classGrade = this.selectedClassGrade;
		return this.schoolListService.handlePostAddRelations(schoolListAdded); 
	    })
	
						      
	).subscribe({
	    next: (res) => { this.checkSubmitSucess(res); },
	    error: (error) => {this.submitError(error);},
	    complete: () => {
		console.info('save schoolList pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();
		    }, 5000);
		
	    }
	    
	    
	});
  }

    compare(o1: any, o2: any): boolean {
	// if possible compare by object's name, and not by reference.
	return o1 && o2 ? o1.name === o2.name : o2 === o2;
    }


    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListListScolairePath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }
}
