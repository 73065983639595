import { Component, EventEmitter, Input,  Output, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';

import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';


import { Note } from 'src/app/interfaces/note';
import { Status } from 'src/app/interfaces/status';
import { ProductCategory } from 'src/app/interfaces/product-category';
import { ProductType  } from 'src/app/interfaces/product-type';

import { ProductCategoryService } from 'src/app/services/product-category.service';
import { StatusService } from 'src/app/services/status.service';
import { ProductTypeService } from 'src/app/services/product-type.service';


import { Response } from '../../interfaces/response';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

import {ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-editer-category',
  templateUrl: './editer-category.component.html',
  styleUrls: ['./editer-category.component.scss'], encapsulation: ViewEncapsulation.None
})
export class EditerCategoryComponent  implements OnInit  {
  @Input() TitleVisible: boolean = true;
  @Input() DisplayConfirmationButton: boolean = true;

    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();

    ////////////////////////
    //  Attributes        
    ////////////////////////

    @Input()     _productCategory: ProductCategory;
    @Input('id') _id?: string;   
    
    form: FormGroup;

    productTypesListCtrl = new FormControl('');
    productTypesData : ProductType[];//string[];
    productTypesList : ProductType[];//string[];
    selectedProductTypes : ProductType[];

    
    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatuses : Status[];
    selectedStatus : Status;

    ////////////////////////
    // Constructor
    ////////////////////////

    constructor(private router: Router,
		private route: ActivatedRoute,
		private productCategoryService : ProductCategoryService,
		private statusService : StatusService,
		private productTypeService : ProductTypeService,
		
		private _snackBar: MatSnackBar,
		public fb: FormBuilder) { 
 
      }
      
    ////////////////////////
    // Init
    ////////////////////////  
    ngOnInit(): void {
	this.initCurrentRoute();
	this.refresh();
	this.initForm(); 

    }

    initCurrentRoute():void {
	this.route.params.subscribe(params => {  
	    this._id = params["id"];
	    console.log("current id " + this._id)
	});

    }

    initCurrentObject(): void {
	
      this.productCategoryService.getById(this._id).subscribe(
	  {
              next: (response: Response<number, ProductCategory>) => {
		  console.log("response");
		  console.log(response);
		  this._productCategory = response.Data;
		  this.selectedProductTypes = this._productCategory?.productTypes;
		  if (this._productCategory?.status)
		      this.selectedStatus = this._productCategory?.status ;
		  if (this._productCategory?.note) {
		      
		  } else {
		      const note : Note = {  content :  "" };
		      this._productCategory.note = note;
		  }
		  console.log(this._productCategory.status);
		  console.log(this._productCategory);
		  
              },
              error: (e) => {
		  console.log(e);
              },
              complete: () => console.info('loaduser complete')
	  }
      );

    }



    ////////////////////////
    // Datasrouces
    ///////////////////////


    
    refresh(): void {
	this.initCurrentObject();
	
	this.statusService.getAllStatuses().subscribe({
	    next: (response: Response<number, Status[]>) => {
		
		this.statusesData = response.Data;
		this.initStatusesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load statuses complete')
	});

	this.productTypeService.getAllProductTypes().subscribe({
            next: (response: Response<number, ProductType[]>) => {
		this.productTypesData = response.Data;
		this.initProductTypesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load productTypes complete')
	});

    }

    ////////////////////////
    // Datasrouces Form
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    name: new FormControl(null, [Validators.required, Validators.maxLength(60), Validators.minLength(2)]),
	    description: new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(2)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
	});

    }
    
    ////////////////////////
    // Datasources Functions
    ///////////////////////

    

    compareFn(c1,c2): boolean {
	return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }
    
    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns


    initProductTypesSearchDropdown(){
	this.productTypesList = [];
        for ( let i = 0 ; i < this.productTypesData.length; i ++ ) {
            this.productTypesList.push( this.productTypesData[i] );
        }
    }


    initStatusesSearchDropdown(){
	this.statusesList = [];
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
	    {

		if (!this.selectedStatus && this.statusesData[i].label == "Actif")
		{
		    this.selectedStatuses = [];
		    this.selectedStatus = this.statusesData[i];
		    
		    this.selectedStatuses.push(this.statusesData[i]);
		}
		this.statusesList.push( this.statusesData[i] );
	    }
        }

    }
    
    ////////////////////////
    // On change

    onStatusesChanged($event: any) {
      console.log($event);
    }

    onProductTypesChanged($event: any) {
      console.log($event);
    }


    ////////////////////////
    // On Search
    onKeyProductTypes(value) { 
            this.productTypesList= []; 
            this.selectProductTypeSearch(value);       
    }



    onKeyStatuses(value) { 
            this.statusesList= []; 
            this.selectStatusesSearch(value);       
    }

    ////////////////////////
    // search elements


    selectProductTypeSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.productTypesData.length; i ++ ) {
            let option = this.productTypesData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0
		|| option.name?.toLowerCase().indexOf(filter) >= 0 ) {
                this.productTypesList?.push( option );
            }
        }
    }

    
    selectStatusesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
            let option = this.statusesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.statusesList?.push( option );
            }
        }
    }




    ////////////////////////
    ///////////////////////
    
    
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid) {
	    return;
	}

	let productCategory : ProductCategory = {
            name: this.form.value.name,
	    description: this.form.value.description,
	};


	const note : Note = {
	    content : this.form.value.note
	}

	if ((this._productCategory.note &&  this._productCategory.note.id ) || this._productCategory.noteId ){
	    note.id =  this._productCategory.note && this._productCategory.note.id ?  this._productCategory.note.id :  this._productCategory.noteId ; 
	}
	
	if (this.selectedStatus){
	    this._productCategory.statusId = this.selectedStatus.id;
	}


	this._productCategory.note = note;
	this._productCategory.productTypes = this.selectedProductTypes;
		
	console.log(this._productCategory);
	console.log("gonna save");
	console.log(this.selectedProductTypes);
	

	this.productCategoryService.prepareUpdateRelations(this._productCategory).pipe(
	    switchMap(preparedProductCategory => { 
		console.log("entered switch map -- add new productCategory ");
		console.log(preparedProductCategory);
		return this.productCategoryService.updateById(this._productCategory.id, this._productCategory);
	    }),
	    switchMap(productCategoryUpdatedObservable => {
		let productCategoryUpdated = productCategoryUpdatedObservable.Data;
		    
		console.log("entered switch map -- handle post relations ");
		console.log(this._productCategory);
		this._productCategory.productTypes = this.selectedProductTypes;
		return this.productCategoryService.handlePostUpdateRelations(this._productCategory); 
	    })
	
						      
	).subscribe({
	    next: (res) => { this.checkSubmitSucess(res);},
	    error: (error) => {this.submitError(error)},
	    complete: () => {
		console.info('save productCategory pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();
		    }, 5000);
		
	    }
	    
	    
	});

    }

    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListCategoryPath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }
}

