import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';


import { Product } from 'src/app/interfaces/product';
import { ProductType } from 'src/app/interfaces/product-type';
import { ProductCategory } from 'src/app/interfaces/product-category';
import { Status } from 'src/app/interfaces/status';

import { ProductService } from 'src/app/services/product.service';
import { ProductTypeService } from 'src/app/services/product-type.service';
import { ProductCategoryService } from 'src/app/services/product-category.service';
import { StatusService } from 'src/app/services/status.service';

import { environment } from 'src/environments/environment';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Response } from '../../interfaces/response';
import { Router } from '@angular/router';

import { Observable, forkJoin } from 'rxjs';

import { MatSort } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout'; 
import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@Component({
  selector: 'app-list-category',
  templateUrl: './list-category.component.html',
  styleUrls: ['./list-category.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ListCategoryComponent implements OnInit, AfterViewInit {
    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
    @ViewChild(MatPaginator) paginator: MatPaginator;

    //////////////////////
    // Attributes   
    ////////////////////// 

    columns: string[] = ['#', 'name', 'description', 'productTypes', 'note', 'status', 'Action' ];
    datasource: MatTableDataSource<ProductCategory> = new MatTableDataSource<ProductCategory>();
    pageSize = 10;

    images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);
    
    productCategories: ProductCategory[];

    productTypesListCtrl = new FormControl('');
    productTypesData : ProductCategory[];//string[];
    productTypesList : ProductCategory[];//string[];
    selectedProductTypes : ProductCategory[];

    productsListCtrl = new FormControl('');
    productsList : Product[];//string[];
    productsData : Product[];//string[];
    selectedProductes : Product[];
    
    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatus : Status;
    
    isLoading = true;

    //////////////////////
    // Constructor       
     //////////////////////   

  constructor(private productCategoryService: ProductCategoryService,
		private productService : ProductService,
		private productTypeService : ProductTypeService,
		private statusService : StatusService,
	      private router: Router,
	      public dialog: MatDialog) {
      
  }


        //////////////////////
    // Init
    //////////////////////

    
    ngAfterViewInit() {
	this.datasource.paginator = this.paginator;
    }

    ngOnInit(): void {
	this.refresh();
    }


    //////////////////////
    // Data sources
    //////////////////////

    doFilter(value: string) : void  {
	this.datasource.filter = value.trim().toLocaleLowerCase();
    }

    
    refresh(): void {
	this.setLoading(true);
	this.productCategoryService.getAllProductCategories().subscribe(  
	    {
		next: (response: Response<number, ProductCategory[]>) => {
		    console.log(response);
		    this.productCategories = response.Data;
		    this.datasource.data = this.productCategories;
		    this.datasource.paginator = this.paginator;
		    this.setLoading(false);
		    console.log(this.productCategories);

		},
		error: (e) => {
		    this.setLoading(false);
		},
		complete: () => console.info('loadMenu complete')
	    }
	);
	this.productTypeService.getAll().subscribe({
	    next: (response: Response<number, ProductCategory[]>) => {
		this.productTypesList = response.Data;
            },error: (e) => { }, complete: () => console.info('load productTypes complete')
	});
	this.statusService.getAll().subscribe({
	    next: (response: Response<number, Status[]>) => {
		this.statusesList = response.Data;
            },error: (e) => { }, complete: () => console.info('load status complete')
	});
	this.productService.getAll().subscribe({
	    next: (response: Response<number, Product[]>) => {
		this.productsList = response.Data;
            },error: (e) => { }, complete: () => console.info('load status complete')
	});
    }

    //////////////////////
    // Loading
    /////////////////////

    toggleLoading() : void {
	this.isLoading = !this.isLoading;
    }

    setLoading(loading : boolean) : void {
	this.isLoading = loading;
    }

    //////////////////////
    //////////////////////

    

    //////////////////////
    // Actions
    //////////////////////

  onAddNewCategory(): void {
    this.router.navigate([environment.paths.NewCategoryPath]);
  }

  onEditCategory(id): void {
      this.router.navigate([environment.paths.EditerCategoryPath, id]);
  }

  onConsulterCategory(id): void {
      this.router.navigate([environment.paths.ConsulterCategoryPath, id]);
  }

  onDelete(id): void {
    const dialogRef = this.dialog.open(ConfirmerSuppressionDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {

      	  this.productCategoryService.deleteById(id).subscribe(
	      {
		  next: (response: Response<number, ProductType>) => {
		      console.log(response);
		      this.refresh();
		  },
		  error: (e) => {
		      console.log(e);
		  },
		  complete: () => console.info('delete complete')
	      }
	  );
      }

    });
  }
}
