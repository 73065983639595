<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">NOUVEAU CLIENT</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Clients</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">


        <h3 style="color: black; font-size:medium">Clients</h3>
        <h6 style="color: black; font-size: small">Remplir les informations ci-dessous</h6>
        <form class="example-form" [formGroup]="form" (ngSubmit)="submit()">

	    <div class="row mb-2">
		<div class="col"> 
		    <mat-label>Nom</mat-label>
		    <input type="text" matInput formControlName="lastname" class="form-control form-control-sm mb-2" required>
		    <app-show-errors [ctrl]="form.get('lastname')"></app-show-errors>
		</div>

	        <div class="col">
		    <mat-label>Pr�nom</mat-label>
                    <input  formControlName="firstname"  class="form-control form-control-sm"  matInput type="text" 
					     required>
		    <app-show-errors [ctrl]="form.get('firstname')"></app-show-errors>
                </div>
	    </div>

	    
	    
	    <div class="row mb-2">			    
		<div class="col">
                        <mat-label>Mot de passe </mat-label>
                        <input  type="password" matInput formControlName="password" class="form-control form-control-sm" required>
			<app-show-errors [ctrl]="form.get('password')"></app-show-errors>
                </div>
                <div class="col">
                        <mat-label>V�rification du mot de passe</mat-label>
                        <input  type="password" matInput formControlName="passwordcheck" class="form-control form-control-sm" required>
			<app-show-errors [ctrl]="form.get('passwordcheck')"></app-show-errors>
                </div>
            </div>

	    
	    
	    <div class="row mt-3" style="margin-top;5px;margin-bottom;5px;">
		<mat-divider ></mat-divider>
		<h6 style="color: black; ">Informations</h6>
		<mat-divider [inset]="true"></mat-divider>
	    </div>

	    <div class="row mb-2">
		<div class="col">
                    <mat-label>Téléphone</mat-label>
                        <input matInput formControlName="phone"  class="form-control form-control-sm" type="text"  required>
			<app-show-errors [ctrl]="form.get('phone')"></app-show-errors>
                </div>
                <div class="col">
                        <mat-label>Email</mat-label>
                        <input type="email" matInput formControlName="email" class="form-control form-control-sm" required>
			<app-show-errors [ctrl]="form.get('email')"></app-show-errors>
                </div>
	    </div>



	    
	    <div class="row mt-3" style="margin-top;5px;margin-bottom;5px;">
		<h6 style="color: black; ">Adresse</h6>
		<mat-divider [inset]="true"></mat-divider>
	    </div>

	    <div class="">
		<div class="row">
                    <div class="col-6">
			<mat-label>Numéro de rue</mat-label>
			<input matInput #numRue  type="text" placeholder="Ex. 21" formControlName="street"
                               class="form-control form-control-sm" required>
			<app-show-errors [ctrl]="form.get('street')"></app-show-errors>
                    </div>
                    <div class="col-6">
			<mat-label>Ville</mat-label>
			<input matInput formControlName="city" type="text" class="form-control form-control-sm" required>
			<app-show-errors [ctrl]="form.get('city')"></app-show-errors>
                    </div>
		    
		</div>

		<div class="row">
                    <div class="col-6">
			<mat-label>Code Postale</mat-label>
			<input matInput #postalCode maxlength="5" type="number" placeholder="Ex. 94105"
                               formControlName="zip" class="form-control form-control-sm" required>
			<mat-hint align="end" style="color: black;">{{postalCode.value.length}} / 5</mat-hint>
			<app-show-errors [ctrl]="form.get('zip')"></app-show-errors>
                    </div>
                    <div class="col-6">
			<mat-select-country  #countryChild formControlName="country" appearance="fill" label="Pays"
					     (onCountrySelected)="onCountrySelected($event)"
					     loadingDB="true"
					     [value]="defaultCountry"
					     required>
			    
			</mat-select-country>
			<app-show-errors [ctrl]="form.get('country')"></app-show-errors>
                    </div>
		</div>
	    </div>

	    <div class="row mt-3" style="margin-top;5px;margin-bottom;5px;">
		<mat-divider ></mat-divider>
		<h6 style="color: black; ">Adresse de facturation</h6>
		<mat-divider [inset]="true"></mat-divider>
	    </div>
	    
	    <div class="">
		<div class="row">
                    <div class="col-6">
			<mat-label>Numéro de rue</mat-label>
			<input matInput #billingNumRue  type="text" placeholder="Ex. 21" formControlName="billingStreet"
                               class="form-control form-control-sm" required>
			<app-show-errors [ctrl]="form.get('billingStreet')"></app-show-errors>
                    </div>
                    <div class="col-6">
			<mat-label>Ville</mat-label>
			<input matInput formControlName="billingCity" type="text" class="form-control form-control-sm" required>
			<app-show-errors [ctrl]="form.get('billingCity')"></app-show-errors>
                    </div>
		    
		</div>

		<div class="row">
                    <div class="col-6">
			<mat-label>Code Postale</mat-label>
			<input matInput #billingPostalCode maxlength="5" type="number" placeholder="Ex. 94105"
                               formControlName="billingZip" class="form-control form-control-sm" required>
			<mat-hint align="end" style="color: black;">{{billingPostalCode.value.length}} / 5</mat-hint>
			<app-show-errors [ctrl]="form.get('billingZip')"></app-show-errors>
                    </div>
		</div>
	    </div>
	    
	    <div class="row mt-3" style="margin-top;5px;margin-bottom;5px;">
		<h6 style="color: black; "></h6>
		<mat-divider [inset]="true"></mat-divider>
	    </div>
	    
	    <div >
		<mat-label>Sociétés</mat-label>
		<div class="input-group">
                    <mat-select placeholder="Choisir des Expéditions" class="  form-control form-control-sm mb-2" [formControl]="companiesListCtrl"  required [(ngModel)]="selectedCompany">
			<input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyCompanies($event.target.value)"> 
			<mat-select-trigger>
                            {{selectedCompany?.name  || ''}} 
			    <span *ngIf="(companiesList?.length || 0) > 1" class="example-additional-selection">
				(+{{(selectedCompanies?.length || 0) - 1}} {{selectedCompanies?.length === 2 ? 'autre' :
				'autres'}})
			    </span>
			</mat-select-trigger>
			<mat-option *ngFor="let company of companiesList" [value]="company">{{company.name}} </mat-option>
		    </mat-select>
		</div>
		
	    </div>
	    
            <div class="row">
                <div class="col-6">
                    <mat-label>Status</mat-label>
                    <div class="input-group">
			<mat-select placeholder="Choisir un statut pour ce produit" class="  form-control form-control-sm mb-2"   required
				    [(ngModel)]="selectedStatus" [formControl]="statusesListCtrl"  [compareWith]="compare" (selectionChange)="onStatusesChanged($event)"  >
			    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyStatuses($event.target.value)">  
			    <mat-select-trigger>
				{{selectedStatus?.label  || ''}}
				<span *ngIf="(selectedStatuses?.length || 0) > 1" class="example-additional-selection">
				    (+{{(selectedStatuses?.length || 0) - 1}} {{selectedStatuses?.length === 2 ? 'autre' :
				    'autres'}})
				</span>
			    </mat-select-trigger>
			    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
			</mat-select>
                    </div>
                </div>
            </div>

	    <div class="row mt-3">
                <div class="col-12">
                    <mat-form-field appearance="outline" class="example-full-width mb-2">
                        <mat-label>Note</mat-label>
                        <textarea style="height: 100px;" formControlName="note" matInput placeholder="Note" required></textarea>
			<app-show-errors [ctrl]="form.get('note')"></app-show-errors>
                    </mat-form-field>
                </div>
	    </div>
	    
            <div class="example-full-width d-flex justify-content-center" *ngIf="DisplayConfirmationButton">
                <button class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>

                <!-- <button mat-raised-button cdkFocusInitial>Annuler</button> -->
            </div>



        </form>




    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
