<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">LISTE DES PERMISSIONS</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Permissions</a></li>
                    <li class="breadcrumb-item active">Permission List</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content bdy" style="background-color: white;">
    <div class="container-fluid" style="padding: 30px;">


        <button  class="btn btn-primary btn-sm" (click)="onAddNewPermission()">Ajouter une permission</button>

        <div class="row mb-3 mt-3">

            <div class="col-2">
                <mat-label>Nom</mat-label>

                <mat-select   placeholder="Saisir nom" class="  form-control form-control-sm mb-2" [formControl]="permissionsCtrl" multiple required>
                    <mat-select-trigger>
                        {{permissionsCtrl.value?.[0]?.label || ''}}
                        <span *ngIf="(permissions?.length || 0) > 1" class="example-additional-selection">
                            (+{{(permissions?.length || 0) - 1}} {{permissions?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let permission of permissions" [value]="permission">{{permission.label}}</mat-option>
                </mat-select>

            </div>
            <div class="col-2">
                <mat-label>Roles</mat-label>

                <mat-select   placeholder="Saisir role" class="form-control form-control-sm mb-2" [formControl]="rolesListCtrl" multiple required>
                    <mat-select-trigger>
                        {{rolesListCtrl.value?.[0]?.label || ''}}
                        <span *ngIf="(rolesList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(rolesList?.length || 0) - 1}} {{rolesList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let role of rolesList" [value]="role">{{role.label}}</mat-option>
                </mat-select>
		
            </div>
            <div class="col-2">
                <mat-label>Utilisateurs</mat-label>

                <mat-select  placeholder="Saisir utilisateur" class="form-control form-control-sm mb-2" [formControl]="profilesListCtrl" multiple required>
                    <mat-select-trigger>
                        {{profilesListCtrl.value?.[0]?.firstname  || ''}} {{profilesList?.[0]?.lastname  || ''}} 
                        <span *ngIf="(profilesList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(profilesList?.length || 0) - 1}} {{profilesList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let profile of profilesList" [value]="profile">{{profile.fistname}} {{profile.lastname}}</mat-option>
                </mat-select>

            </div>
            <div class="col-2">
                <mat-label>Status</mat-label>

                <mat-select class="  form-control form-control-sm mb-2" [formControl]="statusesListCtrl" multiple required>
                    <mat-select-trigger>
                        {{statusesListCtrl.value?.[0]?.label || ''}}
                        <span *ngIf="(statusesList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(statusesList?.length || 0) - 1}} {{statusesList?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
                </mat-select>
            </div>

        </div>

        <div class="table-responsive">
	    <div fxLayout fxLayoutAlign="end center">
		<mat-form-field fxFlex="60%">
		    <input matInput type="text" (keyup)="doFilter($event.target.value)"  name="filter" placeholder="Filtrer">
		</mat-form-field>
	    </div>
            <table mat-table [dataSource]="datasource" matSort>

                <ng-container matColumnDef="#">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">#</th>
                    <td style="width: 4%;" mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.id}}</span>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.background-color]="color"> Nom</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.name}}</span>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="label">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.background-color]="color"> Label</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.label}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Description</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="table-regular">{{element.description}}</span>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="roles">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.background-color]="color"> Roles</th>
                    <td mat-cell *matCellDef="let element">
<!--                         <mat-chip-set role="list">
                            <mat-chip role="listitem" class="table-regular">Administrateur</mat-chip>
                        </mat-chip-set> -->
                        <span *ngFor="let role of element.roles ; nb_roles as index;let i = index"   class="badge  badge-info">
			    <span *ngIf="i < 3 || more_roles">{{role?.label}}</span>
			</span>
			<mat-divider></mat-divider>
			<button mat-button  color="primary" *ngIf="element?.roles?.length > 3" (click)="more_roles = !more_roles">{{ more_roles ? 'voir moins' : 'voir plus' }}</button>
			
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="users">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.background-color]="color"> Utilisateurs</th>
                    <td mat-cell *matCellDef="let element">
<!--                         <mat-chip-set role="list">
                            <mat-chip role="listitem" class="table-regular"> Lister les produits </mat-chip>
                            <mat-chip role="listitem" class="table-regular"> Editer </mat-chip>
                        </mat-chip-set> -->
			<span *ngFor="let user of element.users ; nb_users as index;let i = index"  class="badge badge-pill badge-primary">
			    <span *ngIf="(user?.profile) && (i < 3 || more_users)">{{user?.profile?.firstname}} {{user?.profile?.lastname}}</span>
			    <span *ngIf="(!user?.profile) && (i < 3 || more_users)">{{user?.username}}</span>
			    
			</span>
			<mat-divider></mat-divider>
			<button mat-button  color="primary" *ngIf="element?.users?.length > 3" (click)="more_users = !more_users">{{ more_users ? 'voir moins' : 'voir plus' }}</button> 
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Action</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon matTooltip="consulter" (click)="onConsulterPermission(element.id)" class="icon" aria-hidden="false" aria-label="Example home icon"
                            fontIcon="preview"></mat-icon>
                        <mat-icon matTooltip="editer" (click)="onEditPermission(element.id)" class="icon" aria-hidden="false" aria-label="Example home icon"
                            fontIcon="edit"></mat-icon>
                        <mat-icon matTooltip="supprimer" (click)="onDelete(element.id)" class="icon" aria-hidden="false" aria-label="Example home icon"
                            fontIcon="delete"></mat-icon>
                    </td>
                </ng-container>
    
    
    
                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;" class="example-element-row"></tr>
            </table>

	    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
		<mat-progress-spinner 
		    color="primary" 
		    mode="indeterminate">
		</mat-progress-spinner>
	    </mat-card>

		    
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="pageSize" showFirstLastButtons
                aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>


    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
