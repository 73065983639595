import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';



// models
import { Delivery } from '../interfaces/delivery';
import { Note } from '../interfaces/note';

// services
import {NoteService} from '../services/note.service'

// rxjs
import { of, from,  map, Observable, exhaustMap, EMPTY, empty ,identity, forkJoin} from 'rxjs';
import { mergeMap, mergeAll, toArray, switchMap} from 'rxjs/operators';

// response   
import { Response as Rep } from '../interfaces/response';

import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"


@Injectable({
  providedIn: 'root'
})
export class DeliveryService  extends BaseCrudModel<Delivery>{

    getDeliveryWithOrders = {url: `${environment.domains.api.url}/deliveries/orders`, urlOptions: this.options};

    constructor(protected override httpService: HttpService,
	       	private noteService : NoteService) {
	super(httpService, "deliveries");

	var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "objectDetail"},
		{"relation" : "note"},
		{"relation" : "address"}
	    ],
	});

	var addOptions =  RequestOptions.FromArray({
	    relationsToOmit : ['note', 'status']
	});

	this.methods.listing.urlOptions = options;
	this.methods.view.urlOptions = options;
	this.methods.add.urlOptions = addOptions;
	this.methods.update.urlOptions = addOptions;

    }

    getAllDeliveries(): Observable<Rep<number, Delivery[]>> {
	const options = new RequestOptions();

	return this.getAll()
    }

    getAllDeliveriesWithOrders(): Observable<Rep<number, Delivery[]>> {

	return this.httpService.makeGetRequest<Delivery[]>( `${this.getDeliveryWithOrders.url}`, this.methods.view.urlOptions);

    }


    ////////////////////////////////
    // PREPARE || HANDLE Relations
    ////////////////////////////////
    
    override prepareRelations(fromDelivery): Observable<Rep<number, Delivery>> { //Observable<Profile> {
	// values to save
	const delivery : Delivery = fromDelivery;


	// Obs Functions
	const failObj : Rep<number, Note> = {IsSuccess:  0, Data:  null, Status : 0};

	const a$ = of();

	const note$ : Observable<Rep<number, Note>> = delivery.note ? 
	    ( delivery.note.id ? this.noteService.updateById(fromDelivery.note.id, fromDelivery.note) : this.noteService.add(fromDelivery.note) )
	    :  this.httpService.createObservableResponse<Note>(failObj);

	
	return note$
	    .pipe(
		map(note => 
		    {
			console.log("created note");
			console.log(note);
			console.log("before setting Note");
			console.log(delivery);
			(delivery.noteId = note && note.IsSuccess ?
			    (note.Data && note.Data.id ? note.Data.id : delivery.noteId) : delivery.noteId );
			
			return   this.httpService.createResponseFromObj<Delivery>(delivery)
		    }),
		map(rel =>
		    {
			console.log("final result");
			console.log(rel);
			// return profile
			return    this.httpService.createResponseFromObj<Delivery>(delivery) ;
		    }),
		
	    );
    }
}
