import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';


import { Product } from 'src/app/interfaces/product';
import { ProductType } from 'src/app/interfaces/product-type';
import { ProductCategory } from 'src/app/interfaces/product-category';
import { Status } from 'src/app/interfaces/status';

import { ProductService } from 'src/app/services/product.service';
import { ProductTypeService } from 'src/app/services/product-type.service';
import { ProductCategoryService } from 'src/app/services/product-category.service';
import { StatusService } from 'src/app/services/status.service';

import { environment } from 'src/environments/environment';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Response } from '../../interfaces/response';
import { Router } from '@angular/router';

import { Observable, forkJoin } from 'rxjs';

import { MatSort } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout'; 
import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@Component({
  selector: 'app-list-product',
  templateUrl: './list-product.component.html',
  styleUrls: ['./list-product.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ListProductComponent implements OnInit, AfterViewInit {
    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
    @ViewChild(MatPaginator) paginator: MatPaginator;

    //////////////////////
    // Attributes   
    ////////////////////// 

    columns: string[] = ['#', 'mainImage', 'name', 'sku', 'price', 'stock', 'productType', 'Action' ];
    datasource: MatTableDataSource<ProductCategory> = new MatTableDataSource<ProductCategory>();
    pageSize = 10;

    //images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);
    images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);
    
    products: Product[];

    productCategoriesListCtrl = new FormControl('');
    productCategoriesData : ProductCategory[];//string[];
    productCategoriesList : ProductCategory[];//string[];
    selectedProductCategories : ProductCategory[];

    productTypesListCtrl = new FormControl('');
    productTypesList : ProductType[];//string[];
    productTypesData : ProductType[];//string[];
    selectedProductTypes : ProductType[];
    
    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatus : Status;
    
    isLoading = true;

    //////////////////////
    // Constructor       
     //////////////////////   

    constructor(private productService: ProductService,
		private productCategoryService: ProductCategoryService,
		
		private productTypeService : ProductTypeService,
		private statusService : StatusService,
		private router: Router,
		public dialog: MatDialog) {
      
  }

    
    //////////////////////
    // INIT
    //////////////////////
    
    ngAfterViewInit() {
	this.datasource.paginator = this.paginator;
    }

    ngOnInit(): void {
	this.refresh();
    }


    //////////////////////
    // TOOLS
    //////////////////////

    getUrlOf(element) : string {
	var path =  element.path ? element.path.replace(/^public?\//i, "") : null;
	return element && element.path ? `${environment.domains.api.url}${path}` : "";
    }
    
    //////////////////////
    // Data sources
    //////////////////////

    doFilter(value: string) : void  {
	this.datasource.filter = value.trim().toLocaleLowerCase();
    }

    
    refresh(): void {
	this.setLoading(true);
	this.productService.getAllProducts().subscribe(
	    {
		next: (response: Response<number, Product[]>) => {
		    this.products = response.Data;
		    this.datasource.data = this.products;
		    this.datasource.paginator = this.paginator;
		    this.setLoading(false);
		    console.log(this.products);
		},
		error: (e) => {
		},
		complete: () => console.info('loadMenu complete')
	    }
	);
	this.productTypeService.getAll().subscribe({
	    next: (response: Response<number, ProductCategory[]>) => {
		this.productTypesList = response.Data;
            },error: (e) => { }, complete: () => console.info('load productTypes complete')
	});
	this.productCategoryService.getAllProductCategories().subscribe({
	    next: (response: Response<number, ProductCategory[]>) => {
		console.log(response);
		this.productCategoriesList = response.Data;
	    },error: (e) => { },complete: () => console.info('loadMenu complete')
	});

	this.statusService.getAll().subscribe({
	    next: (response: Response<number, Status[]>) => {
		this.statusesList = response.Data;
            },error: (e) => { }, complete: () => console.info('load status complete')
	});
    }

    //////////////////////
    // Loading
    /////////////////////

    toggleLoading() : void {
	this.isLoading = !this.isLoading;
    }

    setLoading(loading : boolean) : void {
	this.isLoading = loading;
    }

    //////////////////////
    //////////////////////

    

    //////////////////////
    // Actions
    //////////////////////

  onAddNewProduct(): void {
    this.router.navigate([environment.paths.NewProductPath]);
  }

  onEditProduct(id): void {
      this.router.navigate([environment.paths.EditerProductPath, id]);
  }

  onConsulterProduct(id): void {
      this.router.navigate([environment.paths.ConsulterProductPath,id]);
  }

  onDelete(id): void {
    const dialogRef = this.dialog.open(ConfirmerSuppressionDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
      	  this.productService.deleteById(id).subscribe(
	      {
		  next: (response: Response<number, Product>) => {
		      console.log(response);
		      this.refresh();
		  },
		  error: (e) => {
		      console.log(e);
		  },
		  complete: () => console.info('delete complete')
	      }
	  );
      }
    });
  }
}
