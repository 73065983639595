import { Country } from '@angular-material-extensions/select-country';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { SchoolService } from 'src/app/services/school.service';
import { ClassGradeService } from 'src/app/services/class-grade.service';
import { ClassLevelService } from 'src/app/services/class-level.service';

import { School } from 'src/app/interfaces/school';
import { Note } from 'src/app/interfaces/note';
import { ClassGrade } from 'src/app/interfaces/class-grade';
import { ClassLevel } from 'src/app/interfaces/class-level';

import { Response } from '../../interfaces/response';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-class',
  templateUrl: './new-class.component.html',
  styleUrls: ['./new-class.component.scss'], encapsulation: ViewEncapsulation.None
})
export class NewClassComponent  implements OnInit  {
    @Input() TitleVisible: boolean = true;
    @Input() DisplayConfirmationButton: boolean = true;

    form: FormGroup;
    
    ////////////////////////
    // Attributes
    ////////////////////////

    schoolsListCtrl = new FormControl('');
    schoolsData : School[];//string[];
    schoolsList : School[];//string[];
    selectedSchool : School;
    selectedSchools : School[];

    classLevelsListCtrl = new FormControl('');
    classLevelsList : ClassLevel[];//string[];
    classLevelsData : ClassLevel[];//string[];
    selectedClassLevel : ClassLevel;
    selectedClassLevels : ClassLevel[];



    ////////////////////////
    // Constructor
    ////////////////////////  

    constructor(private router: Router,
		private _snackBar: MatSnackBar,
		public fb: FormBuilder,
		
		private schoolService: SchoolService,
		private classLevelService: ClassLevelService,
		private classGradeService: ClassGradeService,
	       ) { 
 
    }
      


    ////////////////////////
    // Init
    ///////////////////////
    
    ngOnInit(): void {

	
	this.refresh();
	this.initForm();
    }

    ////////////////////////
    // Datasrouces
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
    });
    }

    ////////////////////////
    // Datasrouces
    ///////////////////////

    refresh(): void {
	
	this.schoolService.getAllSchools().subscribe({
            next: (response: Response<number, School[]>) => {
		this.schoolsData = response.Data;
		this.initSchoolsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load schools complete')
	});

	this.classLevelService.getAllClassLevels().subscribe({
	    next: (response: Response<number, ClassLevel[]>) => {
		this.classLevelsData = response.Data;
		this.initClassLevelsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load classLevels complete')
	});


    }

    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns
    
    initClassLevelsSearchDropdown(){
	this.classLevelsList = [];
        for ( let i = 0 ; i < this.classLevelsData.length; i ++ ) {
            this.classLevelsList.push( this.classLevelsData[i] );
        }
    }


    
    initSchoolsSearchDropdown(){
	this.schoolsList = [];
        for ( let i = 0 ; i < this.schoolsData.length; i ++ ) {
            this.schoolsList.push( this.schoolsData[i] );
        }
    }

    
    ////////////////////////
    // On Search
    
    onKeySchools(value) { 
            this.schoolsList= []; 
            this.selectSchoolSearch(value);       
    }

    onKeyClassLevels(value) { 
            this.classLevelsList= []; 
            this.selectClassLevelsSearch(value);       
    }


    ////////////////////////
    // search elements


    selectClassLevelsSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.classLevelsData.length; i ++ ) {
            let option = this.classLevelsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.classLevelsList?.push( option );
            }
        }
    }

    selectSchoolSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.schoolsData.length; i ++ ) {
            let option = this.schoolsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0
		|| option.name?.toLowerCase().indexOf(filter) >= 0  ) {
                this.schoolsList?.push( option );
            }
        }
    }

    
    ////////////////////////////
    // elements selections
    ///////////////////////////
    
    OnClassLevelsChanged($event: any) {
      console.log($event);
    }

    OnSchoolsChanged($event: any) {
	console.log($event);
    }

    ////////////////////////////
    // External actions
    ///////////////////////////
    
    addNewClassLevel(): void {
/*    const dialogRef = this.dialog.open(AddRoleDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {

      }
    });*/
  }
    
    ////////////////////////
    ///////////////////////
    
    
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid || !this.selectedSchool || !this.selectedClassLevel) {
	    return;
	}

	let classGrade : ClassGrade = {
	};


	const note : Note = {
	    content : this.form.value.note
	}



	classGrade.note = note;
	
	if (this.selectedSchool){
	    classGrade.schoolId = this.selectedSchool.id;
	}
	if (this.selectedClassLevel){
	    classGrade.classLevelId = this.selectedClassLevel.id;
	}

	console.log(classGrade);
	console.log("gonna save");

	this.classGradeService.prepareAddRelations(classGrade).pipe(
	    switchMap(preparedClassGrade => { 
		console.log("entered switch map -- add new classGrade ");
		console.log(preparedClassGrade);
		return this.classGradeService.add(classGrade);}),
	    switchMap(classGradeAddedObservable => {
		let classGradeAdded = classGradeAddedObservable.Data;
		    
		console.log("entered switch map -- handle post relations ");
		console.log(classGradeAdded);
		return this.classGradeService.handlePostAddRelations(classGradeAdded); 
	    })
	
						      
	).subscribe({
	    next: (res) => {  this.checkSubmitSucess(res);},
	    error: (error) => { this.submitError(error) },
	    complete: () => {
		console.info('save school pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();  
		    }, 5000);
		
	    }
	    
	    
	});

    }

    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListClassPath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }
    
	
}
    
/*  utilisateurs: any;
  permissions: any;
  selectedCountry: Country = undefined;
  
  toppings = new FormControl('');

  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

  constructor(private router: Router,
      private _snackBar: MatSnackBar,
      public fb: FormBuilder) { 
 
      }
      
  ngOnInit(): void {
    this.form = this.fb.group({
      nom: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
      prenom: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
      phone: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$"), Validators.minLength(10)]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
      passwordcheck: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
      street: new FormControl(null, [Validators.required, Validators.maxLength(5), Validators.pattern("^[0-9]*$"), Validators.minLength(1)]),
      city: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
      postal: new FormControl(null, [Validators.required, Validators.maxLength(5),  Validators.pattern("^[0-9]*$"), Validators.minLength(5)])
    });
  }

  onCountrySelected($event: Country) {
    this.selectedCountry = $event;
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
  }
}


*/
