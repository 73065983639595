<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">ÉDITER NIVEAU SCOLAIRE</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Établissements</a></li>
                    <li class="breadcrumb-item active">Niveau scolaire</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">



        <h3 style="color: black; font-size:medium">Niveau scolaire</h3>
        <h6 style="color: black; font-size: small">Éditer un niveau scolaire</h6>
        <form class="example-form" [formGroup]="form" (ngSubmit)="submit()" *ngIf="_classLevel">


            <div class="row">
                <div class="col">
                    <mat-label>Nom</mat-label>
                    <input type="text" matInput formControlName="name"  [(ngModel)]="_classLevel.name" class="form-control form-control-sm mb-2"
                        required>
		    <app-show-errors [ctrl]="form.get('name')"></app-show-errors>     
                </div>
	    </div>
	    <div class="row">  
                <div class="col">
                    <mat-form-field appearance="outline" class="example-full-width mb-1">
                    <mat-label>Description</mat-label>           
                    <textarea style="height: 100px;" formControlName="description"  [(ngModel)]="_classLevel.description" matInput placeholder="Description" required></textarea>
		    <app-show-errors [ctrl]="form.get('description')"></app-show-errors>     
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline" class="example-full-width mb-2">
                        <mat-label>Note</mat-label>
                        <textarea style="height: 100px;" matInput formControlName="note"
				  [ngModel]="_classLevel && _classLevel.note && _classLevel.note.content"
				  (ngModelChange)="_classLevel && _classLevel.note && _classLevel.note.content  = $event"
				  placeholder="Note" required></textarea>
			<app-show-errors [ctrl]="form.get('note')"></app-show-errors>     
                    </mat-form-field>
                </div>
	    </div>
	    <div class="row">  
                <div class="col-6">
            <mat-label>Status</mat-label>
            <div class="input-group">
                <mat-select placeholder="Choisir un statut pour ce niveau scolaire" class="  form-control form-control-sm mb-2"   required
			    [(ngModel)]="selectedStatus"   [formControl]="statusesListCtrl"  [compareWith]="compareFn" (selectionChange)="onStatusesChanged($event)"  >
			<input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyStatuses($event.target.value)">  
                      <mat-select-trigger>
                        {{selectedStatus?.label  || ''}}
                        <span *ngIf="(selectedStatuses?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedStatuses?.length || 0) - 1}} {{selectedStatuses?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
		    </mat-select>

            </div>
	    
                </div>
            </div>


            <div class="example-full-width d-flex justify-content-center" *ngIf="DisplayConfirmationButton">
                <button class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>
                <!-- <button mat-raised-button cdkFocusInitial>Annuler</button> -->
            </div>



        </form>




    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
