import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, from,  map,  concatMap, switchMap, Observable, exhaustMap, EMPTY, empty ,identity, forkJoin} from 'rxjs';

import { environment } from 'src/environments/environment';

// models   
import { School } from '../interfaces/school';
import { Address as Address } from '../interfaces/address';
import { ClassLevel } from '../interfaces/class-level';
import { Note } from '../interfaces/note';
import { ClassGrade } from '../interfaces/class-grade';
import { User } from '../interfaces/user';
import { Profil as Profile } from '../interfaces/profil';

// response
import { Response as Rep } from '../interfaces/response';   

import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"

import {NoteService} from '../services/note.service'
import {AddressService} from '../services/address.service'

// extensions
import '../extensions/stringExtends'



@Injectable({
  providedIn: 'root'
})
export class SchoolService extends BaseCrudModel<School>{

    postProfiles = {url: `${environment.domains.api.url}/profiles`, urlOptions: this.options};
    deleteProfiles = {url: `${environment.domains.api.url}/profiles`, urlOptions: this.options};
    postClasses = {url: `${environment.domains.api.url}/class-grades`, urlOptions: this.options};
    deleteClasses = {url: `${environment.domains.api.url}/class-grades`, urlOptions: this.options};
    
    constructor(protected override httpService: HttpService,
		private noteService : NoteService,
		private addressService: AddressService
	       ) {
	super(httpService, "schools");

	var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "address"},
		{"relation" : "objectDetail"},
		{"relation" : "note"},
		{"relation" : "profiles", "scope": {"include" : [{"relation" : "user"}]}},
		{"relation" : "classes", "scope": {"include" : [{"relation" : "classLevel"}]}}
	    ],
	});

	var addOptions =  RequestOptions.FromArray({
	    relationsToOmit : ['note', 'address', 'status', 'classes', 'profiles']
	});

	this.methods.listing.urlOptions = options;
	this.methods.view.urlOptions = options;
	this.methods.add.urlOptions = addOptions;
	this.methods.update.urlOptions = addOptions;
    }

    
    getAllSchools(): Observable<Rep<number, School[]>> {
	const options = new RequestOptions();
	return this.getAll()
    }

    ////////////////////////////////
    // PREPARE || HANDLE Relations
    ////////////////////////////////
    
    override prepareRelations(fromSchool): Observable<Rep<number, School>> { //Observable<Profile> {
	// values to save
	const school : School = fromSchool;


	// Obs Functions
	const failObj : Rep<number, Note> = {IsSuccess:  0, Data:  null, Status : 0};
	const failAddr : Rep<number, Address> = {IsSuccess:  0, Data:  null, Status : 0};

	const a$ = of();

	const add$ : Observable<Rep<number, Address>> = school.address ? 
	    ( school.address.id ? this.addressService.updateById(fromSchool.address.id, fromSchool.address) : this.addressService.add(fromSchool.address) )
	    :  this.httpService.createObservableResponse<Address>(failAddr);

	
	const note$ : Observable<Rep<number, Note>> = school.note ? 
	    ( school.note.id ? this.noteService.updateById(fromSchool.note.id, fromSchool.note) : this.noteService.add(fromSchool.note) )
	    :  this.httpService.createObservableResponse<Note>(failObj);

	
	return add$
	    .pipe(
		exhaustMap(add => 
		    {
			//(school.addressId = add && add.IsSuccess && add.Data ? add.Data.id : null );
			console.log("before setting Address");
			console.log(school);
			(school.addressId = add && add.IsSuccess ?
			    (add.Data && add.Data.id ? add.Data.id : school.addressId) : school.addressId );
			
			return  note$;
		    }),
		map(note => 
		    {
			console.log("created note");
			console.log(note);
			console.log("before setting Note");
			console.log(school);
			(school.noteId = note && note.IsSuccess ?
			    (note.Data && note.Data.id ? note.Data.id : school.noteId) : school.noteId );
			
			return   this.httpService.createResponseFromObj<School>(school)
		    }),
		map(rel =>
		    {
			console.log("final result");
			console.log(rel);
			// return profile
			return    this.httpService.createResponseFromObj<School>(school) ;
		    }),
		
	    );
    }
    

    //////////////////////////
    // HANDLE || PREPARE
    //////////////////////////

    override handleRemoveRelations(school): Observable<any> {
	console.log(school);
	console.log("removePostRelations");

	return forkJoin
	(
	    // Remove elements if empty
	    school && school.id && (school.profiles && school.profiles?.length == 0) ? this.removeProfiles(school.id) :
		this.httpService.createObservableResponse<Profile[]>({IsSuccess:  0, Data:  undefined, Status : 0}),
	    school && school.id && (school.classes && school.classes?.length == 0) ? this.removeClasses(school.id) :
		this.httpService.createObservableResponse<ClassGrade[]>({IsSuccess:  0, Data:  undefined, Status : 0}),

	)

    }

    override handlePostRelations(school): Observable<any> {
	console.log(school);
	console.log("handlePostRelations");
	    return forkJoin
	(
	    
	    // add new elements 
	    school && school.id && school.profiles && school.profiles?.length > 0 ? this.addProfiles(school.id, school.profiles) :
		this.httpService.createObservableResponse<Profile[]>({IsSuccess:  0, Data:  undefined, Status : 0}),
	    school && school.id  && school.classes && school.classes?.length > 0 ? this.addClasses(school.id, school.classes) :
		this.httpService.createObservableResponse<ClassGrade[]>({IsSuccess:  0, Data:  undefined, Status : 0})
	)
    }

    //////////////////////////
    // Add Other objects
    //////////////////////////

    public removeProfiles(schoolId):  Observable<Rep<number, any>> {
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		{"schoolId" : schoolId},
	    ],
	});
	options.bodyParams = {"schoolId" : null}
	return this.httpService.makePatchRequest<any>( `${this.deleteProfiles.url}`, options);
//	return  this.httpService.createObservableResponse<ProfileSchool[]>({IsSuccess:  0, Data:  undefined, Status : 0});
    }

    public removeClasses(schoolId):  Observable<Rep<number, any>> {
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		{"schoolId" : schoolId},
	    ],
	});
	//options.bodyParams = {}
	options.bodyParams = {"schoolId" : null}
	return this.httpService.makePatchRequest<any>( `${this.deleteClasses.url}`, options);

    }

    //////////////////////////
    // Add Other objects
    //////////////////////////

    public addProfiles(schoolId, profiles):  Observable<Rep<number, any>> {
	
 	
	//const arrayProfiles = profiles?.map((r: any): Profile => ({schoolId : schoolId, profileId: r.id}));

	const arrayProfiles = profiles?.map((r: Profile): any => (r.id));

	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		{"id" : {"inq" : arrayProfiles}},
	    ],
	});
	console.log("Array profiles");
	console.log(arrayProfiles);
	options.body =  {"schoolId" : schoolId} ;//arrayProfiles;	

	return arrayProfiles && arrayProfiles.length > 0 ?
	    this.httpService.makePatchRequest<Profile[]>( `${this.postProfiles.url}`.format(), options) :
	    this.httpService.createObservableResponse<Profile[]>({IsSuccess:  0, Data:  undefined, Status : 0});
	//Observable.empty<number, any>() ;
    }

    public addClasses(schoolId, classes):  Observable<Rep<number, any>> {
	const arrayClasses = classes?.map((r: ClassGrade): any => (r.id));
					  
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		{"id" : {"inq" : arrayClasses}},
	    ],
	});

	console.log("Array classes");
	console.log(arrayClasses);

	options.body =  {"schoolId" : schoolId} ;//arrayProfiles;
	
	return arrayClasses && arrayClasses.length > 0 ?
	    this.httpService.makePatchRequest<ClassGrade[]>( `${this.postClasses.url}`, options) :
	    this.httpService.createObservableResponse<ClassGrade[]>({IsSuccess:  0, Data:  undefined, Status : 0});
    }
}


