import { Input, ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-caroussel',
  templateUrl: './product-caroussel.component.html',
  styleUrls: ['./product-caroussel.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ProductCarousselComponent implements OnInit {
  @Input() Images: string[];

  currentImage = '';
  panelOpenState = true;
  ngOnInit(): void {
    this.currentImage = this.Images[0];
  }

  onClick(img) {
    this.currentImage = img;
  }

}
