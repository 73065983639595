<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">NOUVEAU POINT RELAiS</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Points relais</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">


        <h3 style="color: black; font-size:medium">Point relais</h3>
        <h6 style="color: black; font-size: small">Remplir les informations ci-dessous</h6>
        <form class="example-form" [formGroup]="form" (ngSubmit)="submit()">



            <mat-label>Nom</mat-label>
            <input type="text" matInput formControlName="name" class="form-control form-control-sm mb-2" required>
	    <app-show-errors [ctrl]="form.get('name')"></app-show-errors>
	    
            <div class="row">
                <div class="col-6">
                    <mat-label>Numéro de rue</mat-label>
                    <input matInput #numRue  type="text" placeholder="Ex. 21" formControlName="street"
                        class="form-control form-control-sm" required>
		    <app-show-errors [ctrl]="form.get('street')"></app-show-errors>
                </div>
                <div class="col-6">
                    <mat-label>Ville</mat-label>
                    <input matInput formControlName="city" type="text" class="form-control form-control-sm" required>
		    <app-show-errors [ctrl]="form.get('city')"></app-show-errors>
                </div>
		
            </div>

            <div class="row">
                <div class="col-6">
                    <mat-label>Code Postale</mat-label>
                    <input matInput #postalCode maxlength="5" type="number" placeholder="Ex. 94105"
                        formControlName="zip" class="form-control form-control-sm" required>
                    <mat-hint align="end" style="color: black;">{{postalCode.value.length}} / 5</mat-hint>
		    <app-show-errors [ctrl]="form.get('zip')"></app-show-errors>
                </div>
                <div class="col-6">
                    <mat-select-country appearance="legacy" label="Pays" (onCountrySelected)="onCountrySelected($event)"
                        required>
                    </mat-select-country>
                </div>
            </div>

	    <div >
            <mat-label>Expéditions</mat-label>
            <div class="input-group">
                <mat-select placeholder="Choisir des Expéditions" class="  form-control form-control-sm mb-2" [formControl]="shippingsListCtrl" multiple required [(ngModel)]="selectedShippings">
		    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyShippings($event.target.value)"> 
		    <mat-select-trigger>
                        {{shippingsListCtrl.value?.[0]?.name  || ''}} 
			<span *ngIf="(shippingsList?.length || 0) > 1" class="example-additional-selection">
				    (+{{(selectedShippings?.length || 0) - 1}} {{selectedShippings?.length === 2 ? 'autre' :
				    'autres'}})
			</span>
		    </mat-select-trigger>
		    <mat-option *ngFor="let shipping of shippingsList" [value]="shipping">{{shipping.shippingNumber}} </mat-option>
		</mat-select>
            </div>
	    
	    </div>
    
            <div class="row">
                <div class="col-6">
                    <mat-label>Status</mat-label>
                    <div class="input-group">
			<mat-select placeholder="Choisir un statut" class="  form-control form-control-sm mb-2"   required
			    [(ngModel)]="selectedStatus" [formControl]="statusesListCtrl"  [compareWith]="compare" (selectionChange)="onStatusesChanged($event)"  >
			    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyStatuses($event.target.value)">  
			    <mat-select-trigger>
				{{selectedStatus?.label  || ''}}
				<span *ngIf="(selectedStatuses?.length || 0) > 1" class="example-additional-selection">
				    (+{{(selectedStatuses?.length || 0) - 1}} {{selectedStatuses?.length === 2 ? 'autre' :
				    'autres'}})
				</span>
			    </mat-select-trigger>
			    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
			</mat-select>
                    </div>
                </div>
            </div>

	    <div class="row mt-3">
                <div class="col-6">
                    <mat-form-field appearance="outline" class="example-full-width mb-2">
                        <mat-label>Note</mat-label>
                        <textarea style="height: 100px;" formControlName="note" matInput placeholder="Note" required></textarea>
			<app-show-errors [ctrl]="form.get('note')"></app-show-errors>
                    </mat-form-field>
                </div>
	    </div>
		       
            <div class="example-full-width d-flex justify-content-center" *ngIf="DisplayConfirmationButton">
                <button class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>

                <!-- <button mat-raised-button cdkFocusInitial>Annuler</button> -->
            </div>





        </form>




    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
