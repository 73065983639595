import { Country } from '@angular-material-extensions/select-country';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { UserService } from 'src/app/services/user.service';
import { ProfileService } from 'src/app/services/profile.service';
import { SchoolService } from 'src/app/services/school.service';

import { RolesService } from 'src/app/services/roles.service';
import { PermissionService } from 'src/app/services/permission.service';
import { ModuleService } from 'src/app/services/module.service';
import { ClassGradeService } from 'src/app/services/class-grade.service';

import { Role } from 'src/app/interfaces/role';
import { Permission } from 'src/app/interfaces/permission';
import { Profil as Profile } from 'src/app/interfaces/profil';
import { UserModel } from 'src/app/interfaces/user';
import { Module } from 'src/app/interfaces/module';
import { Address } from 'src/app/interfaces/address';
import { School } from 'src/app/interfaces/school';
import { Note } from 'src/app/interfaces/note';
import { ClassGrade } from 'src/app/interfaces/class-grade';

import { Response } from '../../interfaces/response';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-new-ecole',
  templateUrl: './new-ecole.component.html',
  styleUrls: ['./new-ecole.component.scss'], encapsulation: ViewEncapsulation.None
})
export class NewEcoleComponent  implements OnInit  {
  @Input() TitleVisible: boolean = true;
  @Input() DisplayConfirmationButton: boolean = true;

  form: FormGroup;

    ////////////////////////
    // Attributes
    ////////////////////////
    
/*    utilisateurs: any;
    permissions: any;
    selectedCountry: Country = undefined;


    utilisateurs: any;
    roles: any;
*/

    selectedCountry: Country = undefined;
    
    profilesListCtrl = new FormControl('');
    profilesData : Profile[];//string[];
    profilesList : Profile[];//string[];
    selectedProfiles : Profile[];

    
    classGradesListCtrl = new FormControl('');
    classGradesList : ClassGrade[];//string[];
    classGradesData : ClassGrade[];//string[];
    selectedClassGrades : ClassGrade[];


    modulesListCtrl = new FormControl('');
    modulesList : Module[];//string[];
    modulesData : Module[];//string[];
    selectedModule : Module;


    ////////////////////////
    // Constructor
    ////////////////////////  

    constructor(private router: Router,
		private _snackBar: MatSnackBar,
		public fb: FormBuilder,
		
		private schoolService: SchoolService,
		private classGradeService: ClassGradeService,
		private moduleService: ModuleService,
		private profileService : ProfileService,     
	       ) { 
 
      }
      


    ////////////////////////
    // Init
    ///////////////////////
    
    ngOnInit(): void {

	
	this.refresh();
	this.initForm();
    }

    ////////////////////////
    // Datasrouces
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    name: new FormControl(null, [Validators.required, Validators.maxLength(60), Validators.minLength(2)]),
	    //prenom: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    phone: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$"), Validators.minLength(10)]),
	    email: new FormControl(null, [Validators.required, Validators.email]),
	    //password: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
	    //passwordcheck: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
	    street: new FormControl(null, [Validators.required, Validators.maxLength(60), Validators.minLength(2)]),
	    //street: new FormControl(null, [Validators.required, Validators.maxLength(5), Validators.pattern("^[0-9]*$"), Validators.minLength(1)]),
	    city: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    zip: new FormControl(null, [Validators.required, Validators.maxLength(5),  Validators.pattern("^[0-9]*$"), Validators.minLength(5)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
	    description: new FormControl(null, [Validators.required, Validators.maxLength(150), Validators.minLength(2)]),
    });
    }

    ////////////////////////
    // Datasrouces
    ///////////////////////

    refresh(): void {
	
	this.profileService.getAllProfiles().subscribe({
            next: (response: Response<number, Profile[]>) => {
		this.profilesData = response.Data;
		this.initProfilesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load profiles complete')
	});

	this.classGradeService.getAllClassGrades().subscribe({
	    next: (response: Response<number, ClassGrade[]>) => {
		this.classGradesData = response.Data;
		this.initClassGradesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load classGrades complete')
	});

	this.moduleService.getAllModules().subscribe({
	    next: (response: Response<number, Module[]>) => {
		this.modulesData = response.Data;
		this.initModulesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load modules complete')
	});

    }

    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns
    
    initClassGradesSearchDropdown(){
	this.classGradesList = [];
        for ( let i = 0 ; i < this.classGradesData.length; i ++ ) {
            this.classGradesList.push( this.classGradesData[i] );
        }
    }

    initModulesSearchDropdown(){
	this.modulesList = [];
        for ( let i = 0 ; i < this.modulesData.length; i ++ ) {
            this.modulesList.push( this.modulesData[i] );
        }
    }

    
    initProfilesSearchDropdown(){
	this.profilesList = [];
        for ( let i = 0 ; i < this.profilesData.length; i ++ ) {
            this.profilesList.push( this.profilesData[i] );
        }
    }

    ////////////////////////
    // On Search
    
    onKeyProfiles(value) { 
            this.profilesList= []; 
            this.selectProfileSearch(value);       
    }

    onKeyClassGrades(value) { 
            this.classGradesList= []; 
            this.selectClassGradesSearch(value);       
    }

    onKeyModules(value) { 
            this.modulesList= []; 
            this.selectModulesSearch(value);       
    }

    ////////////////////////
    // search elements

    selectModulesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.modulesData.length; i ++ ) {
            let option = this.modulesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.modulesList?.push( option );
            }
        }
    }

    selectClassGradesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.classGradesData.length; i ++ ) {
            let option = this.classGradesData[i];
            if (  option.classLevel?.name.toLowerCase().indexOf(filter) >=0
		||  option.school?.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.classGradesList?.push( option );
            }
        }
    }

    selectProfileSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.profilesData.length; i ++ ) {
            let option = this.profilesData[i];
            if (  option.user?.username.toLowerCase().indexOf(filter) >= 0
		|| option.firstname?.toLowerCase().indexOf(filter) >= 0 
		|| option.lastname?.toLowerCase().indexOf(filter) >= 0  ) {
                this.profilesList?.push( option );
            }
        }
    }

    
    ////////////////////////////
    // elements selections
    ///////////////////////////
    
    onCountrySelected($event: Country) {
	this.selectedCountry = $event;
    }
    
      onClassGradesChanged($event: any) {
      console.log($event);
    }

    ////////////////////////////
    // External actions
    ///////////////////////////
    
    addNewClassGrade(): void {
/*    const dialogRef = this.dialog.open(AddRoleDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {

      }
    });*/
  }
    
    ////////////////////////
    ///////////////////////
    
    
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid) {
	    return;
	}


	let school : School = {
            name: this.form.value.name,
            phoneNumber: this.form.value.phone,
            email: this.form.value.email,
	    description: this.form.value.description,
//        profilesphoneNumber: this.form.value.profiles
	};

	const address: Address = {
	    country:  this.selectedCountry.name,
            street: this.form.value.street?.toString(),
            city: this.form.value.city,
            zip: this.form.value.zip?.toString(),
	    //	  zipCode: this.form.value.postal?.toString(),
            additionalDetail: ''
	  
	};

	const note : Note = {
	    content : this.form.value.note
	}

	
	school.note = note;
	school.address = address;
	school.profiles = this.selectedProfiles;
	school.classes = this.selectedClassGrades;
	
	console.log(school);
	console.log("gonna save");

	this.schoolService.prepareAddRelations(school).pipe(
	    switchMap(preparedSchool => { 
		console.log("entered switch map -- add new school ");
		console.log(preparedSchool);
		return this.schoolService.add(school);}),
	    switchMap(schoolAddedObservable => {
		let schoolAdded = schoolAddedObservable.Data;
		    
		console.log("entered switch map -- handle post relations ");
		console.log(schoolAdded);
		schoolAdded.profiles = this.selectedProfiles;
		schoolAdded.classes = this.selectedClassGrades;
		return this.schoolService.handlePostAddRelations(schoolAdded); 
	    })
	
						      
	).subscribe({
	    next: (res) => { console.log(res); },
	    error: (error) => {console.log(error)},
	    complete: () => {
		console.info('save school pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.router.navigate([environment.paths.ListEcolePath]);
		    }, 5000);
		
	    }
	    
	    
	});

    }
	

/*    ngOnInit(): void {
    this.form = this.fb.group({
      nom: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
      prenom: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
      phone: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$"), Validators.minLength(10)]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
      passwordcheck: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(4)]),
      street: new FormControl(null, [Validators.required, Validators.maxLength(5), Validators.pattern("^[0-9]*$"), Validators.minLength(1)]),
      city: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
      postal: new FormControl(null, [Validators.required, Validators.maxLength(5),  Validators.pattern("^[0-9]*$"), Validators.minLength(5)])
    });
  }



  submit() {
    if (!this.form.valid) {
      return;
    }
  }
*/
}
