import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewRoleComponent } from './Creation/new-role/new-role.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EditerRoleComponent } from './Editer/editer-role/editer-role.component';
import { ListRoleComponent } from './Listing/list-role/list-role.component';
import { LoginComponent } from './login/login.component';
import { ListPermissionComponent } from './Listing/list-permission/list-permission.component';
import { EditerPermissionComponent } from './Editer/editer-permission/editer-permission.component';
import { NewPermissionComponent } from './Creation/new-permission/new-permission.component';
import { ConsulterRoleComponent } from './Consulter/consulter-role/consulter-role.component';
import { ConsulterPermissionComponent } from './Consulter/consulter-permission/consulter-permission.component';
import { ListAccountComponent } from './Listing/list-account/list-account.component';
import { EditerUtilisateurComponent } from './Editer/editer-utilisateur/editer-utilisateur.component';
import { NewUserAccountComponent } from './Creation/new-user-account/new-user-account.component';
import { ConsulterProfilComponent } from './Consulter/consulter-profil/consulter-profil.component';

import { ListCompanyGroupsComponent } from './Listing/list-company-group/list-company-groups.component';
import { NewCompanyGroupComponent } from './Creation/new-company-group/new-company-group.component';
import { ConsulterCompanyGroupComponent } from './Consulter/consulter-company-group/consulter-company-group.component';
import { EditerCompanyGroupComponent } from './Editer/editer-company-group/editer-company-group.component';

import { ListCompaniesComponent } from './Listing/list-companies/list-companies.component';
import { NewCompanyComponent } from './Creation/new-company/new-company.component';
import { ConsulterCompanyComponent } from './Consulter/consulter-company/consulter-company.component';
import { EditerCompanyComponent } from './Editer/editer-company/editer-company.component';

import { ListCustomerComponent } from './Listing/list-customer/list-customer.component';
import { EditerCustomerComponent } from './Editer/editer-customer/editer-customer.component';
import { NewCustomerComponent } from './Creation/new-customer/new-customer.component';
import { ConsulterCustomerComponent } from './Consulter/consulter-customer/consulter-customer.component';
import { ListNiveauScolaireComponent } from './Listing/list-niveau-scolaire/list-niveau-scolaire.component';
import { EditerNiveauScolaireComponent } from './Editer/editer-niveau-scolaire/editer-niveau-scolaire.component';
import { NewNiveauScolaireComponent } from './Creation/new-niveau-scolaire/new-niveau-scolaire.component';
import { ConsulterNiveauScolaireComponent } from './Consulter/consulter-niveau-scolaire/consulter-niveau-scolaire.component';
import { ListEcoleComponent } from './Listing/list-ecole/list-ecole.component';
import { EditerEcoleComponent } from './Editer/editer-ecole/editer-ecole.component';
import { NewEcoleComponent } from './Creation/new-ecole/new-ecole.component';
import { ConsulterEcoleComponent } from './Consulter/consulter-ecole/consulter-ecole.component';
import { ListClassComponent } from './Listing/list-class/list-class.component';
import { EditerClasseComponent } from './Editer/editer-classe/editer-classe.component';
import { NewClassComponent } from './Creation/new-class/new-class.component';
import { ConsulterClasseComponent } from './Consulter/consulter-classe/consulter-classe.component';
import { ListListScolaireComponent } from './Listing/list-list-scolaire/list-list-scolaire.component';
import { EditerListeScolaireComponent } from './Editer/editer-liste-scolaire/editer-liste-scolaire.component';
import { NewListScolaireComponent } from './Creation/new-list-scolaire/new-list-scolaire.component';
import { ConsulterListeScolaireComponent } from './Consulter/consulter-liste-scolaire/consulter-liste-scolaire.component';
import { ListProductComponent } from './Listing/list-product/list-product.component';
import { EditerProductComponent } from './Editer/editer-product/editer-product.component';
import { NewProductComponent } from './Creation/new-product/new-product.component';
import { ConsulterProductComponent } from './Consulter/consulter-product/consulter-product.component';
import { ListCategoryComponent } from './Listing/list-category/list-category.component';
import { EditerCategoryComponent } from './Editer/editer-category/editer-category.component';
import { NewCategoryComponent } from './Creation/new-category/new-category.component';
import { ConsulterCategoryComponent } from './Consulter/consulter-category/consulter-category.component';
import { ListBrandComponent } from './Listing/list-brand/list-brand.component';
import { EditerBrandComponent } from './Editer/editer-brand/editer-brand.component';
import { NewBrandComponent } from './Creation/new-brand/new-brand.component';
import { ConsulterBrandComponent } from './Consulter/consulter-brand/consulter-brand.component';
import { ListProductTypeComponent } from './Listing/list-product-type/list-product-type.component';
import { EditerProductTypeComponent } from './Editer/editer-product-type/editer-product-type.component';
import { NewProductTypeComponent } from './Creation/new-product-type/new-product-type.component';
import { ConsulterProductTypeComponent } from './Consulter/consulter-product-type/consulter-product-type.component';
import { ListOrderComponent } from './Listing/list-order/list-order.component';
import { ConsulterOrderComponent } from './Consulter/consulter-order/consulter-order.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ListExpeditionComponent } from './Listing/list-expedition/list-expedition.component';
import { NewExpeditionComponent } from './Creation/new-expedition/new-expedition.component';
import { EditerExpeditionComponent } from './Editer/editer-expedition/editer-expedition.component';
import { ConsulterExpeditionComponent } from './Consulter/consulter-expedition/consulter-expedition.component';
import { ListDeliveryComponent } from './Listing/list-delivery/list-delivery.component';
import { EditerDeliveryComponent } from './Editer/editer-delivery/editer-delivery.component';
import { NewDeliveryComponent } from './Creation/new-delivery/new-delivery.component';
import { ConsulterDeliveryComponent } from './Consulter/consulter-delivery/consulter-delivery.component';
import { ListMethodExpeditionComponent } from './Listing/list-method-expedition/list-method-expedition.component';
import { EditerMethodeExpeditionComponent } from './Editer/editer-methode-expedition/editer-methode-expedition.component';
import { NewMethodeExpeditionComponent } from './Creation/new-methode-expedition/new-methode-expedition.component';
import { ConsulterMethodeExpeditionComponent } from './Consulter/consulter-methode-expedition/consulter-methode-expedition.component';
import { ListMethodePaymentsComponent } from './Listing/list-methode-payments/list-methode-payments.component';
import { EditerMethodePaymentComponent } from './Editer/editer-methode-payment/editer-methode-payment.component';
import { NewMethodePaymentComponent } from './Creation/new-methode-payment/new-methode-payment.component';
import { ConsulterMethodePaymentComponent } from './Consulter/consulter-methode-payment/consulter-methode-payment.component';
import { ListSalesPointComponent } from './Listing/list-sales-point/list-sales-point.component';
import { EditerSalesPointComponent } from './Editer/editer-sales-point/editer-sales-point.component';
import { NewSalesPointComponent } from './Creation/new-sales-point/new-sales-point.component';
import { ConsulterSalesPointComponent } from './Consulter/consulter-sales-point/consulter-sales-point.component';
import { ListLocalisationComponent } from './Listing/list-localisation/list-localisation.component';
import { EditerLocalisationComponent } from './Editer/editer-localisation/editer-localisation.component';
import { NewLocalisationComponent } from './Creation/new-localisation/new-localisation.component';
import { ConsulterLocalisationComponent } from './Consulter/consulter-localisation/consulter-localisation.component';
import { ListRelayPointComponent } from './Listing/list-relay-point/list-relay-point.component';
import { EditerRelayPointComponent } from './Editer/editer-relay-point/editer-relay-point.component';
import { NewRelayPointComponent } from './Creation/new-relay-point/new-relay-point.component';
import { ConsulterRelayPointComponent } from './Consulter/consulter-relay-point/consulter-relay-point.component';
import { ListDocumentsComponent } from './Listing/list-documents/list-documents.component';
import { EditerDocumentComponent } from './Editer/editer-document/editer-document.component';
import { NewDocumentComponent } from './Creation/new-document/new-document.component';
import { ConsulterDocumentComponent } from './Consulter/consulter-document/consulter-document.component';
import { InvoiceViewerComponent } from './invoice-viewer/invoice-viewer.component';

import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
    {path: 'login', component: LoginComponent},
    {path: 'dashboard', component: DashboardComponent, 
      canActivate: [AuthGuard]
    },


  {path: 'list/roles', component: ListRoleComponent,   canActivate: [AuthGuard]  },
  {path: 'edit/role/:id', component: EditerRoleComponent,   canActivate: [AuthGuard] },
  {path: 'add/role', component: NewRoleComponent,   canActivate: [AuthGuard] },
  {path: 'details/role/:id', component: ConsulterRoleComponent,   canActivate: [AuthGuard] },

  {path: 'list/permissions', component: ListPermissionComponent,   canActivate: [AuthGuard] },
  {path: 'edit/permission/:id', component: EditerPermissionComponent,   canActivate: [AuthGuard] },
  {path: 'add/permission', component: NewPermissionComponent,   canActivate: [AuthGuard] },
  {path: 'details/permission/:id', component: ConsulterPermissionComponent,   canActivate: [AuthGuard] },

  {path: 'list/accounts', component: ListAccountComponent,   canActivate: [AuthGuard] },
  {path: 'edit/account/:id', component: EditerUtilisateurComponent,   canActivate: [AuthGuard] },
  {path: 'add/account', component: NewUserAccountComponent,   canActivate: [AuthGuard] },
  {path: 'details/account/:id', component: ConsulterProfilComponent,   canActivate: [AuthGuard] },

  {path: 'list/customers', component: ListCustomerComponent,   canActivate: [AuthGuard] },
  {path: 'edit/customer/:id', component: EditerCustomerComponent,   canActivate: [AuthGuard] },
  {path: 'add/customer', component: NewCustomerComponent,   canActivate: [AuthGuard] },
    {path: 'details/customer/:id', component: ConsulterCustomerComponent,   canActivate: [AuthGuard] },


    {path: 'list/companies', component: ListCompaniesComponent,   canActivate: [AuthGuard] },
    {path: 'add/company', component: NewCompanyComponent,   canActivate: [AuthGuard] },
    {path: 'edit/company/:id', component: EditerCompanyComponent,   canActivate: [AuthGuard] },
    {path: 'details/company/:id', component: ConsulterCompanyComponent,   canActivate: [AuthGuard] },

    {path: 'list/groups', component: ListCompanyGroupsComponent,   canActivate: [AuthGuard] },
    {path: 'edit/group/:id', component: EditerCompanyGroupComponent,   canActivate: [AuthGuard] },
    {path: 'add/group', component: NewCompanyGroupComponent,   canActivate: [AuthGuard] },
    {path: 'details/group/:id', component: ConsulterCompanyGroupComponent,   canActivate: [AuthGuard] },
    
    
  {path: 'list/niveaux-scolaires', component: ListNiveauScolaireComponent,   canActivate: [AuthGuard] },
  {path: 'edit/niveau-scolaire/:id', component: EditerNiveauScolaireComponent,   canActivate: [AuthGuard] },
  {path: 'add/niveau-scolaire', component: NewNiveauScolaireComponent,   canActivate: [AuthGuard] },
  {path: 'details/niveau-scolaire/:id', component: ConsulterNiveauScolaireComponent,   canActivate: [AuthGuard] },

  {path: 'list/ecoles', component: ListEcoleComponent,   canActivate: [AuthGuard] },
  {path: 'edit/ecole/:id', component: EditerEcoleComponent,   canActivate: [AuthGuard] },
  {path: 'add/ecole', component: NewEcoleComponent,   canActivate: [AuthGuard] },
  {path: 'details/ecole/:id', component: ConsulterEcoleComponent,   canActivate: [AuthGuard] },

  {path: 'list/classes', component: ListClassComponent,   canActivate: [AuthGuard] },
  {path: 'edit/classe/:id', component: EditerClasseComponent,   canActivate: [AuthGuard] },
  {path: 'add/classe', component: NewClassComponent,   canActivate: [AuthGuard] },
  {path: 'details/classe/:id', component: ConsulterClasseComponent,   canActivate: [AuthGuard] },

  {path: 'list/listes-scolaires', component: ListListScolaireComponent,   canActivate: [AuthGuard] },
  {path: 'edit/liste-scolaire/:id', component: EditerListeScolaireComponent,   canActivate: [AuthGuard] },
  {path: 'add/liste-scolaire', component: NewListScolaireComponent,   canActivate: [AuthGuard] },
  {path: 'details/liste-scolaire/:id', component: ConsulterListeScolaireComponent,   canActivate: [AuthGuard] },

  {path: 'list/products', component: ListProductComponent,   canActivate: [AuthGuard] },
  {path: 'edit/product/:id', component: EditerProductComponent,   canActivate: [AuthGuard] },
  {path: 'add/product', component: NewProductComponent,   canActivate: [AuthGuard] },
  {path: 'details/product/:id', component: ConsulterProductComponent,   canActivate: [AuthGuard] },


  {path: 'list/categories', component: ListCategoryComponent,   canActivate: [AuthGuard] },
  {path: 'edit/category/:id', component: EditerCategoryComponent,   canActivate: [AuthGuard] },
  {path: 'add/category', component: NewCategoryComponent,   canActivate: [AuthGuard] },
  {path: 'details/category/:id', component: ConsulterCategoryComponent,   canActivate: [AuthGuard] },

  {path: 'list/brands', component: ListBrandComponent,   canActivate: [AuthGuard] },
  {path: 'edit/brand/:id', component: EditerBrandComponent,   canActivate: [AuthGuard] },
  {path: 'add/brand', component: NewBrandComponent,   canActivate: [AuthGuard] },
  {path: 'details/brand/:id', component: ConsulterBrandComponent,   canActivate: [AuthGuard] },

  {path: 'list/products-types', component: ListProductTypeComponent,   canActivate: [AuthGuard] },
  {path: 'edit/product-type/:id', component: EditerProductTypeComponent,   canActivate: [AuthGuard] },
  {path: 'add/product-type', component: NewProductTypeComponent,   canActivate: [AuthGuard] },
  {path: 'details/product-type/:id', component: ConsulterProductTypeComponent,   canActivate: [AuthGuard] },

  {path: 'list/orders', component: ListOrderComponent,   canActivate: [AuthGuard] },
  {path: 'edit/order/:id', component: CheckoutComponent,   canActivate: [AuthGuard] },
  {path: 'add/order', component: CheckoutComponent,   canActivate: [AuthGuard] },
  {path: 'details/order/:id', component: ConsulterOrderComponent,   canActivate: [AuthGuard] },

  {path: 'list/customers', component: ListCustomerComponent,   canActivate: [AuthGuard] },
  {path: 'edit/customer/:id', component: EditerCustomerComponent,   canActivate: [AuthGuard] },
  {path: 'add/customer', component: NewCustomerComponent,   canActivate: [AuthGuard] },
  {path: 'details/customer/:id', component: ConsulterCustomerComponent,   canActivate: [AuthGuard] },

  {path: 'list/expeditions', component: ListExpeditionComponent,   canActivate: [AuthGuard] },
  {path: 'edit/expedition/:id', component: EditerExpeditionComponent,   canActivate: [AuthGuard] },
  {path: 'add/expedition', component: NewExpeditionComponent,   canActivate: [AuthGuard] },
  {path: 'details/expedition/:id', component: ConsulterExpeditionComponent,   canActivate: [AuthGuard] },

  {path: 'list/deliveries', component: ListDeliveryComponent,   canActivate: [AuthGuard] },
  {path: 'edit/delivery/:id', component: EditerDeliveryComponent,   canActivate: [AuthGuard] },
  {path: 'add/delivery', component: NewDeliveryComponent,   canActivate: [AuthGuard] },
  {path: 'details/delivery/:id', component: ConsulterDeliveryComponent,   canActivate: [AuthGuard] },

  {path: 'list/methode-expedition', component: ListMethodExpeditionComponent,   canActivate: [AuthGuard] },
  {path: 'edit/methode-expedition/:id', component: EditerMethodeExpeditionComponent,   canActivate: [AuthGuard] },
  {path: 'add/methode-expedition', component: NewMethodeExpeditionComponent,   canActivate: [AuthGuard] },
  {path: 'details/methode-expedition/:id', component: ConsulterMethodeExpeditionComponent,   canActivate: [AuthGuard] },
  
  {path: 'list/methode-payments', component: ListMethodePaymentsComponent,   canActivate: [AuthGuard] },
  {path: 'edit/methode-payment/:id', component: EditerMethodePaymentComponent,   canActivate: [AuthGuard] },
  {path: 'add/methode-payment', component: NewMethodePaymentComponent,   canActivate: [AuthGuard] },
  {path: 'details/methode-payment/:id', component: ConsulterMethodePaymentComponent,   canActivate: [AuthGuard] },

  {path: 'list/sales-points', component: ListSalesPointComponent,   canActivate: [AuthGuard] },
  {path: 'edit/sales-point/:id', component: EditerSalesPointComponent,   canActivate: [AuthGuard] },
  {path: 'add/sales-point', component: NewSalesPointComponent,   canActivate: [AuthGuard] },
  {path: 'details/sales-point/:id', component: ConsulterSalesPointComponent,   canActivate: [AuthGuard] },

  {path: 'list/localisations', component: ListLocalisationComponent,   canActivate: [AuthGuard] },
  {path: 'edit/localisation/:id', component: EditerLocalisationComponent,   canActivate: [AuthGuard] },
  {path: 'add/localisation', component: NewLocalisationComponent,   canActivate: [AuthGuard] },
  {path: 'details/localisation/:id', component: ConsulterLocalisationComponent,   canActivate: [AuthGuard] },

  {path: 'list/relay-points', component: ListRelayPointComponent,   canActivate: [AuthGuard] },
  {path: 'edit/relay-point/:id', component: EditerRelayPointComponent,   canActivate: [AuthGuard] },
  {path: 'add/relay-point', component: NewRelayPointComponent,   canActivate: [AuthGuard] },
  {path: 'details/relay-point/:id', component: ConsulterRelayPointComponent,   canActivate: [AuthGuard] },

  {path: 'list/documents', component: ListDocumentsComponent,   canActivate: [AuthGuard] },
  {path: 'edit/document', component: EditerDocumentComponent,   canActivate: [AuthGuard] },
  {path: 'add/document', component: NewDocumentComponent,   canActivate: [AuthGuard] },
  {path: 'details/document', component: ConsulterDocumentComponent,   canActivate: [AuthGuard] },

  {path: 'details/invoice', component: InvoiceViewerComponent,   canActivate: [AuthGuard] },

  {path: '**', redirectTo: 'login', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
