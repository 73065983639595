<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">VOIR ÉCOLE</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Établissements</a></li>
                    <li class="breadcrumb-item active">École</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;" *ngIf="_school">
    <div class="container-fluid" style="padding: 30px;">

        <div class="d-flex justify-content-end">
            <button class="btn btn-primary btn-sm" (click)="onEditEcole(_school.id)">Editer une école</button>
        </div>

        <label>Information personnelle</label>
        <div class="lateral-space">
   
            <div class="row mt-3 w-100 left-background">
                <div class="col-6 left">
                    Nom
                </div>
                <div class="col-6 right">
                    {{_school.name}}   
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Téléphone
                </div>
                <div class="col-6 right">
                     {{_school.phoneNumber}}   
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    Email
                </div>
                <div class="col-6 right">
		    {{_school.email}} 
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Classes
                </div>
                <div class="col-6 right">
                    <span *ngFor="let class of _school?.classes"  class="badge badge-pill badge-primary">{{class?.classLevel?.name}}</span>
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    Utilisateurs (Responsable ou professeurs)
                </div>
                <div class="col-6 right">
		    <span *ngFor="let profile of _school?.profiles"  class="badge badge-pill badge-primary">{{profile.firstname}} {{profile.lastname}}</span>
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left pl-5">
                    Description
                </div>
                <div class="col-6 right">
                    {{_school.description}}
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left pl-5">
                    Note
                </div>
                <div class="col-6 right">
		    {{_school.note?.content}}
                </div>
            </div>

        </div>
        <div class="row mt-5">
            <div class="col-md-6 col-sm-12">
                <label>Addresse</label>
                <div class="lateral-space">
           
                    <div class="row mt-3 w-100 left-background">
                        <div class="col-6 left">
                            Rue
                        </div>
                        <div class="col-6 right">
                            {{_school.address?.street}}     
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Ville
                        </div>
                        <div class="col-6 right">
                            {{_school.address?.city}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Code postal
                        </div>
                        <div class="col-6 right">
                            {{_school.address?.zip}}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Pays
                        </div>
                        <div class="col-6 right">
                            {{_school.address?.country}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Informations additionnelless
                        </div>
                        <div class="col-6 right">
                            {{_school.address?.additionalDetail}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <label>Informations complémentaires</label>
                <div class="lateral-space">
           
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Créé le {{_school?.objectDetail?.creationDate |  date:'dd/MM/yyyy' }}
                        </div>
                        <div class="col-6 right">
                            Modifié le {{_school?.objectDetail?.creationDate |  date:'dd/MM/yyyy' }}  
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Créé par
                        </div>
                        <div class="col-6 right">
                            {{_school?.objectDetail?.creator?.username}}    
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Modifié par
                        </div>
                        <div class="col-6 right">
                            {{_school?.objectDetail?.updater?.username}} 
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Status
                        </div>
                        <div class="col-6 right d-flex justify-content-center">
                             {{_school?.status?.label}}  
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
