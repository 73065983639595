import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { Note } from 'src/app/interfaces/note';
import { Product } from 'src/app/interfaces/product';
import { ProductType } from 'src/app/interfaces/product-type';
import { ProductCategory } from 'src/app/interfaces/product-category';
import { Status } from 'src/app/interfaces/status';

import { ProductService } from 'src/app/services/product.service';
import { ProductTypeService } from 'src/app/services/product-type.service';
import { ProductCategoryService } from 'src/app/services/product-category.service';
import { StatusService } from 'src/app/services/status.service';

import { Response } from '../../interfaces/response';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-product-type',
  templateUrl: './new-product-type.component.html',
  styleUrls: ['./new-product-type.component.scss'], encapsulation: ViewEncapsulation.None
})
export class NewProductTypeComponent  implements OnInit  {
  @Input() TitleVisible: boolean = true;
  @Input() DisplayConfirmationButton: boolean = true;

    form: FormGroup;

    ////////////////////////
    // Attributes 
    ////////////////////////  

    productCategoriesListCtrl = new FormControl('');
    productCategoriesData : ProductCategory[];//string[];
    productCategoriesList : ProductCategory[];//string[];
    selectedProductCategories : ProductCategory[];
    selectedProductCategory : ProductCategory;

    productsListCtrl = new FormControl('');
    productsList : Product[];//string[];
    productsData : Product[];//string[];
    selectedProducts : Product[];

    ////////////////////////
    // Constructor 
    ////////////////////////
    
    constructor(private router: Router,
		private _snackBar: MatSnackBar,
		public fb: FormBuilder,

		private productTypeService: ProductTypeService,
		private productService : ProductService,
		private productCategoryService : ProductCategoryService) { 
 
      }
      

    ////////////////////////
    // Init
    ///////////////////////
    
    ngOnInit(): void {

	
	this.refresh();
	this.initForm();
    }

    ////////////////////////
    // Datasrouces
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    nom: new FormControl(null, [Validators.required, Validators.maxLength(60), Validators.minLength(2)]),
	    description: new FormControl(null, [Validators.required, Validators.maxLength(150), Validators.minLength(2)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
    });
    }

    ////////////////////////
    // Datasrouces
    ///////////////////////

    refresh(): void {
	
	this.productService.getAllProducts().subscribe({
            next: (response: Response<number, Product[]>) => {
		this.productsData = response.Data;
		this.initProductsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load products complete')
	});

	this.productCategoryService.getAllProductCategories().subscribe({
	    next: (response: Response<number, ProductCategory[]>) => {
		this.productCategoriesData = response.Data;
		this.initProductCategoriesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load productCategories complete')
	});


    }

    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns
    
    initProductCategoriesSearchDropdown(){
	this.productCategoriesList = [];
        for ( let i = 0 ; i < this.productCategoriesData.length; i ++ ) {
            this.productCategoriesList.push( this.productCategoriesData[i] );
        }
    }


    
    initProductsSearchDropdown(){
	this.productsList = [];
        for ( let i = 0 ; i < this.productsData.length; i ++ ) {
            this.productsList.push( this.productsData[i] );
        }
    }

    
    ////////////////////////
    // On Search
    
    onKeyProducts(value) { 
            this.productsList= []; 
            this.selectProductsearch(value);       
    }

    onKeyProductCategories(value) { 
            this.productCategoriesList= []; 
            this.selectProductCategoriesSearch(value);       
    }


    ////////////////////////
    // search elements


    selectProductCategoriesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.productCategoriesData.length; i ++ ) {
            let option = this.productCategoriesData[i];
            if (  option.name.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.productCategoriesList?.push( option );
            }
        }
    }

    selectProductsearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.productsData.length; i ++ ) {
            let option = this.productsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0
		|| option.name?.toLowerCase().indexOf(filter) >= 0  ) {
                this.productsList?.push( option );
            }
        }
    }

    
    ////////////////////////////
    // elements selections
    ///////////////////////////
    
    OnProductCategoriesChanged($event: any) {
      console.log($event);
    }

    OnProductsChanged($event: any) {
	console.log($event);
    }

    ////////////////////////////
    // External actions
    ///////////////////////////
    
    addNewProductCategory(): void {
/*    const dialogRef = this.dialog.open(AddRoleDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {

      }
    });*/
  }
    
    ////////////////////////
    ///////////////////////
    
    
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid || !this.selectedProducts || !this.selectedProductCategory) {
	    return;
	}

	let productType : ProductType = {
	    name: this.form.value.nom,
	    description: this.form.value.description,
	};


	const note : Note = {
	    content : this.form.value.note
	}



	productType.note = note;
	productType.products = this.selectedProducts;
	
	if (this.selectedProductCategory){
	    productType.familyTypeId = this.selectedProductCategory.id;
	}

	console.log(productType);
	console.log("gonna save");

	this.productTypeService.prepareAddRelations(productType).pipe(
	    switchMap(preparedProductType => { 
		console.log("entered switch map -- add new productType ");
		console.log(preparedProductType);
		return this.productTypeService.add(productType);}),
	    switchMap(productTypeAddedObservable => {
		let productTypeAdded = productTypeAddedObservable.Data;
		productTypeAdded.products = this.selectedProducts;
		console.log("entered switch map -- handle post relations ");
		console.log(productTypeAdded);
		return this.productTypeService.handlePostAddRelations(productTypeAdded); 
	    })
	
						      
	).subscribe({
	    next: (res) => {  this.checkSubmitSucess(res);},
	    error: (error) => { this.submitError(error) },
	    complete: () => {
		console.info('save products pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();  
		    }, 5000);
		
	    }
	    
	    
	});

    }

    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListProductTypePath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }
}
