import { Component, EventEmitter, Input,  Output, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core'; 
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { ClassLevelService } from 'src/app/services/class-level.service';
import { StatusService } from 'src/app/services/status.service';
import { Status } from 'src/app/interfaces/status';
import { Note } from 'src/app/interfaces/note';
import { ClassLevel } from 'src/app/interfaces/class-level';


import { Response } from '../../interfaces/response';
import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-editer-niveau-scolaire',
  templateUrl: './editer-niveau-scolaire.component.html',
  styleUrls: ['./editer-niveau-scolaire.component.scss'], encapsulation: ViewEncapsulation.None
})
export class EditerNiveauScolaireComponent implements OnInit  {
  @Input() TitleVisible: boolean = true;
  @Input() DisplayConfirmationButton: boolean = true;

    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter(); 
    ////////////////////////
    //  Attributes 
    ////////////////////////

    @Input()     _classLevel: ClassLevel;
    @Input('id') _id?: string; 
    
    form: FormGroup;
    utilisateurs: any;
    permissions: any;

    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatuses : Status[];
    selectedStatus : Status;

    ////////////////////////
    // Constructor
    //////////////////////// 

    constructor(private router: Router,
		private route: ActivatedRoute,
		private classLevelService : ClassLevelService,
		private statusService : StatusService,

		private changeDetectorRefs: ChangeDetectorRef,   
		private _snackBar: MatSnackBar,
		public fb: FormBuilder) { 
	
    }

    ////////////////////////
    // Init
    ////////////////////////  
    ngOnInit(): void {
	this.initCurrentRoute();
	this.refresh();
	this.initForm(); 

    }

    initCurrentRoute():void {
	this.route.params.subscribe(params => {  
	    this._id = params["id"];
	    console.log("current id " + this._id)
	});

    }

    initCurrentObject(): void {
      this.classLevelService.getById(this._id).subscribe(
	  {
              next: (response: Response<number, ClassLevel>) => {
		  console.log("response");
		  console.log(response);
		  this._classLevel = response.Data;
		  if (this._classLevel?.status)
		      this.selectedStatus = this._classLevel?.status ;
		  if (this._classLevel?.note) {
		      
		  } else {
		      const note : Note = {  content :  "" };
		      this._classLevel.note = note;
		  }
		  console.log(this._classLevel.status);
		  console.log(this._classLevel);
		  
              },
              error: (e) => {
		  console.log(e);
              },
              complete: () => console.info('loaduser complete')
	  }
      );

    }



    ////////////////////////
    // Datasrouces
    ///////////////////////


    
    refresh(): void {
	this.initCurrentObject();
	
	this.statusService.getAllStatuses().subscribe({
	    next: (response: Response<number, Status[]>) => {
		
		this.statusesData = response.Data;
		this.initStatusesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load statuses complete')
	});


    }

    ////////////////////////
    // Datasrouces Form
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    name: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    description: new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(2)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
	});

    }
    
    ////////////////////////
    // Datasources Functions
    ///////////////////////

    

    compareFn(c1,c2): boolean {
	return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }
    
    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns
    

    initStatusesSearchDropdown(){
	this.statusesList = [];
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
	    {

		if (!this.selectedStatus && this.statusesData[i].label == "Actif")
		{
		    this.selectedStatuses = [];
		    this.selectedStatus = this.statusesData[i];
		    
		    this.selectedStatuses.push(this.statusesData[i]);
		}
		this.statusesList.push( this.statusesData[i] );
	    }
        }

    }
    
    ////////////////////////
    // On change

    onStatusesChanged($event: any) {
      console.log($event);
    }
    

    ////////////////////////
    // On Search



    onKeyStatuses(value) { 
            this.statusesList= []; 
            this.selectStatusesSearch(value);       
    }

    ////////////////////////
    // search elements

    selectStatusesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
            let option = this.statusesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.statusesList?.push( option );
            }
        }
    }




    ////////////////////////
    ///////////////////////
    
    
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid) {
	    return;
	}

	const note : Note = {
	    content : this.form.value.note
	}

	if ((this._classLevel.note  && this._classLevel.note.id ) || this._classLevel.noteId){
	    note.id =  this._classLevel.note && this._classLevel.note.id ?  this._classLevel.note.id :  this._classLevel.noteId ; 
	}
	
	if (this.selectedStatus){
	    this._classLevel.statusId = this.selectedStatus.id;
	}


	console.log(this._classLevel);
	console.log("gonna save");

	this.classLevelService.prepareUpdateRelations(this._classLevel).pipe(
	    switchMap(preparedClassLevel => { 
		console.log("entered switch map -- add new classLevel ");
		console.log(preparedClassLevel);
		return this.classLevelService.updateById(this._classLevel.id, this._classLevel);
	    }),
	    switchMap(classLevelUpdatedObservable => {
		let classLevelUpdated = classLevelUpdatedObservable.Data;
		    
		console.log("entered switch map -- handle post relations ");
		console.log(this._classLevel);
		return this.classLevelService.handlePostUpdateRelations(this._classLevel); 
	    })
	
						      
	).subscribe({
	    next: (res) => { this.checkSubmitSucess(res);},
	    error: (error) => {this.submitError(error)},
	    complete: () => {
		console.info('save classLevel pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();
		    }, 5000);
		
	    }
	    
	    
	});

    }

    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListNiveauScolairePath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }
 
}

