import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';        

// models
import { Location } from '../interfaces/location';
import { Shipping } from '../interfaces/shipping';
import { Note } from '../interfaces/note';
import { Address as Address } from '../interfaces/address';

// services
import {NoteService} from '../services/note.service'
import {AddressService} from '../services/address.service'

// rxjs
import { of, from,  map, Observable, exhaustMap, EMPTY, empty ,identity, forkJoin} from 'rxjs';
import { mergeMap, mergeAll, toArray, switchMap} from 'rxjs/operators';

// response   
import { Response as Rep } from '../interfaces/response';

import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {BaseCrudModelService as BaseCrudModel} from "./models/base-crud-model.service"

@Injectable({
  providedIn: 'root'
})
export class LocationService extends BaseCrudModel<Location> {
    postShippings = {url: `${environment.domains.api.url}/item-types`, urlOptions: this.options};
    deleteShippings = {url: `${environment.domains.api.url}/item-types`, urlOptions: this.options};
    
    constructor(protected override httpService: HttpService,
		private addressService: AddressService,
		private noteService : NoteService) {
	super(httpService, "locations");

	var options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "objectDetail"},
		{"relation" : "note"},
		{"relation" : "address"},
		//{"relation" : "shippings"},
	    ],
	});

	var addOptions =  RequestOptions.FromArray({
	    relationsToOmit : ['note', 'status', 'shippings', 'address']
	});

	var updateOptions =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "status"},
		{"relation" : "objectDetail"},
		{"relation" : "note"},
		{"relation" : "address"},
		//{"relation" : "shippings"},
	    ],
	    relationsToOmit : ['note', 'status', 'shippings', 'address']
	});

	this.methods.listing.urlOptions = options;
	this.methods.view.urlOptions = options;
	this.methods.add.urlOptions = addOptions;
	this.methods.update.urlOptions = updateOptions;
	
    }

    getAllLocations(): Observable<Rep<number, Location[]>> {
	const options = new RequestOptions();

	return this.getAll()
    }

    getAllRelayPoints(): Observable<Rep<number, Location[]>> {
	var options =  RequestOptions.FromArray({
	    ...this.methods.listing.urlOptions,
	    filters :  [
		//{"locationId" : locationId},
		{"isRelayPoint" : true}
	    ]});
    
	    
	    return this.getAll(options);
	}
    
    
        getAllSalePoints(): Observable<Rep<number, Location[]>> {
	var options =  RequestOptions.FromArray({
	    ...this.methods.listing.urlOptions,
	    filters :  [
		//{"locationId" : locationId},
		{"isSalePoint" : true}
	    ]});
    
	    
	    return this.getAll(options);
	}
    

    
        ////////////////////////////////
    // PREPARE || HANDLE Relations
    ////////////////////////////////
    
    override prepareRelations(fromLocation): Observable<Rep<number, Location>> { //Observable<Profile> {
	// values to save
	const location : Location = fromLocation;


	// Obs Functions
	const failObj  : Rep<number, Note> = {IsSuccess:  0, Data:  null, Status : 0};
	const failAddr : Rep<number, Address> = {IsSuccess:  0, Data:  null, Status : 0};

	const a$ = of();

	const note$ : Observable<Rep<number, Note>> = location.note ? 
	    ( location.note.id ? this.noteService.updateById(fromLocation.note.id, fromLocation.note) : this.noteService.add(fromLocation.note) )
	    :  this.httpService.createObservableResponse<Note>(failObj);

	const add$ : Observable<Rep<number, Address>> = location.address ? 
	    ( location.address.id ? this.addressService.updateById(fromLocation.address.id, fromLocation.address) : this.addressService.add(fromLocation.address) )
	    :  this.httpService.createObservableResponse<Address>(failAddr);

	
	return add$
	    .pipe(
		exhaustMap(add => 
		    {
			//(location.addressId = add && add.IsSuccess && add.Data ? add.Data.id : null );
			console.log("before setting Address");
			console.log(location);
			(location.addressId = add && add.IsSuccess ?
			    (add.Data && add.Data.id ? add.Data.id : location.addressId) : location.addressId );
			
			return   note$
		    }),
		exhaustMap(note => 
		    {
			console.log("created note");
			console.log(note);
			console.log("before setting Note");
			console.log(location);
			(location.noteId = note && note.IsSuccess ?
			    (note.Data && note.Data.id ? note.Data.id : location.noteId) : location.noteId );

			return this.httpService.createObservableResponse<Location[]>({IsSuccess:  1, Data:  [location], Status : 0});
			//return this.createObservableResponse()
			//return   this.httpService.createResponseFromObj<Note>(note)
		    }),
		map(rel =>
		    {
			console.log("final result");
			console.log(rel);
			// return profile
			return    this.httpService.createResponseFromObj<Location>(location) ;
		    }),
		
	    );
    }

    //////////////////////////
    // HANDLE || PREPARE
    //////////////////////////
/*
    override handleRemoveRelations(location): Observable<any> {
	console.log("removePostRelations for location : \n {0}".format(JSON.stringify(location)));

	return forkJoin
	(
	    // Remove elements if empty
	    location && location.id && (location.shippings && location.shippings?.length >= 0) ? this.removeShippings(location.id) :
		this.httpService.createObservableResponse<Shipping[]>({IsSuccess:  0, Data:  undefined, Status : 0})

	)
    }
    
    override handlePostRelations(location): Observable<any> {
	console.log(location);
	console.log("handlePostRelations");
	    return forkJoin
	(
	    
	    // add new elements 
	    location && location.id && location.shippings && location.shippings?.length > 0 ? this.addShippings(location.id, location.shippings) :
		this.httpService.createObservableResponse<Shipping[]>({IsSuccess:  0, Data:  undefined, Status : 0})
	)
    }
*/
    
    //////////////////////////
    // Add Other objects
    //////////////////////////

    public removeShippings(locationId):  Observable<Rep<number, any>> {
	console.log("remove shipping for locationId:{locationId}".formatUnicorn({locationId : locationId}));
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		//{"locationId" : locationId},
		{"locationId" : {"regexp" : "/^{locationId}$/".formatUnicorn({locationId: locationId})}},
	    ],
	});
	options.body = {"locationId" : ""}
	return this.httpService.makePatchRequest<any>( `${this.deleteShippings.url}`, options);
//	return  this.httpService.createObservableResponse<ShippingLocation[]>({IsSuccess:  0, Data:  undefined, Status : 0});
    }


    //////////////////////////
    // Add Other objects
    //////////////////////////

    public addShippings(locationId, shippings):  Observable<Rep<number, any>> {
	
 	
	//const arrayShippings = shippings?.map((r: any): Shipping => ({locationId : locationId, shippingId: r.id}));

	const arrayShippings = shippings?.map((r: Shipping): any => (r.id));

	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		{"id" : {"inq" : arrayShippings}},
	    ],
	});
	console.log("Array shippings");
	console.log(arrayShippings);
	options.body =  {"locationId" : locationId} ;//arrayShippings;	

	return arrayShippings && arrayShippings.length > 0 ?
	    this.httpService.makePatchRequest<Shipping[]>( `${this.postShippings.url}`.format(), options) :
	    this.httpService.createObservableResponse<Shipping[]>({IsSuccess:  0, Data:  undefined, Status : 0});
	//Observable.empty<number, any>() ;
    }
}
