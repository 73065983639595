<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">EDITER ROLE</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Utilisateurs</a></li>
                    <li class="breadcrumb-item active">Roles</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">


        <h3 style="color: black; font-size:medium">Role</h3>
        <h6 style="color: black; font-size: small">Editer un role</h6>
        <form class="example-form" [formGroup]="form" (ngSubmit)="submit()" *ngIf="_role">


                <mat-label>Nom</mat-label>
                <input type="text" matInput formControlName="name"  [(ngModel)]="_role.name"   class="form-control form-control-sm mb-2" required>

                <mat-label>Label</mat-label>
                <input type="text" matInput formControlName="label" [(ngModel)]="_role.label" class="form-control form-control-sm mb-2" required>


                <mat-label>Permissions</mat-label>
                <div class="input-group">
                    <mat-select placeholder="Choisir les permissions pour ce role"  class="  form-control form-control-sm mb-2" [formControl]="permissionsListCtrl" multiple required
				[(ngModel)]="selectedPermissions"  [compareWith]="compareFn" (selectionChange)="onPermissionsChanged($event)"  >
			<input class="mat-select-input-search" placeholder ="recherche " (keyup)="onKeyPermissions($event.target.value)"> 
                    <mat-select-trigger>
                        {{permissionsListCtrl.value?.[0]?.label || ''}}
                        <span *ngIf="(permissionsList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedPermissions?.length || 0) - 1}} {{selectedPermissions?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let permission of permissionsList" [value]="permission">{{permission.label}}</mat-option>                    </mat-select>
                </div>

		
                <mat-label>Utilisateurs</mat-label>
                <div class="input-group">
                    <mat-select placeholder="Choisir les utilisateurs pour ce role" class="  form-control form-control-sm mb-2" [formControl]="usersListCtrl" multiple required
				[(ngModel)]="selectedUsers"  [compareWith]="compareFn" (selectionChange)="onUsersChanged($event)"   >
			<input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyUsers($event.target.value)"> 
                      <mat-select-trigger>
                        {{usersListCtrl.value?.[0]?.username  || ''}} -  {{usersListCtrl.value?.[0]?.profile?.firstname  || ''}} {{usersList?.[0]?.profile?.lastname  || ''}} 
                        <span *ngIf="(usersList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedUsers?.length || 0) - 1}} {{selectedUsers?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let user of usersList" [value]="user">{{user.username}} - {{user.profile?.fistname }} {{user.profile?.lastname}}</mat-option>                  </mat-select>
                </div>


            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Description</mat-label>
                <textarea style="height: 100px;" formControlName="description" [(ngModel)]="_role.description" matInput placeholder="Ex. Ce role a pour but de definir ..." required></textarea>
            </mat-form-field>


            <mat-label>Status</mat-label>
            <div class="input-group">
                <mat-select placeholder="Choisir un statut pour ce role" class="  form-control form-control-sm mb-2"   required
			    [(ngModel)]="selectedStatus"   [formControl]="statusesListCtrl"  [compareWith]="compareFn" (selectionChange)="OnStatusesChanged($event)"  >
			<input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyStatuses($event.target.value)">  
                      <mat-select-trigger>
                        {{statusesListCtrl.value?.label  || ''}}
                        <span *ngIf="(selectedStatuses?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedStatuses?.length || 0) - 1}} {{selectedStatuses?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
		    </mat-select>

            </div>
            <div class="example-full-width d-flex justify-content-center" *ngIf="DisplayConfirmationButton">
                <button class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>
                
                <!-- <button mat-raised-button cdkFocusInitial>Annuler</button> -->
            </div>



        </form>




    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
