
<h1 mat-dialog-title class="mt-2 d-flex justify-content-center" style="font-weight: 300">Nouveau role</h1>
<div mat-dialog-content>
    <app-new-role [TitleVisible]="data.TitleVisible" [DisplayConfirmationButton]="data.DisplayConfirmationButton"></app-new-role>
</div>
<div mat-dialog-actions  class="d-flex justify-content-center">
  <button  class="btn btn-primary btn-sm mr-3"  mat-dialog-close>Annuler</button>
  <button  class="btn btn-primary btn-sm" [mat-dialog-close]="true" cdkFocusInitial>Sauvegarder</button>
</div>


