import { Component, EventEmitter, Input,  Output, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core'; 
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { BrandService } from 'src/app/services/brand.service';

import { ProductService } from 'src/app/services/product.service';
import { ProductTypeService } from 'src/app/services/product-type.service';
import { StatusService } from 'src/app/services/status.service';

import {  Brand } from 'src/app/interfaces/brand';
import { Product } from 'src/app/interfaces/product';
import { Note } from 'src/app/interfaces/note';
import { ProductType } from 'src/app/interfaces/product-type';
import { Status } from 'src/app/interfaces/status';

import { Response } from '../../interfaces/response';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-editer-product',
  templateUrl: './editer-product.component.html',
  styleUrls: ['./editer-product.component.scss'], encapsulation: ViewEncapsulation.None
})
export class EditerProductComponent  implements OnInit  {

    @Input() TitleVisible: boolean = true;
    @Input() DisplayConfirmationButton: boolean = true;

    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter(); 

    ////////////////////////
    //  Attributes 
    ////////////////////////

    @Input()     _product: Product;
    @Input('id') _id?: string; 

    
    @ViewChild("mainImageUpload", {static: false}) mainImageUpload: ElementRef;
    mainImages = [];

    @ViewChild("imagesUpload", {static: false}) imagesUpload: ElementRef;
    images  = [];

    
    form: FormGroup;

    productTypesListCtrl = new FormControl('');
    productTypesData : ProductType[];//string[];
    productTypesList : ProductType[];//string[];
    selectedProductTypes : ProductType[];
    selectedProductType : ProductType;

    brandsListCtrl = new FormControl('');
    brandsList : Brand[];//string[];
    brandsData : Brand[];//string[];
    selectedBrands : Brand[];
    selectedBrand : Brand;

    
    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatuses : Status[];
    selectedStatus : Status;

    ////////////////////////
    // Constructor
    //////////////////////// 

    constructor(private router: Router,
		private route: ActivatedRoute,
		private productService : ProductService,
		private statusService : StatusService,
		private brandService: BrandService,
		private productTypeService : ProductTypeService,
		
		private changeDetectorRefs: ChangeDetectorRef,   
		private _snackBar: MatSnackBar,
		public fb: FormBuilder) { 
	
    }

    ////////////////////////
    // Init
    ////////////////////////  
    ngOnInit(): void {
	this.initCurrentRoute();
	this.refresh();
	this.initForm(); 

    }

    initCurrentRoute():void {
	this.route.params.subscribe(params => {  
	    this._id = params["id"];
	    console.log("current id " + this._id)
	});

    }

    initCurrentObject(): void {
	
      this.productService.getById(this._id).subscribe(
	  {
              next: (response: Response<number, Product>) => {
		  console.log("response");
		  console.log(response);
		  this._product = response.Data;
		  if (this._product?.productType)
		      this.selectedProductType = this._product?.productType;
		  if (this._product.brand)
		      this.selectedBrand = this._product?.brand;
		  if (this._product?.status)
		      this.selectedStatus = this._product?.status ;
		  if (this._product?.note) {
		      
		  } else {
		      const note : Note = {  content :  "  " };
		      this._product.note = note;
		  }
		  if (!this._product.weight)
		      this._product.weight = 0;
		  if (!this._product.description)
		      this._product.description = "    ";

		  console.log(this._product.status);
		  console.log(this._product);
		  
              },
              error: (e) => {
		  console.log(e);
              },
              complete: () => console.info('loaduser complete')
	  }
      );

    }



    ////////////////////////
    // Datasrouces
    ///////////////////////


    
    refresh(): void {
	this.initCurrentObject();
	
	this.statusService.getAllStatuses().subscribe({
	    next: (response: Response<number, Status[]>) => {
		
		this.statusesData = response.Data;
		this.initStatusesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load statuses complete')
	});

	this.productTypeService.getAllProductTypes().subscribe({
            next: (response: Response<number, ProductType[]>) => {
		this.productTypesData = response.Data;
		this.initProductTypesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load productTypes complete')
	});

	this.brandService.getAllBrands().subscribe({
	    next: (response: Response<number, Brand[]>) => {
		this.brandsData = response.Data;
		this.initBrandsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load brands complete')
	});
    }

    ////////////////////////
    // Datasrouces Form
    ///////////////////////

    initForm() : void {
	const  numRegex = /^-?\d*[.,]?\d{0,2}$/;
	this.form = this.fb.group({
	    name: new FormControl(null, [Validators.required, Validators.maxLength(90), Validators.minLength(2)]),
	    price: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.pattern(numRegex), Validators.minLength(1)]),
	    weight: new FormControl(null, [ Validators.maxLength(10), Validators.pattern("^[0-9]*$"), Validators.minLength(1)]),
	    sku: new FormControl(null, [Validators.required, Validators.maxLength(30), Validators.minLength(2)]),
	    quantity: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(1)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
	    description: new FormControl(null, [Validators.required, Validators.maxLength(150), Validators.minLength(2)]),
	    images : new FormControl(null, []),
	    mainImages : new FormControl(null, [])
	});

    }
    
    ////////////////////////
    // Datasources Functions
    ///////////////////////

    

    compareFn(c1, c2): boolean {
	return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }
    
    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns

    initBrandsSearchDropdown(){
	this.brandsList = [];
        for ( let i = 0 ; i < this.brandsData.length; i ++ ) {
            this.brandsList.push( this.brandsData[i] );
        }
    }

    initProductTypesSearchDropdown(){
	this.productTypesList = [];
        for ( let i = 0 ; i < this.productTypesData.length; i ++ ) {
            this.productTypesList.push( this.productTypesData[i] );
        }
    }


    initStatusesSearchDropdown(){
	this.statusesList = [];
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
	    {

		if (!this.selectedStatus && this.statusesData[i].label == "Actif")
		{
		    this.selectedStatuses = [];
		    this.selectedStatus = this.statusesData[i];
		    
		    this.selectedStatuses.push(this.statusesData[i]);
		}
		this.statusesList.push( this.statusesData[i] );
	    }
        }

    }
    
    ////////////////////////
    // On change

    onStatusesChanged($event: any) {
      console.log($event);
    }

    onProductTypesChanged($event: any) {
      console.log($event);
    }

    
    onBrandsChanged($event: any) {
      console.log($event);
    }

    ////////////////////////
    // On Search
    onKeyProductTypes(value) { 
            this.productTypesList= []; 
            this.selectProductTypeSearch(value);       
    }

    onKeyBrands(value) { 
            this.brandsList= []; 
            this.selectBrandsSearch(value);       
    }


    onKeyStatuses(value) { 
            this.statusesList= []; 
            this.selectStatusesSearch(value);       
    }

    ////////////////////////
    // search elements

        selectBrandsSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.brandsData.length; i ++ ) {
            let option = this.brandsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >=0 ) {
                this.brandsList?.push( option );
            }
        }
    }

    selectProductTypeSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.productTypesData.length; i ++ ) {
            let option = this.productTypesData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0  ) {
                this.productTypesList?.push( option );
            }
        }
    }

    
    selectStatusesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
            let option = this.statusesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.statusesList?.push( option );
            }
        }
    }




    ////////////////////////
    ///////////////////////
    
    
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid) {
	    return;
	}

	//
	// Main Image
	const mainImageUpload = this.mainImageUpload.nativeElement;
	this.mainImages = mainImageUpload.files;
	const formDataMainImage = new FormData();
	//	if ( this.mainImage) {
	for (const mainImage of this.mainImages) {
	    console.log("MainImages : \n {mainImage}".formatUnicorn({mainImage: JSON.stringify(mainImage)}));
	    console.log(mainImage);
	    formDataMainImage.append('files', mainImage, mainImage.name);
	}
	

	//
	// Images
	const imagesUpload = this.imagesUpload.nativeElement;
	this.images = imagesUpload.files;
	const formDataImages = new FormData();

	for (const image of this.images) {
	    console.log("Images : \n {image}".formatUnicorn({image: JSON.stringify(image)}));
	    console.log(image);
	    formDataImages.append('files', image, image.name);
	}



	const note : Note = {
	    content : this.form.value.note
	}

	if ((this._product.note &&  this._product.note.id ) || this._product.noteId ){
	    note.id =  this._product.note && this._product.note.id ?  this._product.note.id :  this._product.noteId ; 
	}
	
	if (this.selectedStatus)
	    this._product.statusId = this.selectedStatus.id;
	
	
	if (this.selectedProductType)
	    this._product.productTypeId = this.selectedProductType.id;
	
	
	if (this.selectedBrand)
	    this._product.brandId = this.selectedBrand.id;
	

	if (this.mainImages.length > 0 && formDataMainImage.has('files'))
	    this._product.dataToUpload = formDataMainImage;

	let imgs = this.images?.length > 0 &&  formDataImages.has('files') ?  this._product.images : [];

	this._product.note = note;


	
	//this._product.productTypes = this.selectedProductTypes;
	//this._product.classes = this.selectedBrands;
		
	console.log(this._product);
	console.log("gonna save");
	console.log(this.selectedProductType);
	

	this.productService.prepareUpdateRelations(this._product).pipe(
	    switchMap(preparedProduct => { 
		console.log("entered switch map -- add new product ");
		console.log(preparedProduct);
		return this.productService.updateById(this._product.id, this._product);
	    }),
	    switchMap(productUpdatedObservable => {
		let productUpdated = productUpdatedObservable.Data;
		    
		console.log("entered switch map -- handle post relations ");
		console.log(this._product);

		this._product.brand = this.selectedBrand;
		this._product.productType = this.selectedProductType;
		this._product.images = imgs;
		console.log("Updating product {product}".formatUnicorn({product: JSON.stringify(this._product)}))
		if (this.images.length > 0  && formDataImages.has('files')){
		    this._product.imagesDataToUpload = formDataImages;
		}

		return this.productService.handlePostUpdateRelations(this._product); 
	    })
	
						      
	).subscribe({
	    next: (res) => { this.checkSubmitSucess(res);},
	    error: (error) => {this.submitError(error)},
	    complete: () => {
		console.info('save product pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();
		    }, 5000);
		
	    }
	    
	    
	});

    }

    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListProductPath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }

}
