import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';


import { Customer } from 'src/app/interfaces/customer';
import { CompanyGroup } from 'src/app/interfaces/company-group';
import { Company } from 'src/app/interfaces/company';
import { Status } from 'src/app/interfaces/status';     


import { CustomerService } from 'src/app/services/customer.service';
import { CompanyGroupService } from 'src/app/services/company-group.service';  
import { StatusService } from 'src/app/services/status.service';
import { CompanyService } from 'src/app/services/company.service';  

import { environment } from 'src/environments/environment';

import { Response } from '../../interfaces/response';
import { Router } from '@angular/router';

import { MatSort } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout'; 
import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-list-customer',
  templateUrl: './list-customer.component.html',
  styleUrls: ['./list-customer.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ListCustomerComponent implements OnInit, AfterViewInit {
    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
    @ViewChild(MatPaginator) paginator: MatPaginator;

    //////////////////////
    // Attributes
    //////////////////////
    
    columns: string[] = ['#', 'name', 'email', 'phoneNumber', 'address', 'company', 'companyGroup','status', 'Action' ];
    columnsSelection: string[] = ['id', 'profile.email', 'profile.lastname', 'profile.firstname',
				  'billingAddress.street', 'billingAddress.city', 'billingAddress.zip' ,
				  'shippingAddress.street', 'shippingAddress.city', 'shippingAddress.zip',
				  'profile.address.street', 'profile.address.city', 'profile.address.zip',
				  'status.label', 'company.name', 'profile.phoneNumber', 'Action' ];

  datasource: MatTableDataSource<Customer> = new MatTableDataSource<Customer>();
  pageSize = 10;

    customers: Customer[];

    color="#f8f8fb";
    
    companiesListCtrl = new FormControl('');
    companiesData : Company[];//string[];
    companiesList : Company[];//string[];
    selectedCompanies : Company[];

    companyGroupsListCtrl = new FormControl('');
    companyGroupsList : CompanyGroup[];//string[];
    companyGroupsData : CompanyGroup[];//string[];
    selectedCompanyGroups : CompanyGroup[];
    
    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatus : Status;
    
    isLoading = true;
    
    //////////////////////
    // Constructor
    //////////////////////
    
    constructor(private customerService: CustomerService,
		private companyGroupService : CompanyGroupService,
		private companyService : CompanyService,
		private statusService : StatusService,
		
		private router: Router,
		public dialog: MatDialog) {

  }

      
    //////////////////////
    // Init
    //////////////////////

    ngAfterViewInit() {
	this.datasource.paginator = this.paginator;
	this.datasource.filterPredicate = this.tableFilter(); 
    }

    
    ngOnInit(): void {
      this.refresh();
    }

    //////////////////////
    // Data sources
    //////////////////////

        tableFilter(): (data: any, filter: string) => boolean {
	let columnsNames = this.columnsSelection;

	let filterFunction = function(data, filter): boolean{
	    

	    var datas = columnsNames.map(x => {
		// split string succh as "customer.name"
		var objectData = x.includes(".") ? x.split(".") : [x];
		var currentObject = data;

		// concat array to create object like data["customer"]["name"]
		objectData.forEach(key => {
		    if (!currentObject[key])
			return;
		    currentObject = currentObject[key];
		})
		// get val and check if its related to filter
		let val = currentObject ?? data[x];
		if (val && val.toString().toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1){		    
		    return  data;
		}
		return null;
		
	    }).filter(x => x); // map and filter to eliminate null data in array

	    
	    return datas?.length > 0 ? true : false; 
	}
	return filterFunction;
     }

    doFilter(value: string) : void  {
	this.datasource.filter = value.trim().toLocaleLowerCase();
	}
    
    refresh() :void{
	this.setLoading(true);
	this.customerService.getAllCustomers().subscribe(
	    {
		next: (response: Response<number, Customer[]>) => {
		    this.customers = response.Data;
		    this.datasource.data = this.customers;
		    this.datasource.filterPredicate = this.tableFilter(); 
		    this.datasource.paginator = this.paginator;
		    this.setLoading(false);
		},
		error: (e) => {
		    this.setLoading(false);
		},
		complete: () => console.info('loadMenu complete')
	    }
	);

	this.companyService.getAll().subscribe({
	    next: (response: Response<number, Company[]>) => {
		this.companiesData = response.Data ? response.Data : [];
		this.initCompaniesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load companies complete')
	});
	this.statusService.getAll().subscribe({
	    next: (response: Response<number, Status[]>) => {
		this.statusesData = response.Data;
		this.initStatusesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load status complete')
	});
	this.companyGroupService.getAll().subscribe({
	    next: (response: Response<number, CompanyGroup[]>) => {
		this.companyGroupsData = response.Data;
		this.initCompanyGroupsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load status complete')
	});
	
    }




    ////////////////////////
    // Datasources Functions
    ///////////////////////

    

    compareFn(c1, c2): boolean {
	return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }
    
    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns

    initCompanyGroupsSearchDropdown(){
	this.companyGroupsList = [];
        for ( let i = 0 ; i < this.companyGroupsData.length; i ++ ) {
            this.companyGroupsList.push( this.companyGroupsData[i] );
        }
    }

    initCompaniesSearchDropdown(){
	this.companiesList = [];
        for ( let i = 0 ; i < this.companiesData.length; i ++ ) {
            this.companiesList.push( this.companiesData[i] );
        }
    }


    initStatusesSearchDropdown(){
	this.statusesList = [];
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
	    this.statusesList.push( this.statusesData[i] );	    
        }

    }
    
    ////////////////////////
    // On change

    onStatusesChanged($event: any) {
      console.log($event);
    }

    onCompaniesChanged($event: any) {
      console.log($event);
    }

    
    onCompanyGroupsChanged($event: any) {
      console.log($event);
    }

    ////////////////////////
    // On Search
    onKeyCompanies(value) { 
            this.companiesList= []; 
            this.selectCompanySearch(value);       
    }

    onKeyCompanyGroups(value) { 
            this.companyGroupsList= []; 
            this.selectCompanyGroupsSearch(value);       
    }


    onKeyStatuses(value) { 
            this.statusesList= []; 
            this.selectStatusesSearch(value);       
    }

    ////////////////////////
    // search elements

    selectCompanyGroupsSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.companyGroupsData.length; i ++ ) {
            let option = this.companyGroupsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >=0 ) {
                this.companyGroupsList?.push( option );
            }
        }
    }

    selectCompanySearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.companiesData.length; i ++ ) {
            let option = this.companiesData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0  ) {
                this.companiesList?.push( option );
            }
        }
    }

    
    selectStatusesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
            let option = this.statusesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.statusesList?.push( option );
            }
        }
    }




    ////////////////////////
    ///////////////////////
    
    

    //////////////////////
    // Loading
    //////////////////////

    toggleLoading() : void {
	this.isLoading = !this.isLoading;
    }

    setLoading(loading : boolean) : void {
	this.isLoading = loading;
    }

    //////////////////////
    //////////////////////
    
    //////////////////////
    // Actions
    ////////////////////// 
    


  onAddNewCustomer(): void {
      this.router.navigate([environment.paths.NewCustomerPath]);
  }

  onEditCustomer(id): void {
	console.log("payment");
	this.router.navigate([environment.paths.EditerCustomerPath, id]);

  }

 onConsulterCustomer(id): void {
	this.router.navigate([environment.paths.ConsulterCustomerPath,id]);

  }

  onDelete(id): void {
    const dialogRef = this.dialog.open(ConfirmerSuppressionDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
	  console.log(id);;


	  this.customerService.deleteById(id).subscribe(
	      {
		  next: (response: Response<number, Customer>) => {
		      console.log(response);
		      this.refresh();
		  },
		  error: (e) => {
		      console.log(e);
		  },
		  complete: () => console.info('delete complete')
	      }
	  );
      }
    });
  }
    
}
