<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">ÉDITER LIVRAISON</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Livraisons</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">



        <h3 style="color: black; font-size:medium">Éditer une livraison</h3>
        <form class="example-form" [formGroup]="form" (ngSubmit)="submit()">


            <div class="row mt-3">
                <div class="col-4">
                    <mat-label>Numéro</mat-label>
                    <input type="text" matInput formControlName="nom" class="form-control form-control-sm" required>
                </div>
                <div class="col-4">
                    <mat-label>Commande</mat-label>
                    <div class="input-group">
                        <mat-select placeholder="Choisir..." class="  form-control form-control-sm"
                            [formControl]="toppings" multiple required>
                            <mat-select-trigger>
                                {{toppings.value?.[0] || ''}}
                                <span *ngIf="(toppings.value?.length || 0) > 1" class="example-additional-selection">
                                    (+{{(toppings.value?.length || 0) - 1}} {{toppings.value?.length === 2 ? 'other' :
                                    'others'}})
                                </span>
                            </mat-select-trigger>
                            <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                        </mat-select>
                    </div>
                </div>
                <div class="col-4">
                    <mat-label>Retrait/Expédition</mat-label>
                    <div class="input-group">
                        <mat-select placeholder="Choisir..." class="  form-control form-control-sm"
                            [formControl]="toppings" multiple required>
                            <mat-select-trigger>
                                {{toppings.value?.[0] || ''}}
                                <span *ngIf="(toppings.value?.length || 0) > 1" class="example-additional-selection">
                                    (+{{(toppings.value?.length || 0) - 1}} {{toppings.value?.length === 2 ? 'other' :
                                    'others'}})
                                </span>
                            </mat-select-trigger>
                            <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                    <div class="col-3">
                        <mat-label>Numéro de rue</mat-label>
                        <input matInput #numRue maxlength="5" type="number" placeholder="Ex. 21" formControlName="street"
                            class="form-control form-control-sm" required>
                    </div>
                    <div class="col-3">
                        <mat-label>Ville</mat-label>
                        <input matInput formControlName="city" type="text" class="form-control form-control-sm" required>
                    </div>
                    <div class="col-3">
                        <mat-label>Code Postale</mat-label>
                        <input matInput #postalCode maxlength="5" type="number" placeholder="Ex. 94105"
                            formControlName="postal" class="form-control form-control-sm" required>
                    </div>
                    <div class="col-3">
                        <mat-select-country appearance="fill" label="Pays" (onCountrySelected)="onCountrySelected($event)"
                            required>
                        </mat-select-country>
                    </div>
            </div>


            <div class="row">
                <div class="col-4">
                    <mat-label>Livreur</mat-label>
                    <div class="input-group">
                        <mat-select placeholder="Choisir..." class="  form-control form-control-sm"
                            [formControl]="toppings" multiple required>
                            <mat-select-trigger>
                                {{toppings.value?.[0] || ''}}
                                <span *ngIf="(toppings.value?.length || 0) > 1" class="example-additional-selection">
                                    (+{{(toppings.value?.length || 0) - 1}} {{toppings.value?.length === 2 ? 'other' :
                                    'others'}})
                                </span>
                            </mat-select-trigger>
                            <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                        </mat-select>
                    </div>
                </div>
                <div class="col-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Date de livraison previsionnel</mat-label>
                        <input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="pickerLivraisonPre">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="pickerLivraisonPre"></mat-datepicker-toggle>
                        <mat-datepicker #pickerLivraisonPre></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Livre le</mat-label>
                        <input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="pickerLivreLe">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="pickerLivreLe"></mat-datepicker-toggle>
                        <mat-datepicker #pickerLivreLe></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>


            <div class="row mt-3">

                <div class="col-4">
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Note</mat-label>
                        <textarea style="height: 100px;" matInput placeholder="Description" required></textarea>
                    </mat-form-field>
                </div>

                <div class="col-4">
                    <mat-label>Status de livraison</mat-label>
                    <div class="input-group">
                        <mat-select placeholder="Choisir..." class="  form-control form-control-sm"
                            [formControl]="toppings" multiple required>
                            <mat-select-trigger>
                                {{toppings.value?.[0] || ''}}
                                <span *ngIf="(toppings.value?.length || 0) > 1" class="example-additional-selection">
                                    (+{{(toppings.value?.length || 0) - 1}} {{toppings.value?.length === 2 ? 'other' :
                                    'others'}})
                                </span>
                            </mat-select-trigger>
                            <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>








            <div class="example-full-width d-flex justify-content-start">
                <button class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>
            </div>

        </form>




    </div><!--/. container-fluid -->
</section>
<!-- /.content -->