import { Country } from '@angular-material-extensions/select-country';
import { AfterViewInit, Component, EventEmitter, OnInit,  Output, Input,
	 ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';

import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

// services  
import { UserService } from 'src/app/services/user.service';
import { ProductService } from 'src/app/services/product.service';
import { SchoolListService } from 'src/app/services/school-list.service';
//import { ClassGradeService } from 'src/app/services/roles.service';
import { FileService } from 'src/app/services/file.service';      
import { StatusService } from 'src/app/services/status.service';
import { ClassGradeService } from 'src/app/services/class-grade.service';
import { SchoolService } from 'src/app/services/school.service';
import { ClassLevelService } from 'src/app/services/class-level.service';

// models    
import { Model } from 'src/app/interfaces/model';  
import { Status } from 'src/app/interfaces/status';
import { Product } from 'src/app/interfaces/product';
import { SchoolList } from 'src/app/interfaces/school-list';
import { Note } from 'src/app/interfaces/note';
import { ClassGrade } from 'src/app/interfaces/class-grade';
import { File } from 'src/app/interfaces/file';
import { School } from 'src/app/interfaces/school';
import { ClassLevel } from 'src/app/interfaces/class-level';
//
import { Response } from '../../interfaces/response';

//
import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

import {ActivatedRoute} from '@angular/router';

//
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';

import { MatSort } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';

import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';

@Component({
  selector: 'app-editer-liste-scolaire',
  templateUrl: './editer-liste-scolaire.component.html',
  styleUrls: ['./editer-liste-scolaire.component.scss'], encapsulation: ViewEncapsulation.None
})
export class EditerListeScolaireComponent implements OnInit {

    @Input() TitleVisible: boolean = true;
    @Input() DisplayConfirmationButton: boolean = true;

    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter(); 


    ////////////////////////
    //  Attributes 
    ////////////////////////

    @Input()     _schoolList: SchoolList;
    @Input('id') _id?: string; 

    @ViewChild("fileUpload", {static: false}) fileUpload: ElementRef;

    files  = [];
    
    form: FormGroup;
    selectedCountry: Country = undefined;

    ////////////////////////
    // Classes
    ////////////////////////

    schoolsListCtrl = new FormControl('');
    schoolsData : School[];//string[];
    schoolsList : School[];//string[];
    selectedSchool : School;
    selectedSchools : School[];

    classLevelsListCtrl = new FormControl('');
    classLevelsList : ClassLevel[];//string[];
    classLevelsData : ClassLevel[];//string[];
    selectedClassLevel : ClassLevel;
    selectedClassLevels : ClassLevel[];


    classGradesListCtrl = new FormControl('');
    classGradesList : ClassGrade[];//string[];
    classGradesData : ClassGrade[] = [];//string[];
    selectedClassGrade : ClassGrade;

    
    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatuses : Status[];
    selectedStatus : Status;

    ////////////////////////
    // PRODUCTS ATTRIBUTES 
    ////////////////////////
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    
    //
    // Datatable
    //
    columnsSelection: string[] = ['#', 'image', 'product.name', 'product.description','product.sku', 'price', 'quantity', 'Total', 'Action' ];

    datasourceSelection: MatTableDataSource<Model> = new MatTableDataSource<Model>();

    pageSize = 200;
    
    //
    // Data Sources
    //
    productsListCtrl = new FormControl('');
    productsList : Product[];//string[];
    productsData : Product[];//string[];
    selectedProducts : Product[];


    // selectedProduct Converted 
    selectedProductDetails : any[] = [];
    selectedProductDetailsMap : Map<any, any> = new Map<any, any>();
    productDetails : any[] = [];
    
    
    isLoading = true;
    shouldAddClass = false;
    
    ////////////////////////
    // Constructor
    //////////////////////// 

    constructor(private router: Router,
		private route: ActivatedRoute,
		private schoolListService : SchoolListService,
		private statusService : StatusService,
		private classGradeService: ClassGradeService,

		private schoolService: SchoolService,
		private classLevelService: ClassLevelService,
	
		private productService : ProductService,
		private fileService: FileService,
		
		private changeDetectorRefs: ChangeDetectorRef,   
		private _snackBar: MatSnackBar,
		public fb: FormBuilder) { 
	
    }

    ////////////////////////
    // Init
    ////////////////////////  
    ngOnInit(): void {
	this.initCurrentRoute();
	this.refresh();
	this.initForm(); 

    }

    ngAfterViewInit() {
	this.datasourceSelection.paginator = this.paginator;
	this.datasourceSelection.sort = this.sort;
	this.datasourceSelection.filterPredicate = this.tableFilter(); 

	this.initClassGradeDetails(this.selectedClassGrade);
    }

    initCurrentRoute():void {
	this.route.params.subscribe(params => {  
	    this._id = params["id"];
	    //console.log("current id " + this._id)
	});

    }

    initClassGradeDetails(classGrade : any) {
	if (classGrade && classGrade.id) {
	    let classLevel = classGrade.classLevel;
	    let school = classGrade.school;

	    this.selectedClassLevel = classLevel && classLevel.id
		&& this.classLevelsList && this.classLevelsList.length > 0 ?
		this.classLevelsList.find(x => x && x.id == classLevel.id)
		: null;
	    this.selectedSchool = school && school.id
		&& this.schoolsList && this.schoolsList.length > 0 ?
		this.schoolsList.find( x => x && x.id == school.id) : null ;
	}

    }

    initCurrentObject(): void {
	
      this.schoolListService.getByIdWithoutProducts(this._id).subscribe(
	  {
              next: (response: Response<number, SchoolList>) => {
		  //console.log("response");
		  //console.log(response);
		  this._schoolList = response.Data;

		  //this.selectedProducts = this._schoolList?.products;
		  this.selectedProductDetails = this._schoolList?.schoolListProducts;		  
		  this.selectedProductDetailsMap = this.toMap(this.selectedProductDetails, 'productId');
		  console.log(this.selectedProductDetailsMap);
		  this.selectedClassGrade = this._schoolList?.classGrade;
		  
		  
		  if (this._schoolList?.status)
		      this.selectedStatus = this._schoolList?.status ;
		  if (this._schoolList?.note) {
		      
		  } else {
		      const note : Note = {  content :  "" };
		      this._schoolList.note = note;
		  }
		  //console.log(this._schoolList.status);
		  //console.log(this._schoolList);


		  
              },
              error: (e) => {
		  //console.log(e);
              },
              complete: () => {this.initDataTables(); console.info('loaduser complete') }
	  }
      );

    }

    getUrlOf(element) : string {
	var path =  element.path ? element.path.replace(/^public?\//i, "") : null;
	return element && element.path ? `${environment.domains.api.url}${path}` : "";
    }

    
    ////////////////////////
    // Datatable
    ///////////////////////

    
    initDatasources() : void {
	
	this.datasourceSelection.data = this.productDetails;
	this.datasourceSelection.paginator = this.paginator;
	this.datasourceSelection.sort = this.sort;
	this.datasourceSelection.filterPredicate = this.tableFilter(); 
	
    }

    initDataTables(): void {
	this.setLoading(true);  
	this.productService.getAllProductsTable().subscribe({
            next: (response: Response<number, Product[]>) => {
		this.productsData = response.Data;
		//setInterval(() => {
		    this.productDetails = this.productsData.map(product => this.fromProductDataToProductDetail(product));
		//});
		//console.log(this.selectedProductDetails);
		this.initDatasources(); 
		this.initProductsSearchDropdown();
		
            },error: (e) => { this.setLoading(false); }, complete: () => {
		console.info('load products complete'); this.setLoading(false);
	    }
	});


    }

    getCheckedItems() {
	this.selectedProductDetails = this.datasourceSelection.data
	    .filter(i => (i as any).checked == true)
	    .map(product => this.fromProductDetailToProductData(product));
    }

    fromProductDetailToProductData(productDetail) {
	let productData = {
	    productId : productDetail.productId,
	    //price : productDetail.price
	    quantity: productDetail.quantity
	};
	return productData;
    }

    toMap(list, keyProp) : any{
	const keyValuePairs = list?.map(item => [item[keyProp], item]);
	return new Map(keyValuePairs);
    }
    
    fromProductDataToProductDetail(product) {
	// check if product is checked
	//	let found = this.selectedProductDetails?.find(x=> x.productId == product.id);
	let found = this.selectedProductDetailsMap.get(product.id);
	//let found = null;
	let productDetail = {
	    checked : found ? true :  false,
	    productId : product.id,
	    product: product, 
	    price : product.price,
	    quantity:  found ? (found.quantity ?? 1) : 0 ,
//	    quantity: 0,
	    img : product.mainImage? this.getUrlOf(product.mainImage) : ""
	}
	////console.log(productDetail);
	return productDetail;
	
    }
    
    ////////////////////////
    // Filter Datatable
    ///////////////////////

    doFilter(value: string) : void  {
	this.datasourceSelection.filter = value.trim().toLocaleLowerCase();
    }

    
    tableFilter(): (data: any, filter: string) => boolean {
	let columnsNames = this.columnsSelection;

	let filterFunction = function(data, filter): boolean{
	    

	    var datas = columnsNames.map(x => {
		// split string succh as "customer.name"
		var objectData = x.includes(".") ? x.split(".") : [x];
		var currentObject = data;

		// concat array to create object like data["customer"]["name"]
		objectData.forEach(key => {
		    currentObject = currentObject[key];
		})
		// get val and check if its related to filter
		let val = currentObject ?? data[x];
		if (val && val.toString().toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1){		    
		    return  data;
		}
		return null;
		
	    }).filter(x => x); // map and filter to eliminate null data in array

	    
	    return datas?.length > 0 ? true : false; 
	}
	return filterFunction;
     }



    ////////////////////////
    // Datasrouces
    ///////////////////////


    
    refresh(): void {
	this.initCurrentObject();
	
	this.statusService.getAllStatuses().subscribe({
	    next: (response: Response<number, Status[]>) => {
		
		this.statusesData = response.Data;
		this.initStatusesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load statuses complete')
	});

	/*this.productService.getAllProducts().subscribe({
            next: (response: Response<number, Product[]>) => {
		this.productsData = response.Data;
		this.initProductsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load products complete')
	});*/

	this.classGradeService.getAllClassGrades().subscribe({
	    next: (response: Response<number, ClassGrade[]>) => {
		this.classGradesData = response.Data;
		this.initClassGradesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load classGrades complete')
	});

	this.schoolService.getAllSchools().subscribe({
            next: (response: Response<number, School[]>) => {
		this.schoolsData = response.Data;
		this.initSchoolsSearchDropdown();
		this.initClassGradeDetails(this.selectedClassGrade);
	    },error: (e) => { }, complete: () => console.info('load schools complete')
	});

	this.classLevelService.getAllClassLevels().subscribe({
	    next: (response: Response<number, ClassLevel[]>) => {
		this.classLevelsData = response.Data;
		this.initClassLevelsSearchDropdown();
		this.initClassGradeDetails(this.selectedClassGrade);
            },error: (e) => { }, complete: () => console.info('load classLevels complete')
	});

    }

    ////////////////////////
    // Datasrouces Form
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    name: new FormControl(null, [Validators.required, Validators.maxLength(60), Validators.minLength(2)]),
	    note: new FormControl(null, [Validators.maxLength(200), Validators.minLength(0)]),
	    files : new FormControl(null, [])
	});

    }
    
    ////////////////////////
    // Datasources Functions
    ///////////////////////

    

    compareFn(c1,c2): boolean {
	console.log(c1);
	console.log(c2);
	return c1 && c2 ? c1.id === c2.id  || c1.name === c2.name : c1 === c2;
    }
    
    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns

    initClassLevelsSearchDropdown(){
	this.classLevelsList = [];
        for ( let i = 0 ; i < this.classLevelsData.length; i ++ ) {
            this.classLevelsList.push( this.classLevelsData[i] );
        }
    }


    
    initSchoolsSearchDropdown(){
	this.schoolsList = [];
        for ( let i = 0 ; i < this.schoolsData.length; i ++ ) {
            this.schoolsList.push( this.schoolsData[i] );
        }
    }


    initClassGradesSearchDropdown(){
	this.classGradesList = [];
        for ( let i = 0 ; i < this.classGradesData?.length; i ++ ) {
            this.classGradesList.push( this.classGradesData[i] );
        }
    }

    initProductsSearchDropdown(){
	this.productsList = this.productsData;
/*        for ( let i = 0 ; i < this.productsData?.length; i ++ ) {
            this.productsList.push( this.productsData[i] );
        }*/
    }


    initStatusesSearchDropdown(){
	this.statusesList = [];
        for ( let i = 0 ; i < this.statusesData?.length; i ++ ) {
	    {

		if (!this.selectedStatus && this.statusesData[i].label == "Actif")
		{
		    this.selectedStatuses = [];
		    this.selectedStatus = this.statusesData[i];
		    
		    this.selectedStatuses.push(this.statusesData[i]);
		}
		this.statusesList.push( this.statusesData[i] );
	    }
        }

    }
    
    ////////////////////////
    // On change

    onStatusesChanged($event: any) {
      //console.log($event);
    }

    onProductsChanged($event: any) {
      //console.log($event);
    }

    onCountrySelected($event: Country) {
	this.selectedCountry = $event;
    }
    
    
    OnClassLevelsChanged($event: any) {
	console.log($event);

	let school : any = this.selectedSchool;
	let classLevel = $event.value;

	if (school && school.id && classLevel && classLevel.id ) {

	    this.selectedClassGrade =  this.classGradesList.length > 0 ?
		this.classGradesList.find(x => x && x.schoolId == school.id  && x.classLevelId == classLevel.id)
		: null;
	}


    }

    OnSchoolsChanged($event: any) {
	console.log($event);

	let school : any = $event.value;
	let classLevel = this.selectedClassLevel;

	if (school && school.id && classLevel && classLevel.id ) {

	    this.selectedClassGrade =  this.classGradesList.length > 0 ?
		this.classGradesList.find(x => x && x.schoolId == school.id  && x.classLevelId == classLevel.id)
		: null;
	}

    }
    
    onClassGradesChanged($event: any) {
	console.log($event);

	let classGrade : any = $event.value;
	if (classGrade && classGrade.id) {
	    let classLevel = classGrade.classLevel;
	    let school = classGrade.school;

	    this.selectedClassLevel = classLevel && classLevel.id && this.classLevelsList.length > 0 ?
		this.classLevelsList.find(x => x && x.id == classLevel.id)
		: null;
	    this.selectedSchool = school && school.id && this.schoolsList.length > 0 ?
		this.schoolsList.find( x => x && x.id == school.id) : null ;
	}
    }


    ////////////////////////
    // On Search
    onKeyProducts(value) { 
            this.productsList= []; 
            this.selectProductSearch(value);       
    }

    onKeyClassGrades(value) { 
            this.classGradesList= []; 
            this.selectClassGradesSearch(value);       
    }


    onKeyStatuses(value) { 
            this.statusesList= []; 
            this.selectStatusesSearch(value);       
    }


    onKeySchools(value) { 
            this.schoolsList= []; 
            this.selectSchoolSearch(value);       
    }

    onKeyClassLevels(value) { 
            this.classLevelsList= []; 
            this.selectClassLevelsSearch(value);       
    }


    ////////////////////////
    // search elements


    selectClassLevelsSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.classLevelsData.length; i ++ ) {
            let option = this.classLevelsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.classLevelsList?.push( option );
            }
        }
    }

    selectSchoolSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.schoolsData.length; i ++ ) {
            let option = this.schoolsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0
		|| option.name?.toLowerCase().indexOf(filter) >= 0  ) {
                this.schoolsList?.push( option );
            }
        }
    }
    

    selectClassGradesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.classGradesData.length; i ++ ) {
            let option = this.classGradesData[i];
            if (  option.classLevel?.name.toLowerCase().indexOf(filter) >=0
		||  option.school?.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.classGradesList?.push( option );
            }
        }
    }

    selectProductSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.productsData.length; i ++ ) {
            let option = this.productsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.productsList?.push( option );
            }
        }
    }

    
    selectStatusesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
            let option = this.statusesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.statusesList?.push( option );
            }
        }
    }




    ////////////////////////
    ///////////////////////

        //////////////////////
    // Loading
    //////////////////////

    toggleLoading() : void {
	this.isLoading = !this.isLoading;
    }

    setLoading(loading : boolean) : void {
	this.isLoading = loading;
    }

    //////////////////////
    //////////////////////

    
    
    ////////////////////////
    // Links / URL
    ///////////////////////


    openInNewTab(namedRoute) {
	let newRelativeUrl = this.router.createUrlTree([namedRoute]);
	//console.log(newRelativeUrl);
	let baseUrl = window.location.href.replace(this.router.url, '');

	window.open(baseUrl + newRelativeUrl, '_blank');
    }
    
    onConsulterProduct(id): void {
	this.openInNewTab(`${environment.paths.ConsulterProductPath}/${id}`);
    }


    ////////////////////////////
    // External actions
    ///////////////////////////
    
    addNewClassGrade(): void {
	this.shouldAddClass = !this.shouldAddClass;
    }
    
    ////////////////////////
    ///////////////////////

    
    ////////////////////////
    // Actions
    ///////////////////////

  submit() {
	if (!this.form.valid) {
	    return;
	}

	this.getCheckedItems();
	
	const fileUpload = this.fileUpload.nativeElement;
	this.files = fileUpload.files;
	const formData = new FormData();

	for (const file of this.files) {
	    //console.log("Files : \n {file}".formatUnicorn({file: JSON.stringify(file)}));
	    //console.log(file);
	    formData.append('files', file, file.name);
	}
	
/*	let schoolList : SchoolList = {
            name: this.form.value.name,
        
	};
*/

	const note : Note = {
	    content : this.form.value.note
	}

	if ((this._schoolList.note &&  this._schoolList.note.id ) || this._schoolList.noteId ){
	    note.id =  this._schoolList.note && this._schoolList.note.id ?  this._schoolList.note.id :  this._schoolList.noteId ; 
	}
	
	if (this.selectedStatus){
	    this._schoolList.statusId = this.selectedStatus.id;
	}


	this._schoolList.note = note;
	this._schoolList.classGrade = this.selectedClassGrade;

	if (this.selectedClassGrade && this.selectedClassGrade.id){
	    //console.log(this.selectedClassGrade.id);
	    this._schoolList.classGradeId = this.selectedClassGrade.id;
	}
	else if (this.selectedSchool && this.selectedSchool.id
	    && this.selectedClassLevel && this.selectedClassLevel.id) {
	    let classGrade : ClassGrade = {
		schoolId : this.selectedSchool.id,
		classLevelId : this.selectedClassLevel.id,
	    };
	    
	    this._schoolList.classGrade = classGrade;  
	}



	//this._schoolList.products = this.selectedProducts;	
	if (this.selectedProductDetails && this.selectedProductDetails.length > 0)
	    this._schoolList.schoolListProducts = this.selectedProductDetails;

	
	//console.log("Gonna save school list : \n {0}".formatUnicorn(JSON.stringify(this._schoolList)));
	


	this._schoolList.dataToUpload  = formData; // working for /files/upload/images
	//console.log(this._schoolList);
	//console.log("gonna save");
	//console.log(this.selectedProducts);
	

	this.schoolListService.prepareUpdateRelations(this._schoolList).pipe(
	    switchMap(preparedSchoolList => { 
		//console.log("entered switch map -- add new schoolList ");
		//console.log(preparedSchoolList);
		return this.schoolListService.updateById(this._schoolList.id, this._schoolList);
	    }),
	    switchMap(schoolListUpdatedObservable => {
		let schoolListUpdated = schoolListUpdatedObservable.Data;
		    
		//console.log("entered switch map -- handle post relations ");
		//console.log(this._schoolList);
		
		//this._schoolList.products = this.selectedProducts;
		this._schoolList.schoolListProducts = this.selectedProductDetails;
		this._schoolList.classGrade = this.selectedClassGrade;
		return this.schoolListService.handlePostUpdateRelations(this._schoolList); 
	    })
	
						      
	).subscribe({
	    next: (res) => { this.checkSubmitSucess(res);},
	    error: (error) => {this.submitError(error)},
	    complete: () => {
		console.info('save schoolList pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();
		    }, 5000);
		
	    }
	    
	    
	});

    }

    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	//this.router.navigate([environment.paths.ListEcolePath]);
	this.router.navigate([environment.paths.ListListScolairePath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	//console.log(error);
    }

    checkSubmitSucess(res){
	//console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }





    ///////////////////////////////
    // DUplicate  ACTION 
    ///////////////////////////////

    onDuplicateSchoolListClicked(){
	if (!this.form.valid) {
	    return;
	}

	this.getCheckedItems();
	
	let schoolList : SchoolList = {
            name: this.form.value.name,

	};

	const note : Note = {
	    content : this.form.value.note
	}



	
	schoolList.note = note;

	schoolList.classGrade = this.selectedClassGrade;

	if (this.selectedClassGrade && this.selectedClassGrade.id){
	    schoolList.classGradeId = this.selectedClassGrade.id;
	}
	else if (this.selectedSchool && this.selectedSchool.id
	    && this.selectedClassLevel && this.selectedClassLevel.id) {
	    let classGrade : ClassGrade = {
		schoolId : this.selectedSchool.id,
		classLevelId : this.selectedClassLevel.id,
	    };
	    
	    schoolList.classGrade = classGrade;  
	}


	if (this.selectedProductDetails && this.selectedProductDetails.length > 0)
	    schoolList.schoolListProducts = this.selectedProductDetails;

	
	console.log("Gonna save school list : \n {0}".formatUnicorn(JSON.stringify(schoolList)));

	const fileUpload = this.fileUpload.nativeElement;
	this.files = fileUpload.files;
	const formData = new FormData();

	for (const file of this.files) {
	    console.log("Files : \n {file}".formatUnicorn({file: JSON.stringify(file)}));
	    console.log(file);
	    formData.append('files', file, file.name);
	}
	console.log(formData);
	schoolList.dataToUpload  = formData; // working for /files/upload/images


	
	this.schoolListService.prepareAddRelations(schoolList).pipe(
	    switchMap(preparedSchoolList => { 
		console.log("entered switch map -- add new schoolList ");
		console.log(preparedSchoolList);
		return this.schoolListService.add(schoolList);}),
	    switchMap(schoolListAddedObservable => {
		let schoolListAdded = schoolListAddedObservable.Data;
		    
		console.log("entered switch map -- handle post relations ");
		console.log(schoolListAdded);
		schoolListAdded.schoolListProducts = this.selectedProductDetails;
		//schoolListAdded.products = this.selectedProducts;
		schoolListAdded.classGrade = this.selectedClassGrade;
		return this.schoolListService.handlePostAddRelations(schoolListAdded); 
	    })
	
						      
	).subscribe({
	    next: (res) => { this.checkDuplicateSubmitSucess(res); },
	    error: (error) => {this.duplicateSubmitError(error);},
	    complete: () => {
		console.info('save schoolList pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.duplicateSubmitSuccess();
		    }, 5000);
		
	    }
	    
	    
	});

    }

    ///////////////////////////////
    // Success / Faillure | Submit

    duplicateSubmitSuccess(){
	this.router.navigate([environment.paths.ListListScolairePath]);
    }
    
    duplicateSubmitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    duplicateSubmitError(error){
	console.log(error);
    }

    checkDuplicateSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.duplicateSubmitFaillure();
	} else {
	    this.duplicateSubmitSuccess();

	}
    }

}

