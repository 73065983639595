<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">CHECKOUT</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Commande</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content bdy" style="background-color: white;">
    <div class="container-fluid" style="padding: 30px;">


        <mat-stepper orientation="horizontal">


            <mat-step>
                <ng-template matStepLabel><mat-icon>fact_check</mat-icon> Sélection</ng-template>

                <div class="table-responsive">
		    <div fxLayout fxLayoutAlign="end center">
			<mat-form-field fxFlex="60%">
			    <input matInput type="text" (keyup)="doFilter($event.target.value)"  name="filter" placeholder="Filtrer">
			</mat-form-field>
		    </div>

                    <table mat-table [dataSource]="datasourceSelection" matSort>
        
                        <ng-container matColumnDef="#">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">
                                <mat-checkbox></mat-checkbox>
                            </th>
                            <td style="width: 4%;" mat-cell *matCellDef="let element">
                                <mat-checkbox [(ngModel)]="element.checked"></mat-checkbox>  
                            </td>
                        </ng-container>
        
                        <ng-container matColumnDef="image">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Image</th>
                            <td style="width: 15%;" mat-cell *matCellDef="let element">
                                <img class="bg-image" src="{{element?.img}}" *ngIf="element && element.img" />
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="product.name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Nom</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span>{{element?.product?.name}}</span>
                            </td>
                        </ng-container>

			<ng-container matColumnDef="product.description">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Description</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span>{{element?.product?.description}}</span>
                            </td>
                        </ng-container>
        
                        <ng-container matColumnDef="price">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Prix</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span class="table-regular">{{element?.price}} </span>
                            </td>
                        </ng-container>
        
                        <ng-container matColumnDef="quantity">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Quantité</th>
                            <td style="width: 120px;" mat-cell *matCellDef="let element">
                                <!-- <input matInput maxlength="5" type="number" placeholder="0" [(ngModel)]="element.qty"  class="form-control form-control-sm" required> -->
                                <mat-form-field appearance="outline" floatLabel="always">
                                    <input matInput type="number" class="example-right-align" [(ngModel)]="element.quantity"  placeholder="0">
                                  </mat-form-field>
                            </td>
                        </ng-container>
        
                        <ng-container matColumnDef="Total">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Total</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span class="table-regular">{{element.price * element.quantity}} $</span>
                            </td>
                        </ng-container>
   
    
        
                        <ng-container matColumnDef="Action">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-icon matTooltip="consulter" (click)="onConsulterProduct(element.productId)" class="icon" aria-hidden="false"
                                    aria-label="Example home icon" fontIcon="preview"></mat-icon>
                            </td>
                        </ng-container>
        
        
        
                        <tr mat-header-row *matHeaderRowDef="columnsSelection"></tr>
                        <tr mat-row *matRowDef="let row; columns: columnsSelection;" class="example-element-row"></tr>
                    </table>
		    
		    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
			<mat-progress-spinner 
			    color="primary" 
			    mode="indeterminate">
			</mat-progress-spinner>
		    </mat-card>
			    
                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="pageSize" showFirstLastButtons
                        aria-label="Select page of periodic elements">
                    </mat-paginator>
                </div>

                <button  mat-raised-button matStepperNext color="primary">Suivant</button>
              </mat-step>


            <mat-step>
  

              <ng-template matStepLabel><mat-icon>local_shipping</mat-icon> Information d'expédition</ng-template>
              <ng-template matStepContent>

                <h6>Remplir les informations ci-dessous</h6>

                <form class="example-form" [formGroup]="form" (ngSubmit)="submit()">


                <div class="row mb-3">

                    <div class="col-6">
			<mat-label>Nom</mat-label>
			<input type="text" matInput formControlName="firstname" class="form-control form-control-sm mb-2" required>
			<app-show-errors [ctrl]="form.get('firstname')"></app-show-errors>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-6">

			<mat-label>Prénom</mat-label>
			<input type="text" matInput formControlName="lastname" class="form-control form-control-sm mb-2" required>
			<app-show-errors [ctrl]="form.get('lastname')"></app-show-errors>
                    </div>
                </div>
    
    
    
                <div class="row mb-3">
                    <div class="col-6">
			<mat-label>Email</mat-label>
			<input type="email" matInput formControlName="email" class="form-control form-control-sm mb-2" required>
			<app-show-errors [ctrl]="form.get('email')"></app-show-errors>
	            </div>
                </div>
    
    
                <div class="row">
                    <div class="col-6">
                            <mat-label>Téléphone</mat-label>
                            <input matInput maxlength="10" type="number" placeholder="" formControlName="phone"  class="form-control form-control-sm" required>
			    <app-show-errors [ctrl]="form.get('phonel')"></app-show-errors>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-6">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Addresse</mat-label>
                       
			    <input matInput #numRue  type="text" placeholder="Ex. 21" formControlName="street"
				   class="form-control form-control-sm" required>
			    <app-show-errors [ctrl]="form.get('street')"></app-show-errors>
                        </mat-form-field>
                    </div>
		    <div class="col-6">
                        <mat-form-field appearance="outline" class="example-full-width">
                            
			    <mat-label>Ville</mat-label>
			    <input matInput formControlName="city" type="text" class="form-control form-control-sm" required>
			    <app-show-errors [ctrl]="form.get('city')"></app-show-errors>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mt-1">
                    <div class="col-6">
			<mat-select-country appearance="legacy" label="Pays" (onCountrySelected)="onCountrySelected($event)"
					    required>
			</mat-select-country>
                    </div>
		</div>

                <div class="row">
                    <div class="col-6">
			<mat-label>Code Postale</mat-label>
			<input matInput #postalCode maxlength="5" type="number" placeholder="Ex. 94105"
                               formControlName="zip" class="form-control form-control-sm" required>
			<mat-hint align="end" style="color: black;">{{postalCode.value.length}} / 5</mat-hint>
			<app-show-errors [ctrl]="form.get('zip')"></app-show-errors>
                    </div>

                </div>

                <div class="row mt-2">
                    <div class="col-6">
                    <mat-form-field appearance="outline" class="example-full-width mb-2">
                        <mat-label>Note</mat-label>
                        <textarea style="height: 100px;" formControlName="note" matInput placeholder="Note" required></textarea>
			<app-show-errors [ctrl]="form.get('note')"></app-show-errors>
                    </mat-form-field>
                    </div>
                </div>

                <div class="row no-gutters mt-1">
                    <div class="col-6 ">
                            <mat-label>Méthode de paiement</mat-label>
                            <div class="input-group">
                    		<mat-select placeholder="Choisir une methode de paiement" class="  form-control form-control-sm mb-2"   required
					    [(ngModel)]="selectedPaymentType" [formControl]="paymentTypesListCtrl"  [compareWith]="compare" (selectionChange)="onPaymentTypesChanged($event)"  >
				    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyPaymentTypes($event.target.value)">  
				    <mat-select-trigger>
					{{selectedPaymentType?.label  || ''}}
					<span *ngIf="(selectedPaymentTypes?.length || 0) > 1" class="example-additional-selection">
					    (+{{(selectedPaymentTypes?.length || 0) - 1}} {{selectedPaymentTypes?.length === 2 ? 'autre' :
					    'autres'}})
					</span>
				    </mat-select-trigger>
				    <mat-option *ngFor="let paymentType of paymentTypesList" [value]="paymentType">{{paymentType.label}}</mat-option>
				</mat-select>
                                <div class="input-group-append">
                                    <button type="button"class="btn btn-primary  form-control-sm" (click)="addNewRole()">
                                    <mat-icon>add</mat-icon>
                                  </button>
                                </div>
                              </div>
                    </div>
                </div>

                <div class="row no-gutters mt-1">
                    <div class="col-6 ">
                            <mat-label>Méthode d expédition</mat-label>
                            <div class="input-group">
                    		<mat-select placeholder="Choisir une methode d'expédition" class="  form-control form-control-sm mb-2"   required
					    [(ngModel)]="selectedShippingType" [formControl]="shippingTypesListCtrl"  [compareWith]="compare" (selectionChange)="onShippingTypesChanged($event)"  >
				    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyShippingTypes($event.target.value)">  
				    <mat-select-trigger>
					{{selectedShippingType?.label  || ''}}
					<span *ngIf="(selectedShippingTypes?.length || 0) > 1" class="example-additional-selection">
					    (+{{(selectedShippingTypes?.length || 0) - 1}} {{selectedShippingTypes?.length === 2 ? 'autre' :
					    'autres'}})
					</span>
				    </mat-select-trigger>
				    <mat-option *ngFor="let shippingType of shippingTypesList" [value]="shippingType">{{shippingType.label}}</mat-option>
				</mat-select>                                <div class="input-group-append">
                                  <button type="button"class="btn btn-primary  form-control-sm" (click)="addNewRole()">
                                    <mat-icon>add</mat-icon>
                                  </button>
                                </div>
                              </div>
                    </div>
                </div>

                <div class="row no-gutters mt-1">
                    <div class="col-6 ">
                            <mat-label>Point de retrait</mat-label>
                            <div class="input-group">
                    		<mat-select placeholder="Choisir un point de retrait" class="  form-control form-control-sm mb-2"   required
					    [(ngModel)]="selectedLocation" [formControl]="locationsListCtrl"  [compareWith]="compare" (selectionChange)="onLocationsChanged($event)"  >
				    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyLocations($event.target.value)">  
				    <mat-select-trigger>
					{{selectedLocation?.label  || ''}}
					<span *ngIf="(selectedLocations?.length || 0) > 1" class="example-additional-selection">
					    (+{{(selectedLocations?.length || 0) - 1}} {{selectedLocations?.length === 2 ? 'autre' :
					    'autres'}})
					</span>
				    </mat-select-trigger>
				    <mat-option *ngFor="let location of locationsList" [value]="location">{{location.name}}</mat-option>
				</mat-select>
                                <div class="input-group-append">
                                  <button type="button"class="btn btn-primary  form-control-sm" (click)="addNewRole()">
                                    <mat-icon>add</mat-icon>
                                  </button>
                                </div>
                              </div>
                    </div>
                </div>

                <button mat-raised-button matStepperPrevious color="primary" class="mr-1">Précédent</button>
                <button mat-raised-button matStepperNext color="primary">Suivant</button>
            </form>
              </ng-template>
            </mat-step>





            <mat-step>
              <ng-template matStepLabel><mat-icon>payment</mat-icon> Information de paiement</ng-template>
              <ng-template matStepContent>

                <p style="color: black; font-size: medium;">Résumé de la commande</p>
                <h6 style="font-size: small;">Remplir les informations ci-dessous</h6>


                <div class="row mb-2">
                    <div class="col-6">
                        <div class="table-responsive">
                            <table mat-table [dataSource]="datasource">
                
                                <ng-container matColumnDef="#">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                    <td  mat-cell *matCellDef="let element">
                                        <span class="table-regular">{{element?.desc}}</span>
                                    </td>
                                </ng-container>
                    
                                <ng-container matColumnDef="Price">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span class="table-regular">{{element?.value}}</span>
                                    </td>
                                </ng-container>
                    
                    
                                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                                <tr mat-row *matRowDef="let row; columns: columns;" class="example-element-row"></tr>
                            </table>
                    
                        </div>
                    </div>
                    <div class="col-6">
                        <img src="https://www.icone-png.com/png/13/12927.png"/>
                    </div>
                </div>

                <button mat-raised-button matStepperPrevious color="primary" class="mr-1">Précédent</button>
                <button mat-raised-button matStepperNext color="primary">Suivant</button>
              </ng-template>
            </mat-step>






            <mat-step>
              <ng-template matStepLabel><mat-icon>done</mat-icon> Confirmation</ng-template>
              <p>This content was rendered eagerly</p>
              <button mat-raised-button color="primary" matStepperPrevious>Précédent</button>
            </mat-step>
          </mat-stepper>

    </div><!--/. container-fluid -->
</section>
<!-- /.content -->


  
