<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">ÉDITER LA CLASSE</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Établissements</a></li>
                    <li class="breadcrumb-item active">Classe</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">



        <a  href="#">Télécharger le pdf</a>
        <h6 class="mt-2" style="color: black; font-size: small">Éditer la classe</h6>
        <form class="example-form" [formGroup]="form" (ngSubmit)="submit()" *ngIf="_classGrade">


  


            <mat-label>École</mat-label>
            <div class="input-group mb-2">
                <mat-select placeholder="Choisir un une ecole pour cette classe" class="  form-control form-control-sm mb-2"   required
			    [(ngModel)]="selectedSchool"
			    [formControl]="schoolsListCtrl"
			    (selectionChange)="OnSchoolsChanged($event)"
			    [compareWith]="compareFn">
		    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeySchools($event.target.value)">  
                    <mat-select-trigger>
                        {{selectedSchool?.name  || ''}}
                        <span *ngIf="(selectedSchools?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedSchools?.length || 0) - 1}} {{selectedSchools?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                      </mat-select-trigger>
                    <mat-option *ngFor="let school of schoolsList" [value]="school">{{school.name}}</mat-option>
		</mat-select>
                <div class="input-group-append">
                    <button type="button" class="btn btn-primary  form-control-sm">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>

            <mat-label>Niveau scolaire</mat-label>
            <div class="input-group  mb-2">
		<mat-select placeholder="Choisir un un niveau scolaire pour cette classe"
			    class="  form-control form-control-sm mb-2"   required
			    [(ngModel)]="selectedClassLevel"
			    [formControl]="classLevelsListCtrl"
			    [compareWith]="compareFn"
			    (selectionChange)="OnClassLevelsChanged($event)"  >
		    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyClassLevels($event.target.value)">  
                    <mat-select-trigger>
                        {{selectedClassLevel?.name  || ''}}
                        <span *ngIf="(selectedClassLevels?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedClassLevels?.length || 0) - 1}} {{selectedClassLevels?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                      </mat-select-trigger>
                    <mat-option *ngFor="let classLevel of classLevelsList" [value]="classLevel">{{classLevel.name}}</mat-option>
		</mat-select>
                <div class="input-group-append">
                    <button type="button" class="btn btn-primary  form-control-sm">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>


            <mat-form-field appearance="outline" class="example-full-width mb-2">
                <mat-label>Note</mat-label>
                <textarea style="height: 100px;" matInput
			  formControlName="note"
			  [ngModel]="_classGrade && _classGrade.note && _classGrade.note.content"
			  (ngModelChange)="_classGrade && _classGrade.note && _classGrade.note.content  = $event"
			  placeholder="Note" required></textarea>
            </mat-form-field>
		    
            <mat-label>Status</mat-label>
            <div class="input-group">
                <mat-select placeholder="Choisir un statut pour cette classe" class="  form-control form-control-sm mb-2"   required
			    [(ngModel)]="selectedStatus"
			    [formControl]="statusesListCtrl"
			    [compareWith]="compareFn"
			    (selectionChange)="onStatusesChanged($event)"  >
			<input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyStatuses($event.target.value)">  
                      <mat-select-trigger>
                        {{selectedStatus?.label  || ''}}
                        <span *ngIf="(selectedStatuses?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedStatuses?.length || 0) - 1}} {{selectedStatuses?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
		</mat-select>
            </div>

            <div class="example-full-width d-flex justify-content-center" *ngIf="DisplayConfirmationButton">
                <button class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>
                <!-- <button mat-raised-button cdkFocusInitial>Annuler</button> -->
            </div>



        </form>




    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
