import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, zip } from 'rxjs';
import { EMPTY, empty  } from 'rxjs';
import { interval, of } from 'rxjs';
import { delay, take } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { Profil } from '../interfaces/profil';
import { UserModel } from '../interfaces/user';
import { UserRole } from '../interfaces/user';
import { UserPermission } from '../interfaces/user';
import { Role } from '../interfaces/role';
import { Permission } from '../interfaces/permission';

import { BaseCrudModelService as BaseCrudModel } from '../services/models/base-crud-model.service';
import { Response as Rep } from '../interfaces/response';


import {ProfileService} from '../services/profile.service'
import {CustomHttpRequestService as HttpService} from "../services/http/custom-http-request.service";
import {HttpRequestOptionsService as RequestOptions} from "../services/http/http-request-options.service"
import {switchMap, map, combineLatest} from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class UserService  extends BaseCrudModel<UserModel>{

    postRoles = {url: `${environment.domains.api.url}/user-roles/all`, urlOptions: this.options};
    deleteRoles = {url: `${environment.domains.api.url}/user-roles`, urlOptions: this.options};
    postPermissions = {url: `${environment.domains.api.url}/user-permissions/all`, urlOptions: this.options};
    deletePermissions = {url: `${environment.domains.api.url}/user-permissions`, urlOptions: this.options};

    postLogin = {url: `${environment.domains.api.url}users/login`, urlOptions: this.options};
    
    //    constructor(private httpClient: HttpClient) { }
    constructor(protected override httpService: HttpService,
		private profileService : ProfileService) {
	super(httpService, "users");

	var options =  RequestOptions.FromArray({
	    relationsToOmit : ['roles', 'permissions']
	});

	this.methods.add.urlOptions = options;
	//this.methods.postRoles = {url: `${environment.domains.api.url}/user-roles/all`, urlOptions: this.options},
    }

    authenticate(user): Observable<Rep<number, UserModel>> {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
	
	const options = new RequestOptions();
	options.headers = headers;
	options.body = user;	
	return this.httpService.makePostRequest<UserModel>( `${this.postLogin.url}`, options) ;
    }

    getAllProfil(): Observable<Rep<number, Profil[]>> {


	const options = new RequestOptions();

/*       return new Observable<Rep<number, Profil[]>>(observer => {
        observer.next({
          IsSuccess: 0,
          Data: [
            {
              id: '1',
              firstname: 'Marc',
              lastname: 'Landers',
              email: 'lander@gmail.com',
              phoneNumber: '+330629798567'
            }
          ]
        });
        observer.complete();
      }); */
	return this.httpService.makeGetRequest<Profil[]>( `${environment.domains.profil.GetAllProfil}`, options);
//	return this.httpClient.get<Rep<number, Profil[]>>( `${environment.domains.profil.GetAllProfil}`, { headers: headers });
	}


    getAllUsers(): Observable<Rep<number, UserModel[]>> {


	const options =  RequestOptions.FromArray({
	    relations :  [
		{"relation" : "roles"},
		{"relation" : "permissions"},
		{"relation" : "profile"}
 	    ],
	});

	return this.getAll(options);
	
    }


    withProfile(profil): Observable<any> {
	return forkJoin(
	    this.profileService.add(profil))
	    .pipe(
		map(profileResp => {
		    console.log(profileResp);
		    //combine result1Data and result2Data ()
		})
	    )
    }


    //////////////////////////
    // PREPARE || HANDLE Relations
    //////////////////////////
    
    override handleRemoveRelations(user): Observable<any> {
	console.log("handlePostRelations - User");
	console.log(user);


	const apiCalls: any = {};
	
	//
	//remove elements if empty
	//
	apiCalls.removeUsers = 	user && user.id
		//&& ((user.roles && user.roles?.length > 0) || user.roles?.length == 0)
		? this.removeRoles(user.id) :
	    this.httpService.createObservableResponse<UserRole[]>({IsSuccess:  0, Data:  undefined, Status : 0});

	apiCalls.remmovePermissions = user && user.id
//		&& ((user.permissions && user.permissions?.length > 0) || user.permissions?.length == 0) 
		? this.removePermissions(user.id) :
	    this.httpService.createObservableResponse<UserPermission[]>({IsSuccess:  0, Data:  undefined, Status : 0});

	return forkJoin(apiCalls);
    }

    
    override handlePostRelations(user): Observable<any> {
	console.log("handlePostRelations - User");
	console.log(user);

	
	return forkJoin
	([
	    //
	    // add new elements
	    //
	    
	    user && user.id  && user.roles && user.roles?.length > 0 ? this.addRoles(user.id, user.roles) :
		this.httpService.createObservableResponse<UserRole[]>({IsSuccess:  0, Data:  undefined, Status : 0}),
	    
	    user && user.id  && user.permissions && user.permissions?.length > 0 ? this.addPermissions(user.id, user.permissions) :
		this.httpService.createObservableResponse<UserPermission[]>({IsSuccess:  0, Data:  undefined, Status : 0}),
            //this.copyAttachments(),
            //this.uploadCustomerData(),
            //this.uploadAttachments()
	]);
    }


    //////////////////////////
    // remove objects
    //////////////////////////

        public removePermissions(userId):  Observable<Rep<number, any>> {
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		{"userId" : userId},
	    ],
	});
	options.bodyParams = {}
	return this.httpService.makeDeleteRequest<any>( `${this.deletePermissions.url}`, options);
    }

    public removeRoles(userId):  Observable<Rep<number, any>> {
	var options =  RequestOptions.FromArray({
	    whereClauses :  [
		{"userId" : userId},
	    ],
	});
	options.bodyParams = {}
	return this.httpService.makeDeleteRequest<any>( `${this.deleteRoles.url}`, options);

    }

    //////////////////////////////////////
    // Add Objects
    ////////////////////////////////////////
    
    public addRoles(userId, roles):  Observable<Rep<number, any>> {
	const options =  new RequestOptions();
	
	const arrayRoles = roles?.map((r: any): UserRole => ({userId : userId, roleId: r.id}));

	console.log(arrayRoles);
	options.body = arrayRoles;	

	return arrayRoles && arrayRoles.length > 0 ?
	    this.httpService.makePostRequest<UserRole[]>( `${this.postRoles.url}`, options) :
	    this.httpService.createObservableResponse<UserRole[]>({IsSuccess:  0, Data:  undefined, Status : 0});
	//Observable.empty<number, any>() ;
    }

    public addPermissions(userId, permissions):  Observable<Rep<number, any>> {
	const options =  new RequestOptions();
	
	const arrayPermissions = permissions?.map((r: any): UserPermission => ({userId : userId, permissionId: r.id}));

	console.log(arrayPermissions);
	options.body = arrayPermissions;	

	return arrayPermissions && arrayPermissions.length > 0 ?
	    this.httpService.makePostRequest<UserPermission[]>( `${this.postPermissions.url}`, options) :
	    this.httpService.createObservableResponse<UserPermission[]>({IsSuccess:  0, Data:  undefined, Status : 0});
    }

}

/*
	    //
	    //remove elements if empty
	    //
p	    user && user.id
		//&& ((user.roles && user.roles?.length > 0) || user.roles?.length == 0)
		? this.removeRoles(user.id) :
		this.httpService.createObservableResponse<UserRole[]>({IsSuccess:  0, Data:  undefined, Status : 0}).pipe(take(1)),
	    
	    user && user.id
//		&& ((user.permissions && user.permissions?.length > 0) || user.permissions?.length == 0) 
		? this.removePermissions(user.id) :
		this.httpService.createObservableResponse<UserPermission[]>({IsSuccess:  0, Data:  undefined, Status : 0}).pipe(delay(100)),

	    //
*/
