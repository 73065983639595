import { Component, EventEmitter, Input,  Output, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';

import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { Note } from 'src/app/interfaces/note';
import { Address } from 'src/app/interfaces/address';
import { Shipping } from 'src/app/interfaces/shipping';
import { Location } from 'src/app/interfaces/location';
import { Status } from 'src/app/interfaces/status';
import { Order  } from 'src/app/interfaces/order';

import { ShippingService } from 'src/app/services/shipping.service';
import { LocationService } from 'src/app/services/location.service';
import { StatusService } from 'src/app/services/status.service';
import { Country } from '@angular-material-extensions/select-country';
import { OrderService } from 'src/app/services/order.service';

import { Response } from '../../interfaces/response';

import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

import {ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-editer-relay-point',
  templateUrl: './editer-relay-point.component.html',
  styleUrls: ['./editer-relay-point.component.scss'], encapsulation: ViewEncapsulation.None
})
export class EditerRelayPointComponent implements OnInit {
    selectedCountry: Country = undefined;

    @Input() TitleVisible: boolean = true;
    @Input() DisplayConfirmationButton: boolean = true;

    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();

    ////////////////////////
    //  Attributes     
    //////////////////////// 

    @Input()     _location: Location;
    @Input('id') _id?: string;
    
    form: FormGroup;


    shippingsListCtrl = new FormControl('');
    shippingsData : Shipping[];//string[];
    shippingsList : Shipping[];//string[];
    selectedShippings : Shipping[];

    
    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatuses : Status[];
    selectedStatus : Status;

    ordersListCtrl = new FormControl('');
    ordersList : Order[];//string[];
    ordersData : Order[];//string[];
    selectedOrders : Order[];
//    selectedOrder : Order;

    ////////////////////////
    // Constructor
    ////////////////////////
  
    constructor(private router: Router,
		private route: ActivatedRoute,
		private locationService : LocationService,
		private statusService : StatusService,
		private shippingService : ShippingService,
		private orderService : OrderService,
		
		private _snackBar: MatSnackBar,
		public fb: FormBuilder) { 
 
      }

        ////////////////////////
    // Init
    ////////////////////////  
    ngOnInit(): void {
	this.initCurrentRoute();
	this.refresh();
	this.initForm(); 

    }

    initCurrentRoute():void {
	this.route.params.subscribe(params => {  
	    this._id = params["id"];
	    console.log("current id " + this._id)
	});

    }

    initCurrentObject(): void {
	
      this.locationService.getById(this._id).subscribe(
	  {
              next: (response: Response<number, Location>) => {
		  console.log("response");
		  console.log(response);
		  this._location = response.Data;
		  this.selectedShippings = this._location?.shippings;
		  if (this._location?.status)
		      this.selectedStatus = this._location?.status ;

		  /*
		    if (this._location?.familyType)
		    this.selectedOrder = this._location?.familyType ;
		  */
		  
		  if (this._location?.note) {
		      
		  } else {
		      const note : Note = {  content :  "" };
		      this._location.note = note;
		  }
		  console.log(this._location.status);
		  console.log(this._location);
		  
              },
              error: (e) => {
		  console.log(e);
              },
              complete: () => console.info('loaduser complete')
	  }
      );

    }



    ////////////////////////
    // Datasrouces
    ///////////////////////


    
    refresh(): void {
	this.initCurrentObject();
	
	this.statusService.getAllStatuses().subscribe({
	    next: (response: Response<number, Status[]>) => {
		
		this.statusesData = response.Data;
		this.initStatusesSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load statuses complete')
	});

	this.orderService.getAllOrders().subscribe({
	    next: (response: Response<number, Order[]>) => {
		
		this.ordersData = response.Data;
		this.initOrdersSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load orders complete')
	});

	this.shippingService.getAllShippings().subscribe({
            next: (response: Response<number, Shipping[]>) => {
		this.shippingsData = response.Data;
		this.initShippingsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load shippings complete')
	});

    }

    ////////////////////////
    // Datasrouces Form
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    name: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    street: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    city: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
	    zip: new FormControl(null, [Validators.required, Validators.maxLength(5),  Validators.pattern("^[0-9]*$"), Validators.minLength(5)]),
//	    label: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(2)]),
//	    description: new FormControl(null, [Validators.required, Validators.maxLength(150), Validators.minLength(2)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),
	    isSalePoint: new FormControl(null, []),
	    isRelayPoint: new FormControl(null, []),
	    country: new FormControl(''),
	});

    }
    
    ////////////////////////
    // Datasources Functions
    ///////////////////////

    

    compareFn(c1,c2): boolean {
	return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }
    
    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns


    initShippingsSearchDropdown(){
	this.shippingsList = [];
        for ( let i = 0 ; i < this.shippingsData.length; i ++ ) {
            this.shippingsList.push( this.shippingsData[i] );
        }
    }


    initStatusesSearchDropdown(){
	this.statusesList = [];
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
	    {

		if (!this.selectedStatus && this.statusesData[i].label == "Actif")
		{
		    this.selectedStatuses = [];
		    this.selectedStatus = this.statusesData[i];
		    
		    this.selectedStatuses.push(this.statusesData[i]);
		}
		this.statusesList.push( this.statusesData[i] );
	    }
        }

    }

    initOrdersSearchDropdown(){
	this.ordersList = [];
        for ( let i = 0 ; i < this.ordersData.length; i ++ ) {
	    {
		this.ordersList.push( this.ordersData[i] );
	    }
        }

    }

    ////////////////////////
    // On change

    onStatusesChanged($event: any) {
      console.log($event);
    }

    onOrdersChanged($event: any) {
	console.log($event);
    }

    onShippingsChanged($event: any) {
      console.log($event);
    }


    ////////////////////////
    // On Search

    onCountrySelected($event: Country) {
    this.selectedCountry = $event;
  }

    onKeyShippings(value) { 
            this.shippingsList= []; 
            this.selectShippingSearch(value);       
    }



    onKeyStatuses(value) { 
            this.statusesList= []; 
            this.selectStatusesSearch(value);       
    }

    onKeyOrders(value) { 
            this.ordersList= []; 
            this.selectOrdersSearch(value);       
    }

    ////////////////////////
    // search elements


    selectShippingSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.shippingsData.length; i ++ ) {
            let option = this.shippingsData[i];
            if (  option.shippingNumber.toLowerCase().indexOf(filter) >= 0
		|| option.shippingNumber?.toLowerCase().indexOf(filter) >= 0 ) {
                this.shippingsList?.push( option );
            }
        }
    }

    
    selectStatusesSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.statusesData.length; i ++ ) {
            let option = this.statusesData[i];
            if (  option.label.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.statusesList?.push( option );
            }
        }
    }



    selectOrdersSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.ordersData.length; i ++ ) {
            let option = this.ordersData[i];
            if (    option.orderNumber.toLowerCase().indexOf(filter) >= 0 ) {
                this.ordersList?.push( option );
            }
        }
    }




    ////////////////////////
    ///////////////////////
    
    
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid) {
	    return;
	}


	const note : Note = {
	    content : this.form.value.note
	}

	if ((this._location.note &&  this._location.note.id ) || this._location.noteId ){
	    note.id =  this._location.note && this._location.note.id ?  this._location.note.id :  this._location.noteId ; 
	}
	
	if (this.selectedStatus){
	    this._location.statusId = this.selectedStatus.id;
	}

	/*if (this.selectedOrders){
	    this._location.familyTypeIdorders = this.selectedOrders;
	}*/

	const address: Address = {
	  country:  this.selectedCountry.name,
          street: this.form.value.street?.toString(),
          city: this.form.value.city,
          zip: this.form.value.zip?.toString(),
//	  zipCode: this.form.value.postal?.toString(),
          additionalDetail: ''	  
      };


	if (this._location.address?.id)
	    address.id = this._location.address.id.toString();
	else if (this._location.addressId)
	    address.id = this._location.addressId;

	
	this._location.note = note;
	this._location.address = address;

	if (this.selectedShippings && this.selectedShippings.length > 0)
	    this._location.shippings = this.selectedShippings;
	if (this.selectedOrders && this.selectedOrders.length > 0)
	    this._location.orders = this.selectedOrders;
	
	this._location.note = note;

		
	console.log(this._location);
	console.log("gonna save");
	console.log(this.selectedShippings);
	

	this.locationService.prepareUpdateRelations(this._location).pipe(
	    switchMap(preparedLocation => { 
		console.log("entered switch map -- add new location ");
		console.log(preparedLocation);
		return this.locationService.updateById(this._location.id, this._location);
	    }),
	    switchMap(locationUpdatedObservable => {
		let locationUpdated = locationUpdatedObservable.Data;
		    
		console.log("entered switch map -- handle post relations ");
		console.log(this._location);
		this._location.shippings = this.selectedShippings;
		this._location.orders = this.selectedOrders;
		return this.locationService.handlePostUpdateRelations(this._location); 
	    })
	
						      
	).subscribe({
	    next: (res) => { this.checkSubmitSucess(res);},
	    error: (error) => {this.submitError(error)},
	    complete: () => {
		console.info('save location pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();
		    }, 5000);
		
	    }
	    
	    
	});

    }

    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListRelayPointsPath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }




}
