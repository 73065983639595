import { Component, EventEmitter, OnInit, Input, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';

import { environment } from 'src/environments/environment';

import { ProfileService } from 'src/app/services/profile.service';
import { Profil } from 'src/app/interfaces/profil';
import { Response } from '../../interfaces/response';

@Component({
  selector: 'app-consulter-profil',
  templateUrl: './consulter-profil.component.html',
  styleUrls: ['./consulter-profil.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ConsulterProfilComponent implements OnInit  {
  @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();


    ////////////////////////
    // Attributes
    ///////////////////////
    
    @Input()     _profile: Profil;
    @Input('id') _id?: string;

    ////////////////////////
    // constructor
    ///////////////////////
    
    constructor(private router: Router,
		private route: ActivatedRoute,
	       private profileService: ProfileService) {
  }


    ////////////////////////
    // init
    ///////////////////////
    ngOnInit(): void {
	
	this.initCurrentRoute();
	this.refresh();

    }

    initCurrentRoute():void {
	this.route.params.subscribe(params => {
            this._id = params["id"];
	    console.log("current id " + this._id)
        });


    }

    initCurrentObject(): void {
	
	this.profileService.getById(this._id).subscribe(
	    {
		next: (response: Response<number, Profil>) => {
		    console.log(response);
		    this._profile = response.Data;
		    
	      //this.datasource.data = this.profile;
              //this.datasource.paginator = this.paginator;

		    console.log(this._profile);

		    // this.refresh();
		},
		error: (e) => {
		    console.log(e);
		},
		complete: () => console.info('loaduser complete')
	    }
	);

    }

    
    
    ////////////////////////
    // Datasource
    ///////////////////////

    refresh(): void {
	this.initCurrentObject();

    }
    

    

  onEditProfil(id): void {
      this.router.navigate([environment.paths.EditerUtilisateurPath, id]);
  }

}
