import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmerSuppressionDialogComponent } from 'src/app/Dialog/confirmer-suppression-dialog/confirmer-suppression-dialog.component';

import { Product } from 'src/app/interfaces/product';
import { ProductType } from 'src/app/interfaces/product-type';
import { ProductCategory } from 'src/app/interfaces/product-category';
import { Status } from 'src/app/interfaces/status';

import { ProductService } from 'src/app/services/product.service';
import { ProductTypeService } from 'src/app/services/product-type.service';
import { ProductCategoryService } from 'src/app/services/product-category.service';
import { StatusService } from 'src/app/services/status.service';

import { environment } from 'src/environments/environment';

import {switchMap, map, combineLatest} from 'rxjs/operators'  
import { Response } from '../../interfaces/response';
import { Router } from '@angular/router';

import { Observable, forkJoin } from 'rxjs';

import { MatSort } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout'; 
import {ProgressSpinnerMode, MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-list-product-type',
  templateUrl: './list-product-type.component.html',
  styleUrls: ['./list-product-type.component.scss'], encapsulation: ViewEncapsulation.None
})
export class ListProductTypeComponent implements OnInit, AfterViewInit {
    @Output() onChangeComponent: EventEmitter<string> = new EventEmitter();
    @ViewChild(MatPaginator) paginator: MatPaginator;
    
    //////////////////////
    // Attributes
    //////////////////////

    columns: string[] = ['#', 'name', 'description', 'category', 'note', 'status', 'Action' ];
    datasource: MatTableDataSource<ProductType> = new MatTableDataSource<ProductType>();
    pageSize = 10;
    
    color="#f8f8fb";
    toppings = new FormControl('');

    toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

    images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);

    productTypes: ProductType[];

    productCategoriesListCtrl = new FormControl('');
    productCategoriesData : ProductCategory[];//string[];
    productCategoriesList : ProductCategory[];//string[];
    selectedProductCategorys : ProductCategory[];

    productsListCtrl = new FormControl('');
    productsList : Product[];//string[];
    productsData : Product[];//string[];
    selectedProductes : Product[];
    
    statusesListCtrl = new FormControl('');
    statusesList : Status[];//string[];
    statusesData : Status[];//string[];
    selectedStatus : Status;
    
    isLoading = true;

    //////////////////////
    // Constructor       
     ////////////////////// 
    constructor(private productTypeService: ProductTypeService,
		private productService : ProductService,
		private productCategoryService : ProductCategoryService,
		private statusService : StatusService,
		private router: Router,
		public dialog: MatDialog) {

  }


    //////////////////////
    // Init
    //////////////////////

    
    ngAfterViewInit() {
	this.datasource.paginator = this.paginator;
    }

    ngOnInit(): void {
	this.refresh();
    }


    //////////////////////
    // Data sources
    //////////////////////

    doFilter(value: string) : void  {
	this.datasource.filter = value.trim().toLocaleLowerCase();
    }

    
    refresh(): void {
	this.setLoading(true);
	this.productTypeService.getAllProductTypes().subscribe(  
	    {
		next: (response: Response<number, Product[]>) => {
		    console.log(response);
		    this.productTypes = response.Data;
		    this.datasource.data = this.productTypes;
		    this.datasource.paginator = this.paginator;
		    this.setLoading(false);
		    console.log(this.productTypes);

		},
		error: (e) => {
		    this.setLoading(false);
		},
		complete: () => console.info('loadMenu complete')
	    }
	);
	this.productCategoryService.getAll().subscribe({
	    next: (response: Response<number, ProductCategory[]>) => {
		this.productCategoriesList = response.Data;
            },error: (e) => { }, complete: () => console.info('load productCategories complete')
	});
	this.statusService.getAll().subscribe({
	    next: (response: Response<number, Status[]>) => {
		this.statusesList = response.Data;
            },error: (e) => { }, complete: () => console.info('load status complete')
	});
	this.productService.getAll().subscribe({
	    next: (response: Response<number, Product[]>) => {
		this.productsList = response.Data;
            },error: (e) => { }, complete: () => console.info('load status complete')
	});
    }

    //////////////////////
    // Loading
    /////////////////////

    toggleLoading() : void {
	this.isLoading = !this.isLoading;
    }

    setLoading(loading : boolean) : void {
	this.isLoading = loading;
    }

    //////////////////////
    //////////////////////

    

    //////////////////////
    // Actions
    //////////////////////

    
  onAddNewProductType(): void {
    this.router.navigate([environment.paths.NewProductTypePath]);
  }

  onEditProductType(id): void {
      this.router.navigate([environment.paths.EditerProductTypePath, id]);
  }

  onConsulterProductType(id): void {
      this.router.navigate([environment.paths.ConsulterProductTypePath, id]);
  }

  onDelete(id): void {
    const dialogRef = this.dialog.open(ConfirmerSuppressionDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
	  this.productTypeService.deleteById(id).subscribe(
	      {
		  next: (response: Response<number, ProductType>) => {
		      console.log(response);
		      this.refresh();
		  },
		  error: (e) => {
		      console.log(e);
		  },
		  complete: () => console.info('delete complete')
	      }
	  );
      }
    });
  }
}
