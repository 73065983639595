<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">VOIR MÉTHODE EXPÉDITION</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Livraisons</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;" *ngIf="_shippingType">
    <div class="container-fluid" style="padding: 30px;">

        <div class="d-flex justify-content-end">
            <button class="btn btn-primary btn-sm" (click)="onEditShippingType(_shippingType.id)" >Editer méthode d'expédition</button>
        </div>

        <label>Voir méthode d'expédition</label>
        <div class="lateral-space">
   
            <div class="row mt-3 w-100 left-background">
                <div class="col-6 left">
                    Nom
                </div>
                <div class="col-6 right normal">
                    {{_shippingType?.name}}    
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Label
                </div>
                <div class="col-6 right normal">
                    {{_shippingType?.label}}  
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    Description
                </div>
                <div class="col-6 right normal">
                    {{_shippingType?.description}}   
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Commandes
                </div>
                <div class="col-6 right normal">
                    <span *ngFor="let shipping of _shippingType?.shippings"  class="badge badge-pill badge-primary">{{shipping?.shippingNumber}}</span>
                </div>
            </div>
        </div>




        <label class="mt-5">Informations complémentaires</label>
        <div class="lateral-space">
   
            <div class="row mt-3 w-100 left-background">
                <div class="col-6 left">
                    Créé le {{_shippingType?.objectDetail?.creationDate |  date:'dd/MM/yyyy' }}  
                </div>
                <div class="col-6 right normal">
                    Modifié le {{_shippingType?.objectDetail?.modificationDate |  date:'dd/MM/yyyy' }} 
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Créé par  
                </div>
                <div class="col-6 right link">
                    {{_shippingType?.objectDetail?.creator?.username}}  
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    Modifié par
                </div>
                <div class="col-6 right link">
                    {{_shippingType?.objectDetail?.updater?.username}}  
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6 left">
                    Status
                </div>
                <div class="col-6 right link d-flex justify-content-center">
                    <span class="badge badge-pill badge-primary"> {{_shippingType?.status?.label}}  </span>
                </div>
            </div>
        </div>
    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
