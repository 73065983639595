<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">ÉDITER UN PRODUIT</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Produits</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">



        <h3 style="color: black; font-size:medium">Information sur le produit</h3>
        <h6 style="color: black; font-size: small">Remplissez les informations suivantes</h6>
        <form class="example-form" [formGroup]="form" (ngSubmit)="submit()"  *ngIf="_product">


            <div class="row mt-3">
                <div class="col-6">
                    <mat-label>Nom du produit</mat-label>
                    <input type="text" matInput formControlName="name"  [(ngModel)]="_product.name" class="form-control form-control-sm" required>
		    <app-show-errors [ctrl]="form.get('name')"></app-show-errors>  
                </div>
                <div class="col-6">
                    <mat-label>Type de produit</mat-label>
                    <div class="input-group">
			<mat-select placeholder="Choisir un type de produit" class="  form-control form-control-sm mb-2"
				    [formControl]="productTypesListCtrl"  required
				    [(ngModel)]="selectedProductType"
				    [compareWith]="compareFn"
				    (selectionChange)="onProductTypesChanged($event)"   >
			<input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyProductTypes($event.target.value)"> 
                      <mat-select-trigger>
                        {{selectedProductType?.name  || ''}} 
                        <span *ngIf="(productTypesList?.length >=1)" class="example-additional-selection">
                            (+{{(selectedProductTypes?.length || 0) - 1}} {{selectedProductTypes?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let productType of productTypesList" [value]="productType">{{productType.name}}</mat-option>
                    </mat-select>

                        <div class="input-group-append">
                            <button type="button" class="btn btn-primary  form-control-sm">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-6">
                    <mat-label>Marque</mat-label>
                    <div class="input-group">
			<mat-select placeholder="Choisir une marque pour ce produit" class="  form-control form-control-sm mb-2"
				    [formControl]="brandsListCtrl"  required
				    [(ngModel)]="selectedBrand"
				    [compareWith]="compareFn"
				    (selectionChange)="onBrandsChanged($event)"   >
			    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyBrands($event.target.value)"> 
			    <mat-select-trigger>
                          {{selectedBrand?.name  || ''}} 
				<span *ngIf="(brandsList?.length || 0) > 1" class="example-additional-selection">
				    (+{{(selectedBrands?.length || 0) - 1}} {{selectedBrands?.length === 2 ? 'autre' :
				    'autres'}})
				</span>
			    </mat-select-trigger>
			    <mat-option *ngFor="let brand of brandsList" [value]="brand">{{brand?.name}}</mat-option>
			</mat-select>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-primary  form-control-sm">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-6">

                    <div class="row">

                        <div class="col-4">
                                <mat-label>Prix</mat-label>
                                <input matInput maxlength="5" formControlName="price"  [(ngModel)]="_product.price" type="number" placeholder="Ex. 21" class="form-control form-control-sm" required>
				<app-show-errors [ctrl]="form.get('price')"></app-show-errors>
                        </div>
                        <div class="col-4">
                                <mat-label>Poids</mat-label>
                                <input matInput formControlName="weight"  [(ngModel)]="_product.weight" type="number" class="form-control form-control-sm" required>
				<app-show-errors [ctrl]="form.get('weight')"></app-show-errors>
                        </div>
                        <div class="col-4">
                                <mat-label>Sku</mat-label>
                                <input matInput maxlength="20" type="text" formControlName="sku"  [(ngModel)]="_product.sku" placeholder="Ex. 94105" class="form-control form-control-sm" required>
				<app-show-errors [ctrl]="form.get('sku')"></app-show-errors>
                        </div>
                    </div>

                </div>
            </div>


            <div class="row mt-3">
                <div class="col-6">
                    <div class="row">
                        <div class="col-12">
                            <mat-label>Quantité</mat-label>
                            <input type="number" matInput formControlName="quantity"  [(ngModel)]="_product.quantity"  class="form-control form-control-sm" required>
			    <app-show-errors [ctrl]="form.get('quantity')"></app-show-errors>
    
                        </div>

                        <div class="col-12">
                            <mat-label>Status</mat-label>
                            <div class="input-group">
				<mat-select placeholder="Choisir un statut pour ce produit"
					    class="form-control form-control-sm mb-2"   required
					    [(ngModel)]="selectedStatus"
					    [formControl]="statusesListCtrl"
					    [compareWith]="compareFn"
					    (selectionChange)="onStatusesChanged($event)"  >
				    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyStatuses($event.target.value)">  
				    <mat-select-trigger>
					{{selectedStatus?.label  || ''}}
					<span *ngIf="(selectedStatuses?.length || 0) > 1" class="example-additional-selection">
					    (+{{(selectedStatuses?.length || 0) - 1}} {{selectedStatuses?.length === 2 ? 'autre' :
					    'autres'}})
					</span>
				    </mat-select-trigger>
				    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
				</mat-select>
                            </div>
                        </div>

     
                    </div>
                   
                </div>
                <div class="col-6">
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Description</mat-label>
                        <textarea style="height: 100px;" formControlName="description"  [(ngModel)]="_product.description" matInput placeholder="Description" required></textarea>
			<app-show-errors [ctrl]="form.get('description')"></app-show-errors>
                    </mat-form-field>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-6">
                    <mat-label>Image principale</mat-label>
		    <input type="file"  #mainImageUpload id="mainImageUpload" name="mainImageUpload"  formControlName="mainImages" class="form-control" id="inputGroupMainImage02">
                </div>

                <div class="col-6">
                    <mat-label>Images</mat-label> 
		    <input type="file" [multiple]="true"  #imagesUpload id="imagesUpload" name="imagesUpload"  formControlName="images" class="form-control" id="inputGroupImages03">
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-6">
                    <mat-form-field appearance="outline" class="example-full-width mb-2">
                        <mat-label>Note</mat-label>
                <textarea style="height: 50px;" matInput placeholder="note" formControlName="note"
			  [ngModel]="_product && _product.note && _product.note.content"
			  (ngModelChange)="_product && _product.note && _product.note.content  = $event"
			  required></textarea>
		<app-show-errors [ctrl]="form.get('note')"></app-show-errors>
                    </mat-form-field>
                </div>
            </div>


            <div class="example-full-width d-flex justify-content-start">
                <button class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>
            </div>
            
        </form>




    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
