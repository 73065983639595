<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">ÉDITER LE CLIENT</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Clients</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">


        <h3 style="color: black; font-size:medium">Clients</h3>
        <h6 style="color: black; font-size: small">Éditer le client</h6>
        <form class="example-form" [formGroup]="form" (ngSubmit)="submit()"  *ngIf="_customer">


                <div class="col">
                    <mat-label>Nom</mat-label>
                    <input class="form-control form-control-sm" matInput type="text" formControlName="firstname"
			   [(ngModel)]="_customer && _customer.profile && _customer.profile.firstname" required>
		    <app-show-errors [ctrl]="form.get('firstname')"></app-show-errors>
                </div>
                <div class="col">
                    <mat-label>Prénom</mat-label>
                    <input formControlName="lastname" [(ngModel)]="_customer && _customer.profile && _customer.profile.lastname" class="form-control form-control-sm" matInput type="text" required>
		    <app-show-errors [ctrl]="form.get('lastname')"></app-show-errors>
                </div>

		<div class="col">
		    <mat-label>Telephone</mat-label>
		    <input type="text" matInput formControlName="phone"  [(ngModel)]="_customer && _customer.profile && _customer.profile.phoneNumber"  class="form-control form-control-sm mb-2" required>
		    <app-show-errors [ctrl]="form.get('phone')"></app-show-errors>
		</div>
		<div class="col">
		    <mat-label>Email</mat-label>
		    <input type="text" matInput formControlName="email"  [(ngModel)]="_customer && _customer.profile && _customer.profile.email"  class="form-control form-control-sm mb-2" required>
		    <app-show-errors [ctrl]="form.get('email')"></app-show-errors>
		</div>
		
	    <div class="row mt-5" style="margin-top;5px;margin-bottom;5px;">
		<h6 style="color: black; ">Adresse</h6>
		<mat-divider [inset]="true"></mat-divider>
	    </div>
	    
            <div class="row">
                <div class="col-6">
                    <mat-label>Numéro de rue</mat-label>
                    <input matInput #numRue type="text" placeholder="Ex. 21" formControlName="street"
			   [ngModel]="_customer && _customer?.shippingAddress && _customer?.shippingAddress?.street"
			   (ngModelChange)="_customer &&  _customer.shippingAddress && _customer.shippingAddress.street = $event"
                        class="form-control form-control-sm" required>
		    <app-show-errors [ctrl]="form.get('street')"></app-show-errors>
                </div>
                <div class="col-6">
                    <mat-label>Ville</mat-label>
                    <input matInput
			   [ngModel]="_customer && _customer?.shippingAddress && _customer?.shippingAddress?.city"
			   (ngModelChange)="_customer &&  _customer.shippingAddress && _customer.shippingAddress.city = $event"
			   formControlName="city" type="text" class="form-control form-control-sm" required>
		    <app-show-errors [ctrl]="form.get('city')"></app-show-errors>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <mat-label>Code Postale</mat-label>
                    <input matInput #postalCode maxlength="5" type="number" placeholder="Ex. 94105"
			   [ngModel]="_customer && _customer?.shippingAddress &&_customer?.shippingAddress?.zip"
			   (ngModelChange)="_customer &&  _customer.shippingAddress && _customer.shippingAddress.zip = $event"
			   formControlName="zip" class="form-control form-control-sm" required>
                    <mat-hint align="end" style="color: black;">{{postalCode.value.length}} / 5</mat-hint>
		    <app-show-errors [ctrl]="form.get('zip')"></app-show-errors>
                </div>
                <div class="col-6">
                    <mat-select-country #countryChild name="country" formControlName="country" appearance="fill" 
					
					[value]="defaultCountry"
					label="Pays" loadingDB="true" 
					(onCountrySelected)="onCountrySelected($event)" required>
                    </mat-select-country>
                </div>
            </div>


	    <div class="row mt-5" style="margin-top;5px;margin-bottom;5px;">
		<h6 style="color: black; ">Adresse de facturation</h6>
		<mat-divider [inset]="true"></mat-divider>
	    </div>
		    
	    <div class="row">
                <div class="col-6">
                    <mat-label>Numéro de rue</mat-label>
                    <input matInput #billingNumRue type="text" placeholder="Ex. 21" formControlName="billingStreet"
			   [ngModel]="_customer && _customer?.billingAddress && _customer?.billingAddress?.street"
			   (ngModelChange)="_customer &&  _customer.billingAddress && _customer.billingAddress.street = $event"
                        class="form-control form-control-sm" required>
		    <app-show-errors [ctrl]="form.get('street')"></app-show-errors>
                </div>
                <div class="col-6">
                    <mat-label>Ville</mat-label>
                    <input matInput
			   [ngModel]="_customer && _customer?.billingAddress && _customer?.billingAddress?.city"
			   (ngModelChange)="_customer &&  _customer.billingAddress && _customer.billingAddress.city = $event"
			   formControlName="billingCity" type="text" class="form-control form-control-sm" required>
		    <app-show-errors [ctrl]="form.get('city')"></app-show-errors>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <mat-label>Code Postale</mat-label>
                    <input matInput #billinhPostalCode maxlength="5" type="number" placeholder="Ex. 94105"
			   [ngModel]="_customer && _customer?.billingAddress &&_customer?.billingAddress?.zip"
			   (ngModelChange)="_customer &&  _customer.billingAddress && _customer.billingAddress.zip = $event"
			   formControlName="billingZip" class="form-control form-control-sm" required>
                    <mat-hint align="end" style="color: black;">{{postalCode.value.length}} / 5</mat-hint>
		    <app-show-errors [ctrl]="form.get('zip')"></app-show-errors>
                </div>
            </div>

	    <div class="row">
	    </div>

	    <div class="row mt-5" style="margin-top;5px;margin-bottom;5px;">

		<mat-divider [inset]="true"></mat-divider>
	    </div>
	    

	    <mat-label>Société</mat-label>
            <div class="input-group">
		<mat-select placeholder="Choisir les clients"
			    class="  form-control form-control-sm mb-2"
			    [formControl]="companiesListCtrl" 
			    [(ngModel)]="selectedCompany"
			    [compareWith]="compareFn"
			    (selectionChange)="onCompaniesChanged($event)"
			    required >
		    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyCompanies($event.target.value)"> 
		    <mat-select-trigger>
			{{selectedCompany?.name  || ''}} 
                        <span *ngIf="(companiesList?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedCompanies?.length || 0) - 1}} {{selectedCompanies?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let company of companiesList" [value]="company">{{company.name}}</mat-option>
		</mat-select>
                <div class="input-group-append">
                    <button type="button"class="btn btn-primary  form-control-sm">
                      <mat-icon>add</mat-icon>
                    </button>
                  </div>
            </div>
	    
	    <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Note</mat-label>
		<textarea style="height: 50px;" matInput placeholder="note" formControlName="note"
			  [ngModel]="_customer && _customer.note && _customer.note.content"
			  (ngModelChange)="_customer && _customer.note && _customer.note.content  = $event"
			  required></textarea>
		<app-show-errors [ctrl]="form.get('note')"></app-show-errors>
            </mat-form-field>

	    
            <div class="row">
                <div class="col-6">
                    <mat-label>Status</mat-label>
                    <div class="input-group">
			<mat-select placeholder="Choisir un statut pour ce type de produit" class="  form-control form-control-sm mb-2"   required
			    [(ngModel)]="selectedStatus" [formControl]="statusesListCtrl"  [compareWith]="compareFn" (selectionChange)="onStatusesChanged($event)"  >
		    <input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyStatuses($event.target.value)">  
                    <mat-select-trigger>
                        {{selectedStatus?.label  || ''}}
                        <span *ngIf="(selectedStatuses?.length || 0) > 1" class="example-additional-selection">
                            (+{{(selectedStatuses?.length || 0) - 1}} {{selectedStatuses?.length === 2 ? 'autre' :
                            'autres'}})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
		</mat-select>
                    </div>
                </div>
            </div>

            <div class="example-full-width d-flex justify-content-center" *ngIf="DisplayConfirmationButton">
                <button class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>

                <!-- <button mat-raised-button cdkFocusInitial>Annuler</button> -->
            </div>




        </form>




    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
