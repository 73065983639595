<!-- Content Header (Page header) -->
<div class="content-header header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">VOIR EXPÉDITION</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Expédition</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 30px;">

        <div class="d-flex justify-content-end">
            <!--<button class="btn btn-primary btn-sm" (click)="onEditShipping(_shipping.id)">Éditer une expédition</button>-->
        </div>

        <label>Voir Expedition</label>
        <div class="lateral-space">
   
            <div class="row mt-3 w-100 left-background">
                <div class="col-6 left">
                    Numéro d'expédition retrait
                </div>
                <div class="col-6 right">
                    {{_shipping.shippingNumber}}
                </div>
            </div>
            <div *ngIf="_shipping.shippingType" class="row w-100">
                <div class="col-6 left">
                    Méthode d'expédition retrait
                </div>
                <div class="col-6 right">
                    {{_shipping?.shippingType?.name}}
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    Date d'expédition
                </div>
                <div class="col-6 right">
                    {{ _shipping.shippingDate |  date:'dd/MM/yyyy' }}
                </div>
            </div>
            <div *ngIf="_shipping.shippingStatus" class="row w-100">
                <div class="col-6 left">
                    Status de l'expédition
                </div>
                <div class="col-6 right">
                    {{_shipping.shippingStatus?.label}}
                </div>
            </div>
            <div *ngIf="_shipping?.manager" class="row w-100 left-background">
                <div class="col-6 left">
                    Gestionnaire
                </div>
                <div class="col-6 right">
                    {{_shipping?.manager?.firstname}} {{_shipping?.manager?.lastname}}
                </div>
            </div>
            <div *ngIf="_shipping?.relayPoint" class="row w-100">
                <div class="col-6 left">
                    Point de relais
                </div>
                <div class="col-6 right">
		    {{_shipping?.relayPoint?.name}}
                </div>
            </div>
            <div class="row w-100 left-background">
                <div class="col-6 left">
                    Type Livraison
                </div>
                <div class="col-6 right">
		    <span *ngIf="_shipping.delivery"> <span class="badge badge-pill badge-primary"> Livraison </span> </span>
		    <span *ngIf="_shipping.relayPoint">
			<span *ngIf="_shipping.relayPoint.isRelayPoint" class="table-regular">
			    <span class="badge badge-pill badge-info"> Point relais </span>
			</span>
			<span *ngIf="_shipping.relayPoint.isSalePoint" class="table-regular">
			    <span class="badge badge-pill badge-warning"> Point de vente </span>
			</span>
			
		    </span>

                </div>
            </div>
            <div *ngIf="_shipping?.deliveryMan"  class="row w-100">
                <div class="col-6 left">
                    Livreur
                </div>
                <div class="col-6 right">
                    {{_shipping?.deliveryMan?.firstname}} {{_shipping?.deliveryMan?.lastname}}
                </div>
            </div>
        </div>







        <div *ngIf="_shipping?.delivery" class="row mt-5">
            <div class="col-md-6 col-sm-12">
                <label>Adresse de livraison</label>
                <div class="lateral-space">
           
                    <div class="row mt-3 w-100 left-background">
                        <div class="col-6 left">
                            Rue
                        </div>
                        <div class="col-6 right">
                             {{_shipping?.delivery?.address?.street}}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Ville
                        </div>
                        <div class="col-6 right">
                            {{_shipping?.delivery?.address?.city}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Code postal
                        </div>
                        <div class="col-6 right">
                            {{_shipping?.delivery?.address?.zip}}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Pays
                        </div>
                        <div class="col-6 right">
			    {{_shipping?.delivery?.address?.country}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Details additionnels
                        </div>
                        <div class="col-6 right">
                             {{_shipping?.delivery?.additionalDetail}}
                        </div>
                    </div>
                </div>
            </div>
	</div>

        <div *ngIf="_shipping?.relayPoint" class="row mt-5">
            <div class="col-md-6 col-sm-12">
                <label>Adresse du point de retrait </label>
                <div class="lateral-space">
           
                    <div class="row mt-3 w-100 left-background">
                        <div class="col-6 left">
                            Rue
                        </div>
                        <div class="col-6 right">
                             {{_shipping?.relayPoint?.address?.street}}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Ville
                        </div>
                        <div class="col-6 right">
                            {{_shipping?.relayPoint?.address?.city}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Code postal
                        </div>
                        <div class="col-6 right">
                            {{_shipping?.relayPoint?.address?.zip}}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Pays
                        </div>
                        <div class="col-6 right">
			    {{_shipping?.relayPoint?.address?.country}}
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Details additionnels
                        </div>
                        <div class="col-6 right">
                             {{_shipping?.relayPoint?.additionalDetail}}
                        </div>
                    </div>
                </div>
            </div>


	    <div class="col-md-6 col-sm-12">
                <label>Informations complémentaires</label>
                <div class="lateral-space">
           
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Créé le {{_shipping?.objectDetail?.creationDate |  date:'dd/MM/yyyy' }}  
                        </div>
                        <div class="col-6 right">
                            Modifié le {{_shipping?.objectDetail?.creationDate |  date:'dd/MM/yyyy' }}  
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Créé par
                        </div>
                        <div class="col-6 right">
                             {{_shipping?.objectDetail?.creator?.username}}   
                        </div>
                    </div>
                    <div class="row w-100 left-background">
                        <div class="col-6 left">
                            Modifié par
                        </div>
                        <div class="col-6 right">
                            {{_shipping?.objectDetail?.updater?.username}}
                        </div>
                    </div>
                    <div class="row w-100">
                        <div class="col-6 left">
                            Status
                        </div>
                        <div class="col-6 right d-flex justify-content-center">
                            <span class="badge badge-pill badge-primary">{{_shipping?.status?.label}}  </span>  
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
