<!-- Content Header (Page header) -->
<div class="content-header header" *ngIf="TitleVisible">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="m-0" style="color:black; font-weight: 300;">AJOUTER UN PRODUIT</h4>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Ecommerce</a></li>
                    <li class="breadcrumb-item active">Produits</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content" style="background-color: white;">
    <div class="container-fluid" style="padding: 50px;">



        <h3 style="color: black; font-size:medium">Information sur le produit</h3>
        <h6 style="color: black; font-size: small">Remplissez les informations suivantes</h6>

	

	
        <mat-stepper orientation="horizontal">

            <mat-step>
		

		<ng-template matStepLabel><mat-icon>local_shipping</mat-icon> Information du produit </ng-template>
		<ng-template matStepContent>
		    
		    <h6 style="color: black; font-size: small">Remplir les informations ci-dessous</h6>
		    

		    <form id="productForm" class="example-form" [formGroup]="form" (ngSubmit)="submit()">


			<div class="row mt-3">
			    <div class="col-6">
				<mat-label>Nom du produit</mat-label>
				<input type="text" matInput formControlName="name" class="form-control form-control-sm" required>
				<app-show-errors [ctrl]="form.get('name')"></app-show-errors>
			    </div>
			    <div class="col-6">
				<mat-label>Type de produit</mat-label>
				<div class="input-group">
				    <mat-select class="  form-control form-control-sm mb-2" [formControl]="productTypesListCtrl"
						       [(ngModel)]="selectedProductType"
						required  >
					<input class="mat-select-input-search" placeholder ="recherche " (keyup)="onKeyProductTypes($event.target.value)"> 
					<mat-select-trigger>
					    {{selectedProductType?.name || ''}}
					    <span *ngIf="(productTypesListCtrl.value?.length || 0) > 1" class="example-additional-selection">
						(+{{(productTypesListCtrl.value?.length || 0) - 1}} {{productTypesListCtrl.value?.length === 2 ? 'autre' :
						'autres'}})
					    </span>
					</mat-select-trigger>
					<mat-option *ngFor="let productType of productTypesList" [value]="productType">{{productType?.name}}</mat-option>
				    </mat-select>
				    <div class="input-group-append">
					<button type="button" class="btn btn-primary  form-control-sm">
					    <mat-icon>add</mat-icon>
					</button>
				    </div>
				</div>
			    </div>
			</div>

			<div class="row mt-3">
			    <div class="col-6">
				<mat-label>Marque</mat-label>
				<div class="input-group">
				    <mat-select class="  form-control form-control-sm mb-2" [formControl]="brandsListCtrl"
						       [(ngModel)]="selectedBrand"
						
						required  >
					<input class="mat-select-input-search" placeholder ="recherche " (keyup)="onKeyBrands($event.target.value)"> 
					<mat-select-trigger>
					    {{selectedBrand?.name || ''}} 
					    <span *ngIf="(brandsListCtrl.value?.length || 0) > 1" class="example-additional-selection">
						(+{{(brandsListCtrl.value?.length || 0) - 1}} {{brandsListCtrl.value?.length === 2 ? 'other' :
						'others'}})
					    </span>
					</mat-select-trigger>
					<mat-option *ngFor="let brand of brandsList" [value]="brand">{{brand?.name}}</mat-option>
				    </mat-select>

				    <div class="input-group-append">
					<button type="button" class="btn btn-primary  form-control-sm">
					    <mat-icon>add</mat-icon>
					</button>
				    </div>
				</div>
			    </div>
			    <div class="col-6">

				<div class="row">

				    <div class="col-4">
					<mat-label>Prix</mat-label>
					<input matInput maxlength="5" type="number" placeholder="Ex. 21" formControlName="price"  class="form-control form-control-sm" required>
					<app-show-errors [ctrl]="form.get('price')"></app-show-errors>
				    </div>
				    <div class="col-4">
					<mat-label>Poids</mat-label>
					<input matInput formControlName="weight" type="number" class="form-control form-control-sm" required>
					<app-show-errors [ctrl]="form.get('weight')"></app-show-errors>
				    </div>
				    <div class="col-4">
					<mat-label>Sku</mat-label>
					<input matInput type="text" maxlength="20"  placeholder="Ex. ref produit" formControlName="sku" class="form-control form-control-sm" required>
					<app-show-errors [ctrl]="form.get('sku')"></app-show-errors>
				    </div>
				</div>

			    </div>
			</div>


			<div class="row mt-3">
			    <div class="col-6">
				<div class="row">
				    <div class="col-12">
					<mat-label>Quantité</mat-label>
					<input type="number" matInput formControlName="quantity" class="form-control form-control-sm" required>
					<app-show-errors [ctrl]="form.get('quantity')"></app-show-errors>
					
				    </div>

				    <div class="col-12">
					<mat-label>Status</mat-label>
					<div class="input-group">
					    <mat-select placeholder="Choisir un statut pour ce produit" class="  form-control form-control-sm mb-2"   required
							[(ngModel)]="selectedStatus" [formControl]="statusesListCtrl"  [compareWith]="compare" (selectionChange)="onStatusesChanged($event)"  >
						<input class="mat-select-input-search" placeholder ="recherche" (keyup)="onKeyStatuses($event.target.value)">  
						<mat-select-trigger>
						    {{selectedStatus?.label  || ''}}
						    <span *ngIf="(selectedStatuses?.length || 0) > 1" class="example-additional-selection">
							(+{{(selectedStatuses?.length || 0) - 1}} {{selectedStatuses?.length === 2 ? 'autre' :
							'autres'}})
						    </span>
						</mat-select-trigger>
						<mat-option *ngFor="let status of statusesList" [value]="status">{{status.label}}</mat-option>
					    </mat-select>
					</div>
				    </div>

				    
				</div>
				
			    </div>
			    <div class="col-6">
				<mat-form-field appearance="outline" class="example-full-width">
				    <mat-label>Description</mat-label>
				    <textarea style="height: 100px;" formControlName="description" matInput placeholder="Description" required></textarea>
				    <app-show-errors [ctrl]="form.get('description')"></app-show-errors>
				</mat-form-field>
			    </div>
			</div>


			<div class="row mt-3">
			    <div class="col-6">
				<mat-label>Image principale</mat-label>
				<input type="file"  #mainImageUpload id="mainImageUpload" name="mainImageUpload"  formControlName="mainImages" class="form-control" id="inputGroupMainImage02">
				<app-show-errors [ctrl]="form.get('mainImageUpload')"></app-show-errors>
			    </div>

			    <div class="col-6">
				<mat-label>Images</mat-label>
				<input type="file" [multiple]="true"  #imagesUpload id="imagesUpload" name="imagesUpload"  formControlName="images" class="form-control" id="inputGroupImages03">
				<app-show-errors [ctrl]="form.get('imagesUpload')"></app-show-errors>
			    </div>
			</div>

			<div class="row mt-3">
			    <div class="col-6">
				<mat-form-field appearance="outline" class="example-full-width mb-2">
				    <mat-label>Note</mat-label>
				    <textarea style="height: 100px;" formControlName="note" matInput placeholder="Note" required></textarea>
				    <app-show-errors [ctrl]="form.get('note')"></app-show-errors>
				</mat-form-field>
			    </div>
			</div>


			<!--<div class="example-full-width d-flex justify-content-start">
			    <button class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>
			</div>-->
			
		    </form>

		</ng-template>
	    </mat-step>

	    <mat-step >
                <ng-template matStepLabel><mat-icon>fact_check</mat-icon> Groupe </ng-template>
		
		<!--
		     <div   class="row mt-5 top-right" layout="row" layout-align=" end">
		     <div  layout="column" layout-align="center end" >
		     <div><button mat-raised-button matStepperPrevious color="primary" class="mr-1" type="button">Pr�c�dent</button></div>
		     <div><button mat-raised-button matStepperNext color="primary" type="button">Suivant</button></div>
		     </div>
		     </div>
		-->

		<h6 style="color: black; font-size: small">Remplir les informations ci-dessous</h6>
		
                <div class="table-responsive">
		    <div fxLayout="row" fxLayoutAlign="end center">
			<mat-form-field fxFlex="60%">
			    <input matInput type="text" (keyup)="doFilter($event.target.value)"  name="filter" placeholder="Filtrer">
			</mat-form-field>
		    </div>

                    <table mat-table [dataSource]="datasourceSelection" matSort>
			
                        <ng-container matColumnDef="#">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">
                                <mat-checkbox></mat-checkbox>
                            </th>
                            <td style="width: 4%;" mat-cell *matCellDef="let element">
                                <mat-checkbox [(ngModel)]="element.checked"></mat-checkbox>
                            </td>
                        </ng-container>
			
			
                        <ng-container matColumnDef="companyGroup.label">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Label</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span>{{element?.companyGroup?.label}}</span>
                            </td>
                        </ng-container>

			
                        <ng-container matColumnDef="companyGroup.name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Nom</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span>{{element?.companyGroup?.name}}</span>
                            </td>
                        </ng-container>

			
			<ng-container matColumnDef="companyGroup.description">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Description</th>
                            <td style="width: auto;" mat-cell *matCellDef="let element">
                                <span>{{element?.companyGroup?.description}}</span>
                            </td>
                        </ng-container>
			
                        <ng-container matColumnDef="price">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color">Prix</th>
                            <td style="width: 120px;" mat-cell *matCellDef="let element">
                                <!-- <input matInput maxlength="5" type="number" placeholder="0" [(ngModel)]="element.qty"  class="form-control form-control-sm" required> -->
                                <mat-form-field appearance="outline" floatLabel="always">
                                    <input matInput type="number" class="example-right-align" [(ngModel)]="element.price"  placeholder="0">
                                </mat-form-field>
                            </td>
                        </ng-container>
			
			<ng-container matColumnDef="companyGroup.companies">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Societes</th>
			    <td mat-cell *matCellDef="let element">
				<span *ngFor="let company of element.companyGroup?.companies ; nb_companies as index;let i = index"   class="badge  badge-info">
				    <span *ngIf="i < 3 || more_companies">{{company?.name}}</span>
				</span>
				<mat-divider></mat-divider>
				<button mat-button  color="primary" *ngIf="element?.companies?.length > 3" (click)="more_companies = !more_companies">{{ more_companies ? 'voir moins' : 'voir plus' }}</button>
			    </td>
			</ng-container>


			<ng-container matColumnDef="companyGroup.products">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> Produits</th>
			    <td mat-cell *matCellDef="let element">
				<span class=""  *ngIf="3 as maxNbProducts">
				    <a  [routerLink]="" (click)="show_products = !show_products" ><span *ngIf=" element?.companyGroup?.products?.length > 0 ">Voir les produits </span></a>

				    <span *ngIf="show_products;">
					<span *ngFor="let product of element?.companyGroup?.products;nb_products as index;let i = index;let c = count">

					    <span *ngIf="i < maxNbProducts || more_products;  "  class="badge badge-pill badge-info"> {{product?.name}}</span>
					    <button mat-button  color="primary" *ngIf="i  + 1== c && c > maxNbProducts" (click)="more_products = !more_products">{{ more_products ? 'voir moins' : 'voir plus' }}</button> 
					</span>
				    </span>

				</span>

			    </td>
			</ng-container>

			
                        <ng-container matColumnDef="Action">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="color"> </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-icon matTooltip="consulter" (click)="onConsulterCompanyGroup(element.companyGroupId)" class="icon" aria-hidden="false"
					  aria-label="Example home icon" fontIcon="preview"></mat-icon>
                            </td>
                        </ng-container>
			
			
			
                        <tr mat-header-row *matHeaderRowDef="columnsSelection"></tr>
                        <tr mat-row *matRowDef="let row; columns: columnsSelection;" class="example-element-row"></tr>
                    </table>
		    
		    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
			<mat-progress-spinner 
			    color="primary" 
			    mode="indeterminate">
			</mat-progress-spinner>
		    </mat-card>
		    
                    <mat-paginator [pageSizeOptions]="[20, 50, 100, 500]" [pageSize]="pageSize" showFirstLastButtons
				   aria-label="Select page of periodic elements">
                    </mat-paginator>
                </div>


		<button mat-raised-button matStepperPrevious color="primary" class="mr-1" type="button">Pr�c�dent</button>
                <button mat-raised-button matStepperNext color="primary" type="button">Suivant</button>
		

            </mat-step>

	    <mat-step>
		<ng-template matStepLabel><mat-icon>done</mat-icon> Confirmation</ng-template>


		<ng-template matStepContent>
		    		
		    <div class="example-full-width d-flex justify-content-center" *ngIf="DisplayConfirmationButton">
			<button type="submit" form="productForm" class="btn btn-primary" [disabled]="form.invalid">Sauvegarder</button>
			
			<!-- <button mat-raised-button cdkFocusInitial>Annuler</button> -->
		    </div>
		</ng-template>
		
		
		<button mat-raised-button color="primary" matStepperPrevious>Pr�c�dent</button>
            </mat-step>


	</mat-stepper>





    </div><!--/. container-fluid -->
</section>
<!-- /.content -->
