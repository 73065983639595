import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';



import { Brand } from 'src/app/interfaces/brand';
import { Note } from 'src/app/interfaces/note';
import { Product } from 'src/app/interfaces/product';

import { ProductService } from 'src/app/services/product.service'
import { BrandService } from 'src/app/services/brand.service';


import { Response } from '../../interfaces/response';
import {switchMap, map, combineLatest} from 'rxjs/operators'
import { Observable, forkJoin } from 'rxjs';    
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-new-brand',
  templateUrl: './new-brand.component.html',
  styleUrls: ['./new-brand.component.scss'], encapsulation: ViewEncapsulation.None
})
export class NewBrandComponent  implements OnInit  {
  @Input() TitleVisible: boolean = true;
  @Input() DisplayConfirmationButton: boolean = true;

    ////////////////////////
    // Attributes
    ///////////////////////

    
  form: FormGroup;

    productsListCtrl = new FormControl('');
    productsList : Product[];//string[];
    productsData : Product[];//string[];
    selectedProducts : Product[];
    
    ////////////////////////  
    // Constructor 
    ////////////////////////
    
  constructor(private router: Router,
	      private _snackBar: MatSnackBar,
	      private brandService: BrandService,
	      private productService: ProductService, 
      public fb: FormBuilder) { 
 
      }
      

    ////////////////////////
    // Init
    ///////////////////////
    
    ngOnInit(): void {

	
	this.refresh();
	this.initForm();
    }

    ////////////////////////
    // Datasrouces
    ///////////////////////

    initForm() : void {
	this.form = this.fb.group({
	    nom: new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(2)]),
	    description: new FormControl(null, [Validators.required, Validators.maxLength(150), Validators.minLength(2)]),
	    note: new FormControl(null, [Validators.required, Validators.maxLength(200), Validators.minLength(2)]),     
	});
    }

    ////////////////////////
    // Datasrouces
    ///////////////////////

    refresh(): void {
	
	this.productService.getAllProducts().subscribe({
	    next: (response: Response<number, Product[]>) => {
		this.productsData = response.Data;
		this.initProductsSearchDropdown();
            },error: (e) => { }, complete: () => console.info('load products complete')
	});


    }

    ////////////////////////
    // Mat-select Search
    ///////////////////////

    ////////////////////////
    // initialize dropdowns
    
    initProductsSearchDropdown(){
	this.productsList = [];
        for ( let i = 0 ; i < this.productsData.length; i ++ ) {
            this.productsList.push( this.productsData[i] );
        }
    }


    

    
    ////////////////////////
    // On Search
    
    onKeyProducts(value) { 
            this.productsList= []; 
            this.selectProductsSearch(value);       
    }


    ////////////////////////
    // search elements


    selectProductsSearch(value:string){
        let filter = value.toLowerCase();
        for ( let i = 0 ; i < this.productsData.length; i ++ ) {
            let option = this.productsData[i];
            if (  option.name.toLowerCase().indexOf(filter) >=0
		||  option.name.toLowerCase().indexOf(filter) >= 0 ) {
                this.productsList?.push( option );
            }
        }
    }


    
    ////////////////////////////
    // elements selections
    ///////////////////////////
    
    OnProductsChanged($event: any) {
      console.log($event);
    }


    ////////////////////////////
    // External actions
    ///////////////////////////
    
    addNewProduct(): void {
/*    const dialogRef = this.dialog.open(AddRoleDialogComponent, {
      disableClose: true,
      data: { TitleVisible: false, DisplayConfirmationButton: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {

      }
    });*/
  }
    
    ////////////////////////
    ///////////////////////
    
    
    ////////////////////////
    // Actions
    ///////////////////////


  submit() {
	if (!this.form.valid) {
	    return;
	}

	let brand : Brand = {
	    name: this.form.value.nom,
	    description: this.form.value.description,
	};


	const note : Note = {
	    content : this.form.value.note
	}



	brand.note = note;
	brand.products = this.selectedProducts;
	console.log(brand);
	console.log("gonna save");

	this.brandService.prepareAddRelations(brand).pipe(
	    switchMap(preparedBrand => { 
		console.log("entered switch map -- add new brand ");
		console.log(preparedBrand);
		return this.brandService.add(brand);}),
	    switchMap(brandAddedObservable => {
		let brandAdded = brandAddedObservable.Data;
		brandAdded.products = this.selectedProducts;
		console.log("entered switch map -- handle post relations ");
		console.log(brandAdded);
		return this.brandService.handlePostAddRelations(brandAdded); 
	    })
	
						      
	).subscribe({
	    next: (res) => {  this.checkSubmitSucess(res);},
	    error: (error) => { this.submitError(error) },
	    complete: () => {
		console.info('save school pre relations complete')
		this._snackBar.open('Sauvegarde effectuee', '', {
		    duration: 3000,
		    horizontalPosition: 'center',
		    verticalPosition: 'bottom',
		    panelClass: ['mat-toolbar', 'mat-primary']}
				   );
		setTimeout(() => 
		    {
			this.submitSuccess();  
		    }, 5000);
		
	    }
	    
	    
	});

    }

    ///////////////////////////////
    // Success / Faillure | Submit
    ///////////////////////////////

    submitSuccess(){
	this.router.navigate([environment.paths.ListBrandPath]);
    }
    
    submitFaillure(){
	this._snackBar.open('l\'enregistrement a echouee', '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
      });
    }

    submitError(error){
	console.log(error);
    }

    checkSubmitSucess(res){
	console.log(res);
	if (res.IsSuccess == 0){
	    this.submitFaillure();
	} else {
	    this.submitSuccess();

	}
    }
}
