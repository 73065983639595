import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Menu } from './interfaces/menu';
import { MenuHeader } from './interfaces/menu-header';
import { MenuJson } from './interfaces/menu-json';
import { MenuService } from './services/menu.service';
import { Response } from './interfaces/response';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'], encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  title = 'OFP-App';
  // menuDtos: MenuDto[];
  menuHeaders: MenuHeader[] = undefined;
  env = environment;
  currentMenu: Menu;
  componentToLoad: string = 'CheckoutComponent';
  hideMenu = false;

  /*   treeControlMap: Map<number, NestedTreeControl<MenuDto>> = new Map();
    datasourceMap: Map<number, MatTreeNestedDataSource<MenuDto>> = new Map(); */



  myData = undefined;

  constructor(private menuService: MenuService, private router: Router) {
  }

    ngOnInit(): void {
//	this.loadMenuHeader();
    }

    ngAfterViewInit() : void {
	this.loadMenuHeader();

    }


  hideMainMenu(state: boolean) {
    this.hideMenu = state;
  }

  public loadMenuHeader() {
    if (this.menuHeaders !== undefined) {
      console.log('this.menuHeaders !== undefined');
      this.menuHeaders.forEach((menuHeader) => {
        this.loadMenu(menuHeader.Id);
      });
    } else {
      console.log('this.menuHeaders === undefined');
      this.menuService.getMenuHeaders().subscribe(
        {
          next: (response: Response<number, MenuHeader[]>) => {
            if (response.IsSuccess === 0) {
              this.menuHeaders = response.Data;
              this.menuHeaders.forEach((menuHeader) => {
                this.loadMenu(menuHeader.Id);
              });
            }
          },
          error: (e) => {
          },
          complete: () => console.info('loadMenuHeader complete')
        }
      );
    }
  }


  private loadMenu(menuHeaderId: number) {
    const instance = this;
    this.menuService.getMenu(menuHeaderId).subscribe(
      {
        next: (response: Response<number, MenuJson>) => {
          if (response.IsSuccess === 0) {
 
              const treeView = $('.example' + response.Data.MenuHeaderId).aclTreeView({
                // collapse all nodes on init
                initCollapse: true,

                // animation speed in milliseconds
                animationSpeed: 100,

                // allows multiple nodes to be opened at a time
                multy: false,

                // callback
                callback: function (event, $elem, params) {
                  //console.log(params);
                  if (params.path) {
                    instance.loadComponent(params.path);
                  }
                },
              }, JSON.parse(response.Data.Json));
          }
        },
        error: (e) => {
        },
        complete: () => console.info('loadMenu complete')
      }
    );
  }

  /*   getTreeControl(menuHeaderId: number): NestedTreeControl<MenuDto> {
      return this.treeControlMap.get(menuHeaderId);
    }
  
    
    getDataSource(menuHeaderId: number): MatTreeNestedDataSource<MenuDto> {
      return this.datasourceMap.get(menuHeaderId);
    } */

 // hasChild = (_: number, node: MenuDto) => !!node.ChildMenuItems && node.ChildMenuItems.length > 0;

  loadComponent(path: string) {
  //  this.componentToLoad = componentName;
    this.redirectTo(path);
  }

  redirectTo(uri:string){
    this.router.navigate([uri]);
 }

  onChangeComponent(componentName: string) {
    this.componentToLoad = componentName;
  }


    logout(){
      localStorage.removeItem('user');
      localStorage.removeItem('profile');
      localStorage.removeItem('company');
      localStorage.removeItem('companyGroup');
      localStorage.removeItem('token');
      this.router.navigate(['/']).then(() => { window.location.reload();}); 
  }
}
